import { Component, Prop, Inject, Vue, Emit, Watch } from 'vue-property-decorator';
import { PersonaFisica as PersonaFisicaBuscador } from '@conacyt/buscador-cvu';
import { HistoricoPagos, HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import { PerfilApi } from '@conacyt/perfil-usuario-client';

import { StatusCodes } from 'http-status-codes';

import DatosGeneralesComponent from '@/components/expediente-persona/datos-generales/datos-generales.vue';
import VigenciasSniComponent from '@/components/expediente-persona/vigencias-sni/vigencias-sni.vue';
import AcreditacionesComponent from '@/components/expediente-persona/acreditaciones/acreditaciones.vue';
import ParticipacionesComoEvaluadorComponent from '@/components/expediente-persona/participaciones-como-evaluador/participaciones-como-evaluador.vue';
import AyudantiasComponent from '@/components/expediente-persona/ayudantias/ayudantias.vue';
import MisAyudantesComponent from '@/components/expediente-persona/mis-ayudantes/mis-ayudantes.vue';
import CuentasBancariasComponent from '@/components/expediente-persona/cuentas-bancarias/cuentas-bancarias.vue';
import ExcepcionesComponent from '@/components/expediente-persona/excepciones/excepciones.vue';
import AccountService from '@/account/account.service';
import { Authority } from '@/shared/security/authority';
import Utilidades from '@/shared/config/utilidades';

@Component({
  components: {
    'datos-generales': DatosGeneralesComponent,
    'vigencias-sni': VigenciasSniComponent,
    agreditaciones: AcreditacionesComponent,
    'participaciones-como-evaluador': ParticipacionesComoEvaluadorComponent,
    ayudantias: AyudantiasComponent,
    'cuentas-bancarias': CuentasBancariasComponent,
    'mis-ayudantes': MisAyudantesComponent,
    excepciones: ExcepcionesComponent,
  },
  computed: {
    loginCvu: {
      get() {
        return this.$store.getters.userInfo?.cvu;
      },
    },
  },
})
export default class ExpedientePersonaComponent extends Vue {
  @Inject('historicoPersonasApi') private historicoPersonasApi: () => HistoricoPersonasApi;
  @Inject('perfilApi') private perfilApi!: () => PerfilApi;
  @Inject('accountService') private accountService: () => AccountService;

  public readonly loginCvu!: string;

  public personaCvu: PersonaFisicaBuscador = null;

  @Prop({ default: false })
  public readOnly: boolean;

  /**
   * Indica si el usuario actual tiene permisos buscar otro cvu que no sea el suyo
   */
  public autorizado = true;

  @Prop({ default: null })
  public cvu: string;

  @Prop({ default: true })
  public visible: boolean;

  @Prop({ default: () => [] })
  public exclude: Array<string>;

  ayudantiasKey = 0;

  public utilidades = new Utilidades();
  public historicoPagos: Array<HistoricoPagos> = [];
  public currentPage = 1;
  public perPage = 12;
  public totalRows = 0;
  private datosCompletosExcel = [];
  private paginaSiguienteExcel = true;
  public btnExcelShow = true;

  created() {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth([Authority.OPERADOR, Authority.SOPORTE, Authority.INTERNAL_USER])
      .then(autorizado => {
        this.autorizado = autorizado;
      });
    if (this.cvu) {
      this.search = this.cvu;
      this.retriveExpedientePersona(this.search);
    }
  }

  @Watch('autorizado')
  private consultarExpedientePropio() {
    if (!this.autorizado) {
      this.search = this.loginCvu;
      this.retriveExpedientePersona(this.search);
    }
  }

  showSection(section: string): boolean {
    if (this.exclude?.length > 0) {
      return !this.exclude.includes(section);
    }
    return true;
  }

  expediente = null;
  search = null;
  isSearching = false;
  mensaje = 'Ingresa el cvu de la persona que deseas buscar y da clic en el botón con la lupa';
  variant = 'light';
  vigenciasVisible = true;
  acreditacionesVisible = true;
  participacionesVisible = true;
  ayudantiasVisible = true;
  participacionesEvaluadorVisible = true;
  cuentasBancariasVisible = true;
  hasError = false;

  public handleSearch() {
    this.retriveExpedientePersona(this.search);
  }
  public retriveExpedientePersona(cvu) {
    this.search = cvu;
    this.isSearching = true;
    this.expediente = null;
    this.historicoPagos = [];
    this.currentPage = 1;
    this.historicoPersonasApi()
      .getExpediente(cvu)
      .then(result => {
        if (result) {
          this.isSearching = false;
          this.expediente = result.data;
          this.search = null;
          this.hasError = false;
          this.retriveAyudantesByCvu(this.expediente.datosGenerales.persona.cvu);
          this.resolveInvestigadorInfo(this.expediente.ayudantias);
          this.personaUpdated(this.expediente);
          this.buscarHistoricoPagos();
        }
      })
      .catch(error => {
        this.search = null;
        this.isSearching = false;
        this.expediente = null;
        if (error.response.data.status === StatusCodes.NOT_FOUND) {
          this.mensaje = 'No encontramos resultados para el cvu ingresado';
          this.variant = 'warning';
          this.hasError = false;
        } else {
          this.mensaje = 'Tuvimos problemas con la carga, por favor intenta nuevamente';
          this.variant = 'danger';
          this.hasError = true;
        }
      });
  }

  public resolveInvestigadorInfo(misAyudantias) {
    console.log(misAyudantias);
    if (misAyudantias?.length > 0) {
      const cvusToFind = misAyudantias.map(ayudante => ayudante.cvuInvestigador);
      this.perfilApi()
        .getBulkPerfiles(cvusToFind)
        .then(result => {
          const investigadores = result.data;
          for (const ayudantia of this.expediente.ayudantias) {
            const investigadorFinded = investigadores.find(investigador => investigador.cvu == ayudantia.cvuInvestigador);
            ayudantia.nombreCompletoInvestigador = investigadorFinded.nombreCompleto;
          }
          this.ayudantiasKey += 1;
        })
        .catch(error => {});
    }
  }

  public misAyudantes = null;
  public retriveAyudantesByCvu(cvu) {
    this.misAyudantes = null;
    this.historicoPersonasApi()
      .getAllAyudantesByCvuInvestigador(cvu)
      .then(result => {
        if (result) {
          if (result?.data?.length > 0) {
            this.misAyudantes = result.data;
          }
        }
      })
      .catch(error => {});
  }

  @Emit('persona-updated')
  public personaUpdated(expediente) {
    return expediente;
  }

  public resolveNombreCompletoPersona() {
    if (this.expediente?.datosGenerales?.persona) {
      return (
        this.expediente.datosGenerales.persona.nombre +
        ' ' +
        this.expediente.datosGenerales.persona.apellidoPaterno +
        ' ' +
        this.expediente.datosGenerales.persona.apellidoMaterno
      );
    }
    return '';
  }

  /**
   * Busca el histórico pagos del Investigador ó del Ayudante
   *
   * @memberof ExpedientePersonaComponent
   */
  private buscarHistoricoPagos() {
    this.historicoPersonasApi()
      .getHistoricoPagos(
        this.expediente.datosGenerales.persona.cvu,
        undefined,
        undefined,
        undefined,
        undefined,
        this.currentPage,
        this.perPage
      )
      .then(result => {
        if (result.data.length > 0) {
          for (const historicoPago of result.data) {
            let datosHistoricos;
            if (this.expediente.datosGenerales.persona.cvu == historicoPago.cvuInvestigador) {
              datosHistoricos = this.utilidades.construirHistoricoPagos(historicoPago);
              this.resolvePagination(result.headers);
              this.historicoPagos.push(datosHistoricos);
            }
          }
        }

        return this.historicoPersonasApi().getHistoricoPagos(
          undefined,
          this.expediente.datosGenerales.persona.cvu,
          undefined,
          undefined,
          undefined,
          this.currentPage,
          this.perPage
        );
      })
      .then(result => {
        if (result.data.length > 0) {
          for (const historicoPago of result.data) {
            let datosHistoricos;
            if (this.expediente.datosGenerales.persona.cvu == historicoPago.cvuAyudante) {
              datosHistoricos = this.utilidades.construirHistoricoPagos(historicoPago);
              this.resolvePagination(result.headers);
              this.historicoPagos.push(datosHistoricos);
            }
          }
        }
      })
      .catch(error => {});
  }

  public fields = [
    { key: 'anio', label: 'Año', sortable: true },
    { key: 'nivel', label: this.$t('apeironGwApp.historicoPagos.nivel'), sortable: true },
    { key: 'ubicacion', label: this.$t('apeironGwApp.historicoPagos.ubicacion'), sortable: true },
    { key: 'sucursal', label: this.$t('apeironGwApp.historicoPagos.sucursal'), class: 'text-right', sortable: true },
    { key: 'cuentaCLABE', label: this.$t('apeironGwApp.historicoPagos.cuentaCLABE'), sortable: true },
    { key: 'mes', label: this.$t('apeironGwApp.historicoPagos.mes'), class: 'text-center', sortable: true },
    { key: 'monto', label: this.$t('apeironGwApp.historicoPagos.monto'), class: 'text-right', sortable: true },
    { key: 'estatusPago', label: this.$t('apeironGwApp.historicoPagos.estatusPago'), sortable: true },
  ];

  public handlePageChange(pageNumber: number) {
    this.historicoPagos = [];
    this.currentPage = pageNumber;
    this.buscarHistoricoPagos();
  }
  private resolvePagination(headers) {
    this.totalRows = headers['x-total'];
    this.currentPage = headers['x-page'];
  }

  /**
   * Genera el excel del Ayudante o del Investigador
   *
   * @memberof ExpedientePersonaComponent
   */
  public descargarReporteExcel() {
    this.exportarDatosExcelHistoricoPagos().then(() => {
      if (this.datosCompletosExcel.length > 0) {
        const columnasAFormatear = ['monto'];
        this.utilidades.exportarExcel(
          this.datosCompletosExcel,
          this.$t('apeironGwApp.historicoPagos.title').toString(),
          columnasAFormatear
        );
      }
      this.reiniciarDatos();
    });
  }

  /**
   * Metodo para generar un documento excel Historico pagos
   *
   * @memberof ExpedientePersonaComponent
   */
  private exportarDatosExcelHistoricoPagos() {
    return new Promise<void>((resolve, reject) => {
      const obtenerPagina = page => {
        return this.historicoPersonasApi()
          .getHistoricoPagos(this.expediente.datosGenerales.persona.cvu, undefined, undefined, undefined, undefined, page, this.perPage)
          .then(result => {
            if (result.data.length > 0) {
              const datos = result.data.map(r => this.utilidades.construirHistoricoPagosExcelBuscadorExpediente(r));
              this.datosCompletosExcel = this.datosCompletosExcel.concat(datos);
              this.paginaSiguienteExcel = Number(result.headers['x-total-pages']) > page;

              if (this.paginaSiguienteExcel) {
                return obtenerPagina(page + 1);
              } else {
                resolve();
              }
            }
            return this.historicoPersonasApi().getHistoricoPagos(
              undefined,
              this.expediente.datosGenerales.persona.cvu,
              undefined,
              undefined,
              undefined,
              page,
              this.perPage
            );
          })
          .then(result => {
            const datos = result.data.map(r => this.utilidades.construirHistoricoPagosExcelBuscadorExpediente(r));
            this.datosCompletosExcel = this.datosCompletosExcel.concat(datos);
            this.paginaSiguienteExcel = Number(result.headers['x-total-pages']) > page;

            if (this.paginaSiguienteExcel) {
              return obtenerPagina(page + 1);
            } else {
              resolve();
            }
          })
          .catch(error => {})
          .finally(() => {});
      };
      obtenerPagina(1);
    });
  }

  private reiniciarDatos() {
    this.datosCompletosExcel = [];
    this.paginaSiguienteExcel = false;
  }
}
