import RevisionService from '@/entities/revision/revision.service';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';
import { TipoRevision } from '@/shared/model/enumerations/tipo-revision.model';
import { Respuesta } from '@/shared/model/respuesta.model';
import { IRevision, Revision } from '@/shared/model/revision.model';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import SolucionMixin from '../mixins/solucion-mixin';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import SolicitudMixin from '../mixins/solicitud-mixin';
import { Solucion } from '@/shared/model/solucion.model';
import HomeMenuComponent from '@/components/home-menu/home-menu.component';

@Component
export default class EvaluacionConsejoComponent extends mixins(SolucionMixin, SolicitudMixin, HomeMenuComponent) {
  @Inject('revisionService') private revisionService: () => RevisionService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public revision: Revision;

  @Prop({ required: true })
  public solucionConsejo: Solucion;

  @Prop({ default: false })
  public checkConsejo!: boolean;

  public checkboxValue: boolean = this.checkConsejo;

  public revisionPlenaria: IRevision = null;

  public textoConsejo: string;

  public idNoAprobado = NRecomendadoEnum.NO_APROBADO;
  public textNoAprobado = this.$t('dictamen.noAprobado');

  public aceptada = EstadoRevision.ACEPTADA;
  public revisada = EstadoRevision.REVISADA;

  /**
   * Identificador evaluacion del consejo.
   */
  public consejoId: string;

  /**
   * Identificador evaluacion plenaria.
   */
  public plenariaId: string;

  /**
   *Este constructor toma el texto del consejo de la solución
   *y lo asigna a la propiedad 'textoConsejo'.
   *
   *@memberof EvaluacionConsejoComponent
   */
  constructor() {
    super();
    this.textoConsejo = this.solucionConsejo ? this.solucionConsejo.params.evaluacion.textoConsejo : '';
  }

  mounted(): void {
    this.set();
  }

  /**
   * Carga inicial del componente
   *
   * @memberof EvaluacionConsejoComponent
   */
  public set(): void {
    if (this.solicitud) {
      this.getDefinicionRevision(this.solicitud.solucionId);
    }
  }

  /**
   * Método utilizado para gestionar el valor del checkbox.
   * Comprueba si existe el textoConsejo en el dictamen
   * . Si es así, establece 'checkboxValue' en verdadero;
   * de lo contrario, lo establece en falso.
   *
   * @memberof EvaluacionConsejoComponent
   */
  public handlecheckboxValue(): void {
    if (this.revision?.respuesta?.dictamen?.textoConsejo) {
      this.checkboxValue = true;
    } else {
      this.checkboxValue = false;
    }
  }

  /**
   *Obtiene las definiciones de una revisión
   *
   * @memberof EvaluacionConsejoComponent
   */
  @Watch('definicionEvaluacion')
  public setDefiniciones(): void {
    if (this.solicitud?.reconsideracion) {
      this.plenariaId = this.getDefinicionByTipoComision(TipoRevision.PLENARIA, TipoComision.RECONSIDERACION);
      this.consejoId = this.getDefinicionByTipoComision(TipoRevision.CONSEJO, TipoComision.RECONSIDERACION);
    } else {
      this.plenariaId = this.getDefinicionByTipoComision(TipoRevision.PLENARIA, TipoComision.DICTAMINADORA);
      this.consejoId = this.getDefinicionByTipoComision(TipoRevision.CONSEJO, TipoComision.DICTAMINADORA);
    }
    this.setRevisionPlenaria();
  }

  /**
   *Obtiene la revisión tipo plenaria
   *
   * @memberof EvaluacionConsejoComponent
   */
  public setRevisionPlenaria(): void {
    this.revisionService()
      .getAllRevisionesBySolicitudId(this.solicitud.id, this.plenariaId)
      .then(revision => {
        this.revisionPlenaria = revision[0];
        this.handlecheckboxValue();
      })
      .catch(err => {});
  }

  /**
   *Guarda los datos que se obtuvieron de la evaluación plenaria
   *o los datos que se obtengan en la evaluación de consejo
   *dependiendo lo que el usuario selecione en el checkboxValue.
   *
   * @memberof EvaluacionConsejoComponent
   */
  public guardarDatosRecuperados(revision: IRevision): void {
    let revisionConsejo;

    if (!this.checkboxValue) {
      revisionConsejo = {
        comision: revision.respuesta.comision,
        evaluacion: revision.respuesta.evaluacion,
        dictamen: {
          ...revision.respuesta.dictamen,
        },
        resumen: revision.respuesta.resumen,
      };
    } else {
      revisionConsejo = {
        comision: revision.respuesta.comision,
        evaluacion: {
          id: this.idNoAprobado,
          nombre: this.textNoAprobado,
        },
        dictamen: {
          textoConsejo: this.textoConsejo,
        },
      };
    }
    this.revision.respuesta = { ...new Respuesta(), ...revisionConsejo, valido: true };
    this.$emit('guardarDatosRecuperados', revisionConsejo);
  }
}
