var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "at-ta",
        {
          attrs: { members: _vm.members },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (s) {
                return [
                  _c("b-icon", { attrs: { icon: "code-slash" } }),
                  _vm._v(" "),
                  _c("span", { domProps: { textContent: _vm._s(s.item) } }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.text,
            callback: function ($$v) {
              _vm.text = $$v
            },
            expression: "text",
          },
        },
        [
          _vm._v(" "),
          _c("b-form-textarea", {
            staticClass: "shadow",
            attrs: { rows: "3", "max-rows": "3" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }