var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.listMessages
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._l(_vm.comentarios, function (comentario) {
                return _c(
                  "b-card",
                  {
                    key: comentario.id,
                    staticClass: "mb-4",
                    attrs: { "bg-variant": "light" },
                  },
                  [
                    _c(
                      "b-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "center-felx",
                            attrs: { col: "", lg: "2" },
                          },
                          [_c("span", { staticClass: "icon-usuario" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          {
                            staticClass: "left-felx",
                            attrs: { col: "", lg: "9" },
                          },
                          [
                            _c("b-card-text", { staticClass: "text-left" }, [
                              comentario.tipo
                                ? _c(
                                    "div",
                                    { staticClass: "font-weight-bolder" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.TipoComentario." +
                                              comentario.tipo
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              comentario.text
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(comentario.text),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              comentario.fechaCreacion
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "text-muted",
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "clock-history" },
                                          }),
                                          _vm._v(" "),
                                          _c("em", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("timeElapsed")(
                                                  comentario.fechaCreacion
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _vm.isFetching ? _c("comentarios-skeleton") : _vm._e(),
              _vm._v(" "),
              _vm.totalItems > _vm.itemsPerPage
                ? _c(
                    "infinite-loading",
                    {
                      ref: "infiniteLoading",
                      attrs: {
                        slot: "append",
                        identifier: _vm.infiniteId,
                        "force-use-infinite-wrapper": ".el-table__body-wrapper",
                        distance: 20,
                      },
                      on: { infinite: _vm.loadMore },
                      slot: "append",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "no-more" }, slot: "no-more" },
                        [_vm._v(_vm._s(_vm.$t("global.process.search.nomore")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "no-results" }, slot: "no-results" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("global.process.search.noresults"))
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCommentDialogActivated
        ? _c(
            "div",
            { staticClass: "chat-popup text-left" },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "tipo-comentario-id",
                        label: "Asunto",
                        "label-for": "tipo-comentario-id",
                      },
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          staticClass: "mb-3",
                          model: {
                            value: _vm.$v.comentario.tipo.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.comentario.tipo, "$model", $$v)
                            },
                            expression: "$v.comentario.tipo.$model",
                          },
                        },
                        _vm._l(
                          _vm.tipoComentarioValues,
                          function (tipoComentario) {
                            return _c(
                              "b-form-select-option",
                              {
                                key: tipoComentario,
                                attrs: {
                                  id: "tipo-comentario-id",
                                  value: tipoComentario,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.TipoComentario." +
                                          tipoComentario
                                      )
                                    )
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "comentario-text-id",
                        label: "Comentario",
                        "label-for": "comentario-id",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: { id: "textarea", rows: "10", "max-rows": "6" },
                        model: {
                          value: _vm.$v.comentario.text.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.comentario.text, "$model", $$v)
                          },
                          expression: "$v.comentario.text.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.$v.comentario.$invalid,
                        id: "add-comentario",
                        variant: "primary",
                      },
                      on: { click: _vm.agregarComentarioHandler },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Agregar\n      "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.addComments
        ? _c(
            "b-button",
            {
              staticClass: "floating-btn",
              attrs: { id: "desglose-resumen", variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.toggleDialog()
                },
              },
            },
            [
              _c("b-icon", {
                staticClass: "floating-btn--icon",
                attrs: {
                  icon: _vm.isCommentDialogActivated ? "x-lg" : "chat-left",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }