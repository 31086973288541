import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { IVigenciaDto, VigenciaDto } from '@/shared/model/vigencia.model';
import { IDatosGenerales } from './persona/datos-generales';
import { TipoSolucion } from './enumerations/tipo-solucion.model';
import { IFile } from './file.model';
import * as personaUtils from '@/shared/util/format-util';
import { IProgramaIntegrado } from './snp/programa-integrado.model';
import { LieSnp } from '@/entities/snp/lie/lie.model';
import { PronaceSnp } from '@/entities/snp/pronaces/pronace.model';
import { RegistroTemaSnp } from '@/entities/snp/temas/temas.model';

export interface Solicitante {
  cvu?: string;
  nombre?: string;
  apellidoPaterno?: string;
  apellidoMaterno?: string;
}

export interface IRevisorResumen {
  id?: string;
  evaluacionId?: string;
  cvu?: string;
  revisorId?: string;
  nombre?: string;
  estado?: EstadoRevision;
}

export class RevisorResumen implements IRevisorResumen {
  constructor(
    public id?: string,
    public evaluacionId?: string,
    public cvu?: string,
    public revisorId?: string,
    public nombre?: string | null,
    public estado?: EstadoRevision | null
  ) {}
}

export interface SolicitudRizoma {
  solucionSolicitud?: {
    tipoSolucion?: TipoSolucion;
  };
  areaConocimiento?: {
    area?: {
      clave?: number;
      nombre?: string;
    };
  };
  programa?: {
    participante?: {
      institucion?: {
        nombreInstitucion?: string;
      };
      programa?: {
        nombre?: string;
        clave?: string;
      };
    };
  };
}

export interface ISolicitud {
  id?: string;
  nombre?: string | null;
  usuario?: string | null;
  solucionId?: string | null;
  estado?: EstadoSolicitud | null;
  revisores?: RevisorResumen[] | null;
  solicitante?: Solicitante;
  SOLICITUD_RIZOMA?: SolicitudRizoma | null;
  vigenciasSni?: IVigenciaDto[];
  reconsideracion?: Reconsideracion | null;
  datosGeneralesAcreditaciones?: IDatosGenerales | null;
  movimiento?: object;
  investigador?: object;
  operadorSelect?: string;
  extemporanea?: boolean;
  oficio?: string;
  estadoDocComprobante?: string;
  estadoDocInforme?: string;
  urlComprobante?: IFile;
  urlInforme?: IFile;
  contenidoBase64?: string;
  estadoFormalizacion?: string;
  ayudante?: object;
  reconocimiento?: string;
  urlConvenio?: string;
  isReconsideracion(): boolean;
  isEmerito(): boolean;
  esExtemporanea(): boolean;
  getInvestigador(): object;
  reconocimiento_reconsideracion?: string;
  reconocimiento_dictaminadora?: string;
  oficio_reconsideracion?: string;
  oficio_dictaminadora?: string;
  urlConvenio_reconsideracion?: string;
  urlConvenio_dictaminadora?: string;
  programasIntegrados?: IProgramaIntegrado[];
  cuota?: Cuota;
  areasConocimiento?: AreaConocimiento[];
  reestructuraciones?: Reestructuracion[];
  lies?: Array<LieSnp>;
  pronace?: PronaceSnp;
  temas?: Array<RegistroTemaSnp>;
  datos_conciliados?: any;
  terminos_aceptados?: boolean;
  dependientes_modificados?: boolean;
  ajustes_solicitados?: boolean;
}

export class Solicitud implements ISolicitud {
  constructor(
    public id?: string,
    public nombre?: string | null,
    public usuario?: string | null,
    public solucionId?: string | null,
    public estado?: EstadoSolicitud | null,
    public revisores?: RevisorResumen[] | null,
    public solicitante?: Solicitante,
    public SOLICITUD_RIZOMA?: any,
    public vigenciasSni?: IVigenciaDto[],
    public reconsideracion?: Reconsideracion,
    public movimiento?: any,
    public investigador?: any,
    public operadorSelect?: string,
    public programa?: any,
    public urlComprobante?: IFile,
    public urlInforme?: IFile,
    public estadoDocComprobante?: string,
    public estadoDocInforme?: string,
    public extemporanea?: boolean,
    public oficio?: string,
    public reconocimiento?: string,
    public urlConvenio?: string,
    public contenidoBase64?: string,
    public estadoFormalizacion?: string,
    public ayudante?: any,
    public reconocimiento_reconsideracion?: string,
    public reconocimiento_dictaminadora?: string,
    public oficio_reconsideracion?: string,
    public oficio_dictaminadora?: string,
    public urlConvenio_reconsideracion?: string,
    public urlConvenio_dictaminadora?: string,
    public cuota?: Cuota,
    public programasIntegrados?: IProgramaIntegrado[],
    public areasConocimiento?: AreaConocimiento[],
    public lies?: Array<LieSnp>,
    public pronace?: PronaceSnp,
    public temas?: Array<RegistroTemaSnp>,
    public reestructuraciones?: Reestructuracion[],
    public datos_conciliados?: any,
    public terminos_aceptados?: boolean,
    public dependientes_modificados?: boolean
  ) {
    this.revisores = this.revisores ? this.revisores : [];
  }

  /**
   * Crea una instancia de Solicitud a partir de un objeto plano.
   * @param data Objeto que contiene los datos de la solicitud.
   */
  static fromObject(data: ISolicitud): Solicitud {
    // agregar transformaciones para los campos que lo requieran
    const transformations = {
      vigenciasSni: (vigenciasSni: IVigenciaDto[]) => vigenciasSni.map(VigenciaDto.fromObject),
      pronace: (pronace: PronaceSnp) => PronaceSnp.fromObject(pronace),
    };
    const solicitud = new Solicitud();

    for (const [key, value] of Object.entries(data)) {
      const transform = transformations[key as keyof ISolicitud];
      solicitud[key] = transform ? transform(value) : value;
    }

    solicitud['nombreCompleto'] = personaUtils.fullName(solicitud.solicitante);

    return solicitud;
  }

  /**
   * Indica si está en reconsideración.
   */
  public isReconsideracion(): boolean {
    return this.reconsideracion != null;
  }

  /**
   * Indica si la solicitud es de tipo Emeritos.
   */
  public isEmerito(): boolean {
    return this.SOLICITUD_RIZOMA?.solucionSolicitud?.tipoSolucion === TipoSolucion.EMERITOS;
  }

  /**
   * Indica si la solicitud contiene la propiedad extemporánea.
   */
  public esExtemporanea(): boolean {
    return this.extemporanea === true;
  }

  /**
   * Se valida si el campo investigador tiene datos en caso de que no
   * se obtendra del campo solicitante.
   */
  public getInvestigador(): object {
    return this.investigador ?? this.solicitante;
  }
}

export interface Reconsideracion {
  uri?: string;
  nombre?: string;
}

export interface Cuota {
  cobra?: boolean;
  esVoluntario?: boolean;
  frecuenciaColegiatura?: { id?: string; nombre?: string };
  frecuenciaInscripcion?: { id?: string; nombre?: string };
  frecuenciaOtraCuota?: { id?: string; nombre?: string };
  montoColegiatura?: number;
  montoInscripcion?: number;
  montoOtra?: number;
  tipoCuota?: string;
}

export interface AreaConocimiento {
  id?: string;
  area?: { id?: string; nombre?: string; clave?: string; version?: string };
  campo?: { id?: string; nombre?: string; clave?: string; version?: string };
  disciplina?: { id?: string; nombre?: string; clave?: string; version?: string };
  subdisciplina?: { id?: string; nombre?: string; clave?: string; version?: string };
  principal?: boolean;
}

export interface Reestructuracion {
  fechaReestructuracion?: string | Date;
  fechaInicioOperacion?: string | Date;
  antecedentesPrograma?: string;
  justificacionReestructuracion?: string;
  razonRecienteCreacion?: string;
}
