var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "row m-1" },
      [
        _c(
          "b-row",
          { staticClass: "align-items-stretch mb-5" },
          _vm._l(_vm.forms, function (form) {
            return _c(
              "b-col",
              {
                key: form.id,
                staticClass: "mt-3",
                attrs: { sm: "12", md: "12", lg: "4" },
              },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "h-100",
                    attrs: { "no-body": "", "bg-variant": "light" },
                  },
                  [
                    _c("b-card-body", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-2 center-felx card-contacto" },
                          [_c("span", { class: `icon-` + form.icon })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("b-card-title", [_vm._v(_vm._s(form.title))]),
                            _vm._v(" "),
                            _c("b-card-subtitle", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.MenuElement." + form.menuName
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-card-text", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(form.description) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-2" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "text-left mb-3" },
                            _vm._l(form.tags, function (tag, index) {
                              return _c(
                                "b-badge",
                                {
                                  key: index,
                                  staticClass: "m-1",
                                  attrs: { pill: "", variant: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(tag) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-auto text-right" }, [
                        _vm.isFormSelected(form)
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-zoom",
                                    attrs: { id: form.id, variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfigurarComponenteModal(
                                          form
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "icon-configuracion",
                                    }),
                                    _vm._v(" "),
                                    _c("b", [
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(_vm.$t("entity.action.config"))
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-zoom",
                                    attrs: { id: form.id, variant: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemoveComponente(form)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "x-circle" },
                                    }),
                                    _vm._v(" "),
                                    _c("b", [
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(_vm.$t("entity.action.remove"))
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("b-icon", {
                                  staticClass: "btn-zoom",
                                  attrs: {
                                    variant: "success",
                                    "font-scale": "2.0",
                                    icon: "plus-square-dotted",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAddComponente(form)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "configComponente",
            attrs: {
              scrollable: "",
              centered: "",
              size: "xl",
              id: "configComponente",
              title: "Configuración de componente",
            },
          },
          [
            _vm.componenteEditable
              ? _c("b-card", [
                  _vm.componenteEditable.formId === "REGISTRAR_DOCUMENTOS" ||
                  _vm.componenteEditable.menuName === "DOCUMENTOS"
                    ? _c(
                        "div",
                        [
                          _vm.componenteEditable
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols": "4",
                                    "label-cols-lg": "2",
                                    label: "Carpeta",
                                  },
                                },
                                [
                                  _c("b-form-radio-group", {
                                    attrs: {
                                      size: "lg",
                                      id: "btn-select-carpeta-id",
                                      options: _vm.carpetasOptions,
                                      name: "btn-select-carpeta",
                                    },
                                    model: {
                                      value:
                                        _vm.componenteEditable.configuracion
                                          .carpeta,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.componenteEditable.configuracion,
                                          "carpeta",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "componenteEditable.configuracion.carpeta",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols": "4",
                                "label-cols-lg": "2",
                                label: "Permitir agregar más documentos",
                              },
                            },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  name: "requiere-confirmacion",
                                  size: "lg",
                                  switch: "",
                                },
                                model: {
                                  value:
                                    _vm.componenteEditable.configuracion
                                      .hasMoreDocuments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.componenteEditable.configuracion,
                                      "hasMoreDocuments",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "componenteEditable.configuracion.hasMoreDocuments",
                                },
                              }),
                              _vm._v(" "),
                              _vm.componenteEditable.configuracion
                                .hasMoreDocuments
                                ? [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-5",
                                        attrs: {
                                          header:
                                            "Configuración para agregar documentos adicionales",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols": "6",
                                              "label-cols-lg": "6",
                                              label:
                                                "Máximo número de archivos",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "number" },
                                              model: {
                                                value:
                                                  _vm.componenteEditable
                                                    .configuracion
                                                    .maxNumberOfDocuments,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.componenteEditable
                                                      .configuracion,
                                                    "maxNumberOfDocuments",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "componenteEditable.configuracion.maxNumberOfDocuments",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols": "6",
                                              "label-cols-lg": "6",
                                              label:
                                                "Tamaño máximo de los archivos",
                                            },
                                          },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                options: _vm.sizeOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.componenteEditable
                                                    .configuracion.maxFileSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.componenteEditable
                                                      .configuracion,
                                                    "maxFileSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "componenteEditable.configuracion.maxFileSize",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols": "6",
                                              "label-cols-lg": "6",
                                              label:
                                                "Tipo de Archivos permitidos",
                                            },
                                          },
                                          [
                                            _c("b-form-checkbox-group", {
                                              staticClass: "mb-3",
                                              attrs: {
                                                options: _vm.mediaTypeOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.componenteEditable
                                                    .configuracion.mediaTypes,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.componenteEditable
                                                      .configuracion,
                                                    "mediaTypes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "componenteEditable.configuracion.mediaTypes",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.componenteEditable
                            ? _c("config-documentos", {
                                model: {
                                  value:
                                    _vm.componenteEditable.configuracion
                                      .documentos,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.componenteEditable.configuracion,
                                      "documentos",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "componenteEditable.configuracion.documentos",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-reglas",
                                  modifiers: { "accordion-reglas": true },
                                },
                              ],
                              attrs: { block: "", variant: "info" },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "list-task" } }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.transicion.businessRules.title"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-roles",
                                  modifiers: { "accordion-roles": true },
                                },
                              ],
                              attrs: { block: "", variant: "info" },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "people" } }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "archeApp.solucion.seccion.autoridad.noRoles"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-reglas",
                            accordion: "reglas",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c("select-transition-elements", {
                                staticClass: "mt-3",
                                attrs: {
                                  id: "reglas-id",
                                  items: _vm.reglas,
                                  "add-tags-column": "",
                                  "add-condition-column": "",
                                },
                                model: {
                                  value:
                                    _vm.componenteEditable.configuracion.reglas,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.componenteEditable.configuracion,
                                      "reglas",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "componenteEditable.configuracion.reglas",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { id: "accordion-roles" } },
                        [
                          _c(
                            "b-card",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "componente-add-roles",
                                    size: "sm",
                                    label: "Roles",
                                    "label-for": "add-roles",
                                  },
                                },
                                [
                                  _c("b-form-checkbox-group", {
                                    attrs: {
                                      id: "add-roles",
                                      options: _vm.rolesAutoridadValues,
                                    },
                                    model: {
                                      value:
                                        _vm.componenteEditable.configuracion
                                          .roles,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.componenteEditable.configuracion,
                                          "roles",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "componenteEditable.configuracion.roles",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCloseConfigurarComponenteModal()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      id: "jhi-confirm-delete-solicitud",
                      "data-cy": "entityConfirmAddAutoridadButton",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSaveConfigurarComponenteModal()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("entity.action.save")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }