var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-alert", { attrs: { show: "", variant: _vm.variant } }, [
    _vm.title
      ? _c("h4", { staticClass: "alert-heading" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _vm._v(" "),
    _vm.description
      ? _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } })
      : _vm._e(),
    _vm._v(" "),
    _vm.footer
      ? _c("div", [
          _c("hr"),
          _vm._v(" "),
          _c("p", {
            staticClass: "mb-0",
            domProps: { innerHTML: _vm._s(_vm.footer) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }