import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  computed: {
    ...mapGetters(['estadoCargaPerfil']),
  },
})
export default class NoAccessModalComponent extends Vue {
  @Prop({ required: true })
  id: string;

  @Prop({ default: true })
  show: boolean;

  @Watch('show')
  public start(show: boolean) {
    if (show) {
      (this.$refs['modal' + this.id] as any).show();
    } else {
      (this.$refs['modal' + this.id] as any).hide();
    }
  }

  public returnToPreviousPage() {
    this.$router.go(-1);
  }
}
