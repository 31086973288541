import { Module } from 'vuex';

export const sidebarStore: Module<any, any> = {
  state: {
    sidebarOpen: false,
  },
  getters: {
    sidebarOpen: state => state.sidebarOpen,
  },
  mutations: {
    setSidebarOpen(state, sidebarOpen) {
      state.sidebarOpen = sidebarOpen;
    },
  },
  actions: {
    closeSidebar(ctx) {
      ctx.commit('setSidebarOpen', false);
    },
    openSidebar(ctx) {
      ctx.commit('setSidebarOpen', true);
    },
    toggleSidebar(ctx) {
      ctx.state.sidebarOpen = !ctx.state.sidebarOpen;
    },
  },
};
