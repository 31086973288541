var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      !_vm.readOnly
        ? _c("buscador-persona", {
            on: { selected: _vm.handleAddParticipante },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTable
        ? _c("b-table", {
            staticClass: "mt-5",
            attrs: {
              items: _vm.participantes,
              fields: _vm.campos,
              "show-empty": "",
              busy: _vm.isFetching,
              "head-variant": "primary",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center text-danger my-2" },
                        [
                          _c("b-spinner", { staticClass: "align-middle" }),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("global.process.load.loading"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "h4 text-center" }, [
                        _vm._v(_vm._s(_vm.$t("entity.data.blank"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(dependenciaId)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          row.item.dependenciaId || !_vm.isSedeDuplicated
                            ? _c("b-icon", {
                                attrs: {
                                  icon: "check2-circle",
                                  scale: "1.5",
                                  variant: "success",
                                },
                              })
                            : _c("b-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  icon: "exclamation-triangle",
                                  title: _vm.$t(
                                    "apeironGwApp.persona.participacion.registro.seleccioneDependencia"
                                  ),
                                  scale: "1.5",
                                  variant: "warning",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(nombre)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            row.item.participante.nombre +
                              " " +
                              row.item.participante.apellidoPaterno +
                              " " +
                              row.item.participante.apellidoMaterno
                          ) +
                          "\n    "
                      ),
                    ]
                  },
                },
                {
                  key: "row-details",
                  fn: function (row) {
                    return [
                      _c(
                        "b-card",
                        { staticClass: "p-5" },
                        [
                          _c("persona-update", {
                            attrs: {
                              duracionOficial: _vm.duracionOficial,
                              readOnly: true,
                            },
                            model: {
                              value: row.item.participante,
                              callback: function ($$v) {
                                _vm.$set(row.item, "participante", $$v)
                              },
                              expression: "row.item.participante",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(acciones)",
                  fn: function (row) {
                    return [
                      !_vm.readOnly
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "outline-success", size: "sm" },
                              on: { click: row.toggleDetails },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("entity.action.detail")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readOnly
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditParticipacion(row.item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("entity.action.edit")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readOnly
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "danger", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePrepareToRemove(row.item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("entity.action.delete")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              636573367
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.totalItems > _vm.itemsPerPage
        ? _c(
            "infinite-loading",
            {
              ref: "infiniteLoading",
              attrs: {
                slot: "append",
                identifier: _vm.infiniteId,
                distance: 20,
                "force-use-infinite-wrapper": ".el-table__body-wrapper",
                spinner: "circles",
              },
              on: { infinite: _vm.loadMore },
              slot: "append",
            },
            [
              _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                _vm._v(_vm._s(_vm.$t("global.process.search.nomore"))),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }, [
                _vm._v(_vm._s(_vm.$t("global.process.search.noresults"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addPersona",
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            scrollable: "",
            size: "xl",
            id: "addPersona",
          },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.form.add"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.participanteToEdit
            ? _c(
                "b-card",
                { ref: "erroresValidacion", staticClass: "p-5" },
                [
                  _vm.showErrores
                    ? _c("message", {
                        staticClass: "mb-5",
                        attrs: {
                          variant: "warning",
                          title: _vm.erroresTitle,
                          description: _vm.erroresDescription,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        show: !this.currentParticipacion.dependenciaId,
                        variant: "warning",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "apeironGwApp.persona.participacion.registro.actualizarDependencia",
                              {
                                sede: _vm.dependenciaSelected.sede,
                                dependencia:
                                  _vm.dependenciaSelected.nombreDependencia,
                              }
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("persona-resumen", {
                    attrs: {
                      persona: _vm.participanteToEdit,
                      showDireccion: false,
                    },
                  }),
                  _vm._v(" "),
                  _c("persona-update", {
                    attrs: { duracionOficial: _vm.duracionOficial },
                    on: { valid: _vm.handleValidForm },
                    model: {
                      value: _vm.participanteToEdit,
                      callback: function ($$v) {
                        _vm.participanteToEdit = $$v
                      },
                      expression: "participanteToEdit",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.isSaving, variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseAddPersonaModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "m-2",
                  attrs: {
                    disabled: _vm.isSaving || !_vm.isFormValid,
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSaveParticipacion()
                    },
                  },
                },
                [
                  _vm.isSaving
                    ? _c("i", [_vm._v(_vm._s(_vm.$t("entity.action.saving")))])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("entity.action.save"))),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmParticipanteRemoveModal",
          attrs: { "header-bg-variant": "primary", size: "xl" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("entity.delete.title")))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body text-center" },
            [
              _c("p", { staticClass: "h3 mb-3" }, [
                _vm._v(_vm._s(_vm.$t("entity.delete.question"))),
              ]),
              _vm._v(" "),
              _vm.participanteToRemove?.participante
                ? _c("persona-resumen", {
                    attrs: {
                      persona: _vm.participanteToRemove.participante,
                      showDireccion: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }