import { AlertCommons } from './alert.commons';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';

export default class AlertService extends AlertCommons {
  public sectionUpdated(instance: Vue, section: string) {
    this.showSuccess(instance, 'global.messages.saved.detail', { section: section });
  }

  public solicitudUpdated(instance: Vue) {
    this.showSuccess(instance, 'archeApp.solicitud.updated');
  }

  public solicitudCreated(instance: Vue) {
    this.showSuccess(instance, 'archeApp.solicitud.created');
  }

  public programaCreated(instance: Vue, clavePrograma: string) {
    this.showSuccess(instance, 'apeironGwApp.programa.created', { param: clavePrograma });
  }

  public programaUpdated(instance: Vue, clavePrograma: string) {
    this.showSuccess(instance, 'apeironGwApp.programa.updated', { param: clavePrograma });
  }

  public revisionSent(instance: Vue) {
    this.showSuccess(instance, 'apeironGwApp.revision.sent');
  }

  public formUpdated(instance: Vue) {
    this.showSuccess(instance, 'apeironGwApp.form.updated');
  }
  public formCreated(instance: Vue) {
    this.showSuccess(instance, 'apeironGwApp.form.created');
  }

  public transitionSuccess(instance: Vue, accion: TipoAccion) {
    this.showSuccess(instance, 'archeApp.solicitud.transition', { param: this.resolveAccion(accion) });
  }

  public resolveAccion(accion: TipoAccion) {
    switch (accion) {
      case TipoAccion.ENVIAR:
        return 'enviada';
      case TipoAccion.APROBAR:
        return 'aprobada';
      case TipoAccion.VALIDAR:
        return 'validada';
      case TipoAccion.RECHAZAR:
        return 'rechazada';
      case TipoAccion.CANCELAR:
        return 'cancelada';
      case TipoAccion.RECLASIFICAR:
        return 'reclasificada';
      case TipoAccion.FORMALIZAR:
        return 'formalizada';
      case TipoAccion.BAJAR:
        return 'dada de baja';
      case TipoAccion.BAJAR_POR_ACUERDO:
        return 'dada de baja por acuerdo';
      case TipoAccion.ACTUALIZAR:
        return 'actualizada';
      case TipoAccion.FINALIZAR:
        return 'finalizada';
      case TipoAccion.REVISAR:
        return 'revisada';
      case TipoAccion.PREASIGNAR:
        return 'preasignada';
      case TipoAccion.ASIGNAR:
        return 'asignada';
      case TipoAccion.ACTIVAR:
        return 'activada';
      default:
        return 'no-info-action';
    }
  }
}
