export enum TipoSolucion {
  AYUDANTES = 'AYUDANTES',
  INGRESO_PERMANENCIA_PROMOCION = 'INGRESO_PERMANENCIA_PROMOCION',
  EMERITOS = 'EMERITOS',
  BECAS_NACIONALES = 'BECAS_NACIONALES',
  BECAS_EXTRANJERAS = 'BECAS_EXTRANJERAS',
  POSGRADO_NACIONAL = 'POSGRADO_NACIONAL',
  POSDOCTORADO = 'POSDOCTORADO',
  CUENTAS_BANCARIAS = 'CUENTAS_BANCARIAS',
  ACREDITACIONES = 'ACREDITACIONES',
  ACREDITACIONES_SIN_INST = 'ACREDITACIONES_SIN_INST',
  INSTITUCIONES = 'INSTITUCIONES',
  REGISTRO_MATRICULA_SNP = 'REGISTRO_MATRICULA_SNP',
  MOVIMIENTOS_SITUACION = 'MOVIMIENTOS_SITUACION',
  ACREDITACIONES_PROGRAMAS = 'ACREDITACIONES_PROGRAMAS',
}
