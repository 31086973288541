import Component from 'vue-class-component';
import { Inject, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { IDocumentoConfiguracion, DocumentoConfiguracion } from '@/shared/model/configuracion/documentos-configuracion.model';
import camelCase from 'camelcase';
import { normalizeSync } from 'normalize-diacritics';

import { Constants } from '@/shared/util/constants';
import ScriptService from '@/shared/script/script.service';

@Component({
  components: {
    draggable,
  },
})
export default class ConfigDocumentos extends Vue {
  @Inject('scriptService') private scriptService: () => ScriptService;

  @Prop({ required: true })
  value: IDocumentoConfiguracion[];

  @Prop({ default: false })
  readonly: boolean;

  public currentIndex = -1;

  public mediaTypeOptions = Constants.MEDIA_TYPE_OPTIONS;

  public cmOptions = ScriptService.CM_OPTIONS;

  public columnSelected = null;

  public currentColumnIndex = -1;

  private reglaDefault = 'return true;';

  public dragOptions = {
    animation: 0,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
  };

  get documentos() {
    return this.value;
  }

  set documentos(documentos: IDocumentoConfiguracion[]) {
    this.$emit('input', documentos);
  }

  public sizeOptions = Constants.FILE_SIZE_OPTIONS;

  public handleAddProperty(documentIndx) {
    const doc = this.documentos[documentIndx];
    doc.nombrePropiedad = camelCase(normalizeSync(doc.nombre));
    this.documentos.splice(documentIndx, 1, doc);
  }

  public handleAddDocumento() {
    const config: DocumentoConfiguracion = new DocumentoConfiguracion();
    config.nombre = '';
    config.requerido = true;
    config.max = 3000000;
    config.mediaTypes.push('pdf');
    config.expresion = this.reglaDefault;
    this.documentos.push(config);
  }

  public handleDelete(index) {
    this.documentos.splice(index, 1);
  }

  public mouseover(index) {
    this.currentIndex = index;
  }

  public isHover(index) {
    return this.currentIndex === index;
  }

  public onCmReady(codemirror) {
    this.scriptService().onCmReady(codemirror);
  }

  public openReglaModal(index) {
    if (<any>this.$refs.reglaModal) {
      this.columnSelected = { ...this.documentos[index] };
      this.columnSelected.expresion = this.columnSelected?.expresion ?? this.reglaDefault;
      this.currentColumnIndex = index;
      (<any>this.$refs.reglaModal).show();
    }
  }

  public handleConfirmation(modalReference): void {
    this.documentos.splice(this.currentColumnIndex, 1, this.columnSelected);
    this.currentColumnIndex = -1;
    this.hideModal(modalReference);
  }

  public handleCancel(modalReference): void {
    this.hideModal(modalReference);
  }

  public hideModal(modalReference) {
    (this.$refs[modalReference] as any).hide();
  }
}
