var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "text-center",
        attrs: {
          items: _vm.transiciones,
          fields: _vm.campos,
          "show-empty": "",
          "head-variant": "primary",
          "caption-top": "",
        },
        scopedSlots: _vm._u([
          {
            key: "table-caption",
            fn: function () {
              return [
                _c("div", { staticClass: "font-weight-bolder h4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("archeApp.solucion.process.transition.title"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("archeApp.solucion.process.transition.subtitle")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.handleOpenAddTransicionModal },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "plus" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("entity.action.add"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "h4 text-center" }, [
                  _vm._v(_vm._s(_vm.$t("entity.data.blank"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(accion)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("apeironGwApp.TipoAccion." + row.item.accion)
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(destino)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("archeApp.EstadoSolicitud." + row.item.destino)
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(mensaje)",
            fn: function (row) {
              return [
                _c("b-icon", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.html.left.v-light",
                      value: row.item.mensaje,
                      expression: "row.item.mensaje",
                      modifiers: {
                        hover: true,
                        html: true,
                        left: true,
                        "v-light": true,
                      },
                    },
                  ],
                  attrs: { icon: "chat-square-dots", "font-scale": "1.5" },
                }),
              ]
            },
          },
          {
            key: "cell(confirmar)",
            fn: function (row) {
              return [
                row.item.confirmar
                  ? _c("b-icon", {
                      attrs: { icon: "check2-square", "font-scale": "1.5" },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(reglas)",
            fn: function (row) {
              return [
                row.item.reglas.length > 0
                  ? _c("b-badge", { attrs: { variant: "danger", pill: "" } }, [
                      _vm._v(" " + _vm._s(row.item.reglas.length) + " "),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(opciones)",
            fn: function (row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { variant: "danger", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.handlePrepareToRemove(row.index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("entity.action.delete"))
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { variant: "success", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEditar(row.index)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("entity.action.edit")))]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addTransicion",
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            scrollable: "",
            size: "xl",
            id: "addTransicion",
          },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("apeironGwApp.transicion.add")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row text-center" }, [
              _c(
                "div",
                { staticClass: "col-5" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "componente-add-accion",
                        size: "sm",
                        label: "Acción",
                        "label-for": "add-accion",
                      },
                    },
                    [
                      _c("b-form-select", {
                        staticClass: "mb-3",
                        attrs: {
                          state: _vm.transicion.accion ? true : false,
                          id: "add-accion",
                          options: _vm.acciones,
                        },
                        model: {
                          value: _vm.transicion.accion,
                          callback: function ($$v) {
                            _vm.$set(_vm.transicion, "accion", $$v)
                          },
                          expression: "transicion.accion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-2" },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c("b-icon", {
                    staticClass: "align-middle",
                    attrs: { icon: "arrow-bar-right", "font-scale": "3" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-5" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "componente-add-destino",
                        size: "sm",
                        label: "Destino",
                        "label-for": "add-destino",
                      },
                    },
                    [
                      _c("b-form-select", {
                        staticClass: "mb-3",
                        attrs: {
                          state: _vm.transicion.destino ? true : false,
                          id: "add-destino",
                          options: _vm.estados,
                        },
                        model: {
                          value: _vm.transicion.destino,
                          callback: function ($$v) {
                            _vm.$set(_vm.transicion, "destino", $$v)
                          },
                          expression: "transicion.destino",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-mensaje",
                              modifiers: { "accordion-mensaje": true },
                            },
                          ],
                          attrs: { block: "", variant: "info" },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "chat-left" } }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.transicion.message.confirm"
                                )
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-mensaje",
                        accordion: "mensaje",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                name: "requiere-confirmacion",
                                switch: "",
                              },
                              model: {
                                value: _vm.transicion.confirmar,
                                callback: function ($$v) {
                                  _vm.$set(_vm.transicion, "confirmar", $$v)
                                },
                                expression: "transicion.confirmar",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.transicion.message.dobleCheckQuestion"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                name: "requiere-agregar-comentario",
                                switch: "",
                              },
                              model: {
                                value: _vm.transicion.agregarComentario,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.transicion,
                                    "agregarComentario",
                                    $$v
                                  )
                                },
                                expression: "transicion.agregarComentario",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.transicion.message.addMessageQuestion"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "componente-add-mensaje",
                                size: "sm",
                                label: "Mensaje de confirmación",
                                "label-for": "mensaje-confirmacion",
                              },
                            },
                            [
                              _c("vue-editor", {
                                attrs: { id: "mensaje-confirmacion" },
                                model: {
                                  value: _vm.transicion.mensaje,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.transicion, "mensaje", $$v)
                                  },
                                  expression: "transicion.mensaje",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-reglas",
                              modifiers: { "accordion-reglas": true },
                            },
                          ],
                          attrs: { block: "", variant: "info" },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "list-task" } }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.transicion.businessRules.title"
                                )
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-reglas",
                        accordion: "reglas",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("select-transition-elements", {
                            staticClass: "mt-3",
                            attrs: {
                              id: "reglas-id",
                              items: _vm.reglas,
                              "add-tags-column": "",
                              "add-condition-column": "",
                            },
                            model: {
                              value: _vm.transicion.reglas,
                              callback: function ($$v) {
                                _vm.$set(_vm.transicion, "reglas", $$v)
                              },
                              expression: "transicion.reglas",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-acciones",
                              modifiers: { "accordion-acciones": true },
                            },
                          ],
                          attrs: { block: "", variant: "info" },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "lightning" } }),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("entity.acciones")) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-acciones",
                        accordion: "acciones",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("select-transition-elements", {
                            staticClass: "mt-3",
                            attrs: { id: "acciones-id", items: _vm.actions },
                            model: {
                              value: _vm.transicion.acciones,
                              callback: function ($$v) {
                                _vm.$set(_vm.transicion, "acciones", $$v)
                              },
                              expression: "transicion.acciones",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-notificaciones",
                              modifiers: { "accordion-notificaciones": true },
                            },
                          ],
                          attrs: { block: "", variant: "info" },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "envelope" } }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.transicion.notificacion.title"
                                )
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-notificaciones",
                        accordion: "notificaciones",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _vm.notificacion.activada
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            name: "activar-notificacion",
                                            size: "lg",
                                            switch: "",
                                          },
                                          model: {
                                            value: _vm.notificacion.activada,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.notificacion,
                                                "activada",
                                                $$v
                                              )
                                            },
                                            expression: "notificacion.activada",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.notificacion.activada
                                                  ? "Desactivar notificación"
                                                  : "Activar notificación"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols": "2",
                                        "label-cols-lg": "1",
                                        label: _vm.$t(
                                          "apeironGwApp.transicion.notificacion.para"
                                        ),
                                      },
                                    },
                                    [
                                      _c("tags", {
                                        attrs: {
                                          id: "notificacion-activada-id",
                                          variant: "primary",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.transicion.notificacion.placeholder.para"
                                          ),
                                          size: "md",
                                          disabled: !_vm.notificacion.activada,
                                        },
                                        model: {
                                          value: _vm.notificacion.para,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificacion,
                                              "para",
                                              $$v
                                            )
                                          },
                                          expression: "notificacion.para",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.variableCorreos,
                                        function (variable, index) {
                                          return _c(
                                            "b-button",
                                            {
                                              key: index,
                                              staticClass: "ml-2 mt-3",
                                              attrs: {
                                                variant: "outline-success",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addCorreoToPara(
                                                    variable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(variable.nombre) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols": "2",
                                        "label-cols-lg": "1",
                                        label: _vm.$t(
                                          "apeironGwApp.transicion.notificacion.cc"
                                        ),
                                      },
                                    },
                                    [
                                      _c("tags", {
                                        attrs: {
                                          id: "notificacion-cc-id",
                                          variant: "primary",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.transicion.notificacion.placeholder.cc"
                                          ),
                                          size: "md",
                                          disabled: !_vm.notificacion.activada,
                                        },
                                        model: {
                                          value: _vm.notificacion.cc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificacion,
                                              "cc",
                                              $$v
                                            )
                                          },
                                          expression: "notificacion.cc",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.variableCorreos,
                                        function (variable, index) {
                                          return _c(
                                            "b-button",
                                            {
                                              key: index,
                                              staticClass: "ml-2 mt-3",
                                              attrs: {
                                                variant: "outline-success",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addCorreoToCc(
                                                    variable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(variable.nombre) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols": "2",
                                        "label-cols-lg": "1",
                                        label: _vm.$t(
                                          "apeironGwApp.transicion.notificacion.cco"
                                        ),
                                      },
                                    },
                                    [
                                      _c("tags", {
                                        attrs: {
                                          id: "notificacion-cco-id",
                                          variant: "primary",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.transicion.notificacion.placeholder.cco"
                                          ),
                                          size: "md",
                                          disabled: !_vm.notificacion.activada,
                                        },
                                        model: {
                                          value: _vm.notificacion.cco,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificacion,
                                              "cco",
                                              $$v
                                            )
                                          },
                                          expression: "notificacion.cco",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.variableCorreos,
                                        function (variable, index) {
                                          return _c(
                                            "b-button",
                                            {
                                              key: index,
                                              staticClass: "ml-2 mt-3",
                                              attrs: {
                                                variant: "outline-success",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addCorreoToCco(
                                                    variable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(variable.nombre) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols": "2",
                                        "label-cols-lg": "1",
                                        label: _vm.$t(
                                          "apeironGwApp.transicion.notificacion.asunto"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          size: "md",
                                          disabled: !_vm.notificacion.activada,
                                        },
                                        model: {
                                          value: _vm.notificacion.asunto,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificacion,
                                              "asunto",
                                              $$v
                                            )
                                          },
                                          expression: "notificacion.asunto",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("vue-editor", {
                                    ref: "mensajeNotificacion",
                                    attrs: { id: "mensaje-notificacion" },
                                    model: {
                                      value: _vm.notificacion.mensaje,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificacion,
                                          "mensaje",
                                          $$v
                                        )
                                      },
                                      expression: "notificacion.mensaje",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.variables,
                                    function (variable, index) {
                                      return _c(
                                        "b-button",
                                        {
                                          key: index,
                                          staticClass: "ml-2 mt-3",
                                          attrs: {
                                            variant: "outline-success",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addVariable(variable)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(variable.nombre) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _c("empty-data", {
                                attrs: {
                                  message: _vm.$t(
                                    "global.messages.alert.notificacionNotActivated"
                                  ),
                                  hint: _vm.$t(
                                    "global.messages.alert.activate"
                                  ),
                                  icon: "bell",
                                },
                                on: {
                                  add: function ($event) {
                                    _vm.notificacion.activada = true
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c("button", {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
              on: {
                click: function ($event) {
                  return _vm.handleCloseAddTransicionModal()
                },
              },
            }),
            _vm._v(" "),
            _vm.transicion.accion && _vm.transicion.destino
              ? _c("button", {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "button",
                    id: "jhi-confirm-delete-solicitud",
                    "data-cy": "entityConfirmAddAutoridadButton",
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("entity.action.save")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAddTransicion()
                    },
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmRemoveModal",
          attrs: { "header-bg-variant": "primary" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("entity.delete.title")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("entity.delete.question")))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }