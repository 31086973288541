var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: "modal" + _vm.id,
      attrs: {
        centered: "",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "header-bg-variant": "primary",
        id: "modal" + _vm.id,
        size: "lg",
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("error.userLoadFailure.title")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "row text-center" }, [
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("b-icon", {
                attrs: {
                  variant: "info",
                  animation: "fade",
                  "font-scale": "5",
                  icon: "person-bounding-box",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col" }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mt-3" },
          [
            _c("b-alert", { attrs: { show: "", variant: "info" } }, [
              _c("p", {
                staticClass: "text-justify",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("error.userLoadFailure.description")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "text-justify",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("error.userLoadFailure.hint")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.returnToPreviousPage()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "arrow-left-circle", scale: "1" },
                      }),
                      _vm._v(_vm._s(_vm.$t("entity.action.return"))),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }