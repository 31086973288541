var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.showBreadCrumb
        ? _c("b-breadcrumb", {
            attrs: { items: _vm.breadcrumbItems },
            on: { click: _vm.onClick },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }