import { TipoLayout } from '@/shared/model/enumerations/tipo-layout.model';
/**
 * Catálogos utilizados en el registro de situaciones
 */

export class Catalogo {
  static MOVIMIENTOS = [
    {
      id: 161,
      clave: 'INV_X_MEX',
      desc: 'Investigadoras e investigadores por México',
    },
    {
      id: 162,
      clave: 'EST_POSDOC',
      desc: 'Estancias posdoctorales',
    },
    {
      id: 1,
      clave: 1,
      desc: 'Pago de estímulo',
    },
    {
      id: 6,
      clave: 'EXT-QUINCE-ANIOS',
      desc: '65 años de edad y 15 años en el SNI',
    },
    {
      id: 7,
      clave: 'EXT-MIEMBRO-COM',
      desc: 'Haber sido miembro de comisión dictaminadora',
    },
    {
      id: 8,
      clave: 'EXT-EMBARAZO',
      desc: 'Maternidad o Paternidad',
    },
    {
      id: 9,
      clave: 'EXT-RENUNCIA-PERIODO',
      desc: 'Renuncia al periodo ampliado',
    },
    {
      id: 70,
      clave: 'PAGOAYU',
      desc: 'Pago a ayudantes',
    },
    {
      id: 11,
      clave: 'CGOADMV',
      desc: 'Cargo administrativo fuera de su institución',
    },
    {
      id: 12,
      clave: 'CGOELEC',
      desc: 'Cargo de elección popular',
    },
    {
      id: 13,
      clave: 'CATCONA',
      desc: 'Catedrático CONACYT',
    },
    {
      id: 14,
      clave: 'COMCSDO',
      desc: 'Comisión o licencia con goce de sueldo completo',
    },
    {
      id: 15,
      clave: 'COMPSDO',
      desc: 'Comisión o licencia con goce parcial de sueldo',
    },
    {
      id: 16,
      clave: 'CTOTDET',
      desc: 'Personal activo y vigente por tiempo determinado',
    },
    {
      id: 17,
      clave: 'CTOTIND',
      desc: 'Personal activo y vigente por tiempo indeterminado',
    },
    {
      id: 19,
      clave: 'CURLICE',
      desc: 'Cursos licenciatura',
    },
    {
      id: 22,
      clave: 'INCPERM',
      desc: 'Incapacidad permanente',
    },
    {
      id: 23,
      clave: 'JUBILA',
      desc: 'Jubilación',
    },
    {
      id: 24,
      clave: 'LICSSDO',
      desc: 'Comisión o licencia sin goce de sueldo',
    },
    {
      id: 25,
      clave: 'NOFCNV',
      desc: 'Sin convenio firmado con CONACYT',
    },
    {
      id: 26,
      clave: 'PERSABA',
      desc: 'Periodo sabático',
    },
    {
      id: 27,
      clave: 'SINADSC',
      desc: 'Sin institución',
    },
    {
      id: 28,
      clave: 'REGISTRO_BENEFICIARIOS',
      desc: 'Registro de beneficiarios',
    },
    {
      id: 50,
      clave: 'CURSOS',
      desc: 'Cursos',
    },
    {
      id: 51,
      clave: 'PAGO-BENEFICIARIO-RECURRENTE',
      desc: 'Pago al beneficiario recurrente',
    },
    {
      id: 52,
      clave: 'PAGO-BENEFICIARIO-UNICA-EXHIBICION',
      desc: 'Pago a beneficiarios única exhibición',
    },
    {
      id: 53,
      clave: 'PAGO-PENSIONISTAS-MONTO',
      desc: 'Pago a pensionistas monto',
    },
    {
      id: 54,
      clave: 'PAGO-PENSIONISTAS-PORCENTAJE',
      desc: 'Pago a pensionistas porcentaje',
    },
    {
      id: 55,
      clave: 'INCAPACIDAD-PERMANENTE',
      desc: 'Incapacidad permanente',
    },
    {
      id: 56,
      clave: 'RECHAZO-TESOFE',
      desc: 'Rechazo TESOFE',
    },
    {
      id: 30,
      clave: 'CGOSEGUR',
      desc: 'Cargo por Seguros',
    },
    {
      id: 110,
      clave: 'MODVIG-RENUNCIA-DISTINCION',
      desc: 'Renuncia a la distinción',
    },
    {
      id: 111,
      clave: 'MODVIG-BAJA-JUNTA-HONOR',
      desc: 'Baja por Junta de Honor',
    },
    {
      id: 112,
      clave: 'MODVIG-SITUACION-EMERITO-SNI',
      desc: 'Situación del Emérito ante SNI',
    },
    {
      id: 90,
      clave: 'CGOPAGEX',
      desc: 'Cargo por pago de notificación extemporánea SNI',
    },
    {
      id: 130,
      clave: 'CGOPAGEX-AUTO',
      desc: 'Cargo por pago de notificación extemporánea SIN - Auto',
    },
    {
      id: 131,
      clave: 'PAGO-ESTIMULO-EXTRA',
      desc: 'Pago de estí­mulo 1/3 de salario candidato',
    },
    {
      id: 163,
      clave: 'BAJXVIG',
      desc: 'Baja del SNI por fin de vigencia de la distinción estatus del investigador tras ser aprobado',
    },
    {
      id: 164,
      clave: 'BAJXJUN',
      desc: 'Baja del SNI por Junta de Honor',
    },
    {
      id: 165,
      clave: 'MEXEXTR',
      desc: 'Integrante mexicano en el extranjero',
    },
    {
      id: 81,
      clave: 'BAJINST',
      desc: 'Baja de la institución',
    },
    {
      id: 21,
      clave: 'FALLEC',
      desc: 'Baja por fallecimiento',
    },
    {
      id: 41,
      clave: 'PAGO-ESTIMULO-RETROACTIVO',
      desc: 'Pago de estí­mulo retroactivo',
    },
    {
      id: 101,
      clave: 'ESPOSDOC',
      desc: 'Estancia posdoctoral',
    },
    {
      id: 102,
      clave: 'ESINVEST',
      desc: 'Estancia de investigación',
    },
    {
      id: 61,
      clave: 'CGOADMVINS',
      desc: 'Cargo administrativo en su misma institución',
    },
    {
      id: 205,
      clave: 'AYUDANTE-REGISTRO',
      desc: 'Pago de ayudantía',
    },
    {
      id: 206,
      clave: 'AYUDANTE-RECLAS',
      desc: 'Reclasificación de ayudante',
    },
    {
      id: 207,
      clave: 'AYUDANTE-RENOV',
      desc: 'Renoviación de ayudante',
    },
    {
      id: 208,
      clave: 'AYUDANTE-SUSPENS',
      desc: 'Suspensión de ayudante',
    },
    {
      id: 209,
      clave: 'EXT-SITUACION-FAMILIAR',
      desc: 'Situación familiar grave',
    },
    {
      id: 210,
      clave: 'EXT-ENFERMEDAD-GRAVE',
      desc: 'Enfermedad grave',
    },
    {
      id: 211,
      clave: 'EXT-CARGO-ADM-FUERA',
      desc: 'Finalizó Cargo administrativo fuera de su institución',
    },
    {
      id: 212,
      clave: 'EXT-CARGO-ELEC-POP',
      desc: 'Finalizó Cargo de elección Popular',
    },
    {
      id: 142,
      clave: 'REDUC-TERMINACION',
      desc: 'Terminación anticipada',
    },
    {
      id: 121,
      clave: 'EXT-REGLAMENTO',
      desc: 'Extensión por reglamento',
    },
  ];

  static SITUACIONES = [
    {
      id: 1,
      value: 'Baja de la institución',
    },
    {
      id: 2,
      value: 'Jubilación',
    },
    {
      id: 3,
      value: 'Comisión o licencia con goce de sueldo',
    },
    {
      id: 4,
      value: 'Comisión o licencia con goce parcial de sueldo',
    },
    {
      id: 5,
      value: 'Personal por tiempo determinado',
    },
    {
      id: 6,
      value: 'Personal por tiempo indeterminado',
    },
    {
      id: 7,
      value: 'Baja por fallecimiento',
    },
    {
      id: 8,
      value: 'Cargo administrativo',
    },
    {
      id: 9,
      value: 'Estancias posdoctorales',
    },
    {
      id: 10,
      value: 'Investigadoras e investigadores por México',
    },
  ];

  static PERIODICIDAD = [
    {
      id: 267,
      clave: 'PR',
      desc: 'Recurrente',
    },
    {
      id: 268,
      clave: 'PU',
      desc: 'Único',
    },
  ];
  static APLICA_PAGO = [
    {
      id: 1,
      clave: 'SI',
      desc: 'Sí',
    },
    {
      id: 2,
      clave: 'NO',
      desc: 'No',
    },
  ];
  static TIPO_PARTICIPACION = [
    {
      id: 1,
      value: 'Fortalecimiento y consolidación de la comunidad',
    },
    {
      id: 2,
      value: 'Promoción del acceso universal al conocimiento humanístico o científico y a sus beneficios sociales',
    },
    {
      id: 3,
      value: 'Investigación en ciencia básica o de frontera en alguna de las áreas y campos del saber científico',
    },
    {
      id: 4,
      value: 'Desarrollo de tecnologías estratégicas de vanguardia e innovación abierta',
    },
    {
      id: 5,
      value: 'Investigación con incidencia en la atención de problemáticas nacionales',
    },
  ];

  static TIPO_VIGENCIAS_SNI = [
    { id: 2, clave: '1', homoclave: 'EXT-EMBARAZO', desc: 'EXTENSIÓN POR MATERNIDAD O PATERNIDAD' },
    { id: 4, clave: '3', homoclave: 'EXT-QUINCE-ANIOS', desc: 'EXTENSIÓN 15 AÑOS' },
    { id: 13, clave: '12', homoclave: 'EXT-MIEMBRO-COM', desc: 'EXTENSIÓN POR MIEMBRO DE COMISIÓN' },
    { id: 42, clave: '43', homoclave: 'EXT-ENFERMEDAD-GRAVE', desc: 'EXTENSIÓN POR ENFERMEDAD GRAVE' },
    { id: 43, clave: '44', homoclave: 'EXT-SITUACION-FAMILIAR', desc: 'EXTENSIÓN POR SITUACIÓN FAMILIAR GRAVE' },
    { id: 61, clave: '62', homoclave: 'EXT-REGLAMENTO', desc: 'EXTENSIÓN POR REGLAMENTO' },
    { id: 82, clave: '83', homoclave: 'REDUC-TERMINACION', desc: 'TERMINACIÓN ANTICIPADA' },
    {
      id: 23,
      clave: '24',
      homoclave: 'OCUPA-CARGO-ADMINISTRATIVO-DE-ELECCION-POPULAR',
      desc: 'CARGO ADMINISTRATIVO O DE ELECCIÓN POPULAR',
    },
    {
      id: 22,
      clave: '23',
      homoclave: 'FINALIZO-CARGO-ADMINISTRATIVO-DE-ELECCION-POPULAR',
      desc: 'FINALIZÓ CARGO ADMINISTRATIVO O DE ELECCIÓN POPULAR',
    },
    { id: 9, clave: '8', homoclave: 'FALLEC', desc: 'BAJA POR FALLECIMIENTO' },
    { id: 51, clave: '52', homoclave: 'INCPERM', desc: 'INCAPACIDAD PERMANENTE' },
  ];

  static TIPO_EXCEPCIONES_SNI = [
    { id: 550, clave: 'ADEUDO', homoclave: 'ADEUDO', desc: 'ADEUDO' },
    { id: 551, clave: 'SANCION', homoclave: 'SANCION', desc: 'SANCIÓN' },
    { id: 552, clave: 'JUICIOS', homoclave: 'JUICIOS', desc: 'JUICIOS DE AMPARO' },
    { id: 553, clave: 'EMEXCEP', homoclave: 'EMEXCEP', desc: 'EMÉRITOS Y 65 + 15' },
  ];

  static situacionesWithoutParticipacion(): Array<any> {
    return Catalogo.SITUACIONES.filter(situacion => situacion.id == 1 || situacion.id == 2 || situacion.id == 7).map(
      situacion => situacion.value
    );
  }

  static situacionesWithoutFechaFin(): Array<any> {
    return Catalogo.SITUACIONES.filter(situacion => situacion.id == 1 || situacion.id == 2 || situacion.id == 7 || situacion.id == 6).map(
      situacion => situacion.value
    );
  }

  static situacionesWithoutFechaFinProgramas(): Array<any> {
    return Catalogo.SITUACIONES.filter(situacion => situacion.id == 10).map(situacion => situacion.value);
  }

  static movimientosAsOptions(): Array<any> {
    return Catalogo.MOVIMIENTOS.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }
  static periodicidadAsOptions(): Array<any> {
    return Catalogo.PERIODICIDAD.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }
  static aplicaPagoAsOptions(): Array<any> {
    return Catalogo.APLICA_PAGO.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }
  static situacionesValueOptions(tipo?: TipoLayout): Array<any> {
    if (tipo && tipo === TipoLayout.INSTITUCIONES) {
      return Catalogo.SITUACIONES.filter(situacion => situacion.id != 9 && situacion.id != 10).map(situacion => {
        return { value: situacion.value, text: situacion.value, disabled: false };
      });
    } else {
      return Catalogo.SITUACIONES.map(situacion => {
        return { value: situacion.value, text: situacion.value, disabled: false };
      });
    }
  }

  static aplicaPagoAsValueOptions(): Array<any> {
    return Catalogo.APLICA_PAGO.map(situacion => {
      return { value: situacion.desc, text: situacion.desc, disabled: false };
    });
  }
  static tipoParticipacionAsValueOptions(): Array<any> {
    return Catalogo.TIPO_PARTICIPACION.map(tipoParticipacion => {
      return { value: tipoParticipacion.value, text: tipoParticipacion.value, disabled: false };
    });
  }

  static tiExcepcionesSniAsOptions(): Array<any> {
    return Catalogo.TIPO_EXCEPCIONES_SNI.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }

  static tipoVigenciasSniAsOptions(): Array<any> {
    return Catalogo.TIPO_VIGENCIAS_SNI.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }

  static INSTITUCIONES = [
    {
      id: '253068',
      nombre: 'UNIVERSIDAD VERACRUZANA',
      estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
      tipoNivel: { id: 1, descripcion: 'Pública' },
      cargo: [
        {
          cvu: 19423,
          nombre: 'ROBERTO',
          primerApellido: 'ZENTENO',
          segundoApellido: 'CUEVAS',
          nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
          rol: { id: 1, descripcion: 'Autoridad Facultada' },
          email: 'robzencue@gmail.com',
        },
        {
          cvu: 438766,
          nombre: 'JESUS',
          primerApellido: 'SANCHEZ',
          segundoApellido: 'OREA',
          rol: { id: 3, descripcion: 'Enlace institucional' },
          email: 'jesanchez@uv.mx',
        },
      ],
      dependencia: [
        {
          id: 87721,
          nombre: 'UNIVERSIDAD VERACRUZANA REGION POZA RICA-TUXPAN',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              nombreCargo: '.',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [
            {
              id: 15133,
              nombre: 'CENTRO DE IDIOMAS Y DE AUTOACCESO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15498,
              nombre: 'FACULTAD DE PEDAGOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15188,
              nombre: 'FACULTAD DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15190,
              nombre: 'UNIDAD DE SERV.BIBLIOTECARIOS Y DE INFORMACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15165,
              nombre: 'COORDINACION REGIONAL DE DIFUSION CULTURAL Y EXT.UNIV.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15189,
              nombre: 'TALLER LIBRE DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15168,
              nombre: 'VICE-RECTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15115,
              nombre: 'SECRETARIA ACADEMICA REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15066,
              nombre: 'COORDINACION ACADEMICA REGIONAL DE ENSEÑANZA ABIERTA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15191,
              nombre: 'SECRETARIA DE ADMINISTRACION Y FINANZAS REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15134,
              nombre: 'COORDINACION REGIONAL DE ACTIVIDADES DEPORTIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15482,
              nombre: 'FACULTAD DE INGENIERIA CIVIL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15038,
              nombre: 'FACULTAD DE INGENIERIA MECANICA ELECTRICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15438,
              nombre: 'FACULTAD DE ARQUITECTURA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15135,
              nombre: 'COORDINACION REGIONAL AREA DE FORMACION BASICA GENERAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15399,
              nombre: 'FACULTAD DE MEDICINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15091,
              nombre: 'FACULTAD DE INGENIERIA ELECTRONICA Y COMUNICACIONES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15400,
              nombre: 'FACULTAD DE PSICOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15451,
              nombre: 'FACULTAD DE CONTADURIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15039,
              nombre: 'FACULTAD DE CIENCIAS BIOLOGICAS Y AGROPECUARIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15211,
              nombre: 'COORDINACION REGIONAL DE BIBLIOTECAS POZA R-TUXPAM',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15513,
              nombre: 'FACULTAD DE ODONTOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15398,
              nombre: 'FACULTAD DE TRABAJO SOCIAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15092,
              nombre: 'COORDINACION REGIONAL DE T.I. POZA RICA TUXPAN',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15166,
              nombre: 'FACULTAD DE CIENCIAS QUIMICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
          ],
        },
        {
          id: 87669,
          nombre: 'UNIDAD MEDICA DE ALTA ESPECIALIDAD H. E.NO. 14 CENTRO MEDICO NACIONAL "ADOLFO RUIZ CORTINES"',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [],
        },
        {
          id: 87719,
          nombre: 'UNIVERSIDAD VERACRUZANA REGION XALAPA',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              nombreCargo: '.',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [
            {
              id: 15215,
              nombre: 'DIRECCION GENERAL DEL AREA ACADEMICA DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15029,
              nombre: 'TALLER LIBRE DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15121,
              nombre: 'FACULTAD DE DERECHO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15042,
              nombre: 'DIRECCION GENERAL DEL AREA ACADEMICA DE HUMANIDADES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15103,
              nombre: 'FACULTAD DE INGENIERIA CIVIL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15217,
              nombre: 'DIRECCION GENERAL DE INVESTIGACIONES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15155,
              nombre: 'FACULTAD DE FILOSOFIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15104,
              nombre: 'FACULTAD DE IDIOMAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15220,
              nombre: 'INSTITUTO DE CIENCIAS DE LA SALUD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15096,
              nombre: 'INSTITUTO DE INVESTIGACIONES Y ESTUDIOS SUPERIORES DE LAS CIENCIAS ADMINISTRATIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15078,
              nombre: 'FACULTAD DE ESTADISTICA E INFORMATICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  nombreCargo: '.',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15053,
              nombre: 'FACULTAD DE INGENIERIA MECANICA Y ELECTRICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15145,
              nombre: 'INSTITUTO DE INVESTIGACIONES EN EDUCACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15199,
              nombre: 'INSTITUTO DE INVESTIGACIONES Y ESTUDIOS SUPERIORES ECONOMICOS Y SOCIALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15205,
              nombre: 'ESCUELA PARA ESTUDIANTES EXTRANJEROS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15124,
              nombre: 'FACULTAD DE TEATRO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15055,
              nombre: 'FACULTAD DE ARTES PLASTICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15046,
              nombre: 'INSTITUTO DE INVESTIGACIONES EN INTELIGENCIA ARTIFICIAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15123,
              nombre: 'FACULTAD DE ECONOMIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15206,
              nombre: 'SECRETARIA ACADEMICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15180,
              nombre: 'MUSEO DE ANTROPOLOGIA DE XALAPA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15142,
              nombre: 'DIRECCION GENERAL DE VINCULACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15193,
              nombre: 'DIRECCION GENERAL DE RELACIONES INTERNACIONALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15194,
              nombre: 'DIRECCION GENERAL DEL AREA ACADEMICA ECON-ADMVA.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15070,
              nombre: 'DIRECCION GENERAL DE DESARROLLO ACADEMICO E INNOVACION EDUCATIVA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15120,
              nombre: 'CENTRO DE ESTUDIOS DE LA CULTURA Y LA COMUNICACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15197,
              nombre: 'INSTITUTO DE QUIMICA APLICADA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15179,
              nombre: 'INSTITUTO DE SALUD PUBLICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15147,
              nombre: 'INSTITUTO DE PSICOLOGIA Y EDUCACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15047,
              nombre: 'INSTITUTO DE INVESTIGACIONES PSICOLOGICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15154,
              nombre: 'FACULTAD DE FISICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15204,
              nombre: 'FACULTAD DE CIENCIAS ADMINISTRATIVAS Y SOCIALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15081,
              nombre: 'COORDINACION REGIONAL DE BIBLIOTECAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15122,
              nombre: 'CENTRO DE ESTUDIOS DE JAZZ (JAZZUV)',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15076,
              nombre: 'FACULTAD DE HISTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15098,
              nombre: 'CENTRO DE ESTUDIOS DE GENERO DE LA U.V.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15146,
              nombre: 'INSTITUTO DE INVESTIGACIONES EN CONTADURIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15149,
              nombre: 'CENTRO DE INVESTIGACIONES BIOMEDICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15051,
              nombre: 'CENTRO DE INICIACION MUSICAL INFANTIL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15052,
              nombre: 'FACULTAD DE LETRAS ESPAÑOLAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15054,
              nombre: 'FACULTAD DE BIOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15185,
              nombre: 'RECTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15183,
              nombre: 'DEPARTAMENTO DE FISICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15026,
              nombre: 'CENTRO DE INVESTIGACION Y DESARROLLO DE ALIMENTOS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15117,
              nombre: 'DIRECCION GENERAL DEL AREA ACADEMICA TECNICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15219,
              nombre: 'INSTITUTO DE ANTROPOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15102,
              nombre: 'FACULTAD DE ARQUITECTURA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15200,
              nombre: 'CENTRO DE ESTUDIOS DE OPINION Y ANALISIS DE LA U.V.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15027,
              nombre: 'CENTRO DE INVESTIGACIONES TROPICALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15028,
              nombre: 'TALLER DE LAUDERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15159,
              nombre: 'SECRETARIA DE DESARROLLO INSTITUCIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15156,
              nombre: 'FACULTAD DE SOCIOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15181,
              nombre: 'FACULTAD DE PEDAGOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15178,
              nombre: 'INSTITUTO DE ARTES PLASTICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15057,
              nombre: 'FACULTAD DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15116,
              nombre: 'DIRECCION GENERAL DEL SISTEMA DE ENSEÑANZA ABIERTA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15201,
              nombre: 'FACULTAD DE MATEMATICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15202,
              nombre: 'FACULTAD DE INSTRUMENTACION ELECTRONICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15184,
              nombre: 'FACULTAD DE BIOANALISIS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15043,
              nombre: 'DIRECCION GENERAL DEL AREA ACADEMICA DE CIENCIAS DE LA SALUD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15174,
              nombre: 'DIRECCION GENERAL DE COMUNICACION UNIVERSITARIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15214,
              nombre: 'DIRECCION DEL AREA ACADEMICA DE CIENCIAS BIOLOGICAS Y AGROPECUARIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15177,
              nombre: 'INSTITUTO DE BIOTECNOLOGIA Y ECOLOGIA APLICADA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15158,
              nombre: 'FACULTAD DE NUTRICION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15173,
              nombre: 'DIRECCION DE LOS CENTROS DE IDIOMAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15148,
              nombre: 'INSTITUTO DE NEUROETOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15073,
              nombre: 'CENTRO DE INVESTIGACION EN MICOLOGIA APLICADA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15058,
              nombre: 'SECRETARIA DE ADMINISTRACION Y FINANZAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15044,
              nombre: 'DIRECCION GENERAL DE DIFUSION CULTURAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15048,
              nombre: 'INSTITUTO DE INVESTIGACIONES BIOLOGICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15119,
              nombre: 'INSTITUTO DE INVESTIGACIONES LINGÜISTICO LITERARIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15157,
              nombre: 'COORDINACION DE LA UNIDAD DE GENERO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15143,
              nombre: 'INSTITUTO DE CIENCIAS BASICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15075,
              nombre: 'CENTRO DE INVESTIGACIO E INNOVACION EN EDUCACION SUPERIOR',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15099,
              nombre: 'CENTRO DE ESTUDIOS CHINA-VERACRUZ',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15196,
              nombre: 'DIRECCION GENERAL DE RECURSOS FINANCIEROS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15216,
              nombre: 'DIRECCION GENERAL DE RECURSOS HUMANOS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15118,
              nombre: 'INSTITUTO DE INVESTIGACIONES CEREBRALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15105,
              nombre: 'FACULTAD DE PSICOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15077,
              nombre: 'FACULTAD DE CONTADURIA Y ADMINISTRACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15151,
              nombre: 'FACULTAD DE CIENCIAS QUIMICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15153,
              nombre: 'FACULTAD DE QUIMICA FARMACEUTICA BIOLOGICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15152,
              nombre: 'CENTRO PARA EL DESARROLLO HUMANO E INTEGRAL DE LOS UNIVERSITARIOS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15079,
              nombre: 'FACULTAD DE MUSICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15106,
              nombre: 'FACULTAD DE DANZA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15198,
              nombre: 'INSTITUTO DE FILOSOFIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15175,
              nombre: 'DIRECCION DEL AREA DE FORMACION BASICA GENERAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15080,
              nombre: 'COORDINACION UNIVERSITARIA DE OBSERVATORIOS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15141,
              nombre: 'DIRECCION GENERAL DE LA UNIDAD DE ESTUDIOS DE POSGRADO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15056,
              nombre: 'FACULTAD DE ODONTOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15071,
              nombre: 'INSTITUTO DE INVESTIGACIONES JURIDICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15045,
              nombre: 'DIRECCION GENERAL DE LA UNIVERSIDAD VIRTUAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15144,
              nombre: 'INSTITUTO DE INVESTIGACIONES HISTORICO-SOCIALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15218,
              nombre: 'DIRECCION GENERAL DE ADMINISTRACION ESCOLAR',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15101,
              nombre: 'CENTRO DE INVESTIGACIONES EN DOCUMENTACION SOBRE LA UNIVERSIDAD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15050,
              nombre: 'CENTRO DE IDIOMAS Y DE AUTOACCESO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15097,
              nombre: 'CENTRO DE ESTUDIOS SOBRE DERECHO GLOBALIZACION Y SEGURIDAD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15082,
              nombre: 'UNIVERSIDAD VERACRUZANA INTERCULTURAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15100,
              nombre: 'CENTRO DE ESTUDIOS E INVESTIGACION EN CONOCIMIENTO Y APRENDIZAJE HUMANO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15069,
              nombre: 'DIRECCION GENERAL DE INFORMATICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15481,
              nombre: 'FACULTAD DE MEDICINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15182,
              nombre: 'FACULTAD DE ANTROPOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15074,
              nombre: 'CENTRO DE ECOALFABETIZACION Y DIALOGO DE SABERES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15049,
              nombre: 'CENTRO DE CIENCIAS DE LA TIERRA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15072,
              nombre: 'INSTITUTO DE INVESTIGACIONES FORESTALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15213,
              nombre: 'DIRECCION GENERAL DE TECNOLOGIA DE INFORMACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15195,
              nombre: 'DIRECCION GENERAL DE BIBLIOTECAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15150,
              nombre: 'CENTRO DE ESTUDIOS CREACION Y DOCUMENTACION DE LAS ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15176,
              nombre: 'DIRECCION DE ACTIVIDADES DEPORTIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15203,
              nombre: 'FACULTAD DE CIENCIAS AGRICOLAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
          ],
        },
        {
          id: 87720,
          nombre: 'UNIVERSIDAD VERACRUZANA REGION VERACRUZ',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              nombreCargo: '.',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [
            {
              id: 15107,
              nombre: 'CENTRO DE IDIOMAS Y DE AUTOACCESO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15083,
              nombre: 'INSTITUTO DE INVESTIGACIONES MEDICO BIOLOGICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15128,
              nombre: 'FACULTAD DE INGENIERIA ELECTRICA Y ELECTRONICA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15125,
              nombre: 'CENTRO DE ESTUDIO Y SERVICIOS EN SALUD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15207,
              nombre: 'FACULTAD DE BIOANALISIS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15108,
              nombre: 'FACULTAD DE INGENIERIA DE LA CONSTRUCCION Y EL HABITAT',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15035,
              nombre: 'SECRETARIA DE ADMINISTRACION Y FINANZAS REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15084,
              nombre: 'FACULTAD DE CIENCIAS Y TECNICAS DE LA COMUNICACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15109,
              nombre: 'COORDINACION REGIONAL DE DIFUSION CULTURAL Y EXT.UNIV.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15493,
              nombre: 'FACULTAD DE ODONTOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15110,
              nombre: 'UNIDAD DE SERVICIOS BIBLIOTECARIOS DE LA USBI VER.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15130,
              nombre: 'COORDINACION ACADEMICA REGIONAL DE ENSEÑANZA ABIERTA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15467,
              nombre: 'FACULTAD DE MEDICINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15395,
              nombre: 'CENTRO DE INICIACION MUSICAL INFANTIL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15394,
              nombre: 'INSTITUTO DE MEDICINA FORENSE',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15086,
              nombre: 'COORDINACION REGIONAL AREA DE FORMACION BASICA GENERAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15059,
              nombre: 'FACULTAD DE NUTRICION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15061,
              nombre: 'UNIDAD ACADEMICA DE CIENCIAS DE LA SALUD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15034,
              nombre: 'COORDINACION REGIONAL BIBLIOTECA VERACRUZ',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15060,
              nombre: 'TALLER LIBRE DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15087,
              nombre: 'SECRETARIA ACADEMICA REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15129,
              nombre: 'FACULTAD DE EDUCACION FISICA DEPORTE Y RECREACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15032,
              nombre: 'FACULTAD DE MEDICINA VETERINARIA Y ZOOTECNIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15492,
              nombre: 'FACULTAD DE CONTADURIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15030,
              nombre: 'INSTITUTO DE INGENIERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15160,
              nombre: 'INSTITUTO DE CIENCIAS MARINAS Y PESQUERIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15126,
              nombre: 'FACULTAD DE PSICOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15161,
              nombre: 'FACULTAD DE INGENIERIA MECANICA Y CIENCIAS NAVALES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15186,
              nombre: 'CENTRO DE INVESTIGACION EN MICRO Y NANOTECNOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15031,
              nombre: 'FACULTAD DE ADMINISTRACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15085,
              nombre: 'COORDINACION REGIONAL DE ACTIVIDADES DEPORTIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15162,
              nombre: 'VICE-RECTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15127,
              nombre: 'FACULTAD DE INGENIERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15208,
              nombre: 'COORDINACION REGIONAL DE T.I. VERACRUZ',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15396,
              nombre: 'FACULTAD DE PEDAGOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15508,
              nombre: 'FACULTAD DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15033,
              nombre: 'FACULTAD DE CIENCIAS QUIMICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
          ],
        },
        {
          id: 87718,
          nombre: 'UNIVERSIDAD VERACRUZANA REGION CORDOBA-ORIZABA',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              nombreCargo: '.',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [
            {
              id: 15131,
              nombre: 'INSTITUTO DE PSICOLOGIA Y EDUCACION(CEEORI)',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15209,
              nombre: 'COORDINACION REGIONAL DE ACTIVIDADES DEPORTIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15435,
              nombre: 'FACULTAD DE ARQUITECTURA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15164,
              nombre: 'COORDINACION ACADEMICA REG. DE ENSEÑANZA ABIERTA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15111,
              nombre: 'FACULTAD DE NEGOCIOS Y TECNOLOGIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15036,
              nombre: 'COORDINACION REGIONAL DE T.I. ORIZABA CORDOBA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15187,
              nombre: 'CENTRO UNIVERSITARIO PARA LAS ARTES, LA CIENCIA Y LA CULTURA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15064,
              nombre: 'CENTRO DE IDIOMAS Y DE AUTOACCESO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15448,
              nombre: 'CENTRO DE IDIOMAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15065,
              nombre: 'SECRETARIA ACADEMICA REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15037,
              nombre: 'SECRETARIA DE ADMINISTRACION Y FINANZAS REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15468,
              nombre: 'FACULTAD DE ODONTOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15089,
              nombre: 'COORDINACION REGIONAL AREA DE FORMACION BASICA GENERAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15090,
              nombre: 'COORDINACION REGIONAL DE BIBLIOTECAS ORIZABA-CORD.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15210,
              nombre: 'FACULTAD DE CIENCIAS QUIMICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15112,
              nombre: 'VICE-RECTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15062,
              nombre: 'INSTITUTO DE PSICOLOGIA Y EDUCACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15434,
              nombre: 'FACULTAD DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15163,
              nombre: 'COORDINACION REG. DE DIFUSION CULTURAL Y EXT.UNIV.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15449,
              nombre: 'FACULTAD DE MEDICINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15088,
              nombre: 'FACULTAD DE CIENCIAS BIOLOGICO AGROPECUARIAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15063,
              nombre: 'FACULTAD DE INGENIERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15132,
              nombre: 'TALLER LIBRE DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
          ],
        },
        {
          id: 87722,
          nombre: 'UNIVERSIDAD VERACRUZANA REGION COATZACOALCOS-MINATITLAN',
          estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
          tipoNivel: { id: 1, descripcion: 'Pública' },
          cargo: [
            {
              cvu: 19423,
              nombre: 'ROBERTO',
              primerApellido: 'ZENTENO',
              segundoApellido: 'CUEVAS',
              nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
              rol: { id: 1, descripcion: 'Autoridad Facultada' },
              email: 'robzencue@gmail.com',
            },
            {
              cvu: 438766,
              nombre: 'JESUS',
              primerApellido: 'SANCHEZ',
              segundoApellido: 'OREA',
              nombreCargo: '.',
              rol: { id: 3, descripcion: 'Enlace institucional' },
              email: 'jesanchez@uv.mx',
            },
          ],
          pais: 'México',
          subDependencia: [
            {
              id: 15212,
              nombre: 'COORDINACION REGIONAL DE T.I. MINATITLAN',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15067,
              nombre: 'FACULTAD DE INGENIERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15167,
              nombre: 'FACULTAD DE CIENCIAS QUIMICAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15170,
              nombre: 'COORDINACION REGIONAL AREA DE FORMACION BASICA GENERAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15192,
              nombre: 'COORDINACION REG. DE DIFUSION CULTURAL Y EXT.UNIV.',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15137,
              nombre: 'CENTRO DE ESTUDIOS INTERDISCILPLINARIOS EN AGROBIODIVERSIDAD',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15140,
              nombre: 'UNIDAD SERVICIOS BIBLIOTECARIOS Y DE INFORMACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15172,
              nombre: 'TALLER LIBRE DE ARTES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15041,
              nombre: 'UNIVERSIDAD VERACRUZANA INTERCULTURAL HUAZUNTLAN',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15136,
              nombre: 'CENTRO DE IDIOMAS Y DE AUTOACCESO',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15093,
              nombre: 'SECRETARIA DE ADMINISTRACION Y FINANZAS REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15402,
              nombre: 'FACULTAD DE INGENIERIA EN SISTEMAS DE PRODUCCION AGROPECUARIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15094,
              nombre: 'ESCUELA DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15452,
              nombre: 'FACULTAD DE MEDICINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15426,
              nombre: 'FACULTAD DE ENFERMERIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15040,
              nombre: 'UNIDAD DE SERVICIOS BIBLIOTECARIOS USBI COATZA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15114,
              nombre: 'FACULTAD DE CONTADURIA Y ADMINISTRACION',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15401,
              nombre: 'FACULTAD DE TRABAJO SOCIAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15171,
              nombre: 'SECRETARIA ACADEMICA REGIONAL',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15499,
              nombre: 'FACULTAD DE ODONTOLOGIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15138,
              nombre: 'COORDINACION REGIONAL DE T.I. COATZACOALCOS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15139,
              nombre: 'COORDINACION REGIONAL DE BIBLIOTECAS COATZA-MINA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15095,
              nombre: 'VICE-RECTORIA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15169,
              nombre: 'COORDINACION REGIONAL DE ACTIVIDADES DEPORTIVAS',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15068,
              nombre: 'COORDINACION ACADEMICA REG. DE ENSEÑANZA ABIERTA',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
            {
              id: 15113,
              nombre: 'CENTRO DE INVESTIGACIONES DE RECURSOS ENERGETICOS Y SUSTENTABLES',
              estado: { id: 30, descripcion: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
              tipoNivel: { id: 1, descripcion: 'Pública' },
              cargo: [
                {
                  cvu: 19423,
                  nombre: 'ROBERTO',
                  primerApellido: 'ZENTENO',
                  segundoApellido: 'CUEVAS',
                  nombreCargo: 'DIRECTOR GENERAL DE INVESTIGACIONES',
                  rol: { id: 1, descripcion: 'Autoridad Facultada' },
                  email: 'robzencue@gmail.com',
                },
                {
                  cvu: 438766,
                  nombre: 'JESUS',
                  primerApellido: 'SANCHEZ',
                  segundoApellido: 'OREA',
                  rol: { id: 3, descripcion: 'Enlace institucional' },
                  email: 'jesanchez@uv.mx',
                },
              ],
              pais: 'México',
              departamento: [],
            },
          ],
        },
      ],
      pais: 'México',
      tipo: { id: 1, descripcion: 'Nacional' },
    },
  ];
}
