import { Component, Prop } from 'vue-property-decorator';
import ClaustroRegistro from '@/entities/snp/claustro/claustro-registro.vue';
import ClaustroListado from '@/entities/snp/claustro/claustro-listado.vue';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';

@Component({
  components: {
    claustroRegistro: ClaustroRegistro,
    claustroListado: ClaustroListado,
  },
})
export default class ClaustroComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public toggleActualizarTabla = false;
  public mostrarRegistro = false;
  public mostrarListado = true;

  public actualizarTabla(): void {
    this.toggleActualizarTabla = !this.toggleActualizarTabla;
    this.mostrarRegistro = false;
    this.mostrarListado = true;
  }
}
