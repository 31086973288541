import { render, staticRenderFns } from "./reglas.vue?vue&type=template&id=62679d54&scoped=true&"
import script from "./reglas.component.ts?vue&type=script&lang=ts&"
export * from "./reglas.component.ts?vue&type=script&lang=ts&"
import style0 from "./reglas.vue?vue&type=style&index=0&id=62679d54&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62679d54",
  null
  
)

export default component.exports