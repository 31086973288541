import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AutocompleteTextComponent extends Vue {
  @Prop({ default: null })
  public value: string;

  public members = ['titulo', 'solicitante.nombre', 'solicitante.cvu'];

  get text() {
    return this.value;
  }

  set text(text: string) {
    this.$emit('input', text);
  }
}
