var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isFetching
        ? _c(
            "div",
            [
              _c("b-skeleton", { attrs: { width: "10%" } }),
              _vm._v(" "),
              _c("b-skeleton", { attrs: { type: "input" } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSelectComponent
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.programa.form.select"),
                "label-for": "input-sm",
              },
            },
            [
              _c("b-form-select", {
                staticClass: "mb-3",
                attrs: { options: _vm.programas },
                model: {
                  value: _vm.programaSelected,
                  callback: function ($$v) {
                    _vm.programaSelected = $$v
                  },
                  expression: "programaSelected",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showNoProgramsMessage
        ? _c(
            "b-card",
            [
              _c("message", {
                attrs: {
                  description: _vm.$t("apeironGwApp.programa.form.noprogram"),
                  variant: "warning",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }