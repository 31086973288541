import { GenericaDto, IGenericaDto } from '@/shared/model/genericaDto.model';
import { ICargoDto } from '@/shared/model/cargo.model';
import { ISubDependencia } from '@/shared/model/sub-dependencia.model';

export interface IDependencia {
  id?: string;
  nombre?: string;
  estado?: GenericaDto;
  tipoNivel?: GenericaDto;
  cargo?: ICargoDto[];
  subDependencia?: ISubDependencia[];
  pais?: string;
  tipo?: GenericaDto;
  nivelNodo?: string;
}

export class Dependencia implements IDependencia {
  constructor(
    public id?: string,
    public nombre?: string,
    public estado?: IGenericaDto,
    public tipoNivel?: IGenericaDto,
    public cargo?: ICargoDto[],
    public subDependencia?: ISubDependencia[],
    public pais?: string,
    public tipo?: IGenericaDto,
    public nivelNodo?: string
  ) {
    this.tipo = this.tipo ? this.tipo : new GenericaDto();
    this.tipoNivel = this.tipoNivel ? this.tipoNivel : new GenericaDto();
    this.estado = this.estado ? this.estado : new GenericaDto();
    this.cargo = this.cargo ? this.cargo : [];
    this.subDependencia = this.subDependencia ? this.subDependencia : [];
  }
}
