var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-muted" },
    [
      _c(
        "b-row",
        { staticClass: "ml-1" },
        [
          _c(
            "b-col",
            { staticClass: "text-left", attrs: { cols: "0" } },
            [
              _c("b-icon", {
                staticClass: "mt-1",
                attrs: {
                  "font-scale": "1.5",
                  variant: _vm.variant,
                  icon: _vm.icon,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "text-left", attrs: { cols: "0" } },
            [
              _c("b-badge", { attrs: { variant: "light" } }, [
                _c("h6", [
                  _c("strong", [_vm._v(_vm._s("v." + _vm.version + ""))]),
                ]),
              ]),
              _c("em", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm.mensaje) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }