var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-link",
    {
      attrs: { exact: "", "exact-active-class": "active" },
      on: { click: _vm.previousState },
    },
    [
      _c("b-icon", {
        attrs: { icon: "arrow-left-circle", scale: "1.2", variant: "primary" },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "ml-1 item-text font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("entity.action.return"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }