import { GenericaDto, IGenericaDto } from '@/shared/model/genericaDto.model';
import { CargoDto, ICargoDto } from '@/shared/model/cargo.model';
import { IDependencia } from '@/shared/model/dependencia.model';

export interface IInstitucionDto {
  id?: string;
  nombre?: string;
  estado?: IGenericaDto;
  tipoNivel?: IGenericaDto;
  cargo?: CargoDto[];
  dependencia?: IDependencia[];
  pais?: string;
  tipo?: IGenericaDto;
  nivelNodo?: string;
}

export class InstitucionDto implements IInstitucionDto {
  constructor(
    public id?: string,
    public nombre?: string,
    public estado?: IGenericaDto,
    public tipoNivel?: IGenericaDto,
    public cargo?: ICargoDto[],
    public dependencia?: IDependencia[],
    public pais?: string,
    public tipo?: IGenericaDto,
    public nivelNodo?: string
  ) {
    this.tipo = this.tipo ? this.tipo : new GenericaDto();
    this.tipoNivel = this.tipoNivel ? this.tipoNivel : new GenericaDto();
    this.estado = this.estado ? this.estado : new GenericaDto();
    this.cargo = this.cargo ? this.cargo : [];
    this.dependencia = this.dependencia ? this.dependencia : [];
  }
}
