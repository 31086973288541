var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.resultado?.id
        ? _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: {
                        "border-variant": "success",
                        "header-bg-variant": "success",
                        header: "Resultados",
                        "header-text-variant": "white",
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "apeironGwApp.persona.expediente.convocatoria"
                                    ),
                                  },
                                },
                                [
                                  _vm.resultado?.convocatoria
                                    ? _c("p", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.resultado.convocatoria)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "apeironGwApp.resultados.motivo"
                                    ),
                                  },
                                },
                                [
                                  _vm.resultado?.motivo
                                    ? _c("p", [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.resultado.motivo)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "apeironGwApp.resultados.resultado"
                                    ),
                                  },
                                },
                                [
                                  _vm.resultado?.nivel
                                    ? _c("p", [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.resultado.nivel)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "apeironGwApp.resultados.oficio"
                                    ),
                                  },
                                },
                                [
                                  _vm.oficioUrl
                                    ? _c(
                                        "p",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              attrs: {
                                                href: _vm.oficioUrl,
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v("Oficio")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "apeironGwApp.resultados.reconocimiento"
                                    ),
                                  },
                                },
                                [
                                  _vm.reconocimientoUrl
                                    ? _c(
                                        "p",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              attrs: {
                                                href: _vm.reconocimientoUrl,
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v("Reconocimiento")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mt-3" },
                        [
                          _vm.resultado?.solicitudUrl
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    target: "_blank",
                                    variant: "primary",
                                    href: _vm.resultado.solicitudUrl,
                                  },
                                },
                                [_vm._v("Solicitud")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resultado?.perfilUrl
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    target: "_blank",
                                    href: _vm.resultado.perfilUrl,
                                  },
                                },
                                [_vm._v("Perfil Único")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFetching
        ? _c("b-skeleton-table", {
            attrs: {
              rows: 1,
              columns: 5,
              "table-props": { bordered: true, striped: true },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.noData
        ? _c("empty-data", {
            attrs: {
              message: "Aún no se cargan tus resultados",
              hint: "Por favor, espera a que se cargue la información",
              icon: "bell",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }