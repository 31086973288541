var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      !_vm.isOperador
        ? _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              attrs: { md: "12" },
            },
            [
              _c("h4", { staticClass: "title mt-4" }, [
                !_vm.isOperador
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.title-ajustes"
                          )
                        )
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.title-ajustes-solicitados"
                          )
                        )
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "b-alert",
                {
                  staticClass: "mt-4 mb-4",
                  attrs: { show: "", variant: "primary" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("apeironGwApp.comentariosAjuste.alertInfo"))
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "comentario-text-id",
                    label: _vm.$t(
                      "apeironGwApp.comentariosAjuste.titleComentario"
                    ),
                    "label-for": "comentario-id",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: { id: "textarea", rows: "6", "max-rows": "6" },
                    model: {
                      value: _vm.$v.comentario.text.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.comentario.text, "$model", $$v)
                      },
                      expression: "$v.comentario.text.$model",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-table-simple",
                    { attrs: { responsive: "", borderless: "" } },
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.comentariosAjuste.titleDocumento"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-td", { staticClass: "text-left" }, [
                            _vm.documentoModel.uri
                              ? _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          href: _vm.documentoModel.uri,
                                          target: "_blank",
                                          rel: "noreferrer",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "file-earmark-arrow-down-fill",
                                            variant: "success",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.documentoModel.nombre)
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            !_vm.documentoModel.uri &&
                                            _vm.isUpdating()
                                              ? _c("b-icon", {
                                                  attrs: {
                                                    variant: "primary",
                                                    icon: "arrow-clockwise",
                                                    animation: "spin-pulse",
                                                    "font-scale": "2",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-col",
                                          [
                                            _c("b-form-file", {
                                              attrs: {
                                                disabled:
                                                  _vm.isUpdating() ||
                                                  _vm.$v.comentario.$invalid,
                                                placeholder: _vm.$t(
                                                  "apeironGwApp.comentariosAjuste.lblSeleccionar"
                                                ),
                                                "drop-placeholder": _vm.$t(
                                                  "apeironGwApp.comentariosAjuste.lblArrastrar"
                                                ),
                                                accept: ".pdf",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onReadFile(
                                                    $event,
                                                    _vm.documentoModel
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.currentFile,
                                                callback: function ($$v) {
                                                  _vm.currentFile = $$v
                                                },
                                                expression: "currentFile",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("small", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "apeironGwApp.comentariosAjuste.lblExtensiones",
                                                    { extensiones: "pdf" }
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                          _vm._v(" "),
                          !_vm.isOperador
                            ? _c(
                                "b-td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.readOnly,
                                      expression: "!readOnly",
                                    },
                                  ],
                                  staticClass: "text-left",
                                },
                                [
                                  _vm.documentoModel.uri
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-danger",
                                            type: "button",
                                            size: "sm",
                                            disabled: _vm.isUpdating(),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteDocumento(
                                                _vm.documentoModel
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.isUpdating()
                                            ? _c("b-icon", {
                                                attrs: {
                                                  icon: "arrow-clockwise",
                                                  animation: "spin-pulse",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.comentariosAjuste.lblReemplazar"
                                              )
                                            )
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isOperador
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.readOnly,
                          expression: "!readOnly",
                        },
                      ],
                      staticClass: "text-right",
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.$v.comentario.$invalid ||
                              _vm.documentoModel.uri === undefined,
                            id: "add-comentario",
                            variant: "primary",
                          },
                          on: { click: _vm.agregarComentarioHandler },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.comentariosAjuste.lblAgregar"
                                )
                              ) +
                              " "
                          ),
                          _c("b-icon", { attrs: { icon: "plus" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.listMessages
        ? _c(
            "b-col",
            { staticClass: "mt-4" },
            [
              _vm._l(_vm.comentarios, function (comentario) {
                return _c(
                  "b-card",
                  {
                    key: comentario.id,
                    staticClass: "mb-4",
                    attrs: { "border-variant": "dark" },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("div", { staticClass: "text-muted mb-2" }, [
                            comentario.usuarioId
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(comentario.usuarioId) +
                                      "\n              "
                                  ),
                                  comentario.fechaCreacion
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "float-right",
                                          staticStyle: {
                                            "white-space": "pre-line",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "clock-history" },
                                          }),
                                          _vm._v(" "),
                                          _c("em", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  comentario.fechaCreacion
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "b-card-text",
                              { staticClass: "text-left" },
                              [
                                comentario.text
                                  ? _c("div", {
                                      staticClass: "mt-2 mb-2",
                                      domProps: {
                                        innerHTML: _vm._s(comentario.text),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                comentario.documento_comprobatorio
                                  ? _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          href: comentario
                                            .documento_comprobatorio.uri,
                                          target: "_blank",
                                          rel: "noreferrer",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "icon-pdf" }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              comentario.documento_comprobatorio
                                                .nombre
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _vm.isFetching ? _c("comentarios-skeleton") : _vm._e(),
              _vm._v(" "),
              _vm.totalItems > _vm.itemsPerPage
                ? _c(
                    "infinite-loading",
                    {
                      ref: "infiniteLoading",
                      attrs: {
                        slot: "append",
                        identifier: _vm.infiniteId,
                        "force-use-infinite-wrapper": ".el-table__body-wrapper",
                        distance: 20,
                      },
                      on: { infinite: _vm.loadMore },
                      slot: "append",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "no-more" }, slot: "no-more" },
                        [_vm._v(_vm._s(_vm.$t("global.process.search.nomore")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "no-results" }, slot: "no-results" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("global.process.search.noresults"))
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }