import HomeMenuComponent from '@/components/home-menu/home-menu.component';
import { INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';
import { Dictamen } from '@/shared/model/respuesta.model';
import { Revision } from '@/shared/model/revision.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';

const validations: any = {
  dictamenCompleto: {
    comentarios: {
      required,
      min: minLength(140),
      max: maxLength(10000),
    },
  },
};

@Component({
  validations,
  computed: {
    ...mapGetters(['dictamen', 'evaluacion']),
  },
  methods: {
    ...mapMutations(['setDictamen', 'setEvaluacion']),
  },
})
export default class DictamenReconsideracionComponent extends mixins(HomeMenuComponent) {
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public revision: Revision;

  /**
   * Indica si se requiere una evaluación individual.
   */
  @Prop({ required: false, default: true })
  public evaluacionIndividual: boolean;

  public dictamenCompleto: any = {};

  public aceptada = EstadoRevision.ACEPTADA;
  public revisada = EstadoRevision.REVISADA;

  public idNoAprobado = NRecomendadoEnum.NO_APROBADO;

  public caracter_inicial = 0;
  public caracteres_faltantes = 139;
  public caracteres_minimos = 140;
  public caracteres_maximos = 10000;

  setDictamen!: (dictamen: Dictamen) => void;

  setEvaluacion!: (evaluacion: INivel) => void;

  public readonly dictamen: Dictamen;

  public readonly evaluacion: INivel;

  mounted(): void {
    this.get();
  }

  public get(): void {
    if (this.revision) {
      if (this.revision?.respuesta?.dictamen) {
        this.dictamenCompleto = this.revision.respuesta.dictamen;
      } else if (this.dictamen && this.revision?.respuesta?.evaluacion) {
        this.dictamenCompleto = this.dictamen;
      } else if (!this.evaluacionIndividual) {
        this.dictamenCompleto = this.dictamen;
        this.revision.respuesta.evaluacion = this.evaluacion;
      }
    }
  }

  public guardarDictamen(): void {
    this.setDictamen({});
    const datosEnviar = { dictamen: this.dictamenCompleto, valido: true };
    this.$emit('guardarDictamenReconsideracion', datosEnviar);
  }
}
