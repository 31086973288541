import { EstadoSolucion } from '@/shared/model/enumerations/estado-solucion.model';

export default function resolveEstadoSolucionVariant(estado: EstadoSolucion) {
  switch (estado) {
    case EstadoSolucion.EN_CAPTURA:
      return 'primary';
    case EstadoSolucion.PUBLICADA:
      return 'success';
    case EstadoSolucion.CERRADA:
    case EstadoSolucion.ARCHIVADA:
      return 'light';
    case EstadoSolucion.CANCELADA:
      return 'danger';
    default:
      return 'primary';
  }
}
