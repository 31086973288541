
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class ValidationsCommons extends Vue {
  @Prop({ default: null })
  requiredValue: boolean;

  @Prop({ default: '' })
  requiredMessage: string;

  @Prop({ default: null })
  minValue: boolean;

  @Prop({ default: '' })
  minMessage: string;

  @Prop({ default: null })
  maxValue: boolean;

  @Prop({ default: '' })
  maxMessage: string;

  @Prop({ default: null })
  regexValue: boolean;

  @Prop({ default: '' })
  regexMessage: string;

  @Prop({ default: null })
  validValue: boolean;

  @Prop({ default: '' })
  validMessage: string;
}
