import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import ApeironComponent from '@/components/commons/apeiron-component.model';
import { required } from 'vuelidate/lib/validators';
import { Fields } from '@/components/bulk-operations/carga-masiva/fields';
import XLSX from 'xlsx';
import { Catalogo } from '@/components/movimientos/registro-movimiento/movimientos.catalog';
import * as dateUtils from '@/shared/date/date-utils';
import InstitucionService from '@/entities/institucion/institucion.service';
import SolicitudService from '@/entities/solicitud/solicitud.service';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import SolucionService from '@/entities/solucion/solucion.service';

import { EstadoProceso } from '@/shared/model/enumerations/estado-proceso.model';
import CargaMasivaUtils from '@/shared/data/carga-masiva-utils.service';
import * as arrayUtils from '@/shared/util/array-util';
import SelectOptionService from '@/shared/form/select-option.service';
import { TipoLayout } from '@/shared/model/enumerations/tipo-layout.model';

const validations: any = {
  form: {
    subSeccion: {
      nombre: { required },
    },
  },
};

@Component({
  validations,
})
export default class CargaMasivaComponent extends mixins(ApeironComponent, CargaMasivaUtils) {
  @Inject('institucionService') public institucionService: () => InstitucionService;
  @Inject('solicitudService') public solicitudService: () => SolicitudService;
  @Inject('solucionService') public solucionService: () => SolucionService;
  @Inject('selectOptionService') private selectOptionService: () => SelectOptionService;

  @Prop({ required: false })
  public params: any;

  public form = { subSeccion: {} };
  public dataFile = null;
  public institucionesLoadingState = EstadoProceso.INICIAL;
  public institucionesCompletoLoadingState = EstadoProceso.INICIAL;
  public solucionLoadingState = EstadoProceso.INICIAL;

  public items = [];
  public tipoMovimientoOpciones = [];
  public tipoParticipacionOpciones = [];
  public fields = null;
  public busy = false;
  public searching = false;
  public catalogoInstituciones = null;
  public catalogoInstitucionesCompleto = null;
  public institucionesIndex = null;
  public institucionesCompletasIndex = null;
  public solucion = null;
  public tipoLayoutOptiones = null;
  public tipoLayout = null;
  public uploading = false;

  public situacionesWithoutParticipacion = Catalogo.situacionesWithoutParticipacion();
  public situacionesWithoutFechaFin = Catalogo.situacionesWithoutFechaFin();
  public situacionesWithoutFechaFinProgramas = Catalogo.situacionesWithoutFechaFinProgramas();

  created() {
    if (this.formModel?.subSeccion) {
      this.form.subSeccion = { ...this.form.subSeccion, ...JSON.parse(JSON.stringify(this.formModel.subSeccion)) };
    }
  }

  mounted() {
    if (this.isConfigured) {
      this.tipoLayoutOptiones = this.selectOptionService().resolveTipoLayout(this);
      this.tipoMovimientoOpciones = Catalogo.movimientosAsOptions();
      this.tipoParticipacionOpciones = Catalogo.tipoParticipacionAsValueOptions();
      this.configurarInstituciones();
      this.retriveAllInstituciones();
    }
  }

  public configurarInstituciones() {
    this.institucionesLoadingState = EstadoProceso.INICIAL;
    this.institucionesCompletoLoadingState = EstadoProceso.INICIAL;
    this.retriveInstitucionesByAprobador();
  }
  public configurarPrueba() {
    this.institucionesCompletoLoadingState = EstadoProceso.INICIAL;
    this.institucionesLoadingState = EstadoProceso.FINALIZADO_EXITOSAMENTE;
    this.catalogoInstituciones = Catalogo.INSTITUCIONES;
  }

  @Watch('tipoLayout')
  changeTipoLayout(tipoLayout) {
    if (tipoLayout) {
      const solucionId =
        tipoLayout === TipoLayout.INSTITUCIONES ? this.params.cargaMasiva.solucionId : this.params.cargaMasiva.programasSolucionId;
      this.retriveSolucionById(solucionId);
      this.fields = Fields.getFields(tipoLayout);
    }
  }

  get isConfigured() {
    if (this.params?.cargaMasiva?.solucionId && this.params?.cargaMasiva?.programasSolucionId) {
      return true;
    }
    return false;
  }

  get isComponentReady() {
    return (
      this.institucionesLoadingState === EstadoProceso.FINALIZADO_EXITOSAMENTE &&
      this.solucionLoadingState === EstadoProceso.FINALIZADO_EXITOSAMENTE
    );
  }
  get isSelectReady() {
    return this.institucionesLoadingState === EstadoProceso.FINALIZADO_EXITOSAMENTE;
  }

  public handleChangeTipoLayout() {
    this.handleClear();
  }

  public retriveSolucionById(id) {
    this.solucionLoadingState = EstadoProceso.CARGANDO;
    this.solucionService()
      .find(id)
      .then(res => {
        this.solucionLoadingState = EstadoProceso.FINALIZADO_EXITOSAMENTE;
        this.solucion = res;
      })
      .catch(error => {
        this.solucionLoadingState = EstadoProceso.FINALIZADO_CON_ERRORES;
      });
  }
  paginationQuery = {
    page: 1,
    size: 100,
    sort: ' id , desc ',
  };
  public retriveInstitucionesByAprobador() {
    this.searching = true;
    this.institucionesLoadingState = EstadoProceso.CARGANDO;
    this.institucionService()
      .getInstitucionByAprobador(this.paginationQuery, this.userInfo.cvu)
      .then(result => {
        this.searching = false;
        if (result?.length > 0) {
          this.catalogoInstituciones = result;
          this.institucionesLoadingState = EstadoProceso.FINALIZADO_EXITOSAMENTE;
        } else {
          this.institucionesLoadingState = EstadoProceso.DATOS_NO_ENCONTRADOS;
        }
      })
      .catch(error => {
        this.institucionesLoadingState = EstadoProceso.FINALIZADO_CON_ERRORES;
        this.searching = false;
      });
  }
  public retriveAllInstituciones() {
    this.searching = true;
    this.institucionesCompletoLoadingState = EstadoProceso.CARGANDO;
    this.institucionService()
      .findAll()
      .then(result => {
        this.searching = false;
        if (result?.length > 0) {
          this.catalogoInstitucionesCompleto = result;
          this.institucionesCompletoLoadingState = EstadoProceso.FINALIZADO_EXITOSAMENTE;
        } else {
          this.institucionesCompletoLoadingState = EstadoProceso.DATOS_NO_ENCONTRADOS;
        }
      })
      .catch(error => {
        this.institucionesLoadingState = EstadoProceso.FINALIZADO_CON_ERRORES;
        this.searching = false;
      });
  }

  public onReadLayoutFile() {
    if (this.dataFile) {
      const fileReader = new FileReader();
      this.busy = true;
      fileReader.onload = (e: any) => {
        const content = e.target.result;
        const data = new Uint8Array(content);
        const workbook = XLSX.read(data, { raw: true, dateNF: 'DD/MM/YYYY' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const currentHeader = this.fields
          .map(field => field.key)
          .filter(field => field !== 'check' && field !== 'actions' && field !== 'state');
        XLSX.utils.sheet_add_aoa(worksheet, [currentHeader], { origin: 'A1' });
        this.items = this.mapExcelSheetToItems(worksheet);
        // Se agrega delay para permitir que el componente <excel> pueda cargar los registros
        this.sleep(3000).then(() => {
          this.busy = false;
        });
      };
      fileReader.readAsArrayBuffer(this.dataFile);
    }
  }

  public mapExcelSheetToItems(worksheet) {
    const jsonRows = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'dd/mm/yyyy' });
    const items = jsonRows.map(row => this.mapExcelToEntity(row));
    this.institucionesIndex = this.createInstitucionesIndex(items, this.catalogoInstituciones, false);
    if (this.tipoLayout === TipoLayout.PROGRAMAS) {
      this.institucionesCompletasIndex = this.createInstitucionesIndex(items, this.catalogoInstitucionesCompleto, true);
    }
    return items;
  }

  public createInstitucionesIndex(items, catalogoInstituciones, isComision) {
    const institucionIndexed = [];
    const institucionesOptions = this.itemAsOptions(catalogoInstituciones);
    for (const item of items) {
      const row = isComision ? item.comision : item;
      const indexedRow = this.getDefaultIndexedRow(institucionesOptions);
      this.indexDependencias(indexedRow, row, catalogoInstituciones);
      this.indexSubdependencias(indexedRow, row);
      this.indexDepartamentos(indexedRow, row);
      this.setDepartamentoProperties(indexedRow, row);
      institucionIndexed.push(indexedRow);
    }

    return institucionIndexed;
  }

  public indexDependencias(indexedRow, item, catalogoInstituciones) {
    if (item?.institucion?.id) {
      const institucionFinded = catalogoInstituciones.find(institucion => institucion.id == item.institucion.id);
      if (institucionFinded) {
        indexedRow.dependencia = this.itemAsOptions(institucionFinded.dependencia);
        indexedRow.dependenciaRaw = institucionFinded.dependencia;
        this.copyCommonProperties(item, institucionFinded);
        item.idTipoInstitucion = institucionFinded.tipoNivel.id;
        item.tipoInstitucion = institucionFinded.tipoNivel.descripcion;
      } else {
        item.institucion = null;
        item.dependencia = null;
        item.subdependencia = null;
        item.departamento = null;
      }
    }
  }

  public indexSubdependencias(indexedRow, item) {
    if (item?.institucion?.id && item?.dependencia?.id) {
      const dependenciaFinded = indexedRow.dependenciaRaw.find(dependencia => dependencia.id == item.dependencia.id);
      if (dependenciaFinded) {
        indexedRow.subdependencia = this.itemAsOptions(dependenciaFinded.subDependencia);
        indexedRow.subdependenciaRaw = dependenciaFinded.subDependencia;
        this.copyCommonProperties(item, dependenciaFinded);
      } else {
        item.dependencia = null;
        item.subdependencia = null;
        item.departamento = null;
      }
    }
  }
  public indexDepartamentos(indexedRow, item) {
    if (item?.institucion?.id && item?.dependencia?.id && item?.subdependencia?.id) {
      const subdependenciaFinded = indexedRow.subdependenciaRaw.find(subdependencia => subdependencia.id == item.subdependencia.id);
      if (subdependenciaFinded) {
        indexedRow.departamento = this.itemAsOptions(subdependenciaFinded.departamento);
        indexedRow.departamentoRaw = subdependenciaFinded.departamento;
        this.copyCommonProperties(item, subdependenciaFinded);
      } else {
        item.subdependencia = null;
        item.departamento = null;
      }
    }
  }

  public setDepartamentoProperties(indexedRow, item) {
    if (item?.institucion?.id && item?.dependencia?.id && item?.subdependencia?.id && item?.departamento?.id) {
      const departamentoFinded = indexedRow.departamentoRaw.find(departamento => departamento.id == item.departamento.id);
      if (departamentoFinded) {
        this.copyCommonProperties(item, departamentoFinded);
      } else {
        item.departamento = null;
      }
    }
  }
  public getDefaultIndexedRow(institucionesOptions) {
    return {
      institucion: institucionesOptions,
      dependencia: null,
      subdependencia: null,
      departamento: null,
      dependenciaRaw: null,
      subdependenciaRaw: null,
      departamentoRaw: null,
    };
  }

  public mapExcelToEntity(row) {
    row.inicio = dateUtils.fromMultiFormat(row.inicio);
    row.fin = dateUtils.fromMultiFormat(row.fin);
    row.institucion = this.decodeItem(row.institucion);
    row.dependencia = this.decodeItem(row.dependencia);
    row.subdependencia = this.decodeItem(row.subdependencia);
    row.departamento = this.decodeItem(row.departamento);
    this.resolveInstituciones(row, this.catalogoInstituciones);
    row.processInfo = {
      isSelectable: true,
      isProcessing: false,
      isFinished: false,
      hasErrors: false,
      errors: [],
    };
    if (this.tipoLayout === TipoLayout.PROGRAMAS) {
      row.comision = {
        institucion: this.decodeItem(row.institucionComision),
        dependencia: this.decodeItem(row.dependenciaComision),
        subdependencia: this.decodeItem(row.subdependenciaComision),
        departamento: this.decodeItem(row.departamentoComision),
      };
      this.resolveInstituciones(row.comision, this.catalogoInstitucionesCompleto);
    }
    return row;
  }

  public elementsToProcess = null;
  public handleUpload(rows) {
    if (rows?.length > 0) {
      this.uploading = true;
      const uniqueCVUs = new Set();
      // Lista para almacenar CVUs duplicados
      const duplicatedCVUs = [];

      rows.forEach(row => {
        if (uniqueCVUs.has(row.cvu)) {
          // CVU duplicado y agregado a la lista de duplicados
          duplicatedCVUs.push(row.cvu);
        } else {
          uniqueCVUs.add(row.cvu);
        }
      });
      if (duplicatedCVUs.length > 0) {
        const duplicatedCVUsMessage = `CVU duplicado(s): ${duplicatedCVUs.join(
          ', '
        )} en la plantilla, uno de ellos se logró cargar su registro.`;
        this.alertService().showError(this, duplicatedCVUsMessage);
      }

      // Convertir el Set de uniqueCVUs en un Array
      const elementsWithoutDuplicates = Array.from(uniqueCVUs).map(cvu => rows.find(row => row.cvu === cvu));

      //Realiza la carga de CVUs sin duplicar datos
      this.elementsToProcess = arrayUtils.splitInChunks(elementsWithoutDuplicates, 25);
      this.doMultipleCreations(this.elementsToProcess.shift());
    }
  }
  public handleClear() {
    this.dataFile = null;
    this.items = [];
  }

  public doMultipleCreations(elementsToProcess) {
    this.markInProcess(elementsToProcess);
    const solicitudes = elementsToProcess.map(element => this.mapToSolicitud(element));
    this.solicitudService()
      .doMultipleCreations(solicitudes)
      .then(res => {
        this.markComplete(res.data.processedElements);
        if (this.elementsToProcess?.length > 0) {
          this.doMultipleCreations(this.elementsToProcess.shift());
        } else {
          (this.$refs.bulkTableComponent as any).onFinish();
          this.uploading = false;
        }
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public mapToSolicitud(row) {
    let fechaInicioSituacion;

    if (row?.situacion && !this.situacionesWithoutParticipacion.includes(row.situacion)) {
      fechaInicioSituacion = row?.inicio;
    } else {
      fechaInicioSituacion = this.obtenerFechaInicio(row);
    }

    let fechaFinSituacion;

    if (row?.situacion && !this.situacionesWithoutFechaFin.includes(row.situacion)) {
      fechaFinSituacion = row?.fin || 'Indeterminado';
    } else if (row?.situacion && this.situacionesWithoutFechaFinProgramas.includes(row.situacion)) {
      fechaFinSituacion = row?.fin || 'Indeterminado';
    } else {
      fechaFinSituacion = 'Indeterminado';
    }
    return {
      nombre: this.solucion.titulo,
      solucionId: this.solucion.id,
      estado: EstadoSolicitud.EN_CAPTURA,
      hint: 'bulk',
      solicitante: {
        cvu: row.cvu,
        login: 'null',
        nombre: 'null',
        apellidoMaterno: 'null',
        apellidoPaterno: 'null',
        curp: 'null',
        correo: 'null',
      },
      tipoAcreditacion: this.tipoLayout,
      situaciones: row.situacion,
      fechaInicioSituacion: fechaInicioSituacion,
      fechaFinSituacion: fechaFinSituacion,
      tipoParticipacion: row.tipoParticipacion,
      institucionActualSubSeccion: {
        institucion: row?.institucion?.nombre ?? 'Sin información',
        dependencia: row?.dependencia?.nombre ?? 'Sin información',
        subDependencia: row?.subdependencia?.nombre ?? 'Sin información',
        departamento: row?.departamento?.nombre ?? 'Sin información',
        idInstitucion: this.toInt(row?.institucion?.id ?? null),
        idDependencia: this.toInt(row?.dependencia?.id ?? null),
        idSubDependencia: this.toInt(row?.subdependencia?.id ?? null),
        idDepartamento: this.toInt(row?.departamento?.id ?? null),
        institucionComision: row?.comision?.institucion?.nombre ?? 'Sin información COMISIÓN',
        dependenciaComision: row?.comision?.dependencia?.nombre ?? 'Sin información COMISIÓN',
        subDependenciaComision: row?.comision?.subdependencia?.nombre ?? 'Sin información COMISIÓN',
        departamentoComision: row?.comision?.departamento?.nombre ?? 'Sin información COMISIÓN',
        idInstitucionComision: this.toInt(row?.comision?.institucion?.id ?? null),
        idDependenciaComision: this.toInt(row?.comision?.dependencia?.id ?? null),
        idSubDependenciaComision: this.toInt(row?.comision?.subdependencia?.id ?? null),
        idDepartamentoComision: this.toInt(row?.comision?.departamento?.id ?? null),
        idTipoInstitucion: row?.tipoNivel?.id ?? null,
        tipoInstitucion: row?.tipoNivel?.descripcion ?? null,
        tipoNivel: row.tipoNivel,
        idTipoNivel: this.toInt(row.idTipoNivel ?? null),
        pais: row.pais,
        estado: row.estado,
        idEstado: row.idEstado,
        idPais: 143,
        estadoComision: row.comision?.estado ?? null,
        idEstadoComision: this.toInt(row.comision?.idEstado ?? null),
        idPaisComision: 143,
        paisComision: row.comision?.pais ?? null,
        idTipoNivelComision: this.toInt(row.comision?.idTipoNivel ?? null),
        tipoNivelComision: row.comision?.tipoNivel ?? null,
      },
    };
  }
  public resolveFechaFin(value) {}

  public toInt(stringNumber) {
    if (stringNumber) {
      return parseInt(stringNumber);
    }
    return null;
  }
  public markInProcess(elementsToProcess) {
    elementsToProcess.forEach(element => {
      (this.$refs.bulkTableComponent as any).onProcess(element.cvu);
    });
  }
  public markComplete(elementsToProcess) {
    elementsToProcess.forEach(element => {
      (this.$refs.bulkTableComponent as any).onComplete(element);
    });
  }

  get userInfo() {
    return this.$store.getters.userInfo;
  }

  private obtenerFechaInicio(row) {
    if (row?.situacion && this.situacionesWithoutParticipacion.includes(row.situacion)) {
      const hoy = new Date();
      const ultimoDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);
      return ultimoDiaDelMes.toISOString().slice(0, 10);
    } else {
      return 'Indeterminado';
    }
  }
}
