var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      attrs: {
        "data-cy": "navbar",
        toggleable: "md",
        type: "dark",
        sticky: "",
      },
    },
    [
      _c("b-navbar-brand", { attrs: { href: "#" } }, [
        _c("img", {
          staticClass: "conahcyt",
          attrs: { src: "/content/images/conahcyt.svg", alt: "Conahcyt" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { attrs: { id: "navbar-informacion-general-id" } },
            [
              _c("b-nav-item", { attrs: { href: "#" } }, [
                _vm._v(_vm._s(_vm.$t("global.menu.whoAreWe"))),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: "#" } }, [
                _vm._v(_vm._s(_vm.$t("global.menu.strategies"))),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: "#" } }, [
                _vm._v(_vm._s(_vm.$t("global.menu.goals"))),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: "#" } }, [
                _vm._v(_vm._s(_vm.$t("global.menu.indicator"))),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: "#" } }, [
                _vm._v(_vm._s(_vm.$t("global.menu.contact"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-nav-item",
                { attrs: { id: "navbar-home-id", to: "/", exact: "" } },
                [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", { attrs: { icon: "home" } }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.home")),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  attrs: { exact: "" },
                  on: { click: _vm.showTourWelcomeWithoutTimer },
                },
                [
                  _c(
                    "span",
                    [
                      _c("b-icon", { attrs: { icon: "question-lg" } }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.help")),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: "/convocatorias", exact: "" } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", { attrs: { icon: "book" } }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("global.menu.entities.convocatoria")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: "/bandejaSolicitudes", exact: "" } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", { attrs: { icon: "book" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("evaluacion")),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "no-bold",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.entities.main")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("entities-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/admin"),
                      },
                      attrs: {
                        right: "",
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "no-bold",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.main")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/gateway",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "road" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.gateway")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/metrics",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.metrics")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/health",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.health")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/configuration",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.configuration")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/logs",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.admin.logs")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.openAPIEnabled
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/docs",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "book" },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.admin.apidocs")
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.languages && Object.keys(_vm.languages).length > 1
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { id: "languagesnavBarDropdown", right: "" } },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "flag" } }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "no-bold",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.language")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.languages, function (value, key) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: `lang-${key}`,
                            class: { active: _vm.isActiveLanguage(key) },
                            on: {
                              click: function ($event) {
                                return _vm.changeLanguage(key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(value.name) + "\n        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "no-bold",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.menu.account.main")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.account.logout")
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.account.login")
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-tour", {
        attrs: {
          name: "tour",
          steps: _vm.steps,
          options: _vm.tourOptions,
          callbacks: _vm.tourCallbacks,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tour) {
              return [
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    tour.steps[tour.currentStep]
                      ? _c(
                          "v-step",
                          {
                            key: tour.currentStep,
                            attrs: {
                              step: tour.steps[tour.currentStep],
                              "previous-step": tour.previousStep,
                              "next-step": tour.nextStep,
                              stop: tour.stop,
                              skip: tour.skip,
                              "is-first": tour.isFirst,
                              "is-last": tour.isLast,
                              labels: tour.labels,
                              highlight: tour.highlight,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "v-step__content",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-2 align-self-center" },
                                    [
                                      _c(
                                        "h3",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "danger",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(tour.currentStep + 1)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-10" }, [
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          tour.steps[tour.currentStep].content
                                        ),
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                attrs: { slot: "actions text-right" },
                                slot: "actions text-right",
                              },
                              [
                                !tour.isLast
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "v-step__button v-step__button-skip",
                                        on: { click: tour.skip },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("apeironGwApp.tour.skip")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !tour.isFirst
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "v-step__button v-step__button-previous",
                                        on: { click: tour.previousStep },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.tour.previous"
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !tour.isLast
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "v-step__button v-step__button-next",
                                        on: { click: tour.nextStep },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("apeironGwApp.tour.next")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                tour.isLast
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "v-step__button v-step__button-stop",
                                        on: { click: tour.stop },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("apeironGwApp.tour.stop")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "tourWelcome",
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            id: "tourWelcome",
            centered: "",
          },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("Guía interactiva de la solicitud")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: { src: "content/images/tour-welcome.gif", alt: "tour" },
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v(
                  "\n        En esta guía interactiva te vamos a explicar las diferentes secciones que conforman a una solicitud. Da clic en el boton de\n        "
                ),
                _c(
                  "b-button",
                  {
                    attrs: { disabled: true, size: "sm", variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleConfirmation()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("entity.action.confirmTour")))]
                ),
                _vm._v(
                  "\n        para que podamos comenzar. Da clic en el boton de\n        "
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: true,
                      size: "sm",
                      variant: "outline-danger",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleCancel()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
                ),
                _vm._v(
                  "\n        en caso de que no quieras seguir nuestro recorrido.\n      "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirmTour")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "tourCompleted",
          attrs: {
            size: "xl",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            id: "tourCompleted",
            centered: "",
          },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("¡Felicidades!")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row text-center" }, [
              _c("div", { staticClass: "col" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("b-icon", {
                    attrs: {
                      variant: "primary",
                      animation: "fade",
                      "font-scale": "7.5",
                      icon: "gift",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("p", { staticClass: "h3 text-justify" }, [
                _vm._v(
                  "Has concluido el recorrido exitosamente. Si tienes dudas, siempre te puedes contactar con nosotros"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [_c("contacto-asistencia")], 1),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeTourCompletedModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.close")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }