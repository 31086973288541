var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.label
        ? _c("label", { attrs: { for: "tags-pills" } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-tags", {
        attrs: {
          "input-id": "tags-pills",
          "tag-variant": _vm.variant,
          "tag-pills": "",
          size: _vm.size,
          limit: _vm.limit,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
        },
        model: {
          value: _vm.tags,
          callback: function ($$v) {
            _vm.tags = $$v
          },
          expression: "tags",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }