import { Component, Prop, Vue } from 'vue-property-decorator';
import { DatosGenerales, Expediente } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';

@Component
export default class DatosGeneralesComponent extends Vue {
  @Prop({ required: false })
  public item: Expediente;

  @Prop({ required: false })
  public misAyudantes: any;

  get datosGenerales(): DatosGenerales {
    return this.item.datosGenerales;
  }

  get vigenciaSniActiva() {
    let vigencia = null;
    if (this.item?.vigenciasSni?.length > 0) {
      vigencia = this.item.vigenciasSni.find(vigencia => vigencia.activo);
    }
    return vigencia;
  }

  get acreditacion() {
    let acreditaciones = null;
    let acreditacion = null;
    if (this.item?.acreditaciones?.length > 0) {
      acreditaciones = (this.item.acreditaciones as any).toSorted(dateUtils.stringDateInUtcComparator);
    }
    if (acreditaciones?.length > 0) {
      acreditacion = acreditaciones[0];
    }
    return acreditacion;
  }

  get nivel() {
    return this.vigenciaSniActiva?.nivelSni?.descripcion ?? null;
  }

  get situacion() {
    return this.acreditacion?.tipo?.descripcion ?? null;
  }

  get institucion() {
    return this.acreditacion?.institucion?.descripcion ?? null;
  }

  get entidad() {
    return this.acreditacion?.entidadFederativa?.nombre ?? null;
  }

  get ayudantes() {
    return this.misAyudantes;
  }

  get cuentas() {
    if (this.item?.cuentasBancarias?.length > 0) {
      const cuentaActiva = this.item.cuentasBancarias.filter(cuenta => cuenta.estadoSolicitud === EstadoSolicitud.ENVIADA);
      return cuentaActiva;
    }
    return null;
  }
}
