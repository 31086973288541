export interface IInstitucionActual {
  idInstitucion?: number;
  institucion?: string;
  idDependencia?: number;
  dependencia?: string;
  idSubDependencia?: number;
  subDependencia?: string;
  idDepartamento?: number;
  departamento?: string;
  idTipoNivel?: number;
  tipoNivel?: string;
  idPais?: number;
  pais?: string;
  idEstado?: number;
  estado?: string;
}

export class InstitucionActual implements IInstitucionActual {
  constructor(
    public idInstitucion?: number,
    public institucion?: string,
    public idDependencia?: number,
    public dependencia?: string,
    public idSubDependencia?: number,
    public subDependencia?: string,
    public idDepartamento?: number,
    public departamento?: string,
    public idTipoNivel?: number,
    public tipoNivel?: string,
    public idPais?: number,
    public pais?: string,
    public idEstado?: number,
    public estado?: string
  ) {}
}
