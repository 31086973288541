import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import VueCountdown from '@chenfengyuan/vue-countdown';

@Component({
  components: {
    countdown: VueCountdown,
  },
})
export default class TimerComponent extends Vue {
  @Prop({ required: true })
  id: string;

  @Prop({ default: 0 })
  time: number;

  get countDown() {
    return this.time > 0 ? this.time : 10; // se deja en 10 para darle tiempo a vuejs crear el componente
  }

  public threshold = 172800000; // 2 días

  @Prop({ default: false })
  activate: boolean;

  public showCountDown = false;
  public isFinish = false;

  public end() {
    this.$emit('end');
    (this.$refs['modal' + this.id] as any).show();
    this.isFinish = true;
  }

  public handleConfirmation(): void {
    (this.$refs['modal' + this.id] as any).hide();
    this.$router.go(-1);
  }

  @Watch('activate', { immediate: true })
  public start(activate: boolean) {
    if (activate) {
      (this.$refs[this.id] as any).start();
      if (this.time < this.threshold) {
        this.showCountDown = true;
      }
    }
  }

  get timerVariant() {
    return this.isFinish ? 'bg-danger' : 'bg-success';
  }
}
