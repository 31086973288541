import SolicitudService from '@/entities/solicitud/solicitud.service';
import AlertService from '@/shared/alert/alert.service';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { AreaConocimiento, Solicitud } from '@/shared/model/solicitud.model';
import { SaciApi } from '@conacyt/saci-client';
import { BModal } from 'bootstrap-vue';
import { Component, Emit, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

export interface IAreaConocimiento {
  id?: string;
  clave?: string;
  nombre?: string;
}
const validations = function () {
  const { areaConocimiento } = this as CamposConocimientoComponent;
  return {
    areaConocimiento: {
      area: { required },
      campo: {
        required: value => {
          return !!areaConocimiento.area ? !!value : true;
        },
      },
      disciplina: {
        required: value => {
          return !!areaConocimiento.campo ? !!value : true;
        },
      },
      subdisciplina: {
        required: value => {
          return !!areaConocimiento.disciplina ? !!value : true;
        },
      },
    },
  };
};

@Component({ validations })
export default class CamposConocimientoComponent extends Vue {
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('saciApi') public saciApi: () => SaciApi;
  @Inject('solicitudService') public solicitudService: () => SolicitudService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public areaConocimiento: AreaConocimiento = {};

  public area: Array<IAreaConocimiento> = [
    { id: '9', clave: '2', nombre: 'Biología y Química' },
    { id: '13', clave: '6', nombre: 'Ciencias Sociales' },
    { id: '14', clave: '7', nombre: 'Ciencias de Agricultura, Agropecuarias, Forestales y de Ecosistemas' },
    { id: '11', clave: '4', nombre: 'Ciencias de la Conducta y la Educación' },
    { id: '8', clave: '1', nombre: 'Físico-Matemáticas y Ciencias de la Tierra' },
    { id: '12', clave: '5', nombre: 'Humanidades' },
    { id: '15', clave: '8', nombre: 'Ingenierías y Desarrollo Tecnológico' },
    { id: '16', clave: '9', nombre: 'Interdisciplinaria' },
    { id: '10', clave: '3', nombre: 'Medicina y Ciencias de la Salud' },
  ];

  public catalogoArea: Array<AreaConocimiento> = [];
  public catalogoCampo: Array<AreaConocimiento> = [];
  public catalogoDisciplina: Array<AreaConocimiento> = [];
  public catalogoSubdisciplina: Array<AreaConocimiento> = [];

  public areasConocimiento: Array<AreaConocimiento> = [];

  public page = 1;
  public registros = 100;
  public mostrarSeccionRegistro = false;
  public mostrarLista = false;

  /**
   * Variables para solo Lectura
   */
  public areaLectura = false;
  public campoLectura = true;
  public disciplinaLectura = true;
  public subdisciplinaLectura = true;

  public areaAEliminar: null;

  @Ref('confirmarModalEliminar')
  public readonly confirmarModalEliminar: BModal;

  @Ref('confirmarModalPrincipal')
  public readonly confirmarModalPrincipal: BModal;

  public readonly fieldsSolicitudes = [
    {
      key: 'area',
      label: this.$t('apeironGwApp.programa.camposConocimiento.areaConocimiento').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'campo',
      label: this.$t('apeironGwApp.programa.camposConocimiento.campo').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'disciplina',
      label: this.$t('apeironGwApp.programa.camposConocimiento.disciplina').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'subDisciplina',
      label: this.$t('apeironGwApp.programa.camposConocimiento.subDisciplina').toString(),
      class: 'text-center',
      sortable: true,
    },
    { key: 'principal', label: this.$t('apeironGwApp.programa.camposConocimiento.principal').toString(), class: 'text-center' },
    { key: 'accion', label: this.$t('bandeja.acciones').toString(), class: 'text-center' },
  ];

  mounted() {
    this.get();
  }

  /**
   * Carga inicial del componente
   *
   * @memberof CamposConocimientoComponent
   */
  public get(): void {
    if (this.solicitud?.areasConocimiento) {
      this.areasConocimiento = this.solicitud.areasConocimiento;
    }
  }

  /**
   * Obtiene los campos de conocimiento de un área específica y actualiza el estado de los campos de lectura.
   *
   * @memberof CamposConocimientoComponent
   */
  public obtenerCampos(area: AreaConocimiento): void {
    if (area) {
      this.getCatalogos('getCatCamposAreasConocimiento', area.id).then(res => {
        this.catalogoCampo = res;
        this.campoLectura = false;
        this.disciplinaLectura = true;
        this.subdisciplinaLectura = true;
        this.areaConocimiento.disciplina = {};
        this.areaConocimiento.subdisciplina = {};
      });
    }
  }

  /**
   * Obtiene las disciplinas de conocimiento de un campo específico y actualiza el estado de las disciplinas de lectura.
   *
   * @memberof CamposConocimientoComponent
   */
  public obtenerDisciplinas(campo: AreaConocimiento): void {
    if (campo) {
      this.getCatalogos('getCatDisciplinasCampos', campo.id).then(res => {
        this.catalogoDisciplina = res;
        this.disciplinaLectura = false;
        this.subdisciplinaLectura = true;
      });
    }
  }

  /**
   * Obtiene las subdDsciplinas de conocimiento de una discilpina específico y actualiza el estado de las subdDsciplinas de lectura.
   *
   * @memberof CamposConocimientoComponent
   */
  public obtenerSubDisciplinas(disciplina: AreaConocimiento): void {
    if (disciplina) {
      this.getCatalogos('getSubDisciplinasDisciplinas', disciplina.id).then(res => {
        this.catalogoSubdisciplina = res;
        this.subdisciplinaLectura = false;
      });
    }
  }

  /**
   * Obtiene los datos del área de conocimiento desde el catálogo especificado SACI.
   *
   * @memberof CamposConocimientoComponent
   */
  public getCatalogos(catalogo: string, param1): Promise<any[]> {
    return new Promise(resolve => {
      try {
        this.saciApi()
          [catalogo](param1, this.page, this.registros)
          .then(res => {
            resolve(this.toCatalogoVue(res.data));
          })
          .catch(error => {
            this.alertService().showHttpError(this, error.response);
          });
      } catch (error) {
        this.alertService().showHttpError(this, error.response);
      }
    });
  }

  /**
   * Transforma un array de objetos JSON en un formato específico para ser usado en un catálogo Vue.
   *
   * @memberof CamposConocimientoComponent
   */
  public toCatalogoVue(jsonData: Array<any>): Array<any> {
    const catalogo = [];
    jsonData.forEach(element => {
      catalogo.push({ value: element, text: element.nombre });
    });
    return catalogo;
  }

  /**
   * Determina si el campo debe habilitarse.
   *
   * @memberof CamposConocimientoComponent
   */
  get deshabilitarCampo(): boolean {
    return this.solicitud.estado === EstadoSolicitud.EN_CAPTURA || this.solicitud.estado === EstadoSolicitud.REVISADA;
  }

  /**
   * Elimina un área de conocimiento de la lista de áreas de conocimiento.
   *
   * @memberof CamposConocimientoComponent
   */
  public eliminarAreaConocimiento(areaConocimiento: AreaConocimiento) {
    this.areasConocimiento = this.areasConocimiento.filter(area => area !== areaConocimiento);
    this.guardarAreaConocimiento();
  }

  /**
   * Guarda el área de conocimiento en la solicitud actual y actualiza la solicitud.
   *
   * @memberof CamposConocimientoComponent
   */
  public guardarSeccion(): void {
    const tienePrincipal = this.areasConocimiento.some(area => area.principal === true);

    if (tienePrincipal) {
      this.guardarAreaConocimiento();
    } else {
      this.alertService().showWarning(this, this.$t('apeironGwApp.programa.camposConocimiento.campoPrincipal', 'Falla').toString());
    }
  }

  /**
   * Este método `limpiarAreaConocimiento` restablece el estado de las propiedades
   * relacionadas con el área de conocimiento a sus valores predeterminados.
   *
   * @memberof CamposConocimientoComponent
   */
  private limpiarAreaConocimiento() {
    (this.campoLectura = true), (this.disciplinaLectura = true), (this.subdisciplinaLectura = true);
    this.areaConocimiento = {
      area: undefined,
      campo: undefined,
      disciplina: undefined,
      subdisciplina: undefined,
    };
  }

  /**
   * Transforma un array de objetos AreaConocimiento en un array de objetos con claves y nombres específicos.
   *
   * @memberof CamposConocimientoComponent
   */
  public transformAreasConocimiento(areas: Array<AreaConocimiento>): Array<any> {
    return areas.map(areaConocimiento => ({
      cveArea: areaConocimiento.area?.clave,
      area: areaConocimiento.area?.nombre,
      cveCampo: areaConocimiento.campo?.clave,
      campo: areaConocimiento.campo?.nombre,
      cveDisciplina: areaConocimiento.disciplina?.clave,
      disciplina: areaConocimiento.disciplina?.nombre,
      cveSubDisciplina: areaConocimiento.subdisciplina?.clave,
      subDisciplina: areaConocimiento.subdisciplina?.nombre,
      principal: areaConocimiento?.principal ?? false,
    }));
  }

  /**
   * Agrega un nuevo objeto de área de conocimiento a la lista de áreas de conocimiento.
   *
   * @memberof CamposConocimientoComponent
   */
  public agregarArea() {
    if (
      this.areaConocimiento.area.nombre &&
      this.areaConocimiento.campo.nombre &&
      this.areaConocimiento.disciplina.nombre &&
      this.areaConocimiento.subdisciplina.nombre
    ) {
      const nuevaArea = {
        area: this.areaConocimiento.area,
        campo: this.areaConocimiento.campo,
        disciplina: this.areaConocimiento.disciplina,
        subdisciplina: this.areaConocimiento.subdisciplina,
      };

      const areaTransformada = this.transformAreasConocimiento([nuevaArea])[0];
      this.areasConocimiento.push(areaTransformada);
      this.limpiarAreaConocimiento();
      this.mostrarLista = true;
    } else {
      this.alertService().showWarning(this, this.$t('apeironGwApp.programa.camposConocimiento.camposRequeridos', 'Falla').toString());
    }
  }

  /**
   * Verifica si el área especificada es principal para habilitar el botón .
   *
   * @memberof CamposConocimientoComponent
   */
  public mostrarCheckPrincipal(area) {
    return area.principal === true;
  }

  /**
   * Muestra un modal de confirmación para eliminar un área de conocimiento.
   *
   * @memberof CamposConocimientoComponent
   */
  public mostrarModalConfirmacionEliminarArea(areaConocimiento): void {
    this.areaAEliminar = areaConocimiento;
    this.confirmarModalEliminar.show();
  }

  /**
   * Método para confirmar la acción de eliminación de un área de conocimiento.
   *
   * @memberof CamposConocimientoComponent
   */
  public confirmarAccionEliminarArea(): void {
    this.eliminarAreaConocimiento(this.areaAEliminar);

    this.confirmarModalEliminar.hide();
  }

  /**
   * Oculta el modal de eliminar un área de conocimiento.
   *
   * @memberof CamposConocimientoComponent
   */
  public ocultarModalEliminarArea(): void {
    this.confirmarModalEliminar.hide();
  }

  /**
   * Muestra un modal de confirmación para designar un área de conocimiento como principal.
   *
   * @memberof CamposConocimientoComponent
   */
  public mostrarModalConfirmacionAreaPrincipal(areaConocimientoSeleccionada: AreaConocimiento): void {
    this.areasConocimiento.forEach(area => {
      if (area !== areaConocimientoSeleccionada && area.principal) {
        area.principal = false;
      }
    });

    areaConocimientoSeleccionada.principal = true;

    this.areaConocimiento = { ...areaConocimientoSeleccionada };
    this.areaConocimiento = this.areaConocimiento;
    this.confirmarModalPrincipal.show();
  }

  /**
   * Método para confirmar la acción de agregar un área de conocimiento como principal.
   *
   * @memberof CamposConocimientoComponent
   */
  public confirmarAccionAreaPrincipal(): void {
    this.confirmarModalPrincipal.hide();
  }

  /**
   * Oculta el modal para agregar un área de conocimiento como principal
   * y muestra las areas conocimiento que contiene la solicitud originalmente.
   *
   * @memberof CamposConocimientoComponent
   */
  public async ocultarModalAreaPrincipal(): Promise<void> {
    const solicitudOriginal = await this.solicitudService().find(this.solicitud.id);
    this.areasConocimiento = solicitudOriginal.areasConocimiento;
    this.areasConocimiento = [...this.areasConocimiento];

    this.confirmarModalPrincipal.hide();
  }

  /**
   * Controla el deshabilitado de los campos y botones.
   * @memberof CamposConocimientoComponent
   */
  get desactivarBoton(): boolean {
    if (this.areasConocimiento.length > 0 && this.areasConocimiento.some(area => area.principal === true)) {
      return false;
    }
    return true;
  }

  public handleInput(): void {
    if (this.areasConocimiento.some(area => area.principal === true)) {
      this.areaConocimiento.principal = true;
    }
    this.areaConocimiento.principal = false;
  }

  get activarBoton(): boolean {
    return this.$v.$invalid;
  }

  get mostrarTexto() {
    return this.solicitud?.areasConocimiento?.length === 0 || !this.solicitud?.areasConocimiento;
  }

  @Emit('guardarAreaConocimiento')
  public guardarAreaConocimiento(): { areasConocimiento: Array<AreaConocimiento> } {
    return { areasConocimiento: this.areasConocimiento };
  }
}
