var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { id: "dictamen-general", fluid: "" } },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.guardarDictamen.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-container",
                    {
                      staticClass: "border text-justify",
                      attrs: { id: "dictamen-solicitud" },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area1")),
                        },
                      }),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            this.solicitud.SOLICITUD_RIZOMA?.areaConocimiento
                              ?.area?.clave
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area2")),
                        },
                      }),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            this.solicitud.SOLICITUD_RIZOMA?.areaConocimiento
                              ?.area?.nombre
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area3")),
                        },
                      }),
                      _vm._v(" "),
                      _c("strong", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area4")),
                        },
                      }),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(this.revision?.respuesta?.comision)),
                      ]),
                      _vm._v(" "),
                      this.revision?.respuesta?.evaluacion?.id !==
                      this.idNoAprobado
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("reconsideracion.area5")
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      this.revision?.respuesta?.evaluacion?.id ===
                      this.idNoAprobado
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("reconsideracion.areaNoAprobado")
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      this.revision?.respuesta?.evaluacion?.id !==
                      this.idNoAprobado
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                this.revision?.respuesta?.evaluacion?.nombre
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      this.revision?.respuesta?.evaluacion?.id ===
                      this.idNoAprobado
                        ? _c("strong", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("reconsideracion.areaNoAprobado2")
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area6")),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "line-break",
                        domProps: {
                          textContent: _vm._s(_vm.$t("reconsideracion.area7")),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("label", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("dictamen.comentarios")),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "comentarios",
                      placeholder: "Redacte aquí",
                      rows: "4",
                      disabled: this.revision.estado === this.revisada,
                      state: !_vm.$v.dictamenCompleto.comentarios.$invalid,
                      required: "",
                    },
                    model: {
                      value: _vm.dictamenCompleto.comentarios,
                      callback: function ($$v) {
                        _vm.$set(_vm.dictamenCompleto, "comentarios", $$v)
                      },
                      expression: "dictamenCompleto.comentarios",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.required
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.$t("caracteres.requerido")),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.min
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("caracteres.min", { min: "140" })
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.max
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("caracteres.max", { max: "10000" })
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "float-right" }, [
                    _vm.$v.dictamenCompleto.comentarios.$model?.length <=
                    _vm.caracteres_minimos
                      ? _c("small", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.dictamenCompleto.comentarios.$model
                                  ?.length <= _vm.caracteres_faltantes,
                              expression:
                                "$v.dictamenCompleto.comentarios.$model?.length <= caracteres_faltantes",
                            },
                          ],
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.faltantes", {
                                minimos:
                                  _vm.$v.dictamenCompleto.comentarios.$model
                                    ?.length - _vm.caracteres_minimos,
                              })
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.dictamenCompleto.comentarios.$model?.length >=
                    _vm.caracteres_minimos
                      ? _c("small", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.dictamenCompleto.comentarios.$model
                                  ?.length <= _vm.caracteres_maximos,
                              expression:
                                "$v.dictamenCompleto.comentarios.$model?.length <= caracteres_maximos",
                            },
                          ],
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.escritos", {
                                inicial:
                                  _vm.$v.dictamenCompleto.comentarios.$model
                                    ?.length + _vm.caracter_inicial,
                              })
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.dictamenCompleto.comentarios.$model?.length >
                    _vm.caracteres_maximos
                      ? _c("small", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.sobrepasados")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "border-top mt-4 mb-4" }, [
                  _c("div", { staticClass: "mb-5 float-right" }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "mb-5 float-right" },
                  [
                    this.revision.estado === this.aceptada &&
                    _vm.$v.dictamenCompleto.comentarios.min &&
                    _vm.$v.dictamenCompleto.comentarios.max
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "md",
                              disabled:
                                _vm.hasAnyAuthority("ROLE_OPERADOR") &&
                                _vm.authenticated,
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("font-awesome-icon", {
                              staticClass: "mr-2",
                              attrs: { icon: "save" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }