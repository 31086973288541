import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class EmptyDataComponent extends Vue {
  @Prop({ default: 'info-circle' })
  public icon: string;

  @Prop()
  public message: string;

  @Prop()
  public hint: string;

  get messageDetail() {
    return this.message ?? this.$t('global.messages.alert.info');
  }

  get hintDetail() {
    return this.hint ?? this.$t('global.messages.alert.add-items');
  }

  @Emit('add')
  public handleAddData() {
    return true;
  }
}
