import { Authority } from '@/shared/security/authority';
import { ChildProcess } from 'child_process';
import path from 'path';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Solicitud = () => import('@/entities/solicitud/recepcion/solicitud.vue');
// prettier-ignore
const SolicitudUpdate = () => import('@/entities/solicitud/recepcion/solicitud-update.vue');

// prettier-ignore
const SolicitudAdministrar = () => import('@/entities/solicitud/administracion/solicitud.vue');
// prettier-ignore
const SolicitudAsignarRevisor = () => import('@/entities/solicitud/administracion/solicitud-asignar-revisor.vue');

// prettier-ignore
const Solucion = () => import('@/entities/solucion/solucion.vue');
// prettier-ignore
const SolucionUpdate = () => import('@/entities/solucion/solucion-update.vue');
// prettier-ignore
const Convocatoria = () => import('@/entities/convocatoria/convocatoria.vue');
// prettier-ignore
const Revision = () => import('@/entities/revision/revision.vue');
// prettier-ignore
const RevisionUpdate = () => import('@/entities/revision/revision-update.vue');
// prettier-ignore
const Form = () => import('@/entities/form/form.vue');
// prettier-ignore
const FormUpdate = () => import('@/entities/form/form-update.vue');
// prettier-ignore
const FormDetails = () => import('@/entities/form/form-details.vue');
// prettier-ignore
const Pago = () => import('@/entities/administracion/pago/pago.vue');

const Insaculacion = () => import('@/entities/evaluacion/insaculacion/insaculacion.vue');

const AdminComisionesList = () => import('@/entities/evaluacion/insaculacion/adminComisionesList.vue');

const AsignacionSolicitudes = () => import('@/entities/evaluacion/asignacion/asignacionSolicitudes.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
// prettier-ignore

const bandejaSolicitudes = () => import('@/entities/evaluacion/bandeja-solicitudes/bandeja-solicitudes.vue');

const bandejaPlenariaSolicitud = () => import('@/entities/evaluacion/bandeja-plenaria-solicitud/bandeja-plenaria-solicitud.vue');

const bandejaAdministracion = () => import('@/entities/evaluacion/bandeja-administracion/bandeja-administracion.vue');

const DictamenPlenaria = () => import('@/entities/evaluacion/dictamen-plenaria/dictamen-plenaria.vue');

const ConsejoGeneral = () => import('@/entities/evaluacion/dictamen-plenaria/consejo-general.vue');

const RegitroMovimientoOperador = () => import('@/components/movimientos/registro-movimiento-operador/registro-movimiento-operador.vue');

const DocumentosAcreditaciones = () => import('@/entities/acreditaciones/documentos-acreditaciones.vue');

const Reportes = () => import('@/entities/reportes/reportes.vue');

const CancelarSolicitudes = () => import('@/entities/solicitud/cancelacion/solicitud-cancelar.vue');

const BandejaConvenios = () => import('@/entities/evaluacion/formalizacion/bandeja-convenios.vue');

const BandejaEvaluador = () => import('@/entities/becas/evaluacion-extranjeras/bandeja-evaluador/bandeja-evaluador.vue');

const Sicob = () => import('@/entities/sicob/sicob.vue');

//Seguimiento administrativo
const ConsultaBecas = () => import('@/entities/seguimiento/consulta-becas/consulta-becas.vue');
const DetalleBeca = () => import('@/entities/seguimiento/detalle-beca/detalle-beca.vue');
const LiberacionBecas = () => import('@/entities/seguimiento/liberacion-beca/liberacion-beca.vue');
const SeguimientoBecasNacionales = () => import('@/entities/seguimiento/seguimiento-menu.vue');
const ConsultarMovimiento = () => import('@/entities/seguimiento/movimiento-beca/movimiento-beca-formulario.vue');
const AgregarMovimiento = () => import('@/entities/seguimiento/movimiento-beca/movimiento-beca-formulario.vue');
const ConsultaMovimientosBecas = () => import('@/entities/seguimiento/consulta-movimientos/consulta-movimientos.vue');

//Seguimiento financiero
const ConsultaNominas = () => import('@/entities/seguimiento/consulta-nominas/consulta-nominas.vue');
const Configuracion = () => import('@/entities/seguimiento/financiero/configuracion/configuracion.vue');
const Prenomina = () => import('@/entities/seguimiento/financiero/prenomina/prenomina.vue');
const Dispersion = () => import('@/entities/seguimiento/financiero/dispersion/dispersion.vue');
const Conciliacion = () => import('@/entities/seguimiento/financiero/conciliacion/conciliacion.vue');
const Cierre = () => import('@/entities/seguimiento/financiero/cierre/cierre.vue');
const PeriodosEvaluacionBecas = () => import('@/entities/seguimiento/periodo-evaluacion/periodos-evaluacion.vue');
const DetalleMovimientoAcademico = () => import('@/entities/seguimiento/academico/movimiento-academico-formulario.vue');
const ReportesBecasNacionales = () => import('@/entities/seguimiento/reportes/reportes-bn.vue');
const MovimientosPagoPeriodico = () => import('@/entities/seguimiento/financiero/movimiento-periodico/consulta-movimientos-periodico.vue');

//SNP
const SNPMenu = () => import('@/entities/snp/snp-menu.vue');
const Programas = () => import('@/entities/snp/administracion/programas/programas.vue');
const ReportesSnp = () => import('@/entities/snp/administracion/reportes/reportes-snp.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'solicitud/:solucionId/list',
      name: 'SolicitudList',
      component: Solicitud,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'solicitud',
      name: 'Solicitud',
      component: Solicitud,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'solicitud/new',
      name: 'SolicitudCreate',
      component: SolicitudUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'solicitud/:solicitudId/edit',
      name: 'SolicitudEdit',
      component: SolicitudUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'solicitud/:solucionId/edit',
      name: 'SolicitudCreateFromSolucion',
      component: SolicitudUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'solicitud/:solicitudId/view',
      name: 'SolicitudView',
      component: SolicitudUpdate,
      meta: { authorities: [Authority.USER], readOnly: true },
    },
    {
      path: 'solicitud/administracion',
      name: 'SolicitudAdministrar',
      component: SolicitudAdministrar,
      meta: { authorities: [Authority.OPERADOR, Authority.SOPORTE] },
    },
    {
      path: 'solicitud/:solicitudId/administracion/asignar-revisor/edit',
      name: 'SolicitudAsignarRevisor',
      component: SolicitudAsignarRevisor,
      meta: { authorities: [Authority.OPERADOR, Authority.SOPORTE] },
    },
    {
      path: 'solucion',
      name: 'Solucion',
      component: Solucion,
      meta: { authorities: [Authority.INNOVADOR] },
    },
    {
      path: 'solucion/new',
      name: 'SolucionCreate',
      component: SolucionUpdate,
      meta: { authorities: [Authority.INNOVADOR] },
    },
    {
      path: 'solucion/:solucionId/edit',
      name: 'SolucionEdit',
      component: SolucionUpdate,
      meta: { authorities: [Authority.INNOVADOR] },
    },
    {
      path: 'convocatorias',
      name: 'Convocatoria',
      component: Convocatoria,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'revision',
      name: 'Revision',
      component: Revision,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'revision/new',
      name: 'RevisionCreate',
      component: RevisionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'revision/:solicitudId/:revisionId/:evaluacionId/edit',
      name: 'RevisionEdit',
      component: RevisionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'form',
      name: 'Form',
      component: Form,
      meta: { authorities: [Authority.MODELADOR] },
    },
    {
      path: 'form/new',
      name: 'FormCreate',
      component: FormUpdate,
      meta: { authorities: [Authority.MODELADOR] },
    },
    {
      path: 'form/:formId/edit',
      name: 'FormEdit',
      component: FormUpdate,
      meta: { authorities: [Authority.MODELADOR] },
    },
    {
      path: 'form/:formId/view',
      name: 'FormView',
      component: FormDetails,
      meta: { authorities: [Authority.MODELADOR] },
    },
    {
      path: 'administracion/pago',
      name: 'Pago',
      component: Pago,
      meta: { authorities: [Authority.INNOVADOR] },
    },
    {
      path: 'bandejaSolicitudes',
      name: 'bandeja-solicitudes',
      component: bandejaSolicitudes,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'evaluacion/insaculacion',
      name: 'Insaculacion',
      component: Insaculacion,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: 'evaluacion/adminComisionesList',
      name: 'AdminComisionesList',
      component: AdminComisionesList,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: 'asignacion/asignacionSolicitudes',
      name: 'AsignacionSolicitudes',
      component: AsignacionSolicitudes,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: 'bandejaPlenariaSolicitud',
      name: 'BandejaPlenariaSolicitud',
      component: bandejaPlenariaSolicitud,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bandeja/administracion',
      name: 'BandejaAdministracion',
      component: bandejaAdministracion,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: 'evaluacion/dictamenPlenaria',
      name: 'DictamenPlenaria',
      component: DictamenPlenaria,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/ConsejoGeneral',
      name: 'ConsejoGeneral',
      component: ConsejoGeneral,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: '/regitroMovimientoOperador',
      name: 'RegitroMovimientoOperador',
      component: RegitroMovimientoOperador,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: 'documentos/Acreditaciones',
      name: 'DocumentosAcreditaciones',
      component: DocumentosAcreditaciones,
      meta: { authorities: [Authority.SOPORTE] },
    },
    {
      path: '/reportes',
      name: 'Reportes',
      component: Reportes,
      meta: { authorities: [Authority.OPERADOR, Authority.AUDITOR] },
    },
    {
      path: '/consultaBecas',
      name: 'ConsultaBecas',
      component: ConsultaBecas,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA, Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/liberacionBecas',
      name: 'LiberacionBecas',
      component: LiberacionBecas,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA, Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/detalleBeca/:becaId',
      name: 'DetalleBeca',
      component: DetalleBeca,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/seguimientoBecasNacionales',
      name: 'SeguimientoBecasNacionales',
      component: SeguimientoBecasNacionales,
      children: [
        {
          path: 'reportes-bn',
          name: 'reportes-bn',
          component: ReportesBecasNacionales,
        },
      ],
      meta: { authorities: [Authority.OPERADOR_PROGRAMA, Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/movimientoBeca/:movimientoBecaId',
      name: 'MovimientoBeca',
      component: ConsultarMovimiento,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cancelar/solicitudes',
      name: 'CancelarSolicitudes',
      component: CancelarSolicitudes,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: '/movimientoBeca/:becaId',
      name: 'AgregarMovimientoBeca',
      component: AgregarMovimiento,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/consultaMovimientosBecas',
      name: 'ConsultaMovimientosBecas',
      component: ConsultaMovimientosBecas,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/movimientoBeca/:movimientoBecaId/:permisoRevision',
      name: 'ConsultarMovimientoBeca',
      component: ConsultarMovimiento,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/bandejaConvenios',
      name: 'BandejaConvenios',
      component: BandejaConvenios,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: '/becas-extranjeras/evaluacion',
      name: 'BandejaEvaluador',
      component: BandejaEvaluador,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/sicob',
      name: 'Sicob',
      component: Sicob,
      meta: { authorities: [Authority.OPERADOR] },
    },
    {
      path: '/consultaNominas',
      name: 'ConsultaNominas',
      component: ConsultaNominas,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA, Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/configuracion/:idNomina',
      name: 'Configuracion',
      component: Configuracion,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA] },
    },
    {
      path: '/prenomina/:idNomina',
      name: 'Prenomina',
      component: Prenomina,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA] },
    },
    {
      path: '/dispersion/:idNomina',
      name: 'Dispersion',
      component: Dispersion,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA] },
    },
    {
      path: '/conciliacion/:idNomina',
      name: 'Conciliacion',
      component: Conciliacion,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA] },
    },
    {
      path: '/cierre/:idNomina',
      name: 'Cierre',
      component: Cierre,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA] },
    },
    {
      path: '/movimientosPeriodicos',
      name: 'MovimientosPeriodicos',
      component: MovimientosPagoPeriodico,
      meta: { authorities: [Authority.OPERADOR_PROGRAMA, Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/periodosEvaluacionBecas',
      name: 'PeriodosEvaluacionBecas',
      component: PeriodosEvaluacionBecas,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/snp',
      component: SNPMenu,
      name: 'SNPMenu',
      children: [
        {
          path: 'programas',
          name: 'Programas',
          component: Programas,
        },
        {
          path: 'reportes-snp',
          name: 'reportes-snp',
          component: ReportesSnp,
        },
      ],
      meta: { authorities: [Authority.AUDITOR, Authority.USER] },
    },
    {
      path: '/movimientoAcademico/:idBeca/:idPeriodo',
      name: 'DetalleMovimientoAcademico',
      component: DetalleMovimientoAcademico,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
