var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _vm.mock
            ? [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "INE",
                      "label-cols-sm": "2",
                      "label-size": "sm",
                    },
                  },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "col-2 text-right",
                      attrs: { size: "lg" },
                    }),
                    _vm._v(" "),
                    _c("b-form-file", { attrs: { size: "sm" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "RFC",
                      "label-cols-sm": "2",
                      "label-size": "sm",
                    },
                  },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "col-2 text-right",
                      attrs: { size: "lg" },
                    }),
                    _vm._v(" "),
                    _c("b-form-file", { attrs: { size: "sm" } }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "b-row",
                  [
                    _vm.configuracion.documentos
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _vm.isLoading
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _c("b-skeleton-table", {
                                          staticClass: "ml-3",
                                          attrs: {
                                            rows: 5,
                                            columns: 3,
                                            "hide-header": true,
                                            "table-props": {
                                              bordered: false,
                                              striped: false,
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("b-skeleton", {
                                          staticClass: "ml-3",
                                          attrs: { type: "button" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _vm.properties
                                          ? _c(
                                              "b-row",
                                              {
                                                attrs: {
                                                  set: (_vm.executions = []),
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { md: "12" } },
                                                  [
                                                    _vm._l(
                                                      _vm.properties,
                                                      function (
                                                        property,
                                                        index
                                                      ) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "b-row",
                                                                {
                                                                  attrs: {
                                                                    set: (_vm.executions[
                                                                      index
                                                                    ] = _vm.execute(
                                                                      property
                                                                        .config
                                                                        .expresion
                                                                    )),
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .executions[
                                                                    index
                                                                  ]?.data
                                                                    ? _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "b-card",
                                                                            {
                                                                              key: index,
                                                                              staticClass:
                                                                                "mt-3 custom-card-documentos",
                                                                              attrs:
                                                                                {
                                                                                  title:
                                                                                    property
                                                                                      .config
                                                                                      .title,
                                                                                  "border-variant":
                                                                                    _vm
                                                                                      .documentoModel[
                                                                                      property
                                                                                        .key
                                                                                    ]
                                                                                      ?.check
                                                                                      ? "success"
                                                                                      : _vm
                                                                                          .documentoModel[
                                                                                          property
                                                                                            .key
                                                                                        ]
                                                                                          ?.check ==
                                                                                        null
                                                                                      ? ""
                                                                                      : "danger",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "b-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          md: "12",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-row",
                                                                                        [
                                                                                          _c(
                                                                                            "b-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  md: "8",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm
                                                                                                .documentoModel[
                                                                                                property
                                                                                                  .key
                                                                                              ]
                                                                                                .uri
                                                                                                ? [
                                                                                                    _c(
                                                                                                      "b-link",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            href: _vm
                                                                                                              .documentoModel[
                                                                                                              property
                                                                                                                .key
                                                                                                            ]
                                                                                                              .uri,
                                                                                                            target:
                                                                                                              "_blank",
                                                                                                            rel: "noreferrer",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "b-icon",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                icon: "file-earmark-arrow-down-fill",
                                                                                                                variant:
                                                                                                                  "success",
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                        _vm._v(
                                                                                                          "\n                                        " +
                                                                                                            _vm._s(
                                                                                                              _vm
                                                                                                                .documentoModel[
                                                                                                                property
                                                                                                                  .key
                                                                                                              ]
                                                                                                                .nombre
                                                                                                            )
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                : [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        !_vm
                                                                                                          .documentoModel[
                                                                                                          property
                                                                                                            .key
                                                                                                        ]
                                                                                                          .uri &&
                                                                                                        _vm.isUpdating(
                                                                                                          index
                                                                                                        )
                                                                                                          ? _c(
                                                                                                              "b-icon",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    variant:
                                                                                                                      "primary",
                                                                                                                    icon: "arrow-clockwise",
                                                                                                                    animation:
                                                                                                                      "spin-pulse",
                                                                                                                    "font-scale":
                                                                                                                      "2",
                                                                                                                  },
                                                                                                              }
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _c(
                                                                                                          "b-form-file",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                disabled:
                                                                                                                  _vm.isUpdating(
                                                                                                                    index
                                                                                                                  ) ||
                                                                                                                  _vm.readOnly ||
                                                                                                                  !_vm.disabledValidation(),
                                                                                                                placeholder:
                                                                                                                  "Seleccionar archivo",
                                                                                                                "drop-placeholder":
                                                                                                                  "Arrastre un archivo aquí...",
                                                                                                                accept:
                                                                                                                  _vm.toFileExtension(
                                                                                                                    property
                                                                                                                      .config
                                                                                                                      .mediaTypes
                                                                                                                  ),
                                                                                                              },
                                                                                                            on: {
                                                                                                              input:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.onReadFile(
                                                                                                                    $event,
                                                                                                                    _vm
                                                                                                                      .documentoModel[
                                                                                                                      property
                                                                                                                        .key
                                                                                                                    ],
                                                                                                                    index
                                                                                                                  )
                                                                                                                },
                                                                                                            },
                                                                                                            model:
                                                                                                              {
                                                                                                                value:
                                                                                                                  _vm.currentFile,
                                                                                                                callback:
                                                                                                                  function (
                                                                                                                    $$v
                                                                                                                  ) {
                                                                                                                    _vm.currentFile =
                                                                                                                      $$v
                                                                                                                  },
                                                                                                                expression:
                                                                                                                  "currentFile",
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                        _vm._v(
                                                                                                          " "
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "small",
                                                                                                          {
                                                                                                            domProps:
                                                                                                              {
                                                                                                                innerHTML:
                                                                                                                  _vm._s(
                                                                                                                    _vm.$t(
                                                                                                                      "archivosApp.documento.extensiones",
                                                                                                                      {
                                                                                                                        param:
                                                                                                                          _vm.toFileExtension(
                                                                                                                            property
                                                                                                                              .config
                                                                                                                              .mediaTypes
                                                                                                                          ),
                                                                                                                      }
                                                                                                                    )
                                                                                                                  ),
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "b-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  md: "4",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm.showValidation(
                                                                                                _vm
                                                                                                  .documentoModel[
                                                                                                  property
                                                                                                    .key
                                                                                                ]
                                                                                                  .check
                                                                                              )
                                                                                                ? [
                                                                                                    !_vm.disabledValidation()
                                                                                                      ? _c(
                                                                                                          "b-form-group",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "mt-1",
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "b-form-radio-group",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    "button-variant":
                                                                                                                      "outline-danger",
                                                                                                                    options:
                                                                                                                      _vm.options,
                                                                                                                  },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      _vm
                                                                                                                        .documentoModel[
                                                                                                                        property
                                                                                                                          .key
                                                                                                                      ]
                                                                                                                        .check,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.$set(
                                                                                                                          _vm
                                                                                                                            .documentoModel[
                                                                                                                            property
                                                                                                                              .key
                                                                                                                          ],
                                                                                                                          "check",
                                                                                                                          $$v
                                                                                                                        )
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "documentoModel[property.key].check",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : _c(
                                                                                                          "div",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "b-icon",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    "font-scale":
                                                                                                                      "1.5",
                                                                                                                    variant:
                                                                                                                      _vm
                                                                                                                        .documentoModel[
                                                                                                                        property
                                                                                                                          .key
                                                                                                                      ]
                                                                                                                        .check
                                                                                                                        ? "success"
                                                                                                                        : _vm
                                                                                                                            .documentoModel[
                                                                                                                            property
                                                                                                                              .key
                                                                                                                          ]
                                                                                                                            ?.check ==
                                                                                                                          null
                                                                                                                        ? "warning"
                                                                                                                        : "danger",
                                                                                                                    icon: _vm
                                                                                                                      .documentoModel[
                                                                                                                      property
                                                                                                                        .key
                                                                                                                    ]
                                                                                                                      .check
                                                                                                                      ? "check-circle"
                                                                                                                      : _vm
                                                                                                                          .documentoModel[
                                                                                                                          property
                                                                                                                            .key
                                                                                                                        ]
                                                                                                                          ?.check ==
                                                                                                                        null
                                                                                                                      ? "exclamation-circle"
                                                                                                                      : "x-circle",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              "\n                                        " +
                                                                                                                _vm._s(
                                                                                                                  _vm
                                                                                                                    .documentoModel[
                                                                                                                    property
                                                                                                                      .key
                                                                                                                  ]
                                                                                                                    .check
                                                                                                                    ? "Aceptado"
                                                                                                                    : _vm
                                                                                                                        .documentoModel[
                                                                                                                        property
                                                                                                                          .key
                                                                                                                      ]
                                                                                                                        ?.check ==
                                                                                                                      null
                                                                                                                    ? "Pendiente de revisión"
                                                                                                                    : "Rechazado"
                                                                                                                ) +
                                                                                                                "\n                                      "
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                  ]
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "b-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  md: "12",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              !_vm.readOnly ||
                                                                                              _vm.disabledValidation()
                                                                                                ? [
                                                                                                    _c(
                                                                                                      "b-form-group",
                                                                                                      {
                                                                                                        directives:
                                                                                                          [
                                                                                                            {
                                                                                                              name: "show",
                                                                                                              rawName:
                                                                                                                "v-show",
                                                                                                              value:
                                                                                                                _vm
                                                                                                                  .documentoModel[
                                                                                                                  property
                                                                                                                    .key
                                                                                                                ]
                                                                                                                  ?.check ===
                                                                                                                  false &&
                                                                                                                _vm.permiteComentar &&
                                                                                                                _vm.isOperador,
                                                                                                              expression:
                                                                                                                "documentoModel[property.key]?.check === false && permiteComentar && isOperador",
                                                                                                            },
                                                                                                          ],
                                                                                                        staticClass:
                                                                                                          "mt-2",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            label:
                                                                                                              _vm.$t(
                                                                                                                "archivosApp.documento.comentarios.comentario"
                                                                                                              ),
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "b-form-textarea",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "w-100",
                                                                                                            attrs:
                                                                                                              {
                                                                                                                id: "select-nombre-id",
                                                                                                                rows: "3",
                                                                                                                "max-rows":
                                                                                                                  "8",
                                                                                                                disabled:
                                                                                                                  _vm.isUpdating(
                                                                                                                    index
                                                                                                                  ) ||
                                                                                                                  _vm.readOnly,
                                                                                                              },
                                                                                                            model:
                                                                                                              {
                                                                                                                value:
                                                                                                                  _vm
                                                                                                                    .documentoModel[
                                                                                                                    property
                                                                                                                      .key
                                                                                                                  ]
                                                                                                                    .comentario,
                                                                                                                callback:
                                                                                                                  function (
                                                                                                                    $$v
                                                                                                                  ) {
                                                                                                                    _vm.$set(
                                                                                                                      _vm
                                                                                                                        .documentoModel[
                                                                                                                        property
                                                                                                                          .key
                                                                                                                      ],
                                                                                                                      "comentario",
                                                                                                                      $$v
                                                                                                                    )
                                                                                                                  },
                                                                                                                expression:
                                                                                                                  "documentoModel[property.key].comentario",
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "float-right",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm
                                                                                                          .documentoModel[
                                                                                                          property
                                                                                                            .key
                                                                                                        ]
                                                                                                          ?.check ===
                                                                                                          false &&
                                                                                                        _vm.isOperador
                                                                                                          ? _c(
                                                                                                              "b-button",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    disabled:
                                                                                                                      !_vm
                                                                                                                        .documentoModel[
                                                                                                                        property
                                                                                                                          .key
                                                                                                                      ]
                                                                                                                        ?.comentario,
                                                                                                                    variant:
                                                                                                                      "primary",
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.agregarComentario(
                                                                                                                        property.key
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    domProps:
                                                                                                                      {
                                                                                                                        textContent:
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "archivosApp.documento.comentarios.comentar"
                                                                                                                            )
                                                                                                                          ),
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  " "
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "b-icon",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "pencil",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _vm._v(
                                                                                                          " "
                                                                                                        ),
                                                                                                        _vm
                                                                                                          .documentoModel[
                                                                                                          property
                                                                                                            .key
                                                                                                        ]
                                                                                                          ?.comentarios
                                                                                                          ? _c(
                                                                                                              "b-button",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    variant:
                                                                                                                      "primary",
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.handleOpenComentariosModal(
                                                                                                                        property.key
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    domProps:
                                                                                                                      {
                                                                                                                        textContent:
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "archivosApp.documento.comentarios.verComentarios"
                                                                                                                            )
                                                                                                                          ),
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  " "
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "b-icon",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "list-task",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                : _vm._e(),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              !_vm.readOnly
                                                                                                ? [
                                                                                                    _vm
                                                                                                      .documentoModel[
                                                                                                      property
                                                                                                        .key
                                                                                                    ]
                                                                                                      .uri &&
                                                                                                    !_vm
                                                                                                      .documentoModel[
                                                                                                      property
                                                                                                        .key
                                                                                                    ]
                                                                                                      .check &&
                                                                                                    !_vm.isOperador
                                                                                                      ? _c(
                                                                                                          "b-button",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                variant:
                                                                                                                  "outline-danger",
                                                                                                                type: "button",
                                                                                                                size: "sm",
                                                                                                                disabled:
                                                                                                                  _vm.isUpdating(
                                                                                                                    index
                                                                                                                  ),
                                                                                                              },
                                                                                                            on: {
                                                                                                              click:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.handleDeleteDocumento(
                                                                                                                    _vm
                                                                                                                      .documentoModel[
                                                                                                                      property
                                                                                                                        .key
                                                                                                                    ],
                                                                                                                    index
                                                                                                                  )
                                                                                                                },
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm.isUpdating(
                                                                                                              index
                                                                                                            )
                                                                                                              ? _c(
                                                                                                                  "b-icon",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "arrow-clockwise",
                                                                                                                        animation:
                                                                                                                          "spin-pulse",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                            _c(
                                                                                                              "span",
                                                                                                              {
                                                                                                                domProps:
                                                                                                                  {
                                                                                                                    textContent:
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "archivosApp.documento.reemplazar"
                                                                                                                        )
                                                                                                                      ),
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _vm.configuracion
                                                  .hasMoreDocuments &&
                                                _vm.canAddMoreDocuments
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        staticClass: "btn-zoom",
                                                        attrs: {
                                                          variant:
                                                            "outline-success",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.handleOpenAddNewDocumentModal,
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "plus",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("b", [
                                                          _c("i", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "archivosApp.documento.agregar"
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "b-modal",
                                                  {
                                                    ref: "addNewDocument",
                                                    attrs: {
                                                      "no-close-on-esc": "",
                                                      "no-close-on-backdrop":
                                                        "",
                                                      "hide-header-close": "",
                                                      size: "xl",
                                                      id: "addNewDocument",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "modal-title",
                                                        },
                                                        slot: "modal-title",
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "archivosApp.documento.agregar"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modal-body",
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              "label-cols": "6",
                                                              "label-cols-lg":
                                                                "6",
                                                              label:
                                                                "Nombre del archivo",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "input-new-file-name",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newFileName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.newFileName =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "newFileName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "modal-footer",
                                                        },
                                                        slot: "modal-footer",
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn btn-secondary",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.cancel"
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleCancel()
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("button", {
                                                          staticClass:
                                                            "btn btn-primary",
                                                          attrs: {
                                                            type: "button",
                                                            id: "jhi-confirm-delete-solicitud",
                                                            "data-cy":
                                                              "entityConfirmAddAutoridadButton",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "entity.action.save"
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleAddDocument()
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-modal",
                                                  {
                                                    ref: "verComentarios",
                                                    attrs: {
                                                      "no-close-on-esc": "",
                                                      "no-close-on-backdrop":
                                                        "",
                                                      "hide-header-close": "",
                                                      size: "xl",
                                                      id: "verComentarios",
                                                      scrollable: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "modal-title",
                                                          fn: function () {
                                                            return [
                                                              _c("span", {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "archivosApp.documento.comentarios.title"
                                                                      )
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      561759586
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modal-body",
                                                      },
                                                      [
                                                        _vm.comentarios.length >
                                                        0
                                                          ? _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      md: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card mt-12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-overlay",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                rounded:
                                                                                  "sm",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-table",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "sticky-header":
                                                                                      "500px",
                                                                                    fields:
                                                                                      _vm.configuracionColumnasComent,
                                                                                    items:
                                                                                      _vm.comentarios,
                                                                                    "head-variant":
                                                                                      "primary",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "cell(archivo)",
                                                                                        fn: function (
                                                                                          data
                                                                                        ) {
                                                                                          return [
                                                                                            _c(
                                                                                              "b-icon",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    icon: "file-earmark-arrow-down-fill",
                                                                                                    variant:
                                                                                                      "success",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "b-link",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    href: data
                                                                                                      .item
                                                                                                      .uri,
                                                                                                    target:
                                                                                                      "_blank",
                                                                                                    rel: "noreferrer",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    data
                                                                                                      .item
                                                                                                      .nombreArchivo
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    false,
                                                                                    3122584857
                                                                                  ),
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "card-footer text-muted",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "archivosApp.documento.comentarios.total",
                                                                                        {
                                                                                          total:
                                                                                            _vm
                                                                                              .comentarios
                                                                                              .length,
                                                                                        }
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "modal-footer",
                                                        },
                                                        slot: "modal-footer",
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.hideModalComentarios,
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "entity.action.cancel"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "x",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-col", { attrs: { md: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id: "save-entity",
                                                disabled: _vm.isSaving,
                                                variant: "primary",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(true)
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("entity.action.save")
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-icon", {
                                                attrs: { icon: "save" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }