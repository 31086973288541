import { render, staticRenderFns } from "./menu-skeleton.vue?vue&type=template&id=4f19ac60&scoped=true&"
import script from "./menu-skeleton.component.ts?vue&type=script&lang=ts&"
export * from "./menu-skeleton.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f19ac60",
  null
  
)

export default component.exports