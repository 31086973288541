import EntityFactory, { ICatalogosDictamen, INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { TipoPerfil } from '@/shared/model/enumerations/tipo-perfil.model';
import { mapGetters, mapMutations } from 'vuex';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { Solucion } from '@/shared/model/solucion.model';
import AlertService from '@/shared/alert/alert.service';
import { Revision } from '@/shared/model/revision.model';
import HomeMenuComponent from '@/components/home-menu/home-menu.component';

const validations = function () {
  const { caracteresMinimos, caracteresMaximos } = this as DictamenComponet;
  return {
    dictamenCompleto: {
      comentarios: {
        min: minLength(caracteresMinimos),
        max: maxLength(caracteresMaximos),
        required,
      },
    },
  };
};

@Component({
  validations,
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters(['dictamen', 'evaluacion']),
  },
  methods: {
    ...mapMutations(['setDictamen', 'setEvaluacion']),
  },
})
export default class DictamenComponet extends mixins(HomeMenuComponent) {
  @Inject('alertService') public alertService: () => AlertService;

  @Prop({ required: true })
  public solicitud: any;

  @Prop({ required: true })
  public revision: Revision;

  @Prop({ required: false, default: true })
  public evaluacionIndividual: boolean;

  @Prop({ required: true })
  public solucion: Solucion;

  public idNoAprobado = NRecomendadoEnum.NO_APROBADO;

  public dictamenCompleto: any = {};

  public readonly dictamen: any;

  public readonly evaluacion: INivel;

  public accepted2 = true;

  public aceptada = EstadoRevision.ACEPTADA;
  public revisada = EstadoRevision.REVISADA;

  public frontera = TipoPerfil.FRONTERA;
  public socioambiental = TipoPerfil.SOCIOAMBIENTAL;
  public tecnológica = TipoPerfil.TECNOLOGICA;

  public dictamenPositivo: string[] = ['1', '2', '3', '4'];
  public dictamenNegativo: string[] = ['3', '4'];
  public dictamenInnovacionNegativo: string[] = ['2'];

  public readonly caracterInicial = 0;
  public readonly caracteresFaltantes = 139;
  public readonly caracteresMinimos = 140;
  public readonly caracteresMaximos = 10000;

  public claveComponent = '.i';

  public claseComponent = 'i';

  /**
   * Asignación de los catalogos que se encuentran en el entityFactory con los cuales se renderizaran dinámicamente
   * las <options> de los <select> dinámicos.
   */
  public catalogos = {
    capacidad1: EntityFactory.nivelCapacidad,
    participacion: EntityFactory.nivelParticipacionPromocion,
    innovacionNivel: EntityFactory.nivelInnovacionNivel,
    involucramiento: EntityFactory.nivelInvolucramiento,
    liderazgo: EntityFactory.nivelLiderazgo,
    demuestraNegativo: EntityFactory.nivelDemuestra,
    capacidadNegativo: EntityFactory.nivelCapacidad,
    cumpleNegativo1: EntityFactory.nivelCumple,
    presentaAmbiental: EntityFactory.nivelPresenta,
    involucramientoNegativo: EntityFactory.nivelInvolucramiento,
    cuentaNegativo5: EntityFactory.nivelCuenta,
    liderazgNegativo: EntityFactory.nivelLiderazgo,
    participando: EntityFactory.nivelParticipando,
    innovacionNivelNegativo: EntityFactory.nivelInnovacionNivel,
    presentaFrontera: EntityFactory.nivelPresenta,
    tecnologias: EntityFactory.nivelTecnologias,
    capacidad2: EntityFactory.nivelCapacidad,
    capacidad4: EntityFactory.nivelCapacidad,
    nivel: EntityFactory.nivelNivel,
    nivelMadurez: EntityFactory.nivelMadurez,
    mejoraVida: EntityFactory.nivelMejoraVida,
    autor: EntityFactory.nivelAutor,
    capacidad3: EntityFactory.nivelCapacidad,
    demuestraInnovacion: EntityFactory.nivelDemuestra,
    tecnologiasdemuestraInnovacion: EntityFactory.nivelTecnologias,
    capacidaddemuestraInnovacion: EntityFactory.nivelCapacidad,
    cumpledemuestraInnovacion: EntityFactory.nivelCumple,
    innovacionNivelInnovacion: EntityFactory.nivelInnovacionNivel,
    presentaNegativo: EntityFactory.nivelPresenta,
    demuestraNegativo1: EntityFactory.nivelDemuestra,
    tecnologiasNegativo2: EntityFactory.nivelTecnologias,
    capacidad5: EntityFactory.nivelCapacidad,
    acredita: EntityFactory.nivelAcredita,
    demuestraNegativo3: EntityFactory.nivelDemuestra,
    demuestraNegativo4: EntityFactory.nivelDemuestra,
    autorNegativo: EntityFactory.nivelAutor,
    cumpleNegativo4: EntityFactory.nivelCumple,
    presentaNegativo4: EntityFactory.nivelPresenta,
    cuentaTecno: EntityFactory.nivelCuenta,
    liderazgoTecno: EntityFactory.nivelLiderazgo,
  };

  setDictamen!: (dictamen: any) => void;

  setEvaluacion!: (evaluacion: INivel) => void;

  created() {
    this.claveComponent = this.claveComponent + this.revision.revisor.revisorId;
    this.claseComponent = this.claseComponent + this.revision.revisor.revisorId;
  }

  mounted(): void {
    this.get();
    this.establecerOptions();
    this.leerValores();
    this.setVariablesSolicitudRevision();
    this.setValorInicialCheckBox();
  }

  /**
   * Se considera en los dictamenes de Tecno una sección dinámica que consiste en mostrar dentro
   * del dictamen de 1 a 3 opciones dinámicas que a su vez incluyen las variables tratadas en los dictamenes anteriores.
   * Setteamos el valor correspondiente en el checkBox de las 3 opciones y renderizamos o no las opciones correspondientes
   *
   * @memberof DictamenComponet
   */
  setValorInicialCheckBox() {
    const check1: HTMLInputElement = document.querySelector(`${this.claveComponent} [id=opcion1]`) as HTMLInputElement;
    const opcion1: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion1Tecno]`) as HTMLSpanElement;

    const check2: HTMLInputElement = document.querySelector(`${this.claveComponent} [id=opcion2]`) as HTMLInputElement;
    const opcion2: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion2Tecno]`) as HTMLSpanElement;

    const check3: HTMLInputElement = document.querySelector(`${this.claveComponent} [id=opcion3]`) as HTMLInputElement;
    const opcion3: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion3Tecno]`) as HTMLSpanElement;

    if (check1 instanceof HTMLInputElement && check2 instanceof HTMLInputElement && check3 instanceof HTMLInputElement) {
      check1.addEventListener('change', () => {
        if (check1.checked) {
          opcion1.style.display = 'block';
        } else {
          opcion1.style.display = 'none';
        }
      });
      check2.addEventListener('change', () => {
        if (check2.checked) {
          opcion2.style.display = 'block';
        } else {
          opcion2.style.display = 'none';
        }
      });
      check3.addEventListener('change', () => {
        if (check3.checked) {
          opcion3.style.display = 'block';
        } else {
          opcion3.style.display = 'none';
        }
      });
    }
  }

  /**
   * Realiza una asignación de valores que se traen de la solicitud y revisión del usuario, hacia elementos HTML (<p>) que
   * son renderizados desde la cadena del dictamen recuperada de la solución.
   * Muestra valores dinámicos pero que no son editables, estan dados por procesos previos del flujo de la solicitud.
   *
   * @memberof DictamenComponet
   */
  setVariablesSolicitudRevision() {
    const claveArea = document.querySelector(`${this.claveComponent} [id=claveArea]`) as HTMLParagraphElement;
    const nombreArea = document.querySelector(`${this.claveComponent} [id=nombreArea]`) as HTMLParagraphElement;
    const respuestaComision = document.querySelector(`${this.claveComponent} [id=respuestaComision]`) as HTMLParagraphElement;
    const evaluacionNombre = document.querySelector(`${this.claveComponent} [id=evaluacionNombre]`) as HTMLParagraphElement;
    if (claveArea) {
      claveArea.innerText = this.solicitud.SOLICITUD_RIZOMA?.areaConocimiento?.area?.clave;
    }
    if (nombreArea) {
      nombreArea.innerText = this.solicitud.SOLICITUD_RIZOMA.areaConocimiento?.area?.nombre;
    }
    if (respuestaComision) {
      respuestaComision.innerText = this.revision.respuesta.comision;
    }
    if (evaluacionNombre) {
      evaluacionNombre.innerText = this.revision?.respuesta?.evaluacion?.nombre;
    }
  }

  /**
   * Obtiene el dictamen en la respuesta de la revisión correspondiente
   *
   * @memberof DictamenComponet
   */
  public get(): void {
    if (Object.keys(this.dictamen).length === 0) {
      this.crearEstructuraDictamen();
    }
    if (this.solicitud && this.revision) {
      if (this.revision?.respuesta?.dictamen && Object.keys(this.revision?.respuesta?.dictamen).length !== 0) {
        this.dictamenCompleto = this.revision.respuesta.dictamen;
      } else if (Object.keys(this.dictamen).length !== 0 && this.revision?.respuesta?.evaluacion) {
        this.dictamenCompleto = this.dictamen;
      } else if (!this.evaluacionIndividual) {
        this.dictamenCompleto = this.dictamen;
        this.revision.respuesta.evaluacion = this.evaluacion;
      }
    }
  }

  /**
   * Guarda el Dictamen actualizado con los valores que le ha dado el usuario.
   *
   * @memberof DictamenComponet
   */
  public guardarDictamen(): void {
    if (this.dictamenCompleto.checkExcepcion === true) {
      const check = this.dictamenCompleto.checkExcepcion ? this.dictamenCompleto.checkExcepcion : false;
      const coment = this.dictamenCompleto.comentarios;
      this.dictamenCompleto = {
        checkExcepcion: check,
        comentarios: coment,
      };
      const datosEnviar = { dictamen: this.dictamenCompleto, valido: true };
      this.$emit('guardarDictamen', datosEnviar);
    } else {
      this.establecerValores();
      if (
        this.dictamenCompleto?.opcion1 === false &&
        this.dictamenCompleto?.opcion2 === false &&
        this.dictamenCompleto?.opcion3 === false
      ) {
        this.alertService().showWarning(this, this.$t('Debe seleccionar al menos una opción para este dictamen.').toString());
        return;
      }
      if (
        (this.dictamenCompleto?.opcion1 === true || this.dictamenCompleto?.opcion2 === true || this.dictamenCompleto?.opcion3 === true) &&
        this.solucionTipoTecno
      ) {
        if (this.verificaPropiedades()) {
          this.setDictamen({});
          this.dictamenCompleto.checkExcepcion = false;
          const datosEnviar = { dictamen: this.dictamenCompleto, valido: true };
          this.$emit('guardarDictamen', datosEnviar);
        } else {
          this.alertService().showWarning(this, this.$t('Falta de campos obligatorios de las opciones que ha seleccionado.').toString());
          return;
        }
      } else {
        this.setDictamen({});
        this.dictamenCompleto.checkExcepcion = false;
        const datosEnviar = { dictamen: this.dictamenCompleto, valido: true };
        this.$emit('guardarDictamen', datosEnviar);
      }
    }
  }

  /**
   * (funcionalidad solo para el perfil Tecno)
   * Verifica, antes del guardado, que dado una opción seleccionada del checkbox del dictamen dinámico,
   * se cumplan las propiedades que incluye cada opción.
   * Funciona para Tecno-Positivo y Negativo
   *
   * @memberof DictamenComponet
   */
  verificaPropiedades(): boolean {
    const opcion1: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion1Tecno]`) as HTMLSpanElement;
    opcion1.style.border = '';
    opcion1.style.backgroundColor = '';
    const opcion2: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion2Tecno]`) as HTMLSpanElement;
    opcion2.style.border = '';
    opcion2.style.backgroundColor = '';
    const opcion3: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion3Tecno]`) as HTMLSpanElement;
    opcion3.style.border = '';
    opcion3.style.backgroundColor = '';
    let completaOpcion1 = true;
    let completaOpcion2 = true;
    let completaOpcion3 = true;
    if (this.dictamenCompleto.opcion1 === true) {
      if (
        this.revision?.respuesta?.evaluacion?.id !== '5' &&
        this.dictamenCompleto?.elementosValidosExcepcion3 !== '' &&
        this.dictamenCompleto?.elementosValidosExcepcion4 !== '' &&
        this.dictamenCompleto?.nivelMadurez?.id !== '' &&
        this.dictamenCompleto?.mejoraVida?.id !== ''
      ) {
        completaOpcion1 = true;
      } else if (this.revision?.respuesta?.evaluacion?.id === '5' && this.dictamenCompleto?.acredita.id !== '') {
        completaOpcion1 = true;
      } else {
        completaOpcion1 = false;
        opcion1.style.border = '2px solid red';
        opcion1.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
      }
    }
    if (this.dictamenCompleto.opcion2 === true) {
      if (this.revision?.respuesta?.evaluacion?.id != '5' && this.dictamenCompleto?.elementosValidosExcepcion5 != '') {
        completaOpcion2 = true;
      } else if (
        this.revision?.respuesta?.evaluacion?.id === '5' &&
        this.dictamenCompleto?.demuestraNegativo3.id != '' &&
        this.dictamenCompleto?.elementosValidosExcepcion11 != ''
      ) {
        completaOpcion2 = true;
      } else {
        completaOpcion2 = false;
        opcion2.style.border = '2px solid red';
        opcion2.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
      }
    }
    if (this.dictamenCompleto.opcion3 === true) {
      if (
        this.revision?.respuesta?.evaluacion?.id != '5' &&
        this.dictamenCompleto?.autor?.id != '' &&
        this.dictamenCompleto?.elementosValidosExcepcion6 != ''
      ) {
        completaOpcion3 = true;
      } else if (
        this.revision?.respuesta?.evaluacion?.id === '5' &&
        this.dictamenCompleto?.demuestraNegativo4.id != '' &&
        this.dictamenCompleto?.autorNegativo.id != '' &&
        this.dictamenCompleto?.elementosValidosExcepcion12 != ''
      ) {
        completaOpcion3 = true;
      } else {
        completaOpcion3 = false;
        opcion3.style.border = '2px solid red';
        opcion3.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
      }
    }
    return completaOpcion1 && completaOpcion2 && completaOpcion3;
  }

  /**
   * Se utiliza este método para iterar sobre todos las propiedades del dictamen recuperado de la Base de Datos
   * y asociar dicha propiedad al valor del elemento HTML correspondiente.
   *
   * @memberof DictamenComponet
   */
  leerValores() {
    const entries = Object.entries(this.dictamenCompleto);
    for (const [key, value] of entries) {
      const element = document.querySelector(`${this.claveComponent} [id=${key}]`);
      if (element) {
        if (this.revision.estado === this.revisada) {
          element['disabled'] = true;
        }
        if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'number') {
          element['value'] = value;
        } else if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'checkbox') {
          let checkedValue = false;
          value ? (checkedValue = true) : (checkedValue = false); // Convertir a booleano
          (element as HTMLInputElement).checked = checkedValue;
          if (!value && key === 'opcion1') {
            const opcion1: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion1Tecno]`) as HTMLSpanElement;
            opcion1.style.display = 'none';
          } else if (value && key === 'opcion1') {
            const opcion1: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion1Tecno]`) as HTMLSpanElement;
            opcion1.style.display = 'block';
          }
          if (!value && key === 'opcion2') {
            const opcion2: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion2Tecno]`) as HTMLSpanElement;
            opcion2.style.display = 'none';
          } else if (value && key === 'opcion2') {
            const opcion2: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion2Tecno]`) as HTMLSpanElement;
            opcion2.style.display = 'block';
          }
          if (!value && key === 'opcion3') {
            const opcion3: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion3Tecno]`) as HTMLSpanElement;
            opcion3.style.display = 'none';
          } else if (value && key === 'opcion3') {
            const opcion3: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion3Tecno]`) as HTMLSpanElement;
            opcion3.style.display = 'block';
          }
        } else if (element.tagName === 'SELECT') {
          element['value'] = value['id'];
        } else if (element.tagName === 'SPAN') {
          element['textContent'] = String(value);
        }
      }
    }
  }

  /**
   * Se itera sobre los elementos HTML correspondientes, que fueron renderizados de la cadena templete-string de la solución,
   * obteniendo el valor actual de dichos elementos
   * y asociando dicho valor, mediante el ID, al modelo del dictamenCompleto para ser persistido
   *
   * @memberof DictamenComponet
   */
  establecerValores() {
    if (
      this.dictamenCompleto?.checkExcepcion === false ||
      this.dictamenCompleto?.checkExcepcion === undefined ||
      this.dictamenCompleto?.checkExcepcion === null
    ) {
      const entries = Object.entries(this.dictamenCompleto);
      for (const [key, value] of entries) {
        const element = document.querySelector(`${this.claveComponent} [id=${key}]`);
        if (element) {
          if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'number') {
            this.dictamenCompleto[key] = element['value'];
          } else if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'checkbox') {
            this.dictamenCompleto[key] = (element as HTMLInputElement).checked;
          } else if (element.tagName === 'SELECT' && element['value'] !== '') {
            const option = element.querySelector("option[value='" + element['value'] + "']");
            const objGuardado = {
              id: element['value'],
              nombre: option['innerText'],
            };
            this.dictamenCompleto[key] = objGuardado;
          }
        }
      }
    }
  }

  /**
   * Crea la estructura para el objecto dictamen con las propiedades correspondientes
   * de los elementos HTML del dictamen ya renderizados.
   *
   * @memberof DictamenComponet
   */
  crearEstructuraDictamen() {
    const elementos = document.querySelectorAll('.dynamic input, .dynamic select');
    const dictamen = {};
    elementos.forEach((element: HTMLElement) => {
      if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'number') {
        dictamen[element.id] = '';
      } else if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'checkbox') {
        dictamen[element.id] = false;
        const opcion1: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion1Tecno]`) as HTMLSpanElement;
        opcion1.style.display = 'none';
        const opcion2: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion2Tecno]`) as HTMLSpanElement;
        opcion2.style.display = 'none';
        const opcion3: HTMLSpanElement = document.querySelector(`${this.claveComponent} [id=opcion3Tecno]`) as HTMLSpanElement;
        opcion3.style.display = 'none';
      } else if (element.tagName === 'SELECT') {
        const objGuardado = {
          id: '',
          nombre: '',
        };
        dictamen[element.id] = objGuardado;
      }
    });
    this.setDictamen(dictamen);
  }

  /**
   * Se establecen de manera dinpamica las opciones <option> de los <select>
   * que fueron renderizados desde la cadena templete-string. Nos basamos en los catalogos del EntityFactory
   * para generar las opciones y asociarlas al elemento select correspondiente mediante el ID.
   *
   * @memberof DictamenComponet
   */
  establecerOptions() {
    const entries = Object.entries(this.catalogos);
    for (const [key, value] of entries) {
      const select: HTMLSelectElement = document.querySelector(`${this.claveComponent} [id=${key}]`) as HTMLSelectElement;
      if (select) {
        value.forEach(catalogo => {
          const option = document.createElement('option');
          option.value = catalogo.id;
          option.text = catalogo.nombre;
          select.add(option);
        });
      }
    }
    if (this.solucionTipoTecno) {
      this.setValorInicialCheckBox();
    }
  }

  /**
   * Reestablece el objeto dictamen cuando se vuelve el checkException false
   * y se deja listo el componente para poder escribir un nuevo dictamen
   *
   * @memberof DictamenComponet
   */
  private cambiaExcepcion() {
    if (this.dictamenCompleto?.checkExcepcion === false) {
      this.setDictamen({});
      const datosEnviar = { dictamen: {}, valido: false };
      this.$emit('guardarDictamen', datosEnviar);
      this.get();
      this.establecerOptions();
      this.leerValores();
      this.setVariablesSolicitudRevision();
    }
  }

  /**
   * Comprueba si la solicitud es de tipo tecno para el dictamen
   * @returns Devuelve true si la solicitud es de tipo tecno, de lo contrario devuelve false.
   */
  public get solucionTipoTecno(): boolean {
    return this.solicitud?.SOLICITUD_RIZOMA?.tipoPerfil?.nombre === this.tecnológica;
  }

  /**
   * Validacion para deshabilitar el boton de guardar
   * @returns Devuelve true para deshabilitar el boton, de lo contrario devuelve false.
   */
  public get desactivarBoton(): boolean {
    return (
      (this.hasAnyAuthority('ROLE_OPERADOR') && this.authenticated) ||
      ((this.revision?.respuesta?.evaluacion?.id === this.idNoAprobado || this.dictamenCompleto.checkExcepcion) &&
        (!this.dictamenCompleto.comentarios ||
          this.dictamenCompleto.comentarios.length < this.caracteresMinimos ||
          this.dictamenCompleto.comentarios.length > this.caracteresMaximos))
    );
  }
}
