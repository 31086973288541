import { Component, Watch, Vue } from 'vue-property-decorator';
import * as steps from '@/shared/tour/pages';
@Component
export default class TourHandler extends Vue {
  public steps = [];
  cancelTour = false;

  mounted() {
    (this.$root as any).$on('cancel-tour', this.handleCancelTour);
  }

  beforeDestroy() {
    (this.$root as any).$off('cancel-tour', this.handleCancelTour);
  }

  created(): void {
    this.steps = steps.default.steps;
  }

  public handleCancelTour(cancel) {
    this.cancelTour = cancel;
  }

  public tourOptions = {
    useKeyboardNavigation: false,
    highlight: true,
    labels: {
      buttonSkip: 'Quitar tour',
      buttonPrevious: 'Previo',
      buttonNext: 'Siguiente',
      buttonStop: 'Finalizar',
    },
  };

  public tourCallbacks = {
    onStop: this.handleOnFinishTour,
  };

  public handleOnFinishTour(currentStep) {
    (this.$refs.tourCompleted as any).show();
    (this as any).$confetti.start();
  }

  public closeTourCompletedModal() {
    (this.$refs.tourCompleted as any).hide();
    (this as any).$confetti.stop();
  }

  @Watch('$route')
  onRouteChange(to, from) {
    if (to?.name) {
      if (to.name in steps) {
        this.steps = steps[to.name].steps;
        if (['SolicitudCreateFromSolucion'].indexOf(to.name) >= 0) {
          this.showTourWelcome();
        }
      } else {
        this.steps = steps.default.steps;
      }
    }
  }

  public showTourWelcome() {
    setTimeout(() => {
      if (!this.cancelTour) {
        (this.$refs.tourWelcome as any).show();
      }
      this.cancelTour = false;
    }, 5000);
  }

  public showTourWelcomeWithoutTimer() {
    (this.$refs.tourWelcome as any).show();
  }

  public showConfetti() {
    (this as any).$confetti.start();
    setTimeout(() => {
      (this as any).$confetti.stop();
    }, 10000);
  }

  public showHelp() {
    this.$tours['tour'].start();
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public handleConfirmation(): void {
    this.hideModal();
    this.showHelp();
  }

  public hideModal() {
    (this.$refs.tourWelcome as any).hide();
  }

  get currentPage() {
    return this.$route.name;
  }
}
