export interface IMenuAreaTemasSnp {
  value?: AreaTemasSnp;
  text?: string;
}

export class MenuAreaTemasSnp implements IMenuAreaTemasSnp {
  constructor(public value?: AreaTemasSnp, public text?: string) {}
}

export interface IMenuTemaTemasSnp {
  value?: TemaTemasSnp;
  text?: string;
}

export class MenuTemaTemasSnp implements IMenuTemaTemasSnp {
  constructor(public value?: TemaTemasSnp, public text?: string) {}
}

export interface IAreaTemasSnp {
  id?: number;
  nombre?: string;
}

export class AreaTemasSnp implements IAreaTemasSnp {
  constructor(public id?: number, public nombre?: string) {}
}

export interface ITemaTemasSnp {
  id?: number;
  areaId?: number;
  nombre?: string;
}

export class TemaTemasSnp implements ITemaTemasSnp {
  constructor(public id?: number, public areaId?: number, public nombre?: string) {}
}

export interface IRegistroTemaSnp {
  id?: string;
  tema?: TemaTemasSnp;
  area?: AreaTemasSnp;
  principal?: boolean;
}

export class RegistroTemaSnp implements IRegistroTemaSnp {
  constructor(public id?: string, public tema?: TemaTemasSnp, public area?: AreaTemasSnp, public principal?: boolean) {}
}
