import { Component, Inject, Vue, Prop } from 'vue-property-decorator';
import { Autoridad } from '@/shared/model/autoridad.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import PersonaService from '@/shared/persona/persona.service';
import { IPersona } from '@/shared/model/persona/persona.model';
@Component
export default class AssingAuthority extends Vue {
  @Inject('personaService') public personaService: () => PersonaService;

  @Prop({ required: true })
  value: Autoridad[];

  @Prop({ default: false })
  busy: boolean;

  public isSearching = false;

  public autoridad: Autoridad = new Autoridad();

  public campos = ['cvu', 'usuarioId', 'nombre', 'roles', 'acciones'];

  public rolesAutoridadValues: string[] = this.resolveRoles();

  public personaCvu: IPersona = null;
  public userLogin: string = null;
  public userNotFound = false;

  public resolveRoles() {
    const roles = [];
    Object.keys(RolAutoridad).forEach(roleKey => {
      const message = this.$t('apeironGwApp.RolAutoridad.' + roleKey);
      roles.push({ text: message.toString(), value: roleKey });
    });
    return roles;
  }

  public isRemovable(autoridad: Autoridad): boolean {
    //un usuario no se puede quitar permisos
    if (autoridad.usuarioId === this.username && this.hasOwner(autoridad)) {
      return false;
    }

    if (this.hasOwner(autoridad)) {
      return this.countOwners() > 1;
    }

    return true;
  }

  public hasOwner(autoridad: Autoridad): boolean {
    if (!autoridad.roles) {
      return false;
    }

    return autoridad.roles.findIndex(rol => rol === RolAutoridad.PROPIETARIO) > -1;
  }

  public countOwners() {
    let count = 0;
    this.autoridades.forEach(autoridad => {
      if (autoridad.roles.findIndex(rol => rol === RolAutoridad.PROPIETARIO) > -1) {
        count = count + 1;
      }
    });

    return count;
  }

  set autoridades(autoridades: Autoridad[]) {
    this.$emit('input', autoridades);
  }

  get autoridades() {
    return this.value;
  }

  public handleFindAutoridadByLogIn() {
    this.isSearching = true;
    this.personaService()
      .findByLogin(this.userLogin)
      .then(result => {
        this.personaCvu = result;
        this.userNotFound = false;
        this.isSearching = false;
      })
      .catch(error => {
        this.userNotFound = true;
        this.isSearching = false;
      });
  }

  public handleOpenAddAutoridadModal(): void {
    this.autoridad = new Autoridad();
    this.personaCvu = null;
    this.userNotFound = false;
    this.userLogin = null;
    if (<any>this.$refs.addAutoridad) {
      (<any>this.$refs.addAutoridad).show();
    }
  }
  public handleCloseAddAutoridadModal(): void {
    if (<any>this.$refs.addAutoridad) {
      (<any>this.$refs.addAutoridad).hide();
    }
  }

  public handleAddAutoridad(): void {
    this.autoridad.usuarioId = this.userLogin ? this.userLogin : this.personaCvu.login;
    this.autoridad.cvu = this.personaCvu.cvu;
    this.autoridad.nombre = this.personaCvu.nombre;
    this.autoridad.apellidoPaterno = this.personaCvu.apellidoPaterno;
    this.autoridad.apellidoMaterno = this.personaCvu.apellidoMaterno;
    this.autoridades.push(this.autoridad);
    this.userLogin = null;
    if (<any>this.$refs.addAutoridad) {
      (<any>this.$refs.addAutoridad).hide();
    }
  }

  public handlePrepareToRemove(autoridad): void {
    this.autoridad = autoridad;
    if (<any>this.$refs.confirmRemoveModal) {
      (<any>this.$refs.confirmRemoveModal).show();
    }
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public handleConfirmation(): void {
    const autoridades = this.autoridades.filter((autoridad, index, arr) => {
      if (autoridad.usuarioId === this.autoridad.usuarioId) {
        arr.splice(index, 1);
        return true;
      }
      return false;
    });
    this.hideModal();
  }

  public hideModal() {
    (this.$refs.confirmRemoveModal as any).hide();
  }

  get username(): string {
    return this.$store.getters.account?.login ?? '';
  }
}
