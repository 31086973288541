import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HistoryItemComponent extends Vue {
  @Prop({ required: true })
  public items!: any[];

  @Prop({ required: false })
  public fields!: any[];

  filter = null;
  filterOn = [];
  currentPage = 1;
  perPage = 100;
  totalRows = 0;
  selected = [];
  originalSelected = [];

  mounted() {
    if (this.items?.length) {
      this.totalRows = this.items.length;
    }
  }
  get totalSelectedRows() {
    if (this.selected?.length) {
      return this.selected.length;
    }
    return 0;
  }

  public onFiltered(filteredItems) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  get jsonExcel() {
    const rows = [];
    if (this.fields?.length > 0 && this.items?.length > 0) {
      for (const item of this.items) {
        rows.push(this.createRow(item));
      }
    }
    return rows;
  }

  createRow(item) {
    const currentRow = {};
    for (const field of this.fields) {
      const formatter = this.resolveFormatter(field.key);
      currentRow[field.label] = formatter(this.resolveValue(field.key, item), field.key, item);
    }
    return currentRow;
  }

  resolveValue(key: string, field) {
    const paths = key.split('.');
    let currentValue = field;
    for (const path of paths) {
      currentValue = currentValue ?? [path];
    }
    return currentValue;
  }

  resolveFormatter(key: string) {
    const field = this.fields.find(field => field.key === key);
    if (field.formatter) {
      return field.formatter;
    }
    // se regresa una formateador que sólo regresa el valor sin modificarlo
    return value => value;
  }
}
