var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h4", [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("bandeja.estimación")) },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-badge",
                {
                  attrs: {
                    variant: "primary",
                    href: _vm.solicitud.SOLICITUD_RIZOMA.documentoPu.documento
                      .uri,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("bandeja.verPu")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-badge",
                {
                  attrs: {
                    variant: "primary",
                    href: _vm.solicitud.SOLICITUD_RIZOMA.documentoSolicitud
                      .documento.uri,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("bandeja.verSolicitud")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.solicitud.reconsideracion && _vm.solicitud.reconsideracion.uri
                ? _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: "primary",
                        href: _vm.solicitud.reconsideracion.uri,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("bandeja.verReconsideracion")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.revisorSeleccionado
        ? _c(
            "b-row",
            { staticClass: "text-right" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("h5", [
                  _vm._v(
                    "Se seleccionó Evaluador " + _vm._s(_vm.revisorSeleccionado)
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "div",
              { staticClass: "valoracion border" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c("h5", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("bandeja.area")),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.solicitud.SOLICITUD_RIZOMA.areaConocimiento.area
                              .nombre
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c("h5", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("bandeja.evaluado")),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre
                          )
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c(
                          "b-table-simple",
                          {
                            attrs: {
                              hover: "",
                              small: "",
                              "caption-top": "",
                              responsive: "",
                            },
                          },
                          [
                            _c(
                              "b-thead",
                              { attrs: { "head-variant": "dark" } },
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.produccion"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-th",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.productoPrincipal"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-th",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.noPrincipales"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-th",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.producionGeneral"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tbody",
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.articulos"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.totalArticulosPrincipal)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalArticulos -
                                              _vm.totalArticulosPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [_vm._v(_vm._s(_vm.totalArticulos))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.libros"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [_vm._v(_vm._s(_vm.totalLibrosPrincipal))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalLibros -
                                              _vm.totalLibrosPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [_vm._v(_vm._s(_vm.totalLibros))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.capitulos"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.totalCapitulosPrincipal)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalCapitulos -
                                              _vm.totalCapitulosPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [_vm._v(_vm._s(_vm.totalCapitulos))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.desarrolloTecno"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalDesarrolloTecnoPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalDesarrolloTecno -
                                              _vm.totalDesarrolloTecnoPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [_vm._v(_vm._s(_vm.totalDesarrolloTecno))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.propiedadIntelectual"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalPropiedadintelectualPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalPropiedadintelectual -
                                              _vm.totalPropiedadintelectualPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.totalPropiedadintelectual)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      { staticClass: "text-justify border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "evaluacion-individual.transferenciaTecno"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalTransferenciatecnoPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalTransferenciatecno -
                                              _vm.totalTransferenciatecnoPrincipal
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-center border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.totalTransferenciatecno)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _vm.expediente?.vigenciasSni
                          ? _c(
                              "collapse-item",
                              {
                                attrs: {
                                  visible: true,
                                  title: _vm.$t(
                                    "apeironGwApp.persona.expediente.vigencias.sni.title"
                                  ),
                                },
                              },
                              [
                                _c("vigencias-sni", {
                                  attrs: { item: _vm.expediente.vigenciasSni },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-row", [
                  _c(
                    "form",
                    {
                      attrs: {
                        id: "formulario-dinamico-id",
                        name: "editForm",
                        role: "form",
                        novalidate: "",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "formulario" },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("label", {
                              attrs: { for: "nivelAspira" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("bandeja.aspira")),
                              },
                            }),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                staticClass: "form-control",
                                attrs: { disabled: "" },
                              },
                              [
                                _c("option", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira
                                        .nombre
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("br"),
                            _vm._v(" "),
                            !this.evaluacionIndividual
                              ? _c("label", { attrs: { for: "prelación" } }, [
                                  _vm._v(
                                    "Prelación: " + _vm._s(this.resumen) + " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("label", {
                              attrs: { for: "nivelRecomendado" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("bandeja.recomendado")
                                ),
                              },
                            }),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.recomendado,
                                    expression: "recomendado",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  disabled:
                                    this.revision.estado === this.revisada,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.recomendado = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.calculaPrelacionValidayCambioNivel,
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.nivelRecomendado,
                                function (item, index) {
                                  return _c(
                                    "option",
                                    { key: index, domProps: { value: item } },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.nombre) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: this.tieneCandidato,
                                variant: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("bandeja.tieneCandidato")) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: this.validaNiveles,
                                variant: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "evaluacion-individual.nivelNoRepetido"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-col", { attrs: { md: "12" } }, [
                        _c("div", { staticClass: "border-top mt-4 mb-4" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb-5 float-right" },
                          [
                            this.revision.estado === this.aceptada
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "primary",
                                      size: "md",
                                      disabled:
                                        (_vm.hasAnyAuthority("ROLE_OPERADOR") &&
                                          _vm.authenticated) ||
                                        (this.tieneCandidato &&
                                          _vm.recomendado.id ===
                                            _vm.nivelCandidato),
                                    },
                                    on: { click: _vm.guardarEvaluacion },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("entity.action.save")
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: "save" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }