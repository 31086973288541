import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IconPickerComponent extends Vue {
  public icons = [
    'aportaciones',
    'copiar',
    'solicitud',
    'usuario',
    'diploma',
    'doc',
    'editar',
    'educacion',
    'eliminar',
    'eliminar-perfil',
    'configuracion',
    'empleo',
    'enlace',
    'datos-gen',
    'curso',
    'telefono',
    'cursos',
    'descargar',
    'derecha',
    'comunidades',
    'check',
    'check-caja',
    'check-circulo',
    'check-neg',
    'colapsar',
    'colapsar-der',
    'colapsar-izq',
    'enlace-2',
    'entrar',
    'tiempo',
    'menos',
    'reproducir',
    'abajo',
    'abajo-der',
    'abajo-izq',
    'abajo-izq',
    'abc-asc',
    'abc-des',
    'abc-des',
    'accesibilidad',
    'acerca',
    'acerca-apeiron',
    'actualizar',
    'archivo',
    'calendario',
    'calificacion',
    'camara',
    'cargar',
    'centrar-mapa',
    'check',
    'check-circulo',
    'correo-nuevo',
  ];

  public handleSelectedIcon(selected: string) {
    this.$emit('seleted', selected);
  }
}
