import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { IForm } from '@/shared/model/form.model';
import { IComponente, Componente } from '@/shared/model/componente.model';
import RuleService from '@/shared/rule/rule.service';
import AlertService from '@/shared/alert/alert.service';
import { Constants } from '@/shared/util/constants';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';

@Component
export default class FormPickerComponent extends Vue {
  @Inject('ruleService') public ruleService: () => RuleService;
  @Inject('alertService') public alertService: () => AlertService;

  @Prop({ required: true })
  public value: IComponente[];

  @Prop({ required: true })
  public forms: IForm[];

  @Prop({ required: true })
  public formOptions: any;

  public currentForm = null;

  public componenteEditable = null;

  public reglas = [];

  public sizeOptions = Constants.FILE_SIZE_OPTIONS;

  public mediaTypeOptions = Constants.MEDIA_TYPE_OPTIONS;

  public rolesAutoridadValues: string[] = this.resolveRoles();

  created(): void {
    this.retriveRules();
  }

  public resolveRoles() {
    const roles = [];
    Object.keys(RolAutoridad).forEach(roleKey => {
      const message = this.$t('apeironGwApp.RolAutoridad.' + roleKey);
      roles.push({ text: message.toString(), value: roleKey });
    });
    return roles;
  }

  public retriveRules(): void {
    this.ruleService()
      .retrieve()
      .then(param => {
        this.reglas = param.data;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
  public carpetasOptions = ['DADC', 'DADTVI', 'RIZOMA', 'CRIP'];

  get transitionRules() {
    return this.reglas;
  }

  get componentes(): IComponente[] {
    return this.value;
  }

  set componentes(componentes: IComponente[]) {
    this.$emit('input', componentes);
  }

  public persona: any = null;

  public tabComponentesIndex = 0;
  public selectedIcon = '';

  public linkComponentClass(idx) {
    if (this.tabComponentesIndex === idx) {
      return ['bg-primary', 'text-light'];
    } else {
      return ['bg-light'];
    }
  }

  public openConfigurarComponenteModal(form: any) {
    this.componenteEditable = this.componentes.find(comp => comp.formId === form.id);
    (<any>this.$refs.configComponente).show();
  }

  public handleCloseConfigurarComponenteModal() {
    (<any>this.$refs.configComponente).hide();
  }

  public handleSaveConfigurarComponenteModal() {
    (<any>this.$refs.configComponente).hide();
  }

  // Manejo de los tabs
  public isFormSelected(form: any): boolean {
    return this.componentes.find(comp => comp.formId === form.id) ? true : false;
  }

  // Manejo de los tabs
  public getComponente(form: any): any {
    return this.componentes.find(comp => comp.formId === form.id);
  }

  public getIconFormSelected(form: any): string {
    return this.componentes.find(comp => comp.formId === form.id).icon;
  }

  public handleAddComponente(form): void {
    this.currentForm = form;
    const componente = new Componente();
    componente.formId = this.currentForm.id;
    componente.titulo = this.currentForm.title;
    componente.menuName = this.currentForm.menuName;
    componente.descripcion = this.currentForm.name;
    componente.orden = this.componentes.length + 1;
    componente.icon = this.currentForm.icon;
    componente.path = this.currentForm.path;
    componente.tipo = this.currentForm.tipo;
    this.componentes.push(componente);
    this.$emit('addComponente');
  }

  public handleRemoveComponente(form: any): void {
    this.removeComponente(form.id);
  }

  public removeComponente(formId: string): void {
    let index = this.componentes.findIndex(item => item.formId === formId);
    this.componentes.splice(index, 1);
    index = 1;
    this.componentes.forEach(component => {
      component.orden = index++;
    });
    this.$emit('removeComponente');
  }

  public openIconPickerModal(form) {
    this.currentForm = form;
    if (<any>this.$refs.iconPickerModal) {
      (<any>this.$refs.iconPickerModal).show();
    }
  }

  public cancelIconPickerModal() {
    (this.$refs.iconPickerModal as any).hide();
  }
}
