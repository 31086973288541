import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ayudantia } from '@conacyt/historicopersonasms-client';
import * as formatterUtils from '@/shared/formatter/formatter-utils';

import * as dateUtils from '@/shared/date/date-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class MisAyudantesComponent extends Vue {
  @Prop({ required: false })
  public item: Array<any>;

  get ayudantias(): Array<any> {
    return this.item;
  }

  get fields() {
    return [
      {
        key: 'ayudantia.vigencia.fechaInicio',
        label: 'Fecha de inicio',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      {
        key: 'ayudantia.vigencia.fechaFin',
        label: 'Fecha de fin',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      { key: 'ayudantia.umas', label: 'UMAs', sortable: true },
      { key: 'ayudantia.estadoSolicitud', label: 'Estatus', sortable: true },
      { key: 'persona.cvu', label: 'CVU Ayudante', sortable: true },
      {
        key: 'nombreAyudante',
        label: 'Nombre del Ayudante',
        sortable: true,
        formatter: (value, key, item) => formatterUtils.nombreAyudante(value, key, item),
      },
      { key: 'ayudantia.convenio.urlConvenio', label: 'URL convenio', sortable: true, link: true },
    ];
  }
}
