var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: _vm.id,
      attrs: {
        scrollable: _vm.scrollable,
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        centered: _vm.centered,
        "header-bg-variant": "primary",
        size: _vm.size,
        id: _vm.id,
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body text-center" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.question) } }),
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.check
        ? _c(
            "b-form-checkbox",
            {
              staticClass: "text-center",
              attrs: {
                id: "check-accept-id",
                name: "terminos-condiciones",
                size: "lg",
              },
              model: {
                value: _vm.accept,
                callback: function ($$v) {
                  _vm.accept = $$v
                },
                expression: "accept",
              },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("entity.confirmation.confirmationMessage")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.addComentario
        ? _c(
            "div",
            { staticClass: "m-5" },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "add-comentario",
                  placeholder: "Agrega un comentario para continuar...",
                  rows: "5",
                },
                model: {
                  value: _vm.comentario,
                  callback: function ($$v) {
                    _vm.comentario = $$v
                  },
                  expression: "comentario",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "outline-danger" },
              on: {
                click: function ($event) {
                  return _vm.handleCancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { disabled: _vm.isDisabled, variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleConfirmation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }