export enum TipoAccion {
  ACTIVAR = 'ACTIVAR',
  ACTUALIZAR = 'ACTUALIZAR',
  ASIGNAR = 'ASIGNAR',
  PREASIGNAR = 'PREASIGNAR',
  FINALIZAR = 'FINALIZAR',
  LEER = 'LEER',
  ESCRIBIR = 'ESCRIBIR',
  COMENTAR = 'COMENTAR',
  ENVIAR = 'ENVIAR',
  VALIDAR = 'VALIDAR',
  APROBAR = 'APROBAR',
  RECHAZAR = 'RECHAZAR',
  CANCELAR = 'CANCELAR',
  RECLASIFICAR = 'RECLASIFICAR',
  FORMALIZAR = 'FORMALIZAR',
  BAJAR = 'BAJAR',
  BAJAR_POR_ACUERDO = 'Dar de baja por acuerdo',
  DICTAMINAR = 'DICTAMINAR',
  REVISAR = 'REVISAR',
  EVALUAR = 'EVALUAR',
  PREFORMALIZAR = 'PREFORMALIZAR',
  CONFIRMAR = 'CONFIRMAR',
}
