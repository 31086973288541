import { Component, Vue, Inject } from 'vue-property-decorator';

import AlertService from '@/shared/alert/alert.service';

@Component
export default class BreadcrumbComponent extends Vue {
  @Inject('alertService') private alertService: () => AlertService;

  mounted() {
    (this.$root as any).$on('update-breadcrumb', this.handleUpdateBreadcrumb);
  }

  beforeDestroy() {
    (this.$root as any).$off('update-breadcrumb', this.handleUpdateBreadcrumb);
  }

  public handleUpdateBreadcrumb(name) {}

  public isHome(to) {
    return to.name === 'Home';
  }

  public reset() {
    while (this.showBreadCrumb) {
      this.breadcrumbItems.pop();
    }
  }
  public breadcrumbItems = [
    {
      text: 'Inicio',
      to: { name: 'Home' },
    },
  ];

  onClick($event) {}

  public addBreadcrumbItem(item) {
    this.breadcrumbItems.push(item);
  }

  public removeBreadcrumbItem(item) {
    if (this.breadcrumbItems.length > 1) {
      this.breadcrumbItems.pop();
    }
  }

  get showBreadCrumb() {
    return this.breadcrumbItems.length > 1;
  }
}
