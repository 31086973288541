var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.label
        ? _c("label", { staticClass: "form-control-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: {
          mode: "dateTime",
          "model-config": _vm.initTimeConfig,
          timezone: _vm.timezone,
          locale: _vm.locale,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ inputValue, inputEvents }) {
              return [
                _c(
                  "b-form-input",
                  _vm._g({ attrs: { value: inputValue } }, inputEvents)
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.dateTime,
          callback: function ($$v) {
            _vm.dateTime = $$v
          },
          expression: "dateTime",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }