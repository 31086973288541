import { Component, Prop, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';

@Component
export default class PadronDownloadComponent extends mixins(JhiDataUtils) {
  @Inject('historicoPersonasApi') public historicoPersonasApi: () => HistoricoPersonasApi;

  @Prop({ required: true })
  public padronEncabezadoId: string;

  public isDownloading = false;
  public downloadLayout(): void {
    this.isDownloading = true;
    this.historicoPersonasApi()
      .getAllPadronNominaArchivo(this.padronEncabezadoId, 1, 100)
      .then(
        res => {
          const documento = res.data;
          const url = documento.contenido;
          window.open(url, '_blank');
          this.isDownloading = false;
        },
        err => {
          this.isDownloading = false;
        }
      );
  }

  get downloadState(): string {
    if (this.isDownloading) {
      return 'outline-danger';
    } else {
      return 'outline-success';
    }
  }

  public getDateName() {
    const currentDate = new Date();
    return (
      currentDate.getFullYear() +
      '' +
      currentDate.getMonth() +
      '' +
      currentDate.getHours() +
      '' +
      currentDate.getMinutes() +
      '' +
      currentDate.getSeconds()
    );
  }
}
