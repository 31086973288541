var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-button",
    {
      attrs: { variant: "outline-success" },
      on: {
        click: function ($event) {
          return _vm.downloadFile()
        },
      },
    },
    [
      _c("b-icon", { attrs: { icon: "file-earmark-spreadsheet" } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t("entity.action.export")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }