var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.estadosReporte, function (estado) {
        return _c(
          "b-card",
          {
            key: estado,
            staticClass: "ml-2 mt-2 col-2",
            attrs: { "bg-variant": _vm.variant(estado) + "-opacity-10" },
          },
          [
            _c(
              "b-overlay",
              {
                attrs: {
                  variant: "transparent",
                  show: _vm.isFetching,
                  "spinner-variant": "primary",
                  opacity: 1,
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100" }),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-center col" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.resolveCountByEstado(estado))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-center" }, [
                    _c("small", { class: "text-" + _vm.variant(estado) }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("archeApp.EstadoSolicitud." + estado)) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }