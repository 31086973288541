import { render, staticRenderFns } from "./padron.vue?vue&type=template&id=6a2ffe12&scoped=true&"
import script from "./padron.component.ts?vue&type=script&lang=ts&"
export * from "./padron.component.ts?vue&type=script&lang=ts&"
import style0 from "./padron.vue?vue&type=style&index=0&id=6a2ffe12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2ffe12",
  null
  
)

export default component.exports