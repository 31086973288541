import { Component, Prop, Inject, Vue } from 'vue-property-decorator';
import PersonaService from '@/shared/persona/persona.service';

@Component
export default class BuscadorPersonaComponent extends Vue {
  @Inject('personaService') public personaService: () => PersonaService;

  @Prop({ default: false })
  public readOnly: boolean;

  cvuToSearch = null;
  isSearching = false;
  userNotFound = false;

  public handleFindPersonaByLogin() {
    this.isSearching = true;
    this.personaService()
      .findByCvu(this.cvuToSearch)
      .then(res => {
        if (res) {
          this.$emit('selected', res);
          this.cvuToSearch = null;
          this.userNotFound = false;
        } else {
          this.userNotFound = true;
        }
        this.isSearching = false;
      })
      .catch(error => {
        this.isSearching = false;
        this.userNotFound = true;
      });
  }

  get isSearchDisabled(): boolean {
    return this.isSearching || !this.cvuToSearch?.length;
  }
}
