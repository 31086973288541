var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-4" },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-skeleton-img", {
                attrs: { "no-aspect": "", height: "150px" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-card-text",
            { staticClass: "mt-5" },
            [
              _c("b-skeleton", { attrs: { animation: "fade", width: "87%" } }),
              _vm._v(" "),
              _c("b-skeleton", { attrs: { animation: "fade", width: "55%" } }),
              _vm._v(" "),
              _c("b-skeleton", { attrs: { animation: "fade", width: "70%" } }),
              _vm._v(" "),
              _c("b-skeleton", { attrs: { animation: "fade", width: "79%" } }),
              _vm._v(" "),
              _c("b-skeleton", { attrs: { animation: "fade", width: "50%" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }