import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import CatalogosSnp from '@/entities/snp/catalogos/catalogos-snp.factory';
import { mixins } from 'vue-class-component';
import { v4 as uuidv4 } from 'uuid';
import { MenuAreaTemasSnp, MenuTemaTemasSnp, RegistroTemaSnp } from '@/entities/snp/temas/temas.model';

const validations = function () {
  return {
    tema: {
      area: {
        required,
      },
      tema: {
        required,
      },
      principal: {},
    },
  };
};

@Component({ validations: validations })
export default class TemasRegistroComponent extends mixins(ApeironComponent) {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({
    required: false,
    default: function () {
      return new RegistroTemaSnp();
    },
  })
  public temaEdicion: RegistroTemaSnp;

  public areas: Array<MenuAreaTemasSnp> = [];
  public temas: Array<MenuTemaTemasSnp> = [];

  public tema: RegistroTemaSnp = new RegistroTemaSnp();

  public mounted(): void {
    this.iniciarComponente(false);
  }

  @Watch('temaEdicion')
  public watchTemaEdicion(): void {
    this.iniciarComponente(false);
  }

  public iniciarComponente(limpiarLie: boolean): void {
    this.areas = [];
    CatalogosSnp.obtenerAreasDeTemas().forEach(area => {
      this.areas.push({ text: area.nombre, value: area });
    });

    if (this.temaEdicion && this.temaEdicion.id && !limpiarLie) {
      this.tema = this.temaEdicion;
      this.cambiarSeleccionArea(false);
    } else {
      this.tema = new RegistroTemaSnp();
      this.tema.id = uuidv4();
      this.tema.principal = false;
    }
  }

  public cambiarSeleccionArea(limpiarSelecion: boolean): void {
    this.temas = [];
    CatalogosSnp.obtenerTemasDeTemas(this.tema.area?.id).forEach(tema => {
      this.temas.push({ text: tema.nombre, value: tema });
    });

    if (limpiarSelecion) {
      this.tema.tema = undefined;
    }
  }

  public limpiarDatos(): void {
    this.iniciarComponente(true);
  }

  public agregarTema(): void {
    if (!this.formularioIsInvalid) {
      this.agregarTemaAtabla();
    } else {
      this.alertService().showWarning(this, this.$t('apeironGwApp.temaSnp.form.formularioInvalido').toString());
    }
  }

  @Emit('agregarTema')
  public agregarTemaAtabla(): RegistroTemaSnp {
    const tema = { ...this.tema };

    this.limpiarDatos();

    return tema;
  }

  get formularioIsInvalid(): boolean {
    return this.$v.$invalid;
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }
}
