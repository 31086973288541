var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("hr", { staticClass: "hr hr-blurry" }),
      _vm._v(" "),
      _vm.showFull
        ? _c("b-skeleton-table", {
            attrs: {
              rows: 5,
              columns: 5,
              "table-props": { bordered: true, striped: true },
            },
          })
        : _vm._l(7, function (index) {
            return _c(
              "div",
              { key: index },
              [
                _c("b-skeleton", {
                  staticClass: "mb-4",
                  attrs: { width: "100%" },
                }),
                _vm._v(" "),
                _c("hr", { staticClass: "hr hr-blurry" }),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }