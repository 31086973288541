var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("countdown", {
        ref: _vm.id,
        attrs: { id: _vm.id, "auto-start": false, time: _vm.countDown },
        on: { end: _vm.end },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _vm.showCountDown
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "card floating-timer",
                          class: _vm.timerVariant,
                        },
                        [
                          _c("h3", { staticClass: "card-title text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row d-flex flex-wrap justify-content-center mt-1 text-white",
                              },
                              [
                                _c("a", [
                                  _c(
                                    "span",
                                    { staticClass: "badge text-white" },
                                    [
                                      _vm._v(_vm._s(props.days) + " "),
                                      _c(
                                        "span",
                                        { staticClass: "h6 text-white" },
                                        [_vm._v("días")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v("\n              :\n              "),
                                _c("a", [
                                  _c(
                                    "span",
                                    { staticClass: "badge text-white" },
                                    [
                                      _vm._v(_vm._s(props.hours) + " "),
                                      _c(
                                        "span",
                                        { staticClass: "h6 text-white" },
                                        [_vm._v("horas")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v("\n              :\n              "),
                                _c("a", [
                                  _c(
                                    "span",
                                    { staticClass: "badge text-white" },
                                    [
                                      _vm._v(_vm._s(props.minutes) + " "),
                                      _c(
                                        "span",
                                        { staticClass: "h6 text-white" },
                                        [_vm._v("minutos")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v("\n              :\n              "),
                                _c("a", [
                                  _c(
                                    "span",
                                    { staticClass: "badge text-white" },
                                    [
                                      _vm._v(_vm._s(props.seconds) + " "),
                                      _c(
                                        "span",
                                        { staticClass: "h6 text-white" },
                                        [_vm._v("segundos")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "text-center text-white" },
                            [_vm._v("Tiempo restante para recibir solicitudes")]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal" + _vm.id,
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "header-bg-variant": "primary",
            id: "modal" + _vm.id,
          },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("Fecha límite para esta operación")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v("Se ha terminado el tiempo para enviar solicitudes"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }