import { Component, Inject, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import AlertService from '@/shared/alert/alert.service';
import { Participante } from './participante.model';
import { required } from 'vuelidate/lib/validators';
import ParticipanteService from './participante.service';
import { FiltroParticipacion } from '@/shared/model/filtro-participacion.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { StatusCodes } from 'http-status-codes';
import JhiDataUtils from '@/shared/data/data-utils.service';

const validations: any = {
  form: {
    subSeccion: {
      nombre: { required },
    },
  },
};

@Component({
  validations,
})
export default class ParticipantesComponent extends mixins(JhiDataUtils) {
  @Inject('participanteService') public participanteService: () => ParticipanteService;
  @Inject('alertService') private alertService: () => AlertService;

  @Prop({ required: true })
  solucionId: string;

  @Prop({ required: true })
  solicitudId: string;

  @Prop({ default: false })
  public readOnly: boolean;

  @Prop({ required: false })
  contenedorId: string;

  @Prop({ required: false })
  dependenciaSelected: any;

  @Prop({ required: false })
  isSedeDuplicated: boolean;

  @Prop({ required: false })
  duracionOficial: number;

  @Prop({ required: false })
  rol: RolAutoridad;

  public itemsPerPage = 100;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = {};
  public isSaving = false;
  public showErrores = false;

  form = { subSeccion: new Participante() };
  campos = [
    { key: 'dependenciaId', label: this.$t('apeironGwApp.persona.participacion.becario.dependencia') },
    { key: 'participante.cvu', label: this.$t('apeironGwApp.persona.cvu') },
    { key: 'participante.login', label: this.$t('apeironGwApp.persona.login') },
    { key: 'nombre', label: this.$t('apeironGwApp.persona.nombre') },
    { key: 'participante.curp', label: this.$t('apeironGwApp.persona.curp') },
    { key: 'participante.rfc', label: this.$t('apeironGwApp.persona.rfc') },
    { key: 'participante.genero', label: this.$t('apeironGwApp.persona.genero') },
  ];
  isFetching = false;
  filtro = new FiltroParticipacion();

  participantes = [];
  participanteToRemove = null;
  participanteToEdit = null;
  currentParticipacion = null;
  erroresTitle = null;
  erroresDescription = null;

  public mounted(): void {
    this.retrieveAll();
  }

  get showTable() {
    return Array.isArray(this.participantes);
  }
  created() {
    if (!this.readOnly) {
      this.campos.push({ key: 'acciones', label: this.$t('entity.acciones') });
    }
  }

  retrieveAll() {
    if (this.isReadyToFind()) {
      this.buildFilter();
      this.isFetching = true;
      const paginationQuery = {
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
        filter: this.filtro,
      };
      this.participanteService()
        .retrieve(paginationQuery)
        .then(
          res => {
            if (res.data && res.data.length > 0) {
              for (const revision of res.data) {
                this.participantes.push(revision);
              }
              if (res?.headers && res.headers['link']) {
                this.links = this.parseLinks(res.headers['link']);
              }
            }
            this.totalItems = Number(res.headers['x-total-count']);
            this.queryCount = this.totalItems;
            this.isFetching = false;

            if (<any>this.$refs.infiniteLoading) {
              (<any>this.$refs.infiniteLoading).stateChanger.loaded();
              if (this.page > this.links['last']) {
                (<any>this.$refs.infiniteLoading).stateChanger.complete();
              }
            }
          },
          err => {
            this.isFetching = false;
            if (err.response.data.status !== StatusCodes.NOT_FOUND) {
              this.alertService().showHttpError(this, err.response);
            }
          }
        );
    }
  }

  isReadyToFind() {
    return this.contenedorId && this.dependenciaSelected?.claveDependencia;
  }
  buildFilter() {
    this.filtro.solicitudId = this.solicitudId;
    this.filtro.rol = this.rol;
    if (this.contenedorId) {
      this.filtro.contenedorId = this.contenedorId;
    }

    if (this.dependenciaSelected?.claveDependencia) {
      this.filtro.dependenciaId = this.dependenciaSelected.claveDependencia;
    }
  }

  handleAddParticipante(participante) {
    this.currentParticipacion = {
      id: null,
      solicitudId: this.solicitudId,
      contenedorId: this.contenedorId,
      dependenciaId: this.dependenciaSelected.claveDependencia,
      participante: null,
      rol: RolAutoridad.ALUMNO,
    };
    this.participanteToEdit = participante;
    this.handleOpenAddPersonaModal();
  }

  handleEditParticipacion(participacion) {
    this.currentParticipacion = participacion;
    this.participanteToEdit = JSON.parse(JSON.stringify(participacion.participante));
    this.handleOpenAddPersonaModal();
  }

  handlePrepareToRemove(participante) {
    this.participanteToRemove = participante;
    if (<any>this.$refs.confirmParticipanteRemoveModal) {
      (<any>this.$refs.confirmParticipanteRemoveModal).show();
    }
  }

  public handleSaveParticipacion() {
    this.currentParticipacion.participante = this.participanteToEdit;
    if (!this.currentParticipacion.dependenciaId) {
      this.currentParticipacion.dependenciaId = this.dependenciaSelected.claveDependencia;
    }
    this.isSaving = true;
    if (this.currentParticipacion.id) {
      this.handleUpdateParticipacion();
    } else {
      this.handleCreateParticipacion();
    }
  }

  public handleCreateParticipacion() {
    this.participanteService()
      .create(this.currentParticipacion, this.solucionId)
      .then(param => {
        this.isSaving = false;
        this.clear();
        this.alertService().sectionUpdated(this, 'Participantes');
        this.handleCloseAddPersonaModal();
        this.$emit('actualizarContadorParticipacion', {});
      })
      .catch(error => {
        this.isSaving = false;
        const errores = {
          'error.conflict': {
            title: 'apeironGwApp.persona.form.messages.historicoApoyos',
            description: 'apeironGwApp.persona.form.messages.solicitudActiva',
          },
          'error.apoyoVigenteHistoricoBecas': {
            title: 'apeironGwApp.persona.form.messages.historicoApoyos',
            description: 'apeironGwApp.persona.form.messages.apoyoVigente',
          },
          'error.apoyoSimilarHistoricoBecas': {
            title: 'apeironGwApp.persona.form.messages.historicoApoyos',
            description: 'apeironGwApp.persona.form.messages.apoyoSimilar',
          },
          'error.serviceUnavailable': {
            title: 'apeironGwApp.persona.form.messages.historicoApoyos',
            description: 'apeironGwApp.persona.form.messages.historicoApoyosNoDisponible',
          },
          'error.participanteRegistrado': {
            title: 'apeironGwApp.persona.form.messages.participanteRegistrado',
            description: 'apeironGwApp.persona.form.messages.participanteRegistradoPrograma',
          },
        };

        const errorData = error.response?.data;
        const errorKey = errorData?.message;

        if (error.response.data.status === StatusCodes.PRECONDITION_FAILED) {
          this.erroresTitle = this.$t('apeironGwApp.persona.form.messages.registrada');
          this.erroresDescription = this.$t('apeironGwApp.persona.form.messages.detalle');
          this.showErrores = true;
          this.focusOnErrors();
        } else if (errorKey && errores[errorKey]) {
          const { title, description } = errores[errorKey];
          this.erroresTitle = this.$t(title);
          this.erroresDescription = this.$t(description, { param: errorData.detail });
          this.showErrores = true;
          this.focusOnErrors();
        } else {
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  public handleUpdateParticipacion() {
    this.participanteService()
      .update(this.currentParticipacion)
      .then(param => {
        this.isSaving = false;
        this.clear();
        this.alertService().sectionUpdated(this, 'Participantes');
        this.handleCloseAddPersonaModal();
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public isFormValid = false;
  public handleValidForm(isValid) {
    this.isFormValid = isValid;
  }

  public focusOnErrors() {
    (this.$refs.erroresValidacion as any).scrollIntoView({ behavior: 'smooth' });
  }
  public handleOpenAddPersonaModal(): void {
    this.showErrores = false;
    if (<any>this.$refs.addPersona) {
      (<any>this.$refs.addPersona).show();
    }
  }

  public handleCloseAddPersonaModal(): void {
    this.participanteToEdit = null;
    if (<any>this.$refs.addPersona) {
      (<any>this.$refs.addPersona).hide();
    }
  }

  handleCancel(): void {
    this.hideModal();
  }

  handleConfirmation(): void {
    this.participanteService()
      .delete(this.participanteToRemove.id)
      .then(() => {
        this.clear();
        this.hideModal();
        this.$emit('actualizarContadorParticipacion', {});
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  hideModal() {
    (this.$refs.confirmParticipanteRemoveModal as any).hide();
  }

  public clear(): void {
    this.page = 1;
    this.participantes = [];
    this.retrieveAll();
  }

  public loadMore($state): void {
    this.page++;
    this.retrieveAll();
  }
  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAll();
  }

  public changeOrder(propOrder): void {
    this.propOrder = propOrder;
    this.reverse = !this.reverse;
    this.transition();
  }
}
