import { Module } from 'vuex';
import { EstadoProceso } from '@/shared/model/enumerations/estado-proceso.model';

export const perfilLoadingStore: Module<any, any> = {
  state: {
    estadoCargaPerfil: EstadoProceso.PENDIENTE,
  },
  getters: {
    estadoCargaPerfil: state => state.estadoCargaPerfil,
  },
  mutations: {
    setEstadoCargaPerfil(state, estado) {
      state.estadoCargaPerfil = estado;
    },
  },
  actions: {
    iniciarCargaDelPerfil(ctx) {
      ctx.commit('setEstadoCargaPerfil', EstadoProceso.CARGANDO);
    },
    detenerCargaDelPerfil(ctx, estado) {
      ctx.commit('setEstadoCargaPerfil', estado);
    },
  },
};
