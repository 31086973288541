var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "h1 text-center text-dark" }, [
        _vm._v(_vm._s(_vm.solucion.titulo)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h3 mb-3 text-center" }, [
        _vm._v(_vm._s(_vm.solucion.descripcion)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h5 text-center" }, [
        _c("i", [_vm._v("v.(" + _vm._s(_vm.solucion.version) + ")")]),
      ]),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mt-5" },
        [
          _c("header", { staticClass: "bx-header-title" }, [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("archeApp.solucion.seccion.informacion.title"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-title-line" }),
          ]),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t("archeApp.solucion.tipoSolucion"),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("div", { staticClass: "p" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "apeironGwApp.TipoSolucion." +
                                _vm.solucion.tipo +
                                ".simpleName"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t("archeApp.solucion.tags"),
                        "label-for": "input-sm",
                      },
                    },
                    _vm._l(_vm.solucion.tags, function (b, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "rt-1" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "mr-2",
                              attrs: { pill: "", variant: "primary" },
                            },
                            [_vm._v(_vm._s(b) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t("archeApp.solucion.visible"),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "ml-3",
                        attrs: {
                          "font-scale": "2",
                          variant: _vm.solucion.visible
                            ? "success"
                            : "secondary",
                          icon: _vm.solucion.visible ? "eye" : "eye-slash",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t(
                          "archeApp.solucion.seccion.parametros.calendario.fecha.apertura"
                        ),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timeElapsed")(
                              _vm.solucion.calendario.fechaInicio
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t(
                          "archeApp.solucion.seccion.parametros.calendario.fecha.limite.recibir"
                        ),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timeElapsed")(
                              _vm.solucion.calendario.fechaFinSolicitud
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t(
                          "archeApp.solucion.seccion.parametros.calendario.fecha.limite.evaluacion"
                        ),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timeElapsed")(
                              _vm.solucion.calendario.fechaFinRevision
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols": "4",
                        "label-cols-lg": "6",
                        "label-size": "sm",
                        label: _vm.$t(
                          "archeApp.solucion.seccion.calendario.fecha.limite.reconsideracion"
                        ),
                        "label-for": "input-sm",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timeElapsed")(
                              _vm.solucion.calendario.fechaFinReconsideracion
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c("header", { staticClass: "bx-header-title" }, [
            _c("h3", [_vm._v("Mensajes")]),
            _vm._v(" "),
            _c("div", { staticClass: "header-title-line" }),
          ]),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "sm",
                description: _vm.$t(
                  "archeApp.solucion.seccion.mensaje.bienvenida.solicitantes"
                ),
              },
            },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.solucion.mensaje.bienvenida),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "sm",
                description: _vm.$t(
                  "archeApp.solucion.seccion.mensaje.bienvenida.revisores"
                ),
              },
            },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.solucion.mensaje.bienvenidaRevision),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "sm",
                description: _vm.$t(
                  "archeApp.solucion.seccion.mensaje.bienvenida.terminosRevisores"
                ),
              },
            },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.solucion.mensaje.terminosRevision),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "sm",
                description: _vm.$t(
                  "archeApp.solucion.seccion.mensaje.bienvenida.operador"
                ),
              },
            },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.solucion.mensaje.bienvenidaOperador),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-5 h1 text-center" }, [_vm._v("Proceso")]),
      _vm._v(" "),
      _vm._l(_vm.solucion.proceso.estados, function (estado, index) {
        return [
          _c(
            "b-card",
            {
              key: index,
              staticClass: "mb-5",
              attrs: {
                "bg-variant":
                  _vm.resolveEstadoSolicitudVariant(estado.nombre) +
                  "-opacity-10",
                "header-bg-variant": _vm.resolveEstadoSolicitudVariant(
                  estado.nombre
                ),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "h3 text-white text-center" },
                          [
                            _c("b-icon", { attrs: { icon: "view-list" } }),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "archeApp.EstadoSolicitud." + estado.nombre
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-5",
                  attrs: {
                    label: _vm.$t(
                      "archeApp.solucion.process.permission.rolesYPermisos"
                    ),
                  },
                },
                [
                  _c("permisos", {
                    attrs: { readonly: true },
                    model: {
                      value: estado.permisos,
                      callback: function ($$v) {
                        _vm.$set(estado, "permisos", $$v)
                      },
                      expression: "estado.permisos",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-5",
                  attrs: {
                    label: _vm.$t(
                      "archeApp.solucion.process.transition.definitionTransition"
                    ),
                  },
                },
                _vm._l(estado.transiciones, function (transicion, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "mt-3" },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            "bg-variant":
                              _vm.resolveEstadoSolicitudVariant(estado.nombre) +
                              "-opacity-25",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "archeApp.solucion.process.transition.transition"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-icon", {
                                      staticClass: "align-middle",
                                      attrs: { icon: "shuffle" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", { staticClass: "row text-center" }, [
                            _c(
                              "div",
                              { staticClass: "col-5" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      id: "componente-add-accion",
                                      size: "sm",
                                      label: "Acción",
                                      "label-for": "add-accion",
                                    },
                                  },
                                  [
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.TipoAccion." +
                                              transicion.accion
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-2" },
                              [
                                _c("b-icon", {
                                  staticClass: "align-middle",
                                  attrs: {
                                    icon: "arrow-bar-right",
                                    "font-scale": "3",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-5" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "text-left",
                                    attrs: {
                                      id: "componente-add-destino",
                                      size: "sm",
                                      label: "Destino",
                                      "label-for": "add-destino",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          variant:
                                            _vm.resolveEstadoSolicitudVariant(
                                              transicion.destino
                                            ),
                                        },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "archeApp.EstadoSolicitud." +
                                                  transicion.destino
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      transicion.reglas?.length > 0
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "mt-5",
                              attrs: { label: "Reglas de negocio aplicables" },
                            },
                            [
                              _c("b-table", {
                                attrs: {
                                  items: transicion.reglas,
                                  fields: ["clave", "nombre", "condicion"],
                                  "show-empty": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "head(condicion)",
                                      fn: function () {
                                        return [
                                          _c("span", [_vm._v("Condición")]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      transicion.acciones?.length > 0
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "mt-5",
                              attrs: {
                                label: "Acciones ejecutadas en la transición",
                              },
                            },
                            [
                              _c("b-table", {
                                attrs: {
                                  items: transicion.acciones,
                                  fields: ["clave", "nombre"],
                                  "show-empty": "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      }),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mt-3" },
        [
          _c("header", { staticClass: "bx-header-title" }, [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("archeApp.solucion.seccion.autoridad.title"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-title-line" }),
          ]),
          _vm._v(" "),
          _c("b-table", {
            attrs: {
              items: _vm.solucion.autoridades,
              fields: ["cvu", "usuarioId", "nombre", "roles"],
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("global.process.load.loading"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "empty",
                fn: function () {
                  return [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "archeApp.solucion.seccion.autoridad.noAuthorities"
                          )
                        )
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(roles)",
                fn: function (row) {
                  return _vm._l(row.item.roles, function (rol, index) {
                    return _c(
                      "b-badge",
                      {
                        key: index,
                        staticClass: "m-1",
                        attrs: { pill: "", variant: "primary" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("apeironGwApp.RolAutoridad." + rol)) +
                            "\n        "
                        ),
                      ]
                    )
                  })
                },
              },
              {
                key: "cell(nombre)",
                fn: function (row) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          row.item.nombre +
                            " " +
                            row.item.apellidoPaterno +
                            " " +
                            row.item.apellidoMaterno
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }