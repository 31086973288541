var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("regresar-button"),
      _vm._v(" "),
      _c(
        "h2",
        { attrs: { id: "page-heading", "data-cy": "SolucionHeading" } },
        [
          _c("span", { attrs: { id: "solucion-heading" } }, [
            _vm._v(_vm._s(_vm.$t("apeironGwApp.convocatoria.titulo"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.showCardList()
        ? _c("cards-list", {
            attrs: { id: "id-convocatoria", items: _vm.solucionsCards },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFetching ? _c("card-list-skeleton") : _vm._e(),
      _vm._v(" "),
      _vm.totalItems > _vm.itemsPerPage
        ? _c(
            "infinite-loading",
            {
              ref: "infiniteLoading",
              attrs: {
                slot: "append",
                identifier: _vm.infiniteId,
                distance: 20,
                "force-use-infinite-wrapper": ".el-table__body-wrapper",
                spinner: "circles",
              },
              on: { infinite: _vm.loadMore },
              slot: "append",
            },
            [
              _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                _vm._v(_vm._s(_vm.$t("global.process.search.nomore"))),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }, [
                _vm._v(_vm._s(_vm.$t("global.process.search.noresults"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", { ref: "removeEntity", attrs: { id: "removeEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c("span", {
            attrs: {
              id: "archeApp.solucion.delete.question",
              "data-cy": "solucionDeleteDialogHeading",
            },
            domProps: { textContent: _vm._s(_vm.$t("entity.delete.title")) },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", {
            attrs: { id: "jhi-delete-solucion-heading" },
            domProps: {
              textContent: _vm._s(
                _vm.$t("archeApp.solucion.delete.question", {
                  id: _vm.removeId,
                })
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c("button", {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
            on: {
              click: function ($event) {
                return _vm.closeDialog()
              },
            },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn btn-primary",
            attrs: {
              type: "button",
              id: "jhi-confirm-delete-solucion",
              "data-cy": "entityConfirmDeleteButton",
            },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.delete")) },
            on: {
              click: function ($event) {
                return _vm.removeSolucion()
              },
            },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }