export interface ISubNivelInstitucion {
  idInstitucion?: number;
  descInstitucion?: string;
  idInstDependencia?: number;
  descInstDependencia?: string;
  idInstSubdependencia?: number;
  descInstSubdependencia?: string;
  descInstDepartamento?: string;
  idEntidad?: number;
}

export class SubNivelInstitucion implements ISubNivelInstitucion {
  constructor(
    public idInstitucion?: number,
    public descInstitucion?: string,
    public idInstDependencia?: number,
    public descInstDependencia?: string,
    public idInstSubdependencia?: number,
    public descInstSubdependencia?: string,
    public descInstDepartamento?: string,
    public idEntidad?: number
  ) {}
}
