import { GenericaDto, IGenericaDto } from '@/shared/model/genericaDto.model';
import { ICargoDto } from '@/shared/model/cargo.model';
import { IDepartamento } from '@/shared/model/departamento.model';

export interface ISubDependencia {
  id?: string;
  nombre?: string;
  estado?: GenericaDto;
  tipoNivel?: GenericaDto;
  cargo?: ICargoDto[];
  departamento?: IDepartamento[];
  pais?: string;
  tipo?: GenericaDto;
  nivelNodo?: string;
}

export class SubDependencia implements ISubDependencia {
  constructor(
    public id?: string,
    public nombre?: string,
    public estado?: IGenericaDto,
    public tipoNivel?: IGenericaDto,
    public cargo?: ICargoDto[],
    public departamento?: IDepartamento[],
    public pais?: string,
    public tipo?: IGenericaDto,
    public nivelNodo?: string
  ) {
    this.tipo = this.tipo ? this.tipo : new GenericaDto();
    this.tipoNivel = this.tipoNivel ? this.tipoNivel : new GenericaDto();
    this.estado = this.estado ? this.estado : new GenericaDto();
    this.cargo = this.cargo ? this.cargo : [];
    this.departamento = this.departamento ? this.departamento : [];
  }
}
