import { Component, Prop, Vue } from 'vue-property-decorator';
import { ParticipacionEvaluador } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class ParticipacionesComoEvaluadorComponent extends Vue {
  @Prop({ required: false })
  public item: Array<ParticipacionEvaluador>;

  get participaciones(): Array<ParticipacionEvaluador> {
    return this.item;
  }

  get fields() {
    return [
      { key: 'convocatoria.nombre', label: this.$t('apeironGwApp.insaculacion.convocatoria').toString(), sortable: true },
      {
        key: 'convocatoria.fechaApertura',
        label: this.$t('apeironGwApp.convocatoria.anio').toString(),
        sortable: true,
        formatter: value => dateUtils.formatDateYear(value),
      },
    ];
  }
}
