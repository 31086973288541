var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "bv-example-row" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "text-left text-dark",
                  staticStyle: { "background-color": "#add8e6" },
                  attrs: { "no-body": "" },
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.institucion.instruccion.instruccion"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("strong", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.instruccion.filtroAutoridad"
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.cvu")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.nombre")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoPaterno")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoMaterno")
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.instruccion.filtroInstitucion"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "apeironGwApp.institucion.instruccion.nombreInstitucion"
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("li", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "apeironGwApp.institucion.instruccion.estado"
                              )
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "h4",
              { attrs: { id: "page-heading", "data-cy": "FiltroHeading" } },
              [
                _c("span", {
                  attrs: { id: "Filtro-heading" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("apeironGwApp.institucion.tabla.filtros")
                    ),
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "b-form",
        {
          attrs: { name: "editForm", role: "form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.buscarAutoridadesEnApeiron()
            },
          },
        },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "cvu-title" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.cvu")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.filtroInstitucion.cvu,
                            expression: "filtroInstitucion.cvu",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          name: "cvu",
                          id: "form-cvu-id",
                          "data-cy": "cvu",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.placeholder.cvu"
                          ),
                        },
                        domProps: { value: _vm.filtroInstitucion.cvu },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.filtroInstitucion,
                                "cvu",
                                $event.target.value.trim()
                              )
                            },
                            _vm.limitarLongitudCvu,
                          ],
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-nombre" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.nombre")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.filtroInstitucion.nombre,
                            expression: "filtroInstitucion.nombre",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "nombre",
                          id: "form-nombre-id",
                          "data-cy": "nombre",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.nombre"
                          ),
                        },
                        domProps: { value: _vm.filtroInstitucion.nombre },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.filtroInstitucion,
                              "nombre",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-name" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoPaterno")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.filtroInstitucion.apellidoPaterno,
                            expression: "filtroInstitucion.apellidoPaterno",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "apellidoPaterno",
                          id: "form-apellidoPaterno-id",
                          "data-cy": "apellidoPaterno",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.apellidoPaterno"
                          ),
                        },
                        domProps: {
                          value: _vm.filtroInstitucion.apellidoPaterno,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.filtroInstitucion,
                              "apellidoPaterno",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-name" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoMaterno")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.filtroInstitucion.apellidoMaterno,
                            expression: "filtroInstitucion.apellidoMaterno",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "apellidoMaterno",
                          id: "form-apellidoMaterno-id",
                          "data-cy": "apellidoMaterno",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.apellidoMaterno"
                          ),
                        },
                        domProps: {
                          value: _vm.filtroInstitucion.apellidoMaterno,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.filtroInstitucion,
                              "apellidoMaterno",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-name" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.institucion")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.filtroInstitucion.institucion,
                            expression: "filtroInstitucion.institucion",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "institucion",
                          id: "form-institucion-id",
                          "data-cy": "institucion",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.institucion"
                          ),
                        },
                        domProps: { value: _vm.filtroInstitucion.institucion },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.filtroInstitucion,
                              "institucion",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-menuName" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.estado")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.filtroInstitucion.estado,
                              expression: "filtroInstitucion.estado",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: _vm.disabledEstados,
                            name: "estados",
                            id: "form-estado-id",
                            "data-cy": "estado",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.filtroInstitucion,
                                "estado",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.estadosMexico, function (estadoElement) {
                          return _c(
                            "option",
                            {
                              key: estadoElement.id,
                              domProps: { value: estadoElement.nombre },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(estadoElement.nombre) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "8" } }),
                  _vm._v(" "),
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.isDisabled || _vm.isSaving,
                              variant: "outline-danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.buscarAutoridadesEnApeiron()
                              },
                            },
                          },
                          [
                            _vm.isSaving
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "clock",
                                    animation: "spin-pulse",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.search")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.clean()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("apeironGwApp.institucion.borrar")
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "accordion", attrs: { role: "tablist" } },
        _vm._l(_vm.instituciones, function (inst, instIndex) {
          return _c(
            "b-card",
            { key: instIndex, attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                {
                  staticClass: "p-1",
                  attrs: { "header-tag": "header", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: `institucion-${instIndex}`,
                          expression: "`institucion-${instIndex}`",
                        },
                      ],
                      staticClass: "bg-success text-white",
                      attrs: { block: "", variant: "info" },
                      on: {
                        click: function ($event) {
                          _vm.isSaci && _vm.buscarDependencia(inst)
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(inst.nombre) + "\n          "),
                      _vm.isSaving
                        ? _c("b-icon", {
                            attrs: { icon: "clock", animation: "spin-pulse" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: `institucion-${instIndex}`,
                    visible: false,
                    accordion: "institucion",
                    role: "nivel1",
                  },
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {
                                staticClass: "form-control-label",
                                attrs: { for: "form-name" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.consulta.tipoInstitucion"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: inst.tipo.descripcion,
                                    expression: "inst.tipo.descripcion",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "tipoInstitucion",
                                  id: "form-tipoInstitucion",
                                  "data-cy": "tipoInstitucion",
                                  disabled: "true",
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.consulta.tipoInstitucion"
                                  ),
                                },
                                domProps: { value: inst.tipo.descripcion },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      inst.tipo,
                                      "descripcion",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {
                                staticClass: "form-control-label",
                                attrs: { for: "form-pais" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.consulta.pais"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: inst.pais,
                                    expression: "inst.pais",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "pais",
                                  id: "pais-id",
                                  "data-cy": "pais",
                                  disabled: "true",
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.consulta.pais"
                                  ),
                                },
                                domProps: { value: inst.pais },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(inst, "pais", $event.target.value)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {
                                staticClass: "form-control-label",
                                attrs: { for: "form-entidad" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.consulta.entidad"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: inst.estado.descripcion,
                                    expression: "inst.estado.descripcion",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "entidad",
                                  id: "entidad-id",
                                  "data-cy": "entidad",
                                  disabled: "true",
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.consulta.entidad"
                                  ),
                                },
                                domProps: { value: inst.estado.descripcion },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      inst.estado,
                                      "descripcion",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {
                                staticClass: "form-control-label",
                                attrs: { for: "form-tipoNivelUno" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.consulta.tipoNivelUno"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: inst.tipoNivel.descripcion,
                                    expression: "inst.tipoNivel.descripcion",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "tipoNivelUno",
                                  id: "tipoNivelUno-id",
                                  "data-cy": "tipoNivelUno",
                                  disabled: "true",
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.consulta.tipoNivelUno"
                                  ),
                                },
                                domProps: { value: inst.tipoNivel.descripcion },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      inst.tipoNivel,
                                      "descripcion",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "9" } }, [
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "h3",
                              {
                                attrs: {
                                  id: "page-heading",
                                  "data-cy": "FiltroHeading",
                                },
                              },
                              [
                                _c("span", {
                                  attrs: { id: "Filtro-heading" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.institucion.tabla.autoridadesInstitucion"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showNuevoAutoridad(
                                        inst,
                                        null,
                                        null,
                                        null
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.institucion.tabla.nuevaAutoridad"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-icon", { attrs: { icon: "" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              hover: "",
                              items: inst.cargo,
                              fields: _vm.campos,
                              "show-empty": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(rol)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.item.rol.descripcion) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "empty",
                                  fn: function () {
                                    return [
                                      _c("h4", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.institucion.tabla.vacio"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "cell(acciones)",
                                  fn: function (row) {
                                    return [
                                      row.item.nombre !== ""
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "mr-1",
                                              attrs: {
                                                title: _vm.$t(
                                                  "apeironGwApp.institucion.tabla.eliminarNivelActual"
                                                ),
                                                disabled: _vm.isSaving,
                                                ariant: "danger",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.eliminarAutoridad(
                                                    row.item,
                                                    inst,
                                                    null,
                                                    null,
                                                    null,
                                                    "0"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "entity.action.delete"
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.isSaving
                                                ? _c("b-icon", {
                                                    attrs: {
                                                      icon: "clock",
                                                      animation: "spin-pulse",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.item.nombre !== ""
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "mr-1",
                                              attrs: {
                                                title: _vm.$t(
                                                  "apeironGwApp.institucion.tabla.eliminarSubNiveles"
                                                ),
                                                disabled: _vm.isSaving,
                                                ariant: "danger",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.eliminarAutoridadArbol(
                                                    row.item,
                                                    inst,
                                                    null,
                                                    null,
                                                    null,
                                                    "0"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.institucion.tabla.eliminarArbol"
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.isSaving
                                                ? _c("b-icon", {
                                                    attrs: {
                                                      icon: "clock",
                                                      animation: "spin-pulse",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(nombre)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-nombre" },
                                        [
                                          _vm._v(
                                            _vm._s(row.item.nombre) +
                                              " " +
                                              _vm._s(row.item.primerApellido) +
                                              " " +
                                              _vm._s(row.item.segundoApellido)
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(cargo)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-autoridad" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.item.nombreCargo) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(cvu)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.item.cvu) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(inst.dependencia, function (dep, depIndex) {
                        return _c(
                          "b-card",
                          { key: depIndex, attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-header",
                              {
                                staticClass: "p-4",
                                attrs: { "header-tag": "header", role: "tab" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle",
                                        value: `depedencia-${depIndex}`,
                                        expression: "`depedencia-${depIndex}`",
                                      },
                                    ],
                                    staticClass: "bg-info text-white",
                                    attrs: { block: "", variant: "secondary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isSaci &&
                                          _vm.buscarSubDependencia(inst, dep)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(dep.nombre) +
                                        "\n                "
                                    ),
                                    _vm.isSaving
                                      ? _c("b-icon", {
                                          attrs: {
                                            icon: "clock",
                                            animation: "spin-pulse",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  id: `depedencia-${depIndex}`,
                                  visible: false,
                                  accordion: "depedencia",
                                  role: "nivel2",
                                },
                              },
                              [
                                _c(
                                  "b-card-body",
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "5" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", {
                                                staticClass:
                                                  "form-control-label",
                                                attrs: { for: "form-pais" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.institucion.consulta.pais"
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: dep.pais,
                                                    expression: "dep.pais",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "pais",
                                                  id: "pais-id",
                                                  "data-cy": "pais",
                                                  disabled: "true",
                                                  placeholder: _vm.$t(
                                                    "apeironGwApp.institucion.consulta.pais"
                                                  ),
                                                },
                                                domProps: { value: dep.pais },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      dep,
                                                      "pais",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-col", { attrs: { cols: "5" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", {
                                                staticClass:
                                                  "form-control-label",
                                                attrs: { for: "form-entidad" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.institucion.consulta.entidad"
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      dep.estado.descripcion,
                                                    expression:
                                                      "dep.estado.descripcion",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "entidad",
                                                  id: "entidad-id",
                                                  "data-cy": "entidad",
                                                  disabled: "true",
                                                  placeholder: _vm.$t(
                                                    "apeironGwApp.institucion.consulta.entidad"
                                                  ),
                                                },
                                                domProps: {
                                                  value: dep.estado.descripcion,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      dep.estado,
                                                      "descripcion",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "outline-danger",
                                                  disabled:
                                                    _vm.botonEliminarDependeciaDisabled ||
                                                    _vm.isSaving,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.eliminarDependencia(
                                                      inst,
                                                      dep
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "entity.action.delete"
                                                      )
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("b-icon", {
                                                  attrs: { icon: "" },
                                                }),
                                                _vm._v(" "),
                                                _vm.isSaving
                                                  ? _c("b-icon", {
                                                      attrs: {
                                                        icon: "clock",
                                                        animation: "spin-pulse",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "9" } }, [
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "h3",
                                            {
                                              attrs: {
                                                id: "page-heading",
                                                "data-cy": "FiltroHeading",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                attrs: { id: "Filtro-heading" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.institucion.tabla.autoridadesDependencia"
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "float-right" },
                                            [
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "outline-danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showNuevoAutoridad(
                                                        inst,
                                                        dep,
                                                        null,
                                                        null
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "apeironGwApp.institucion.tabla.nuevaAutoridad"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("b-icon", {
                                                    attrs: { icon: "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("b-table", {
                                          attrs: {
                                            striped: "",
                                            hover: "",
                                            items: dep.cargo,
                                            fields: _vm.campos,
                                            "show-empty": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(rol)",
                                                fn: function (row) {
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.item.rol
                                                            .descripcion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "empty",
                                                fn: function () {
                                                  return [
                                                    _c("h4", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "apeironGwApp.institucion.tabla.vacio"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "cell(acciones)",
                                                fn: function (row) {
                                                  return [
                                                    row.item.nombre !== ""
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover",
                                                                modifiers: {
                                                                  hover: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              title: _vm.$t(
                                                                "apeironGwApp.institucion.tabla.eliminarNivelActual"
                                                              ),
                                                              disabled:
                                                                _vm.isSaving,
                                                              ariant: "danger",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.eliminarAutoridad(
                                                                  row.item,
                                                                  inst,
                                                                  dep,
                                                                  null,
                                                                  null,
                                                                  "1"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "entity.action.delete"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.isSaving
                                                              ? _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "clock",
                                                                    animation:
                                                                      "spin-pulse",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    row.item.nombre !== ""
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover",
                                                                modifiers: {
                                                                  hover: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              title:
                                                                "Eliminar en el nivel actual y todos los subniveles",
                                                              disabled:
                                                                _vm.isSaving,
                                                              ariant: "danger",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.eliminarAutoridadArbol(
                                                                  row.item,
                                                                  inst,
                                                                  dep,
                                                                  null,
                                                                  null,
                                                                  "1"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "apeironGwApp.institucion.tabla.eliminarArbol"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.isSaving
                                                              ? _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "clock",
                                                                    animation:
                                                                      "spin-pulse",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(nombre)",
                                                fn: function (row) {
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.item.nombre +
                                                            " " +
                                                            row.item
                                                              .primerApellido +
                                                            " " +
                                                            row.item
                                                              .segundoApellido
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(cargo)",
                                                fn: function (row) {
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.item.nombreCargo
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(cvu)",
                                                fn: function (row) {
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(row.item.cvu) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      dep.subDependencia,
                                      function (subDep, subIndex) {
                                        return _c(
                                          "b-card",
                                          {
                                            key: subIndex,
                                            attrs: { "no-body": "" },
                                          },
                                          [
                                            _c(
                                              "b-card-header",
                                              {
                                                staticClass: "p-4",
                                                attrs: {
                                                  "header-tag": "header",
                                                  role: "tab",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-toggle",
                                                        rawName: "v-b-toggle",
                                                        value: `subDependencia-${subIndex}`,
                                                        expression:
                                                          "`subDependencia-${subIndex}`",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "bg-dark text-white",
                                                    attrs: {
                                                      block: "",
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isSaci &&
                                                          _vm.buscarDepartamento(
                                                            inst,
                                                            subDep
                                                          )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(subDep.nombre) +
                                                        "\n                      "
                                                    ),
                                                    _vm.isSaving
                                                      ? _c("b-icon", {
                                                          attrs: {
                                                            icon: "clock",
                                                            animation:
                                                              "spin-pulse",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-collapse",
                                              {
                                                attrs: {
                                                  id: `subDependencia-${subIndex}`,
                                                  visible: false,
                                                  accordion: "subdepedencia",
                                                  role: "nivel3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-body",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              cols: "5",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c("label", {
                                                                  staticClass:
                                                                    "form-control-label",
                                                                  attrs: {
                                                                    for: "form-pais",
                                                                  },
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "apeironGwApp.institucion.consulta.pais"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        subDep.pais,
                                                                      expression:
                                                                        "subDep.pais",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    name: "pais",
                                                                    id: "pais-id",
                                                                    "data-cy":
                                                                      "pais",
                                                                    disabled:
                                                                      "true",
                                                                    placeholder:
                                                                      _vm.$t(
                                                                        "apeironGwApp.institucion.consulta.pais"
                                                                      ),
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      subDep.pais,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          subDep,
                                                                          "pais",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              cols: "5",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c("label", {
                                                                  staticClass:
                                                                    "form-control-label",
                                                                  attrs: {
                                                                    for: "form-entidad",
                                                                  },
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "apeironGwApp.institucion.consulta.entidad"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        subDep
                                                                          .estado
                                                                          .descripcion,
                                                                      expression:
                                                                        "subDep.estado.descripcion",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    name: "entidad",
                                                                    id: "entidad-id",
                                                                    "data-cy":
                                                                      "entidad",
                                                                    disabled:
                                                                      "true",
                                                                    placeholder:
                                                                      _vm.$t(
                                                                        "apeironGwApp.institucion.consulta.entidad"
                                                                      ),
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      subDep
                                                                        .estado
                                                                        .descripcion,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          subDep.estado,
                                                                          "descripcion",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "outline-danger",
                                                                  disabled:
                                                                    _vm.botonEliminarSubDependeciaDisabled ||
                                                                    _vm.isSaving,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.eliminarSubDependencia(
                                                                        inst,
                                                                        dep,
                                                                        subDep
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "entity.action.delete"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _vm.isSaving
                                                                  ? _c(
                                                                      "b-icon",
                                                                      {
                                                                        attrs: {
                                                                          icon: "clock",
                                                                          animation:
                                                                            "spin-pulse",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              cols: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c(
                                                              "h3",
                                                              {
                                                                attrs: {
                                                                  id: "page-heading",
                                                                  "data-cy":
                                                                    "FiltroHeading",
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  attrs: {
                                                                    id: "Filtro-heading",
                                                                  },
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "apeironGwApp.institucion.tabla.autoridadesSubDependencia"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "float-right",
                                                              },
                                                              [
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-danger",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showNuevoAutoridad(
                                                                            inst,
                                                                            dep,
                                                                            subDep,
                                                                            null
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "apeironGwApp.institucion.tabla.nuevaAutoridad"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-icon",
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("b-table", {
                                                          attrs: {
                                                            striped: "",
                                                            hover: "",
                                                            items: subDep.cargo,
                                                            fields: _vm.campos,
                                                            "show-empty": "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "cell(rol)",
                                                                fn: function (
                                                                  row
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          row
                                                                            .item
                                                                            .rol
                                                                            .descripcion
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "empty",
                                                                fn: function () {
                                                                  return [
                                                                    _c("h4", {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "apeironGwApp.institucion.tabla.vacio"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "cell(acciones)",
                                                                fn: function (
                                                                  row
                                                                ) {
                                                                  return [
                                                                    row.item
                                                                      .nombre !==
                                                                    ""
                                                                      ? _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  _vm.$t(
                                                                                    "apeironGwApp.institucion.tabla.eliminarNivelActual"
                                                                                  ),
                                                                                disabled:
                                                                                  _vm.isSaving,
                                                                                ariant:
                                                                                  "danger",
                                                                                size: "sm",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.eliminarAutoridad(
                                                                                    row.item,
                                                                                    inst,
                                                                                    dep,
                                                                                    subDep,
                                                                                    null,
                                                                                    "2"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    textContent:
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "entity.action.delete"
                                                                                        )
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.isSaving
                                                                              ? _c(
                                                                                  "b-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "clock",
                                                                                        animation:
                                                                                          "spin-pulse",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    row.item
                                                                      .nombre !==
                                                                    ""
                                                                      ? _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  _vm.$t(
                                                                                    "apeironGwApp.institucion.tabla.eliminarSubNiveles"
                                                                                  ),
                                                                                disabled:
                                                                                  _vm.isSaving,
                                                                                ariant:
                                                                                  "danger",
                                                                                size: "sm",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.eliminarAutoridadArbol(
                                                                                    row.item,
                                                                                    inst,
                                                                                    dep,
                                                                                    subDep,
                                                                                    null,
                                                                                    "2"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    textContent:
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "apeironGwApp.institucion.tabla.eliminarArbol"
                                                                                        )
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.isSaving
                                                                              ? _c(
                                                                                  "b-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "clock",
                                                                                        animation:
                                                                                          "spin-pulse",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(nombre)",
                                                                fn: function (
                                                                  row
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          row
                                                                            .item
                                                                            .nombre +
                                                                            " " +
                                                                            row
                                                                              .item
                                                                              .primerApellido +
                                                                            " " +
                                                                            row
                                                                              .item
                                                                              .segundoApellido
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(cargo)",
                                                                fn: function (
                                                                  row
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          row
                                                                            .item
                                                                            .nombreCargo
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(cvu)",
                                                                fn: function (
                                                                  row
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          row
                                                                            .item
                                                                            .cvu
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      subDep.departamento,
                                                      function (
                                                        depart,
                                                        deparIndex
                                                      ) {
                                                        return _c(
                                                          "b-card",
                                                          {
                                                            key: deparIndex,
                                                            attrs: {
                                                              "no-body": "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-card-header",
                                                              {
                                                                staticClass:
                                                                  "p-4",
                                                                attrs: {
                                                                  "header-tag":
                                                                    "header",
                                                                  role: "tab",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-toggle",
                                                                          rawName:
                                                                            "v-b-toggle",
                                                                          value: `departamento-${deparIndex}`,
                                                                          expression:
                                                                            "`departamento-${deparIndex}`",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "bg-warning text-white",
                                                                    attrs: {
                                                                      block: "",
                                                                      variant:
                                                                        "warning",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          depart.nombre
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                    _vm.isSaving
                                                                      ? _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "clock",
                                                                                animation:
                                                                                  "spin-pulse",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "b-collapse",
                                                              {
                                                                attrs: {
                                                                  id: `departamento-${deparIndex}`,
                                                                  visible: false,
                                                                  accordion:
                                                                    "departamento",
                                                                  role: "nivel4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-card-body",
                                                                  [
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "5",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-group",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "form-control-label",
                                                                                    attrs:
                                                                                      {
                                                                                        for: "form-pais",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        textContent:
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "apeironGwApp.institucion.consulta.pais"
                                                                                            )
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            depart.pais,
                                                                                          expression:
                                                                                            "depart.pais",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "form-control",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "text",
                                                                                        name: "pais",
                                                                                        id: "pais-id",
                                                                                        "data-cy":
                                                                                          "pais",
                                                                                        disabled:
                                                                                          "true",
                                                                                        placeholder:
                                                                                          _vm.$t(
                                                                                            "apeironGwApp.institucion.consulta.pais"
                                                                                          ),
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          depart.pais,
                                                                                      },
                                                                                    on: {
                                                                                      input:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            $event
                                                                                              .target
                                                                                              .composing
                                                                                          )
                                                                                            return
                                                                                          _vm.$set(
                                                                                            depart,
                                                                                            "pais",
                                                                                            $event
                                                                                              .target
                                                                                              .value
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "5",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-group",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "form-control-label",
                                                                                    attrs:
                                                                                      {
                                                                                        for: "form-entidad",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        textContent:
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "apeironGwApp.institucion.consulta.entidad"
                                                                                            )
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            depart
                                                                                              .estado
                                                                                              .descripcion,
                                                                                          expression:
                                                                                            "depart.estado.descripcion",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "form-control",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "text",
                                                                                        name: "entidad",
                                                                                        id: "entidad-id",
                                                                                        "data-cy":
                                                                                          "entidad",
                                                                                        disabled:
                                                                                          "true",
                                                                                        placeholder:
                                                                                          _vm.$t(
                                                                                            "apeironGwApp.institucion.consulta.entidad"
                                                                                          ),
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          depart
                                                                                            .estado
                                                                                            .descripcion,
                                                                                      },
                                                                                    on: {
                                                                                      input:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            $event
                                                                                              .target
                                                                                              .composing
                                                                                          )
                                                                                            return
                                                                                          _vm.$set(
                                                                                            depart.estado,
                                                                                            "descripcion",
                                                                                            $event
                                                                                              .target
                                                                                              .value
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "2",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    variant:
                                                                                      "outline-danger",
                                                                                    disabled:
                                                                                      _vm.botonEliminarDepartamentoDisabled ||
                                                                                      _vm.isSaving,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.eliminarDepartamento(
                                                                                        inst,
                                                                                        dep,
                                                                                        subDep,
                                                                                        depart
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        textContent:
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "entity.action.delete"
                                                                                            )
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "b-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm.isSaving
                                                                                  ? _c(
                                                                                      "b-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            icon: "clock",
                                                                                            animation:
                                                                                              "spin-pulse",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "9",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "h3",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    id: "page-heading",
                                                                                    "data-cy":
                                                                                      "FiltroHeading",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        id: "Filtro-heading",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        textContent:
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "apeironGwApp.institucion.tabla.autoridadesDepartamento"
                                                                                            )
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "3",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "float-right",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "b-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        variant:
                                                                                          "outline-danger",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.showNuevoAutoridad(
                                                                                            inst,
                                                                                            dep,
                                                                                            subDep,
                                                                                            depart
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        domProps:
                                                                                          {
                                                                                            textContent:
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "apeironGwApp.institucion.tabla.nuevaAutoridad"
                                                                                                )
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "b-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            icon: "",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("br"),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "b-table",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                striped:
                                                                                  "",
                                                                                hover:
                                                                                  "",
                                                                                items:
                                                                                  depart.cargo,
                                                                                fields:
                                                                                  _vm.campos,
                                                                                "show-empty":
                                                                                  "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "cell(rol)",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              row
                                                                                                .item
                                                                                                .rol
                                                                                                .descripcion
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "empty",
                                                                                    fn: function () {
                                                                                      return [
                                                                                        _c(
                                                                                          "h4",
                                                                                          {
                                                                                            domProps:
                                                                                              {
                                                                                                textContent:
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "apeironGwApp.institucion.tabla.vacio"
                                                                                                    )
                                                                                                  ),
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                    proxy: true,
                                                                                  },
                                                                                  {
                                                                                    key: "cell(acciones)",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        row
                                                                                          .item
                                                                                          .nombre !==
                                                                                        ""
                                                                                          ? _c(
                                                                                              "b-button",
                                                                                              {
                                                                                                directives:
                                                                                                  [
                                                                                                    {
                                                                                                      name: "b-tooltip",
                                                                                                      rawName:
                                                                                                        "v-b-tooltip.hover",
                                                                                                      modifiers:
                                                                                                        {
                                                                                                          hover: true,
                                                                                                        },
                                                                                                    },
                                                                                                  ],
                                                                                                staticClass:
                                                                                                  "mr-1",
                                                                                                attrs:
                                                                                                  {
                                                                                                    title:
                                                                                                      _vm.$t(
                                                                                                        "apeironGwApp.institucion.tabla.eliminarNivelActual"
                                                                                                      ),
                                                                                                    disabled:
                                                                                                      _vm.isSaving,
                                                                                                    ariant:
                                                                                                      "danger",
                                                                                                    size: "sm",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.eliminarAutoridad(
                                                                                                        row.item,
                                                                                                        inst,
                                                                                                        dep,
                                                                                                        subDep,
                                                                                                        depart,
                                                                                                        "3"
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    domProps:
                                                                                                      {
                                                                                                        textContent:
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "entity.action.delete"
                                                                                                            )
                                                                                                          ),
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _vm.isSaving
                                                                                                  ? _c(
                                                                                                      "b-icon",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            icon: "clock",
                                                                                                            animation:
                                                                                                              "spin-pulse",
                                                                                                          },
                                                                                                      }
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "cell(nombre)",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              row
                                                                                                .item
                                                                                                .nombre +
                                                                                                " " +
                                                                                                row
                                                                                                  .item
                                                                                                  .primerApellido +
                                                                                                " " +
                                                                                                row
                                                                                                  .item
                                                                                                  .segundoApellido
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "cell(cargo)",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              row
                                                                                                .item
                                                                                                .nombreCargo
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "cell(cvu)",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              row
                                                                                                .item
                                                                                                .cvu
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass:
                                                          "text-right my-3",
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "outline-danger",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showNuevoSubNivelModal(
                                                                        inst,
                                                                        dep,
                                                                        subDep
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "apeironGwApp.institucion.tabla.nuevoDepartamento"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-row",
                                      { staticClass: "text-right my-3" },
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "outline-danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showNuevoSubNivelModal(
                                                      inst,
                                                      dep,
                                                      null
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "apeironGwApp.institucion.tabla.nuevaSubDependencia"
                                                      )
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("b-icon", {
                                                  attrs: { icon: "" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "text-right my-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showNuevoSubNivelModal(
                                        inst,
                                        null,
                                        null
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.institucion.tabla.nuevaDependencia"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-icon", { attrs: { icon: "" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.mostrarPaginacion
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.pageInstitucion.items,
                      "per-page": _vm.pageInstitucion.perPage,
                      align: "center",
                    },
                    on: { input: _vm.cambioDePaginaInstitucion },
                    model: {
                      value: _vm.pageInstitucion.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInstitucion, "page", $$v)
                      },
                      expression: "pageInstitucion.page",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pageInstitucion.items > _vm.pageInstitucion.perPage
        ? _c(
            "b-row",
            { staticClass: "justify-content-center text-center" },
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c("div", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.institucion.totalRegistros", {
                          total: this.pageInstitucion.items,
                        })
                      ),
                    },
                  }),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "autoridadModal", attrs: { size: "xl" } },
        [
          _c("h4", {
            staticClass: "modal-title",
            attrs: { slot: "modal-title", id: "showNuevaAutoridad" },
            domProps: {
              textContent: _vm._s(
                _vm.$t("apeironGwApp.institucion.modalNuevaAutoridad.titulo")
              ),
            },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body pad" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "h4",
                      {
                        attrs: { id: "TituloModal", "data-cy": "TituloModal" },
                      },
                      [
                        _c("span", {
                          attrs: { id: "TituloModal" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("apeironGwApp.institucion.institucion")
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.nuevaAutoridad.institucion
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", {
                            staticClass: "form-control-label",
                            attrs: { for: "form-institucion" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.institucion.institucion")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nuevaAutoridad.institucion.nombre,
                                expression: "nuevaAutoridad.institucion.nombre",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "institucion",
                              id: "institucion-id",
                              "data-cy": "institucion",
                              disabled: "true",
                              placeholder: _vm.$t(
                                "apeironGwApp.institucion.institucion"
                              ),
                            },
                            domProps: {
                              value: _vm.nuevaAutoridad.institucion.nombre,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.nuevaAutoridad.institucion,
                                  "nombre",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nuevaAutoridad.dependencia
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", {
                            staticClass: "form-control-label",
                            attrs: { for: "form-dependencia" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.institucion.modalNuevaAutoridad.dependencia"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nuevaAutoridad.dependencia.nombre,
                                expression: "nuevaAutoridad.dependencia.nombre",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "dependencia",
                              id: "dependencia-id",
                              "data-cy": "dependencia",
                              disabled: "true",
                              placeholder: _vm.$t(
                                "apeironGwApp.institucion.modalNuevaAutoridad.dependencia"
                              ),
                            },
                            domProps: {
                              value: _vm.nuevaAutoridad.dependencia.nombre,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.nuevaAutoridad.dependencia,
                                  "nombre",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nuevaAutoridad.subDependencia
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", {
                            staticClass: "form-control-label",
                            attrs: { for: "form-subDependencia" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.institucion.modalNuevaAutoridad.subdependencia"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nuevaAutoridad.subDependencia.nombre,
                                expression:
                                  "nuevaAutoridad.subDependencia.nombre",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "subDependencia",
                              id: "subDependencia-id",
                              "data-cy": "subDependencia",
                              disabled: "true",
                              placeholder: _vm.$t(
                                "apeironGwApp.institucion.modalNuevaAutoridad.subdependencia"
                              ),
                            },
                            domProps: {
                              value: _vm.nuevaAutoridad.subDependencia.nombre,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.nuevaAutoridad.subDependencia,
                                  "nombre",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nuevaAutoridad.departamento
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", {
                            staticClass: "form-control-label",
                            attrs: { for: "form-departamento" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.institucion.modalNuevaAutoridad.departamento"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nuevaAutoridad.departamento.nombre,
                                expression:
                                  "nuevaAutoridad.departamento.nombre",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "departamento",
                              id: "departamento-id",
                              "data-cy": "departamento",
                              disabled: "true",
                              placeholder: _vm.$t(
                                "apeironGwApp.institucion.modalNuevaAutoridad.departamento"
                              ),
                            },
                            domProps: {
                              value: _vm.nuevaAutoridad.departamento.nombre,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.nuevaAutoridad.departamento,
                                  "nombre",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "h4",
                      {
                        attrs: {
                          id: "SubTituloModal",
                          "data-cy": "SubTituloModal",
                        },
                      },
                      [
                        _c("span", {
                          attrs: { id: "SubTituloModal" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "apeironGwApp.institucion.modalNuevaAutoridad.autoridad"
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-cvu-nueva-auto" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.placeholder.cvu")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.autoridad.cvu,
                            expression: "autoridad.cvu",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "numbert",
                          name: "cvu-nueva-auto",
                          id: "cvu-nueva-autoid",
                          "data-cy": "cvu-nueva-auto",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.placeholder.cvu"
                          ),
                          maxlength: 14,
                        },
                        domProps: { value: _vm.autoridad.cvu },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.autoridad, "cvu", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                !_vm.autoridad.cvu ||
                                _vm.isSaving ||
                                _vm.isGuardando,
                              variant: "outline-danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.buscarCVU()
                              },
                            },
                          },
                          [
                            _vm.isSaving || _vm.isGuardando
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "clock",
                                    animation: "spin-pulse",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.search")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.cleanNuevaAutoridad()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.institucion.modalNuevaAutoridad.borrar"
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-nombre" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.nombre")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.autoridad.nombre,
                            expression: "autoridad.nombre",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "nombre",
                          id: "form-nombre-id",
                          "data-cy": "nombre",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.nombre"
                          ),
                          disabled: "true",
                        },
                        domProps: { value: _vm.autoridad.nombre },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.autoridad,
                              "nombre",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-name" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoPaterno")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.autoridad.primerApellido,
                            expression: "autoridad.primerApellido",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "apellidoPaterno",
                          id: "form-apellidoPaterno-id",
                          "data-cy": "apellidoPaterno",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.apellidoPaterno"
                          ),
                          disabled: "true",
                        },
                        domProps: { value: _vm.autoridad.primerApellido },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.autoridad,
                              "primerApellido",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-cargo" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.institucion.apellidoMaterno")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.autoridad.segundoApellido,
                            expression: "autoridad.segundoApellido",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "apellido-materno",
                          id: "form-apellido-materno-id",
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.apellidoMaterno"
                          ),
                          "data-cy": "apellido-materno",
                          disabled: "true",
                        },
                        domProps: { value: _vm.autoridad.segundoApellido },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.autoridad,
                              "segundoApellido",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-nombre-autoridad" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.modalNuevaAutoridad.cargo"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.autoridad.nombreCargo,
                            expression: "autoridad.nombreCargo",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "cargo",
                          id: "form-Cargo-id",
                          "data-cy": "cargo",
                          maxlength: 256,
                          placeholder: _vm.$t(
                            "apeironGwApp.institucion.modalNuevaAutoridad.cargo"
                          ),
                        },
                        domProps: { value: _vm.autoridad.nombreCargo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.autoridad,
                              "nombreCargo",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-rol-autoridad" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.modalNuevaAutoridad.rol"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.autoridad.rol,
                              expression: "autoridad.rol",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "rol-autoridad",
                            id: "form-rol-autoridad-id",
                            "data-cy": "rol-autoridad",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.autoridad,
                                "rol",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.rolAutoridad, function (element) {
                          return _c(
                            "option",
                            { key: element.id, domProps: { value: element } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(element.descripcion) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-nivel-guardado" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.modalNuevaAutoridad.nivelGuardado"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.nuevaAutoridad.nivelGuardado,
                              expression: "nuevaAutoridad.nivelGuardado",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "-nivel-guardado",
                            id: "form-nivel-guardado-id",
                            "data-cy": "nivel-guardado",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.nuevaAutoridad,
                                "nivelGuardado",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _vm.nuevaAutoridad.departamento
                            ? _c(
                                "option",
                                { domProps: { value: _vm.nivelGuardado[0] } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.nivelGuardado[0].descripcion) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._l(_vm.nivelGuardado, function (element) {
                                return _c(
                                  "option",
                                  {
                                    key: element.id,
                                    domProps: { value: element },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(element.descripcion) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              ref: "confirmModal",
              attrs: { title: "Confirmación", "hide-footer": "" },
            },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {
                        staticClass: "form-control-label",
                        attrs: { for: "form-cvu-nueva-auto" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.institucion.modalNuevaAutoridad.confirmarAutoridad"
                            )
                          ),
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "text-center justify-content-center" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmAction()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.confirm")
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelAction()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "button",
                    id: "jhi-confirm-delete-solicitud",
                    "data-cy": "entityConfirmAddAutoridadButton",
                    disabled:
                      _vm.isDisabledGuardado || _vm.isSaving || _vm.isGuardando,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.mostrarModalConfirmacion()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.save")),
                    },
                  }),
                  _vm._v(" "),
                  _vm.isSaving || _vm.isGuardando
                    ? _c("b-icon", {
                        attrs: { icon: "clock", animation: "spin-pulse" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hideModalNuevaAutoridad()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.cancel")),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.$t("apeironGwApp.institucion.modalNuevoSubNivel.titulo"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.hideModalNuevoSubNivel },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("entity.action.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.isNuevoSubNivelValido || _vm.isSaving,
                      },
                      on: { click: _vm.guardarNuevoSubNivel },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("entity.action.save")) +
                          "\n        "
                      ),
                      _vm.isSaving
                        ? _c("b-icon", {
                            attrs: { icon: "clock", animation: "spin-pulse" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showNuevoSubNivelModalFlag,
            callback: function ($$v) {
              _vm.showNuevoSubNivelModalFlag = $$v
            },
            expression: "showNuevoSubNivelModalFlag",
          },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.nuevoSubNivel.idInstitucion
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.institucion"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.nuevoSubNivel.descInstitucion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstitucion",
                                      $$v
                                    )
                                  },
                                  expression: "nuevoSubNivel.descInstitucion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nuevoSubNivel.idInstDependencia
                    ? _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.dependencia"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.nuevoSubNivel.descInstDependencia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstDependencia",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "nuevoSubNivel.descInstDependencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nuevoSubNivel.idInstSubdependencia
                    ? _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.subdependencia"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.nuevoSubNivel.descInstSubdependencia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstSubdependencia",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "nuevoSubNivel.descInstSubdependencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nuevoSubNivel.idInstitucion &&
                  !_vm.nuevoSubNivel.idInstDependencia
                    ? _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.dependencia"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.modalNuevoSubNivel.placeholder.dependencia"
                                  ),
                                  maxlength: "150",
                                },
                                model: {
                                  value: _vm.nuevoSubNivel.descInstDependencia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstDependencia",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "nuevoSubNivel.descInstDependencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nuevoSubNivel.idInstDependencia &&
                  !_vm.nuevoSubNivel.idInstSubdependencia
                    ? _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.subdependencia"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.modalNuevoSubNivel.placeholder.subdependencia"
                                  ),
                                  maxlength: "150",
                                },
                                model: {
                                  value:
                                    _vm.nuevoSubNivel.descInstSubdependencia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstSubdependencia",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "nuevoSubNivel.descInstSubdependencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nuevoSubNivel.idInstDependencia &&
                  _vm.nuevoSubNivel.idInstSubdependencia
                    ? _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "apeironGwApp.institucion.modalNuevoSubNivel.departamento"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "apeironGwApp.institucion.modalNuevoSubNivel.placeholder.departamento"
                                  ),
                                  maxlength: "150",
                                },
                                model: {
                                  value: _vm.nuevoSubNivel.descInstDepartamento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.nuevoSubNivel,
                                      "descInstDepartamento",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "nuevoSubNivel.descInstDepartamento",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "apeironGwApp.institucion.modalNuevoSubNivel.estado"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              model: {
                                value: _vm.nuevoSubNivel.idEntidad,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.nuevoSubNivel,
                                    "idEntidad",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "nuevoSubNivel.idEntidad",
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "null" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.institucion.modalNuevoSubNivel.placeholder.estado"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.estadosMexico,
                                function (estadoElement) {
                                  return _c(
                                    "option",
                                    {
                                      key: estadoElement.id,
                                      domProps: { value: estadoElement.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(estadoElement.nombre) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }