import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { PadronEncabezado } from '@conacyt/historicopersonasms-client';

@Component
export default class LastPadronInfoComponent extends Vue {
  @Prop({ required: true })
  lastExecution: PadronEncabezado;

  @Prop({ required: false })
  status: boolean;

  showLastExecution = true;

  @Emit('show-last-execute')
  handleShowLastExecution() {
    this.showLastExecution = !this.showLastExecution;
    return this.showLastExecution;
  }
}
