var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      this.form?.investigador?.cvu
        ? _c("persona-resumen", {
            attrs: { persona: this.form.investigador, showDireccion: false },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vigencias-sni", { attrs: { item: _vm.expediente?.vigenciasSni } }),
      _vm._v(" "),
      _c("acreditaciones", { attrs: { item: _vm.expediente?.acreditaciones } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }