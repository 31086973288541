export interface IPagoAdeudo {
  montoPagado?: number | 0;
  mesPago?: string;
  restaAdeudo?: number | 0;
}

export interface IComentarioJuicio {
  descripcion?: string;
  fecha?: any;
}

export interface ICat {
  id?: string;
  desc?: string;
  clave?: string;
}

export interface IRestForm {
  movimiento?: IMovimiento;
}

export interface IMovimiento {
  tipo?: ICat;
  inicioAplicacion?: any;
  finAplicacion?: any;
  observacion?: string;
  motivo?: ICat;
  autoridad?: string;
  actoReclamado?: ICat[];
  numeroJucio?: string;
  mesProgramado?: string;
  estatus?: string;
  comentarios?: ComentarioJuicio[];
  pagosAdeudos?: PagoAdeudo[];
  montoAdeudo?: number;
  estatusAdeudo?: string;
  actoReclamadoTexto?: string;
  suspension?: string;
  incidente?: string;
  ejecutoria?: string;
  oficioSolicitud?: string;
  decision?: string;
  tipoIncidente?: string;
  tipoEjecutoria?: string;
  tipoOficioSolicitud?: string;
  oficioSolicitudTexto?: string;
  historicoJuicioProceso?: IHistoricoJuicio[];
  noProceso?: number;
}

export interface IHistoricoJuicio {
  proceso?: string;
  tipoProceso?: string;
  descProceso?: string;
  noProceso?: number;
  fecha?: any;
  estatus?: string;
}

export class RestForm implements IRestForm {
  constructor(public movimiento?: IMovimiento) {}
}

export class Movimiento implements IMovimiento {
  constructor(
    public tipo?: ICat,
    public inicioAplicacion?: any,
    public finAplicacion?: any,
    public observacion?: string,
    public motivo?: ICat,
    public autoridad?: string,
    public actoReclamado?: ICat[],
    public numeroJucio?: string,
    public mesProgramado?: string,
    public estatus?: string,
    public comentarios?: IComentarioJuicio[],
    public pagosAdeudos?: IPagoAdeudo[],
    public montoAdeudo?: number,
    public estatusAdeudo?: string,
    public actoReclamadoTexto?: string,
    public suspension?: string,
    public incidente?: string,
    public ejecutoria?: string,
    public oficioSolicitud?: string,
    public decision?: string,
    public tipoIncidente?: string,
    public tipoEjecutoria?: string,
    public tipoOficioSolicitud?: string,
    public oficioSolicitudTexto?: string,
    public historicoJuicioProceso?: IHistoricoJuicio[],
    public noProceso?: number
  ) {
    this.comentarios = this.comentarios ? this.comentarios : [];
    this.pagosAdeudos = this.pagosAdeudos ? this.pagosAdeudos : [];
    this.historicoJuicioProceso = this.historicoJuicioProceso ? this.historicoJuicioProceso : [];
  }
}

export class PagoAdeudo implements IPagoAdeudo {
  constructor(public montoPagado?: number | 0, public mesPago?: string, public restaAdeudo?: number | 0) {}
}

export class ComentarioJuicio implements IComentarioJuicio {
  constructor(public descripcion?: string, public fecha?: string) {}
}

export class Cat implements ICat {
  constructor(public id?: string, public desc?: string, public clave?: string) {}
}

export class HistoricoJuicio implements IHistoricoJuicio {
  constructor(
    public proceso?: string,
    public tipoProceso?: string,
    public descProceso?: string,
    public noProceso?: number,
    public fecha?: any,
    public estatus?: string
  ) {}
}
