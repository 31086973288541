var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "text-center",
        attrs: {
          items: _vm.permisos,
          fields: _vm.campos,
          "show-empty": "",
          "head-variant": "primary",
          "caption-top": "",
        },
        scopedSlots: _vm._u([
          {
            key: "table-caption",
            fn: function () {
              return [
                _c("div", { staticClass: "font-weight-bolder h4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("archeApp.solucion.process.permission.title"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("archeApp.solucion.process.permission.subtitle")
                    )
                  ),
                ]),
                _vm._v(" "),
                !_vm.readonly
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-end" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.handleOpenAddPermisoModal },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "plus" } }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("entity.action.add"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "h4 text-center" }, [
                  _vm._v(_vm._s(_vm.$t("entity.data.blank"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "head(avatar)",
            fn: function () {
              return [_vm._v(" # ")]
            },
            proxy: true,
          },
          {
            key: "cell(avatar)",
            fn: function () {
              return [_c("b-avatar", { attrs: { variant: "primary" } })]
            },
            proxy: true,
          },
          {
            key: "cell(rol)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("apeironGwApp.RolAutoridad." + row.item.rol)
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(acciones)",
            fn: function (row) {
              return _vm._l(row.item.acciones, function (accion) {
                return _c(
                  "b-badge",
                  {
                    key: accion,
                    staticClass: "m-1",
                    attrs: { pill: "", variant: "primary" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("apeironGwApp.TipoAccion." + accion)) +
                        "\n      "
                    ),
                  ]
                )
              })
            },
          },
          {
            key: "cell(opciones)",
            fn: function (row) {
              return [
                !_vm.readonly
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "danger", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePrepareToRemove(row.index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("entity.action.delete"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "success", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditar(row.index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("entity.action.edit")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addPermiso",
          attrs: { id: "addPermiso", size: "xl", scrollable: "" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("archeApp.solucion.process.permission.add"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "componente-add-rol",
                    size: "sm",
                    label: "Rol",
                    "label-for": "add-rol",
                  },
                },
                [
                  _c("b-form-select", {
                    staticClass: "mb-3",
                    attrs: { id: "add-rol", options: _vm.roles },
                    model: {
                      value: _vm.permiso.rol,
                      callback: function ($$v) {
                        _vm.$set(_vm.permiso, "rol", $$v)
                      },
                      expression: "permiso.rol",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Aviso" } },
                    [
                      _c("aviso-editable", {
                        model: {
                          value: _vm.permiso.aviso,
                          callback: function ($$v) {
                            _vm.$set(_vm.permiso, "aviso", $$v)
                          },
                          expression: "permiso.aviso",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Acciones" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "componente-add-acciones",
                            size: "sm",
                            label: "Permisos",
                            "label-for": "add-acciones",
                          },
                        },
                        [
                          _c("b-form-checkbox-group", {
                            attrs: {
                              id: "add-acciones",
                              options: _vm.acciones,
                              stacked: "",
                            },
                            model: {
                              value: _vm.permiso.acciones,
                              callback: function ($$v) {
                                _vm.$set(_vm.permiso, "acciones", $$v)
                              },
                              expression: "permiso.acciones",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c("button", {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
              on: {
                click: function ($event) {
                  return _vm.handleCloseAddPermisoModal()
                },
              },
            }),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn btn-primary",
              attrs: {
                type: "button",
                id: "jhi-confirm-delete-solicitud",
                "data-cy": "entityConfirmAddAutoridadButton",
              },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.save")) },
              on: {
                click: function ($event) {
                  return _vm.handleAddPermiso()
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmRemoveModal",
          attrs: { "header-bg-variant": "primary" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("Confirmar eliminación")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", [_vm._v("Estas seguro de eliminar esta permiso?")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }