import { Component } from 'vue-property-decorator';
import { StatusCodes } from 'http-status-codes';
import LastPadronInfoComponent from '@/components/pago/last-padron-info/last-padron-info.vue';
import PadronDownloadComponent from '@/components/pago/padron-download/padron-download.vue';
import { Constants } from '@/components/pago/padron/constants';
import PadronEncabezadoComponent from '@/components/pago/padron-encabezado.component';
import { mixins } from 'vue-class-component';
import { PadronEncabezado, PadronNomina } from '@conacyt/historicopersonasms-client';

@Component({
  components: {
    'last-padron-info': LastPadronInfoComponent,
    'padron-download': PadronDownloadComponent,
  },
})
export default class PadronComponent extends mixins(PadronEncabezadoComponent) {
  perPageOptions = [10, 50, 100, 150];
  tipoPersonaOptions = [
    { value: 'INV', text: 'Investigador' },
    { value: 'AYU', text: 'Ayudante' },
  ];
  tipoPersonaSelected = 'INV';
  currentPadronEncabezado: PadronEncabezado = null;
  padron: PadronNomina[] = [];
  currentPage = 1;
  perPage = 150;
  totalRows = 0;

  cvuToSearch = null;

  public fields = Constants.FIELDS;

  resetPagination() {
    this.padron = [];
    this.currentPage = 1;
    this.totalRows = 0;
  }

  handleShowLastExecute(isRetrivingLastPadron: boolean) {
    this.retrivePadronEncabezado(isRetrivingLastPadron);
  }

  doAfterRetrivePadronEncabezado(lastPadronEncabezado: PadronEncabezado) {
    this.currentPadronEncabezado = lastPadronEncabezado;
    this.retrivePadron();
  }

  public retrivePadron() {
    this.isSearching = true;
    this.getPadronPromise(this.tipoPersonaSelected)
      .then(result => {
        if (result) {
          this.isSearching = false;
          this.resolvePagination(result.headers);
          this.padron = result.data;
        }
      })
      .catch(error => {
        this.isSearching = false;
        if (error.response.data.status !== StatusCodes.NOT_FOUND) {
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  public getPadronPromise(tipoPersonaSelected: string) {
    if (tipoPersonaSelected === 'INV') {
      return this.historicoPersonasApi().getAllPadronNominaInvestigador(this.currentPadronEncabezado.id, this.currentPage, this.perPage);
    }
    return this.historicoPersonasApi().getAllPadronNominaAyudante(this.currentPadronEncabezado.id, this.currentPage, this.perPage);
  }

  public rowClass(item, type) {
    if (!item || type !== 'row') return '';
    if (item.reglasValidacion.estatusValidacionReglas) return 'table-success-opacity-25';
    return 'table-danger-opacity-25';
  }

  public isValid(item) {
    return item.reglasValidacion.estatusValidacionReglas;
  }
  public openConfigurarComponente(row) {
    row.toggleDetails();
  }

  public encodeRules(row) {
    if (row?.item?.reglasValidacion) {
      const list = [];
      this.resolveProperty(list, 'inicial', row.item.reglasValidacion);
      return list;
    }
    return [];
  }

  public toNormalCase(name) {
    if (name) {
      const words = name.match(/[A-Za-z][a-z]*/g) || [];
      return words.map(this.capitalize).join(' ');
    }
    return '';
  }

  public capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  public resolveProperty(list, clave, valor) {
    if (typeof valor === 'object') {
      for (const [key, value] of Object.entries(valor)) {
        const result = this.resolveProperty(list, key, value);
        if (result) {
          list.push(result);
        }
      }
    } else if (typeof valor === 'boolean') {
      return { prop: clave, valor: valor };
    } else {
      return null;
    }
  }

  public resolvePagination(headers) {
    this.totalRows = headers['x-total'];
    this.currentPage = headers['x-page'];
  }

  public handleTipoPersonaSelectedChange() {
    this.handlePageChange(1);
  }

  public handlePageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.retrivePadron();
  }

  public handlePageSizeChange() {
    this.retrivePadron();
  }

  public getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  public handleShowPersonaDetail(cvu: string) {
    this.cvuToSearch = null;
    this.cvuToSearch = cvu;
  }

  public handleConfirmarPadron() {
    this.isSearching = true;
    this.historicoPersonasApi()
      .confirmaEjecucionAceptada(this.currentPadronEncabezado.id)
      .then(result => {
        if (result) {
          this.isSearching = false;
          this.retrivePadronEncabezado(this.isRetrivingLastPadron);
        }
      })
      .catch(error => {
        this.isSearching = false;
        if (error.response.data.status !== StatusCodes.NOT_FOUND) {
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  get showConfirmButton() {
    if (this.currentPadronEncabezado?.ejecucionAceptada) {
      return false;
    }
    return true;
  }
}
