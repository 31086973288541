const solucionesSteps: any = {
  steps: [
    {
      target: '#activar-vista-previa-id',
      content: `Activa la vista previa de la solución para validar tu trabajo`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#acciones-id',
      content: `Aquí podrás ver todas las acciones que se van creando para esta solución`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#cuestionario-id',
      content: `Aquí podrás ver el cuestionario que se va generando`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#menu-dinamico-id',
      content: `Aquí podras ir registrando los componentes que se van agregando a esta solución`,
      params: {
        placement: 'right',
      },
    },
  ],
};

export default solucionesSteps;
