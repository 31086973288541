var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.lastPadronEncabezado?.estatusJob
        ? _c("job-status", {
            model: {
              value: _vm.lastPadronEncabezado.estatusJob,
              callback: function ($$v) {
                _vm.$set(_vm.lastPadronEncabezado, "estatusJob", $$v)
              },
              expression: "lastPadronEncabezado.estatusJob",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSearching
        ? _c("b-skeleton-table", {
            attrs: {
              rows: 5,
              columns: 5,
              "table-props": { bordered: true, striped: true },
            },
          })
        : _c(
            "b-list-group-item",
            { staticClass: "mb-2 border-0" },
            [
              _vm.showRules
                ? _vm._l(_vm.reglas, function (regla) {
                    return _c("div", { key: regla.id }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-10" }, [
                          _c("i", {
                            staticClass: "fa fa-align-justify handle",
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text ml-3" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(regla.tipo) +
                                  " - " +
                                  _vm._s(regla.descripcion)
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-2 text-right" },
                          [
                            _c("b-form-checkbox", {
                              attrs: { switch: "", size: "lg" },
                              model: {
                                value: regla.activo,
                                callback: function ($$v) {
                                  _vm.$set(regla, "activo", $$v)
                                },
                                expression: "regla.activo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row text-right float-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.execute-confirmation-modal",
                          modifiers: { "execute-confirmation-modal": true },
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        disabled: _vm.isDisabled(),
                        variant: "primary",
                        size: "sm",
                      },
                    },
                    [
                      _vm.isExecuting
                        ? _c("strong", { staticClass: "ml-2" }, [
                            _c("em", [
                              _vm._v(_vm._s(_vm.$t("global.process.running"))),
                            ]),
                          ])
                        : _c("strong", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.$t("global.process.execute"))),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          id: "execute-confirmation-modal",
          title: _vm.$t("apeironGwApp.pago.rules.execute.modal.title"),
          question: _vm.$t("apeironGwApp.pago.rules.execute.modal.question"),
          size: "sm",
          centered: "",
        },
        on: { confirmed: _vm.handleEjecutar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }