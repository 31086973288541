var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("header", { staticClass: "bx-header-title mt-2" }, [
        _c("span", { staticClass: "font-weight-bolder text-rizoma h3" }, [
          _vm._v(
            _vm._s(
              _vm.$t("apeironGwApp.persona.expediente.ayudantias.subtitle")
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("history-item", {
        attrs: { items: _vm.ayudantias, fields: _vm.fields },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }