import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';

export function resolveIcon(accion: TipoAccion): string {
  switch (accion) {
    case TipoAccion.ENVIAR:
      return 'check-circle';
    case TipoAccion.RECLASIFICAR:
      return 'cash-coin';
    case TipoAccion.VALIDAR:
    case TipoAccion.APROBAR:
    case TipoAccion.REVISAR:
      return 'check2-square';
    case TipoAccion.RECHAZAR:
    case TipoAccion.BAJAR:
    case TipoAccion.BAJAR_POR_ACUERDO:
    case TipoAccion.CANCELAR:
      return 'x-circle';
    case TipoAccion.ACTIVAR:
      return 'toggle-on';
    case TipoAccion.ACTUALIZAR:
      return 'save';
    case TipoAccion.DICTAMINAR:
      return 'clipboard-check';
    case TipoAccion.FORMALIZAR:
      return 'pen';
    case TipoAccion.ASIGNAR:
      return 'person-check';
    default:
      return 'check-circle';
  }
}

export function resolveVariant(accion: TipoAccion): string {
  switch (accion) {
    case TipoAccion.ENVIAR:
    case TipoAccion.VALIDAR:
    case TipoAccion.APROBAR:
      return 'primary';
    case TipoAccion.RECHAZAR:
    case TipoAccion.BAJAR:
    case TipoAccion.CANCELAR:
      return 'outline-danger';
    default:
      return 'primary';
  }
}
