var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            id: "componente-add-" + _vm.id,
            size: "sm",
            label: _vm.label,
            "label-for": "add-" + _vm.id,
          },
        },
        [
          _c("b-form-checkbox-group", {
            staticClass: "mb-3 text-left",
            attrs: {
              id: "add-" + _vm.id,
              size: "lg",
              options: _vm.options,
              stacked: "",
              switches: "",
            },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }