var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("expediente-persona", {
        ref: "registro-investigador-id",
        staticClass: "mb-3",
        attrs: {
          id: "registro-investigador-id",
          visible: false,
          "read-only": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }