import { Component, Prop, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import CargaMasivaUtils from '@/shared/data/carga-masiva-utils.service';
import ReporteService from '@/components/reporte/reporte.service';
import { TipoLayout } from '@/shared/model/enumerations/tipo-layout.model';

@Component
export default class SheetDownloadComponent extends mixins(JhiDataUtils, CargaMasivaUtils) {
  @Inject('reporteService') public reporteService: () => ReporteService;

  @Prop({ required: false })
  public instituciones: any;

  @Prop({ default: TipoLayout.INSTITUCIONES })
  public tipoLayout: TipoLayout;

  public institucionesBrief = null;

  public isDownloading = false;
  public downloadLayout(): void {
    this.resolveInstitucionesBrief();

    this.isDownloading = true;
    this.reporteService()
      .downloadLayout({ tipo: this.tipoLayout, instituciones: this.institucionesBrief })
      .then(
        res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const dummyDownloadLink = this.$refs.dummyDownloadLink as any;
          dummyDownloadLink.href = url;
          dummyDownloadLink.download = 'acreditaciones' + this.getDateName() + '.xlsx';
          dummyDownloadLink.click();
          this.isDownloading = false;
        },
        err => {
          this.isDownloading = false;
        }
      );
  }

  get downloadState(): string {
    if (this.isDownloading) {
      return 'outline-danger';
    } else {
      return 'outline-success';
    }
  }

  public getDateName() {
    const currentDate = new Date();
    return (
      currentDate.getFullYear() +
      '' +
      currentDate.getMonth() +
      '' +
      currentDate.getHours() +
      '' +
      currentDate.getMinutes() +
      '' +
      currentDate.getSeconds()
    );
  }

  public resolveInstitucionesBrief() {
    this.institucionesBrief = [];
    for (const institucion of this.instituciones) {
      const encodedName = this.encodeItem(institucion);
      this.institucionesBrief.push(this.createInstitucion(encodedName, this.resolveDependencias(institucion.dependencia)));
    }
  }

  public resolveDependencias(dependencias) {
    const dependenciasBrief = [];
    for (const dependencia of dependencias) {
      const encodedName = this.encodeItem(dependencia);
      dependenciasBrief.push(this.createDependencia(encodedName, this.resolveSubDependencias(dependencia.subDependencia)));
    }
    return dependenciasBrief;
  }

  public resolveSubDependencias(subDependencias) {
    const subdependenciasBrief = [];
    for (const subdependencia of subDependencias) {
      const encodedName = this.encodeItem(subdependencia);
      subdependenciasBrief.push(this.createSubdependencia(encodedName, this.resolveDepartamentos(subdependencia.departamento)));
    }
    return subdependenciasBrief;
  }

  public resolveDepartamentos(departamentos) {
    const departamentosBrief = [];
    for (const departamento of departamentos) {
      const encodedName = this.encodeItem(departamento);
      departamentosBrief.push(this.createDepartamento(encodedName));
    }
    return departamentosBrief;
  }

  public createInstitucion(nombre, dependencias) {
    return { nombre: nombre, dependencias: dependencias };
  }

  public createDependencia(nombre, subdependencias) {
    return { nombre: nombre, subdependencias: subdependencias };
  }

  public createSubdependencia(nombre, departamentos) {
    return { nombre: nombre, departamentos: departamentos };
  }

  public createDepartamento(nombre) {
    return { nombre: nombre };
  }
}
