var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-table-simple",
    {
      staticClass: "mt-5",
      attrs: { borderless: "", responsive: "", fixed: "" },
    },
    [
      _vm.documentos && _vm.documentos.length > 0
        ? _c(
            "b-thead",
            [
              _c(
                "b-tr",
                { staticClass: "text-center" },
                [
                  _c("b-th", { staticStyle: { width: "17rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("apeironGwApp.configDocumentos.table.nombre")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { width: "10rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("apeironGwApp.configDocumentos.table.requerido")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { width: "9rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("apeironGwApp.configDocumentos.table.visible")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { width: "11rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("apeironGwApp.configDocumentos.table.tamanio")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { width: "15rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("apeironGwApp.configDocumentos.table.mediaTypes")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { width: "8rem" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "draggable",
        _vm._b(
          {
            attrs: { tag: "tbody" },
            model: {
              value: _vm.documentos,
              callback: function ($$v) {
                _vm.documentos = $$v
              },
              expression: "documentos",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        _vm._l(_vm.documentos, function (documento, index) {
          return _c(
            "b-tr",
            {
              key: index,
              on: {
                mouseover: function ($event) {
                  return _vm.mouseover(index)
                },
              },
            },
            [
              _c(
                "b-td",
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled: _vm.readonly,
                      placeholder: "ej. Título",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleAddProperty(index)
                      },
                    },
                    model: {
                      value: documento.nombre,
                      callback: function ($$v) {
                        _vm.$set(documento, "nombre", $$v)
                      },
                      expression: "documento.nombre",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-td",
                { staticClass: "text-center" },
                [
                  _c("b-form-checkbox", {
                    attrs: { size: "lg", disabled: _vm.readonly, switch: "" },
                    model: {
                      value: documento.requerido,
                      callback: function ($$v) {
                        _vm.$set(documento, "requerido", $$v)
                      },
                      expression: "documento.requerido",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-td",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "background-color": "#65e567" },
                      attrs: { size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.openReglaModal(index)
                        },
                      },
                    },
                    [_c("b-icon", { attrs: { icon: "pencil" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-td",
                [
                  _c("b-form-select", {
                    attrs: { disabled: _vm.readonly, options: _vm.sizeOptions },
                    model: {
                      value: documento.max,
                      callback: function ($$v) {
                        _vm.$set(documento, "max", $$v)
                      },
                      expression: "documento.max",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-td",
                [
                  _c("b-form-checkbox-group", {
                    staticClass: "mb-3",
                    attrs: {
                      disabled: _vm.readonly,
                      options: _vm.mediaTypeOptions,
                    },
                    model: {
                      value: documento.mediaTypes,
                      callback: function ($$v) {
                        _vm.$set(documento, "mediaTypes", $$v)
                      },
                      expression: "documento.mediaTypes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-td",
                [
                  _vm.isHover(index) && !_vm.readonly
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-2",
                          attrs: { variant: "outline-danger", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "x-circle", "aria-hidden": "true" },
                          }),
                          _vm._v(" Eliminar\n        "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      !_vm.readonly
        ? _c(
            "b-tr",
            [
              _c(
                "b-td",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-2",
                      attrs: { variant: "primary", size: "sm" },
                      on: { click: _vm.handleAddDocumento },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "plus-circle-dotted",
                          "aria-hidden": "true",
                        },
                      }),
                      _vm._v(" Agregar\n      "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "reglaModal",
          attrs: {
            size: "xl",
            centered: "",
            "header-bg-variant": "primary",
            scrollable: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("div", { staticClass: "text-left" }, [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("apeironGwApp.configDocumentos.reglaModal.title")
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm.columnSelected
                ? _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("codemirror", {
                        attrs: { autofocus: true, options: _vm.cmOptions },
                        on: { ready: _vm.onCmReady },
                        model: {
                          value: _vm.columnSelected.expresion,
                          callback: function ($$v) {
                            _vm.$set(_vm.columnSelected, "expresion", $$v)
                          },
                          expression: "columnSelected.expresion",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("reglaModal")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation("reglaModal")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }