import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
@Component
export default class ModalContainerComponent extends Vue {
  @Prop({ default: 'confirmation' })
  id: string;

  @Prop({ default: 'Confirmación' })
  title: string;

  @Prop({ default: false })
  scrollable: boolean;

  @Prop({ default: 'xl' })
  size: string;

  @Prop({ default: false })
  centered: boolean;

  public handleCancel(): void {
    this.hideModal();
  }

  public hideModal() {
    (this.$refs[this.id] as any).hide();
  }
}
