import { PerfilApi } from '@conacyt/perfil-usuario-client';
import { CvuApi } from '@conacyt/cvu-client';
import { IPersona } from '@/shared/model/persona/persona.model';
import * as personaMapper from '@/shared/persona/persona.mapper';

const baseApiUrl = 'services/historicopersonasms/api/expedientes';

export default class PersonaService {
  constructor(private perfilApi: PerfilApi, private cvuInfoService: CvuApi) {}

  public findByCvu(cvu: string): Promise<IPersona> {
    return new Promise<IPersona>((resolve, reject) => {
      this.perfilApi
        .getPerfil(cvu)
        .then(res => resolve(personaMapper.toPersona(res.data)))
        .catch(error => {
          if (error.response.status === 404) {
            this.cvuInfoService
              .getPersonaFisicaByCvu(cvu)
              .then(res => resolve(res.data))
              .catch(error => reject(error));
          }
          reject(error);
        });
    });
  }

  public findByLogin(login: string): Promise<IPersona> {
    return new Promise<IPersona>((resolve, reject) => {
      this.perfilApi
        .getPerfil(undefined, login)
        .then(res => resolve(personaMapper.toPersona(res.data)))
        .catch(error => {
          if (error.response.status === 404) {
            this.cvuInfoService
              .getPersonaFisicaByEmail(login)
              .then(res => resolve(res.data))
              .catch(error => reject(error));
          } else {
            reject(error);
          }
        });
    });
  }

  public findByLoginInLegacyAndMerge(login: string, persona: IPersona): Promise<IPersona> {
    return new Promise<IPersona>((resolve, reject) => {
      this.cvuInfoService
        .getPersonaFisicaByEmail(login)
        .then(res => resolve(personaMapper.mergePersona(persona, res.data)))
        .catch(error => reject(error));
    });
  }
}
