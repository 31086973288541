import { Prop, Inject, Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { Solicitud } from '@/shared/model/solicitud.model';
import SolucionMixin from '@/entities/evaluacion/mixins/solucion-mixin';
import { TipoRevision } from '@/shared/model/enumerations/tipo-revision.model';
import { IFiltro } from '@/shared/model/filtro.model';
import { FiltroRevision } from '@/shared/model/filtro-revision.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { IRevision, Revision } from '@/shared/model/revision.model';
import RevisionMixin from '@/entities/evaluacion/mixins/revision-mixin';
import { VigenciaDto } from '@/shared/model/vigencia.model';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
import SolicitudMixin from '@/entities/evaluacion/mixins/solicitud-mixin';
import { Solucion } from '@/shared/model/solucion.model';
import VigenciaMixin from '@/entities/evaluacion/mixins/vigencia-mixin';

@Component
export default class ResultadoEvaluacionComponent extends mixins(SolucionMixin, RevisionMixin, SolicitudMixin, VigenciaMixin) {
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public solucionResultado: Solucion;

  public revisionRecons: IRevision = new Revision();
  public consejoId: string;

  public get oficio(): string {
    if (this.solicitud.isReconsideracion() && this.solicitud?.esExtemporanea() && this.solicitud.estado !== EstadoSolicitud.FORMALIZADA) {
      return this.anioSolucion !== 2023 ? this.solicitud?.oficio_reconsideracion : this.solicitud?.oficio;
    } else if (
      this.solicitud.isReconsideracion() &&
      !this.solicitud.esExtemporanea() &&
      this.solicitud.estado !== EstadoSolicitud.FORMALIZADA
    ) {
      return this.anioSolucion !== 2023 ? this.solicitud?.oficio_reconsideracion : this.solicitud?.oficio;
    } else if (!this.solicitud.isReconsideracion() && this.solicitud?.estado == EstadoSolicitud.FORMALIZADA) {
      return this.anioSolucion !== 2023 ? this.solicitud?.oficio_dictaminadora : this.solicitud?.oficio;
    } else {
      return undefined;
    }
  }

  public get reconocimiento(): string {
    return this.solicitud?.reconsideracion !== undefined
      ? undefined
      : this.anioSolucion !== 2023
      ? this.solicitud?.reconocimiento_dictaminadora
      : this.solicitud?.reconocimiento;
  }

  public get vigenciaActiva(): VigenciaDto {
    return this.solicitud?.vigenciasSni?.find(vigencia => {
      return vigencia.activo && vigencia.tipo?.clave === '0';
    });
  }

  public get vigenciaPendiente(): VigenciaDto {
    return this.solicitud?.vigenciasSni?.find(vigencia => {
      return vigencia.estatusVigencia?.clave === 'PENDIENTE' && vigencia.tipo?.clave === '0';
    });
  }

  public get vigenciaActivaPendiente(): VigenciaDto {
    return this.vigenciaActiva || this.vigenciaPendiente;
  }

  mounted(): void {
    if (this.solicitud) {
      this.getDefinicionRevision(this.solicitud.solucionId);
    }
    if (this.solucionResultado) {
      this.anioSolucion = new Date(this.solucionResultado.calendario.fechaInicio).getFullYear();
    }
  }

  @Watch('definicionEvaluacion')
  public getDefinicionesRevisiones(): void {
    for (const definicion of this.definicionEvaluacion.revisiones) {
      if (definicion.tipoRevision === TipoRevision.CONSEJO && definicion.tipoComision === TipoComision.DICTAMINADORA) {
        this.consejoId = definicion.id;
      }
    }
    this.getRevisionConsejo();
  }

  /**
   * Por medio del filtro paginationQuery hace la
   * petición/consulta a revision-mixxin para obtener
   * la revisión tipo CONSEJO  y estatus REVISADA.
   *
   */
  private getRevisionConsejo(): void {
    const filtroSol: IFiltro = {};
    const filtroRev: FiltroRevision = {};

    filtroRev.solucionId = this.solicitud.solucionId;
    filtroRev.evaluacionId = this.consejoId;
    filtroRev.estado = EstadoRevision.REVISADA;

    filtroSol.estadosSolicitud = [EstadoSolicitud.FORMALIZADA];
    filtroSol.solucionId = this.solicitud.solucionId;
    filtroSol.solicitudId = this.solicitud.id;
    filtroSol.cvuSolicitante = this.solicitud.solicitante.cvu;

    const filtroSolRev = Object.assign(filtroRev, filtroSol);

    const paginationQuery = {
      page: 0,
      size: 1,
      sort: this.sort(),
      filter: filtroSolRev,
    };

    this.getRevisiones(paginationQuery);
  }

  /**
   * Busca la revisión y la muestra hacia el Front.
   */
  @Watch('solicitudRevisiones')
  public getRevisionFinal(): void {
    for (const solicitudRevision of this.solicitudRevisiones) {
      if (solicitudRevision.revisiones.length === 1) {
        for (const revision of solicitudRevision.revisiones) {
          this.revisionRecons = { ...new Revision(), ...revision };
        }
      }
    }
  }

  /**
   * Formatea una fecha en el formato "YYYY-MM-DD".
   *
   */
  public formatFecha(fecha) {
    return fecha ? fecha.slice(0, 10) : '';
  }

  /**
   * Valida que el estado este en formalizada o en firma
   * para visualizar los resultados.
   */
  public get verResultado(): boolean {
    return this.solicitud?.estado == EstadoSolicitud.FORMALIZADA || this.solicitud?.estado == EstadoSolicitud.EN_FIRMA;
  }
}
