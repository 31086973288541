var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-dropdown-item", { attrs: { href: "/solicitud" } }, [
        _c("span", { staticClass: "icon-solicitud" }),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.solicitud")),
            },
          },
          [_vm._v("Solicitud")]
        ),
      ]),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_INNOVADOR")
        ? _c("b-dropdown-item", { attrs: { to: "/solucion" } }, [
            _c("span", { staticClass: "icon-soluciones" }),
            _vm._v(" "),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.solucion")),
                },
              },
              [_vm._v("Solucion")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/revision" } }, [
        _c("span", { staticClass: "icon-revision" }),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.revision")),
            },
          },
          [_vm._v("Revision")]
        ),
      ]),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_MODELADOR")
        ? _c("b-dropdown-item", { attrs: { to: "/form" } }, [
            _c("span", { staticClass: "icon-evaluacion-2" }),
            _vm._v(" "),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.form")),
                },
              },
              [_vm._v("Form")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }