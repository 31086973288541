var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ribbon"),
      _vm._v(" "),
      _c("jhi-navbar"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wrapper",
          class: { toggled: !_vm.sidebarOpen },
          attrs: { id: "app" },
        },
        [
          _c("side-navbar", { attrs: { sidebarOpen: _vm.sidebarOpen } }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "page-content-wrapper" } },
            [
              _c(
                "header",
                {
                  staticClass: "header-conacyt",
                  class: { rizoma: _vm.authenticated },
                },
                [_c("data-user", { attrs: { sidebarOpen: _vm.sidebarOpen } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    container: !_vm.sidebarOpen,
                    "container-fluid": _vm.sidebarOpen,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [_c("router-view")],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("jhi-footer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "footer bg-transparent" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row align-items-center" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }