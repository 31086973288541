import { Filtro } from '@/shared/model/filtro.model';
import { Component, Emit, Inject } from 'vue-property-decorator';

import SolicitudService from '@/entities/solicitud/solicitud.service';

import { ISolicitud, Solicitud } from '@/shared/model/solicitud.model';
import { mixins } from 'vue-class-component';
import SolucionMixin from './solucion-mixin';
import Vue2Filters from 'vue2-filters';
import { Transicion } from '@/shared/model/proceso/transicion.model';
import { TransicionContext } from '@/shared/model/proceso/transicion-context.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { ISolicitudRevision } from '@/shared/model/solicitud-revision.model';

/**
 * An utility service for SolicitudMixin.
 */
@Component({
  mixins: [Vue2Filters.mixin],
})
export default class SolicitudMixin extends mixins(SolucionMixin) {
  @Inject('solicitudService') public solicitudService: () => SolicitudService;

  public idsSolicitud: Array<string> = [];

  public arraySolicitudes: Array<ISolicitud> = [];

  public solicitud: Solicitud;

  public getListaSolicitudes(listasolicitudes: Array<Solicitud>) {
    this.idsSolicitud = new Array<string>();
    for (const solicitudAsignada of listasolicitudes) {
      this.idsSolicitud.push(solicitudAsignada.id);
    }
    const filtroSolicitud = new Filtro();
    filtroSolicitud.idsSolicitud = this.idsSolicitud;
    filtroSolicitud.estadosSolicitud = [
      EstadoSolicitud.VALIDADA,
      EstadoSolicitud.DICTAMINADA,
      EstadoSolicitud.REVISADA,
      EstadoSolicitud.FORMALIZADA,
      EstadoSolicitud.ENVIADA,
      EstadoSolicitud.EN_CORRECCION,
    ];
    const paginationQuery = {
      page: SolucionMixin.PAGE - 1,
      size: listasolicitudes.length,
      sort: this.sort(),
      filter: filtroSolicitud,
    };
    if (listasolicitudes.length > 0) {
      this.solicitudService()
        .retrieve(paginationQuery)
        .then(r => {
          this.arraySolicitudes = r.data;
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
  }

  /**
   * Actualiza una solicitud
   * @param solicitud Solicitud a actualizar
   */
  @Emit('solicitudDictaminada')
  public async dictaminarSolicitud(solicitud: Solicitud): Promise<Solicitud> {
    try {
      const transicionContext = new TransicionContext(new Transicion(TipoAccion.DICTAMINAR), null, solicitud.id, solicitud.solucionId);
      return await this.solicitudService().transition(transicionContext);
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
      throw error;
    }
  }

  /**
   * La solicitud se aprueba por lo tanto la solicitud queda en estado revisado.
   * @param solicitud Solicitud a aprobar
   */
  public async aprobarSolicitud(solicitud: ISolicitud): Promise<Solicitud> {
    const transicionContext = new TransicionContext();
    transicionContext.solicitudId = solicitud.id;
    transicionContext.solucionId = solicitud.solucionId;
    const transicion = new Transicion(TipoAccion.REVISAR);
    transicionContext.transicion = transicion;
    try {
      return await this.solicitudService().transition(transicionContext);
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
      throw error;
    }
  }

  /**
   * La solicitud se envia a firmar el convenio.
   * @param solicitud Solicitud a firmar
   */
  public async enviaFirma(solicitud: ISolicitud): Promise<Solicitud> {
    const transicionContext = new TransicionContext();
    transicionContext.solicitudId = solicitud.id;
    transicionContext.solucionId = solicitud.solucionId;
    const transicion = new Transicion(TipoAccion.ACTIVAR);
    transicionContext.transicion = transicion;
    try {
      return await this.solicitudService().transition(transicionContext);
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
      throw error;
    }
  }

  /**
   * Comprueba si la solicitud es de tipo ingreso.
   * @returns Devuelve true si la solicitud es de tipo ingreso, de lo contrario devuelve false.
   */
  public get solucionTipoIngreso(): boolean {
    return this.solicitud?.SOLICITUD_RIZOMA?.solucionSolicitud?.tipoSolucion === this.tipoIngreso;
  }

  /**
   * Comprueba si la solicitud es de tipo emérito.
   * @returns Devuelve true si la solicitud es de tipo emérito, de lo contrario devuelve false.
   */
  public get solucionTipoEmerito(): boolean {
    return this.solicitud?.SOLICITUD_RIZOMA?.solucionSolicitud?.tipoSolucion === this.tipoEmerito;
  }

  /**
   * Ordena por comparación de cadenas, en otras palabras, ordena ascendentemente por el estado de la solicitud.
   * @param data Las solicitudes con revisiones
   */
  public ordenarPorEstado(data: ISolicitudRevision[]): ISolicitudRevision[] {
    return data.sort((a, b) => {
      return a.solicitud.estado.localeCompare(b.solicitud.estado);
    });
  }
}
