import { Component, Prop, Vue } from 'vue-property-decorator';
import { Adeudo } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class ExcepcionesComponent extends Vue {
  @Prop({ required: false, type: Array<Adeudo> })
  public adeudos: Array<Adeudo>;

  get fields(): any {
    return [
      { key: 'activo', label: this.$t('apeironGwApp.persona.expediente.excepciones.activo').toString(), sortable: true },
      { key: 'solicitudId', label: this.$t('apeironGwApp.persona.expediente.excepciones.solicitudId').toString(), sortable: true },
      { key: 'tipo.descripcion', label: this.$t('apeironGwApp.persona.expediente.excepciones.tipo').toString(), sortable: true },
      { key: 'anio', label: this.$t('apeironGwApp.persona.expediente.excepciones.anio').toString(), sortable: true },
      { key: 'estatusInicial', label: this.$t('apeironGwApp.persona.expediente.excepciones.estatusInicial').toString(), sortable: true },
      {
        key: 'fechaAlta',
        label: this.$t('apeironGwApp.persona.expediente.excepciones.fechaAlta').toString(),
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      { key: 'estatusFinal', label: this.$t('apeironGwApp.persona.expediente.excepciones.estatusFinal').toString(), sortable: true },
      {
        key: 'fechaCierre',
        label: this.$t('apeironGwApp.persona.expediente.excepciones.fechaCierre').toString(),
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
    ];
  }
}
