import { Component, Inject } from 'vue-property-decorator';

import SolicitudService from '@/entities/solicitud/solicitud.service';
import { ISolicitud } from '@/shared/model/solicitud.model';
import { mixins } from 'vue-class-component';
import SolucionMixin from './solucion-mixin';
import { HistoricoPersonasApi, VigenciaSni } from '@conacyt/historicopersonasms-client';
import { IVigenciaDto, INivelSni, IVigencia, ITipoVigencia, IEstatusVigencia, VigenciaDto } from '@/shared/model/vigencia.model';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import { ISolicitudRevision } from '@/shared/model/solicitud-revision.model';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
class RespuestaHistorico {
  public isVigente?: boolean;
  public isNivelTres?: boolean;
  public countNivelTres?: number;
  public vigenciaExcepcion?: IVigenciaDto;
}
/**
 * An utility service for VigenciaMixin.
 */
@Component({
  computed: {
    loginCvu: {
      get() {
        return this.$store.getters.userInfo?.cvu;
      },
    },
  },
})
export default class VigenciaMixin extends mixins(SolucionMixin) {
  @Inject('solicitudService') public solicitudService: () => SolicitudService;
  @Inject('historicoPersonasApi') public historicoPersonasApi: () => HistoricoPersonasApi;

  public readonly loginCvu!: string;

  public idHistorico: string;

  public anioSolucion: number = null;
  private primerDia = '-01-01';
  private ultimoDia = '-12-31';

  /**
   * Consulta histórico de vigencias y busca si
   * es Vigente, Nivel 3, tiene vigencia Excepción.
   *
   * @memberof VigenciaComponent
   */
  public obtieneHistoricoVigencias(solicitudConRevision): Promise<RespuestaHistorico> {
    let isVigente = false;
    let isNivelTres = false;
    let countNivelTres = 0;
    let vigenciaExcepcion = null;
    const vigenciasN3: Array<VigenciaSni> = [];
    const respuesta = new RespuestaHistorico();

    return this.historicoPersonasApi()
      .getExpediente(solicitudConRevision.solicitud.solicitante.cvu)
      .then(res => {
        if (res?.data?.vigenciasSni?.length > 0) {
          for (const vigencia of res.data.vigenciasSni) {
            if (vigencia.activo === true && vigencia.estatusVigencia.clave === 'ACTIVO' && vigencia.nivelSni.clave != '6') {
              isVigente = true;
            }
            if (isVigente && vigencia.estatusVigencia.clave === 'ACTIVO' && vigencia.nivelSni.clave === '3') {
              isNivelTres = true;
            }
            if (vigencia.nivelSni.clave === '3') {
              vigenciasN3.push(vigencia);
            }
            if (
              vigencia.activo === true &&
              vigencia.estatusVigencia.clave === 'ACTIVO' &&
              vigencia.tipo.clave === '3' &&
              new Date(vigencia.vigencia.fechaFin).getFullYear() > this.anioSolucion
            ) {
              vigenciaExcepcion = vigencia;
            }
          }
          if (isNivelTres && vigenciasN3.length > 1) {
            countNivelTres = this.vigenciasN3consecutivas(vigenciasN3);
          }

          this.idHistorico = res.data.id;
        }

        respuesta.isNivelTres = isNivelTres;
        respuesta.isVigente = isVigente;
        respuesta.countNivelTres = countNivelTres;
        respuesta.vigenciaExcepcion = vigenciaExcepcion;

        return respuesta;
      })
      .catch(err => {
        if (err.response.status === 404 || err.status === 404) {
          respuesta.isNivelTres = isNivelTres;
          respuesta.isVigente = isVigente;
          respuesta.countNivelTres = countNivelTres;
          respuesta.vigenciaExcepcion = vigenciaExcepcion;
          return respuesta;
        }
        throw new Error(this.$t('dictamen.errorHistorico').toString());
      });
  }

  /**
   * Calcula vigencia para casos tipo evaluación.
   * @param solicitudConRevision
   * @param isVigente
   * @param isNivelTres
   * @param countNivelTres
   */
  public calculoVigenciaSni(solicitudConRevision, isVigente, isNivelTres, countNivelTres): IVigencia {
    const anioInicio = this.anioSolucion + 1;
    let anioFin = null;
    if (solicitudConRevision.revision.respuesta.evaluacion.id === '4') {
      anioFin = this.anioSolucion + 5;
      if (isVigente === true && isNivelTres === true && countNivelTres > 1) {
        anioFin = anioFin + 5;
      }
    } else if (
      solicitudConRevision.revision.respuesta.evaluacion.id === '3' ||
      solicitudConRevision.revision.respuesta.evaluacion.id === '2'
    ) {
      anioFin = this.anioSolucion + 5;
    } else if (solicitudConRevision.revision.respuesta.evaluacion.id === '1') {
      anioFin = this.anioSolucion + 4;
    } else if (solicitudConRevision.revision.respuesta.evaluacion.id === '5') {
      anioFin = this.anioSolucion + 1;
    } else if (
      solicitudConRevision.revision.respuesta.evaluacion.id === '6' &&
      solicitudConRevision.solicitud.SOLICITUD_RIZOMA.solucionSolicitud.tipoSolucion === TipoSolucion.EMERITOS
    ) {
      anioFin = this.anioSolucion + 100;
    }

    const fechaInicioVig = new Date(anioInicio + this.primerDia).toISOString();

    const fechaFinVig = new Date(anioFin + this.ultimoDia).toISOString();

    const vigenciaFechas: IVigencia = { fechaInicio: fechaInicioVig, fechaFin: fechaFinVig };

    return vigenciaFechas;
  }

  /**
   * Calcula las vigencias para casos tipo 65+15
   * @param solicitudConRevision
   * @param vigenciaExcepcion
   * @returns
   */
  public calculoVigenciaSniExcep(solicitudConRevision: ISolicitudRevision, vigenciaExcepcion): IVigencia {
    const anioInicio = this.anioSolucion + 1;
    let nivelDictam = Number(solicitudConRevision.revision.respuesta.evaluacion.id);
    const nivelExcep = Number(vigenciaExcepcion.nivelSni.clave);

    if (nivelDictam === 5) {
      nivelDictam = 0;
    } else {
      nivelDictam = nivelDictam - 1;
    }

    const esNivelSuperior = nivelDictam > nivelExcep;

    if (
      (nivelDictam !== 5 && !solicitudConRevision.solicitud.isReconsideracion()) ||
      (solicitudConRevision.solicitud.isReconsideracion() && esNivelSuperior)
    ) {
      const fechaInicioVig = new Date(anioInicio + this.primerDia).toISOString();

      const fechaFinVig = vigenciaExcepcion.vigencia.fechaFin;

      const vigenciaFechas: IVigencia = { fechaInicio: fechaInicioVig, fechaFin: fechaFinVig };

      return vigenciaFechas;
    } else {
      throw new Error(this.$t('dictamen.errorVigenciaExcep').toString());
    }
  }

  /**
   * Construye la vigencia (tipo evaluacion / excepción).
   * @param solicitudConRevision
   * @param vigenciaFechas
   * @param vigenciaExcepcion
   */
  private construccionVigenciaGlobal(solicitudConRevision: ISolicitudRevision, vigenciaFechas, vigenciaExcepcion): IVigenciaDto {
    const nivelSniEnvio: INivelSni = {
      clave: this.asignarClaveNivel(solicitudConRevision.revision.respuesta.evaluacion.id),
      descripcion: solicitudConRevision.revision.respuesta.evaluacion.nombre,
    };
    const solicitud = solicitudConRevision.solicitud;

    const vigenciaEnvio: IVigenciaDto = new VigenciaDto({}, { fechaInicio: null });

    let tipoVigencia: ITipoVigencia = { clave: '0', descripcion: 'EVALUACION' };

    let idSolicitudVigencia = [solicitudConRevision.solicitud.id, nivelSniEnvio.clave, tipoVigencia.clave].join('_');

    if (solicitud.isReconsideracion()) {
      tipoVigencia = { clave: '26', descripcion: 'EVALUACION-REVISORA' };
    } else if (solicitud.isEmerito()) {
      tipoVigencia = { clave: '99', descripcion: 'EMERITO' };
      idSolicitudVigencia = solicitudConRevision.solicitud.id;
    }

    const estatusVigencia: IEstatusVigencia = { clave: 'PENDIENTE', descripcion: 'Pendiente' };

    vigenciaEnvio.fechaAlta = new Date().toISOString();
    vigenciaEnvio.activo = false;
    vigenciaEnvio.nivelSni = nivelSniEnvio;
    vigenciaEnvio.vigencia = vigenciaFechas;

    if (vigenciaExcepcion && !solicitud.isEmerito()) {
      vigenciaEnvio.solicitudId = vigenciaExcepcion.solicitudId;
      vigenciaEnvio.areaConocimientoEval = {
        id: vigenciaExcepcion.areaConocimientoEval.id.toString(),
        descripcion: vigenciaExcepcion.areaConocimientoEval.descripcion,
      };
    } else {
      vigenciaEnvio.solicitudId = idSolicitudVigencia;
      vigenciaEnvio.areaConocimientoEval = {
        id: solicitudConRevision.solicitud.SOLICITUD_RIZOMA.areaConocimiento.area.clave,
        descripcion: solicitudConRevision.solicitud.SOLICITUD_RIZOMA.areaConocimiento.area.nombre,
      };
    }

    vigenciaEnvio.solucionId = solicitudConRevision.solicitud.solucionId;
    vigenciaEnvio.tipo = tipoVigencia;
    vigenciaEnvio.estatusVigencia = estatusVigencia;
    vigenciaEnvio.cvuUsuarioAlta = this.loginCvu;

    return vigenciaEnvio;
  }

  /**
   * Persiste vigencia en Solicitud Apeiron.
   * @param solicitudConRevision Solicitud con revisión
   * @param vigencia Vigencia a insertar
   */
  public insertVigenciaSolicitud(solicitudConRevision: ISolicitudRevision, vigencia: IVigenciaDto): Promise<ISolicitud> {
    const solicitud: ISolicitud = solicitudConRevision.solicitud;
    if (solicitud?.vigenciasSni == null) {
      solicitud.vigenciasSni = [];
    }
    solicitud.vigenciasSni.push(vigencia);
    return this.solicitudService()
      .update(solicitud)
      .then(sol => {
        return sol;
      })
      .catch(error => {
        throw new Error(this.$t('dictamen.errorVigencia').toString());
      });
  }

  /**
   * Asigna la clave de la vigencia a la id correspondiente
   * @param id
   * @returns
   */
  private asignarClaveNivel(id): string {
    switch (id) {
      case '1':
        return 'C';
      case '2':
        return '1';
      case '3':
        return '2';
      case '4':
        return '3';
      case '5':
        return 'N';
      case '6':
        return 'E';
      default:
        return 'No encontrado';
    }
  }

  /**
   * Inicia proceso para generar y persistir la vigencia.
   * @param solicitudConRevision
   */
  public async generarVigencia(solicitudConRevision: ISolicitudRevision): Promise<ISolicitud> {
    let historico = null;
    let fechas = null;
    // si ya tiene dos vigencias no se genera otra en reconsideración o si ya tiene una en solicitud normal
    const solicitud = solicitudConRevision.solicitud;

    if (
      (this.tipoComision === TipoComision.RECONSIDERACION && solicitud.isReconsideracion() && solicitud?.vigenciasSni?.length >= 2) ||
      (this.tipoComision === TipoComision.DICTAMINADORA && solicitudConRevision?.solicitud?.vigenciasSni?.length >= 1)
    ) {
      return;
    }

    try {
      historico = await this.obtieneHistoricoVigencias(solicitudConRevision);

      if (historico?.vigenciaExcepcion && !solicitud.isEmerito()) {
        fechas = this.calculoVigenciaSniExcep(solicitudConRevision, historico.vigenciaExcepcion);
      } else {
        fechas = this.calculoVigenciaSni(solicitudConRevision, historico.isVigente, historico.isNivelTres, historico.countNivelTres);
      }
      const vigenciaEnvio = this.construccionVigenciaGlobal(solicitudConRevision, fechas, historico?.vigenciaExcepcion);

      return await this.insertVigenciaSolicitud(solicitudConRevision, vigenciaEnvio);
    } catch (e) {
      this.alertService().showHttpError(this, e);
    }
  }

  /**
   * Revisa la continuidad de las vigencias.
   * @param listaVigencias
   * @returns
   */
  private vigenciasN3consecutivas(listaVigencias: Array<VigenciaSni>): number {
    let countNivelTres = 1;
    const vigenciaActual = listaVigencias.find(v => v.estatusVigencia.clave === 'ACTIVO');
    const fechaInicio = new Date(vigenciaActual.vigencia.fechaInicio).getFullYear() - 1;
    for (const vigenciaN3 of listaVigencias) {
      if (fechaInicio === new Date(vigenciaN3.vigencia.fechaFin).getFullYear()) {
        countNivelTres++;
      }
    }
    return countNivelTres;
  }
}
