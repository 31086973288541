import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VersionComponent extends Vue {
  @Prop({ default: 0 })
  public version: any;

  @Prop({ default: null })
  public mensaje: string;

  @Prop({ default: false })
  public archive: boolean;

  get variant() {
    return this.archive ? 'ligth' : 'warning';
  }

  get icon() {
    return this.archive ? 'archive' : 'tag-fill';
  }
}
