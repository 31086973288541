var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "border text-justify", attrs: { id: "dictamen-consejo" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.revisionPlenaria
                ? _c(
                    "b-tab",
                    { attrs: { active: "" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _vm.solucionTipoIngreso
                            ? _c("h3", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("dictamen.evaluacionPleno")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("evaluacion-individual", {
                            attrs: {
                              solicitud: _vm.solicitud,
                              evaluacionIndividual: false,
                              evaluacionPlenaria: true,
                              revision: _vm.revisionPlenaria,
                            },
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          !_vm.checkboxValue
                            ? _c("h3", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("dictamen.plenaria")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.checkboxValue
                            ? _c("h3", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("dictamen.consejo")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "excepcionConsejo",
                                        name: "check-Consejo",
                                        disabled:
                                          this.revision.estado ===
                                          this.revisada,
                                      },
                                      on: { change: _vm.handlecheckboxValue },
                                      model: {
                                        value: _vm.checkboxValue,
                                        callback: function ($$v) {
                                          _vm.checkboxValue = $$v
                                        },
                                        expression: "checkboxValue",
                                      },
                                    },
                                    [
                                      _vm.solucionTipoIngreso
                                        ? _c("a", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "dictamen.checkDictaminadora"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.solucionTipoEmerito
                                        ? _c("a", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("dictamen.checkEmeritos")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.checkboxValue
                            ? _c(
                                "b-row",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-container",
                                        {
                                          staticClass: "border text-justify",
                                          attrs: { id: "dictamen-consejo" },
                                        },
                                        [
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.textoConsejo)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                !_vm.checkboxValue
                                  ? _c(
                                      "div",
                                      [
                                        !_vm.solicitud.reconsideracion &&
                                        _vm.solucionTipoIngreso
                                          ? _c("dictamen", {
                                              attrs: {
                                                solicitud: _vm.solicitud,
                                                evaluacionPlenaria: true,
                                                solucion: _vm.solucionConsejo,
                                                revision: _vm.revisionPlenaria,
                                              },
                                            })
                                          : _vm.solicitud.reconsideracion &&
                                            _vm.solucionTipoIngreso
                                          ? _c("dictamen-reconsideracion", {
                                              attrs: {
                                                solicitud: _vm.solicitud,
                                                evaluacionPlenaria: true,
                                                solucion: _vm.solucionConsejo,
                                                revision: _vm.revisionPlenaria,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.solucionTipoEmerito
                                          ? _c("dictamen-emeritos", {
                                              attrs: {
                                                solicitud: _vm.solicitud,
                                                evaluacionPlenaria: true,
                                                solucion: _vm.solucionConsejo,
                                                revision: _vm.revisionPlenaria,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticClass: "mb-5 float-right" },
                                  [
                                    this.revision.estado === this.aceptada
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              size: "md",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.guardarDatosRecuperados(
                                                  _vm.revisionPlenaria
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("entity.action.save")
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              staticClass: "mr-2",
                                              attrs: { icon: "save" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }