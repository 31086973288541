import Vue from 'vue';

export class AlertCommons {
  delayTimeToHide = 5000;

  public showInfo(instance: Vue, message: string, params?: any) {
    const alertMessage = instance.$t(message, params).toString();
    this.showToaster(instance, instance.$t('global.messages.info.title').toString(), alertMessage, 'info');
  }

  public showSuccess(instance: Vue, message: string, params?: any, delayTimeToHide?: number) {
    const alertMessage = instance.$t(message, params).toString();
    this.showToaster(instance, instance.$t('global.messages.success.title').toString(), alertMessage, 'success', delayTimeToHide);
  }

  public showWarning(instance: Vue, message: string, params?: any) {
    const alertMessage = instance.$t(message, params).toString();
    this.showToaster(instance, instance.$t('global.messages.warning.title').toString(), alertMessage, 'warning');
  }

  public showError(instance: Vue, message: string, params?: any) {
    const alertMessage = instance.$t(message, params).toString();
    this.showToaster(instance, instance.$t('global.messages.error.title').toString(), alertMessage, 'danger');
  }

  public showToaster(instance: Vue, title: string, messsage: string, variant: string, delayTimeToHide?: number) {
    (instance.$root as any).$bvToast.toast(messsage, {
      toaster: 'b-toaster-top-right',
      title: title,
      variant: variant,
      solid: true,
      appendToast: true,
      autoHideDelay: delayTimeToHide || this.delayTimeToHide,
    });
  }

  public showHttpError(instance: Vue, httpErrorResponse: any) {
    if (!httpErrorResponse) {
      this.showError(instance, 'error.serverNotReachable');
      return;
    }
    switch (httpErrorResponse.status) {
      case 0:
        this.showError(instance, 'error.serverNotReachable');
        break;

      case 400: {
        const arr = Object.keys(httpErrorResponse.headers);
        let errorHeader: string | null = null;
        let entityKey: string | null = null;
        for (const entry of arr) {
          if (entry.toLowerCase().endsWith('app-error')) {
            errorHeader = httpErrorResponse.headers[entry];
          } else if (entry.toLowerCase().endsWith('app-params')) {
            entityKey = httpErrorResponse.headers[entry];
          }
        }
        if (errorHeader) {
          const alertData = entityKey ? { entityName: instance.$t(`global.menu.entities.${entityKey}`) } : undefined;
          this.showError(instance, errorHeader, alertData);
        } else if (httpErrorResponse.data !== '' && httpErrorResponse.data.fieldErrors) {
          this.showError(instance, httpErrorResponse.data.message);
        } else {
          this.showError(instance, httpErrorResponse.data.message);
        }
        break;
      }

      case 404:
        this.showError(instance, 'error.http.404');
        break;

      case 503:
        this.showError(instance, instance.$t('error.unavailable', { entityName: httpErrorResponse.data.detail }).toString());
        break;

      default:
        this.showError(instance, httpErrorResponse.data.message);
    }
  }
}
