import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';
import DataUserComponent from './core/user/data-user.vue';
import SideNavbarComponent from './core/side-navbar/side-navbar.vue';
import { mapGetters } from 'vuex';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,

    'jhi-footer': JhiFooter,
    'data-user': DataUserComponent,
    'side-navbar': SideNavbarComponent,
  },
  computed: {
    ...mapGetters(['sidebarOpen', 'authenticated']),
  },
})
export default class App extends Vue {
  readonly sidebarOpen!: boolean;

  toggleSidebar() {
    this.$store.dispatch('toggleSidebar');
  }
}
