import { Inject, Prop, Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { Solicitud } from '@/shared/model/solicitud.model';
import SolucionMixin from '@/entities/evaluacion/mixins/solucion-mixin';
import { TipoRevision } from '@/shared/model/enumerations/tipo-revision.model';
import { IFiltro } from '@/shared/model/filtro.model';
import { FiltroRevision } from '@/shared/model/filtro-revision.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { IRevision, Revision } from '@/shared/model/revision.model';
import RevisionMixin from '@/entities/evaluacion/mixins/revision-mixin';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import { Solucion } from '@/shared/model/solucion.model';
import SolicitudMixin from '@/entities/evaluacion/mixins/solicitud-mixin';
import VigenciaMixin from '@/entities/evaluacion/mixins/vigencia-mixin';

@Component
export default class ResultadoReconsideracionComponent extends mixins(SolucionMixin, RevisionMixin, SolicitudMixin, VigenciaMixin) {
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public solucionResultado: Solucion;

  public revisionRecons: IRevision = new Revision();
  public consejoId: string;
  public esReconsideracion = false;
  public vigenciasSniReconsideracion: any;
  public isFetching = false;

  public get oficio(): string {
    if (this.solicitud.isReconsideracion() && this.solicitud.estado == EstadoSolicitud.FORMALIZADA) {
      return this.solicitud?.oficio_reconsideracion ?? this.solicitud?.oficio;
    } else {
      return undefined;
    }
  }

  public get reconocimiento(): string {
    if (this.solicitud.isReconsideracion() && this.solicitud.estado == EstadoSolicitud.FORMALIZADA) {
      return this.solicitud?.reconocimiento_reconsideracion ?? this.solicitud?.reconocimiento;
    } else {
      return undefined;
    }
  }

  mounted(): void {
    if (this.solicitud) {
      this.getDefinicionRevision(this.solicitud.solucionId);
      this.getVigenciaReconsideracionExpediente();
    }
    if (this.solucionResultado) {
      this.anioSolucion = new Date(this.solucionResultado.calendario.fechaInicio).getFullYear();
    }
  }

  @Watch('definicionEvaluacion')
  public getDefinicionesRevisiones(): void {
    for (const definicion of this.definicionEvaluacion.revisiones) {
      if (definicion.tipoRevision === TipoRevision.CONSEJO && definicion.tipoComision === TipoComision.RECONSIDERACION) {
        this.consejoId = definicion.id;
      }
    }
    this.getRevisionConsejo();
  }

  /**
   * Por medio del filtro paginationQuery hace la
   * petición/consulta a revision-mixxin para obtener
   * la revisión tipo CONSEJO  y estatus REVISADA.
   *
   */
  private getRevisionConsejo(): void {
    const filtroSol: IFiltro = {};
    const filtroRev: FiltroRevision = {};

    filtroRev.solucionId = this.solicitud.solucionId;
    filtroRev.evaluacionId = this.consejoId;
    filtroRev.estado = EstadoRevision.REVISADA;

    filtroSol.estadosSolicitud = [EstadoSolicitud.FORMALIZADA];
    filtroSol.solucionId = this.solicitud.solucionId;
    filtroSol.solicitudId = this.solicitud.id;
    filtroSol.cvuSolicitante = this.solicitud.solicitante.cvu;

    const filtroSolRev = Object.assign(filtroRev, filtroSol);

    const paginationQuery = {
      page: 0,
      size: 1,
      sort: this.sort(),
      filter: filtroSolRev,
    };

    this.getRevisiones(paginationQuery);
  }

  /**
   * Busca la revisión y la muestra hacia el Front.
   */
  @Watch('solicitudRevisiones')
  public getRevisionFinal(): void {
    for (const solicitudRevision of this.solicitudRevisiones) {
      if (solicitudRevision.revisiones.length === 1) {
        for (const revision of solicitudRevision.revisiones) {
          this.revisionRecons = { ...new Revision(), ...revision };
        }
      }
    }
  }

  /**
   * Consulta histórico de vigencias y filtra la de evalaución revisora
   *
   * @memberof ResultadoReconsideracionComponent
   */
  public getVigenciaReconsideracionExpediente(): any {
    this.isFetching = true;
    this.historicoPersonasApi()
      .getExpediente(this.solicitud.solicitante.cvu)
      .then(res => {
        this.isFetching = false;
        if (res?.data?.vigenciasSni?.length > 0) {
          for (const vigencia of res.data.vigenciasSni) {
            if (
              (vigencia.tipo?.clave === '26' || vigencia.tipo?.descripcion == 'EVALUACION-REVISORA') &&
              vigencia.solucionId == this.solicitud.solucionId &&
              vigencia.solicitudId == this.solicitud.id
            ) {
              this.esReconsideracion = true;
              this.vigenciasSniReconsideracion = vigencia;
            }
          }
        }
      })
      .catch(error => {
        this.esReconsideracion = false;
        this.isFetching = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  /**
   * Valida que el estado este en formalizada o en firma
   * para visualizar los resultados.
   */
  public get verResultado(): boolean {
    return this.solicitud?.estado == EstadoSolicitud.FORMALIZADA || this.solicitud?.estado == EstadoSolicitud.EN_FIRMA;
  }
}
