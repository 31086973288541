var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    {
      class: this.claseComponent,
      attrs: { id: "dictamen-general", fluid: "" },
    },
    [
      _c("b-alert", { attrs: { show: "" } }, [
        _c("b", [_vm._v(_vm._s(_vm.$t("dictamen.indicacionDictamen")) + " ")]),
      ]),
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.guardarDictamen.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _vm.dictamenPositivo.includes(
                this.revision?.respuesta?.evaluacion?.id
              )
                ? _c(
                    "b-col",
                    [
                      this.revision.estado === this.aceptada
                        ? _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "excepcion",
                                name: "terminos-condiciones",
                              },
                              on: { change: _vm.cambiaExcepcion },
                              model: {
                                value: _vm.dictamenCompleto.checkExcepcion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dictamenCompleto,
                                    "checkExcepcion",
                                    $$v
                                  )
                                },
                                expression: "dictamenCompleto.checkExcepcion",
                              },
                            },
                            [
                              _c("a", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("dictamen.check")),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dictamenCompleto.checkExcepcion &&
          this.revision?.respuesta?.evaluacion?.id !== this.idNoAprobado
            ? _c(
                "b-row",
                { staticClass: "mt-4 dynamic" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-container",
                        {
                          staticClass: "border text-justify",
                          attrs: { id: "dictamen-solicitud" },
                        },
                        [
                          _c("br"),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.solucion?.params?.evaluacion?.dictamenes
                                  ?.excepcionHtml
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("br"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.accepted2
            ? _c(
                "b-row",
                { staticClass: "mt-4 dynamic" },
                [
                  _vm.dictamenPositivo.includes(
                    this.revision?.respuesta?.evaluacion?.id
                  )
                    ? _c(
                        "b-col",
                        [
                          _c(
                            "b-container",
                            {
                              staticClass: "border text-justify",
                              attrs: { id: "dictamen-solicitud" },
                            },
                            [
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _c("br"),
                              _vm._v(" "),
                              _c("b-col", [
                                !_vm.dictamenNegativo.includes(
                                  this.revision?.respuesta?.evaluacion?.id
                                ) &&
                                _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                  .nombre === this.frontera
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.solucion?.params?.evaluacion
                                            ?.dictamenes?.fronteraPosivitoHtml
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("b-col", [
                                !_vm.dictamenNegativo.includes(
                                  this.revision?.respuesta?.evaluacion?.id
                                ) &&
                                _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                  .nombre === this.socioambiental
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.solucion?.params?.evaluacion
                                            ?.dictamenes?.ambientalPositivoHtml
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              this.revision?.respuesta?.evaluacion?.id == "1"
                                ? _c("b-col", [
                                    _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                      .nombre === this.tecnológica
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.solucion?.params?.evaluacion
                                                ?.dictamenes
                                                ?.tecnologicoPositivoCandidato
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              this.revision?.respuesta?.evaluacion?.id == "2"
                                ? _c("b-col", [
                                    _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                      .nombre === this.tecnológica
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.solucion?.params?.evaluacion
                                                ?.dictamenes
                                                ?.tecnologicoPositivo1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.dictamenNegativo.includes(
                                this.revision?.respuesta?.evaluacion?.id
                              )
                                ? _c("b-col", [
                                    _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                      .nombre === this.frontera
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.solucion?.params?.evaluacion
                                                ?.dictamenes
                                                ?.fronteraPosivitoHtml2y3
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                      .nombre === this.socioambiental
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.solucion?.params?.evaluacion
                                                ?.dictamenes
                                                ?.ambientalPositivoHtml2y3
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil
                                      .nombre === this.tecnológica
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.solucion?.params?.evaluacion
                                                ?.dictamenes
                                                ?.tecnologicoPositivo2y3
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.revision?.respuesta?.evaluacion?.id == this.idNoAprobado
            ? _c(
                "b-row",
                { staticClass: "dynamic" },
                [
                  _c(
                    "b-container",
                    {
                      staticClass: "border text-justify",
                      attrs: { id: "dictamen-solicitud" },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("b-col", [
                        !_vm.dictamenNegativo.includes(
                          _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira.id
                        ) &&
                        _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                          this.frontera
                          ? _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.solucion?.params?.evaluacion?.dictamenes
                                    ?.fronteraNegativoHtml
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.dictamenNegativo.includes(
                          _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira.id
                        ) &&
                        _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                          this.socioambiental
                          ? _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.solucion?.params?.evaluacion?.dictamenes
                                    ?.ambientalNegativoHtml
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira.id == "1"
                        ? _c("b-col", [
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                            this.tecnológica
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.solucion?.params?.evaluacion
                                        ?.dictamenes
                                        ?.tecnologicoNegativoCandidato
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dictamenInnovacionNegativo.includes(
                        _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira.id
                      )
                        ? _c("b-col", [
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                            this.tecnológica
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.solucion?.params?.evaluacion
                                        ?.dictamenes?.tecnologicoNegativo1
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dictamenNegativo.includes(
                        _vm.solicitud.SOLICITUD_RIZOMA.nivelAspira.id
                      )
                        ? _c("b-col", [
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                            this.frontera
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.solucion?.params?.evaluacion
                                        ?.dictamenes?.fronteraNegativoHtml2y3
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                            this.socioambiental
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.solucion?.params?.evaluacion
                                        ?.dictamenes?.ambientalNegativoHtml2y3
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.solicitud.SOLICITUD_RIZOMA.tipoPerfil.nombre ===
                            this.tecnológica
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.solucion?.params?.evaluacion
                                        ?.dictamenes?.tecnologicoNegativo2y3
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                this.revision?.respuesta?.evaluacion?.id
                  ? _c("label", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("dictamen.comentarios")),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  this.revision?.respuesta?.evaluacion?.id
                    ? _c("b-form-textarea", {
                        attrs: {
                          id: "comentarios",
                          placeholder: "Redacte aquí",
                          rows: "4",
                          disabled: this.revision.estado === this.revisada,
                          required:
                            this.revision?.respuesta?.evaluacion?.id ===
                              this.idNoAprobado ||
                            _vm.dictamenCompleto.checkExcepcion,
                          state:
                            !_vm.$v.dictamenCompleto.comentarios.$invalid ||
                            !(
                              this.revision?.respuesta?.evaluacion?.id ===
                                this.idNoAprobado ||
                              _vm.dictamenCompleto.checkExcepcion
                            ),
                        },
                        model: {
                          value: _vm.dictamenCompleto.comentarios,
                          callback: function ($$v) {
                            _vm.$set(_vm.dictamenCompleto, "comentarios", $$v)
                          },
                          expression: "dictamenCompleto.comentarios",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.required &&
                  (this.revision?.respuesta?.evaluacion?.id ===
                    this.idNoAprobado ||
                    _vm.dictamenCompleto.checkExcepcion)
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.$t("caracteres.requerido")),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.min &&
                  (this.revision?.respuesta?.evaluacion?.id ===
                    this.idNoAprobado ||
                    _vm.dictamenCompleto.checkExcepcion)
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("caracteres.min", {
                              min: _vm.caracteresMinimos,
                            })
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.dictamenCompleto.comentarios.max &&
                  (this.revision?.respuesta?.evaluacion?.id ===
                    this.idNoAprobado ||
                    _vm.dictamenCompleto.checkExcepcion)
                    ? _c("small", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("caracteres.max", {
                              max: _vm.caracteresMaximos,
                            })
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "float-right" }, [
                    _vm.$v.dictamenCompleto.comentarios.$model?.length <=
                      _vm.caracteresMinimos &&
                    (this.revision?.respuesta?.evaluacion?.id ===
                      this.idNoAprobado ||
                      _vm.dictamenCompleto.checkExcepcion)
                      ? _c("small", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.dictamenCompleto.comentarios.$model
                                  ?.length <= _vm.caracteresFaltantes,
                              expression:
                                "$v.dictamenCompleto.comentarios.$model?.length <= caracteresFaltantes",
                            },
                          ],
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.faltantes", {
                                minimos:
                                  _vm.$v.dictamenCompleto.comentarios.$model
                                    ?.length - _vm.caracteresMinimos,
                              })
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.dictamenCompleto.comentarios.$model?.length >=
                      _vm.caracteresMinimos &&
                    (this.revision?.respuesta?.evaluacion?.id ===
                      this.idNoAprobado ||
                      _vm.dictamenCompleto.checkExcepcion)
                      ? _c("small", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.dictamenCompleto.comentarios.$model
                                  ?.length <= _vm.caracteresMaximos,
                              expression:
                                "$v.dictamenCompleto.comentarios.$model?.length <= caracteresMaximos",
                            },
                          ],
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.escritos", {
                                inicial:
                                  _vm.$v.dictamenCompleto.comentarios.$model
                                    ?.length + _vm.caracterInicial,
                              })
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.dictamenCompleto.comentarios.$model?.length >
                      _vm.caracteresMaximos &&
                    (this.revision?.respuesta?.evaluacion?.id ===
                      this.idNoAprobado ||
                      _vm.dictamenCompleto.checkExcepcion)
                      ? _c("small", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("caracteres.sobrepasados")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "border-top mt-4 mb-4" }, [
                  _c("div", { staticClass: "mb-5 float-right" }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                this.revision?.respuesta?.evaluacion?.id
                  ? _c(
                      "div",
                      { staticClass: "mb-5 float-right" },
                      [
                        this.revision.estado === this.aceptada
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  size: "md",
                                  disabled: this.desactivarBoton,
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.save")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "save" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }