var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h5", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.claustroSnp.form.buscarDocente")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  !_vm.readOnly
                    ? _c("buscador-persona", {
                        on: { selected: _vm.profesorSeleccionado },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label:
                          _vm.$t(
                            "apeironGwApp.claustroSnp.form.nombreDocente"
                          ) + " *",
                        "label-for": "input-nombre-docente",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-nombre-docente",
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.nombreDocente"
                          ),
                          state: !_vm.$v.claustro.participante.nombre.$invalid,
                          disabled: "",
                        },
                        model: {
                          value: _vm.nombrecompleto,
                          callback: function ($$v) {
                            _vm.nombrecompleto = $$v
                          },
                          expression: "nombrecompleto",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label:
                          _vm.$t(
                            "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                          ) + " *",
                        "label-for": "input-tiempo-dedicacion",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "input-tiempo-dedicacion",
                          options: _vm.tiemposDedicacion,
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                          ),
                          state:
                            !_vm.$v.claustro.profesorSnp?.tiempoDedicacion
                              .$invalid,
                          disabled: _vm.readOnly,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: undefined, disabled: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .$t(
                                            "apeironGwApp.claustroSnp.form.seleccionarUno"
                                          )
                                          .toString()
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.claustro.profesorSnp.tiempoDedicacion,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.claustro.profesorSnp,
                              "tiempoDedicacion",
                              $$v
                            )
                          },
                          expression: "claustro.profesorSnp.tiempoDedicacion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label:
                          _vm.$t("apeironGwApp.claustroSnp.form.institucion") +
                          " *",
                        "label-for": "input-institucion",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "input-institucion",
                          options: _vm.instituciones,
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.institucion"
                          ),
                          state:
                            !_vm.$v.claustro.profesorSnp?.institucion.$invalid,
                          disabled:
                            _vm.instituciones.length < 2 || _vm.readOnly,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.seleccionarInstitucion()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: undefined, disabled: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .$t(
                                            "apeironGwApp.claustroSnp.form.seleccionarUno"
                                          )
                                          .toString()
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.claustro.profesorSnp.institucion,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.claustro.profesorSnp,
                              "institucion",
                              $$v
                            )
                          },
                          expression: "claustro.profesorSnp.institucion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label:
                          _vm.$t("apeironGwApp.claustroSnp.form.sede") + " *",
                        "label-for": "input-sede",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "input-sede",
                          options: _vm.sedes,
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.sede"
                          ),
                          state: !_vm.$v.claustro.profesorSnp?.sede.$invalid,
                          disabled: _vm.sedes.length < 2 || _vm.readOnly,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: undefined, disabled: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .$t(
                                            "apeironGwApp.claustroSnp.form.seleccionarUno"
                                          )
                                          .toString()
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.claustro.profesorSnp.sede,
                          callback: function ($$v) {
                            _vm.$set(_vm.claustro.profesorSnp, "sede", $$v)
                          },
                          expression: "claustro.profesorSnp.sede",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label:
                          _vm.$t("apeironGwApp.claustroSnp.form.fechaIngreso") +
                          " *",
                        "label-for": "input-fecha-ingreso",
                      },
                    },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          id: "input-fecha-ingreso",
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.fechaIngreso"
                          ),
                          state:
                            !_vm.$v.claustro.profesorSnp?.claustroFechaIngreso
                              .$invalid,
                          disabled: _vm.readOnly,
                          "value-as-date": true,
                        },
                        model: {
                          value: _vm.claustro.profesorSnp.claustroFechaIngreso,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.claustro.profesorSnp,
                              "claustroFechaIngreso",
                              $$v
                            )
                          },
                          expression:
                            "claustro.profesorSnp.claustroFechaIngreso",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label: _vm.$t(
                          "apeironGwApp.claustroSnp.form.fechaBaja"
                        ),
                        "label-for": "input-fecha-baja",
                      },
                    },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          id: "input-fecha-baja",
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.form.fechaBaja"
                          ),
                          state:
                            !_vm.$v.claustro.profesorSnp?.claustroFechaBaja
                              .$invalid,
                          disabled: _vm.readOnly,
                          "value-as-date": true,
                        },
                        model: {
                          value: _vm.claustro.profesorSnp.claustroFechaBaja,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.claustro.profesorSnp,
                              "claustroFechaBaja",
                              $$v
                            )
                          },
                          expression: "claustro.profesorSnp.claustroFechaBaja",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.claustro.profesorSnp?.nivelSniNombre,
                  expression: "claustro.profesorSnp?.nivelSniNombre",
                },
              ],
            },
            [
              _c(
                "b-col",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "info" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("p", {
                              staticClass: "font-weight-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.claustroSnp.form.areaConocimiento"
                                  ) + ":"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.claustro.profesorSnp
                                    ?.areaConocimientoNombre
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c("p", {
                              staticClass: "font-weight-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.claustroSnp.form.nivelSni"
                                  ) + ":"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.claustro.profesorSnp?.nivelSniNombre
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("p", {
                              staticClass: "font-weight-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.claustroSnp.form.fechaInicioNivelSni"
                                  ) + ":"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatUtc(
                                    _vm.claustro.profesorSnp
                                      ?.vigenciaFechaInicio
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c("p", {
                              staticClass: "font-weight-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.claustroSnp.form.fechaFinNivelSni"
                                  ) + ":"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatUtc(
                                    _vm.claustro.profesorSnp?.vigenciaFechaFin
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-row", [_c("b-col", [_c("hr")])], 1),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticStyle: { "text-align": "end" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "primary",
                        disabled: _vm.formularioIsInvalid || _vm.readOnly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.guardarClaustro()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.claustroSnp.acciones.guardar")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "save" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "secondary",
                        disabled: _vm.readOnly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.limpiarDatos()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.claustroSnp.acciones.reset")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "refresh" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }