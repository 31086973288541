import { Component, Vue, Prop } from 'vue-property-decorator';

import { EstadoProceso } from '@/shared/model/enumerations/estado-proceso.model';

@Component
export default class EstadoProcesoComponent extends Vue {
  estadoProceso: any = EstadoProceso;

  @Prop({ required: true })
  public value: EstadoProceso;

  @Prop({ default: 'datos' })
  public entityName: string;

  mounted() {}

  get jobStatus(): EstadoProceso {
    return this.value;
  }

  get animation() {
    return this.jobStatus === EstadoProceso.CARGANDO ? 'throb' : 'none';
  }

  get variant() {
    switch (this.jobStatus) {
      case EstadoProceso.PENDIENTE:
      case EstadoProceso.CARGANDO:
      case EstadoProceso.INICIAL:
        return 'warning';
      case EstadoProceso.FINALIZADO_EXITOSAMENTE:
      case EstadoProceso.FINALIZADO_PARCIALMENTE:
        return 'success';
      case EstadoProceso.FINALIZADO_CON_ERRORES:
      case EstadoProceso.DATOS_NO_ENCONTRADOS:
        return 'danger';
      default:
        return 'success';
    }
  }

  get icon() {
    switch (this.jobStatus) {
      case EstadoProceso.PENDIENTE:
      case EstadoProceso.CARGANDO:
        return 'play-circle';
      case EstadoProceso.FINALIZADO_EXITOSAMENTE:
      case EstadoProceso.FINALIZADO_PARCIALMENTE:
        return 'check2-circle';
      case EstadoProceso.FINALIZADO_CON_ERRORES:
      case EstadoProceso.DATOS_NO_ENCONTRADOS:
        return 'x-circle';
      case EstadoProceso.INICIAL:
        return 'exclamation-circle';
      default:
        return 'check2-circle';
    }
  }

  get description() {
    switch (this.jobStatus) {
      case EstadoProceso.PENDIENTE:
      case EstadoProceso.CARGANDO:
        return 'Cargando ' + this.entityName;
      case EstadoProceso.FINALIZADO_EXITOSAMENTE:
      case EstadoProceso.FINALIZADO_PARCIALMENTE:
        return this.entityName + ' cargadas';
      case EstadoProceso.FINALIZADO_CON_ERRORES:
        return 'No se puedo cargar ' + this.entityName;
      case EstadoProceso.DATOS_NO_ENCONTRADOS:
        return 'No se encontraron datos de ' + this.entityName;
      case EstadoProceso.INICIAL:
        return 'Se requiere una acción para cargar ' + this.entityName;
      default:
        return 'La información no se encuentra cargada correctamente para ' + this.entityName;
    }
  }
}
