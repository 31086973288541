import { IAreaConocimiento, INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { DefinicionEvaluacion, IDefinicionEvaluacion, IDefinicionRevision } from '@/shared/model/definicion-evaluacion.model';
import { ISolucion, Solucion } from '@/shared/model/solucion.model';
import { TipoComision } from '@conacyt/insaculacion-client';
import { Module } from 'vuex';

interface RevisionStateStorable {
  evaluacion: INivel;
  dictamen: any;
  revisorSeleccionado: number;

  /**
   * La solución seleccionada en el filtro
   */
  solucion: ISolucion;

  /**
   * El tipo de comisión seleccionado en el filtro
   */
  tipoComision: TipoComision;

  /**
   * El área de conocimiento seleccionada en el filtro
   */
  area: IAreaConocimiento;

  /**
   * El cvu del evaluador capturada en el filtro
   */
  cvuBuscar: string;

  /**
   * La definición de evaluación consultada

   */
  definicionEvaluacion: IDefinicionEvaluacion;

  /**
   * El id del revisor a buscar en el filtro
   */
  revisorId: string;

  /**
   * La definición de revisión seleccionada en el filtro
   */
  definicionRevision: IDefinicionRevision;
}

const defaultRevisionState: RevisionStateStorable = {
  evaluacion: {},
  dictamen: {},
  revisorSeleccionado: 0,
  solucion: new Solucion(),
  tipoComision: null,
  area: null,
  cvuBuscar: '',
  definicionEvaluacion: new DefinicionEvaluacion(),
  revisorId: '',
  definicionRevision: {} as IDefinicionRevision,
};

export const revisionStore: Module<RevisionStateStorable, any> = {
  state: defaultRevisionState,
  getters: {
    evaluacion: state => state.evaluacion,
    dictamen: state => state.dictamen,
    revisorSeleccionado: state => state.revisorSeleccionado,
    solucion: state => state.solucion,
    tipoComision: state => state.tipoComision,
    area: state => state.area,
    cvuBuscar: state => state.cvuBuscar,
    definicionEvaluacion: state => state.definicionEvaluacion,
    revisorId: state => state.revisorId,
    definicionRevision: state => state.definicionRevision,
  },
  mutations: {
    /**
     * Guarda la evaluación
     * @param state estado del store
     * @param evaluacion la evaluación a guardar
     */
    setEvaluacion(state, evaluacion: INivel) {
      state.evaluacion = evaluacion;
    },

    /**
     * Guarda el dictamen
     * @param state estado del store
     * @param dictamen el dictamen de la revisión
     */
    setDictamen(state, dictamen) {
      state.dictamen = dictamen;
    },

    /**
     * Establece el texto revisión seleccionada
     * @param state  estado del store
     * @param indice del revisor seleccionado
     */
    setRevisorSeleccionado(state, indice: number) {
      state.revisorSeleccionado = indice;
    },

    /**
     *
     * @param state el estado del store
     * @param solución la solución seleccionada a guardar
     */
    setSolucion(state, solucion: ISolucion) {
      state.solucion = solucion;
    },

    /**
     * Establece el tipo de comisión seleccionado
     * @param state el estado del store
     * @param tipoComision el tipo de comisión seleccionado a guardar
     */
    setTipoComision(state, tipoComision: TipoComision) {
      state.tipoComision = tipoComision;
    },

    /**
     * Establece el área de conocimiento seleccionada
     * @param state el estado del store
     * @param area el área de conocimiento seleccionada a guardar
     */
    setArea(state, area: IAreaConocimiento) {
      state.area = area;
    },

    /**
     * Establece el cvu del evaluador
     * @param state el estado del store
     * @param cvu el cvu del evaluador a guardar
     */
    setCvuBuscar(state, cvu: string) {
      state.cvuBuscar = cvu;
    },

    /**
     * Establece la definición de evaluación
     * @param state el estado del store
     * @param definicionEvaluacion la definición de evaluación a guardar
     */
    setDefinicionEvaluacion(state, definicionEvaluacion: IDefinicionEvaluacion) {
      state.definicionEvaluacion = definicionEvaluacion;
    },

    /**
     * Establece el id del revisor
     * @param state el estado del store
     * @param revisorId el id del revisor a guardar
     */
    setRevisorId(state, revisorId: string) {
      state.revisorId = revisorId;
    },

    /**
     * Establece la definición de revisión
     * @param state el estado del store
     * @param definicionRevision la definición de revisión a guardar
     */
    setDefinicionRevision(state, definicionRevision: IDefinicionRevision) {
      state.definicionRevision = definicionRevision;
    },
  },
};
