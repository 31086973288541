import { Component, Inject } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { ISolucion } from '@/shared/model/solucion.model';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { EstadoSolucion } from '@/shared/model/enumerations/estado-solucion.model';

import SolucionService from '@/entities/solucion/solucion.service';
import AlertService from '@/shared/alert/alert.service';
import { mixins } from 'vue-class-component';
import { formatRelative } from 'date-fns';
import { es } from 'date-fns/locale';
import { Filtro } from '@/shared/model/filtro.model';
import resolveEstadoSolucionVariant from '@/shared/estados/estados-solucion.util';
import { TipoAcceso } from '@/shared/model/enumerations/tipo-acceso.model';

@Component({
  mixins: [Vue2Filters.mixin],
})
export default class Convocatoria extends mixins(JhiDataUtils) {
  @Inject('solucionService') private solucionService: () => SolucionService;
  @Inject('alertService') private alertService: () => AlertService;

  private removeId: string = null;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public links = {};
  public filtro = new Filtro();

  // public totalItems = 0;

  public solucions: ISolucion[] = [];
  //card
  public solucionsCards: Array<any> = [];
  //skeleton
  public loadingCards = false;
  //Infinite Loading
  public itemsPerPage = 20;
  public page = 1;
  public totalItems = 0;
  public queryCount: number = null;
  public infiniteId = +new Date();

  public isFetching = false;

  public mounted(): void {
    this.retrieveAllSolucions();
  }

  public clear(): void {
    this.page = 1;
    this.solucions = [];
    this.solucionsCards = [];
    this.retrieveAllSolucions();
  }

  public retrieveAllSolucions(): void {
    this.isFetching = true;
    this.filtro.estadoSolucion = EstadoSolucion.PUBLICADA;
    this.filtro.tiposDeAcceso = [TipoAcceso.PUBLICO];
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
      filter: this.filtro,
    };
    this.solucionService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res) {
            if (res?.data?.length > 0) {
              for (const solucion of res.data) {
                this.solucions.push(solucion);
                this.solucionsCards.push(this.creaRegistroCard(solucion));
              }
              if (res?.headers['link']) {
                this.links = this.parseLinks(res.headers['link']);
              }
            }

            this.totalItems = Number(res.headers['x-total-count']);
            this.queryCount = this.totalItems;
            if (<any>this.$refs.infiniteLoading) {
              (<any>this.$refs.infiniteLoading).stateChanger.loaded();
              if (this.page > this.links['last']) {
                (<any>this.$refs.infiniteLoading).stateChanger.complete();
              }
            }
          }
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public handleSyncList(): void {
    this.clear();
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllSolucions();
  }

  public changeOrder(propOrder): void {
    this.propOrder = propOrder;
    this.reverse = !this.reverse;
    this.transition();
  }

  public closeDialog(): void {
    (<any>this.$refs.removeEntity).hide();
  }

  private creaRegistroCard(val: ISolucion): any {
    let router = null;
    if (val.visible) {
      router = {
        edit: {
          to: { name: 'Solicitud', params: { solucionId: val.id } },
          nameBtn: 'Iniciar solicitud',
          icon: 'entrar',
        },
      };
    }
    return {
      title: val.titulo,
      description: `${val.descripcion ? val.descripcion : ''}`,
      footer: `Fecha de apertura: ${val.calendario.fechaInicio ? this.relative(val.calendario.fechaInicio) : ''}
      Fecha limite para recibir solicitudes: ${val.calendario.fechaFinSolicitud ? this.relative(val.calendario.fechaFinSolicitud) : ''}
      Fecha limite para evaluar: ${val.calendario.fechaFinRevision ? this.relative(val.calendario.fechaFinRevision) : ''}
      Fecha limite para reconsiderar: ${
        val.calendario.fechaFinReconsideracion ? this.relative(val.calendario.fechaFinReconsideracion) : ''
      }`,
      icon: 'icon-convocatoria',
      badge: [
        {
          badge: val.estado ? this.$t('archeApp.EstadoSolucion.' + val.estado) : '',
          variant: resolveEstadoSolucionVariant(val.estado),
        },
      ],
      router: router,
    };
  }

  public resetInfinite() {
    this.page = 1;
    this.infiniteId += 1;
    this.solucions = [];
    this.retrieveAllSolucions();
  }

  public loadMore($state): void {
    this.page++;
    this.retrieveAllSolucions();
  }

  relative(date) {
    return formatRelative(new Date(date), new Date(), { locale: es });
  }

  public showCardList(): boolean {
    return this.solucions && !this.isEmptyLoading();
  }

  public isEmptyLoading(): boolean {
    return this.isFetching && this.solucions.length === 0;
  }
}
