import { Component, Vue, Prop } from 'vue-property-decorator';
import { EstatusJob } from '@conacyt/historicopersonasms-client';

@Component
export default class JobStatusComponent extends Vue {
  @Prop({ required: false })
  value: EstatusJob;

  EstatusJob: any = EstatusJob;

  get jobStatus(): EstatusJob {
    return this.value;
  }

  get animation() {
    switch (this.jobStatus) {
      case EstatusJob.SCHEDULED:
      case EstatusJob.ENQUEUED:
      case EstatusJob.PROCESSING:
        return 'throb';
      default:
        return 'throbs';
    }
  }

  get variant() {
    switch (this.jobStatus) {
      case EstatusJob.SCHEDULED:
      case EstatusJob.ENQUEUED:
      case EstatusJob.PROCESSING:
        return 'warning';
      case EstatusJob.SUCCEEDED:
        return 'success';
      case EstatusJob.FAILED:
      case EstatusJob.DELETED:
        return 'danger';
      default:
        return 'success';
    }
  }

  get icon() {
    switch (this.jobStatus) {
      case EstatusJob.SCHEDULED:
      case EstatusJob.ENQUEUED:
      case EstatusJob.PROCESSING:
        return 'play-circle';
      case EstatusJob.SUCCEEDED:
        return 'check2-circle';
      case EstatusJob.FAILED:
      case EstatusJob.DELETED:
        return 'x-circle';
      default:
        return 'success';
    }
  }
}
