import { Component, Inject } from 'vue-property-decorator';
import { Formulario } from './historico-acreditaciones.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { required } from 'vuelidate/lib/validators';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import { mapGetters } from 'vuex';
import * as dateUtils from '@/shared/date/date-utils';

const validations: any = {
  form: {
    subSeccion: {
      nombre: { required },
    },
  },
};

@Component({
  computed: {
    ...mapGetters(['userInfo']),
  },
  validations,
})
export default class HistoricoAcreditacionesComponent extends ApeironComponent {
  @Inject('historicoPersonasApi') private historicoPersonasApi: () => HistoricoPersonasApi;

  public form = { subSeccion: new Formulario() };
  public acreditaciones = [];
  public cvu = '';
  public urlDocumento = 'https://miic.conacyt.mx/generador-api/getPdf?idDocumentoCMS=';

  created() {
    if (this.formModel?.subSeccion) {
      this.form.subSeccion = { ...this.form.subSeccion, ...JSON.parse(JSON.stringify(this.formModel.subSeccion)) };
    }
    this.cvu = this.$store.getters.userInfo.cvu;

    this.getHistoricoAcreditaciones();
  }

  public getHistoricoAcreditaciones(): void {
    this.historicoPersonasApi()
      .getExpedienteAcreditaciones(this.cvu)
      .then(res => {
        this.acreditaciones = this.formatDates(res.data ? res.data.acreditaciones : []);
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.alertService().showInfo(this, error.response.data.detail);
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.acreditaciones = [];
      });
  }

  private formatDates(acreditaciones: any[]): any[] {
    return acreditaciones.map(acreditacion => ({
      ...acreditacion,
      vigencia: {
        ...acreditacion.vigencia,
        fechaInicio: acreditacion.vigencia?.fechaInicio ? dateUtils.defaultFormat(acreditacion.vigencia.fechaInicio) : '',
        fechaFin: acreditacion.vigencia?.fechaFin
          ? dateUtils.defaultFormat(acreditacion.vigencia.fechaFin)
          : this.$t('apeironGwApp.historicoAcreditaciones.rows.indeterminado'),
      },
    }));
  }
}
