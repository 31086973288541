import { Component, Inject, Prop, Ref } from 'vue-property-decorator';

import { FiltroPrograma } from '@/shared/model/filtro-programa.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import ProgramaService from '@/shared/programa/programa.service';
import { Solicitud } from '@/shared/model/solicitud.model';
import { IPrograma, Programa } from '@/shared/model/programa.model';
import { BModal } from 'bootstrap-vue';
import { ProgramaIntegrado } from '@/shared/model/snp/programa-integrado.model';
import { required } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';

const validations = function () {
  return {
    programaIntegrado: {
      fechaInicio: { required },
    },
  };
};
@Component({
  validations,
})
export default class ProgramaIntegradoConComponent extends ApeironComponent {
  public filtro = new FiltroPrograma();
  public form = { programa: Programa, programasIntegrados: [] };
  public queryCount: number = null;
  public propOrder = 'id';
  public reverse = false;
  public isFetching = false;
  public hasProgramas = false;
  public programas: Array<IPrograma> = [];
  public currentPage = 1;
  public perPage = 10;
  public totalRows = 0;
  public perPageOptions = [10, 20, 50, 100, 150];
  public isSaving = false;
  public programasIntegrados: Array<ProgramaIntegrado> = [];
  public editProgramaIntegrado = false;
  public addProgramaIntegrado = false;
  public currentProgramaIngregado = new ProgramaIntegrado();
  public fechaInicio: Date = null;
  public fechaFin: Date = null;

  @Ref('addIntegrado')
  public readonly programaIntegradoModal: BModal;

  @Ref('confirmProgramaIntegradoRemoveModal')
  public readonly confirmProgramaIntegradoRemoveModal: BModal;

  public programasFields = [
    { key: 'clave', label: this.$t('apeironGwApp.programa.programaIntegrado.clave'), class: 'text-left font-weight-bold' },
    {
      key: 'nombre',
      label: this.$t('apeironGwApp.programa.programaIntegrado.nombre'),
      class: 'text-left',
    },
    {
      key: 'integrar',
      label: this.$t('apeironGwApp.programa.programaIntegrado.integrar'),
      class: 'text-left',
    },
  ];

  public programasIntegradosFields = [
    { key: 'clave', label: this.$t('apeironGwApp.programa.programaIntegrado.clave'), class: 'text-left font-weight-bold' },
    {
      key: 'nombre',
      label: this.$t('apeironGwApp.programa.programaIntegrado.nombre'),
      class: 'text-left',
    },
    {
      key: 'fechaInicio',
      label: this.$t('apeironGwApp.programa.programaIntegrado.fechaInicio'),
      class: 'text-left',
    },
    {
      key: 'fechaFin',
      label: this.$t('apeironGwApp.programa.programaIntegrado.fechaFin'),
      class: 'text-left',
    },
    {
      key: 'acciones',
      label: this.$t('entity.acciones'),
      class: 'text-left',
    },
  ];

  @Inject('programaService') public programaService: () => ProgramaService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  /**
   * Inicializa el componente con los programas integrados obteniendo la institución y la sede del postulante
   */
  mounted() {
    this.programasIntegrados = this.solicitud.programasIntegrados ? this.solicitud.programasIntegrados : [];
    this.solicitud.programa.instituciones.forEach(institucion => {
      institucion.sedes.forEach(sede => {
        if (sede.postulante) {
          this.filtro.claveInstitucion = institucion.claveInstitucion;
        }
      });
    });

    if (this.formModel?.programa) {
      this.form.programa = { ...this.form.programa, ...JSON.parse(JSON.stringify(this.formModel.programa)) };
    }
    this.retrieveProgramas();
  }

  /**
   * Obtiene los programas del filtro de búsqueda de forma paginada
   */
  private retrieveProgramas() {
    this.isFetching = true;
    this.programas = [];
    const paginationQuery = {
      page: this.currentPage - 1,
      size: this.perPage,
      sort: this.sort(),
      filter: this.filtro,
    };
    this.programaService()
      .retrieve(paginationQuery)
      .then(res => {
        if (res?.data?.length > 0) {
          for (const programa of res.data) {
            const programaObj = Programa.fromObject(programa);
            const integrado = this.programasIntegrados.find(programaIntegrado => programaIntegrado.clave === programaObj.clave);
            programaObj.integrado = integrado ? true : false;
            this.programas.push(programaObj);
          }
        }
        this.totalRows = Number(res.headers['x-total-count']);
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      })
      .finally(() => {
        this.isFetching = false;
      });
  }

  /**
   * Actualiza los programas de la página recibida
   * @param pageNumber página a actualizar
   */
  public handlePageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.retrieveProgramas();
  }

  /**
   * Actualiza los programas en base al nuevo tamaño de página
   */
  public handlePageSizeChange(): void {
    this.resetPagination();
    this.retrieveProgramas();
  }

  /**
   * Reinicia el número de página y el total de filas
   */
  private resetPagination(): void {
    this.currentPage = 1;
    this.totalRows = 0;
  }

  /**
   * Ordena los programas por la propiedad propOrder y de forma ascendente o descendente
   */
  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  /**
   *Muestra el modal para agregar un programa integrado
   * @param programa a integrar
   */
  public handleIntegrarPrograma(programa) {
    this.addProgramaIntegrado = true;
    this.currentProgramaIngregado = new ProgramaIntegrado();
    this.currentProgramaIngregado.clave = programa.clave;
    this.currentProgramaIngregado.nombre = programa.nombre;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.programaIntegradoModal.show();
  }

  /**
   * Muestra el modal para editar un programa integrado
   * @param programaIntegrado programa integrado a editar
   */
  public handleProgramaIntegradoEdit(programaIntegrado) {
    this.editProgramaIntegrado = true;
    this.currentProgramaIngregado = programaIntegrado;
    this.fechaInicio = dayjs(programaIntegrado.fechaInicio, 'DD/MM/YYYY').toDate();
    this.fechaFin = dayjs(programaIntegrado.fechaFin, 'DD/MM/YYYY').toDate();
    this.programaIntegradoModal.show();
  }

  /**
   * Muestra el modal para confirmar la eliminación de un programa integrado
   * @param programaIntegrado programa integrado a eliminar
   */
  public handleProgramaIntegradoDelete(programaIntegrado) {
    this.currentProgramaIngregado = programaIntegrado;
    this.confirmProgramaIntegradoRemoveModal.show();
  }

  /**
   * Cierra el modal de programa integrado
   */
  public handleCloseIntegrado(): void {
    this.programaIntegradoModal.hide();
  }

  /**
   * Agrega o edita un programa integrado a la lista de programas integrados
   */
  public handleSaveIntegrado(): void {
    this.isSaving = true;
    this.currentProgramaIngregado.fechaInicio = this.fechaInicio ? dayjs(this.fechaInicio).format('DD/MM/YYYY') : null;
    this.currentProgramaIngregado.fechaFin = this.fechaFin ? dayjs(this.fechaFin).format('DD/MM/YYYY') : null;
    if (this.addProgramaIntegrado) {
      this.programasIntegrados.push(this.currentProgramaIngregado);
      this.form.programasIntegrados = this.programasIntegrados;
    } else if (this.editProgramaIntegrado) {
      this.programasIntegrados = this.programasIntegrados.map(programa => {
        if (programa.clave === this.currentProgramaIngregado.clave) {
          return this.currentProgramaIngregado;
        }
        return programa;
      });
      this.form.programasIntegrados = this.programasIntegrados;
    }

    this.addProgramaIntegrado = false;
    this.editProgramaIntegrado = false;
    this.currentProgramaIngregado = new ProgramaIntegrado();
    this.fechaInicio = null;
    this.fechaFin = null;
    this.isSaving = false;
    this.programaIntegradoModal.hide();
    this.retrieveProgramas();
  }

  /**
   * Cierra el modal de confirmación de eliminación de programa integrado
   */
  public handleCancelConfirmation(): void {
    this.confirmProgramaIntegradoRemoveModal.hide();
  }

  /**
   * Elimina un programa integrado de la lista de programas integrados
   */
  public handleConfirmation(): void {
    this.programasIntegrados = this.programasIntegrados.filter(programa => programa.clave !== this.currentProgramaIngregado.clave);
    this.form.programasIntegrados = this.programasIntegrados;
    this.confirmProgramaIntegradoRemoveModal.hide();
    this.retrieveProgramas();
  }

  /**
   * Obtiene el estado de la validación del formulario
   * @returns true si el formulario es válido, false en caso contrario
   */
  get isFormValid() {
    const isValid = this.fechaInicio != null;
    this.$emit('valid', isValid);
    return isValid;
  }

  /**
   * Obtiene el estado de solo lectura del formulario
   * @returns true si el formulario es de solo lectura, false en caso contrario
   */
  get readOnly() {
    return !this.isEditable;
  }

  /**
   * Obtiene el estado de edición del formulario
   * @returns true si el formulario es editable, false en caso contrario
   */
  get isEditable() {
    return (
      this.permisos.estadoSolicitud === EstadoSolicitud.EN_CAPTURA || this.permisos.estadoSolicitud === EstadoSolicitud.EN_ACTUALIZACION
    );
  }
}
