import { Component, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import CargaMasivaUtils from '@/shared/data/carga-masiva-utils.service';
import { DataType } from '@/shared/model/enumerations/data-type.model';
import { Catalogo } from '@/components/movimientos/registro-movimiento/movimientos.catalog';
import { TipoLayout } from '@/shared/model/enumerations/tipo-layout.model';
import AlertService from '@/shared/alert/alert.service';

@Component
export default class ExcelComponent extends mixins(JhiDataUtils, CargaMasivaUtils) {
  @Inject('alertService') protected alertService: () => AlertService;
  @Prop({ required: true })
  public items: any[];

  @Prop({ required: true })
  public fields: any[];

  @Prop({ required: true })
  public institucionesIndex: any[];

  @Prop({ required: false })
  public institucionesCompletoIndex: any[];

  @Prop({ required: true })
  public instituciones: any[];

  @Prop({ required: false })
  public institucionesCompleto: any[];

  @Prop({ default: false })
  public busy: boolean;

  @Prop({ default: false })
  public uploading: boolean;

  @Prop({ default: TipoLayout.INSTITUCIONES })
  public tipoLayout: TipoLayout;

  DataType: any = DataType;

  public selectedCount = 0;

  public checkAll = false;

  public validating = false;

  situacionOpciones = null;
  isCheckingAll = false;
  tipoParticipacionOpciones = Catalogo.tipoParticipacionAsValueOptions();
  situacionesWithoutParticipacion = Catalogo.situacionesWithoutParticipacion();
  situacionesWithoutFechaFin = Catalogo.situacionesWithoutFechaFin();
  situacionesWithoutFechaFinProgramas = Catalogo.situacionesWithoutFechaFinProgramas();

  @Watch('tipoLayout')
  changeTipoLayout(tipoLayout) {
    if (tipoLayout) {
      this.situacionOpciones = Catalogo.situacionesValueOptions(tipoLayout);
    }
  }

  get isDisabled() {
    return this.isProcessing || this.selectedCount <= 0;
  }
  get showTable() {
    return this.items?.length > 0;
  }

  get cvuDuplicates(): boolean {
    const cvus = this.items.map(row => row.cvu);
    const tieneDuplicados = new Set(cvus).size !== cvus.length;

    return tieneDuplicados;
  }

  @Watch('cvuDuplicates')
  public mostrarMensajeDuplicados() {
    if (this.cvuDuplicates) {
      const duplicatedCVUs = [];
      const uniqueCVUs = new Set();

      this.items.forEach(row => {
        if (uniqueCVUs.has(row.cvu)) {
          duplicatedCVUs.push(row.cvu);
        } else {
          uniqueCVUs.add(row.cvu);
        }
      });
      const duplicatedCVUsMessage = `CVU duplicado(s): ${duplicatedCVUs.join(
        ', '
      )} en la plantilla, favor de atender y volver a subir la información`;
      this.alertService().showError(this, duplicatedCVUsMessage);
    }
  }

  public handleSave() {
    console.log('saving');
  }

  get isProcessing() {
    return this.validating || this.uploading;
  }

  @Emit('upload')
  public handleUpload() {
    this.validating = true;
    const errors = this.doLocalValidation();
    if (errors.length > 0) {
      errors.forEach(error => {
        this.onComplete(error);
      });
      this.checkAll = false;
      this.validating = false;
      return [];
    } else {
      this.validating = false;
      return this.items.filter(row => row.selected);
    }
  }

  @Emit('clear')
  public handleClear() {
    return;
  }

  public showTipoParticipacion(row) {
    if (row?.item?.situacion && this.situacionesWithoutParticipacion.includes(row.item.situacion)) {
      return false;
    }
    return true;
  }

  public showTipoParticipacionFechaFin(row) {
    if (row?.item?.situacion && this.situacionesWithoutFechaFin.includes(row.item.situacion)) {
      return false;
    }
    return true;
  }

  public handleInstitucionChange(row, selectedInstitucion) {
    const institucionFinded = this.instituciones.find(institucion => institucion.id === selectedInstitucion.id);
    this.institucionesIndex[row.index].dependencia = this.dependenciasAsOptions(institucionFinded.dependencia);
    this.institucionesIndex[row.index].dependenciaRaw = institucionFinded.dependencia;
    this.copyCommonProperties(row.item, institucionFinded);
    row.item.idTipoInstitucion = institucionFinded.tipoNivel.id;
    row.item.tipoInstitucion = institucionFinded.tipoNivel.descripcion;
  }
  public handleDependenciaChange(row, selectedDependencia) {
    if (this.institucionesIndex[row.index].dependenciaRaw) {
      const dependenciaFinded = this.institucionesIndex[row.index].dependenciaRaw.find(
        dependencia => dependencia.id == selectedDependencia.id
      );
      if (dependenciaFinded) {
        this.institucionesIndex[row.index].subdependencia = this.dependenciasAsOptions(dependenciaFinded.subDependencia);
        this.institucionesIndex[row.index].subdependenciaRaw = dependenciaFinded.subDependencia;
        this.copyCommonProperties(row.item, dependenciaFinded);
      }
    }
  }
  public handleSubdependenciaChange(row, selectedSubdependencia) {
    if (this.institucionesIndex[row.index].subdependenciaRaw) {
      const subdependenciaFinded = this.institucionesIndex[row.index].subdependenciaRaw.find(
        subdependencia => subdependencia.id == selectedSubdependencia.id
      );
      if (subdependenciaFinded) {
        this.institucionesIndex[row.index].departamento = this.dependenciasAsOptions(subdependenciaFinded.departamento);
        this.copyCommonProperties(row.item, subdependenciaFinded);
      }
    }
  }

  public handleDepartamentoChange(row, selectedDepartamento) {
    if (this.institucionesIndex[row.index].departamentoRaw) {
      const departamentoFinded = this.institucionesIndex[row.index].departamentoRaw.find(
        departamento => departamento.id == selectedDepartamento.id
      );
      if (departamentoFinded) {
        this.copyCommonProperties(row.item, departamentoFinded);
      }
    }
  }

  // Manejar cambio de instituciones de comisión
  public handleInstitucionComisionChange(row, selectedInstitucion) {
    const institucionFinded = this.institucionesCompleto.find(institucion => institucion.id === selectedInstitucion.id);
    this.institucionesCompletoIndex[row.index].dependencia = this.dependenciasAsOptions(institucionFinded.dependencia);
    this.institucionesCompletoIndex[row.index].dependenciaRaw = institucionFinded.dependencia;
    this.copyCommonProperties(row.item.comision, institucionFinded);
    row.item.comision.idTipoInstitucion = institucionFinded.tipoNivel.id;
    row.item.comision.tipoInstitucion = institucionFinded.tipoNivel.descripcion;
  }
  public handleDependenciaComisionChange(row, selectedDependencia) {
    if (this.institucionesCompletoIndex[row.index].dependenciaRaw) {
      const dependenciaFinded = this.institucionesCompletoIndex[row.index].dependenciaRaw.find(
        dependencia => dependencia.id == selectedDependencia.id
      );
      if (dependenciaFinded) {
        this.institucionesCompletoIndex[row.index].subdependencia = this.dependenciasAsOptions(dependenciaFinded.subDependencia);
        this.institucionesCompletoIndex[row.index].subdependenciaRaw = dependenciaFinded.subDependencia;
        this.copyCommonProperties(row.item.comision, dependenciaFinded);
      }
    }
  }
  public handleSubdependenciaComisionChange(row, selectedSubdependencia) {
    if (this.institucionesCompletoIndex[row.index].subdependenciaRaw) {
      const subdependenciaFinded = this.institucionesCompletoIndex[row.index].subdependenciaRaw.find(
        subdependencia => subdependencia.id == selectedSubdependencia.id
      );
      if (subdependenciaFinded) {
        this.institucionesCompletoIndex[row.index].departamento = this.dependenciasAsOptions(subdependenciaFinded.departamento);
        this.copyCommonProperties(row.item.comision, subdependenciaFinded);
      }
    }
  }

  public handleDepartamentoComisionChange(row, selectedDepartamento) {
    if (this.institucionesCompletoIndex[row.index].departamentoRaw) {
      const departamentoFinded = this.institucionesCompletoIndex[row.index].departamentoRaw.find(
        departamento => departamento.id == selectedDepartamento.id
      );
      if (departamentoFinded) {
        this.copyCommonProperties(row.item.comision, departamentoFinded);
      }
    }
  }

  public institucionesAsOptions() {
    return this.instituciones.map(institucion => {
      return {
        value: { id: institucion.id, nombre: institucion.nombre, encoded: this.encodeItem(institucion) },
        text: institucion.nombre,
        disabled: false,
      };
    });
  }

  public dependenciasAsOptions(dependencia) {
    return dependencia.map(dependencia => {
      return {
        value: { id: dependencia.id, nombre: dependencia.nombre, encoded: this.encodeItem(dependencia) },
        text: dependencia.nombre,
        disabled: false,
      };
    });
  }

  public onProcess(cvu) {
    const currentItem = this.items.find(item => item.cvu == cvu);
    currentItem.processInfo.isProcessing = true;
  }

  public onComplete(transitionResult) {
    const currentItem = this.items.find(item => item.cvu == transitionResult.cvu);
    currentItem.processInfo.isProcessing = false;
    currentItem.processInfo.isFinished = true;
    currentItem.processInfo.isSelectable = !transitionResult.success; // ya no es elegible para una segunda operación
    currentItem.processInfo.hasErrors = !transitionResult.success;
    currentItem.processInfo.errors = transitionResult.errors;
    currentItem.selected = false;
    this.selectedCount = this.selectedCount - 1;
  }

  public onFinish() {
    this.checkAll = false;
  }

  public handleSelect(check) {
    if (check) {
      this.selectedCount = this.selectedCount + 1;
    } else {
      this.selectedCount = this.selectedCount - 1;
    }
  }

  public bulkOperation = null;
  public currentValue = null;
  public institucionValue = null;
  public dependenciaValue = null;
  public subdependenciaValue = null;
  public departamentoValue = null;
  public institucionOptions = null;
  public dependenciaOptions = null;
  public subdependenciaOptions = null;
  public departamentoOptions = null;

  public handleOpenBulkOperationModal(operation) {
    this.bulkOperation = operation;
    this.currentValue = null;
    this.institucionValue = null;
    this.institucionOptions = this.institucionesAsOptions();
    this.dependenciaValue = null;
    this.dependenciaOptions = null;
    this.subdependenciaValue = null;
    this.subdependenciaOptions = null;
    this.departamentoValue = null;
    this.departamentoOptions = null;
    (this.$refs.bulkOperationModal as any).show();
  }

  public handleCancel(): void {
    this.hideBulkOperationModal();
  }

  public processingForm = false;
  get isBusy() {
    return this.busy || this.processingForm;
  }
  public handleConfirmation(): void {
    this.processingForm = true;
    this.hideBulkOperationModal();
    let index = 0;
    for (const item of this.items) {
      if (item.selected && this.bulkOperation === 'situacion') {
        item.situacion = this.currentValue;
      } else if (item.selected && this.bulkOperation === 'inicio') {
        item.inicio = this.currentValue;
      } else if (item.selected && this.bulkOperation === 'fin') {
        item.fin = this.currentValue;
      } else if (item.selected && this.bulkOperation === 'tipoParticipacion') {
        item.tipoParticipacion = this.currentValue;
      } else if (item.selected && this.bulkOperation === 'institucion') {
        this.institucionesIndex[index].dependencia = this.dependenciaOptions;
        this.institucionesIndex[index].dependenciaRaw = this.dependenciaRaw;
        this.institucionesIndex[index].subdependencia = this.subdependenciaOptions;
        this.institucionesIndex[index].subdependenciaRaw = this.subdependenciaRaw;
        this.institucionesIndex[index].departamento = this.departamentoOptions;
        this.institucionesIndex[index].departamentoRaw = this.departamentoRaw;
        item.institucion = this.institucionValue;
        item.dependencia = this.dependenciaValue;
        item.subdependencia = this.subdependenciaValue;
        item.departamento = this.departamentoValue;
        item.tipoNivel = this.additionalInfo.tipoNivel;
        item.pais = this.additionalInfo.pais;
        item.estado = this.additionalInfo.estado;
        item.idEstado = this.additionalInfo.idEstado;
      }
      item.selected = false;
      index = index + 1;
    }
    this.processingForm = false;
    this.checkAll = false;
    this.selectedCount = 0;
  }

  public hideBulkOperationModal() {
    (this.$refs.bulkOperationModal as any).hide();
  }

  public handleCheckAll(check) {
    this.isCheckingAll = true;
    let count = 0;
    for (const item of this.items) {
      if (item?.processInfo?.isSelectable) {
        item.selected = check;
        count = count + 1;
      }
    }
    this.selectedCount = check ? count : 0;
    this.isCheckingAll = false;
  }

  public dependenciaRaw = null;
  public subdependenciaRaw = null;
  public departamentoRaw = null;
  public additionalInfo = null;
  public handleCatalogoChange(nivel, value) {
    if (nivel === 'institucion') {
      const currentInstitucion = this.instituciones.find(institucion => institucion.id == value.id);
      this.dependenciaOptions = this.itemAsOptions(currentInstitucion.dependencia);
      this.dependenciaRaw = currentInstitucion.dependencia;
      this.additionalInfo = this.updateAdditionalInfo(currentInstitucion);
      this.dependenciaValue = null;
      this.subdependenciaValue = null;
      this.subdependenciaOptions = null;
      this.departamentoOptions = null;
    } else if (nivel === 'dependencia') {
      const currentDependencia = this.dependenciaRaw.find(dependencia => dependencia.id == value.id);
      this.subdependenciaOptions = this.itemAsOptions(currentDependencia.subDependencia);
      this.subdependenciaRaw = currentDependencia.subDependencia;
      this.additionalInfo = this.updateAdditionalInfo(currentDependencia);
      this.subdependenciaValue = null;
      this.departamentoValue = null;
      this.departamentoOptions = null;
    } else if (nivel === 'subdependencia') {
      const currentSubdependencia = this.subdependenciaRaw.find(subdependencia => subdependencia.id == value.id);
      this.departamentoOptions = this.itemAsOptions(currentSubdependencia.departamento);
      this.departamentoRaw = currentSubdependencia.departamento;
      this.additionalInfo = this.updateAdditionalInfo(currentSubdependencia);
      this.departamentoValue = null;
    } else if (nivel === 'departamento') {
      const currentSubdependencia = this.departamentoRaw.find(departamento => departamento.id == value.id);
      this.additionalInfo = this.updateAdditionalInfo(currentSubdependencia);
    }
  }

  public updateAdditionalInfo(organizacion) {
    return {
      tipoNivel: organizacion.tipoNivel.descripcion,
      idTipoNivel: organizacion.tipoNivel.id,
      pais: organizacion.pais,
      estado: organizacion.estado.descripcion,
      idEstado: organizacion.estado.id,
      id: organizacion.id,
    };
  }

  public currentRowDetail = null;
  public handleOpenErrorDetailModal(rowDetail) {
    (this.$refs.errorDetailModal as any).show();
    this.currentRowDetail = rowDetail;
  }

  public handleCloseErrorDetailModal() {
    this.currentRowDetail = null;
    (this.$refs.errorDetailModal as any).hide();
  }

  public doLocalValidation() {
    return this.items
      .filter(row => row.selected)
      .map(row => this.validateLocalRow(row))
      .filter(validationResult => !validationResult.success);
  }

  public validateLocalRow(row) {
    const result = this.defaulValidationResult(row.cvu);

    if (!this.isValidCvu(row)) {
      result.errors.push({ condicion: this.$t('excel.cvu').toString() });
    }
    if (!this.isValidSituacion(row)) {
      result.errors.push({ condicion: this.$t('excel.situacion').toString() });
    }
    if (!this.isValidfechaInicio(row)) {
      result.errors.push({ condicion: this.$t('excel.fechaInicio').toString() });
    }
    if (!this.isValidfechaFin(row)) {
      result.errors.push({ condicion: this.$t('excel.fechaFin').toString() });
    }
    if (!this.isValidInstitucion(row)) {
      result.errors.push({ condicion: this.$t('excel.institucion').toString() });
    }
    if (!this.isValidInstitucionComision(row)) {
      result.errors.push({ condicion: this.$t('excel.institucionComision').toString() });
    }
    if (!this.isValidfechaInicioyFin(row)) {
      result.errors.push({ condicion: this.$t('excel.fechaInicioYFin').toString() });
    }
    result.success = result.errors.length <= 0;
    return result;
  }

  public isValidCvu(row) {
    if (row?.cvu) {
      return row.cvu.trim().length > 0;
    }
    return false;
  }

  public isValidSituacion(row) {
    if (row?.situacion) {
      return row.situacion.trim().length > 0;
    }
    return false;
  }

  public isValidfechaInicio(row) {
    if (row?.situacion && this.situacionesWithoutParticipacion.includes(row.situacion)) {
      if (row?.inicio == null || !!row?.inicio?.length) {
        return true;
      }
    } else if (row?.inicio?.trim().length > 0) {
      return true;
    }
    return false;
  }

  public isValidfechaFin(row) {
    if (
      row?.situacion &&
      (this.situacionesWithoutFechaFin.includes(row.situacion) || this.situacionesWithoutFechaFinProgramas.includes(row.situacion))
    ) {
      if (row?.fin == null || !!row?.fin?.length) {
        return true;
      }
    } else if (row?.fin?.trim().length > 0) {
      return true;
    }
    return false;
  }

  public isValidfechaInicioyFin(row) {
    if (
      row?.situacion &&
      (this.situacionesWithoutFechaFin.includes(row.situacion) || this.situacionesWithoutFechaFinProgramas.includes(row.situacion))
    ) {
      if (row?.fin == null || !!row?.fin?.length) {
        return true;
      }
    } else if (row?.fin > row?.inicio) {
      return true;
    }

    return false;
  }

  public isValidInstitucion(row) {
    if (row?.institucion) {
      return row.institucion.nombre.trim().length > 0;
    }
    return false;
  }

  public isValidInstitucionComision(row) {
    if (this.tipoLayout !== TipoLayout.PROGRAMAS) {
      return true;
    }

    if (row?.comision?.institucion) {
      return row.comision.institucion.nombre.trim().length > 0;
    }
    return false;
  }

  public defaulValidationResult(cvu) {
    return { cvu: cvu, success: true, errors: [] };
  }
}
