import { InstitucionDto, IInstitucionDto } from '@/shared/model/institucion.model';
import { Dependencia, IDependencia } from '@/shared/model/dependencia.model';
import { SubDependencia, ISubDependencia } from '@/shared/model/sub-dependencia.model';
import { Departamento, IDepartamento } from '@/shared/model/departamento.model';

export interface IFiltroInstitucionAprobador {
  instituciones?: IInstitucionDto[];
  institucion?: IInstitucionDto;
  dependencia?: IDependencia;
  subDependencia?: ISubDependencia;
  departamento?: IDepartamento;
  cvuAprobador?: string;
}

export class FiltroInstitucionAprobador implements IFiltroInstitucionAprobador {
  constructor(
    public instituciones?: InstitucionDto[],
    public institucion?: IInstitucionDto,
    public dependencia?: IDependencia,
    public subDependencia?: ISubDependencia,
    public departamento?: IDepartamento,
    public cvuAprobador?: string
  ) {
    this.instituciones = this.instituciones ? this.instituciones : [];
    this.institucion = this.institucion ? this.institucion : new InstitucionDto();
    this.dependencia = this.dependencia ? this.dependencia : new Dependencia();
    this.subDependencia = this.subDependencia ? this.subDependencia : new SubDependencia();
    this.departamento = this.departamento ? this.departamento : new Departamento();
  }
}
