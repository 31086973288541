import Component, { mixins } from 'vue-class-component';
import { Prop, Inject, Emit, Ref } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';
import GrantService from '@/shared/security/grant.service';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import { TransicionContext } from '@/shared/model/proceso/transicion-context.model';
import SelectOptionService from '@/shared/form/select-option.service';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import * as accionUtil from '@/shared/acciones/acciones.util';
import resolveEstadoSolicitudVariant from '@/shared/estados/estados.solicitud.util';
import AsignacionOperadorComponent from '@/entities/becas/asignacion-operador.vue';
import { BModal } from 'bootstrap-vue';
import { Solicitud } from '@/shared/model/solicitud.model';

@Component({
  components: {
    asignacionOperador: AsignacionOperadorComponent,
  },
  computed: {
    login: {
      get() {
        return this.$store.getters.account?.login;
      },
    },
  },
})
export default class TableViewComponent extends mixins(PropertyCommons) {
  @Inject('selectOptionService') private selectOptionService: () => SelectOptionService;
  @Inject('grantService') private grantService: () => GrantService;

  @Prop({ required: true, type: Array })
  public items!: Array<any>;

  @Prop({ default: false })
  public busy: boolean;

  @Prop({ required: true })
  solucion: any;

  @Prop({ required: true })
  estadosDisponibles: any;

  @Prop({ default: 0 })
  totalItems: number;

  @Prop({ default: true })
  operacionMasiva: boolean;

  @Ref('bulkOperationModal2')
  public readonly bulkOperationModal2: BModal;

  public readonly login!: string;

  permisos: any = null;
  model: any = null;
  solicitud: Solicitud = null;

  currentEstadoSelected = null;
  currentActionSelected: TipoAccion = null;
  checkAll = false;

  public removeIndex = null;
  public selected: string[] = [];
  public allSelected: boolean;
  public currentRowDetail = null;

  private created() {
    this.$watch('items', this.itemsWatcher);
    this.allSelected = false;
  }

  get accesosBySolucionAndEstado() {
    return this.grantService().accesosBySolucionAndEstado(this.solucion, this.currentEstadoSelected);
  }

  get accionesByEstado() {
    return this.selectOptionService()
      .resolveActionableAcciones(this)
      .filter(accionOption => this.accesosBySolucionAndEstado.acciones.findIndex(accion => accion === accionOption.value) >= 0);
  }

  get countSelectedItems() {
    if (this.items?.length) {
      return this.items.filter(item => item.selected === true).length;
    }
    return 0;
  }

  get accionesMasivas() {
    const rolesUser: string[] = [];
    this.solucion.autoridades.forEach(autoridad => {
      if (autoridad.usuarioId == this.login) {
        autoridad.roles.forEach(rol => {
          rolesUser.push(rol);
        });
      }
    });

    return this.solucion.vistaResumen.accionesMasivas.filter(accion => accion.roles.some(rol => rolesUser.includes(rol)));
  }

  get estadosBySolucion() {
    if (this.solucion?.id) {
      return this.grantService()
        .resolveEstadosBySolucion(this.solucion)
        .filter(estado => this.estadosDisponibles[estado.nombre])
        .map(estado => {
          return { value: estado.nombre, text: this.$t('archeApp.EstadoSolicitud.' + estado.nombre), disabled: false };
        });
    }
    return [];
  }

  public resolveAccionVariant(accion) {
    return accionUtil.resolveVariant(accion);
  }

  public resolveActionIcon(accion) {
    return accionUtil.resolveIcon(accion);
  }

  public handleCheckboxSelected(item) {
    if (this.countSelectedItems === 0) {
      this.currentEstadoSelected = null;
    } else {
      this.currentEstadoSelected = item.estado;
    }
  }

  public isCheckboxDisabled(item) {
    if (this.countSelectedItems === 0) {
      return false;
    }
    if (this.currentEstadoSelected) {
      return this.currentEstadoSelected !== item.estado;
    } else {
      return false;
    }
  }

  public toggleAll(): void {
    this.selected = [];

    if (this.allSelected) {
      this.items.forEach(value => {
        let bandera = false;

        value.badge.forEach(estatus => {
          bandera = 'Enviada' === estatus.badge ? true : bandera;
        });

        bandera ? this.selected.push(value.checkout[0].value) : null;
      });
    }

    this.$emit('checkSelected', this.selected);
  }

  private itemsWatcher(newValue: Array<any>) {
    this.selected.splice(0, this.selected.length);
  }

  public prepareRemove(instance: any): void {
    this.removeIndex = instance;
    this.$bvModal.show(this.id + '-removeEntityModal');
  }

  public remove(): void {
    this.$emit('remove', this.removeIndex);
    this.$bvModal.hide(this.id + '-removeEntityModal');
  }

  public edit(instance: any): void {
    this.$emit('edit', instance);
  }

  private isTipoAcreditacion(): boolean {
    return this.solucion.tipo == TipoSolucion.ACREDITACIONES || this.solucion.tipo == TipoSolucion.ACREDITACIONES_SIN_INST;
  }

  public isOperationInProcess = false;
  public handleOpenBulkOperationModal(tipoAccionSelected: TipoAccion) {
    if (!this.isOperationInProcess) {
      this.currentActionSelected = tipoAccionSelected;
      (this.$refs.bulkOperationModal as any).show();
    }
  }

  public handleCloseBulkOperationModal() {
    this.currentActionSelected = null;
    (this.$refs.bulkOperationModal as any).hide();
  }
  public handleConfirmBulkOperation() {
    this.emitSelectedItems();
    (this.$refs.bulkOperationModal as any).hide();
  }

  get isOperacionMasiva() {
    return this.operacionMasiva;
  }

  public handleOpenBulkOperationModal2() {
    if (!this.isOperationInProcess) {
      this.bulkOperationModal2.show();
    }
  }

  public handleCloseBulkOperationModal2() {
    this.bulkOperationModal2.hide();
  }

  public handleConfirmBulkOperation2() {
    const itemsSelect = this.items.filter(item => item.selected === true);
    (this.$refs.asignacionOperador as any).guardaOperadores(itemsSelect).then(resp => {
      (this.$parent as any).handleAplicarFiltro();
    });
    this.bulkOperationModal2.hide();
  }

  @Emit('selected')
  public emitSelectedItems() {
    const currentEstado = this.grantService().currentEstado(this.solucion, { estado: this.currentEstadoSelected });
    const currentTransicion = currentEstado.transiciones.find(transicion => transicion.accion === this.currentActionSelected);
    return this.items
      .filter(item => item.selected === true)
      .map(item => {
        const context = new TransicionContext();
        context.transicion = currentTransicion;
        context.mensaje = 'Operación masiva';
        context.solicitudId = item.id;
        context.solucionId = this.solucion.id;
        return context;
      });
  }

  public onProcess(solicitudId) {
    const currentItem = this.items.find(item => item.id == solicitudId);
    currentItem.processInfo.isProcessing = true;
    this.isOperationInProcess = true;
  }

  public onComplete(transitionResult) {
    const currentItem = this.items.find(item => item.id == transitionResult.solicitudId);
    currentItem.processInfo.isProcessing = false;
    currentItem.processInfo.isFinished = true;
    currentItem.processInfo.isSelectable = false; // ya no es elegible para una segunda operación
    currentItem.selected = false;
    currentItem.processInfo.hasErrors = !transitionResult.success;
    currentItem.processInfo.errors = transitionResult.errors;
    currentItem.processInfo.destino = transitionResult.destino;
    if (transitionResult.success) {
      currentItem.badge = [
        {
          badge: this.$t('archeApp.EstadoSolicitud.' + transitionResult.destino),
          variant: resolveEstadoSolicitudVariant(transitionResult.destino),
        },
      ];
      currentItem.estado = transitionResult.destino;
    }
    this.isOperationInProcess = false;
  }

  public handleCloseErrorDetailModal() {
    this.currentRowDetail = null;
    (this.$refs.errorDetailModal as any).hide();
  }
  public handleOpenErrorDetailModal(rowDetail) {
    (this.$refs.errorDetailModal as any).show();
    this.currentRowDetail = rowDetail;
  }

  public handleUnCheckAll() {
    this.currentEstadoSelected = null;
    this.currentActionSelected = null;
    this.checkAll = false;
    this.items.forEach(item => {
      item.selected = false;
    });
  }

  public handleCheckAllByEstado() {
    this.items
      .filter(item => item.processInfo.isSelectable)
      .forEach(item => {
        item.selected = item.estado === this.currentEstadoSelected;
      });
    this.checkAll = true;
    (this.$refs.dropdownSelectAllByState as any).hide(true);
  }

  public getParentComponent() {
    return this;
  }
}
