import { Component, Prop, Vue } from 'vue-property-decorator';
import { Transicion } from '@/shared/model/proceso/transicion.model';
import { Estado } from '@/shared/model/proceso/estado.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import { Proceso } from '@/shared/model/proceso/proceso.model';
import * as accionUtil from '@/shared/acciones/acciones.util';

import { TransicionContext } from '@/shared/model/proceso/transicion-context.model';

@Component
export default class AccionesComponent extends Vue {
  @Prop({ required: true })
  proceso: Proceso;

  @Prop({ required: true })
  estado: Estado;

  @Prop({ required: false })
  accesos: any;

  @Prop({ default: false })
  status: boolean;

  @Prop({ default: false })
  mock: boolean;

  public addComentario = false;

  public selection: Transicion = new Transicion();

  public question = '';

  public confirmTransition(mensaje: string) {
    const context = new TransicionContext();
    context.transicion = this.selection;
    context.mensaje = mensaje;
    this.$emit('transition', context);
  }

  public toggle(index) {
    (this.$root as any).$emit('bv::toggle::collapse', 'accordion-transiciones-' + index);
  }

  public resolveIcon(accion: TipoAccion): string {
    return accionUtil.resolveIcon(accion);
  }

  public resolveVariant(accion: TipoAccion): string {
    return accionUtil.resolveVariant(accion);
  }

  public prepareTransicion(transicion: Transicion) {
    this.selection = transicion;
    this.question = this.selection.mensaje;
    this.addComentario = this.selection.agregarComentario;
  }

  get check() {
    return this.selection.confirmar;
  }

  get message() {
    if (this.mock || !this.estado?.transiciones) {
      return 'Acciones por estado';
    }

    if (!this.estado?.transiciones) {
      return 'No hay transiciones registradas';
    }
    let message = '';
    this.estado.transiciones.forEach(transicion => {
      message =
        message +
        'Selecciona el botón <strong>' +
        this.getAccion(transicion.accion) +
        '</strong> para ' +
        this.getAccion(transicion.accion) +
        ' la solicitud, ';
    });
    return message;
  }

  public getAccion(accion): string {
    return this.$t('apeironGwApp.TipoAccion.' + accion) as string;
  }
  get transiciones() {
    if (this.mock || !this.estado) {
      return [];
    }
    return this.estado.transiciones.filter(transicion => this.accesos.acciones.findIndex(accion => accion === transicion.accion) > -1);
  }

  get avisos() {
    const mensajes = [];

    if (this.mock || !this.estado) {
      return mensajes;
    }

    this.estado.permisos.forEach(permiso => {
      if (this.accesos[permiso.rol]) {
        mensajes.push(permiso.aviso);
      }
    });

    return mensajes;
  }
}
