import { Aviso } from '@/shared/model/proceso/aviso.model';
import { TipoAviso } from '@/shared/model/enumerations/tipo-aviso.model';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AvisoEditableComponent extends Vue {
  @Prop({ default: true })
  public value: Aviso;

  public preview = false;

  public tiposAviso = [];

  created(): void {
    this.tiposAviso = this.resolveTipoAvisos();
  }

  get aviso(): Aviso {
    return this.value;
  }

  set aviso(aviso: Aviso) {
    this.$emit('input', aviso);
  }

  public resolveTipoAvisos() {
    const tiposAviso = [];
    const tipoKeys = Object.keys(TipoAviso);

    tipoKeys.forEach(key => {
      tiposAviso.push({ value: key, text: this.$t('apeironGwApp.TipoAviso.' + key), disabled: false });
    });
    return tiposAviso;
  }
}
