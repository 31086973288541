import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { Persona } from '@/shared/model/persona/persona.model';

export interface IMenuInstitucionClaustroSnp {
  value?: InstitucionClaustroSnp;
  text?: string;
}

export class MenuInstitucionClaustroSnp implements IMenuInstitucionClaustroSnp {
  constructor(public value?: InstitucionClaustroSnp, public text?: string) {}
}

export interface IMenuSedeClaustroSnp {
  value?: SedeClaustroSnp;
  text?: string;
}

export class MenuSedeClaustroSnp implements IMenuSedeClaustroSnp {
  constructor(public value?: SedeClaustroSnp, public text?: string) {}
}
export interface IMenuTiempoDedicacionClaustroSnp {
  value?: TiempoDedicacionClaustroSnp;
  text?: string;
}

export class MenuTiempoDedicacionClaustro implements IMenuTiempoDedicacionClaustroSnp {
  constructor(public value?: TiempoDedicacionClaustroSnp, public text?: string) {}
}

export interface ITiempoDedicacionClaustroSnp {
  id?: number;
  nombre?: string;
}

export class TiempoDedicacionClaustroSnp implements ITiempoDedicacionClaustroSnp {
  constructor(public id?: number, public nombre?: string) {}
}

export interface ISedeClaustroSnp {
  claveSede?: string;
  claveDependencia?: string;
  nombreDependencia?: string;
  sede?: string;
}

export class SedeClaustroSnp implements ISedeClaustroSnp {
  constructor(public claveSede?: string, public claveDependencia?: string, public nombreDependencia?: string, public sede?: string) {}
}

export interface IInstitucionClaustroSnp {
  claveInstitucion?: string;
  nombreInstitucion?: string;
}

export class InstitucionClaustroSnp implements IInstitucionClaustroSnp {
  constructor(public claveInstitucion?: string, public nombreInstitucion?: string) {}
}
export interface IProfesorSnp {
  tiempoDedicacion?: TiempoDedicacionClaustroSnp;
  institucion?: InstitucionClaustroSnp;
  sede?: SedeClaustroSnp;
  areaConocimientoId?: number;
  areaConocimientoNombre?: string;
  vigenciaEstatus?: string;
  nivelSniClave?: string;
  nivelSniNombre?: string;
  vigenciaTipo?: string;
  vigenciaFechaInicio?: Date;
  vigenciaFechaFin?: Date;
  claustroFechaIngreso?: Date;
  claustroFechaBaja?: Date;
}

export class ProfesorSnp implements IProfesorSnp {
  constructor(
    public tiempoDedicacion?: TiempoDedicacionClaustroSnp,
    public institucion?: InstitucionClaustroSnp,
    public sede?: SedeClaustroSnp,
    public areaConocimientoId?: number,
    public areaConocimientoNombre?: string,
    public vigenciaEstatus?: string,
    public nivelSniClave?: string,
    public nivelSniNombre?: string,
    public vigenciaTipo?: string,
    public vigenciaFechaInicio?: Date,
    public vigenciaFechaFin?: Date,
    public claustroFechaIngreso?: Date,
    public claustroFechaBaja?: Date
  ) {}
}

export interface IParticipacionSnp {
  id?: string;
  solicitudId?: string;
  contenedorId?: string;
  dependenciaId?: string;
  participante?: Persona;
  rol?: RolAutoridad;
  profesorSnp?: ProfesorSnp;
}

export class ParticipacionSnp implements IParticipacionSnp {
  constructor(
    public id?: string,
    public solicitudId?: string,
    public contenedorId?: string,
    public dependenciaId?: string,
    public participante?: Persona,
    public rol?: RolAutoridad,
    public profesorSnp?: ProfesorSnp
  ) {
    if (!profesorSnp) {
      this.profesorSnp = new ProfesorSnp();
    }
    if (!participante) {
      this.participante = new Persona();
    }
  }

  static fromObject(item: IParticipacionSnp): ParticipacionSnp {
    const participante = new ParticipacionSnp();

    for (const [key, value] of Object.entries(item)) {
      participante[key] = value;
    }

    return participante;
  }
}
