var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "h4",
    [
      _c(
        "b-badge",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true },
            },
          ],
          staticClass: "text-center",
          attrs: {
            title:
              "Tu solicitud se encuentra " +
              _vm.$t("archeApp.EstadoSolicitud." + _vm.estado),
            pill: "",
            variant: _vm.variant,
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("archeApp.EstadoSolicitud." + _vm.estado))
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }