import { Component } from 'vue-property-decorator';
import { StatusCodes } from 'http-status-codes';
import JobStatusComponent from '@/components/pago/job-status/job-status.vue';
import { mixins } from 'vue-class-component';
import PadronEncabezadoComponent from '@/components/pago/padron-encabezado.component';
import { PadronEncabezado } from '@conacyt/historicopersonasms-client';

@Component({
  components: {
    'job-status': JobStatusComponent,
  },
})
export default class PagoReglasComponent extends mixins(PadronEncabezadoComponent) {
  public reglas = [];

  exludedProperties = ['id', 'solicitante', 'ejecucionAceptada', 'fechaRegistro', 'countInvestigadores', 'countAyudantes'];

  public dragging = false;

  public retriveRules() {
    this.isSearching = true;
    this.historicoPersonasApi()
      .getAllReglas()
      .then(result => {
        if (result) {
          this.isSearching = false;
          this.hasErrors = false;
          this.reglas = result.data;
        }
      })
      .catch(error => {
        this.isSearching = false;
        if (error.response.data.status !== StatusCodes.NOT_FOUND) {
          this.hasErrors = true;
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  public defaultPadronEncabezado(reglas): any {
    return {
      id: null,
      reglasInvestigador: null,
      reglasAyudantia: null,
      fechaRegistro: null,
      countInvestigadores: null,
      countAyudantes: null,
      jobId: null,
      estatusJob: null,
      reglas: reglas,
      ejecucionAceptada: false,
      solicitante: this.getSolicitante(),
    };
  }

  public addPadronEncabezado() {
    this.lastPadronEncabezado.ejecucionAceptada = false;
    this.lastPadronEncabezado.solicitante = this.getSolicitante();
    this.isExecuting = true;
    this.historicoPersonasApi()
      .addPadronEncabezado(this.defaultPadronEncabezado(this.reglas))
      .then(result => {
        if (result) {
          this.isExecuting = false;
          this.hasErrors = false;
          this.retrivePadronEncabezado();
        }
      })
      .catch(error => {
        this.isExecuting = false;
        if (error.response.data.status !== StatusCodes.NOT_FOUND) {
          this.hasErrors = true;
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  public handleEjecutar() {
    this.addPadronEncabezado();
  }

  public isDisabled() {
    return this.isExecuting || this.isJobInProcess;
  }

  get showRules(): boolean {
    return !this.isJobInProcess && this.lastPadronEncabezado?.reglas?.length > 0;
  }

  public doAfterRetrivePadronEncabezado(lastPadronEncabezado: PadronEncabezado) {
    if (!this.isJobInProcess) {
      this.retriveRules();
    }
  }
}
