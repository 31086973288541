import * as dateUtils from '@/shared/date/date-utils';

/**
 * Clase con las principales constantes utilizadas en el Padrón
 */

function formatBol(value) {
  if (!value) {
    return false;
  }
  return value;
}

export class Constants {
  static FIELDS = [
    { key: 'cambios', label: 'Cambios anteriores' },
    { key: 'tipoPago', label: 'Tipo Persona', sortable: true },
    { key: 'cvu', label: 'CVU', sortable: true },
    { key: 'persona.nombre', label: 'Nombre', sortable: true },
    { key: 'persona.apellidoPaterno', label: 'Primer apellido', sortable: true },
    { key: 'persona.apellidoMaterno', label: 'Segundo apellido', sortable: true },
    {
      key: 'ayudantia.vigencia.fechaInicio',
      label: 'Fecha inicio ayudantía',
      sortable: true,
      formatter: value => dateUtils.formatUtc(value),
    },
    { key: 'ayudantia.vigencia.fechaFin', label: 'Fecha fin ayudantía', sortable: true, formatter: value => dateUtils.formatUtc(value) },
    { key: 'ayudantia.umas', label: 'UMAs ayudante', sortable: true },
    { key: 'ayudantia.estadoSolicitud', label: 'Estatus ayudantía', sortable: true },
    { key: 'vigenciaSni.activo', label: 'Vigencia activa', sortable: true, formatter: value => formatBol(value) },
    { key: 'vigenciaSni.nivelSni.descripcion', label: 'Nivel SNI', sortable: true },
    { key: 'vigenciaSni.tipo.descripcion', label: 'Tipo vigencia', sortable: true },
    { key: 'vigenciaSni.vigencia.fechaInicio', label: 'Fecha inicio', sortable: true, formatter: value => dateUtils.formatUtc(value) },
    { key: 'vigenciaSni.vigencia.fechaFin', label: 'Fecha fin', sortable: true, formatter: value => dateUtils.formatUtc(value) },
    { key: 'vigenciaSni.urlConvenio', label: 'URL Convenio', sortable: true },
    { key: 'acreditacion.activoVigente', label: 'Acreditación activo vigente', sortable: true, formatter: value => formatBol(value) },
    { key: 'acreditacion.remunerado', label: 'Acreditación remunerable', sortable: true, formatter: value => formatBol(value) },
    { key: 'acreditacion.institucion.descripcion', label: 'Institución', sortable: true },
    { key: 'acreditacion.institucion.dependenciaDesc', label: 'Dependencia', sortable: true },
    { key: 'acreditacion.institucion.subDependenciaDesc', label: 'Sub-Dependencia', sortable: true },
    { key: 'acreditacion.institucion.departamentoDesc', label: 'Departamento', sortable: true },
    {
      key: 'acreditacion.vigencia.fechaInicio',
      label: 'Fecha de inicio acreditación',
      sortable: true,
      formatter: value => dateUtils.formatUtc(value),
    },
    {
      key: 'acreditacion.vigencia.fechaFin',
      label: 'Fecha de fin acreditación',
      sortable: true,
      formatter: value => dateUtils.formatUtc(value),
    },
    { key: 'acreditacion.estadoSolicitud', label: 'Estado solicitud', sortable: true },
    { key: 'acreditacion.documento.idContentManagment', label: 'Documento', sortable: true },
  ];
}
