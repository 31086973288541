import { Component, Prop } from 'vue-property-decorator';
import { PersonaFisica } from '@conacyt/buscador-cvu';
import { required } from 'vuelidate/lib/validators';
import { Solicitante, Solicitud } from '@/shared/model/solicitud.model';

import ApeironComponent from '@/components/commons/apeiron-component.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';

const validations: any = {
  form: {
    investigador: {
      cvu: { required },
    },
  },
};

@Component({
  validations,
})
export default class RegistroInvestigadorComponent extends ApeironComponent {
  @Prop({ required: false })
  public solicitud: Solicitud;

  @Prop({ required: false })
  public solucion: Solucion;

  public form = { investigador: new PersonaFisica() };

  public indOpen = false;
  readOnly = false;

  get investigador(): Solicitante {
    if (this.isPublico()) {
      return this.solicitud.solicitante;
    } else {
      return this.solicitud.getInvestigador();
    }
  }

  mounted() {
    this.form.investigador = this.investigador;
    this.retriveHistoricoPersona(this.investigador.cvu);
    this.indOpen = this.solicitud.estado != EstadoSolicitud.EN_CAPTURA;
  }
  handlePersonaUpdate(persona) {
    this.form.investigador = persona;
  }

  handleAddInvestigador(persona) {
    this.form.investigador = persona;
    this.retriveHistoricoPersona(persona.cvu);
  }

  retriveHistoricoPersona(cvu: string) {
    (this.$refs['registro-investigador-id'] as any).retriveExpedientePersona(cvu);
  }

  /**
   * Valida si la solución es publica o privada
   * @returns boolean
   */
  public isPublico(): boolean {
    return this.solucion?.params?.isVigencia;
  }
}
