var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.persona.nombre"),
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.persona.nombre +
                      " " +
                      _vm.persona.apellidoPaterno +
                      " " +
                      _vm.persona.apellidoMaterno
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.persona.curp"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.persona.curp))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.persona.rfc"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.persona.rfc))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.persona.genero"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.persona.genero))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.persona.nacionalidad"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.persona.nacionalidad))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }),
      _vm._v(" "),
      _vm.persona.domicilios && _vm.showDireccion
        ? _c(
            "b-form-group",
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  borderless: "",
                  items: _vm.persona.domicilios,
                  "head-variant": "primary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(direccion)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-1 text-center" }, [
                            _vm._v(
                              _vm._s(_vm.$t("apeironGwApp.persona.direccion"))
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "head(tipo)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-1 text-center" }, [
                            _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.tipo"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(direccion)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "p-2 text-justify" }, [
                            _vm._v(_vm._s(data.item.direccion)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(tipo)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "p-2 text-center" }, [
                            _vm._v(_vm._s(data.item.tipo)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2703684140
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }