export interface ITipoAcreditacion {
  isTipoAcreditacion?: boolean;
  isTipoAcreditacionSinInst?: boolean;
  isTipoAcreditacionConInst?: boolean;
}

export class TipoAcreditacion implements ITipoAcreditacion {
  constructor(public isTipoAcreditacion?: boolean, public isTipoAcreditacionSinInst?: boolean, public isTipoAcreditacionConInst?: boolean) {
    this.isTipoAcreditacion = this.isTipoAcreditacion ? this.isTipoAcreditacion : false;
    this.isTipoAcreditacionSinInst = this.isTipoAcreditacionSinInst ? this.isTipoAcreditacionSinInst : false;
    this.isTipoAcreditacionConInst = this.isTipoAcreditacionConInst ? this.isTipoAcreditacionConInst : false;
  }
}
