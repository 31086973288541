var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.items && _vm.items.length > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "font-weight-bolder h4" }, [
                _vm._v(_vm._s(_vm.solucion.titulo)),
              ]),
              _vm._v(" "),
              _vm.solucion.descripcion
                ? _c("div", {
                    staticClass: "text-muted",
                    domProps: { innerHTML: _vm._s(_vm.solucion.descripcion) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.countSelectedItems > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "sticky-top sticky-offset-buttons d-inline-block mb-2 mt-2",
                    },
                    [
                      _vm.accionesByEstado?.length > 0
                        ? _c(
                            "b-button-group",
                            { staticClass: "bg-white" },
                            _vm._l(
                              _vm.accionesByEstado,
                              function (accion, index) {
                                return _c(
                                  "b-button",
                                  {
                                    key: index,
                                    staticClass:
                                      "shadow d-inline-block border-0 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: _vm.resolveAccionVariant(
                                        accion.value
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleOpenBulkOperationModal(
                                          accion.value
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: _vm.resolveActionIcon(
                                          accion.value
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(accion.text) +
                                        "\n          "
                                    ),
                                    _c("small", [
                                      _c("strong", [
                                        _vm._v(
                                          "( " +
                                            _vm._s(
                                              _vm.countSelectedItems.toString()
                                            ) +
                                            " )"
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _c(
                            "b-alert",
                            { attrs: { show: true, variant: "warning" } },
                            [
                              _c("p", {
                                staticClass: "text-center text-center",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "accesos.solicitudes.acciones.notAllowed"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                      _vm._v(" "),
                      _vm.accionesMasivas?.length > 0
                        ? _c(
                            "b-button-group",
                            { staticClass: "bg-white" },
                            _vm._l(
                              _vm.accionesMasivas,
                              function (accion, index) {
                                return _c(
                                  "b-button",
                                  {
                                    key: index,
                                    staticClass:
                                      "shadow d-inline-block border-0 mr-1",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleOpenBulkOperationModal2()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(accion.nombre) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-table-simple",
                { attrs: { small: "", "caption-top": "" } },
                [
                  _c(
                    "b-thead",
                    { attrs: { "head-variant": "primary" } },
                    [
                      _c(
                        "b-tr",
                        {
                          staticClass:
                            "text-center sticky-top sticky-offset-header",
                        },
                        [
                          _c(
                            "b-th",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "dropdownSelectAllByState",
                                  attrs: {
                                    "toggle-class": "border-0",
                                    dropright: "",
                                    variant: "none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _vm.checkAll
                                              ? _c("b-icon", {
                                                  attrs: {
                                                    icon: "check-square",
                                                    variant: "light",
                                                    "font-scale": "1",
                                                  },
                                                })
                                              : _c("b-icon", {
                                                  attrs: {
                                                    icon: "square",
                                                    variant: "light",
                                                    "font-scale": "1",
                                                  },
                                                }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1716147311
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-form",
                                    {
                                      staticClass: "dropdown-menu-custom",
                                      staticStyle: { width: "250px" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "componente-select-estado",
                                            size: "sm",
                                            label: _vm.$t(
                                              "apeironGwApp.tableView.selectEstado"
                                            ),
                                            "label-for": "select-estado",
                                          },
                                        },
                                        [
                                          _c("b-form-radio-group", {
                                            staticClass: "mb-3",
                                            attrs: {
                                              options: _vm.estadosBySolucion,
                                              stacked: "",
                                            },
                                            on: {
                                              change:
                                                _vm.handleCheckAllByEstado,
                                            },
                                            model: {
                                              value: _vm.currentEstadoSelected,
                                              callback: function ($$v) {
                                                _vm.currentEstadoSelected = $$v
                                              },
                                              expression:
                                                "currentEstadoSelected",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("b-dropdown-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _vm.currentEstadoSelected
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "outline-danger",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.handleUnCheckAll,
                                                  },
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "trash" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("apeironGwApp.tableView.solicitante")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.solucion.vistaResumen.columnas,
                            function (column) {
                              return _c("b-th", { key: column.id }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.nombre) +
                                    "\n          "
                                ),
                              ])
                            }
                          ),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(_vm.$t("apeironGwApp.tableView.estado"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(_vm.$t("apeironGwApp.tableView.acciones"))
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    _vm._l(_vm.items, function (item) {
                      return _c(
                        "b-tr",
                        { key: item.id, staticClass: "text-center" },
                        [
                          _c(
                            "b-td",
                            { staticClass: "text-center" },
                            [
                              item.processInfo.isProcessing
                                ? _c("b-icon", {
                                    attrs: {
                                      icon: "play-circle",
                                      variant: "warning",
                                      animation: "throb",
                                      "font-scale": "1.5",
                                    },
                                  })
                                : item.processInfo.hasErrors
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "shadow d-inline-block border-0 mr-1",
                                      attrs: { size: "sm", variant: "blank" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleOpenErrorDetailModal(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "exclamation-circle",
                                          variant: "warning",
                                          "font-scale": "1.5",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleOpenErrorDetailModal(
                                              item
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : item.processInfo.isFinished
                                ? _c("b-icon", {
                                    attrs: {
                                      icon: "check2-circle",
                                      variant: "success",
                                      "font-scale": "1.5",
                                    },
                                  })
                                : _c("b-form-checkbox", {
                                    attrs: {
                                      size: "lg",
                                      disabled: _vm.isCheckboxDisabled(item),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleCheckboxSelected(item)
                                      },
                                    },
                                    model: {
                                      value: item.selected,
                                      callback: function ($$v) {
                                        _vm.$set(item, "selected", $$v)
                                      },
                                      expression: "item.selected",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.solicitante) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(item.columnas, function (desc, index) {
                            return _c(
                              "b-td",
                              { key: index },
                              [
                                desc.hasAccess
                                  ? [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            desc.show ? desc.description : ""
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  : [
                                      _c("b-icon", {
                                        attrs: { icon: "three-dots" },
                                      }),
                                    ],
                              ],
                              2
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            _vm._l(item.badge, function (b) {
                              return _c(
                                "span",
                                { key: b.id, staticClass: "rt-1" },
                                [
                                  b.badge
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: b.variant
                                              ? b.variant
                                              : "light",
                                          },
                                        },
                                        [_vm._v(_vm._s(b.badge) + " ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("b-td", [
                            _c(
                              "div",
                              { staticClass: "btn-group float-right" },
                              [
                                item.router
                                  ? _c("router-link", {
                                      attrs: {
                                        to: item.router.edit.to,
                                        custom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ navigate }) {
                                              return [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                    },
                                                    on: { click: navigate },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-md-inline",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.router.edit
                                                              .nameBtn
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      class:
                                                        "icon-" +
                                                        item.router.edit.icon,
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._l(
                                      item.buttons,
                                      function (button, index) {
                                        return _c("router-link", {
                                          key: index,
                                          staticClass: "mr-2",
                                          attrs: {
                                            to: button.destino,
                                            custom: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ navigate }) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant:
                                                            "outline-primary",
                                                        },
                                                        on: { click: navigate },
                                                      },
                                                      [
                                                        _c("span", {
                                                          class:
                                                            "icon-" +
                                                            button.icon,
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      }
                                    ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.solucion.vistaResumen.pieDePagina
                    ? _c("b-tfoot", [
                        _c("div", {
                          staticClass: "text-muted",
                          staticStyle: { "white-space": "pre-line" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.solucion.vistaResumen.pieDePagina
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "bulkOperationModal",
          attrs: {
            scrollable: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            id: "bulkOperationModal",
            "header-bg-variant": "primary",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("apeironGwApp.tableView.bulkTitle"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body text-center" }, [
            _c("span", {
              staticClass: "h5",
              domProps: {
                innerHTML: _vm._s(_vm.$t("global.messages.info.confirm")),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text-center mt-3" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("apeironGwApp.tableView.transicion", {
                      action: _vm.$t(
                        "apeironGwApp.TipoAccion." + _vm.currentActionSelected
                      ),
                      countSelectedItems: _vm.countSelectedItems,
                    })
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseBulkOperationModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _vm.currentActionSelected
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirmBulkOperation()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "bulkOperationModal2",
          attrs: {
            scrollable: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            id: "bulkOperationModal2",
            "header-bg-variant": "primary",
            size: "lg",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("apeironGwApp.tableView.bulkTitle"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("span", {
              staticClass: "h5",
              domProps: {
                innerHTML: _vm._s(_vm.$t("global.messages.info.confirm")),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("asignacion-operador", {
                  ref: "asignacionOperador",
                  attrs: {
                    id: "asignacionOperador",
                    solucion: _vm.solucion,
                    operacionMasiva: _vm.isOperacionMasiva,
                    permisos: _vm.permisos,
                    formModel: _vm.model,
                    solicitud: _vm.solicitud,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseBulkOperationModal2()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmBulkOperation2()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "errorDetailModal",
          attrs: {
            scrollable: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            size: "xl",
            id: "errorDetailModal",
            "header-bg-variant": "primary",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apeironGwApp.tableView.modal.validacion.titulo"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.currentRowDetail
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      { staticClass: "ml-2 mr-2 mt-3" },
                      [
                        _c("header", { staticClass: "bx-header-title" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.tableView.modal.validacion.soliciante"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "header-title-line" }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-cols": "2",
                              label: _vm.$t("archeApp.solicitud.id"),
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.currentRowDetail.id)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-cols": "2",
                              label: _vm.$t(
                                "apeironGwApp.tableView.solicitante"
                              ),
                            },
                          },
                          [
                            _c("small", [
                              _vm._v(
                                _vm._s(_vm.currentRowDetail.solicitante) + " "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("header", { staticClass: "bx-header-title mt-5" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.tableView.modal.validacion.reglas.titulo"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "header-title-line" }),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "apeironGwApp.tableView.modal.validacion.reglas.mensaje",
                                {
                                  from: _vm.$t(
                                    "archeApp.EstadoSolicitud." +
                                      _vm.currentRowDetail.estado
                                  ),
                                  to: _vm.$t(
                                    "archeApp.EstadoSolicitud." +
                                      _vm.currentRowDetail.processInfo.destino
                                  ),
                                }
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("errors", {
                          model: {
                            value: _vm.currentRowDetail.processInfo.errors,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentRowDetail.processInfo,
                                "errors",
                                $$v
                              )
                            },
                            expression: "currentRowDetail.processInfo.errors",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseErrorDetailModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.close")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }