import { Component, Inject, Vue } from 'vue-property-decorator';
import { StatusCodes } from 'http-status-codes';
import PersonaService from '@/shared/persona/persona.service';
import { EstadoProceso } from '@/shared/model/enumerations/estado-proceso.model';

@Component
export default class PersounaAccountHandler extends Vue {
  @Inject('personaService') public personaService: () => PersonaService;

  mounted() {
    (this.$root as any).$on('load-perfil', this.loadPerfil);
  }

  beforeDestroy() {
    (this.$root as any).$off('load-perfil', this.loadPerfil);
  }

  public loadPerfil() {
    this.$store.dispatch('iniciarCargaDelPerfil');
    this.personaService()
      .findByLogin(this.username)
      .then(userFromRizoma => {
        this.$store.commit('userInfo', userFromRizoma);
        this.personaService()
          .findByLoginInLegacyAndMerge(this.username, userFromRizoma)
          .then(userFromCvuMerged => {
            this.$store.commit('userInfo', userFromCvuMerged);
            this.$store.dispatch('detenerCargaDelPerfil', EstadoProceso.FINALIZADO_EXITOSAMENTE);
          })
          .catch(error => {
            if (error.response.status === StatusCodes.NOT_FOUND) {
              this.$store.dispatch('detenerCargaDelPerfil', EstadoProceso.FINALIZADO_EXITOSAMENTE);
            }
            this.$store.dispatch('detenerCargaDelPerfil', EstadoProceso.FINALIZADO_PARCIALMENTE);
          });
      })
      .catch(error => {
        if (error.response.status === StatusCodes.NOT_FOUND) {
          this.$store.dispatch('detenerCargaDelPerfil', EstadoProceso.DATOS_NO_ENCONTRADOS);
        }
        this.$store.dispatch('detenerCargaDelPerfil', EstadoProceso.FINALIZADO_CON_ERRORES);
      });
  }

  get username(): string {
    return this.$store.getters.account?.login ?? '';
  }
}
