var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      !_vm.isStaff && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/solicitud" } },
            [
              _c("span", { staticClass: "icon-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.solicitud")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isStaff && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/solicitud/administracion" },
            },
            [
              _c("span", { staticClass: "icon-admin-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.adminSolicitud")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isStaff && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/documentos/Acreditaciones" },
            },
            [
              _c("span", { staticClass: "icon-pdf" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.documentosAcreditaciones")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isStaff && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/revision" } },
            [
              _c("span", { staticClass: "icon-revision" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.revision")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_USER", "ROLE_SOPORTE"]) && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/bandejaSolicitudes" },
            },
            [
              _c("span", { staticClass: "icon-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("evaluacionIndividual")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_INNOVADOR") && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/solucion" } },
            [
              _c("span", { staticClass: "icon-soluciones" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.solucion")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_MODELADOR") && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/form" } },
            [
              _c("span", { staticClass: "icon-evaluacion-2" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.form")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/evaluacion/adminComisionesList" },
            },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.adminComisionesList")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/asignacion/asignacionSolicitudes" },
            },
            [
              _c("span", { staticClass: "icon-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.asignacionSolicitudes")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_USER", "ROLE_SOPORTE"]) && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/bandejaPlenariaSolicitud" },
            },
            [
              _c("span", { staticClass: "icon-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.bandejaPlenariaSolicitud")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/bandeja/administracion" },
            },
            [
              _c("span", { staticClass: "icon-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.bandejaAdminRevisiones")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_USER", "ROLE_SOPORTE"]) && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/evaluacion/dictamenPlenaria" },
            },
            [
              _c("span", { staticClass: "icon-pdf" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.dictamenPlenaria")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/consejoGeneral" } },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.consejoGeneral")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/evaluacion/insaculacion" },
            },
            [
              _c("span", { staticClass: "icon-comunidades" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.insaculacion")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/regitroMovimientoOperador" },
            },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("apeironGwApp.movimiento.titulo")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_OPERADOR", "ROLE_AUDITOR"]) &&
      _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/reportes" } },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.reportes")),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([
        "ROLE_OPERADOR_PROGRAMA",
        "ROLE_AUDITOR",
        "ROLE_USER",
      ]) && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/seguimientoBecasNacionales" },
            },
            [
              _c("b-icon", { attrs: { icon: "calendar3" } }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.seguimientoBecasNacionales")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_AUDITOR", "ROLE_USER"]) && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/snp" } },
            [
              _c("b-icon", { attrs: { icon: "file-ppt" } }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.snp")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/cancelar/solicitudes" },
            },
            [
              _c("span", { staticClass: "icon-admin-solicitud" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.cancelarSolicitudes")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/bandejaConvenios" } },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.bandejaConvenios")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_USER", "ROLE_OPERADOR"]) && _vm.authenticated
        ? _c(
            "b-link",
            {
              staticClass: "btn-home m-3",
              attrs: { to: "/becas-extranjeras/evaluacion" },
            },
            [
              _c("span", { staticClass: "icon-revision" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.bandejaEvaluador")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_OPERADOR") && _vm.authenticated
        ? _c(
            "b-link",
            { staticClass: "btn-home m-3", attrs: { to: "/sicob" } },
            [
              _c("span", { staticClass: "icon-evaluacion" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.sicob")),
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }