import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { RevisorResumen } from '@/shared/model/solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import * as estadoUtils from '@/shared/estados/estados.solicitud.util';
import { v4 as uuidv4 } from 'uuid';

@Component
export default class AssingReviewer extends Vue {
  @Prop({ required: true })
  value: RevisorResumen[];

  @Prop({ required: true })
  definicionEvaluacion: any;

  @Prop({ default: false })
  readOnly: boolean;

  public revisor: RevisorResumen = new RevisorResumen();

  public persona = { cvu: null, login: null, nombre: null, apellidoMaterno: null, apellidoPaterno: null };

  public evaluacionTabIndex = 0;

  public currentRevision = null;

  created() {
    if (this.definicionEvaluacion?.revisiones.length > 0) {
      this.currentRevision = this.definicionEvaluacion.revisiones[0];
    }
  }

  @Emit('show-review-details')
  public handleShowReviewDetails(revisor) {
    return revisor;
  }

  get campos() {
    if (this.readOnly) {
      return ['cvu', 'revisorId', 'nombre', 'Acciones'];
    } else {
      return ['cvu', 'revisorId', 'nombre', 'estado', 'Acciones'];
    }
  }
  get revisores() {
    return this.value;
  }

  set revisores(revisores: RevisorResumen[]) {
    this.$emit('input', revisores);
  }

  get revisoresFiltered() {
    if (!this.currentRevision || !this.revisores) {
      return [];
    }
    return this.revisores.filter(revisor => revisor.evaluacionId === this.currentRevision.id);
  }

  get isAlreadyInvited() {
    if (!this.currentRevision?.id && !this.revisores) {
      return false;
    }
    return (
      this.revisores.filter(revisor => revisor.cvu === this.persona.cvu && revisor.evaluacionId === this.currentRevision.id).length > 0
    );
  }

  public loadRevision(revision) {
    this.currentRevision = revision;
  }

  public isRemovable(revisor) {
    return revisor.estado === EstadoRevision.ASIGNADA && !this.readOnly;
  }

  revisorToRemove = { id: null };
  public handlePrepareToRemove(revisor): void {
    this.revisorToRemove = revisor;
    if (<any>this.$refs.confirmRemoveModal) {
      (<any>this.$refs.confirmRemoveModal).show();
    }
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public handleConfirmation(): void {
    const revisorIndexToRemove = this.revisores.findIndex(revisor => revisor.id === this.revisorToRemove.id);
    if (revisorIndexToRemove >= 0) {
      this.revisores.splice(revisorIndexToRemove, 1);
    }
    this.hideModal();
  }

  public hideModal() {
    (this.$refs.confirmRemoveModal as any).hide();
  }

  public handleOpenAddRevisorModal(): void {
    this.persona = { cvu: null, login: null, nombre: null, apellidoMaterno: null, apellidoPaterno: null };
    if (<any>this.$refs.addRevisor) {
      (<any>this.$refs.addRevisor).show();
    }
  }
  public handleCloseAddRevisorModal(): void {
    if (<any>this.$refs.addRevisor) {
      (<any>this.$refs.addRevisor).hide();
    }
  }

  public handleAddRevisor(): void {
    this.revisor = new RevisorResumen();
    this.revisor.id = uuidv4();
    this.revisor.evaluacionId = this.currentRevision.id;
    this.revisor.cvu = this.persona.cvu;
    this.revisor.revisorId = this.persona.login;
    this.revisor.nombre = this.persona.nombre + ' ' + this.persona.apellidoPaterno + ' ' + this.persona.apellidoMaterno;
    this.revisor.estado = EstadoRevision.ASIGNADA;
    this.revisores.push(this.revisor);
    if (<any>this.$refs.addRevisor) {
      (<any>this.$refs.addRevisor).hide();
    }
  }

  public resolveVariant(estado) {
    return estadoUtils.resolveEstadoRevisionVariant(estado);
  }
}
