var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "demo" },
    [
      _c(
        "screen",
        { ref: "screen" },
        [
          _vm._l(_vm.graph.edges, function (edge) {
            return _c("edge", {
              key: edge.id,
              attrs: { data: edge, nodes: _vm.graph.nodes },
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.graph.nodes, function (node) {
            return _c("node", {
              key: node.id,
              ref: "node",
              refInFor: true,
              attrs: { data: node },
            })
          }),
          _vm._v(" "),
          _vm.graph.edges.length
            ? _c(
                "v-label",
                {
                  ref: "label",
                  attrs: {
                    edge: _vm.graph.edges[0],
                    perc: parseInt(_vm.perc),
                    offset: {
                      x: parseInt(_vm.offsetX),
                      y: parseInt(_vm.offsetY),
                    },
                    align: _vm.align,
                    rotate: _vm.rotate,
                    connector: _vm.connector,
                    useDrag: true,
                  },
                  on: { drag: _vm.onDrag },
                },
                [_c("div", { domProps: { innerHTML: _vm._s(_vm.contents) } })]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }