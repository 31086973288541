var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _vm.jsonExcel?.length > 0
                ? _c("export-from-json", {
                    attrs: { data: _vm.jsonExcel, "file-name": "historico" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        staticClass: "text-nowrap mt-3",
        attrs: {
          responsive: "",
          borderless: "",
          "show-empty": "",
          small: "",
          "current-page": _vm.currentPage,
          "sort-direction": "asc",
          "per-page": _vm.perPage,
          items: _vm.items,
          fields: _vm.fields,
          "head-variant": "primary",
        },
        scopedSlots: _vm._u([
          {
            key: "head()",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-center" }, [
                  _vm._v("\n        " + _vm._s(data.label) + "\n      "),
                ]),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "text-center h3 mt-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("apeironGwApp.persona.expediente.empty"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "cell()",
            fn: function (data) {
              return [
                typeof data.value == "boolean"
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        data.value
                          ? _c("b-icon", {
                              staticClass: "i-selected",
                              attrs: {
                                icon: "check-square",
                                variant: "primary",
                                "font-scale": "1.2",
                              },
                            })
                          : _c("b-icon", {
                              staticClass: "i-selected",
                              attrs: {
                                icon: "square",
                                variant: "primary",
                                "font-scale": "1.2",
                              },
                            }),
                      ],
                      1
                    )
                  : data.field.link
                  ? _c(
                      "div",
                      [
                        data.value
                          ? _c(
                              "b-link",
                              { attrs: { target: "_blank", href: data.value } },
                              [_vm._v("Ver convenio")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-center" }, [
                      _vm._v("\n        " + _vm._s(data.value) + "\n      "),
                    ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-pagination", {
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          align: "center",
          size: "sm",
          pills: "",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }