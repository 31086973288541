import { Component, Inject, Prop } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import ResultadosService from './resultados.service';

import JhiDataUtils from '@/shared/data/data-utils.service';

@Component
export default class ResultadosComponent extends mixins(JhiDataUtils, ApeironComponent) {
  @Inject('resultadosService') public resultadosService: () => ResultadosService;

  @Prop({ required: true })
  public solicitud: any;

  @Prop({ required: false })
  public oficioUrl: string;

  @Prop({ required: false })
  public reconocimientoUrl: string;

  isFetching = false;
  resultado = null;
  noData = false;
  created() {
    this.findResultados();
  }

  public findResultados() {
    this.isFetching = true;
    this.resultadosService()
      .find(this.solicitud.id)
      .then(result => {
        this.isFetching = false;
        this.resultado = result;
      })
      .catch(error => {
        this.isFetching = false;

        this.alertService().showHttpError(this, error.response);
      });
  }
}
