import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import dayjs from 'dayjs';

@Component
export default class PersonaUpdateComponent extends Vue {
  @Prop()
  value: any;

  @Prop({ default: false })
  readOnly: boolean;

  @Prop({ required: false })
  duracionOficial: number;

  get persona() {
    return this.value;
  }

  set persona(persona: any) {
    this.$emit('input', persona);
  }

  @Watch('persona.inicioGeneracion', { immediate: true, deep: false })
  changeInicioGeneracion(inicioGeneracion) {
    if (this.readOnly) {
      return;
    }
    if (inicioGeneracion) {
      const currentDate = dayjs(inicioGeneracion);
      this.persona.inicioEstudios = currentDate.startOf('month').format('DD/MM/YYYY');
      this.persona.finEstudios = currentDate
        .startOf('month')
        .add(this.duracionOficial - 1, 'month')
        .endOf('month')
        .format('DD/MM/YYYY');
    }
  }

  get isFormValid() {
    const isValid =
      this.persona.tiempoDedicacion != null &&
      this.persona.tiempoDedicacion !== 'undefined' &&
      this.persona.promedioAnterior != null &&
      this.persona.promedioAnterior !== 'undefined' &&
      this.persona.inicioGeneracion != null &&
      this.persona.inicioGeneracion !== 'undefined';
    this.$emit('valid', isValid);
    return isValid;
  }

  tipoParticipacion = [
    { value: RolAutoridad.ALUMNO, text: 'Alumno' },
    { value: RolAutoridad.PROFESOR, text: 'Profesor' },
  ];
  tipoBeca = [
    { value: '1', text: 'Beca nacional' },
    { value: '2', text: 'Beca en el extranjero' },
  ];
  tiempoDedicacion = [
    { value: '1', text: 'Completo' },
    { value: '2', text: 'Parcial' },
  ];
}
