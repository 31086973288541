import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import { Proceso } from '@/shared/model/proceso/proceso.model';
import { Estado } from '@/shared/model/proceso/estado.model';
import { Transicion } from '@/shared/model/proceso/transicion.model';
import html2pdf from 'html2pdf.js';

import RuleService from '@/shared/rule/rule.service';
import ActionService from '@/shared/action/action.service';
import AlertService from '@/shared/alert/alert.service';

@Component
export default class ProcesoComponent extends Vue {
  @Inject('ruleService') public ruleService: () => RuleService;
  @Inject('actionService') public actionService: () => ActionService;
  @Inject('alertService') public alertService: () => AlertService;

  @Prop({ required: true })
  public value!: Proceso;

  public reglas = [];
  public actions = [];

  public tabCounter = 0;

  public tabIndex = 0;

  public estadosUnicos = [];

  public acciones = [];

  public diagrama = [];

  public diagramId = 0;

  public currentEstate = null;

  public config = {
    theme: 'base',
    themeVariables: {
      primaryColor: '#E4F4FC', // fill color
      primaryBorderColor: '#99ccff', // border color
      primaryTextColor: '#000000', // text color
      lineColor: '#999999',
      secondaryTextColor: '#99ccff', // text color
      secondaryColor: '#FFFFFF',
      tertiaryColor: '#fff',
      transitionColor: '#800000',
    },
  };

  public exportToPDF() {
    html2pdf(document.getElementById('mermaid'), {
      margin: 1,
      filename: 'proceso.pdf',
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    });
  }
  get transitionRules() {
    return this.reglas;
  }

  created(): void {
    this.estadosUnicos = this.resolveEstados();
    this.acciones = this.resolveAcciones();
    this.retriveRules();
    this.retriveActions();
  }

  public retriveRules(): void {
    this.ruleService()
      .retrieve()
      .then(param => {
        this.reglas = param.data;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
  public retriveActions(): void {
    this.actionService()
      .retrieve()
      .then(param => {
        this.actions = param.data;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public renderProcess() {
    const diagrama = [];

    this.proceso.estados.forEach(estado => {
      const transiciones = [];
      diagrama.push({
        id: estado.nombre,
        editable: true,
        style: this.currentEstate === estado.nombre ? 'fill:#E4F4FC,stroke:#99ccff,stroke-width:3px' : '',
        text: this.getEstadoName(estado.nombre),
      });
      estado.transiciones.forEach(transicion => {
        diagrama.push({
          id: estado.nombre,
          text: this.getEstadoName(estado.nombre),
          editable: true,
          link:
            '-- ' +
            '<p><i>' +
            this.getAccionName(transicion.accion) +
            '</i>:</p>' +
            '<small>' +
            this.resolveRoles(estado, transicion.accion) +
            '</small>' +
            ' -->',
          next: [transicion.destino],
        });
      });
    });
    this.diagrama = diagrama;
    this.diagramId += 1;
  }

  public resolveRoles(estado: Estado, accion: TipoAccion) {
    const roles = [];
    estado.permisos.forEach(permiso => {
      if (permiso.acciones.findIndex(e => e === accion) > -1) {
        roles.push(permiso.rol.toLocaleLowerCase());
      }
    });
    return roles;
  }

  public getEstadoName(estadoKey) {
    if (estadoKey) {
      return this.$t('archeApp.EstadoSolicitud.' + estadoKey) + ':';
    } else {
      return 'Requiere configuración:';
    }
  }

  public getAccionName(estadoKey) {
    return this.$t('apeironGwApp.TipoAccion.' + estadoKey);
  }

  public transicion = new Transicion();
  public currentEstado = new Estado();

  get proceso() {
    return this.value;
  }

  public handleChangeTransicion(transicion) {
    this.renderProcess();
  }
  public closeTab(index) {
    this.proceso.estados.splice(index, 1);
    this.syncEstados();
  }

  public newTab() {
    this.tabCounter++;
    this.proceso.estados.push(new Estado());
  }

  public resolveTitle(estado) {
    if (estado.nombre) {
      return this.$t('archeApp.EstadoSolicitud.' + estado.nombre);
    } else {
      return '[Seleccione un estado]';
    }
  }

  public resolveEstados() {
    const estados = [];
    const estadosKeys = Object.keys(EstadoSolicitud);

    estadosKeys.forEach(key => {
      if (key !== EstadoSolicitud.EN_CAPTURA) {
        estados.push({ value: key, text: this.$t('archeApp.EstadoSolicitud.' + key), disabled: false });
      }
    });
    return estados;
  }

  public resolveAcciones() {
    const acciones = [];
    const accionesKeys = Object.keys(TipoAccion);

    accionesKeys.forEach(key => {
      acciones.push({ value: key, text: this.$t('apeironGwApp.TipoAccion.' + key), disabled: false });
    });
    return acciones;
  }

  public onChangeHandler() {
    this.syncEstados();
  }

  @Watch('proceso', { deep: true })
  onProcesoReady(val, oldVal) {
    this.renderProcess();
  }

  public syncEstados() {
    this.estadosUnicos.forEach(estado => {
      const result = this.proceso.estados.filter(obj => obj.nombre === estado.value);
      estado.disabled = result.length > 0 ? true : false;
    });
  }

  public editEstado(estado) {
    const index = this.proceso.estados.findIndex(e => e.nombre === estado);
    if (index > -1) {
      this.tabIndex = index;
    }
    this.currentEstate = estado;
    this.renderProcess();
  }
}
