var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.solicitud?.estado === _vm.isCaptura
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.tipo") } },
            [
              _c("b-form-select", {
                staticClass: "w-100",
                attrs: {
                  id: "select-movimiento-id",
                  state: !_vm.$v.form.movimiento.tipo.$invalid,
                  options: _vm.tipoVigenciasOpcionesSplit,
                  disabled: _vm.indOpen,
                },
                on: { change: _vm.limpiaForm },
                model: {
                  value: _vm.form.movimiento.tipo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "tipo", $$v)
                  },
                  expression: "form.movimiento.tipo",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-movimiento-feedback" } },
                [
                  !_vm.$v.form.movimiento.tipo.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.solicitud?.estado !== _vm.isCaptura
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.tipo") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-movimiento-id",
                  state: !_vm.$v.form.movimiento.tipo.$invalid,
                  type: "text",
                  disabled: _vm.solicitud?.estado !== _vm.isCaptura,
                },
                model: {
                  value: _vm.form.movimiento.tipo.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento.tipo, "desc", $$v)
                  },
                  expression: "form.movimiento.tipo.desc",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-movimiento-feedback" } },
                [
                  !_vm.$v.form.movimiento.tipo.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo ||
      _vm.form?.movimiento?.tipo?.clave === this.cveSancion
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.tipoMotivo") } },
            [
              _c("b-form-select", {
                staticClass: "w-100",
                attrs: {
                  id: "select-motivo-id",
                  state: !_vm.$v.form.movimiento.motivo.$invalid,
                  options: _vm.tipoMotivosOpciones,
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                model: {
                  value: _vm.form.movimiento.motivo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "motivo", $$v)
                  },
                  expression: "form.movimiento.motivo",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.motivo.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === _vm.cveVigCargoAdmvoElec ||
      _vm.form?.movimiento?.tipo?.clave === this.cveSancion ||
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo
        ? _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("apeironGwApp.movimiento.fecha.inicio") },
            },
            [
              _c("b-form-datepicker", {
                staticClass: "w-100",
                attrs: {
                  id: "select-inicio-aplicacion-id",
                  type: "date",
                  "value-as-date": "",
                  state: !_vm.$v.form.movimiento.inicioAplicacion.$invalid,
                  disabled:
                    _vm.indOpen || (!_vm.rolOperador && !_vm.isPublico()),
                },
                model: {
                  value: _vm.form.movimiento.inicioAplicacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "inicioAplicacion", $$v)
                  },
                  expression: "form.movimiento.inicioAplicacion",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-inicio-aplicacion-feedback" } },
                [
                  !_vm.$v.form.movimiento.inicioAplicacion.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === _vm.cveVigFinalizoAdmvoElec ||
      _vm.form?.movimiento?.tipo?.clave === this.cveSancion ||
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.fecha.fin") } },
            [
              _c("b-form-datepicker", {
                staticClass: "w-100",
                attrs: {
                  id: "select-fin-aplicacion-id",
                  type: "date",
                  "value-as-date": "",
                  state: !_vm.$v.form.movimiento.finAplicacion.$invalid,
                  disabled:
                    _vm.indOpen || (!_vm.rolOperador && !_vm.isPublico()),
                },
                model: {
                  value: _vm.form.movimiento.finAplicacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "finAplicacion", $$v)
                  },
                  expression: "form.movimiento.finAplicacion",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-fin-aplicacion-feedback" } },
                [
                  !_vm.$v.form.movimiento.finAplicacion.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.form.movimiento.finAplicacion.minValue
                    ? _c("small", [
                        _vm._v(
                          _vm._s(_vm.$t("apeironGwApp.movimiento.validaFecha"))
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === _vm.cveVigTermAnt
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.motivo") } },
            [
              _c("b-form-textarea", {
                attrs: {
                  state: !_vm.$v.form.movimiento.observacion.$invalid,
                  rows: "3",
                  "max-rows": "8",
                  disabled: _vm.indOpen,
                  placeholder: _vm.$t(
                    "apeironGwApp.movimiento.placeholderMotivo"
                  ),
                },
                model: {
                  value: _vm.form.movimiento.observacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "observacion", $$v)
                  },
                  expression: "form.movimiento.observacion",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.observacion.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.form.movimiento.observacion.minLength
                    ? _c("small", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apeironGwApp.movimiento.placeholderMotivo")
                          )
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.tipoDemanda") } },
            [
              _c("b-form-select", {
                staticClass: "w-100",
                attrs: {
                  id: "select-nombre-id",
                  state: !_vm.$v.form.movimiento.estatus.$invalid,
                  options: _vm.estatusJuciosAmparoOpciones,
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                model: {
                  value: _vm.form.movimiento.estatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "estatus", $$v)
                  },
                  expression: "form.movimiento.estatus",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.estatus.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.numeroJucio") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-nombre-id",
                  state: !_vm.$v.form.movimiento.numeroJucio.$invalid,
                  type: "text",
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                on: {
                  input: function ($event) {
                    return _vm.convertToUpperCaseAndRemoveAccents("numeroJucio")
                  },
                },
                model: {
                  value: _vm.form.movimiento.numeroJucio,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "numeroJucio", $$v)
                  },
                  expression: "form.movimiento.numeroJucio",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.numeroJucio.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.autoridad") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-autoridad-id",
                  state: !_vm.$v.form.movimiento.autoridad.$invalid,
                  type: "text",
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                on: {
                  input: function ($event) {
                    return _vm.convertToUpperCaseAndRemoveAccents("autoridad")
                  },
                },
                model: {
                  value: _vm.form.movimiento.autoridad,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "autoridad", $$v)
                  },
                  expression: "form.movimiento.autoridad",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.autoridad.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
        ? _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("apeironGwApp.movimiento.actoReclamado") },
            },
            [
              _c("b-form-checkbox-group", {
                staticClass: "w-100",
                attrs: {
                  id: "select-actoReclamado-id",
                  options: _vm.filteredActoReclamadoOptions(),
                  state: !_vm.$v.form.movimiento.actoReclamado.$invalid,
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                on: { change: _vm.handleActoReclamadoChange },
                model: {
                  value: _vm.form.movimiento.actoReclamado,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "actoReclamado", $$v)
                  },
                  expression: "form.movimiento.actoReclamado",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.actoReclamado.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.form?.movimiento?.actoReclamado &&
              _vm.form?.movimiento?.actoReclamado.length > 0
                ? _c("b-form-textarea", {
                    attrs: {
                      placeholder: "Ejemplo: ARTICULO 34, FRACCION I",
                      state:
                        !_vm.$v.form.movimiento.actoReclamadoTexto.$invalid,
                      disabled: _vm.indOpen || !_vm.rolOperador,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.convertToUpperCaseAndRemoveAccents(
                          "actoReclamadoTexto"
                        )
                      },
                    },
                    model: {
                      value: _vm.form.movimiento.actoReclamadoTexto,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.movimiento, "actoReclamadoTexto", $$v)
                      },
                      expression: "form.movimiento.actoReclamadoTexto",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.actoReclamadoTexto.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tabs",
        {
          attrs: { "content-class": "mt-3" },
          model: {
            value: _vm.noProceso,
            callback: function ($$v) {
              _vm.noProceso = $$v
            },
            expression: "noProceso",
          },
        },
        [
          _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
            ? _c(
                "b-tab",
                {
                  key:
                    "dyn-tab-" + _vm.$t("apeironGwApp.movimiento.suspension"),
                  attrs: {
                    title: _vm.$t("apeironGwApp.movimiento.suspension"),
                    active: _vm.form.movimiento.noProceso === 0 ? true : false,
                  },
                },
                [
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("apeironGwApp.movimiento.suspension"),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-suspension-1",
                              options: _vm.opcionesSuspencion,
                              state:
                                !_vm.$v.form.movimiento.suspension.$invalid,
                              name: "suspension",
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.resetTipoSuspension(),
                                  _vm.addRegistroHistorico(
                                    _vm
                                      .$t("apeironGwApp.movimiento.suspension")
                                      .toString()
                                  )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.suspension,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.movimiento, "suspension", $$v)
                              },
                              expression: "form.movimiento.suspension",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.suspension.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
                  (_vm.form?.movimiento?.suspension === "definitiva" ||
                    _vm.form?.movimiento?.suspension === "provisional")
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("apeironGwApp.movimiento.decision"),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-decision-1",
                              state: !_vm.$v.form.movimiento.decision.$invalid,
                              name: "decision",
                              options: _vm.tipoSuspensionOpciones,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.addRegistroHistorico(
                                  _vm
                                    .$t("apeironGwApp.movimiento.suspension")
                                    .toString()
                                )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.decision,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.movimiento, "decision", $$v)
                              },
                              expression: "form.movimiento.decision",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.decision.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
            ? _c(
                "b-tab",
                {
                  key: "dyn-tab-" + _vm.$t("apeironGwApp.movimiento.incidente"),
                  attrs: {
                    title: _vm.$t("apeironGwApp.movimiento.incidente"),
                    active: _vm.form.movimiento.noProceso === 1 ? true : false,
                  },
                },
                [
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("apeironGwApp.movimiento.incidente"),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-incidente-1",
                              state: !_vm.$v.form.movimiento.incidente.$invalid,
                              name: "incidente",
                              options: _vm.opcionesGenerales,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.resetTipoIncidente(),
                                  _vm.addRegistroHistorico(
                                    _vm
                                      .$t("apeironGwApp.movimiento.incidente")
                                      .toString()
                                  )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.incidente,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.movimiento, "incidente", $$v)
                              },
                              expression: "form.movimiento.incidente",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.suspension.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
                  _vm.form?.movimiento?.incidente === "Sí"
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "apeironGwApp.movimiento.tipoIncidente"
                            ),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-tipoIncidente-1",
                              state:
                                !_vm.$v.form.movimiento.tipoIncidente.$invalid,
                              name: "tipoIncidente",
                              options: _vm.tipoIncidenteOpciones,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.addRegistroHistorico(
                                  _vm
                                    .$t("apeironGwApp.movimiento.incidente")
                                    .toString()
                                )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.tipoIncidente,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.movimiento,
                                  "tipoIncidente",
                                  $$v
                                )
                              },
                              expression: "form.movimiento.tipoIncidente",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.tipoIncidente.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
            ? _c(
                "b-tab",
                {
                  key:
                    "dyn-tab-" + _vm.$t("apeironGwApp.movimiento.ejecutoria"),
                  attrs: {
                    title: _vm.$t("apeironGwApp.movimiento.ejecutoria"),
                    active: _vm.form.movimiento.noProceso === 2 ? true : false,
                  },
                },
                [
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("apeironGwApp.movimiento.ejecutoria"),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-ejecutoria-1",
                              state:
                                !_vm.$v.form.movimiento.ejecutoria.$invalid,
                              name: "ejecutoria",
                              options: _vm.opcionesGenerales,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.resetTipoEjecutoria(),
                                  _vm.addRegistroHistorico(
                                    _vm
                                      .$t("apeironGwApp.movimiento.ejecutoria")
                                      .toString()
                                  )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.ejecutoria,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.movimiento, "ejecutoria", $$v)
                              },
                              expression: "form.movimiento.ejecutoria",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.ejecutoria.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
                  _vm.form?.movimiento?.ejecutoria === "Sí"
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "apeironGwApp.movimiento.tipoEjecutoria"
                            ),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-tipoEjecutoria-1",
                              state:
                                !_vm.$v.form.movimiento.tipoEjecutoria.$invalid,
                              name: "tipoEjecutoria",
                              options: _vm.tipoEjecutoriaOpciones,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.addRegistroHistorico(
                                  _vm
                                    .$t("apeironGwApp.movimiento.ejecutoria")
                                    .toString()
                                )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.tipoEjecutoria,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.movimiento,
                                  "tipoEjecutoria",
                                  $$v
                                )
                              },
                              expression: "form.movimiento.tipoEjecutoria",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.tipoEjecutoria.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
            ? _c(
                "b-tab",
                {
                  key:
                    "dyn-tab-" +
                    _vm.$t("apeironGwApp.movimiento.oficioSolicitud"),
                  attrs: {
                    title: _vm.$t("apeironGwApp.movimiento.oficioSolicitud"),
                    active: _vm.form.movimiento.noProceso === 3 ? true : false,
                  },
                },
                [
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "apeironGwApp.movimiento.oficioSolicitud"
                            ),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-oficioSolicitud-1",
                              state:
                                !_vm.$v.form.movimiento.oficioSolicitud
                                  .$invalid,
                              name: "oficioSolicitud",
                              options: _vm.opcionesGenerales,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.resetTipoOficioSolicitud(),
                                  _vm.addRegistroHistorico(
                                    _vm
                                      .$t(
                                        "apeironGwApp.movimiento.oficioSolicitud"
                                      )
                                      .toString()
                                  )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.oficioSolicitud,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.movimiento,
                                  "oficioSolicitud",
                                  $$v
                                )
                              },
                              expression: "form.movimiento.oficioSolicitud",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.oficioSolicitud.required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
                  _vm.form?.movimiento?.oficioSolicitud === "Sí"
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "apeironGwApp.movimiento.tipoOficioSolicitud"
                            ),
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-tipoOficioSolicitud-1",
                              state:
                                !_vm.$v.form.movimiento.tipoOficioSolicitud
                                  .$invalid,
                              name: "tipoOficioSolicitud",
                              options: _vm.tipoOficioOpciones,
                              disabled: _vm.indOpen || !_vm.rolOperador,
                            },
                            on: {
                              change: function ($event) {
                                _vm.addRegistroHistorico(
                                  _vm
                                    .$t(
                                      "apeironGwApp.movimiento.oficioSolicitud"
                                    )
                                    .toString()
                                )
                              },
                            },
                            model: {
                              value: _vm.form.movimiento.tipoOficioSolicitud,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.movimiento,
                                  "tipoOficioSolicitud",
                                  $$v
                                )
                              },
                              expression: "form.movimiento.tipoOficioSolicitud",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.tipoOficioSolicitud
                                .required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.form?.movimiento?.tipoOficioSolicitud
                            ? _c("b-form-textarea", {
                                attrs: {
                                  placeholder: "Ejemplo: CA3000/1223/2024",
                                  disabled: _vm.indOpen || !_vm.rolOperador,
                                  state:
                                    !_vm.$v.form.movimiento.oficioSolicitudTexto
                                      .$invalid,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.convertToUpperCaseAndRemoveAccents(
                                      "oficioSolicitudTexto"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.form.movimiento.oficioSolicitudTexto,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.movimiento,
                                      "oficioSolicitudTexto",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.movimiento.oficioSolicitudTexto",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "select-motivo-feedback" } },
                            [
                              !_vm.$v.form.movimiento.tipoOficioSolicitud
                                .required
                                ? _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("entity.validation.required")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
      (_vm.form?.movimiento?.tipoOficioSolicitud === "Pago Programado" ||
        _vm.form?.movimiento?.tipoOficioSolicitud === "Pago Urgente")
        ? _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("apeironGwApp.movimiento.mesProgramado") },
            },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-nombre-id",
                  state: !_vm.$v.form.movimiento.mesProgramado.$invalid,
                  type: "month",
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                on: {
                  input: function ($event) {
                    return _vm.convertToUpperCaseAndRemoveAccents(
                      "mesProgramado"
                    )
                  },
                },
                model: {
                  value: _vm.form.movimiento.mesProgramado,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "mesProgramado", $$v)
                  },
                  expression: "form.movimiento.mesProgramado",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.mesProgramado.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.comentarios") } },
            [
              _c("b-form-textarea", {
                staticClass: "w-100",
                attrs: {
                  id: "select-nombre-id",
                  rows: "3",
                  "max-rows": "8",
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                model: {
                  value: _vm.coment.descripcion,
                  callback: function ($$v) {
                    _vm.$set(_vm.coment, "descripcion", $$v)
                  },
                  expression: "coment.descripcion",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.indOpen &&
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
      _vm.rolOperador
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { sm: "12", md: "12", lg: "12" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.coment?.descripcion,
                        variant: "primary",
                      },
                      on: { click: _vm.agregarComentario },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.add")),
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon", { attrs: { icon: "save" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [
                      _c("mark", { staticClass: "text-primary" }, [
                        _c("em", [
                          _vm._v(
                            _vm._s(_vm.$t("apeironGwApp.movimiento.alerta"))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
      _vm.form?.movimiento?.comentarios
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c(
                  "div",
                  { staticClass: "card mt-12" },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { rounded: "sm" } },
                      [
                        _c("b-table", {
                          attrs: {
                            "sticky-header": "500px",
                            fields: _vm.configuracionColumnasComent,
                            items: _vm.form.movimiento.comentarios,
                            "head-variant": "dark",
                          },
                        }),
                        _vm._v(" "),
                        _c("b-card-text", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("apeironGwApp.asignacion.totalEval", {
                                total: _vm.form.movimiento.comentarios.length,
                              })
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo && this.saldado < 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("div", [
                  _c("strong", [
                    _c("mark", { staticClass: "text-danger" }, [
                      _c("em", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apeironGwApp.movimiento.alertaSaldado")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo
        ? _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("apeironGwApp.movimiento.estatusAdeudo") },
            },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "movimiento-pago-id",
                  state: !_vm.$v.form.movimiento.estatusAdeudo.$invalid,
                  type: "text",
                  disabled: true,
                },
                model: {
                  value: _vm.form.movimiento.estatusAdeudo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "estatusAdeudo", $$v)
                  },
                  expression: "form.movimiento.estatusAdeudo",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.estatusAdeudo.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.indOpen &&
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo &&
      _vm.rolOperador
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { sm: "12", md: "12", lg: "12" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.form.movimiento?.montoAdeudo,
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.isModal()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.add")),
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon", { attrs: { icon: "save" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [
                      _c("mark", { staticClass: "text-primary" }, [
                        _c("em", [
                          _vm._v(
                            _vm._s(_vm.$t("apeironGwApp.movimiento.alertaPago"))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("apeironGwApp.movimiento.montoAdeudo") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  state: !_vm.$v.form.movimiento.montoAdeudo.$invalid,
                  type: "number",
                  name: "numero",
                  id: "numero",
                  disabled: _vm.indOpen || !_vm.rolOperador,
                },
                model: {
                  value: _vm.form.movimiento.montoAdeudo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.movimiento, "montoAdeudo", _vm._n($$v))
                  },
                  expression: "form.movimiento.montoAdeudo",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "select-motivo-feedback" } },
                [
                  !_vm.$v.form.movimiento.montoAdeudo.required
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form?.movimiento?.tipo?.clave === this.cveAdeudo &&
      _vm.form?.movimiento?.pagosAdeudos
        ? _c(
            "b-row",
            {
              attrs: { label: _vm.$t("apeironGwApp.movimiento.pagosAdeudos") },
            },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c(
                  "div",
                  { staticClass: "card mt-12" },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { rounded: "sm" } },
                      [
                        _c("b-table", {
                          attrs: {
                            "sticky-header": "500px",
                            fields: _vm.configuracionColumnasPagos,
                            items: _vm.form.movimiento.pagosAdeudos,
                            "head-variant": "dark",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(accion)",
                                fn: function ({ item }) {
                                  return [
                                    !_vm.indOpen &&
                                    _vm.rolOperador &&
                                    _vm.verificarUltimoRegistro(item)
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editar(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-pencil-square", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.indOpen &&
                                    _vm.rolOperador &&
                                    _vm.verificarUltimoRegistro(item)
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.eliminar(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-trash-fill", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3988241717
                          ),
                        }),
                        _vm._v(" "),
                        _c("b-card-text", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("apeironGwApp.asignacion.totalEval", {
                                total: _vm.form.movimiento.pagosAdeudos.length,
                              })
                            ) +
                              "\n            " +
                              _vm._s(
                                _vm.$t("apeironGwApp.movimiento.total", {
                                  total: _vm.talPago,
                                })
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.solicitud?.vigenciasSni
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("h4", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("dictamen.vigenciaPropuesta")),
                    },
                  }),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.solicitud?.vigenciasSni
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("dictamen.inicioVigencia") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-movimiento-id",
                  type: "text",
                  disabled: true,
                },
                model: {
                  value: _vm.fechaInicioVigencia,
                  callback: function ($$v) {
                    _vm.fechaInicioVigencia = $$v
                  },
                  expression: "fechaInicioVigencia",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.solicitud?.vigenciasSni
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.$t("dictamen.finVigencia") } },
            [
              _c("b-form-input", {
                staticClass: "w-100",
                attrs: {
                  id: "select-movimiento-id",
                  type: "text",
                  disabled: true,
                },
                model: {
                  value: _vm.fechaFinVigencia,
                  callback: function ($$v) {
                    _vm.fechaFinVigencia = $$v
                  },
                  expression: "fechaFinVigencia",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.indOpen
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { sm: "12", md: "12", lg: "12" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "v-if":
                          _vm.form?.movimiento?.tipo?.clave ===
                            this.cveJuicio &&
                          _vm.form?.movimiento?.historicoJuicioProceso &&
                          _vm.form?.movimiento?.historicoJuicioProceso.length >
                            0,
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.verHistorico()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.movimiento.verHist")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon", { attrs: { icon: "save" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.desactivarBoton,
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.guardar()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.save")),
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon", { attrs: { icon: "save" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$v.form.movimiento.$invalid
                    ? _c("div", [
                        _c("small", { staticClass: "text-danger" }, [
                          _c("em", [
                            _vm._v(
                              _vm._s(_vm.$t("entity.validation.anyrequired"))
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-pago",
            "hide-footer": "",
            size: "lg",
            title: _vm.$t("apeironGwApp.movimiento.pagosAdeudos"),
          },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-8" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("apeironGwApp.movimiento.montoPagado"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "w-100",
                        attrs: {
                          state: !_vm.$v.form.movimiento.tipo.$invalid,
                          type: "number",
                          name: "numero",
                          id: "numero",
                          disabled: _vm.indOpen,
                        },
                        model: {
                          value: _vm.pagoAdeudo.montoPagado,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagoAdeudo, "montoPagado", _vm._n($$v))
                          },
                          expression: "pagoAdeudo.montoPagado",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("apeironGwApp.movimiento.mesPago"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "w-100",
                        attrs: {
                          id: "movimiento-pago-fecha",
                          type: "date",
                          disabled: _vm.indOpen,
                        },
                        model: {
                          value: _vm.pagoAdeudo.mesPago,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagoAdeudo, "mesPago", $$v)
                          },
                          expression: "pagoAdeudo.mesPago",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            disabled:
                              !_vm.pagoAdeudo?.montoPagado ||
                              !_vm.pagoAdeudo?.mesPago,
                          },
                          on: { click: _vm.agregarPago },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("entity.action.accept")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-primary",
                          on: { click: _vm.isModal },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("entity.action.cancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-pago",
            "hide-footer": "",
            size: "lg",
            title: _vm.$t("apeironGwApp.movimiento.historicoJuicios"),
          },
          model: {
            value: _vm.modalShowJucio,
            callback: function ($$v) {
              _vm.modalShowJucio = $$v
            },
            expression: "modalShowJucio",
          },
        },
        [
          _vm.form?.movimiento?.tipo?.clave === this.cveJuicio &&
          _vm.form?.movimiento?.historicoJuicioProceso
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "card mt-12" },
                      [
                        _c(
                          "b-overlay",
                          { attrs: { rounded: "sm" } },
                          [
                            _c("b-table", {
                              attrs: {
                                "sticky-header": "500px",
                                fields: _vm.columnasHistoricoProcesoJuicios,
                                items:
                                  _vm.form.movimiento.historicoJuicioProceso,
                                "head-variant": "dark",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-card-text", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("apeironGwApp.asignacion.totalEval", {
                                    total:
                                      _vm.form.movimiento.historicoJuicioProceso
                                        .length,
                                  })
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }