import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import { Filtro, FiltroPropiedad } from '@/shared/model/filtro.model';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import SolucionService from '@/entities/solucion/solucion.service';
import SolicitudService from '@/entities/solicitud/solicitud.service';
import InstitucionService from '@/entities/institucion/institucion.service';

import AlertService from '@/shared/alert/alert.service';
import { IFiltroInstitucionAprobador, FiltroInstitucionAprobador } from '@/shared/model/filtroInstitucionAprobador.model';
import { SubDependencia } from '@/shared/model/sub-dependencia.model';
import { Departamento } from '@/shared/model/departamento.model';
import { TipoAcreditacion, ITipoAcreditacion } from '@/shared/model/tipo-acreditacion.model';
import PersonaService from '@/shared/persona/persona.service';

@Component
export default class FiltroSolicitudesComponent extends Vue {
  [x: string]: any;
  @Inject('solucionService') private solucionService: () => SolucionService;
  @Inject('solicitudService') public solicitudService: () => SolicitudService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('institucionService') public institucionService: () => InstitucionService;
  @Inject('personaService') public personaService: () => PersonaService;

  @Prop({ required: false })
  public value: Filtro;

  @Prop({ required: true })
  public solucionesOptions: any;

  @Prop({ required: false })
  public userLogin: string;

  @Prop({ required: true })
  estadosDisponibles: [string];

  public isNavbarVisible = true;

  public isTipoAcreditacion: ITipoAcreditacion = new TipoAcreditacion();
  public isSaving = false;
  public cvuAprobador: string;
  public filtroInstitucion: IFiltroInstitucionAprobador = new FiltroInstitucionAprobador();
  //temporal start
  public isDisabledInforme = false;
  //temporal end
  public isFetching = false;

  get filtro() {
    return this.value;
  }

  set filtro(filtro: Filtro) {
    this.$emit('input', filtro);
  }

  columnas = [];

  mounted(): void {
    if (this.filtro.solucionId != null) {
      this.validarTipoSolucion(this.filtro.solucionId);
    }
  }

  public handleSolucionSelected(solucionId: any) {
    const solucionSelected = this.solucionesOptions.find(sol => sol.id === solucionId);
    this.columnas = solucionSelected.vistaResumen?.columnas.filter(columna => columna.filter);
    const propiedades = [];
    this.columnas.forEach(columna => {
      const property = new FiltroPropiedad();
      property.nombre = columna.nombre;
      property.path = columna.path;
      property.valor = columna.valor;
      property.tipoReglaFiltro = columna.tipoReglaFiltro;
      property.filtroValores = columna.filtroValores;
      propiedades.push(property);
    });
    this.filtro.propiedades = propiedades;
    this.filtro.estadosSolicitud = null;
    this.$emit('change', solucionSelected);
    this.validarTipoSolucion(solucionId);
  }

  public async getInstitucionByCVU() {
    const paginationQuery = {
      page: 1,
      size: 10,
      sort: ' id , desc ',
    };
    const cvu = await this.obtenerCvuPersona();
    const informeRes = await this.institucionService().getInstitucionByAprobador(paginationQuery, cvu);
    this.filtroInstitucion.instituciones = informeRes;
    if (this.filtroInstitucion.instituciones != null && this.filtroInstitucion.instituciones.length >= 1) {
      this.filtroInstitucion.institucion = this.filtroInstitucion.instituciones[0];
      this.filtroInstitucion.cvuAprobador = cvu;
    }
    this.onChangeInstitucion();
  }

  public handleAplicarFiltro() {
    this.$emit('filter');
    this.buscarByEmail();
  }

  public onChangeInstitucion() {
    this.$emit('institucionChange', this.filtroInstitucion);
  }

  public onChangeDependencia() {
    this.filtroInstitucion.subDependencia = new SubDependencia();
    this.filtroInstitucion.departamento = new Departamento();
    this.onChangeInstitucion();
  }

  public onChangeSubDependencia() {
    this.filtroInstitucion.departamento = new Departamento();
    this.onChangeInstitucion();
  }

  public openFilter() {
    this.isNavbarVisible = true;
  }

  public closeFilter() {
    this.isNavbarVisible = false;
  }

  public showModalInformeMensual() {
    (<any>this.$refs.informeMensualModal).show();
  }

  public hideModalInformeMensual() {
    (<any>this.$refs.informeMensualModal).hide();
  }

  private async obtenerCvuPersona(): Promise<string> {
    try {
      const personaFisicaPerfil = await this.personaService().findByLogin(this.userLogin);
      return personaFisicaPerfil?.cvu;
    } catch (error) {
      this.alertService().showHttpError(this, error);
    }
  }
  //temporal start
  public async InformeMensual() {
    this.isFetching = true;

    try {
      const cvu = await this.obtenerCvuPersona();
      if (this.solucion.tipo && this.solucion.tipo == TipoSolucion.ACREDITACIONES) {
        const informeRes = await this.solicitudService().informeMensualConInstitucion(this.solucion.id, cvu);
      } else {
        const informeRes = await this.solicitudService().informeMensualSinInstitucion(this.solucion.id);
      }
      const message = this.$t('archeApp.solicitud.infomeMensualGenerado');
      const title = this.$t('archeApp.solicitud.infomeMensual');
      this.alertService().showToaster(this, title.toString(), message.toString(), 'success');
      this.hideModalInformeMensual();
      this.isFetching = false;
    } catch (error) {
      this.isFetching = false;
      this.hideModalInformeMensual();

      if (error.response) {
        const status = error.response.status;

        if (status === 404 || status === 400) {
          const message = this.$t('archeApp.solicitud.alertInformeMensaje');
          const title = this.$t('apeironGwApp.institucion.mensaje.tituloInfo');
          this.alertService().showToaster(this, title.toString(), message.toString(), 'warning');
        } else {
          this.alertService().showHttpError(this, error.response);
        }
      } else {
        this.alertService().showHttpError(this, error);
      }
    }
  }
  //temporal end

  private async validarTipoSolucion(idSolucion: any) {
    if (!idSolucion) return;

    try {
      const result = await this.solucionService().find(idSolucion);

      this.solucion = result;

      const isAcreditacion =
        this.solucion &&
        (this.solucion.tipo === TipoSolucion.ACREDITACIONES ||
          this.solucion.tipo === TipoSolucion.ACREDITACIONES_SIN_INST ||
          this.solucion.tipo === TipoSolucion.ACREDITACIONES_PROGRAMAS);

      this.isTipoAcreditacion.isTipoAcreditacion = isAcreditacion;
      //temporal start
      const fechaActual = new Date();
      const fechaFinRevision = new Date(this.solucion.calendario.fechaFinRevision);

      this.isDisabledInforme = fechaFinRevision !== null && fechaFinRevision < fechaActual;
      //temporal end
      if (isAcreditacion) {
        this.isTipoAcreditacion.isTipoAcreditacionConInst = this.solucion.tipo === TipoSolucion.ACREDITACIONES;
        this.isTipoAcreditacion.isTipoAcreditacionSinInst = this.solucion.tipo === TipoSolucion.ACREDITACIONES_SIN_INST;

        if (this.isTipoAcreditacion.isTipoAcreditacionConInst) {
          this.getInstitucionByCVU();
        }
      } else {
        this.isTipoAcreditacion.isTipoAcreditacionConInst = false;
        this.isTipoAcreditacion.isTipoAcreditacionSinInst = false;
      }
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
    }
  }
}
