import { render, staticRenderFns } from "./select-transition-elements.vue?vue&type=template&id=25a4ebb5&scoped=true&"
import script from "./select-transition-elements.component.ts?vue&type=script&lang=ts&"
export * from "./select-transition-elements.component.ts?vue&type=script&lang=ts&"
import style0 from "./select-transition-elements.vue?vue&type=style&index=0&id=25a4ebb5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a4ebb5",
  null
  
)

export default component.exports