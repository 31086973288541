import { HistoricoPagos } from '@conacyt/historicopersonasms-client';
import XLSX from 'xlsx';

interface ConsultaHistoricoPagos {
  expedienteInvestigador: string;
  mes: string;
  anio: string;
  nivel: string;
  ubicacion: string;
  sucursal: string;
  cuenta: string;
  monto: string;
  montoPagar: string;
  seguros: string;
  cuentaCLABE: string;
  cvuInvestigador: string;
  expedienteAyudante: string;
  cvuAyudante: string;
  origen: string;
  claveNomina: string;
  curp: string;
  estatusPago: string;
}

interface ConsultaHistoricoPagosExcel {
  expedienteInvestigador: string;
  mes: string;
  anio: string;
  nivel: string;
  ubicacion: string;
  sucursal: string;
  cuenta: string;
  monto: number;
  montoPagar: number;
  seguros: number;
  cuentaCLABE: string;
  cvuInvestigador: string;
  expedienteAyudante: string;
  cvuAyudante: string;
  origen: string;
  claveNomina: string;
  curp: string;
  estatusPago: string;
}
interface ConsultaHistoricoPagosExcelExpediente {
  anio: string;
  nivel: string;
  ubicacion: string;
  sucursal: string;
  cuentaCLABE: string;
  mes: string;
  monto: number;
  estatusPago: string;
}
export default class Utilidades {
  public regExpNumero = /^[1-9]\d*$/;
  public regExpClavePrograma = /^\d{6}$/;
  public regExpPorcentaje = /^(100(\.00?)?|[1-9]\d?(\.\d{0,2})?)$/;
  public regExpMonto = /^(?!0\d{6,})([1-9]\d{0,6}|0)(\.\d{0,2})?$/;

  /**
   * Método para construir la consulta con la información del histórico de pagos.
   */
  public construirHistoricoPagos(historicoPagos: HistoricoPagos): ConsultaHistoricoPagos {
    return {
      cvuInvestigador: historicoPagos?.cvuInvestigador,
      cvuAyudante: historicoPagos?.cvuAyudante,
      mes: historicoPagos?.nivel ? this.convertirFormatoMes(historicoPagos?.mes) : '',
      anio: historicoPagos?.anio,
      nivel: historicoPagos?.nivel ? this.convertirFormatoNivelSni(historicoPagos?.nivel) : '',
      ubicacion: historicoPagos?.ubicacion,
      sucursal: historicoPagos?.sucursal,
      cuenta: historicoPagos?.cuenta,
      cuentaCLABE: historicoPagos?.cuentaCLABE,
      monto: historicoPagos?.monto ? this.formatearMonto(historicoPagos?.monto) : '',
      montoPagar: historicoPagos?.montoPagar ? this.formatearMonto(historicoPagos?.montoPagar) : '',
      seguros: historicoPagos?.seguros ? this.formatearMonto(historicoPagos?.seguros) : '',
      claveNomina: historicoPagos?.claveNomina,
      curp: historicoPagos?.curp,
      origen: historicoPagos?.origen,
      expedienteInvestigador: historicoPagos?.expedienteInvestigador,
      expedienteAyudante: historicoPagos?.expedienteAyudante,
      estatusPago: historicoPagos?.estatusPago,
    };
  }

  /**
   * Método para construir la consulta para el excel con la información del histórico de pagos.
   */
  public construirHistoricoPagosExcel(historicoPagos: HistoricoPagos): ConsultaHistoricoPagosExcel {
    return {
      cvuInvestigador: historicoPagos?.cvuInvestigador,
      cvuAyudante: historicoPagos?.cvuAyudante,
      mes: historicoPagos?.mes,
      anio: historicoPagos?.anio,
      nivel: historicoPagos?.nivel ? this.convertirFormatoNivelSni(historicoPagos?.nivel) : '',
      ubicacion: historicoPagos?.ubicacion,
      sucursal: historicoPagos?.sucursal,
      cuenta: historicoPagos?.cuenta,
      cuentaCLABE: historicoPagos?.cuentaCLABE,
      monto: historicoPagos?.monto,
      montoPagar: historicoPagos?.montoPagar,
      seguros: historicoPagos?.seguros,
      claveNomina: historicoPagos?.claveNomina,
      curp: historicoPagos?.curp,
      origen: historicoPagos?.origen,
      expedienteInvestigador: historicoPagos?.expedienteInvestigador,
      expedienteAyudante: historicoPagos?.expedienteAyudante,
      estatusPago: historicoPagos?.estatusPago,
    };
  }

  /**
   * Método para construir la consulta para el excel con la información del histórico de pagos.
   */
  public construirHistoricoPagosExcelBuscadorExpediente(historicoPagos: HistoricoPagos): ConsultaHistoricoPagosExcelExpediente {
    return {
      anio: historicoPagos?.anio,
      nivel: historicoPagos?.nivel ? this.convertirFormatoNivelSni(historicoPagos?.nivel) : '',
      ubicacion: historicoPagos?.ubicacion,
      sucursal: historicoPagos?.sucursal,
      cuentaCLABE: historicoPagos?.cuentaCLABE,
      mes: historicoPagos?.nivel ? this.convertirFormatoMes(historicoPagos?.mes) : '',
      monto: historicoPagos?.monto,
      estatusPago: historicoPagos?.estatusPago,
    };
  }

  /**
   * Método para dar formato de moneda a una cantidad númerica.
   */
  public formatearMonto(monto: number): string {
    return monto
      ? monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
      : (0).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  }

  /**
   * Método para convertir una clave de nivelSni a su descripción.
   */
  public convertirFormatoNivelSni(claveNivelSni: string): string {
    switch (claveNivelSni) {
      case 'C':
      case 'C1':
      case 'C2':
        return 'CANDIDATO';
      case '1':
        return 'NIVEL I';
      case '2':
        return 'NIVEL II';
      case '3':
        return 'NIVEL III';
      case 'E':
        return 'EMÉRITO';
      case 'A':
        return 'AYUDANTE';
      case 'B':
        return 'BENEFICIARIO';
      case 'P':
        return 'PENSIONISTA';
      default:
        return claveNivelSni;
    }
  }

  /**
   * Método para convertir el mes .
   */
  public convertirFormatoMes(numeroMes: string): string {
    switch (numeroMes) {
      case '1':
        return 'ENERO';
      case '2':
        return 'FEBRERO';
      case '3':
        return 'MARZO';
      case '4':
        return 'ABRIL';
      case '5':
        return 'MAYO';
      case '6':
        return 'JUNIO';
      case '7':
        return 'JULIO';
      case '8':
        return 'AGOSTO';
      case '9':
        return 'SEPTIEMBRE';
      case '10':
        return 'OCTUBRE';
      case '11':
        return 'NOVIEMBRE';
      case '12':
        return 'DICIEMBRE';

      default:
        return numeroMes;
    }
  }

  /**
   * Método para filtrar las pulsaciones del teclado por expresión regular.
   */
  public filtrarPorExpresionRegular(event: KeyboardEvent, regex: RegExp): void {
    const key = event.key;
    const tempValue = (event.target as HTMLInputElement).value == null ? key : (event.target as HTMLInputElement).value + key;
    const isNumber = regex.test(tempValue);
    const isSpecial = ['Backspace', 'Enter', 'Delete'].includes(key);
    if (!isNumber && !isSpecial) {
      event.preventDefault();
    }
  }

  /**
   * Método para exportar en excel y si está definido columnasAFormatear,
   * formatea las columnas seleccionadas en formato moneda.
   */
  public exportarExcel(filas, nombre, columnasAFormatear = null) {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(filas);

    if (columnasAFormatear != null) {
      const range = XLSX.utils.decode_range(sheet['!ref']);
      for (let i = range.s.r + 1; i <= range.e.r; i++) {
        columnasAFormatear.forEach(nombreColumna => {
          const indiceColumna = Object.keys(filas[0]).indexOf(nombreColumna);
          const cellRef = XLSX.utils.encode_cell({ c: indiceColumna, r: i });
          sheet[cellRef].z = '"$"#,##0.00_);\\("$"#,##0.00\\)';
        });
      }
    }

    XLSX.utils.book_append_sheet(workbook, sheet, nombre);
    const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${this.obtenerFechaNombre() + '-' + nombre}.xlsx`;
    link.click();
  }

  /**
   * Método para obtener la fecha actual como nombre.
   */
  public obtenerFechaNombre() {
    const currentDate = new Date();
    return (
      currentDate.getFullYear() +
      '' +
      currentDate.getMonth() +
      '' +
      currentDate.getHours() +
      '' +
      currentDate.getMinutes() +
      '' +
      currentDate.getSeconds()
    );
  }

  /**
   * Método para obtener la edad a partir de una fecha de tipo string.
   */
  public calcularEdad(fechaNacimiento: string): number {
    const fechaNacimientoDate = new Date(fechaNacimiento);
    const hoy = new Date();
    let edad = hoy.getFullYear() - fechaNacimientoDate.getFullYear();
    const mesActual = hoy.getMonth();
    const diaActual = hoy.getDate();
    const mesNacimiento = fechaNacimientoDate.getMonth();
    const diaNacimiento = fechaNacimientoDate.getDate();

    if (mesActual < mesNacimiento || (mesActual === mesNacimiento && diaActual < diaNacimiento)) {
      edad--;
    }
    return edad;
  }

  public calcularTotalPaginas(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  /**
   * Método para dar el formato: 99,999.
   *
   * @param value valor a dar formato.
   * @returns valor con formato.
   */
  public formatearConComasSinDecimales(value): string {
    return value
      ? value.toLocaleString('es-MX', {
          maximumFractionDigits: 0,
        })
      : (0).toLocaleString('es-MX', {
          maximumFractionDigits: 0,
        });
  }
}
