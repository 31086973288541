import { Component, Prop, Vue } from 'vue-property-decorator';
import { Screen, Node, Edge, Group, Label, graph } from 'vnodes';

@Component({
  components: {
    Screen,
    Node,
    Edge,
    Group,
    'v-label': Label,
  },
})
export default class GraphComponent extends Vue {
  mounted() {
    this.graph.createNode('En captura');
    this.graph.createNode('Enviada');
    this.graph.createNode({
      id: 'Cancelada',
      x: 450,
      y: 100,
    });
    this.graph.createEdge({
      from: 'En captura',
      to: 'Enviada',
      fromAnchor: 'right',
      toAnchor: 'left',
      type: 'smooth',
    });
    this.graph.createEdge({
      from: 'Enviada',
      to: 'En captura',
      fromAnchor: 'right',
      toAnchor: 'left',
      type: 'smooth',
    });
    this.$nextTick(() => {
      (this.$refs.screen as any).zoomNodes(this.graph.nodes);
    });
  }
  public graph = new graph();
  public perc = 50;
  public offsetX = 0;
  public offsetY = 0;
  public align = 'center';
  public rotate = false;
  public connector = true;
  public contents = '<small style="padding: 10px">Enviar</small>';

  public resizeLabel() {
    (this.$refs.label as any).$refs.node.fitContent();
  }

  public onDrag(d) {
    this.offsetX += d.x || 0;
    this.offsetY += d.y || 0;
    this.offsetX = Math.max(Math.min(this.offsetX, 100), -100);
    this.offsetY = Math.max(Math.min(this.offsetY, 100), -100);
  }

  @Prop({ default: null })
  public title: string;

  @Prop({ default: null })
  public description: string;

  @Prop({ default: null })
  public footer: string;

  @Prop({ default: 'primary' })
  public variant: string;

  get icon() {
    if (this.variant === 'success') {
      return 'check-circle-fill';
    } else if (this.variant === 'warning' || this.variant === 'danger') {
      return 'exclamation-triangle-fill';
    } else {
      return 'info-circle-fill';
    }
  }
}
