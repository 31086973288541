import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SelectManyComponent extends Vue {
  @Prop({ required: true })
  public id!: string;

  @Prop({ required: true })
  public value!: any[];

  @Prop({ required: true })
  public label!: string;

  @Prop({ required: true })
  public items!: any[];

  public isFirst = true;

  public isLoading = false;

  get options() {
    const options = [];
    this.items.forEach(item => {
      options.push({ text: item.nombre, value: item.clave });
    });
    return options;
  }

  get selected() {
    if (!this.value) return [];
    return this.value.map(o => o.clave);
  }

  set selected(selected: any[]) {
    this.$emit(
      'input',
      selected.map(clave => this.items.find(item => item.clave === clave))
    );
  }
}
