var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "h2",
        { attrs: { id: "page-heading", "data-cy": "AutoridadesHeading" } },
        [
          _c("span", { attrs: { id: "solicitud-heading" } }, [
            _vm._v(_vm._s(_vm.$t("archeApp.solucion.seccion.autoridad.title"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "primary" },
                  on: { click: _vm.handleOpenAddAutoridadModal },
                },
                [
                  _c("b-icon", { attrs: { icon: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("entity.action.add")))]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.autoridades
                ? _c("export-from-json", {
                    attrs: {
                      data: _vm.autoridades,
                      "file-name": "autoridades",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          items: _vm.autoridades,
          fields: _vm.campos,
          "show-empty": "",
          busy: _vm.busy,
          "head-variant": "primary",
        },
        scopedSlots: _vm._u([
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-danger my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("global.process.load.loading"))),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "h4 text-center" }, [
                  _vm._v(_vm._s(_vm.$t("entity.data.blank"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(roles)",
            fn: function (row) {
              return _vm._l(row.item.roles, function (rol, index) {
                return _c(
                  "b-badge",
                  {
                    key: index,
                    staticClass: "m-1",
                    attrs: { pill: "", variant: "primary" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("apeironGwApp.RolAutoridad." + rol)) +
                        "\n      "
                    ),
                  ]
                )
              })
            },
          },
          {
            key: "cell(acciones)",
            fn: function (row) {
              return [
                _vm.isRemovable(row.item)
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mr-1",
                        attrs: { variant: "danger", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.handlePrepareToRemove(row.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("entity.action.delete")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(nombre)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      row.item.nombre +
                        " " +
                        row.item.apellidoPaterno +
                        " " +
                        row.item.apellidoMaterno
                    ) +
                    "\n    "
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "addAutoridad", attrs: { size: "lg", id: "addAutoridadModal" } },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("archeApp.solucion.seccion.autoridad.action.assign")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "text-center",
                  attrs: {
                    id: "componente-add-autoridad",
                    size: "sm",
                    label: "",
                    "label-for": "add-autoridad",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3" },
                    [
                      _c("b-form-input", {
                        model: {
                          value: _vm.userLogin,
                          callback: function ($$v) {
                            _vm.userLogin =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "userLogin",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.isSearching,
                                variant: "primary",
                              },
                              on: { click: _vm.handleFindAutoridadByLogIn },
                            },
                            [_vm._v("Buscar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-alert",
                    { attrs: { variant: "warning", show: _vm.userNotFound } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("global.process.search.empty")) + " "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.userLogin))]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.personaCvu
                ? _c("b-card", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.cvu"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.personaCvu.cvu)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.login"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.personaCvu.login)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.nombre"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.personaCvu.nombre +
                              _vm.personaCvu.apellidoPaterno +
                              _vm.personaCvu.apellidoMaterno
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-card",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "componente-add-roles",
                        size: "sm",
                        label: "Roles",
                        "label-for": "add-roles",
                      },
                    },
                    [
                      _c("b-form-checkbox-group", {
                        attrs: {
                          id: "add-roles",
                          options: _vm.rolesAutoridadValues,
                        },
                        model: {
                          value: _vm.autoridad.roles,
                          callback: function ($$v) {
                            _vm.$set(_vm.autoridad, "roles", $$v)
                          },
                          expression: "autoridad.roles",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c("button", {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
              on: {
                click: function ($event) {
                  return _vm.handleCloseAddAutoridadModal()
                },
              },
            }),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn btn-primary",
              attrs: {
                disabled: !_vm.personaCvu,
                type: "button",
                id: "jhi-confirm-delete-solicitud",
                "data-cy": "entityConfirmAddAutoridadButton",
              },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.save")) },
              on: {
                click: function ($event) {
                  return _vm.handleAddAutoridad()
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmRemoveModal",
          attrs: { "header-bg-variant": "primary" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("entity.delete.title")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("entity.delete.question")))]),
            _vm._v("\n      " + _vm._s(_vm.autoridad.usuarioId) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }