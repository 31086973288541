var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { id: "conciliacion", fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h4", { staticClass: "title mt-4 mb-4" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("apeironGwApp.conciliacionExtranjeras.title-apoyos")
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.apoyo.apoyo-obtener"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.datos_conciliados.apoyo.tipoApoyo.nombre,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.datos_conciliados.apoyo.tipoApoyo,
                          "nombre",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.datos_conciliados.apoyo.tipoApoyo.nombre",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.apoyo.pais"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value: _vm.solicitud.datos_conciliados.apoyo.pais.nombre,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.datos_conciliados.apoyo.pais,
                          "nombre",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.datos_conciliados.apoyo.pais.nombre",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.apoyo.institucion"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.datos_conciliados.apoyo.institucion
                          .nombre,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.datos_conciliados.apoyo.institucion,
                          "nombre",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.datos_conciliados.apoyo.institucion.nombre",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.apoyo.programa"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value: _vm.solicitud.datos_conciliados.apoyo.programa,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.datos_conciliados.apoyo,
                          "programa",
                          $$v
                        )
                      },
                      expression: "solicitud.datos_conciliados.apoyo.programa",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h4", { staticClass: "title mt-4 mb-4" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.title-periodos"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.periodos.inicio-estudios"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.SOLICITUD_RIZOMA.periodo
                          .fechaInicioEstudios,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.SOLICITUD_RIZOMA.periodo,
                          "fechaInicioEstudios",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.SOLICITUD_RIZOMA.periodo.fechaInicioEstudios",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.periodos.fin-estudios"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.SOLICITUD_RIZOMA.periodo.fechaFinEstudios,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.SOLICITUD_RIZOMA.periodo,
                          "fechaFinEstudios",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.SOLICITUD_RIZOMA.periodo.fechaFinEstudios",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.periodos.inicio-beca"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.SOLICITUD_RIZOMA.periodo.fechaInicioBeca,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.SOLICITUD_RIZOMA.periodo,
                          "fechaInicioBeca",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.SOLICITUD_RIZOMA.periodo.fechaInicioBeca",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.periodos.fin-beca"
                    ),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { readonly: true },
                    model: {
                      value:
                        _vm.solicitud.SOLICITUD_RIZOMA.periodo.fechaFinBeca,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.solicitud.SOLICITUD_RIZOMA.periodo,
                          "fechaFinBeca",
                          $$v
                        )
                      },
                      expression:
                        "solicitud.SOLICITUD_RIZOMA.periodo.fechaFinBeca",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [_c("b-col", [_c("div", { staticClass: "border-custom" })])],
        1
      ),
      _vm._v(" "),
      _vm.solicitud.SOLICITUD_RIZOMA.rubros.length > 0
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("h4", { staticClass: "title mt-4 mb-4" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.title-rubros"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-row",
                        _vm._l(
                          _vm.solicitud.SOLICITUD_RIZOMA.rubros,
                          function (item) {
                            return _c(
                              "b-col",
                              { key: item.id, attrs: { md: "4" } },
                              [
                                _c("b-card-text", [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-bolder" },
                                    [_vm._v(_vm._s(item.concepto))]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.periodo) +
                                        " / " +
                                        _vm._s(item.moneda) +
                                        " / " +
                                        _vm._s(item.montoTotal)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "12" } }, [
                        _c("h4", { staticClass: "title mt-4 mb-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.conciliacionExtranjeras.seguro-medico.seguro-medico"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        _vm._l(
                          _vm.solicitud.SOLICITUD_RIZOMA.rubros,
                          function (item) {
                            return _c(
                              "b-row",
                              { key: item.id },
                              _vm._l(
                                _vm.solicitud.SOLICITUD_RIZOMA.rubros[
                                  _vm.solicitud.datos_conciliados.rubros
                                    .length - 1
                                ].montosPosteriores,
                                function (mp) {
                                  return _c(
                                    "b-col",
                                    { key: mp.id, attrs: { md: "4" } },
                                    [
                                      item.concepto == "SEGURO_MEDICO"
                                        ? _c(
                                            "b-card",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "sub-title":
                                                  _vm.$t(
                                                    "apeironGwApp.conciliacionExtranjeras.seguro-medico.anio"
                                                  ) +
                                                  " " +
                                                  mp.anio,
                                              },
                                            },
                                            [
                                              _c("b-card-text", [
                                                _vm.isOperador
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "apeironGwApp.conciliacionExtranjeras.seguro-medico.edad"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isOperador
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(mp.edad) + " /"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "apeironGwApp.conciliacionExtranjeras.seguro-medico.monto"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    " $ " + _vm._s(mp.monto)
                                                  ),
                                                ]),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("h4", { staticClass: "title mt-4 mb-4" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.title-rubros-autorizar"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-table", {
                    staticClass: "table-conciliacion",
                    attrs: {
                      striped: "",
                      hover: "",
                      fields: _vm.fields,
                      items: _vm.solicitud.datos_conciliados.rubros,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell()",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(data.item.concepto) +
                                  " " +
                                  _vm._s(data.item.periodo) +
                                  " " +
                                  _vm._s(data.item.divisa) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(anio_1)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "$", size: "sm" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      readonly: !_vm.isOperador || _vm.readOnly,
                                    },
                                    on: {
                                      keypress: _vm.numbersOnly,
                                      input: function ($event) {
                                        return _vm.validateInput(
                                          data.item.montos.anio_1,
                                          "monto"
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.formatInput(
                                          data.item.montos.anio_1,
                                          "monto"
                                        )
                                      },
                                    },
                                    model: {
                                      value: data.item.montos.anio_1.monto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          data.item.montos.anio_1,
                                          "monto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.montos.anio_1.monto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(anio_2)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "$", size: "sm" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      readonly: !_vm.isOperador || _vm.readOnly,
                                    },
                                    on: {
                                      keypress: _vm.numbersOnly,
                                      input: function ($event) {
                                        return _vm.validateInput(
                                          data.item.montos.anio_2,
                                          "monto"
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.formatInput(
                                          data.item.montos.anio_2,
                                          "monto"
                                        )
                                      },
                                    },
                                    model: {
                                      value: data.item.montos.anio_2.monto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          data.item.montos.anio_2,
                                          "monto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.montos.anio_2.monto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(anio_3)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "$", size: "sm" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      readonly: !_vm.isOperador || _vm.readOnly,
                                    },
                                    on: {
                                      keypress: _vm.numbersOnly,
                                      input: function ($event) {
                                        return _vm.validateInput(
                                          data.item.montos.anio_3,
                                          "monto"
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.formatInput(
                                          data.item.montos.anio_3,
                                          "monto"
                                        )
                                      },
                                    },
                                    model: {
                                      value: data.item.montos.anio_3.monto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          data.item.montos.anio_3,
                                          "monto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.montos.anio_3.monto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(anio_4)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "$", size: "sm" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      readonly: !_vm.isOperador || _vm.readOnly,
                                    },
                                    on: {
                                      keypress: _vm.numbersOnly,
                                      input: function ($event) {
                                        return _vm.validateInput(
                                          data.item.montos.anio_4,
                                          "monto"
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.formatInput(
                                          data.item.montos.anio_4,
                                          "monto"
                                        )
                                      },
                                    },
                                    model: {
                                      value: data.item.montos.anio_4.monto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          data.item.montos.anio_4,
                                          "monto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.montos.anio_4.monto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(anio_5)",
                          fn: function (data) {
                            return [
                              data.item.montos.anio_5
                                ? _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$", size: "sm" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          readonly:
                                            !_vm.isOperador || _vm.readOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.validateInput(
                                              data.item.montos.anio_5,
                                              "monto"
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.formatInput(
                                              data.item.montos.anio_5,
                                              "monto"
                                            )
                                          },
                                        },
                                        model: {
                                          value: data.item.montos.anio_5.monto,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data.item.montos.anio_5,
                                              "monto",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.item.montos.anio_5.monto",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4274477234
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-3" },
                    [
                      _c("b-card-text", [
                        _c(
                          "div",
                          { staticClass: "font-weight-bolder" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.conciliacionExtranjeras.institucion.convenio"
                                  )
                                ) +
                                "\n            "
                            ),
                            _vm.solicitud.datos_conciliados.apoyo.convenio
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "success" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.conciliacionExtranjeras.institucion.si"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-badge",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "danger" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.conciliacionExtranjeras.institucion.no"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", [_c("div", { staticClass: "border-custom" })]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h4", { staticClass: "title mt-4 mb-4" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "apeironGwApp.conciliacionExtranjeras.title-ependientes-economicos"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.solicitud.datos_conciliados.dependientesEconomicos.length > 0
            ? _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-row",
                    _vm._l(
                      _vm.solicitud.datos_conciliados.dependientesEconomicos,
                      function (item, i) {
                        return _c(
                          "b-col",
                          {
                            key: item.id,
                            attrs: { md: _vm.isOperador ? 6 : 12 },
                          },
                          [
                            _c(
                              "b-card",
                              { staticClass: "mb-3" },
                              [
                                _c("b-row", [
                                  _c("ul", { staticClass: "felx-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "left-felx" },
                                      [
                                        _c("b-card-text", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "font-weight-bolder",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.nombre) +
                                                  " " +
                                                  _vm._s(item.primerApellido) +
                                                  " " +
                                                  _vm._s(item.segundoApellido)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.parentesco
                                            ? _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.parentesco.nombre
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.isOperador
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.readOnly,
                                                expression: "!readOnly",
                                              },
                                            ],
                                            staticClass: "right-felx card-btn",
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editDependiente(
                                                          item,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "icon-editar",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "nombre-boton",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "entity.action.edit"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeModal(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "icon-eliminar",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "nombre-boton",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "entity.action.delete"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-3" },
                    [
                      _c("b-card-text", [
                        _c(
                          "div",
                          { staticClass: "font-weight-bolder" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.conciliacionExtranjeras.title-ependientes-economicos"
                                  )
                                )
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "float-right",
                                attrs: { variant: "danger" },
                              },
                              [_vm._v("No")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isOperador
        ? _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              staticClass: "mt-4",
            },
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "dependientes-economicos-esExtranjero-form",
                        label:
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.esExtranjero.label"
                          ) + " *",
                        state: _vm.$v.dependientesNuevo.esExtranjero.$dirty
                          ? !_vm.$v.dependientesNuevo.esExtranjero.$error
                          : null,
                      },
                    },
                    [
                      _c("b-form-radio-group", {
                        class: _vm.valid == null ? "" : "is-invalid",
                        attrs: {
                          id: "dependientes-economicos-esExtranjero-id",
                          options: _vm.nacionalidadOptions,
                          state: _vm.$v.dependientesNuevo.esExtranjero.$dirty
                            ? !_vm.$v.dependientesNuevo.esExtranjero.$error
                            : null,
                          disabled: _vm.nacionalidadDisabled || _vm.readOnly,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeNacionalidad($event)
                          },
                        },
                        model: {
                          value: _vm.$v.dependientesNuevo.esExtranjero.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dependientesNuevo.esExtranjero,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.dependientesNuevo.esExtranjero.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  !_vm.curpReadOnly
                    ? _c("input-text", {
                        attrs: {
                          id: "dependientes-economicos-curp-id",
                          label: _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.label"
                          ),
                          description: _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.description"
                          ),
                          placeholder: _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.placeholder"
                          ),
                          readonly: _vm.curpReadOnly || _vm.readOnly,
                          required: true,
                          valid: _vm.$v.dependientesNuevo.curp.$dirty
                            ? !_vm.$v.dependientesNuevo.curp.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.dependientesNuevo.curp.required,
                            requiredMessage: _vm.$t(
                              "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.validations.required"
                            ),
                            minValue: !_vm.$v.dependientesNuevo.curp.minLength,
                            minMessage: _vm.$t(
                              "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.dependientesNuevo.curp.maxLength,
                            maxMessage: _vm.$t(
                              "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.validations.maxMessage",
                              { max: "18" }
                            ),
                            regexValue: !_vm.$v.dependientesNuevo.curp.CURP,
                            regexMessage: _vm.$t(
                              "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.curp.validations.regexMessage"
                            ),
                          },
                        },
                        on: { input: _vm.capitalsOnly },
                        model: {
                          value: _vm.$v.dependientesNuevo.curp.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dependientesNuevo.curp,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.dependientesNuevo.curp.$model",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c("input-text", {
                    attrs: {
                      id: "dependientes-economicos-nombre-id",
                      label: _vm.$t(
                        "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.nombre.label"
                      ),
                      readonly: _vm.readOnly,
                      required: true,
                      valid: _vm.$v.dependientesNuevo.nombre.$dirty
                        ? !_vm.$v.dependientesNuevo.nombre.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.dependientesNuevo.nombre.required,
                        requiredMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.nombre.validations.required"
                        ),
                        minValue: !_vm.$v.dependientesNuevo.nombre.minLength,
                        minMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.nombre.validations.minMessage",
                          { min: "2" }
                        ),
                        maxValue: !_vm.$v.dependientesNuevo.nombre.maxLength,
                        maxMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.nombre.validations.maxMessage",
                          { max: "50" }
                        ),
                      },
                      maxCaracteres: 50,
                    },
                    model: {
                      value: _vm.$v.dependientesNuevo.nombre.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dependientesNuevo.nombre, "$model", $$v)
                      },
                      expression: "$v.dependientesNuevo.nombre.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c("input-text", {
                    attrs: {
                      id: "dependientes-economicos.apellido-paterno-id",
                      label: _vm.$t(
                        "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-paterno.label"
                      ),
                      readonly: _vm.readOnly,
                      required: true,
                      valid: _vm.$v.dependientesNuevo.primerApellido.$dirty
                        ? !_vm.$v.dependientesNuevo.primerApellido.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.dependientesNuevo.primerApellido.required,
                        requiredMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-paterno.validations.required"
                        ),
                        minValue:
                          !_vm.$v.dependientesNuevo.primerApellido.minLength,
                        minMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-paterno.validations.minMessage",
                          {
                            min: "2",
                          }
                        ),
                        maxValue:
                          !_vm.$v.dependientesNuevo.primerApellido.maxLength,
                        maxMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-paterno.validations.maxMessage",
                          {
                            max: "50",
                          }
                        ),
                      },
                      maxCaracteres: 50,
                    },
                    model: {
                      value: _vm.$v.dependientesNuevo.primerApellido.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.dependientesNuevo.primerApellido,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.dependientesNuevo.primerApellido.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c("input-text", {
                    attrs: {
                      id: "dependientes-economicos.apellido-materno-id",
                      label: _vm.$t(
                        "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-materno.label"
                      ),
                      readonly: _vm.readOnly,
                      required: true,
                      valid: _vm.$v.dependientesNuevo.segundoApellido.$dirty
                        ? !_vm.$v.dependientesNuevo.segundoApellido.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.dependientesNuevo.segundoApellido.required,
                        requiredMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-materno.validations.required"
                        ),
                        minValue:
                          !_vm.$v.dependientesNuevo.segundoApellido.minLength,
                        minMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-materno.validations.minMessage",
                          {
                            min: "2",
                          }
                        ),
                        maxValue:
                          !_vm.$v.dependientesNuevo.segundoApellido.maxLength,
                        maxMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.apellido-materno.validations.maxMessage",
                          {
                            max: "50",
                          }
                        ),
                      },
                      maxCaracteres: 50,
                    },
                    model: {
                      value: _vm.$v.dependientesNuevo.segundoApellido.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.dependientesNuevo.segundoApellido,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.dependientesNuevo.segundoApellido.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label:
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.nacimiento.label"
                          ) + " *",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { locale: _vm.locale },
                        on: { input: _vm.onFechaSeleccionada },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ inputValue, inputEvents }) {
                                return [
                                  _c(
                                    "input",
                                    _vm._g(
                                      {
                                        staticClass: "custom-date-picker",
                                        domProps: { value: inputValue },
                                      },
                                      inputEvents
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          298704136
                        ),
                        model: {
                          value:
                            _vm.$v.dependientesNuevo.fechaNacimiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dependientesNuevo.fechaNacimiento,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.dependientesNuevo.fechaNacimiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "dependientes-economicos-parentesco-id",
                      label: _vm.$t(
                        "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.parentesco.label"
                      ),
                      readonly: _vm.readOnly,
                      required: true,
                      options: _vm.parentescoOption,
                      valid: _vm.$v.dependientesNuevo.parentesco.$dirty
                        ? !_vm.$v.dependientesNuevo.parentesco.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.dependientesNuevo.parentesco.required,
                        requiredMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.parentesco.validations.required"
                        ),
                        regexMessage: _vm.$t(
                          "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.parentesco.validations.regexMessage"
                        ),
                      },
                    },
                    on: { change: _vm.sexoParentescoChange },
                    model: {
                      value: _vm.$v.dependientesNuevo.parentesco.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.dependientesNuevo.parentesco,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.dependientesNuevo.parentesco.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "dependientes-economicos-sexo-form",
                        label:
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.sexo.label"
                          ) + " *",
                        state: _vm.$v.dependientesNuevo.sexo.$dirty
                          ? !_vm.$v.dependientesNuevo.sexo.$error
                          : null,
                      },
                    },
                    [
                      _c("b-form-radio-group", {
                        class: _vm.valid == null ? "" : "is-invalid",
                        attrs: {
                          id: "dependientes-economicos-sexo-id",
                          options: _vm.sexoOptions,
                          state: _vm.$v.dependientesNuevo.sexo.$dirty
                            ? !_vm.$v.dependientesNuevo.sexo.$error
                            : null,
                          disabled: _vm.sexoDisabled || _vm.readOnly,
                        },
                        model: {
                          value: _vm.$v.dependientesNuevo.sexo.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dependientesNuevo.sexo,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.dependientesNuevo.sexo.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { md: "12" } }, [
                !_vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _vm.removeIndex !== null
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: { click: _vm.cancelaDependiente },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.cancel.label"
                                      )
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-cerrar" }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.addDependiente },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.save.label"
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("font-awesome-icon", {
                              staticClass: "mr-2",
                              attrs: { icon: "save" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("b-col", [_c("div", { staticClass: "border-custom" })]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.isOperador
            ? _c("b-col", [_c("div", { staticClass: "border-custom" })])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("comentarios-ajuste", {
        attrs: {
          id: "comentarios-ajuste-id",
          solicitudId: _vm.solicitud.id,
          solicitud: _vm.solicitud,
          isOperador: _vm.isOperador,
          readOnly: _vm.readOnly,
        },
        on: {
          comentarioSaved: function ($event) {
            return _vm.updateAjustesSolicitados()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.readOnly,
              expression: "!readOnly",
            },
          ],
        },
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              attrs: { md: "12" },
            },
            [
              !_vm.isOperador
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mt-4 mb-4",
                      attrs: { show: "", variant: "primary" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.actualizar-comentarios"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isOperador
                ? _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        name: "check-button",
                        button: "",
                        "button-variant": _vm.confirmo ? "success" : "warning",
                        disabled: _vm.disabledConfirmo,
                      },
                      model: {
                        value: _vm.confirmo,
                        callback: function ($$v) {
                          _vm.confirmo = $$v
                        },
                        expression: "confirmo",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.confirma-informacion"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.confirmo
                        ? _c("b", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.conciliacionExtranjeras.confirma.si"
                                  )
                                ) +
                                ") "
                            ),
                          ])
                        : _c("b", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.conciliacionExtranjeras.confirma.no"
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              attrs: { md: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "float-right mt-4" },
                [
                  !_vm.isOperador
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: !_vm.confirmo,
                          },
                          on: { click: _vm.confirmaDatos },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("entity.action.save")),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "save" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              attrs: { md: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "float-right mt-4" },
                [
                  _vm.isOperador
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.confirmaDatos },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("entity.action.save")),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "save" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "removeDependiente",
          attrs: {
            id: "removeDependiente",
            title: _vm.$t("apeironGwApp.conciliacionExtranjeras.confirmacion"),
          },
          on: { ok: _vm.deleteDependiente, hide: _vm.cancelModal },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.elimina"
                  )
                )
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "changeDependiente",
          attrs: {
            id: "changeDependiente",
            title: _vm.$t("apeironGwApp.conciliacionExtranjeras.informativo"),
            "ok-only": "",
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.dependiente-modifica"
                  )
                )
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }