import { Component, Prop, Vue } from 'vue-property-decorator';
import { PersonaFisica as PersonaFisicaBuscador } from '@conacyt/buscador-cvu';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { IPersona } from '@/shared/model/persona/persona.model';

@Component
export default class PersonaComponent extends Vue {
  public personaCvu: PersonaFisicaBuscador = null;

  @Prop({ required: true })
  public value: any;

  @Prop({ default: false })
  public saving: boolean;

  @Prop({ default: 'EN_CAPTURA' })
  public estadoSolicitud: EstadoSolicitud;

  @Prop({ default: false })
  public readOnly: boolean;

  public umas = [1, 2, 3];

  get persona() {
    return this.value;
  }

  set persona(persona: any) {
    this.$emit('input', persona);
  }

  public save() {
    this.$emit('save');
  }

  public isFormDisabled() {
    if (this.readOnly && EstadoSolicitud[this.estadoSolicitud] === EstadoSolicitud.RECLASIFICADA) {
      return false;
    }
    return this.readOnly;
  }

  get isReclasificacion() {
    return EstadoSolicitud[this.estadoSolicitud] === EstadoSolicitud.RECLASIFICADA;
  }

  /**
   * Manejador de evento para cuando se busca una persona
   * @param persona La persona a agregar
   */
  public agregarPersona(persona: IPersona) {
    this.persona = persona;
  }
}
