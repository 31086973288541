import { Solicitud } from '@/shared/model/solicitud.model';
import { PersonaFisica } from '@conacyt/buscador-cvu';
import { Component, Emit, Inject, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import ApeironComponent from '@/components/commons/apeiron-component.model';
import AcreditacionesComponent from '@/components/expediente-persona/acreditaciones/acreditaciones.vue';
import VigenciasSniComponent from '@/components/expediente-persona/vigencias-sni/vigencias-sni.vue';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import { StatusCodes } from 'http-status-codes';

const validations: any = {
  form: {
    investigador: {
      cvu: { required },
    },
  },
};

@Component({
  validations,
  components: {
    'vigencias-sni': VigenciasSniComponent,
    acreditaciones: AcreditacionesComponent,
  },
})
export default class DatosGeneralesAcreditacionesComponent extends ApeironComponent {
  @Inject('historicoPersonasApi') private historicoPersonasApi: () => HistoricoPersonasApi;

  @Prop({ required: false })
  public solicitud: Solicitud;

  public expediente: any = {};

  public form = { investigador: new PersonaFisica() };

  /**
   * Verifica si hay un solicitante en la solicitud y establece el investigador en el formulario.
   * Recupera el historial de la persona asociada al solicitante.
   *
   * @memberof DatosGeneralesAcreditacionesComponent
   */
  public mounted(): void {
    if (this.solicitud?.solicitante) {
      this.form.investigador = this.solicitud.solicitante;
      this.retriveExpedientePersona(this.solicitud?.solicitante?.cvu);
    }
  }

  /**
   * Recupera el historico de una persona dado un cvu
   *
   * @memberof DatosGeneralesAcreditacionesComponent
   */
  public retriveExpedientePersona(cvu: string): void {
    this.expediente = null;
    this.historicoPersonasApi()
      .getExpediente(cvu)
      .then((result: any) => {
        if (result) {
          this.expediente = result.data;
          this.personaUpdated(this.expediente);
        }
      })
      .catch((error: any) => {
        if (error.response.data.status === StatusCodes.NOT_FOUND) {
          this.alertService().showWarning(this, this.$t('apeironGwApp.expediente.noResultado', 'Falla').toString());
        } else {
          this.alertService().showError(this, this.$t('apeironGwApp.expediente.noCarga', 'Falla').toString());
        }
      });
  }

  /**
   * Emite un evento cuando se actualiza un expediente de persona.
   *
   * @memberof DatosGeneralesAcreditacionesComponent
   */
  @Emit('persona-updated')
  public personaUpdated(expediente) {
    return expediente;
  }
}
