var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.jobStatus
      ? _c(
          "div",
          [
            _c(
              "b-alert",
              { attrs: { show: "", variant: "light" } },
              [
                _c("b-icon", {
                  attrs: {
                    animation: _vm.animation,
                    "font-scale": "1.2",
                    icon: _vm.icon,
                    variant: _vm.variant,
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ml-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("apeironGwApp.EstatusJob.message." + _vm.jobStatus)
                    ),
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }