var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.mostrarTexto
              ? _c("h5", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("apeironGwApp.programa.obligatorio")
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "accordion", attrs: { role: "tablist" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-1",
                                  modifiers: { "accordion-1": true },
                                },
                              ],
                              attrs: { block: "", variant: "light" },
                            },
                            [
                              _c("h5", {
                                staticClass: "mb-0",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.programa.camposConocimiento.registrarCampo"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-1",
                            accordion: "accordion-lies",
                            role: "tabpanel",
                          },
                          model: {
                            value: _vm.mostrarSeccionRegistro,
                            callback: function ($$v) {
                              _vm.mostrarSeccionRegistro = $$v
                            },
                            expression: "mostrarSeccionRegistro",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.camposConocimiento.areaConocimiento"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select",
                                        {
                                          staticClass: "form-control",
                                          attrs: {
                                            disabled: !_vm.deshabilitarCampo,
                                            state:
                                              !_vm.$v.areaConocimiento.area
                                                .$invalid,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.obtenerCampos($event)
                                            },
                                          },
                                          model: {
                                            value: _vm.areaConocimiento.area,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.areaConocimiento,
                                                "area",
                                                $$v
                                              )
                                            },
                                            expression: "areaConocimiento.area",
                                          },
                                        },
                                        _vm._l(
                                          _vm.area,
                                          function (item, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: { value: item },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.nombre) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      !_vm.$v.areaConocimiento.area.required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.camposConocimiento.campo"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("b-form-select", {
                                        staticClass: "form-control",
                                        attrs: {
                                          options: _vm.catalogoCampo,
                                          disabled: _vm.campoLectura,
                                          state:
                                            !_vm.$v.areaConocimiento.campo
                                              .$invalid,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.obtenerDisciplinas(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.areaConocimiento.campo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaConocimiento,
                                              "campo",
                                              $$v
                                            )
                                          },
                                          expression: "areaConocimiento.campo",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.$v.areaConocimiento.campo.required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "line-break" }),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.camposConocimiento.disciplina"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("b-form-select", {
                                        staticClass: "form-control",
                                        attrs: {
                                          options: _vm.catalogoDisciplina,
                                          disabled: _vm.disciplinaLectura,
                                          state:
                                            !_vm.$v.areaConocimiento.disciplina
                                              .$invalid,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.obtenerSubDisciplinas(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.areaConocimiento.disciplina,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaConocimiento,
                                              "disciplina",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "areaConocimiento.disciplina",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.$v.areaConocimiento.disciplina
                                        .required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.camposConocimiento.subDisciplina"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("b-form-select", {
                                        staticClass: "form-control",
                                        attrs: {
                                          options: _vm.catalogoSubdisciplina,
                                          disabled: _vm.subdisciplinaLectura,
                                          state:
                                            !_vm.$v.areaConocimiento
                                              .subdisciplina.$invalid,
                                        },
                                        model: {
                                          value:
                                            _vm.areaConocimiento.subdisciplina,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaConocimiento,
                                              "subdisciplina",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "areaConocimiento.subdisciplina",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.$v.areaConocimiento.subdisciplina
                                        .required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "line-break" }),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { sm: "12", md: "12", lg: "12" },
                                    },
                                    [
                                      _vm.deshabilitarCampo
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "primary",
                                                disabled: _vm.activarBoton,
                                              },
                                              on: { click: _vm.agregarArea },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("entity.action.add")
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-icon", {
                                                attrs: { icon: "save" },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "line-break" }),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("strong", [
                                          _c(
                                            "mark",
                                            { staticClass: "text-primary" },
                                            [
                                              _c("em", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.programa.camposConocimiento.agregaCampo"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "line-break" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "line-break" }),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-2",
                                  modifiers: { "accordion-2": true },
                                },
                              ],
                              attrs: { block: "", variant: "light" },
                            },
                            [
                              _c("h5", {
                                staticClass: "mb-0",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.programa.camposConocimiento.listaCampos"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-2",
                            accordion: "accordion-lies",
                            role: "tabpanel",
                          },
                          model: {
                            value: _vm.mostrarLista,
                            callback: function ($$v) {
                              _vm.mostrarLista = $$v
                            },
                            expression: "mostrarLista",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c("b-table", {
                                attrs: {
                                  "sticky-header": "500px",
                                  fields: _vm.fieldsSolicitudes,
                                  items: _vm.areasConocimiento,
                                  "head-variant": "dark",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "cell(principal)",
                                    fn: function ({ item }) {
                                      return [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            disabled: !_vm.deshabilitarCampo,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.mostrarModalConfirmacionAreaPrincipal(
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.principal,
                                            callback: function ($$v) {
                                              _vm.$set(item, "principal", $$v)
                                            },
                                            expression: "item.principal",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(accion)",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "btn-group" },
                                          [
                                            _vm.deshabilitarCampo
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      variant: "outline-danger",
                                                      title: _vm.$t(
                                                        "apeironGwApp.programa.camposConocimiento.eliminar"
                                                      ),
                                                      disabled:
                                                        _vm.mostrarCheckPrincipal(
                                                          item
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.mostrarModalConfirmacionEliminarArea(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: { icon: "trash" },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line-break" }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { sm: "12", md: "12", lg: "12" },
                                },
                                [
                                  _vm.deshabilitarCampo
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            disabled:
                                              this.areasConocimiento.length ===
                                              0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.guardarSeccion()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("entity.action.save")
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-2",
                                            attrs: { icon: "save" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "line-break" }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmarModalEliminar",
          attrs: {
            title: _vm.$t(
              "apeironGwApp.programa.camposConocimiento.tituloModalEliminarCampo"
            ),
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "form-group" }, [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.programa.camposConocimiento.eliminarCampolista"
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "text-center justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmarAccionEliminarArea()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("entity.action.confirm")
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.ocultarModalEliminarArea()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.cancel")),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmarModalPrincipal",
          attrs: {
            title: _vm.$t(
              "apeironGwApp.programa.camposConocimiento.tituloModalAreaPrincipal"
            ),
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "form-group" }, [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.programa.camposConocimiento.areaPrincipal"
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "text-center justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmarAccionAreaPrincipal()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("entity.action.confirm")
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.ocultarModalAreaPrincipal()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.cancel")),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }