import { TipoComision } from './enumerations/tipo-comision.model';
import { TipoRevision } from './enumerations/tipo-revision.model';

export interface IDefinicionRevision {
  id?: string;
  descripcion?: string;
  tipoEvaluacion?: TipoRevision;
  tipoRevision?: TipoRevision;
  tipoComision?: TipoComision;
}

export interface IDefinicionEvaluacion {
  id?: string;
  revisiones?: IDefinicionRevision[];
}

export class DefinicionEvaluacion implements IDefinicionEvaluacion {
  constructor(public id?: string, public revisiones?: IDefinicionRevision[]) {}
}
