var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-5" },
    [
      _c("message", {
        staticClass: "mb-3",
        attrs: {
          description: _vm.$t("apeironGwApp.transicion.arguments.hint", {
            nombre: _vm.transitionElement.nombre,
          }),
          variant: "info",
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.argumentos, function (argumento, index) {
        return _c(
          "div",
          { key: index },
          [
            argumento.type === _vm.ArgumentType.STRING
              ? _c(
                  "b-form-group",
                  { attrs: { label: argumento.name } },
                  [
                    _c("b-form-input", {
                      staticClass: "w-50",
                      on: { change: _vm.handleChangeValue },
                      model: {
                        value: argumento.value,
                        callback: function ($$v) {
                          _vm.$set(argumento, "value", $$v)
                        },
                        expression: "argumento.value",
                      },
                    }),
                  ],
                  1
                )
              : argumento.type === _vm.ArgumentType.INTEGER ||
                argumento.type === _vm.ArgumentType.FLOAT
              ? _c(
                  "b-form-group",
                  { attrs: { label: argumento.name } },
                  [
                    _c("b-form-input", {
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      on: { change: _vm.handleChangeValue },
                      model: {
                        value: argumento.value,
                        callback: function ($$v) {
                          _vm.$set(argumento, "value", $$v)
                        },
                        expression: "argumento.value",
                      },
                    }),
                  ],
                  1
                )
              : argumento.type === _vm.ArgumentType.DATE
              ? _c(
                  "b-form-group",
                  { attrs: { label: argumento.name } },
                  [
                    _c("b-form-input", {
                      staticClass: "w-50",
                      attrs: { type: "date" },
                      on: { change: _vm.handleChangeValue },
                      model: {
                        value: argumento.value,
                        callback: function ($$v) {
                          _vm.$set(argumento, "value", $$v)
                        },
                        expression: "argumento.value",
                      },
                    }),
                  ],
                  1
                )
              : argumento.type === _vm.ArgumentType.DATE_TIME
              ? _c(
                  "b-form-group",
                  { attrs: { label: argumento.name } },
                  [
                    _c("date-time-picker", {
                      staticClass: "w-50",
                      on: { change: _vm.handleChangeValue },
                      model: {
                        value: argumento.value,
                        callback: function ($$v) {
                          _vm.$set(argumento, "value", $$v)
                        },
                        expression: "argumento.value",
                      },
                    }),
                  ],
                  1
                )
              : argumento.type === _vm.ArgumentType.PROPERTY_MAP
              ? _c(
                  "b-form-group",
                  { attrs: { label: argumento.name } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddPropertyMap(argumento)
                          },
                        },
                      },
                      [_vm._v("Agregar")]
                    ),
                    _vm._v(" "),
                    argumento?.value?.length > 0
                      ? _c("b-table", {
                          attrs: {
                            small: "",
                            "sticky-header": "",
                            responsive: "",
                            items: argumento.value,
                            fields: _vm.argumentTypeFields,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.isSaved
        ? _c("div", [
            _c(
              "i",
              [
                _c("b-icon", {
                  attrs: { variant: "primary", icon: "clock-history" },
                }),
                _vm._v(" "),
                _c("small", { staticClass: "font-weight-bold text-primary" }, [
                  _vm._v(
                    _vm._s(_vm.$t("entity.action.saved")) +
                      " " +
                      _vm._s(_vm._f("timeElapsed")(new Date()))
                  ),
                ]),
              ],
              1
            ),
          ])
        : _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSave()
                },
              },
            },
            [
              _c("b-icon", { attrs: { icon: "save" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("entity.action.save")))]),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }