import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { LieSnp, MenuClasificacionLieSnp, MenuInstitucionLieSnp, TemasLieSnp } from '@/entities/snp/lie/lie.model';
import CatalogosSnp from '@/entities/snp/catalogos/catalogos-snp.factory';
import SeleccionTablasComponent from '@/components/seleccion-tablas/seleccion-tablas.vue';
import { mixins } from 'vue-class-component';
import { v4 as uuidv4 } from 'uuid';

const validations = function () {
  return {
    lie: {
      nombreDeLinea: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250),
      },
      clasificacion: {
        required,
      },
      fechaAlta: {
        required,
      },
      temasPrioritariosPeciti: {
        required,
      },
      institucionCedeDependencia: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(25),
        maxLength: maxLength(2500),
      },
    },
  };
};

@Component({
  validations: validations,
  components: {
    seleccionTablas: SeleccionTablasComponent,
  },
})
export default class LieRegistroComponent extends mixins(ApeironComponent) {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({
    required: false,
    default: function () {
      return new LieSnp();
    },
  })
  public lieEdicion: LieSnp;

  public lie: LieSnp = new LieSnp();

  public instituciones: Array<MenuInstitucionLieSnp> = [];
  public clasificaciones: Array<MenuClasificacionLieSnp> = [];
  public inicialTemasPriorotariosPeciti: Array<TemasLieSnp> = [];
  public temasPrioritariosPeciti: Array<TemasLieSnp> = [];
  public comparacionTemasPriorotariosPeciti = ['areaPeciti', 'prioridadPeciti'];

  public mounted(): void {
    this.iniciarComponente(false);
  }

  @Watch('lieEdicion')
  public watchLieEdicion(): void {
    this.iniciarComponente(false);
  }

  public iniciarComponente(limpiarLie: boolean): void {
    this.clasificaciones = [];
    CatalogosSnp.obtenerClasificaciones().forEach(it => {
      this.clasificaciones.push({ value: it, text: it.nombre });
    });

    this.instituciones = [];

    if (this.solicitud?.programa?.instituciones) {
      this.solicitud.programa.instituciones.forEach(institucion => {
        institucion.sedes.forEach(sede => {
          this.instituciones.push({
            value: {
              claveInstitucion: institucion.claveInstitucion,
              nombreInstitucion: institucion.nombreInstitucion,
              sede: {
                claveSede: sede.claveSede,
                claveDependencia: sede.claveDependencia,
                nombreDependencia: sede.nombreDependencia,
                sede: sede.sede,
              },
            },
            text: institucion.nombreInstitucion + ' | ' + sede.sede + ' | ' + sede.nombreDependencia,
          });
        });
      });
    }

    if (this.lieEdicion && this.lieEdicion.id && !limpiarLie) {
      this.lie = this.lieEdicion;
      this.inicialTemasPriorotariosPeciti = this.lie.temasPrioritariosPeciti;
      let temasDisponibles = CatalogosSnp.obtenerTemasPrioritarios();
      this.lie.temasPrioritariosPeciti.forEach(tema => {
        temasDisponibles = temasDisponibles.filter(opcion => opcion.clavePrioridadPeciti != tema.clavePrioridadPeciti);
      });
      this.temasPrioritariosPeciti = temasDisponibles;
    } else {
      this.lie = new LieSnp();
      this.lie.id = uuidv4();
      this.lie.clasificacion = null;
      this.lie.institucionCedeDependencia = null;
      this.lie.activo = true;
      this.inicialTemasPriorotariosPeciti = [];
      this.temasPrioritariosPeciti = CatalogosSnp.obtenerTemasPrioritarios();
    }

    if (this.instituciones.length == 1) {
      this.lie.institucionCedeDependencia = this.instituciones[0].value;
    }
  }

  public limpiarDatos(): void {
    this.iniciarComponente(true);
  }

  public guardarLie(): void {
    if (!this.readOnly) {
      if (!this.formularioIsInvalid) {
        this.agregarLie();
      } else {
        this.alertService().showWarning(this, this.$t('apeironGwApp.lieSnp.form.formularioInvalido').toString());
      }
    }
  }

  @Emit('agregarLie')
  public agregarLie(): LieSnp {
    const lie = { ...this.lie };

    this.limpiarDatos();

    return lie;
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }

  get formularioIsInvalid(): boolean {
    return this.$v.$invalid;
  }
}
