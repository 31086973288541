var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.readOnly && _vm.autorizado
        ? _c(
            "b-input-group",
            { staticClass: "mt-5 mb-3 w-25" },
            [
              _c("b-form-input", {
                attrs: { placeholder: _vm.$t("global.process.search.action") },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm._v(" "),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        "aria-placeholder": "",
                        size: "sm",
                        variant: "primary",
                      },
                      on: { click: _vm.handleSearch },
                    },
                    [
                      _c("strong", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("global.process.search.action"))),
                      ]),
                      _c("b-icon", {
                        staticClass: "ml-2",
                        attrs: { icon: "search" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-overlay", { attrs: { show: _vm.isSearching } }, [
        _vm.expediente
          ? _c(
              "div",
              [
                _vm.showSection("datos-generales")
                  ? _c("datos-generales", {
                      attrs: {
                        visible: _vm.visible,
                        item: _vm.expediente,
                        misAyudantes: _vm.misAyudantes,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("vigencias")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.vigencias.sni.title"
                          ),
                        },
                      },
                      [
                        _c("vigencias-sni", {
                          attrs: { item: _vm.expediente.vigenciasSni },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("adeudos")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.excepciones.title"
                          ),
                        },
                      },
                      [
                        _c("excepciones", {
                          attrs: { adeudos: _vm.expediente.adeudos },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("acreditaciones")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.acreditaciones.title"
                          ),
                        },
                      },
                      [
                        _c("agreditaciones", {
                          attrs: { item: _vm.expediente.acreditaciones },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("participaciones")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.participaciones.evaluador.title"
                          ),
                        },
                      },
                      [
                        _c("participaciones-como-evaluador", {
                          attrs: {
                            item: _vm.expediente.participacionesEvaluador,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("historicoPagos")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.historicoPagos.title"
                          ),
                        },
                      },
                      [
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "header",
                                { staticClass: "bx-header-title mt-2" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bolder text-rizoma h3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.persona.expediente.historicoPagos.title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-success" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.descargarReporteExcel()
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-spreadsheet",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("entity.action.export")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-table", {
                                staticClass: "text-nowrap mt-3",
                                attrs: {
                                  responsive: "",
                                  borderless: "",
                                  "show-empty": "",
                                  small: "",
                                  "current-page": _vm.currentPage,
                                  "sort-direction": "asc",
                                  items: _vm.historicoPagos,
                                  fields: _vm.fields,
                                  "head-variant": "primary",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.totalRows,
                                  "per-page": _vm.perPage,
                                  align: "center",
                                  size: "sm",
                                  pills: "",
                                  "head-variant": "primary",
                                },
                                on: { change: _vm.handlePageChange },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("ayudantias")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.ayudantias.subtitle"
                          ),
                        },
                      },
                      [
                        _c("ayudantias", {
                          key: _vm.ayudantiasKey,
                          attrs: { item: _vm.expediente.ayudantias },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("mis-ayudantes")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.misAyudantes.subtitle"
                          ),
                        },
                      },
                      [
                        _c("mis-ayudantes", {
                          attrs: { item: _vm.misAyudantes },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSection("cuentas")
                  ? _c(
                      "collapse-item",
                      {
                        attrs: {
                          visible: _vm.visible,
                          title: _vm.$t(
                            "apeironGwApp.persona.expediente.cuentas.title"
                          ),
                        },
                      },
                      [
                        _c("cuentas-bancarias", {
                          attrs: { item: _vm.expediente.cuentasBancarias },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("message", {
                  attrs: {
                    variant: _vm.variant,
                    title: "Buscador de expediente de una persona",
                    description: _vm.mensaje,
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }