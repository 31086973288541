var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "title-block" }, [
              _c("h3", { staticClass: "title mt-4" }, [
                _c("span", {
                  staticClass: "sparkline bar",
                  attrs: { "data-type": "bar" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("apeironGwApp.reconsideracion.resultado")
                    ),
                  },
                }),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.solicitud?.reconsideracion?.uri
                ? _c(
                    "b-card",
                    { staticClass: "card-body mb-3" },
                    [
                      _c(
                        "b-overlay",
                        {
                          attrs: {
                            variant: "transparent",
                            show: _vm.isFetching,
                            "spinner-variant": "primary",
                            opacity: 1,
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "fieldset-9",
                                        label: _vm.$t(
                                          "apeironGwApp.insaculacion.nombre"
                                        ),
                                        "label-for": "nombreAct",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "nombreAct",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.solicitud.nombre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.solicitud,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression: "solicitud.nombre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "fieldset-10",
                                        label: _vm.$t(
                                          "apeironGwApp.insaculacion.cvu"
                                        ),
                                        "label-for": "cvuAct",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "cvuAct", disabled: "" },
                                        model: {
                                          value: _vm.solicitud.solicitante.cvu,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.solicitud.solicitante,
                                              "cvu",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitud.solicitante.cvu",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "fieldset-11",
                                        label: _vm.$t(
                                          "apeironGwApp.insaculacion.convocatoria"
                                        ),
                                        "label-for": "convocatoria",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "convocatoria",
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.solicitud.SOLICITUD_RIZOMA
                                              .solucionSolicitud
                                              .nombreConvocatoria,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.solicitud.SOLICITUD_RIZOMA
                                                .solucionSolicitud,
                                              "nombreConvocatoria",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitud.SOLICITUD_RIZOMA.solucionSolicitud.nombreConvocatoria",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "fieldset-3",
                                        label: _vm.$t(
                                          "apeironGwApp.insaculacion.nombreComision"
                                        ),
                                        "label-for": "nombreComision",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "nombreComision",
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.solicitud.SOLICITUD_RIZOMA
                                              .areaConocimiento.area.nombre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.solicitud.SOLICITUD_RIZOMA
                                                .areaConocimiento.area,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitud.SOLICITUD_RIZOMA.areaConocimiento.area.nombre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.esReconsideracion && _vm.verResultado
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", md: "12", lg: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "fieldset-4",
                                            label: _vm.$t(
                                              "apeironGwApp.reconsideracion.resultado"
                                            ),
                                            "label-for": "nombreComision",
                                          },
                                        },
                                        [
                                          _vm.revisionRecons?.respuesta
                                            ?.evaluacion?.nombre
                                            ? _c("b-form-input", {
                                                attrs: {
                                                  id: "resultado",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.revisionRecons.respuesta
                                                      .evaluacion.nombre,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.revisionRecons
                                                        .respuesta.evaluacion,
                                                      "nombre",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "revisionRecons.respuesta.evaluacion.nombre",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.esReconsideracion && _vm.verResultado
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", md: "12", lg: "12" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "fieldset-4",
                                                    label: _vm.$t(
                                                      "apeironGwApp.reconsideracion.fechaInicio"
                                                    ),
                                                    "label-for": "fechaInicio",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "fechaInicio",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vigenciasSniReconsideracion
                                                          .vigencia.fechaInicio,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vigenciasSniReconsideracion
                                                            .vigencia,
                                                          "fechaInicio",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vigenciasSniReconsideracion.vigencia.fechaInicio",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "fieldset-4",
                                                    label: _vm.$t(
                                                      "apeironGwApp.reconsideracion.fechaFin"
                                                    ),
                                                    "label-for": "fechaFin",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "fechaFin",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vigenciasSniReconsideracion
                                                          .vigencia.fechaFin,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vigenciasSniReconsideracion
                                                            .vigencia,
                                                          "fechaFin",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vigenciasSniReconsideracion.vigencia.fechaFin",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "6" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _vm.oficio
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "apeironGwApp.resultados.oficio"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            href: _vm.oficio,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "apeironGwApp.resultados.oficio"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _vm.reconocimiento
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "apeironGwApp.resultados.reconocimiento"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            href: _vm.reconocimiento,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "apeironGwApp.resultados.reconocimiento"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-card",
                    { attrs: { title: "Sin reconsideración" } },
                    [
                      _c("b-card-text", [
                        _c("h3", { staticClass: "title mt-4" }, [
                          _c(
                            "span",
                            {
                              staticClass: "sparkline bar",
                              attrs: { "data-type": "bar" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "apeironGwApp.sin-reconsideracion.texto"
                                  )
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n              Su solicitud no se encuentra en proceso de reconsideración.\n            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }