import { Component, Prop, Inject, Vue } from 'vue-property-decorator';

import AlertService from '@/shared/alert/alert.service';
import { FiltroPrograma } from '@/shared/model/filtro-programa.model';
import { StatusCodes } from 'http-status-codes';
import ProgramaService from '@/shared/programa/programa.service';

@Component
export default class ProgramaSelectComponent extends Vue {
  @Inject('programaService') public programaService: () => ProgramaService;
  @Inject('alertService') private alertService: () => AlertService;

  @Prop({ default: null })
  public value: string;

  @Prop({ default: () => new FiltroPrograma() })
  public filtro: FiltroPrograma;

  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = {};
  public isFetching = false;

  get programaSelected(): any {
    return this.value;
  }

  set programaSelected(programa: any) {
    this.$emit('input', programa);
  }

  form = { subSeccion: {} };

  public programas = [];
  public selected = null;
  hasProgramas = false;

  public mounted(): void {
    this.retrieveAll();
  }

  retrieveAll() {
    this.isFetching = true;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
      filter: this.filtro,
    };
    this.programaService()
      .retrieve(paginationQuery)
      .then(
        res => {
          this.programas = res.data.map(programa => {
            return { value: programa, text: programa.nombre };
          });
          this.hasProgramas = this.programas.length > 0;
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          if (err.response.data.status !== StatusCodes.NOT_FOUND) {
            this.alertService().showHttpError(this, err.response);
          }
        }
      );
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }
  get showSelectComponent() {
    return !this.isFetching && this.hasProgramas;
  }
  get showNoProgramsMessage() {
    return !this.isFetching && !this.hasProgramas;
  }
}
