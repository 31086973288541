var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "accordion", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true },
                            },
                          ],
                          attrs: { block: "", variant: "light" },
                        },
                        [
                          _c("h5", {
                            staticClass: "mb-0",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.programa.reestructuracion.registro"
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "accordion-lies",
                        role: "tabpanel",
                      },
                      model: {
                        value: _vm.mostrarSeccionRegistro,
                        callback: function ($$v) {
                          _vm.mostrarSeccionRegistro = $$v
                        },
                        expression: "mostrarSeccionRegistro",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.fechaReestructuracion"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "w-100",
                                        attrs: {
                                          id: "select-fecha-reestructuracion-id",
                                          type: "date",
                                          "value-as-date": "",
                                          state:
                                            !_vm.$v.reestructuracion
                                              .fechaReestructuracion.$invalid,
                                          disabled: !_vm.deshabilitarCampo,
                                          placeholder: _vm.$t(
                                            "apeironGwApp.programa.reestructuracion.fechaReestructuracion"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.reestructuracion
                                              .fechaReestructuracion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reestructuracion,
                                              "fechaReestructuracion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "reestructuracion.fechaReestructuracion",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.$v.reestructuracion
                                        .fechaReestructuracion.required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.fechaOperacion"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "w-100",
                                        attrs: {
                                          id: "select-fecha-inicio-operacion-id",
                                          type: "date",
                                          "value-as-date": "",
                                          state:
                                            !_vm.$v.reestructuracion
                                              .fechaInicioOperacion.$invalid,
                                          disabled: !_vm.deshabilitarCampo,
                                          placeholder: _vm.$t(
                                            "apeironGwApp.programa.reestructuracion.fechaOperacion"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.reestructuracion
                                              .fechaInicioOperacion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reestructuracion,
                                              "fechaInicioOperacion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "reestructuracion.fechaInicioOperacion",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.$v.reestructuracion
                                        .fechaInicioOperacion.required
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "entity.validation.required"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.textoFechaMayor
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.programa.reestructuracion.fechaMayor"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line-break" }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.antecedentesPrograma"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-textarea", {
                                    attrs: {
                                      state:
                                        !_vm.$v.reestructuracion
                                          .antecedentesPrograma.$invalid,
                                      disabled: !_vm.deshabilitarCampo,
                                    },
                                    model: {
                                      value:
                                        _vm.reestructuracion
                                          .antecedentesPrograma,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reestructuracion,
                                          "antecedentesPrograma",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "reestructuracion.antecedentesPrograma",
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.antecedentesPrograma
                                    .required
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "entity.validation.required"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.antecedentesPrograma
                                    .min
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.min",
                                              { min: _vm.caracteresMinimos }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion.antecedentesPrograma
                                    .$model?.length >= _vm.caracteresMinimos
                                    ? _c("small", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.$v.reestructuracion
                                                .antecedentesPrograma.$model
                                                ?.length <=
                                              _vm.caracteresMaximos,
                                            expression:
                                              "$v.reestructuracion.antecedentesPrograma.$model?.length <= caracteresMaximos",
                                          },
                                        ],
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.escritos",
                                              {
                                                inicial:
                                                  _vm.$v.reestructuracion
                                                    .antecedentesPrograma.$model
                                                    ?.length +
                                                  _vm.caracterInicial,
                                              }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm.$v.reestructuracion.antecedentesPrograma
                                      .$model?.length <= _vm.caracteresMinimos
                                      ? _c("small", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.$v.reestructuracion
                                                  .antecedentesPrograma.$model
                                                  ?.length <=
                                                _vm.caracteresFaltantes,
                                              expression:
                                                "$v.reestructuracion.antecedentesPrograma.$model?.length <= caracteresFaltantes",
                                            },
                                          ],
                                          staticClass: "text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.programa.reestructuracion.caracteres.faltantes",
                                                {
                                                  minimos:
                                                    _vm.$v.reestructuracion
                                                      .antecedentesPrograma
                                                      .$model?.length -
                                                    _vm.caracteresMinimos,
                                                }
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion.antecedentesPrograma
                                    .$model?.length > _vm.caracteresMaximos
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.sobrepasados"
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.antecedentesPrograma
                                    .max
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.max",
                                              { max: _vm.caracteresMaximos }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line-break" }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.justificacionReestructuracion"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-textarea", {
                                    attrs: {
                                      state:
                                        !_vm.$v.reestructuracion
                                          .justificacionReestructuracion
                                          .$invalid,
                                      disabled: !_vm.deshabilitarCampo,
                                    },
                                    model: {
                                      value:
                                        _vm.reestructuracion
                                          .justificacionReestructuracion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reestructuracion,
                                          "justificacionReestructuracion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "reestructuracion.justificacionReestructuracion",
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion
                                    .justificacionReestructuracion.required
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "entity.validation.required"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion
                                    .justificacionReestructuracion.min
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.min",
                                              { min: _vm.caracteresMinimos }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion
                                    .justificacionReestructuracion.$model
                                    ?.length >= _vm.caracteresMinimos
                                    ? _c("small", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.$v.reestructuracion
                                                .justificacionReestructuracion
                                                .$model?.length <=
                                              _vm.caracteresMaximosJustificacion,
                                            expression:
                                              "$v.reestructuracion.justificacionReestructuracion.$model?.length <= caracteresMaximosJustificacion",
                                          },
                                        ],
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.escritos",
                                              {
                                                inicial:
                                                  _vm.$v.reestructuracion
                                                    .justificacionReestructuracion
                                                    .$model?.length +
                                                  _vm.caracterInicial,
                                              }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm.$v.reestructuracion
                                      .justificacionReestructuracion.$model
                                      ?.length <= _vm.caracteresMinimos
                                      ? _c("small", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.$v.reestructuracion
                                                  .justificacionReestructuracion
                                                  .$model?.length <=
                                                _vm.caracteresFaltantes,
                                              expression:
                                                "$v.reestructuracion.justificacionReestructuracion.$model?.length <= caracteresFaltantes",
                                            },
                                          ],
                                          staticClass: "text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.programa.reestructuracion.caracteres.faltantes",
                                                {
                                                  minimos:
                                                    _vm.$v.reestructuracion
                                                      .justificacionReestructuracion
                                                      .$model?.length -
                                                    _vm.caracteresMinimos,
                                                }
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion
                                    .justificacionReestructuracion.$model
                                    ?.length >
                                  _vm.caracteresMaximosJustificacion
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.sobrepasados"
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion
                                    .justificacionReestructuracion.max
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.max",
                                              {
                                                max: _vm.caracteresMaximosJustificacion,
                                              }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line-break" }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.razonRecienteCreacion"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-textarea", {
                                    attrs: {
                                      state:
                                        !_vm.$v.reestructuracion
                                          .razonRecienteCreacion.$invalid,
                                      disabled: !_vm.deshabilitarCampo,
                                    },
                                    model: {
                                      value:
                                        _vm.reestructuracion
                                          .razonRecienteCreacion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reestructuracion,
                                          "razonRecienteCreacion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "reestructuracion.razonRecienteCreacion",
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.razonRecienteCreacion
                                    .required
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "entity.validation.required"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.razonRecienteCreacion
                                    .min
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.min",
                                              { min: _vm.caracteresMinimos }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion.razonRecienteCreacion
                                    .$model?.length >= _vm.caracteresMinimos
                                    ? _c("small", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.$v.reestructuracion
                                                .razonRecienteCreacion.$model
                                                ?.length <=
                                              _vm.caracteresMaximos,
                                            expression:
                                              "$v.reestructuracion.razonRecienteCreacion.$model?.length <= caracteresMaximos",
                                          },
                                        ],
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.escritos",
                                              {
                                                inicial:
                                                  _vm.$v.reestructuracion
                                                    .razonRecienteCreacion
                                                    .$model?.length +
                                                  _vm.caracterInicial,
                                              }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm.$v.reestructuracion
                                      .razonRecienteCreacion.$model?.length <=
                                    _vm.caracteresMinimos
                                      ? _c("small", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.$v.reestructuracion
                                                  .razonRecienteCreacion.$model
                                                  ?.length <=
                                                _vm.caracteresFaltantes,
                                              expression:
                                                "$v.reestructuracion.razonRecienteCreacion.$model?.length <= caracteresFaltantes",
                                            },
                                          ],
                                          staticClass: "text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.programa.reestructuracion.caracteres.faltantes",
                                                {
                                                  minimos:
                                                    _vm.$v.reestructuracion
                                                      .razonRecienteCreacion
                                                      .$model?.length -
                                                    _vm.caracteresMinimos,
                                                }
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _vm.$v.reestructuracion.razonRecienteCreacion
                                    .$model?.length > _vm.caracteresMaximos
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.sobrepasados"
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.reestructuracion.razonRecienteCreacion
                                    .max
                                    ? _c("small", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.programa.reestructuracion.caracteres.max",
                                              { max: _vm.caracteresMaximos }
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line-break" }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { sm: "12", md: "12", lg: "12" },
                                },
                                [
                                  _vm.deshabilitarCampo
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            disabled: _vm.desactivarBoton,
                                          },
                                          on: {
                                            click: _vm.agregarReestructuracion,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("entity.action.add")
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("b-icon", {
                                            attrs: { icon: "save" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.deshabilitarCampo
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-danger",
                                            disabled: _vm.desactivarBoton,
                                          },
                                          on: {
                                            click: _vm.limpiarReestructuracion,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("entity.action.cancel")
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("b-icon", {
                                            attrs: { icon: "x" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "line-break" }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("strong", [
                                      _c(
                                        "mark",
                                        { staticClass: "text-primary" },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "apeironGwApp.programa.reestructuracion.agregaReestructuracion"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "line-break" }),
              _vm._v(" "),
              _c("div", { staticClass: "line-break" }),
              _vm._v(" "),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true },
                            },
                          ],
                          attrs: { block: "", variant: "light" },
                        },
                        [
                          _c("h5", {
                            staticClass: "mb-0",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.programa.reestructuracion.listado"
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "accordion-lies",
                        role: "tabpanel",
                      },
                      model: {
                        value: _vm.mostrarLista,
                        callback: function ($$v) {
                          _vm.mostrarLista = $$v
                        },
                        expression: "mostrarLista",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-card",
                                { staticClass: "mt-12" },
                                [
                                  _c("b-table", {
                                    attrs: {
                                      "sticky-header": "500px",
                                      fields: _vm.fieldsSolicitudes,
                                      items: _vm.reestructuraciones,
                                      "head-variant": "dark",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "cell(accion)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "btn-group" },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      variant: "outline-danger",
                                                      title: _vm.$t(
                                                        "apeironGwApp.programa.reestructuracion.eliminar"
                                                      ),
                                                      disabled:
                                                        !_vm.deshabilitarCampo,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.mostrarModalConfirmacionEliminarReestructuracion(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: { icon: "trash" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      variant: "primary",
                                                      title: _vm.$t(
                                                        "apeironGwApp.programa.reestructuracion.editar"
                                                      ),
                                                      disabled:
                                                        !_vm.deshabilitarCampo,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editarReestructuracion(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: { icon: "pencil" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { md: "12" } }, [
                                        _c("div", {
                                          staticClass: "border-top mt-4 mb-4",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "mb-5 float-right" },
                                          [
                                            _vm.deshabilitarCampo
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "md",
                                                      disabled:
                                                        this.reestructuraciones
                                                          .length === 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.guardarReestructuracion()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.save"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: { icon: "save" },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "confirmarModalEliminar",
                                      attrs: {
                                        title: _vm.$t(
                                          "apeironGwApp.programa.reestructuracion.tituloModalEliminarReestructuracion"
                                        ),
                                        "hide-footer": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "apeironGwApp.programa.reestructuracion.eliminarReestructuracionLista"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center justify-content-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.confirmarAccionEliminarReestructuracion()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.confirm"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.ocultarModalEliminarReestructuracion()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "entity.action.cancel"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }