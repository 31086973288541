import RevisionService from '@/entities/revision/revision.service';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory, { INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import { Respuesta } from '@/shared/model/respuesta.model';
import { Revision } from '@/shared/model/revision.model';
import { Revisor } from '@/shared/model/revisor.model';
import { SolicitudResumen } from '@/shared/model/solicitud-resumen.model';
import { ISolicitudRevision } from '@/shared/model/solicitud-revision.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

/**
 * An utility service for RevisionMixin.
 * El componente que utilice este mixin requiere establecer la solicitud y la solución.
 * Se pueden establecer la solución usando otro mixin cómo SolucionMixin.
 */
@Component
export default class RevisionMixin extends Vue {
  @Inject('alertService') public alertService: () => AlertService;

  @Inject('revisionService') protected revisionService: () => RevisionService;

  public solicitud: Solicitud;

  @Prop({ required: true })
  public solucionPlenaria: Solucion;

  @Prop({ required: false, default: true })
  public evaluacionIndividual: boolean;

  public nivelRecomendado: Array<INivel> = EntityFactory.nivelesRecomendado;
  public recomendado: INivel = {};
  public idNoAprobado = NRecomendadoEnum.NO_APROBADO;

  public resumen: any = '';

  public solicitudRevisiones: Array<ISolicitudRevision> = [];

  public validaNiveles = false;

  /**
   * Devuelve el total de artículos
   *
   * @memberof RevisionMixin
   */
  public get totalArticulos(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.articulosCientifica?.totalProducto;
  }

  /**
   * Devuelve el total de artículos principales
   *
   * @memberof RevisionMixin
   */
  public get totalArticulosPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.articulosCientifica?.totalProductoPrincipal;
  }

  /**
   * Devuelve el total de Capitulos
   *
   * @memberof RevisionMixin
   */
  public get totalCapitulos(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.capitulosCientifica?.totalProducto;
  }

  /**
   * Devuelve el total de Capitulos Principales
   *
   * @memberof RevisionMixin
   */
  public get totalCapitulosPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.capitulosCientifica?.totalProductoPrincipal;
  }

  /**
   * Devuelve el total de Libros
   *
   * @memberof RevisionMixin
   */
  public get totalLibros(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.librosCientifica?.totalProducto;
  }

  /**
   * Devuelve el total de Libros Principales
   *
   * @memberof RevisionMixin
   */
  public get totalLibrosPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionCientifica?.librosCientifica?.totalProductoPrincipal;
  }

  /**
   * Devuelve el total de Desarrollo tecnológico e innovación
   *
   * @memberof RevisionMixin
   */
  public get totalDesarrolloTecno(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.desarrolloTecnologicoInnovacion?.totalProducto;
  }

  /**
   * Devuelve el total de Desarrollo tecnológico e innovación Principales
   *
   * @memberof RevisionMixin
   */
  public get totalDesarrolloTecnoPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.desarrolloTecnologicoInnovacion?.totalProductoPrincipal;
  }

  /**
   * Devuelve el total de Propiedad intelectual
   *
   * @memberof RevisionMixin
   */
  public get totalPropiedadintelectual(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.propiedadIntelectual?.totalProducto;
  }

  /**
   * Devuelve el total de Propiedad intelectual Principales
   *
   * @memberof RevisionMixin
   */
  public get totalPropiedadintelectualPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.propiedadIntelectual?.totalProductoPrincipal;
  }

  /**
   * Devuelve el total Transferencia de tecnológica
   *
   * @memberof RevisionMixin
   */
  public get totalTransferenciatecno(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.transferenciaTecnologica?.totalProducto;
  }

  /**
   * Devuelve el total  Transferencia de tecnológica Principales
   *
   * @memberof RevisionMixin
   */
  public get totalTransferenciatecnoPrincipal(): number {
    return this.solicitud.SOLICITUD_RIZOMA?.totalesProduccionTecnologica?.transferenciaTecnologica?.totalProductoPrincipal;
  }

  /**
   * Devuelve la clave del área
   *
   * @memberof RevisionMixin
   */
  public get claveArea(): string {
    return this.solicitud.SOLICITUD_RIZOMA?.areaConocimiento.area.clave;
  }

  /**
   * Obtiene la configuración de la prelación de las áreas.
   */
  public get prelacion() {
    return this.solucionPlenaria?.params?.evaluacion?.prelacion;
  }

  /**
   * Calcula el nivel recomendado de SNI en base al área y total de artículos
   *
   * @memberof RevisionMixin
   */
  public calcularNivelAsignado(): void {
    const v1 = '1';
    const v2 = '2';
    const v3 = '3';
    const v4 = '4';
    const v5 = '5';
    const v6 = '6';
    const v7 = '7';
    const v8 = '8';
    const v9 = '9';
    switch (this.claveArea) {
      case v1:
        if (this.totalArticulos >= 2 && this.totalArticulos < 4) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 4 && this.totalArticulos < 9) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 9 && this.totalArticulos < 20) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 14) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
        break;
      case v2:
        if (this.totalArticulos >= 2 && this.totalArticulos <= 4) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 5 && this.totalArticulos < 10) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 10 && this.totalArticulos <= 19) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 20) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v3:
        if (this.totalArticulos >= 3 && this.totalArticulos < 6) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 6 && this.totalArticulos <= 13) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 11 && this.totalArticulos <= 25) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 20) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v4:
        if (this.totalArticulos >= 2 && this.totalArticulos < 5) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 5 && this.totalArticulos <= 11) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 10 && this.totalArticulos < 22) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 15) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v5:
        if (this.totalArticulos >= 1 && this.totalArticulos <= 3) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 2 && this.totalArticulos <= 6) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 3 && this.totalArticulos <= 9) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 3) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v6:
        if (this.totalArticulos >= 1 && this.totalArticulos <= 4) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 3 && this.totalArticulos <= 8) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 4 && this.totalArticulos < 12) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 5) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v7:
        if (this.totalArticulos >= 2 && this.totalArticulos <= 4) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 5 && this.totalArticulos <= 13) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 15 && this.totalArticulos <= 28) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 23) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v8:
        if (this.totalArticulos >= 2 && this.totalArticulos <= 4) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 5 && this.totalArticulos <= 11) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 13 && this.totalArticulos <= 26) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 19) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      case v9:
        if (this.totalArticulos >= 2 && this.totalArticulos < 5) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        } else if (this.totalArticulos >= 5 && this.totalArticulos <= 11) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v2);
        } else if (this.totalArticulos >= 9 && this.totalArticulos <= 21) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v3);
        } else if (this.totalArticulos >= 14) {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v4);
        } else {
          this.recomendado = EntityFactory.nivelesRecomendado.find(ac => ac.id === v1);
        }
      default:
        break;
    }
  }

  /**
   * Calcula la prelación de SNI en base al área y total de artículos
   *
   * @param {any} id
   * @memberof RevisionMixin
   */
  public calcularPrelacion(): void {
    if (this.solucionPlenaria?.tipo === TipoSolucion.INGRESO_PERMANENCIA_PROMOCION) {
      if (!this.prelacion) {
        this.alertService().showWarning(this, this.$t('archeApp.solucion.params.prelacion').toString(), 'danger');
        return;
      }

      if (this.recomendado.id === this.idNoAprobado) {
        this.resumen = '';
        this.validaNiveles = false;
        return;
      }

      const nivel = this.prelacion.areas.find(a => a.clave === parseInt(this.claveArea));

      if (nivel?.[this.recomendado.id]?.articulos) {
        const q1 = 'C';
        const q2 = 'B';
        const q3 = 'A';

        const nivelArticulos = nivel[this.recomendado.id].articulos;

        if (this.totalArticulos >= nivelArticulos[0] && this.totalArticulos < nivelArticulos[1]) {
          this.resumen = q1;
        } else if (this.totalArticulos >= nivelArticulos[1] && this.totalArticulos < nivelArticulos[2]) {
          this.resumen = q2;
        } else if (this.totalArticulos >= nivelArticulos[2]) {
          this.resumen = q3;
        }
      }
    }
  }

  /**
   * Guarda una revisión
   * @param solicitud La solicitud con que estará vinculada la revisión
   * @param evaluacionId El id de la evaluación a la que pertenece la revisión
   * @param estadoRevision El estado de la revisión
   * @param respuesta La respuesta de la revisión
   * @param revisor El revisor que realizó la revisión
   */
  public guardarRevision(
    solicitud: Solicitud,
    evaluacionId: string,
    estadoRevision: EstadoRevision,
    respuesta: Respuesta,
    revisor: Revisor
  ): Promise<Revision> {
    const revision = new Revision();
    revision.estado = estadoRevision;
    revision.evaluacionId = evaluacionId;
    if (respuesta) {
      revision.respuesta = respuesta;
    }
    if (revisor) {
      revision.revisor = revisor;
    }
    revision.solicitudResumen = new SolicitudResumen(solicitud.solucionId, solicitud.id, solicitud.nombre);
    return this.revisionService()
      .create(revision)
      .then(respRevision => {
        revision.id = respRevision.id;
        return Promise.resolve(revision);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** Consulta genérica para la obtención de
   * la solicitud y N revisiones, los parámetros
   * de consulta se añaden a paginationQuery.
   */
  public getRevisiones(paginationQuery): void {
    this.revisionService()
      .getRevisionAllSolicitudAll(paginationQuery)
      .then(solicitudRevisiones => {
        this.solicitudRevisiones = solicitudRevisiones.data;
      });
  }

  /**
   * Guarda la evaluación dependiendo los datos proporcionados
   *
   */
  public guardaEvaluacion(revision: Revision) {
    this.$emit('guardarEvaluacion', revision);
  }
}
