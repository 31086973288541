var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.datosGenerales
      ? _c(
          "div",
          [
            _c(
              "b-card",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "center-flex card-contacto",
                        attrs: { col: "", lg: "1" },
                      },
                      [_c("span", { staticClass: "icon-diploma" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "left-flex", attrs: { col: "", lg: "9" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "font-weight-bolder text-black" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datosGenerales.persona.nombre +
                                        " " +
                                        _vm.datosGenerales.persona
                                          .apellidoPaterno +
                                        " " +
                                        _vm.datosGenerales.persona
                                          .apellidoMaterno
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t("apeironGwApp.persona.cvu"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datosGenerales.persona.cvu)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t("apeironGwApp.persona.rfc"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datosGenerales.persona.rfc)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t("apeironGwApp.persona.curp"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datosGenerales.persona.curp)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t("apeironGwApp.persona.genero"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datosGenerales.persona.genero)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t("apeironGwApp.persona.login"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datosGenerales.persona.login)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.nivel
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.vigencias.sni.nivel"
                                      ),
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.nivel))])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.vigenciaSniActiva
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.vigencias.sni.subtitle"
                                      ),
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.vigenciaSniActiva.tipo.descripcion
                                        ) + " "
                                      ),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatUtc")(
                                              _vm.vigenciaSniActiva.vigencia
                                                .fechaInicio
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" -\n                "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatUtc")(
                                                _vm.vigenciaSniActiva.vigencia
                                                  .fechaFin
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.situacion
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.vigencias.sni.situacion"
                                      ),
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.situacion))])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.institucion
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.acreditaciones.institucion"
                                      ),
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.institucion)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.entidad
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.acreditaciones.entidad"
                                      ),
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.entidad))])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.ayudantes
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.misAyudantes.title"
                                      ),
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.ayudantes.length)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cuentas
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "m-0",
                                    attrs: {
                                      "label-cols": "4",
                                      "label-cols-lg": "3",
                                      "label-size": "sm",
                                      label: _vm.$t(
                                        "apeironGwApp.persona.expediente.cuentas.title"
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-table", {
                                      attrs: {
                                        small: "",
                                        borderless: "",
                                        items: _vm.cuentas,
                                        fields: [
                                          "proceso",
                                          "banco",
                                          "estadoSolicitud",
                                          "numeroCuenta",
                                        ],
                                        "head-variant": "primary",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "head()",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-1 text-center",
                                                  },
                                                  [_vm._v(_vm._s(data.label))]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(proceso)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-2 text-justify",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.proceso)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(banco)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-2 text-justify",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.banco)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(estadoSolicitud)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-2 text-justify",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item
                                                          .estadoSolicitud
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(numeroCuenta)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-2 text-justify",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.numeroCuenta
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        549174656
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-cols": "4",
                                  "label-cols-lg": "3",
                                  "label-size": "sm",
                                  label: _vm.$t(
                                    "apeironGwApp.persona.domicilio"
                                  ),
                                },
                              },
                              [
                                _c("b-table", {
                                  attrs: {
                                    small: "",
                                    borderless: "",
                                    items: _vm.datosGenerales.domicilios,
                                    "head-variant": "primary",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "head(direccion)",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-1 text-center",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.persona.direccion"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "head(tipo)",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-1 text-center",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.persona.tipo"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "cell(direccion)",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-2 text-justify",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(data.item.direccion)
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(tipo)",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-2 text-center",
                                              },
                                              [_vm._v(_vm._s(data.item.tipo))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2703684140
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }