import Vue from 'vue';
import * as dateUtils from '@/shared/date/date-utils';

export function initFilters() {
  Vue.filter('formatDate', value => {
    return dateUtils.formatDate(value);
  });

  Vue.filter('duration', value => {
    return dateUtils.duration(value);
  });

  Vue.filter('truncate', value => {
    if (value) {
      return value.substring(0, 7);
    }
    return '';
  });

  Vue.filter('timeElapsed', function (value) {
    return dateUtils.timeElapsed(value);
  });

  Vue.filter('formatUtc', function (value) {
    return dateUtils.formatUtc(value);
  });
}
