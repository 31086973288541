import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { LieSnp } from '@/entities/snp/lie/lie.model';

@Component({})
export default class LieListadoComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({
    required: false,
    default: function () {
      return new LieSnp();
    },
  })
  public lieAgregar: LieSnp;

  public filtroLies = '';
  public liesFiltradas: Array<LieSnp> = [];
  public lies: Array<LieSnp> = [];

  public mostrarModalActivar = false;
  public mostrarModalDesactivar = false;
  public mostrarModalEliminar = false;

  public lieSeleccionada: LieSnp = new LieSnp();

  public perPage = 15;
  public currentPage = 1;

  public readonly fieldsLies = [
    {
      key: 'institucionCedeDependencia',
      label: this.$t('apeironGwApp.lieSnp.form.institucionCedeDependencia'),
      class: 'text-left',
      thStyle: { width: '20rem' },
    },
    { key: 'nombreDeLinea', label: this.$t('apeironGwApp.lieSnp.form.nombreDeLaLie'), class: 'text-left', thStyle: { width: '6rem' } },
    {
      key: 'clasificacion.nombre',
      label: this.$t('apeironGwApp.lieSnp.form.clasificacion'),
      class: 'text-left',
      thStyle: { width: '5rem' },
    },
    { key: 'activo', label: this.$t('apeironGwApp.lieSnp.form.activo'), class: 'text-left', thStyle: { width: '2rem' } },
    {
      key: 'acciones',
      label: this.$t('apeironGwApp.lieSnp.acciones.acciones'),
      class: 'text-center btn-acciones',
      thStyle: { width: '8rem' },
    },
  ];

  public mounted(): void {
    this.iniciarComponente();
  }

  @Watch('lieAgregar')
  public watchLieAgregar(): void {
    if (this.lieAgregar && this.lieAgregar.id) {
      this.lies = this.lies.filter(lie => lie.id !== this.lieAgregar.id);
    }
    this.lies.push(this.lieAgregar);

    this.buscarEnLie();
  }

  private iniciarComponente(): void {
    this.lieSeleccionada = new LieSnp();

    this.mostrarModalActivar = false;
    this.mostrarModalDesactivar = false;
    this.mostrarModalEliminar = false;

    if (this.solicitud?.lies) {
      this.lies = this.solicitud.lies;
    }

    this.buscarEnLie();
  }

  public mostrarModalEliminarLie(lie: LieSnp): void {
    this.mostrarModalEliminar = true;
    this.lieSeleccionada = lie;
  }

  public mostrarModalDesactivarLie(lie: LieSnp): void {
    this.mostrarModalDesactivar = true;
    this.lieSeleccionada = lie;
  }

  public mostrarModalActivarLie(lie: LieSnp): void {
    this.mostrarModalActivar = true;
    this.lieSeleccionada = lie;
  }

  public eliminarLie(): void {
    this.lies = this.lies.filter(lie => lie.id !== this.lieSeleccionada.id);
    this.mostrarModalEliminar = false;

    this.buscarEnLie();
  }

  @Emit('editarLie')
  public editarLie(lie: LieSnp): LieSnp {
    const lieClonada = { ...lie };

    this.buscarEnLie();

    return lieClonada;
  }

  public desactivarLie(): void {
    this.lies.forEach(lie => {
      if (this.lieSeleccionada.id == lie.id) {
        lie.activo = false;
      }
    });

    this.mostrarModalDesactivar = false;

    this.buscarEnLie();
  }

  public activarLie(): void {
    this.lies.forEach(lie => {
      if (this.lieSeleccionada.id == lie.id) {
        lie.activo = true;
      }
    });

    this.mostrarModalActivar = false;

    this.buscarEnLie();
  }

  public guardarLies(): void {
    if (!this.readOnly) {
      this.guardarSeccion();
    }
  }

  @Emit('guardarLies')
  public guardarSeccion(): Array<LieSnp> {
    return this.lies;
  }

  public buscarEnLie(): void {
    this.liesFiltradas = [];

    this.lies.forEach(lie => {
      if (this.filtroLies) {
        if (
          lie.clasificacion?.nombre?.toLowerCase().includes(this.filtroLies.toLowerCase()) ||
          lie.nombreDeLinea?.toLowerCase().includes(this.filtroLies.toLowerCase()) ||
          lie.institucionCedeDependencia?.nombreInstitucion?.toLowerCase().includes(this.filtroLies.toLowerCase()) ||
          lie.institucionCedeDependencia?.sede?.sede?.toLowerCase().includes(this.filtroLies.toLowerCase()) ||
          lie.institucionCedeDependencia?.sede?.nombreDependencia?.toLowerCase().includes(this.filtroLies.toLowerCase())
        ) {
          this.liesFiltradas.push(lie);
        }
      } else {
        this.liesFiltradas.push(lie);
      }
    });
  }

  get registros(): number {
    return this.liesFiltradas?.length ?? 0;
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }
}
