var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.mostrarTexto
              ? _c("h5", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("apeironGwApp.programa.obligatorio")
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.pronaceSnp.form.modalidad") +
                              " *",
                            "label-for": "input-modalidad",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-modalidad",
                              options: _vm.modalidadesPronaces,
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.modalidad"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.pronaceSnp.modalidad.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.pronaceSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.pronaceSnp.modalidad,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "modalidad", $$v)
                              },
                              expression: "pronaceSnp.modalidad",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.pronaceSnp.form.area") +
                              " *",
                            "label-for": "input-area",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-area",
                              options: _vm.areas,
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.area"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.pronaceSnp.area.$invalid,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.cambiarSeleccionArea(true)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.pronaceSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.pronaceSnp.area,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "area", $$v)
                              },
                              expression: "pronaceSnp.area",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pronaceSnp.area?.claveArea == 0,
                      expression: "pronaceSnp.area?.claveArea == 0",
                    },
                  ],
                },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t(
                                "apeironGwApp.pronaceSnp.form.areaEspecifique"
                              ) +
                              (_vm.pronaceSnp.area?.claveArea == 0 ? " *" : ""),
                            "label-for": "input-area-especifique",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-area-especifique",
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.areaEspecifique"
                              ),
                              disabled: _vm.readOnly,
                              state:
                                !_vm.$v.pronaceSnp.areaEspecifique.$invalid,
                            },
                            model: {
                              value: _vm.pronaceSnp.areaEspecifique,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "areaEspecifique", $$v)
                              },
                              expression: "pronaceSnp.areaEspecifique",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.pronaceSnp.form.tema") +
                              " *",
                            "label-for": "input-tema",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-tema",
                              options: _vm.temas,
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.tema"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.pronaceSnp.tema.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.pronaceSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.pronaceSnp.tema,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "tema", $$v)
                              },
                              expression: "pronaceSnp.tema",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pronaceSnp.tema?.claveArea == 0,
                      expression: "pronaceSnp.tema?.claveArea == 0",
                    },
                  ],
                },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t(
                                "apeironGwApp.pronaceSnp.form.temaEspecifique"
                              ) +
                              (_vm.pronaceSnp.tema?.claveArea == 0 ? " *" : ""),
                            "label-for": "input-tema-especifique",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-tema-especifique",
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.temaEspecifique"
                              ),
                              disabled: _vm.readOnly,
                              state:
                                !_vm.$v.pronaceSnp.temaEspecifique.$invalid,
                            },
                            model: {
                              value: _vm.pronaceSnp.temaEspecifique,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "temaEspecifique", $$v)
                              },
                              expression: "pronaceSnp.temaEspecifique",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.pronaceSnp.form.subtema") +
                              " *",
                            "label-for": "input-subtema",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-subtema",
                              options: _vm.subtemas,
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.subtema"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.pronaceSnp.subtema.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.pronaceSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.pronaceSnp.subtema,
                              callback: function ($$v) {
                                _vm.$set(_vm.pronaceSnp, "subtema", $$v)
                              },
                              expression: "pronaceSnp.subtema",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pronaceSnp.subtema?.claveArea == 0,
                      expression: "pronaceSnp.subtema?.claveArea == 0",
                    },
                  ],
                },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t(
                                "apeironGwApp.pronaceSnp.form.subtemaEspecifique"
                              ) +
                              (_vm.pronaceSnp.subtema?.claveArea == 0
                                ? " *"
                                : ""),
                            "label-for": "input-subtema-especifique",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-subtema-especifique",
                              placeholder: _vm.$t(
                                "apeironGwApp.pronaceSnp.form.subtemaEspecifique"
                              ),
                              disabled: _vm.readOnly,
                              state:
                                !_vm.$v.pronaceSnp.subtemaEspecifique.$invalid,
                            },
                            model: {
                              value: _vm.pronaceSnp.subtemaEspecifique,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pronaceSnp,
                                  "subtemaEspecifique",
                                  $$v
                                )
                              },
                              expression: "pronaceSnp.subtemaEspecifique",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-row", [_c("b-col", [_c("hr")])], 1),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "end" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary",
                            disabled: _vm.formularioIsInvalid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.guardarPronace()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.pronaceSnp.acciones.guardar"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "save" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "button", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.limpiarDatos()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.pronaceSnp.acciones.reset")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "refresh" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }