import { IComentario, Comentario } from '@/shared/model/comentario.model';
import { TipoComentario } from '@/shared/model/enumerations/tipo-comentario.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { required } from 'vuelidate/lib/validators';

import { mixins } from 'vue-class-component';
import { Component, Prop, Inject, Emit, Ref } from 'vue-property-decorator';

import JhiDataUtils from '@/shared/data/data-utils.service';

import ComentarioService from '@/shared/comentario/comentario.service';
import { Filtro } from '@/shared/model/filtro.model';
import { DmsApi, EtapaEnum, Archivo } from '@conacyt/dms-client';
import { File } from '@/shared/model/file.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import AlertService from '@/shared/alert/alert.service';

const validations: any = {
  comentario: {
    usuarioId: {},
    text: {
      required,
    },
  },
  currentFile: {
    nombre: {
      required,
    },
    uri: {
      required,
    },
  },
};
@Component({
  validations,
  computed: {
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
    },
    username: {
      get() {
        return this.$store.getters.account?.login ?? '';
      },
    },
  },
})
export default class ComentariosAjusteComponent extends mixins(JhiDataUtils) {
  @Inject('comentarioService') private comentarioService: () => ComentarioService;
  @Inject('alertService') protected alertService: () => AlertService;
  @Inject('dmsApi') public dmsApi: () => DmsApi;

  public readonly userInfo!: any;

  public readonly username!: any;

  @Ref('infiniteLoading')
  public readonly infiniteLoading: any;

  @Prop({ required: true })
  public solicitudId: string;

  /**
   * Propiedad utilizada para obtener datos_conciliados de solicitud
   */
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ default: true })
  public listMessages: boolean;

  /**
   * Determinará si operador o solicitante pueden agregar comentarios
   */
  @Prop({ default: false })
  public readOnly: boolean;

  @Prop({ default: false })
  public isOperador: boolean;

  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public propOrder = 'fechaCreacion';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = {};

  public comentarios: IComentario[] = [];
  public comentario: IComentario = new Comentario();

  public isFetching = false;

  public filtro = new Filtro();

  // Manejo de documentos
  private ETAPA: EtapaEnum = 'REGISTRO';
  private CARPETA = 'DADC';
  public documentoModel: any = {};
  public currentFile: any = null;
  public currentContent: any = null;
  public isSaving = false;

  public mounted(): void {
    this.retrieveAllComentarios();
  }

  @Emit('comentarioSaved')
  public agregarComentarioHandler() {
    this.comentario.usuarioId = this.username;
    this.comentario.tipo = TipoComentario.REVISION_AJUSTES;
    this.comentario.rol = RolAutoridad.SOLICITANTE;
    this.comentario.solicitudId = this.solicitudId;
    this.comentario.datos_conciliados = this.solicitud.datos_conciliados;
    this.comentarioService()
      .create(this.comentario)
      .then(res => {
        this.comentarios.unshift(res);
        this.comentario = new Comentario();
        this.documentoModel = {};
        return res;
      });
  }

  public clear(): void {
    this.page = 1;
    this.links = {};
    this.infiniteId += 1;
    this.comentarios = [];
    this.retrieveAllComentarios();
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.comentarios = [];
    this.retrieveAllComentarios();
  }

  public retrieveAllComentarios(): void {
    this.isFetching = true;
    this.filtro.solicitudId = this.solicitudId;
    this.filtro.tiposComentario = [TipoComentario.REVISION_AJUSTES];
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
      filter: this.filtro,
    };
    this.comentarioService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              this.comentarios.push(res.data[i]);
            }
            if (res.headers && res.headers['link']) {
              this.links = this.parseLinks(res.headers['link']);
            }
          }
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
          if (this.infiniteLoading) {
            this.infiniteLoading.stateChanger.loaded();
            if (JSON.stringify(this.links) !== JSON.stringify({}) && this.page > this.links['last']) {
              this.infiniteLoading.stateChanger.complete();
            }
          }
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public loadMore($state): void {
    if (!this.isFetching) {
      this.page++;
      this.retrieveAllComentarios();
    }
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public onReadFile(file, doc: File) {
    if (file) {
      this.toBase64(file, base64Data => {
        doc.nombre = file.name;
        doc.contentType = file.type;
        this.currentContent = base64Data;
        this.guardarDocumento(doc);
      });
    }
  }

  public isUpdating() {
    return this.isSaving;
  }

  public handleAdjuntarAComentario(adjuntar: boolean): void {
    this.comentario.documento_comprobatorio = adjuntar ? this.documentoModel : null;
  }

  public guardarDocumento(doc: File) {
    this.isSaving = true;
    const archivo: Archivo = { nombre: doc.nombre, contenido: this.currentContent };
    this.dmsApi()
      .agregaDocumento(this.CARPETA, this.solicitudId, this.userInfo.cvu, this.ETAPA, undefined, archivo)
      .then(res => {
        doc.uri = res.headers.location;
        this.currentContent = null;
        this.currentFile = null;
        this.handleAdjuntarAComentario(true);
        this.isSaving = false;
      })
      .catch(error => {
        this.currentContent = null;
        this.currentFile = null;
        this.documentoModel = {};
        if (error?.response?.data?.detail) {
          this.alertService().showError(this, error.response.data.detail);
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.isSaving = false;
      });
  }

  public handleDeleteDocumento(doc: File) {
    this.isSaving = true;
    this.dmsApi()
      .borraDocumentoPorUrl(doc.uri)
      .then(res => {
        doc.uri = null;
        this.documentoModel = {};
        this.handleAdjuntarAComentario(false);
        this.currentFile = null;
        this.isSaving = false;
      })
      .catch(error => {
        if (error?.response?.data?.detail) {
          this.alertService().showError(this, error.response.data.detail);
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.isSaving = false;
      });
  }
}
