import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { IMascaraEstado } from '@/shared/model/mascara-estado.model';

import { Store } from 'vuex';

export default class GrantService {
  constructor(private store: Store<any>) {}

  /**
   * Verifica si la lista de Roles tienen algún tipo de permisos en el
   * objecto de la Solicitud de acuerdo con la definición de una Solución
   *
   * @param solucion la solución contiene la definición de permsisos sobre el objeto solicitud
   * @param solicitud es la solicitud actual
   * @param roles es la lista de roles que tiene un usuario que inicio sesión
   */
  public hasAccesToObject(solucion: any, solicitud: any, roles: any[]) {
    const accesos = this.accesos(solucion, solicitud);
    return this.isAnyRoleIntoAccess(accesos, roles);
  }

  /**
   * Verifica si algún Rol se encuentra en la lista de Accesos
   *
   * @param accesos lista de accesos a los que tiene permiso el usuario logeado
   * @param roles es la lista de roles que tiene un usuario que inicio sesión
   */
  public isAnyRoleIntoAccess(accesos, roles: any[]) {
    for (const rol of roles) {
      if (accesos[rol]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Crea la lista de Accesos para un usuario que inicio sesión para una solución y solicitud especifica
   *
   * @param solucion es el objecto solución
   * @param solicitud es la solicitud actual
   * @return la lista de accesos a los que tiene permiso un usuario
   */
  public accesos(solucion: any, solicitud: any) {
    const accesos = { acciones: [] };

    if (solicitud == null || solucion == null) {
      return accesos;
    }

    const currentEstado = this.currentEstado(solucion, solicitud);
    const username = this.getUsername();

    solucion.autoridades.forEach(autoridad => {
      if (autoridad.usuarioId === username) {
        autoridad.roles.forEach(rol => (accesos[rol] = []));
      }
    });
    if (this.isSolicitante(solicitud, username)) {
      accesos[RolAutoridad.SOLICITANTE] = [];
    }
    if (this.isRevisor(solicitud, username)) {
      accesos[RolAutoridad.REVISOR] = [];
    }
    if (this.isAyudante(solicitud, username)) {
      accesos[RolAutoridad.AYUDANTE] = [];
    }
    if (currentEstado) {
      currentEstado.permisos.forEach(permiso => {
        if (accesos[permiso.rol]) {
          permiso.acciones.forEach(accion => {
            accesos[permiso.rol].push(accion);
            accesos.acciones.push(accion);
          });
        }
      });
    }

    return accesos;
  }

  /**
   * Regresa la lista de estados, a las que tiene permiso un usuario
   * a partir de la definición en una solución
   *
   * @param solucion es el objecto solución
   * @return lista de estados
   */
  public resolveEstadosBySolucion(solucion: any) {
    const accesos = { acciones: [] };

    if (solucion == null) {
      return accesos;
    }

    const username = this.getUsername();

    solucion.autoridades.forEach(autoridad => {
      if (autoridad.usuarioId === username) {
        autoridad.roles.forEach(rol => (accesos[rol] = []));
      }
    });

    return solucion.proceso.estados.filter(estado => this.hasAccessOnState(estado, accesos));
  }

  private hasAccessOnState(currentEstado: any, accesos: any) {
    let hasAccess = false;
    currentEstado.permisos.forEach(permiso => {
      if (accesos[permiso.rol]) {
        hasAccess = true;
      }
    });
    return hasAccess;
  }

  /**
   * Crea la lista de Accesos para un usuario dada una solución y el estado en el que
   * se encuentra la solicitud
   *
   * @param solucion es el objecto solución
   * @param estado es el estado actual en el que se encuentra una solicitud
   * @return la lista de accesos a los que tiene permiso un usuario dado un estado
   */
  public accesosBySolucionAndEstado(solucion: any, estado: any) {
    const accesos = { acciones: [] };

    if (solucion == null) {
      return accesos;
    }

    const currentEstado = this.currentEstado(solucion, { estado: estado });
    const username = this.getUsername();

    solucion.autoridades.forEach(autoridad => {
      if (autoridad.usuarioId === username) {
        autoridad.roles.forEach(rol => (accesos[rol] = []));
      }
    });

    if (currentEstado) {
      currentEstado.permisos.forEach(permiso => {
        if (accesos[permiso.rol]) {
          permiso.acciones.forEach(accion => {
            accesos[permiso.rol].push(accion);
            accesos.acciones.push(accion);
          });
        }
      });
    }

    return accesos;
  }

  /**
   * Verifica si el usuario actual es un revisar para la solicitud actual
   *
   * @param solicitud es la solicitud
   * @param username es el nombre de usuario o login
   * @return true si el usuario actual es revisaro de la solicitud, de otro modo regresa false
   */
  public isRevisor(solicitud, username) {
    return solicitud?.revisores?.filter(revisor => revisor.revisorId === username).length > 0;
  }

  /**
   * Verifica si el usuario actual es un ayudante para la solicitud actual
   *
   * @param solicitud es la solicitud
   * @param username es el nombre de usuario o login
   * @return true si el usuario actual es ayudante, de otro modo regresa false
   */
  public isAyudante(solicitud, username) {
    return solicitud?.ayudante?.login === username;
  }

  /**
   * Verifica si el usuario actual es el solicitante
   *
   * @param solicitud es la solicitud
   * @param username es el nombre de usuario o login
   * @return true si el usuario actual es el solicitante, de otro modo regresa false
   */
  public isSolicitante(solicitud, username) {
    return solicitud?.usuario === username;
  }

  /**
   * Regresa la defición del Estado actual a partir de la solución y el estado en el que se encuentra
   * la solicitud
   *
   * @param solucion es el objecto solución
   * @param solicitud es la solicitud actual
   * @return Definición del estado en el que se encuentra la solicitud actual
   */
  public currentEstado(solucion: any, solicitud: any) {
    const index = solucion.proceso.estados.findIndex(estado => estado.nombre === solicitud.estado);
    return solucion.proceso.estados[index];
  }

  /**
   * Resuelve el nombre del estado de una solicitud que puede ver un usuario a partir de la definición de
   * sus permisos dentro de una solución
   *
   * @param accesos lista de accesos de un usuario
   * @param mascaraEstados lista de estados que se van a enmascarar
   * @param currentEstado definición del estado actual de una solicitud
   * @return string con el nombre del estado que puede ver el usuario actual
   */
  public resolveEstadoMask(accesos, mascaraEstados: IMascaraEstado[], currentEstado: EstadoSolicitud) {
    if (!mascaraEstados || mascaraEstados.length <= 0) {
      return currentEstado;
    }
    return this.resolveEstadoByFirstRolStrategy(accesos, mascaraEstados, currentEstado);
  }

  /**
   * Regresa el nombre del estado que puede ver un usuario a partir de sus permisos y mascara definida.
   * Se utiliza la estrategia de regresar la mascara para el primer rol que tiene definido el usuario.
   * En caso de que no se tenga ninguna máscara definida, entonces se regresa el nombre del estado sin
   * alteraciones
   *
   * @param accesos lista de accesos de un usuario
   * @param mascaraEstados lista de estados que se van a enmascarar
   * @param currentEstado definición del estado actual de una solicitud
   * @return string con el nombre del estado que puede ver el usuario actual
   */
  private resolveEstadoByFirstRolStrategy(accesos, mascaraEstados: IMascaraEstado[], currentEstado: EstadoSolicitud) {
    for (const mascara of mascaraEstados) {
      if (accesos[mascara.rol] && mascara.estado === currentEstado) {
        return mascara.mascara;
      }
    }
    return currentEstado;
  }

  /**
   * Regresa el nombre del usuario que actualmente ha iniciado sesión
   *
   * @return string con el nombre de usuario que actualmente ha iniciado sesiónI
   */
  public getUsername(): string {
    return this.store.getters.account?.login ?? '';
  }
}
