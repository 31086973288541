import { Component, Prop } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoPrograma } from '@/shared/model/enumerations/estado-programa';
import { FiltroPrograma } from '@/shared/model/filtro-programa.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';

@Component
export default class ProgramaRegistroComponent extends ApeironComponent {
  public filtro = new FiltroPrograma();

  @Prop({ required: false })
  public solucion: Solucion;

  created() {
    this.filtro.coordinadorCvu = this.permisos.usuario.cvu;
    this.filtro.programaEstatus = EstadoPrograma.ELEGIBLE;
    if (this.solucion) {
      this.filtro.tipoEspecial = this.solucion.params.tipoEspecial;
    }
    if (this.formModel?.programa) {
      this.form.programa = { ...this.form.programa, ...JSON.parse(JSON.stringify(this.formModel.programa)) };
    }
  }

  form = { programa: {} };

  get readOnly() {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }
}
