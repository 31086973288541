var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.tiempoDedicacion"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-select", {
            attrs: {
              disabled: _vm.readOnly,
              state:
                _vm.persona.tiempoDedicacion != null &&
                _vm.persona.tiempoDedicacion !== "undefined",
              options: _vm.tiempoDedicacion,
            },
            model: {
              value: _vm.persona.tiempoDedicacion,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "tiempoDedicacion", $$v)
              },
              expression: "persona.tiempoDedicacion",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: "input-feedback-dedicacion" } },
            [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$t("formularioDemo.nombre.validations.required"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.promedioAnterior"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              disabled: _vm.readOnly,
              state:
                _vm.persona.promedioAnterior != null &&
                _vm.persona.promedioAnterior !== "undefined",
              id: "promedio-id",
              type: "number",
            },
            model: {
              value: _vm.persona.promedioAnterior,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "promedioAnterior", $$v)
              },
              expression: "persona.promedioAnterior",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: "input-feedback-promedio" } },
            [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$t("formularioDemo.nombre.validations.required"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            disabled: _vm.readOnly,
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.fechaInicioGeneracion"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: {
              disabled: _vm.readOnly,
              placeholder: "",
              state:
                _vm.persona.inicioGeneracion != null &&
                _vm.persona.inicioGeneracion !== "undefined",
              locale: "es",
            },
            model: {
              value: _vm.persona.inicioGeneracion,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "inicioGeneracion", $$v)
              },
              expression: "persona.inicioGeneracion",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: "input-feedback-genracion" } },
            [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$t("formularioDemo.nombre.validations.required"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            disabled: _vm.readOnly,
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.duracionMeses"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.duracionOficial,
              callback: function ($$v) {
                _vm.duracionOficial = $$v
              },
              expression: "duracionOficial",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            disabled: _vm.readOnly,
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.inicioEstudios"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.persona.inicioEstudios,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "inicioEstudios", $$v)
              },
              expression: "persona.inicioEstudios",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.finEstudios"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.persona.finEstudios,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "finEstudios", $$v)
              },
              expression: "persona.finEstudios",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.obtencionGrado"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: { disabled: _vm.readOnly, placeholder: "", locale: "es" },
            model: {
              value: _vm.persona.obtencionGrado,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "obtencionGrado", $$v)
              },
              expression: "persona.obtencionGrado",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-size": "lg",
            label: _vm.$t(
              "apeironGwApp.persona.participacion.becario.reincorporacion"
            ),
            "label-for": "input-sm",
          },
        },
        [
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: { disabled: _vm.readOnly, placeholder: "", locale: "es" },
            model: {
              value: _vm.persona.reincorporacion,
              callback: function ($$v) {
                _vm.$set(_vm.persona, "reincorporacion", $$v)
              },
              expression: "persona.reincorporacion",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isFormValid
        ? _c(
            "div",
            [
              _c("message", {
                staticClass: "mt-5",
                attrs: {
                  variant: "danger",
                  description: _vm.$t(
                    "apeironGwApp.persona.form.messages.requeridos"
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }