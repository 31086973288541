import SolicitudService from '@/entities/solicitud/solicitud.service';
import AlertService from '@/shared/alert/alert.service';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Reestructuracion, Solicitud } from '@/shared/model/solicitud.model';
import { BModal } from 'bootstrap-vue';
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { format } from 'date-fns';

const validations = function () {
  const { caracteresMinimos, caracteresMaximos, caracteresMaximosJustificacion } = this as RegistroReestructuracionComponent;
  return {
    reestructuracion: {
      fechaReestructuracion: { required },
      fechaInicioOperacion: { required },
      antecedentesPrograma: { required, min: minLength(caracteresMinimos), max: maxLength(caracteresMaximos) },
      justificacionReestructuracion: { required, min: minLength(caracteresMinimos), max: maxLength(caracteresMaximosJustificacion) },
      razonRecienteCreacion: { required, min: minLength(caracteresMinimos), max: maxLength(caracteresMaximos) },
    },
  };
};

@Component({ validations })
export default class RegistroReestructuracionComponent extends Vue {
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('solicitudService') public solicitudService: () => SolicitudService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public reestructuracion: Reestructuracion = {};

  public reestructuraciones: Array<Reestructuracion> = [];

  public reestructuracionAEliminar: Reestructuracion;
  public reestructuracionIndex: number | null = null;
  public textoFechaMayor: boolean | null = null;

  public readonly caracterInicial = 0;
  public readonly caracteresMinimos = 50;
  public readonly caracteresMaximos = 1255;
  public readonly caracteresFaltantes = 49;
  public readonly caracteresMaximosJustificacion = 2500;

  public show = false;
  public mostrarSeccionRegistro = false;
  public mostrarLista = false;

  @Ref('confirmarModalEliminar')
  public readonly confirmarModalEliminar: BModal;

  public readonly fieldsSolicitudes = [
    {
      key: 'justificacionReestructuracion',
      label: this.$t('apeironGwApp.programa.reestructuracion.justificacion').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'fechaReestructuracion',
      label: this.$t('apeironGwApp.programa.reestructuracion.fechaReestructuracion').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'fechaInicioOperacion',
      label: this.$t('apeironGwApp.programa.reestructuracion.fechaOperacion').toString(),
      class: 'text-center',
      sortable: true,
    },
    { key: 'accion', label: this.$t('bandeja.acciones').toString(), class: 'text-center' },
  ];

  mounted() {
    this.get();
  }

  /**
   * Carga inicial del componente
   *
   * @memberof RegistroReestructuracionComponent
   */
  public get(): void {
    if (this.solicitud?.reestructuraciones) {
      this.reestructuraciones = this.solicitud.reestructuraciones.map(reestructuracion => ({
        ...reestructuracion,
        fechaReestructuracion: this.formatearFechaGuardada(reestructuracion.fechaReestructuracion),
        fechaInicioOperacion: this.formatearFechaGuardada(reestructuracion.fechaInicioOperacion),
      }));
    }
  }

  /**
   * Agrega un nuevo objeto de reestructuración a la lista de reestructuraciones.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public agregarReestructuracion() {
    if (this.reestructuracion.fechaInicioOperacion >= this.reestructuracion.fechaReestructuracion) {
      const actualizarReestructuraciones = [...this.reestructuraciones];

      if (this.reestructuracionIndex !== null) {
        actualizarReestructuraciones[this.reestructuracionIndex] = {
          fechaReestructuracion: this.formatearFecha(this.reestructuracion.fechaReestructuracion),
          fechaInicioOperacion: this.formatearFecha(this.reestructuracion.fechaInicioOperacion),
          antecedentesPrograma: this.reestructuracion.antecedentesPrograma,
          justificacionReestructuracion: this.reestructuracion.justificacionReestructuracion,
          razonRecienteCreacion: this.reestructuracion.razonRecienteCreacion,
        };

        this.reestructuracionIndex = null;
      } else {
        actualizarReestructuraciones.push({
          fechaReestructuracion: this.formatearFecha(this.reestructuracion.fechaReestructuracion),
          fechaInicioOperacion: this.formatearFecha(this.reestructuracion.fechaInicioOperacion),
          antecedentesPrograma: this.reestructuracion.antecedentesPrograma,
          justificacionReestructuracion: this.reestructuracion.justificacionReestructuracion,
          razonRecienteCreacion: this.reestructuracion.razonRecienteCreacion,
        });
      }

      this.reestructuraciones = actualizarReestructuraciones;
      this.mostrarLista = true;
      this.textoFechaMayor = false;
      this.limpiarReestructuracion();
    } else {
      this.textoFechaMayor = true;
    }
  }

  /**
   * Función para formatear una fecha en el formato "día/mes/año".
   *
   * @memberof RegistroReestructuracionComponent
   */
  private formatearFecha(fecha) {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];

    const date = new Date(fecha);
    const dia = date.getDate();
    const mes = meses[date.getMonth()];
    const año = date.getFullYear();

    return `${dia}/${mes}/${año}`;
  }

  /**
   * Guarda la reestructuración en la solicitud actual y actualiza la solicitud.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public guardarReestructuracion(): void {
    const solicitud = this.solicitud;

    solicitud.reestructuraciones = this.reestructuraciones.map(r => ({
      ...r,
      fechaReestructuracion: this.convertirFechaAGuardar(r.fechaReestructuracion),
      fechaInicioOperacion: this.convertirFechaAGuardar(r.fechaInicioOperacion),
    }));

    this.$emit('guardarReestructuracion', solicitud);
  }

  /**
   * Este método `limpiarReestructuracion` restablece el estado de las propiedades
   * relacionadas con la reestructuración a sus valores predeterminados.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public limpiarReestructuracion() {
    this.reestructuracion = {
      fechaReestructuracion: undefined,
      fechaInicioOperacion: undefined,
      antecedentesPrograma: undefined,
      justificacionReestructuracion: undefined,
      razonRecienteCreacion: undefined,
    };
    this.mostrarLista = true;
  }

  /**
   * Método para editar una reestructuración.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public editarReestructuracion(item, index) {
    this.reestructuracion = { ...item };

    const meses = {
      enero: 0,
      febrero: 1,
      marzo: 2,
      abril: 3,
      mayo: 4,
      junio: 5,
      julio: 6,
      agosto: 7,
      septiembre: 8,
      octubre: 9,
      noviembre: 10,
      diciembre: 11,
    };

    const parseFecha = fecha => {
      const [dia, mesNombre, año] = fecha.split('/');
      const mes = meses[mesNombre.toLowerCase()];
      const date = new Date(año, mes, dia);
      const offset = date.getTimezoneOffset() / 60;
      date.setHours(date.getHours() - offset + 6);
      return date;
    };

    this.reestructuracion.fechaReestructuracion = parseFecha(this.reestructuracion.fechaReestructuracion);
    this.reestructuracion.fechaInicioOperacion = parseFecha(this.reestructuracion.fechaInicioOperacion);

    this.reestructuracionIndex = index;
    this.mostrarSeccionRegistro = true;
  }

  /**
   * Elimina una reestructuración de la lista de reestructuraciones.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public eliminarReestructuracion(reestructuracion: Reestructuracion) {
    this.reestructuraciones = this.reestructuraciones.filter(res => res !== reestructuracion);
    this.guardarReestructuracion();
  }

  /**
   * Muestra un modal de confirmación para eliminar una reestructuración.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public mostrarModalConfirmacionEliminarReestructuracion(reestructuracion): void {
    this.reestructuracionAEliminar = reestructuracion;
    this.confirmarModalEliminar.show();
  }

  /**
   * Método para confirmar la acción de eliminación de una reestructuración.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public confirmarAccionEliminarReestructuracion(): void {
    this.eliminarReestructuracion(this.reestructuracionAEliminar);

    this.confirmarModalEliminar.hide();
  }

  /**
   * Oculta el modal de eliminar una reestructuración.
   *
   * @memberof RegistroReestructuracionComponent
   */
  public ocultarModalEliminarReestructuracion(): void {
    this.confirmarModalEliminar.hide();
  }

  /**
   * Controla el deshabilitado de los campos y botones.
   *
   * @memberof RegistroReestructuracionComponent
   */
  get desactivarBoton(): boolean {
    return this.$v.$invalid;
  }

  /**
   * Determina si el campo debe habilitarse.
   *
   * @memberof RegistroReestructuracionComponent
   */
  get deshabilitarCampo(): boolean {
    return this.solicitud.estado === EstadoSolicitud.EN_CAPTURA || this.solicitud.estado === EstadoSolicitud.REVISADA;
  }

  /**
   * Convierte una fecha de tipo 'día/nombre del mes/año' a formato 'yyyy-MM-dd' para guardar en DB.
   *
   * @memberof RegistroReestructuracionComponent
   */
  private convertirFechaAGuardar(fecha: string | Date): string {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];

    let dia: string;
    let mesNombre: string;
    let ano: string;

    if (typeof fecha === 'string') {
      const partes = fecha.split('/');
      dia = partes[0].trim();
      mesNombre = partes[1].trim();
      ano = partes[2].trim();
    } else {
      dia = fecha.getDate().toString();
      mesNombre = meses[fecha.getMonth()];
      ano = fecha.getFullYear().toString();
    }

    const mesIndex = meses.indexOf(mesNombre.toLowerCase());
    const fechaConvertida = new Date(parseInt(ano), mesIndex, parseInt(dia));

    return format(fechaConvertida, 'yyyy-MM-dd');
  }

  /**
   * Formatea una fecha a una cadena en formato `día/nombre mes/año`,
   * aumentando la hora en 6 horas.
   *
   * @memberof RegistroReestructuracionComponent
   */
  private formatearFechaGuardada(fecha: string | Date): string {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];

    const date = typeof fecha === 'string' ? new Date(fecha) : fecha;
    date.setHours(date.getHours() + 6);

    const dia = date.getDate();
    const mes = meses[date.getMonth()];
    const año = date.getFullYear();

    return `${dia}/${mes}/${año}`;
  }
}
