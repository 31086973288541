import {
  CaracteristicaPrograma,
  GradoPrograma,
  ModalidadPrograma,
  OrientacionPrograma,
  TipoConvocatoria,
  TipoPrograma,
} from '../administracion/programas/programa.model';
import { TipoReporteSnp } from '../administracion/reportes/reportes-snp.model';
import { TiempoDedicacionClaustroSnp } from '../claustro/claustro.model';
import { ClasificacionLieSnp, TemasLieSnp } from '../lie/lie.model';
import { AreaPronaceSnp, ModalidadPronaceSnp, SubtemaPronaceSnp, TemaPronaceSnp } from '../pronaces/pronace.model';
import { AreaTemasSnp, TemaTemasSnp } from '../temas/temas.model';

export default class CatalogosSnp {
  public static obtenerClasificaciones(): Array<ClasificacionLieSnp> {
    return [
      { id: 'APLICACION', nombre: 'Aplicación' },
      { id: 'GENERACION', nombre: 'Generación' },
      { id: 'AMBAS', nombre: 'Ambas' },
    ];
  }

  public static obtenerTemasPrioritarios(): Array<TemasLieSnp> {
    return [
      { claveAreaPeciti: 1, clavePrioridadPeciti: 1, areaPeciti: 'ENERGÍA', prioridadPeciti: 'Consumo sustentable de energía*' },
      {
        claveAreaPeciti: 1,
        clavePrioridadPeciti: 2,
        areaPeciti: 'ENERGÍA',
        prioridadPeciti: 'Prospección, extracción y aprovechamiento de hidrocarburos',
      },
      {
        claveAreaPeciti: 1,
        clavePrioridadPeciti: 3,
        areaPeciti: 'ENERGÍA',
        prioridadPeciti: 'Desarrollo y aprovechamiento de energías renovables y limpias*',
      },
      { claveAreaPeciti: 2, clavePrioridadPeciti: 4, areaPeciti: 'SALUD', prioridadPeciti: 'Conducta humana y prevención de adicciones*' },
      { claveAreaPeciti: 2, clavePrioridadPeciti: 5, areaPeciti: 'SALUD', prioridadPeciti: 'Medicina preventiva y atención de la salud' },
      {
        claveAreaPeciti: 2,
        clavePrioridadPeciti: 6,
        areaPeciti: 'SALUD',
        prioridadPeciti: 'Enfermedades emergentes y de importancia nacional*',
      },
      { claveAreaPeciti: 2, clavePrioridadPeciti: 7, areaPeciti: 'SALUD', prioridadPeciti: 'Desarrollo de la bioingeniería' },
      {
        claveAreaPeciti: 3,
        clavePrioridadPeciti: 8,
        areaPeciti: 'AMBIENTE',
        prioridadPeciti: 'Gestión integral del agua, seguridad hídrica y derecho al agua*',
      },
      { claveAreaPeciti: 3, clavePrioridadPeciti: 9, areaPeciti: 'AMBIENTE', prioridadPeciti: 'Los océanos y su aprovechamiento' },
      {
        claveAreaPeciti: 3,
        clavePrioridadPeciti: 10,
        areaPeciti: 'AMBIENTE',
        prioridadPeciti: 'Mitigación y adaptación al cambio climático*',
      },
      {
        claveAreaPeciti: 3,
        clavePrioridadPeciti: 11,
        areaPeciti: 'AMBIENTE',
        prioridadPeciti: 'Aprovechamiento y protección de ecosistemas y de la biodiversidad*',
      },
      {
        claveAreaPeciti: 3,
        clavePrioridadPeciti: 12,
        areaPeciti: 'AMBIENTE',
        prioridadPeciti: 'Resiliencia frente a desastres naturales y tecnológicos*',
      },
      {
        claveAreaPeciti: 4,
        clavePrioridadPeciti: 13,
        areaPeciti: 'SOCIEDAD',
        prioridadPeciti: 'Combate a la pobreza y seguridad alimentaria*',
      },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 14, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Comunicación pública de la ciencia' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 15, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Economía del conocimiento' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 16, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Sociedad y economía digital*' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 17, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Estudios de la cultura humana' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 18, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Migraciones y asentamientos humanos*' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 19, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Prevención de riesgos naturales*' },
      { claveAreaPeciti: 4, clavePrioridadPeciti: 20, areaPeciti: 'SOCIEDAD', prioridadPeciti: 'Seguridad ciudadana*' },
      {
        claveAreaPeciti: 5,
        clavePrioridadPeciti: 21,
        areaPeciti: 'CONOCIMIENTO DEL UNIVERSO',
        prioridadPeciti: 'Estudios de astronomía y de cosmología',
      },
      {
        claveAreaPeciti: 5,
        clavePrioridadPeciti: 22,
        areaPeciti: 'CONOCIMIENTO DEL UNIVERSO',
        prioridadPeciti: 'Estudios de física, matemáticas, química y sus aplicaciones',
      },
      {
        claveAreaPeciti: 5,
        clavePrioridadPeciti: 23,
        areaPeciti: 'CONOCIMIENTO DEL UNIVERSO',
        prioridadPeciti: 'Estudio de las geociencias y sus aplicaciones',
      },
      { claveAreaPeciti: 6, clavePrioridadPeciti: 24, areaPeciti: 'DESARROLLO TECNOLÓGICO', prioridadPeciti: 'Automatización y robótica' },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 25,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti: 'Desarrollo de la biotecnología*',
      },
      { claveAreaPeciti: 6, clavePrioridadPeciti: 26, areaPeciti: 'DESARROLLO TECNOLÓGICO', prioridadPeciti: 'Desarrollo de la genómica' },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 27,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti: 'Desarrollo de materiales avanzados*',
      },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 28,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti: 'Desarrollo de nanomateriales y de nanotecnología*',
      },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 29,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti:
          'Conectividad informática y desarrollo de las tecnologías de la información, la comunicación y las telecomunicaciones*',
      },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 30,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti: 'Ingenierías para incrementar el valor agregado en las industrias',
      },
      {
        claveAreaPeciti: 6,
        clavePrioridadPeciti: 31,
        areaPeciti: 'DESARROLLO TECNOLÓGICO',
        prioridadPeciti: 'Manufactura de alta tecnología*',
      },
      { claveAreaPeciti: 7, clavePrioridadPeciti: 32, areaPeciti: 'DESARROLLO SUSTENTABLE', prioridadPeciti: 'Alimentos y su producción*' },
      {
        claveAreaPeciti: 7,
        clavePrioridadPeciti: 33,
        areaPeciti: 'DESARROLLO SUSTENTABLE',
        prioridadPeciti: 'Aspectos normativos para la consolidación institucional',
      },
      {
        claveAreaPeciti: 7,
        clavePrioridadPeciti: 34,
        areaPeciti: 'DESARROLLO SUSTENTABLE',
        prioridadPeciti: 'Ciudades y desarrollo urbano*',
      },
      {
        claveAreaPeciti: 7,
        clavePrioridadPeciti: 35,
        areaPeciti: 'DESARROLLO SUSTENTABLE',
        prioridadPeciti: 'Estudios de política pública y de prospectiva*',
      },
    ];
  }

  public static obtenerModalidadesPronaces(): Array<ModalidadPronaceSnp> {
    return [
      {
        claveModalidadPronace: 1,
        pronace: 'Fortalecimiento de procesos sociocomunitarios y del enfoque intercultural',
      },
      {
        claveModalidadPronace: 2,
        pronace: 'Áreas afines a PRONACES - Temas Prioritarios',
      },
      {
        claveModalidadPronace: 3,
        pronace: 'Áreas afines a PRONACES - Atención a problemas estructurales de salud',
      },
      {
        claveModalidadPronace: 4,
        pronace: 'Áreas afines a PRONACES - Fortalecimiento de la cultura y las humanidades',
      },
      {
        claveModalidadPronace: 5,
        pronace: 'Especialidades médicas en áreas prioritarias',
      },
      {
        claveModalidadPronace: 6,
        pronace: 'Ciencia básica y/o de Frontera',
      },
      {
        claveModalidadPronace: 7,
        pronace: 'Posgrados con la industria (tecnológicos)',
      },
    ];
  }

  public static obtenerArea(): Array<AreaPronaceSnp> {
    return [
      {
        claveArea: 0,
        area: 'Otra',
      },
      {
        claveArea: 1,
        area: 'Salud',
      },
      {
        claveArea: 2,
        area: 'Agua',
      },
      {
        claveArea: 3,
        area: 'Educación',
      },
      {
        claveArea: 4,
        area: 'Seguridad Humana',
      },
      {
        claveArea: 5,
        area: 'Soberanía Alimentaria',
      },
      {
        claveArea: 6,
        area: 'Agentes Tóxicos',
      },
      {
        claveArea: 7,
        area: 'Energía y Cambio Climático',
      },
      {
        claveArea: 8,
        area: 'Sistemas Socioecológico',
      },
    ];
  }

  public static obtenerTemas(claveArea: number): Array<TemaPronaceSnp> {
    const temas = [
      { claveArea: 0, claveTema: 0, tema: 'Otra' },
      { claveArea: 1, claveTema: 1, tema: 'Alimentación' },
      { claveArea: 1, claveTema: 2, tema: 'Caracterización genotípica' },
      { claveArea: 1, claveTema: 3, tema: 'Desarrollo y validación de candidatos vacunales para SARS-CoV-2' },
      { claveArea: 1, claveTema: 4, tema: 'Encuesta Nacional de Salud Mental' },
      { claveArea: 1, claveTema: 5, tema: 'Enfermedades Crónicas no' },
      { claveArea: 1, claveTema: 6, tema: 'Ensayos preclínicos' },
      { claveArea: 1, claveTema: 7, tema: 'Evaluación de riesgo en salud' },
      { claveArea: 1, claveTema: 8, tema: 'Inmunotipificación' },
      { claveArea: 1, claveTema: 9, tema: 'Inteligencia artificial para diagnóstico de cáncer' },
      { claveArea: 1, claveTema: 10, tema: 'Interoperabilidad de sistemas de expediente electrónico único' },
      { claveArea: 1, claveTema: 11, tema: 'Plaguicidas' },
      { claveArea: 1, claveTema: 12, tema: 'Protocolo Clínico Nasalferon (IFN-2b)' },
      { claveArea: 1, claveTema: 13, tema: 'Protocolo Clínico-COVID-19 Jusvinza (CIGB-258)' },
      { claveArea: 1, claveTema: 14, tema: 'Salud Mental y Adicciones' },
      { claveArea: 1, claveTema: 15, tema: 'Salud Mental y COVID-19' },
      { claveArea: 1, claveTema: 16, tema: 'Transmisibles de alta prevalencia' },
      { claveArea: 1, claveTema: 17, tema: 'Vigilancia epidemiológica de adenovirus' },
      { claveArea: 1, claveTema: 18, tema: 'Vigilancia genómica de SARS-CoV-2' },
      { claveArea: 1, claveTema: 19, tema: 'Vigilancia molecular de secuencias virales' },
      { claveArea: 2, claveTema: 20, tema: 'El derecho humano al agua y de los demás derechos humanos y de la naturaleza asociados' },
      { claveArea: 2, claveTema: 21, tema: 'Instituciones locales y comunitarias de gestión del agua, tanto rurales como urbanas' },
      { claveArea: 2, claveTema: 22, tema: 'Protección de las comunidades humanas y no humanas frente a los desastres hídricos' },
      { claveArea: 2, claveTema: 23, tema: 'Restauración de cuencas, acuíferos, ríos, lagos y humedales del país' },
      { claveArea: 2, claveTema: 24, tema: 'Seguimiento hídrico, toxicológico y epidemiológico' },
      { claveArea: 2, claveTema: 25, tema: 'Uso sostenible, eficiente y equitativo del agua y sus tecnologías' },
      { claveArea: 3, claveTema: 26, tema: 'Competencia lectora' },
      { claveArea: 3, claveTema: 27, tema: 'Escritura funcional y creativa' },
      { claveArea: 3, claveTema: 28, tema: 'Evaluación horizontal de la lectura y la escritura' },
      { claveArea: 3, claveTema: 29, tema: 'Lectura y escritura en contextos multilingües' },
      { claveArea: 3, claveTema: 30, tema: 'Lectura y escritura para la participación ciudadana' },
      { claveArea: 3, claveTema: 31, tema: 'Procesos alternativos en la formación de la lectura y escritura' },
      { claveArea: 4, claveTema: 32, tema: 'Desaparición forzada de personas' },
      { claveArea: 4, claveTema: 33, tema: 'Educación para la ciudadanía y la paz' },
      {
        claveArea: 4,
        claveTema: 34,
        tema: 'Integración de las personas en movilidad, basada en el reconocimiento de su pertenencia, el respeto asus derechos y el desarrollo de las comunidades que las acogen',
      },
      { claveArea: 4, claveTema: 35, tema: 'Jóvenes, violencias y juvenicidio' },
      {
        claveArea: 4,
        claveTema: 36,
        tema: 'Marco normativo de las políticas públicas de atención a personas y grupos en movilidad y sus derechos humanos',
      },
      {
        claveArea: 4,
        claveTema: 37,
        tema: 'Movilidad internacional como fenómeno que genera formas y prácticas de vida que cuestionan y trascienden los límites conceptuales de la ciudadanía',
      },
      { claveArea: 4, claveTema: 38, tema: 'Protección de los derechos de las personas y grupos en movilidad especialmente vulnerables' },
      { claveArea: 4, claveTema: 39, tema: 'Racismos y violencias: pueblos indígenas, afrodescendientes y otros grupos racializados' },
      { claveArea: 4, claveTema: 40, tema: 'Violencias contra comunicadores, activistas y luchadores sociales' },
      { claveArea: 4, claveTema: 41, tema: 'Violencias de género' },
      { claveArea: 5, claveTema: 42, tema: 'Alternativas a la siembra y el consumo de cultivos GM' },
      { claveArea: 5, claveTema: 43, tema: 'Autoabasto familiar rural' },
      { claveArea: 5, claveTema: 44, tema: 'Autoabasto familiar urbano y periurbano' },
      {
        claveArea: 5,
        claveTema: 45,
        tema: 'Campañas y jornadas para una alimentación segura, saludable, nutritiva, culturalmente adecuada y en cantidad suficiente',
      },
      {
        claveArea: 5,
        claveTema: 46,
        tema: 'Enfoques científico-técnicos contemporáneos, que beneficien y aprovechen la riqueza genética de variedades nativas en México de maíz, frijol o jitomate',
      },
      { claveArea: 5, claveTema: 47, tema: 'Producción comercial de alimentos sanos para población de bajos ingresos' },
      { claveArea: 5, claveTema: 48, tema: 'Producción y abasto popular sostenible de alimentos pesqueros y acuícolas' },
      { claveArea: 5, claveTema: 49, tema: 'Redes de producción-consumo de alimentos saludables de especialidad' },
      { claveArea: 5, claveTema: 50, tema: 'Uso de agroquímicos nocivos en actividades agropecuarias' },
      { claveArea: 6, claveTema: 51, tema: 'Biomonitoreo y salud ambiental' },
      { claveArea: 6, claveTema: 52, tema: 'Educación y capacitación' },
      { claveArea: 6, claveTema: 53, tema: 'Fortalecimiento de la capacidad de respuesta social' },
      { claveArea: 6, claveTema: 54, tema: 'Monitoreo y diagnóstico ambiental de contaminantes prioritarios' },
      { claveArea: 6, claveTema: 55, tema: 'Políticas públicas' },
      { claveArea: 7, claveTema: 56, tema: 'Clima urbano, determinantes y proyecciones con cambio climático' },
      { claveArea: 7, claveTema: 57, tema: 'Democratización de la energía y generación de energía distribuida comunitaria' },
      {
        claveArea: 7,
        claveTema: 58,
        tema: 'Impactos del cambio climático y/o de la contaminación atmosférica, vulnerabilidad, adaptación y resiliencia',
      },
      {
        claveArea: 7,
        claveTema: 59,
        tema: 'Instrumentos de planeación y gestión, innovación tecnológica y mecanismos de interacción entre los distintos sectores de las ciudades seleccionadas, y con su entorno periurbano, para alcanzar los objetivos de adaptación, mitigación y mejora de la calidad del aire',
      },
      {
        claveArea: 7,
        claveTema: 60,
        tema: 'Mitigación de emisiones en el corto, mediano y largo plazo relacionadas con la calidad del aire  y el cambio climático',
      },
      { claveArea: 7, claveTema: 61, tema: 'Movilidad Sustentable' },
      { claveArea: 7, claveTema: 62, tema: 'Sistemas energéticos rurales sustentables' },
      {
        claveArea: 7,
        claveTema: 63,
        tema: 'Transformaciones sociales y político administrativas para lograr un cambio en el desarrollo de las ciudades seleccionadas tendiente a la reducción de desigualdades, el aumento de resiliencia, la adaptación al cambio climático y la neutralidad de emisiones de carbono',
      },
      { claveArea: 7, claveTema: 64, tema: 'Uso eficiente y energía verde para la industria' },
      { claveArea: 8, claveTema: 65, tema: 'Aprovechamiento sustentable, restauración o conservación de ecosistemas' },
      { claveArea: 8, claveTema: 66, tema: 'Apuntalamiento de programas federales' },
      { claveArea: 8, claveTema: 67, tema: 'Conflictos socioambientales' },
      { claveArea: 8, claveTema: 68, tema: 'Diseño de instrumentos de planeación territorial' },
      { claveArea: 8, claveTema: 69, tema: 'Problemas de salud ambiental y humana' },
      { claveArea: 8, claveTema: 70, tema: 'Sustentabilidad de los sistemas alimentarios' },
      { claveArea: 9, claveTema: 71, tema: 'Cambios institucionales que facilitarían la vivienda adecuada y el acceso justo al hábitat' },
      {
        claveArea: 9,
        claveTema: 72,
        tema: 'Políticas e instrumentos de desarrollo urbano y ordenamiento territorial para el acceso justo al hábitat',
      },
      { claveArea: 9, claveTema: 73, tema: 'Producción y gestión de vivienda adecuada y del habitar sustentable' },
      {
        claveArea: 10,
        claveTema: 74,
        tema: 'Espacio y el territorio como sustento de las memorias y de procesos culturales y bioculturales',
      },
      { claveArea: 10, claveTema: 75, tema: 'Metodologías y enfoques que vinculen el entorno con significaciones simbólicas, saberes y' },
      { claveArea: 10, claveTema: 76, tema: 'Producción de Memorias y narrativas colectivas' },
      { claveArea: 10, claveTema: 77, tema: 'Protección de acervos documentales en riesgo' },
      { claveArea: 10, claveTema: 78, tema: 'Saberes locales asociados a la preservación sustentable de la diversidad biológica' },
    ];

    return temas.filter(tema => tema.claveArea == claveArea);
  }

  public static obtenerSubtemas(claveArea: number): Array<SubtemaPronaceSnp> {
    const subtemas = [
      { claveArea: 0, claveSubtema: 0, subtema: 'Otra' },
      { claveArea: 1, claveSubtema: 1, subtema: 'Análisis de macrodatos y metadatos' },
      { claveArea: 1, claveSubtema: 2, subtema: 'Análisis de metadatos' },
      { claveArea: 1, claveSubtema: 3, subtema: 'Análisis epidemiológico' },
      { claveArea: 1, claveSubtema: 4, subtema: 'Análisis estadístico para ensayos clínicos' },
      { claveArea: 1, claveSubtema: 5, subtema: 'Bioinformática (análisis de datos de secuenciación)' },
      { claveArea: 1, claveSubtema: 6, subtema: 'Citrometría de masas' },
      { claveArea: 1, claveSubtema: 7, subtema: 'Desarrollo y gestión de repositorios' },
      { claveArea: 1, claveSubtema: 8, subtema: 'Desarrollo y validación de tele-herramientas para diagnóstico' },
      { claveArea: 1, claveSubtema: 9, subtema: 'Epidemiologia ambiental' },
      { claveArea: 1, claveSubtema: 10, subtema: 'Geografía y sistemas de información geográfica' },
      { claveArea: 1, claveSubtema: 11, subtema: 'Implementación y evaluación de modelos de intervención' },
      { claveArea: 1, claveSubtema: 12, subtema: 'Inmunovirología (ensayos de neutralización)' },
      { claveArea: 1, claveSubtema: 13, subtema: 'Intervenciones comunitarias de prevención y control de enfermedades crónicas' },
      { claveArea: 1, claveSubtema: 14, subtema: 'Intervenciones comunitarias para comunicación de riesgo' },
      { claveArea: 1, claveSubtema: 15, subtema: 'Pruebas de eficacia de inmunogenicidad' },
      { claveArea: 1, claveSubtema: 16, subtema: 'Secuenciación genómica' },
      { claveArea: 1, claveSubtema: 17, subtema: 'Sistemas de salud y políticas públicas' },
      { claveArea: 1, claveSubtema: 18, subtema: 'Vinculación y gestión de protocolos preclínicos y clínicos' },
      { claveArea: 2, claveSubtema: 19, subtema: 'Acción participativa de las comunidades' },
      {
        claveArea: 2,
        claveSubtema: 20,
        subtema: 'Alternativas técnicas, organizacionales y culturales que conduzcan al máximo ahorro posible de agua para la producción',
      },
      {
        claveArea: 2,
        claveSubtema: 21,
        subtema:
          'Comprender, prevenir y revertir los impactos destructivos de las actividades económicas y los patrones de urbanización en los ecosistemas, cuencas, cuerpos y',
      },
      { claveArea: 2, claveSubtema: 22, subtema: 'Corrupción en el manejo y gestión de los recursos hídricos' },
      { claveArea: 2, claveSubtema: 23, subtema: 'Derechos individuales y colectivos' },
      { claveArea: 2, claveSubtema: 24, subtema: 'Finanzas de agua' },
      { claveArea: 2, claveSubtema: 25, subtema: 'flujos de agua' },
      { claveArea: 2, claveSubtema: 26, subtema: 'Justicia distributiva y restaurativa' },
      { claveArea: 2, claveSubtema: 27, subtema: 'Mitigación y adaptación a los efectos del cambio climático' },
      { claveArea: 2, claveSubtema: 28, subtema: 'Planificación del desarrollo económico e institucional regional' },
      { claveArea: 2, claveSubtema: 29, subtema: 'Políticas públicas' },
      { claveArea: 2, claveSubtema: 30, subtema: 'Vigilancia, monitoreo y evaluación ambiental' },
      { claveArea: 3, claveSubtema: 31, subtema: 'Alfabetización' },
      { claveArea: 3, claveSubtema: 32, subtema: 'Competencia comunicativa del lenguaje escrito' },
      { claveArea: 3, claveSubtema: 33, subtema: 'Escritura creativa' },
      { claveArea: 3, claveSubtema: 34, subtema: 'Escritura funcional' },
      { claveArea: 3, claveSubtema: 35, subtema: 'Lenguas indígenas' },
      { claveArea: 3, claveSubtema: 36, subtema: 'Políticas públicas educativas' },
      { claveArea: 3, claveSubtema: 37, subtema: 'Procesos alternativos en el uso del lenguaje escrito' },
      { claveArea: 4, claveSubtema: 38, subtema: 'Acceso de derechos humanos' },
      {
        claveArea: 4,
        claveSubtema: 39,
        subtema: 'Análisis de archivos judiciales, etnografías de espacios burocráticos, así como procesos de judicialización',
      },
      {
        claveArea: 4,
        claveSubtema: 40,
        subtema:
          'Análisis de la relación de las violencias ejercidas con factores económicos, sociales, políticos e impacto del crimen organizado en las diversas regiones del país',
      },
      { claveArea: 4, claveSubtema: 41, subtema: 'Aplicación, seguimiento y evaluación de la política pública de movilidad humana' },
      { claveArea: 4, claveSubtema: 42, subtema: 'Aspectos estructurales de la movilidad' },
      {
        claveArea: 4,
        claveSubtema: 43,
        subtema: 'Causas biopsicosociales e históricas de la violencia de género (victimarios y agresores)',
      },
      {
        claveArea: 4,
        claveSubtema: 44,
        subtema: 'Causas multifactoriales que inciden en las violencias vividas por los comunicadores y defensores de derechos humanos',
      },
      { claveArea: 4, claveSubtema: 45, subtema: 'Condiciones de vulnerabilidad' },
      {
        claveArea: 4,
        claveSubtema: 46,
        subtema: 'Contextos que posibilitan la desaparición, formas de desaparición y perfiles de los perpetradores',
      },
      { claveArea: 4, claveSubtema: 47, subtema: 'Derechos humanos' },
      { claveArea: 4, claveSubtema: 48, subtema: 'Desaparición forzada' },
      {
        claveArea: 4,
        claveSubtema: 49,
        subtema: 'Desaparición forzada en otras regiones de América Latina y las estrategias desarrolladas en la búsqueda',
      },
      { claveArea: 4, claveSubtema: 50, subtema: 'Discriminación' },
      {
        claveArea: 4,
        claveSubtema: 51,
        subtema:
          'Diseño de políticas y mecanismos que faciliten el ejercicio de la ciudadanía transnacional (identidad, regularización migratoria, salud, educación, unidad familiar e inclusión social)',
      },
      { claveArea: 4, claveSubtema: 52, subtema: 'Educación centrada en el conocimiento de los derechos humanos' },
      { claveArea: 4, claveSubtema: 53, subtema: 'Educación centrada en la compresión de la otredad' },
      {
        claveArea: 4,
        claveSubtema: 54,
        subtema: 'Educación centrada en una ciudadanía cultural, que permita identificar la violencia que se ejerce de manera cotidiana',
      },
      { claveArea: 4, claveSubtema: 55, subtema: 'Educación con perspectiva comunitaria' },
      { claveArea: 4, claveSubtema: 56, subtema: 'en territorios indígenas, personas migrantes' },
      { claveArea: 4, claveSubtema: 57, subtema: 'Escenarios de vida y escenarios forenses que coadyuven a la' },
      {
        claveArea: 4,
        claveSubtema: 58,
        subtema: 'Estereotipos y estigmas sobre la juventud que reproducen los medios masivos de comunicación',
      },
      {
        claveArea: 4,
        claveSubtema: 59,
        subtema: 'Estrategias para la atención y prevención de las agresiones y mejor protección a periodistas y activistas',
      },
      {
        claveArea: 4,
        claveSubtema: 60,
        subtema:
          'Evaluación de la efectividad de los mecanismos de prevención, protección y procuración de justicia (desde las organizaciones sociales y el gobierno)',
      },
      { claveArea: 4, claveSubtema: 61, subtema: 'Evaluación de los servicios en violencia de género' },
      {
        claveArea: 4,
        claveSubtema: 62,
        subtema:
          'Evaluación de Programas sociales, culturales o comunitarios que hayan sido exitosos en fomentar la no violencia de género',
      },
      {
        claveArea: 4,
        claveSubtema: 63,
        subtema: 'Factores transversales de los procesos de movilidad humana (edad, género y pertenencia étnica)',
      },
      {
        claveArea: 4,
        claveSubtema: 64,
        subtema: 'Formas de violencia que las y los jóvenes enfrentan en su vida cotidiana (doméstica y en espacios escolares y virtuales)',
      },
      {
        claveArea: 4,
        claveSubtema: 65,
        subtema: 'Impacto de la creciente violencia que satura los espacios digitales, a los que tienen accesos los jóvenes',
      },
      { claveArea: 4, claveSubtema: 66, subtema: 'Instrumentos y acciones de las políticas de movilidad humanas vigentes' },
      { claveArea: 4, claveSubtema: 67, subtema: 'Interculturalidad' },
      { claveArea: 4, claveSubtema: 68, subtema: 'Localización de personas' },
      { claveArea: 4, claveSubtema: 69, subtema: 'Marco prohibicionista y perspectivas punitivas sobre el consumo de drogas' },
      { claveArea: 4, claveSubtema: 70, subtema: 'Mecanismos de atención oportuna o de prevención' },
      {
        claveArea: 4,
        claveSubtema: 71,
        subtema: 'Mecanismos de operación del clasismo/racismo/discriminación étnica en el nuevo sistema judicial y penitenciario',
      },
      { claveArea: 4, claveSubtema: 72, subtema: 'Migración interna' },
      { claveArea: 4, claveSubtema: 73, subtema: 'Migración internacional' },
      {
        claveArea: 4,
        claveSubtema: 74,
        subtema:
          'Movilidad interna e internacional (refugiados, inmigrantes, retornados, desplazados internos y jornaleros agrícolas migrantes)',
      },
      {
        claveArea: 4,
        claveSubtema: 75,
        subtema: 'Movilización jurídica y política de los pueblos indígenas y alternativas que aporten a la defensa de sus identidades',
      },
      { claveArea: 4, claveSubtema: 76, subtema: 'Narcoviolencia que afecta a la juventud' },
      {
        claveArea: 4,
        claveSubtema: 77,
        subtema:
          'Política nacional de protección de los derechos de grupos en movilidad vulnerables (niñas, niños y adolescentes, migrantes en tránsito, migrantes jornaleros y desplazados internos)',
      },
      { claveArea: 4, claveSubtema: 78, subtema: 'Políticas de memoria, justicia y reparación' },
      { claveArea: 4, claveSubtema: 79, subtema: 'Políticas para la integración de poblaciones en movilidad' },
      { claveArea: 4, claveSubtema: 80, subtema: 'Políticas públicas de prevención' },
      { claveArea: 4, claveSubtema: 81, subtema: 'Racismo a los pueblos indígenas y afromexicanos' },
      { claveArea: 4, claveSubtema: 82, subtema: 'Reconocimiento de la ciudadanía transnacional' },
      { claveArea: 4, claveSubtema: 83, subtema: 'Sistematización, registro y análisis bioantropológico de restos humanos' },
      { claveArea: 4, claveSubtema: 84, subtema: 'Trata y desaparición forzada con fines de explotación sexual' },
      { claveArea: 4, claveSubtema: 85, subtema: 'Violaciones a los derechos humanos de pueblos y comunidades indígenas' },
      { claveArea: 4, claveSubtema: 86, subtema: 'Violencia contra las mujeres: niñas y adolescentes' },
      { claveArea: 4, claveSubtema: 87, subtema: 'Violencia doméstica y conyugal y sexual' },
      { claveArea: 4, claveSubtema: 88, subtema: 'Violencia y corrupción institucional' },
      { claveArea: 5, claveSubtema: 89, subtema: 'Agroecología' },
      { claveArea: 5, claveSubtema: 90, subtema: 'Bancos de semillas comunitarios' },
      { claveArea: 5, claveSubtema: 91, subtema: 'Comercialización de productos pesqueros y acuícolas' },
      { claveArea: 5, claveSubtema: 92, subtema: 'Conocimiento y uso de plantas y variedades nativas de frijol, maíz y jitomate' },
      { claveArea: 5, claveSubtema: 93, subtema: 'Control cultural de plagas y enfermedades' },
      { claveArea: 5, claveSubtema: 94, subtema: 'Economía solidaria' },
      { claveArea: 5, claveSubtema: 95, subtema: 'Fertilización natural y abonos verdes' },
      { claveArea: 5, claveSubtema: 96, subtema: 'Genética agrícola' },
      { claveArea: 5, claveSubtema: 97, subtema: 'Huertos familiares y escolares' },
      {
        claveArea: 5,
        claveSubtema: 98,
        subtema: 'Mejoramiento de las actividades pesqueras y acuícolas responsables con el medio ambiente',
      },
      { claveArea: 5, claveSubtema: 99, subtema: 'Patrimonio agroecológico de comunidades y pueblos' },
      { claveArea: 5, claveSubtema: 100, subtema: 'Políticas públicas' },
      { claveArea: 5, claveSubtema: 101, subtema: 'Producción y abasto a escala familiar' },
      { claveArea: 5, claveSubtema: 102, subtema: 'Producción y comercialización de productos agrícolas de especialidad' },
      { claveArea: 5, claveSubtema: 103, subtema: 'Química verde y uso de agroquímicos inocuos' },
      { claveArea: 5, claveSubtema: 104, subtema: 'Redes de consumo familiar y comunitario' },
      { claveArea: 5, claveSubtema: 105, subtema: 'Redes de producción y consumo familiar y comunitario' },
      { claveArea: 5, claveSubtema: 106, subtema: 'Sistemas agro pastoriles y agrosilvícolas' },
      { claveArea: 5, claveSubtema: 107, subtema: 'Transferencia de conocimientos y apropiación de saberes a nivel regional y nacional' },
      {
        claveArea: 6,
        claveSubtema: 108,
        subtema: 'Capacidades de organización, análisis, comunicación, negociación y diálogo de las comunidades afectadas',
      },
      { claveArea: 6, claveSubtema: 109, subtema: 'Diálogo de saberes, trabajo participativo y transdisciplinario' },
      { claveArea: 6, claveSubtema: 110, subtema: 'Dispersión y exposición de contaminantes' },
      { claveArea: 6, claveSubtema: 111, subtema: 'Fuentes y rutas de contaminación' },
      { claveArea: 6, claveSubtema: 112, subtema: 'Herramientas jurídicas' },
      { claveArea: 6, claveSubtema: 113, subtema: 'Mapas de riesgo' },
      { claveArea: 6, claveSubtema: 114, subtema: 'Normas y políticas ambientales y de protección a la salud' },
      { claveArea: 6, claveSubtema: 115, subtema: 'Ordenamiento territorial y ambiental' },
      { claveArea: 6, claveSubtema: 116, subtema: 'Riesgos en la salud por exposición crónica' },
      { claveArea: 7, claveSubtema: 117, subtema: 'Aplicación de nuevas tecnologías y modelos de negocio' },
      { claveArea: 7, claveSubtema: 118, subtema: 'Cambios conductuales y ciencias del comportamiento humano' },
      { claveArea: 7, claveSubtema: 119, subtema: 'Centros de trabajo a distancia' },
      { claveArea: 7, claveSubtema: 120, subtema: 'Contaminación atmosférica' },
      {
        claveArea: 7,
        claveSubtema: 121,
        subtema: 'Diseño e implementación participativa de sistemas energéticos para comunidades rurales',
      },
      { claveArea: 7, claveSubtema: 122, subtema: 'Distribución y logística' },
      { claveArea: 7, claveSubtema: 123, subtema: 'Economía circular' },
      { claveArea: 7, claveSubtema: 124, subtema: 'Efectos en la población humana' },
      { claveArea: 7, claveSubtema: 125, subtema: 'Electromovilidad y transporte público' },
      { claveArea: 7, claveSubtema: 126, subtema: 'Generación de energía distribuida para democratizar la energía' },
      { claveArea: 7, claveSubtema: 127, subtema: 'Modificación de patrones de producción y consumo urbanos' },
      { claveArea: 7, claveSubtema: 128, subtema: 'Movilidad virtual' },
      { claveArea: 7, claveSubtema: 129, subtema: 'Planeación territorial' },
      { claveArea: 7, claveSubtema: 130, subtema: 'Planeación urbana y vulnerabilidad' },
      { claveArea: 7, claveSubtema: 131, subtema: 'Políticas de desarrollo local' },
      { claveArea: 7, claveSubtema: 132, subtema: 'Políticas públicas' },
      { claveArea: 7, claveSubtema: 133, subtema: 'Riesgos climáticos y sus costos' },
      { claveArea: 7, claveSubtema: 134, subtema: 'Sistemas de gobernanza' },
      { claveArea: 7, claveSubtema: 135, subtema: 'Sumideros de carbono urbanos' },
      { claveArea: 7, claveSubtema: 136, subtema: 'Sustitución de combustibles fósiles' },
      { claveArea: 7, claveSubtema: 137, subtema: 'Uso eficiente de energía' },
      { claveArea: 8, claveSubtema: 138, subtema: 'Acuícolas o marinos' },
      { claveArea: 8, claveSubtema: 139, subtema: 'Agroecológicos' },
      { claveArea: 8, claveSubtema: 140, subtema: 'Agroforestales' },
      { claveArea: 8, claveSubtema: 141, subtema: 'Conservación de los ecosistemas' },
      { claveArea: 8, claveSubtema: 142, subtema: 'Construcción de megaproyectos' },
      { claveArea: 8, claveSubtema: 143, subtema: 'Contaminación del suelo, agua o aire' },
      { claveArea: 8, claveSubtema: 144, subtema: 'Desertificación' },
      { claveArea: 8, claveSubtema: 145, subtema: 'Economía campesina' },
      { claveArea: 8, claveSubtema: 146, subtema: 'Ecosistemas acuáticos (epicontinentales, costeros o marinos)' },
      { claveArea: 8, claveSubtema: 147, subtema: 'Ecosistemas terrestres' },
      { claveArea: 8, claveSubtema: 148, subtema: 'Enfermedades emergentes' },
      { claveArea: 8, claveSubtema: 149, subtema: 'Especies exóticas' },
      { claveArea: 8, claveSubtema: 150, subtema: 'Medidas de adaptación al cambio global' },
      { claveArea: 8, claveSubtema: 151, subtema: 'Pecuarios' },
      { claveArea: 8, claveSubtema: 152, subtema: 'Plagas' },
      { claveArea: 8, claveSubtema: 153, subtema: 'Planes de desarrollo urbano u ordenamientos territoriales participativos' },
      { claveArea: 8, claveSubtema: 154, subtema: 'Recuperación de los lazos sociales' },
      { claveArea: 8, claveSubtema: 155, subtema: 'Riqueza biocultural' },
      { claveArea: 9, claveSubtema: 156, subtema: 'Asentamientos informales' },
      { claveArea: 9, claveSubtema: 157, subtema: 'Crisis sanitarias y ambientales' },
      { claveArea: 9, claveSubtema: 158, subtema: 'Derecho a la vivienda y a la ciudad' },
      { claveArea: 9, claveSubtema: 159, subtema: 'Desarrollo de sistemas tecnológicos' },
      { claveArea: 9, claveSubtema: 160, subtema: 'Gestión del suelo con énfasis en las modalidades de carácter social y comunal' },
      {
        claveArea: 9,
        claveSubtema: 161,
        subtema: 'Marcos normativos con visión regional para el desarrollo de una vivienda adecuada y de ciudades sustentables',
      },
      { claveArea: 9, claveSubtema: 162, subtema: 'Mitigación y adaptación al cambio climático' },
      { claveArea: 9, claveSubtema: 163, subtema: 'Nueva Agenda Urbana' },
      { claveArea: 9, claveSubtema: 164, subtema: 'Optimización del ciclo de vida de la vivienda' },
      { claveArea: 9, claveSubtema: 165, subtema: 'Proyectos federales' },
      { claveArea: 9, claveSubtema: 166, subtema: 'Riesgos para la salud pública y los desastres' },
      { claveArea: 9, claveSubtema: 167, subtema: 'Seguridad y soberanía alimentarias' },
      { claveArea: 9, claveSubtema: 168, subtema: 'Vivienda abandonada' },
      { claveArea: 9, claveSubtema: 169, subtema: 'Vivienda social en entornos intraurbanos y en renta' },
      { claveArea: 9, claveSubtema: 170, subtema: 'Vivienda y la vida rural' },
      { claveArea: 10, claveSubtema: 171, subtema: 'Apropiación del espacio colectivo' },
      { claveArea: 10, claveSubtema: 172, subtema: 'Conservación del patrimonio cultural' },
      { claveArea: 10, claveSubtema: 173, subtema: 'Diversidad biocultural' },
      { claveArea: 10, claveSubtema: 174, subtema: 'Diversidad cultural' },
      { claveArea: 10, claveSubtema: 175, subtema: 'Ejercicio y uso de memorias locales y comunitarias' },
      { claveArea: 10, claveSubtema: 176, subtema: 'Entorno natural y sociocultural' },
      { claveArea: 10, claveSubtema: 177, subtema: 'Los procesos culturales como factor de desarrollo' },
      { claveArea: 10, claveSubtema: 178, subtema: 'Paisajes y hábitat' },
      { claveArea: 10, claveSubtema: 179, subtema: 'Políticas públicas incluyentes' },
      { claveArea: 10, claveSubtema: 180, subtema: 'Prácticas sustentables' },
      { claveArea: 10, claveSubtema: 181, subtema: 'Preservación de los ecosistemas' },
      {
        claveArea: 10,
        claveSubtema: 182,
        subtema: 'Reapropiación del espacio público y el paisaje como factor de identidad local y regional',
      },
      { claveArea: 10, claveSubtema: 183, subtema: 'Reconocimiento de procesos socioculturales y bioculturales' },
      { claveArea: 10, claveSubtema: 184, subtema: 'Reconstrucción del diálogo' },
      { claveArea: 10, claveSubtema: 185, subtema: 'Restauración de vínculos sociales' },
      { claveArea: 10, claveSubtema: 186, subtema: 'Saberes locales' },
      { claveArea: 10, claveSubtema: 187, subtema: 'Saberes tradicionales' },
    ];

    return subtemas.filter(subtema => subtema.claveArea == claveArea);
  }

  public static obtenerTiposDeReportesSnp(): Array<TipoReporteSnp> {
    return [{ id: '1', clave: 'REGISTRO_MATRICULA_SNP', nombre: 'Registro de Matrículas SNP' }];
  }

  public static obtenerTiemposDedicacion(): Array<TiempoDedicacionClaustroSnp> {
    return [
      { id: 5, nombre: 'PROFESOR DE TIEMPO COMPLETO' },
      { id: 6, nombre: 'PROFESOR DE CÁTEDRA' },
      { id: 7, nombre: 'PROFESOR POSDOCTORANTE DE TIEMPO COMPLETO' },
      { id: 8, nombre: 'PROFESOR DE TIEMPO PARCIAL INTERNO' },
      { id: 9, nombre: 'PROFESOR DE TIEMPO PARCIAL EXTERNO' },
      { id: 10, nombre: 'TUTOR EXTERNO' },
      { id: 11, nombre: 'PROFESOR POSDOCTORANTE DE TIEMPO PARCIAL' },
    ];
  }

  public static obtenerAreasDeTemas(): Array<AreaTemasSnp> {
    return [
      { id: 1, nombre: 'Área de salud humana' },
      { id: 2, nombre: 'Área de arte y cultura' },
      { id: 3, nombre: 'Área de preservación, protección y mejoramiento del ambiente' },
      { id: 4, nombre: 'Área de ingenierías y tecnológicas' },
      { id: 5, nombre: 'Área de ciencias sociales' },
      { id: 6, nombre: 'Área de educación' },
      { id: 7, nombre: 'Área de administración' },
    ];
  }

  public static obtenerTemasDeTemas(areaId: number): Array<TemaTemasSnp> {
    const temas = [
      { id: 1, areaId: 1, nombre: 'Administración de la atención de enfermería' },
      { id: 2, areaId: 1, nombre: 'Biología celular y histología' },
      { id: 3, areaId: 1, nombre: 'Biomedicina molecular' },
      { id: 4, areaId: 1, nombre: 'Ciencias de la nutrición' },
      { id: 5, areaId: 1, nombre: 'Ciencias del comportamiento' },
      { id: 6, areaId: 1, nombre: 'Cirugía, traumatología, cirugía experimental' },
      { id: 7, areaId: 1, nombre: 'Endodoncia' },
      { id: 8, areaId: 1, nombre: 'Enfermedades de la nutrición y endocrinología' },
      { id: 9, areaId: 1, nombre: 'Enfermedades infecciosas, bacteriología clínica, virología clínica' },
      { id: 10, areaId: 1, nombre: 'Enfermería cardiovascular' },
      { id: 11, areaId: 1, nombre: 'Enfermería clínica avanzada' },
      { id: 12, areaId: 1, nombre: 'Enfermería del adulto en estado crítico' },
      { id: 13, areaId: 1, nombre: 'Enfermería del anciano' },
      { id: 14, areaId: 1, nombre: 'Enfermería del neonato' },
      { id: 15, areaId: 1, nombre: 'Enfermería en la cultura física y el deporte' },
      { id: 16, areaId: 1, nombre: 'Enfermería en salud familiar' },
      { id: 17, areaId: 1, nombre: 'Enfermería en salud mental' },
      { id: 18, areaId: 1, nombre: 'Enfermería en salud pública' },
      { id: 19, areaId: 1, nombre: 'Enfermería infantil' },
      { id: 20, areaId: 1, nombre: 'Enfermería perinatal' },
      { id: 21, areaId: 1, nombre: 'Farmacia, farmacología clínica' },
      { id: 22, areaId: 1, nombre: 'Farmacología humana' },
      { id: 23, areaId: 1, nombre: 'Fisiología' },
      { id: 24, areaId: 1, nombre: 'Fisiopatología' },
      { id: 25, areaId: 1, nombre: 'Hematología e inmunología clínica' },
      { id: 26, areaId: 1, nombre: 'Investigación clínica' },
      { id: 27, areaId: 1, nombre: 'Investigación médica' },
      { id: 28, areaId: 1, nombre: 'Neurociencias' },
      { id: 29, areaId: 1, nombre: 'Odontopediatría' },
      { id: 30, areaId: 1, nombre: 'Ortodoncia' },
      { id: 31, areaId: 1, nombre: 'Ortopedia dentomaxilofacial' },
      { id: 32, areaId: 1, nombre: 'Parasitología' },
      { id: 33, areaId: 1, nombre: 'Patología y medicina bucal' },
      { id: 34, areaId: 1, nombre: 'Periodoncia' },
      { id: 35, areaId: 1, nombre: 'Prostodoncia' },
      { id: 36, areaId: 1, nombre: 'Prótesis bucal' },
      { id: 37, areaId: 1, nombre: 'Psicología clínica y de la salud' },
      { id: 38, areaId: 1, nombre: 'Psicología de la personalidad' },
      { id: 1, areaId: 2, nombre: 'Arquitectura' },
      { id: 2, areaId: 2, nombre: 'Artes escénicas' },
      { id: 3, areaId: 2, nombre: 'Artes para diseño' },
      { id: 4, areaId: 2, nombre: 'Conservación del patrimonio arquitectónico' },
      { id: 5, areaId: 2, nombre: 'Planificación, diseño y desarrollo urbano' },
      { id: 6, areaId: 2, nombre: 'Diseño, planificación y conservación de paisajes y jardines' },
      { id: 7, areaId: 2, nombre: 'Estudios y procesos creativos an arte y diseño' },
      { id: 8, areaId: 2, nombre: 'Música' },
      { id: 9, areaId: 2, nombre: 'Producción artística' },
      { id: 1, areaId: 3, nombre: 'Acuacultura' },
      { id: 2, areaId: 3, nombre: 'Agroecología' },
      { id: 3, areaId: 3, nombre: 'Bioeconomía pesquera y acuícola' },
      { id: 4, areaId: 3, nombre: 'Biosistemática y manejo de recursos naturales' },
      { id: 5, areaId: 3, nombre: 'Bioteconología vegetal' },
      { id: 6, areaId: 3, nombre: 'Botánica' },
      { id: 7, areaId: 3, nombre: 'Ciencia y tecnología de los recursos forestales' },
      { id: 8, areaId: 3, nombre: 'Ciencias de la floricultura' },
      { id: 9, areaId: 3, nombre: 'Ciencias del mar' },
      { id: 10, areaId: 3, nombre: 'Ciencias del suelo' },
      { id: 11, areaId: 3, nombre: 'Ciencias en biodiversidad' },
      { id: 12, areaId: 3, nombre: 'Ciencias veterinarias' },
      { id: 13, areaId: 3, nombre: 'Climatología' },
      { id: 14, areaId: 3, nombre: 'Conservación de ecosistemas' },
      { id: 15, areaId: 3, nombre: 'Desarrollo de productos bióticos' },
      { id: 16, areaId: 3, nombre: 'Ecología' },
      { id: 17, areaId: 3, nombre: 'Sistema de producción agrícola' },
      { id: 18, areaId: 3, nombre: 'Farmacología animal' },
      { id: 19, areaId: 3, nombre: 'Fitomejoramiento' },
      { id: 20, areaId: 3, nombre: 'Fitopatología' },
      { id: 21, areaId: 3, nombre: 'Fitosanidad' },
      { id: 22, areaId: 3, nombre: 'Fruticultura y citicultura' },
      { id: 23, areaId: 3, nombre: 'Genética vegetal' },
      { id: 24, areaId: 3, nombre: 'Geodesia' },
      { id: 25, areaId: 3, nombre: 'Geofísica y sismología' },
      { id: 26, areaId: 3, nombre: 'Geografía física y matemática' },
      { id: 27, areaId: 3, nombre: 'Geología' },
      { id: 28, areaId: 3, nombre: 'Gestión integrada de cuencas' },
      { id: 29, areaId: 3, nombre: 'Hidrociencias' },
      { id: 30, areaId: 3, nombre: 'Hidrología y recursos hídricos' },
      { id: 31, areaId: 3, nombre: 'Hidrometeorología' },
      { id: 32, areaId: 3, nombre: 'Horticultura' },
      { id: 33, areaId: 3, nombre: 'Ingeniería agrícola' },
      { id: 34, areaId: 3, nombre: 'Manejo agroecológico de plagas y enfermedades' },
      { id: 35, areaId: 3, nombre: 'Manejo costero y de recursos marinos' },
      { id: 36, areaId: 3, nombre: 'Manejo de vida silvestre' },
      { id: 37, areaId: 3, nombre: 'Manejo sustentable de agroecosistemas' },
      { id: 38, areaId: 3, nombre: 'Meteorología' },
      { id: 39, areaId: 3, nombre: 'Mitigación y adaptación al cambio climático' },
      { id: 40, areaId: 3, nombre: 'Nutrición vegetal y fertilización de cultivos' },
      { id: 41, areaId: 3, nombre: 'Oceanografía' },
      { id: 42, areaId: 3, nombre: 'Producción animal' },
      { id: 43, areaId: 3, nombre: 'Producción y tecnología de semillas' },
      { id: 44, areaId: 3, nombre: 'Química del ambiente' },
      { id: 45, areaId: 3, nombre: 'Recursos acuáticos' },
      { id: 46, areaId: 3, nombre: 'Recursos naturales y medio ambiente en zonas áridas' },
      { id: 47, areaId: 3, nombre: 'Reproducción animal' },
      { id: 48, areaId: 3, nombre: 'Restauración ecológica' },
      { id: 49, areaId: 3, nombre: 'Salud ambiental' },
      { id: 50, areaId: 3, nombre: 'Silvicultura y manejo de bosques' },
      { id: 51, areaId: 3, nombre: 'Toxicología' },
      { id: 52, areaId: 3, nombre: 'Zootecnia y ciencias pecuarias' },
      { id: 1, areaId: 4, nombre: 'Automatización y control' },
      { id: 2, areaId: 4, nombre: 'Bioingeniería ingeniería biomédica' },
      { id: 3, areaId: 4, nombre: 'Ciencia e ingeniería de la computación' },
      { id: 4, areaId: 4, nombre: 'Ciencia y tecnología de materiales' },
      { id: 5, areaId: 4, nombre: 'Ciencia y tecnología del agua' },
      { id: 6, areaId: 4, nombre: 'Ciencias de la información' },
      { id: 7, areaId: 4, nombre: 'Ciencias y tecnología de la madera' },
      { id: 8, areaId: 4, nombre: 'Control y sistemas dinámicos' },
      { id: 9, areaId: 4, nombre: 'Desarrollo sustentable y gestión de riesgos' },
      { id: 10, areaId: 4, nombre: 'Dirección y gestión de proyectos de ingeniería' },
      { id: 11, areaId: 4, nombre: 'Diseño avanzado' },
      { id: 12, areaId: 4, nombre: 'Diseño interactivo y manufactura' },
      { id: 13, areaId: 4, nombre: 'Energía renovable' },
      { id: 14, areaId: 4, nombre: 'Ingeniería ambiental' },
      { id: 15, areaId: 4, nombre: 'Ingeniería automotriz' },
      { id: 16, areaId: 4, nombre: 'Ingeniería biomédica' },
      { id: 17, areaId: 4, nombre: 'Ingeniería bioquímica' },
      { id: 18, areaId: 4, nombre: 'Ingeniería cerámica' },
      { id: 19, areaId: 4, nombre: 'Ingeniería civil' },
      { id: 20, areaId: 4, nombre: 'Ingeniería de corrosión' },
      { id: 21, areaId: 4, nombre: 'Ingeniería de estructuras' },
      { id: 22, areaId: 4, nombre: 'Ingeniería de petróleo' },
      { id: 23, areaId: 4, nombre: 'Ingeniería de polímeros y plásticos' },
      { id: 24, areaId: 4, nombre: 'Ingeniería de sistemas energéticos' },
      { id: 25, areaId: 4, nombre: 'Ingeniería de software' },
      { id: 26, areaId: 4, nombre: 'Ingeniería de vías terrestres' },
      { id: 27, areaId: 4, nombre: 'Ingeniería eléctrica' },
      { id: 28, areaId: 4, nombre: 'Ingeniería electrónica' },
      { id: 29, areaId: 4, nombre: 'Ingeniería en comunicaciones' },
      { id: 30, areaId: 4, nombre: 'Ingeniería en energía' },
      { id: 31, areaId: 4, nombre: 'Ingeniería en exploración y explotación de recursos naturales' },
      { id: 32, areaId: 4, nombre: 'Ingeniería en seguridad y tecnologías de la información' },
      { id: 33, areaId: 4, nombre: 'Ingeniería en telecomunicaciones' },
      { id: 34, areaId: 4, nombre: 'Ingeniería física' },
      { id: 35, areaId: 4, nombre: 'Ingeniería industrial y manufactura' },
      { id: 36, areaId: 4, nombre: 'Ingeniería informática' },
      { id: 37, areaId: 4, nombre: 'Ingeniería mecánica' },
      { id: 38, areaId: 4, nombre: 'Ingeniería mecatrónica' },
      { id: 39, areaId: 4, nombre: 'Ingeniería metalurgía' },
      { id: 40, areaId: 4, nombre: 'Ingeniería nuclear' },
      { id: 41, areaId: 4, nombre: 'Ingeniería química' },
      { id: 42, areaId: 4, nombre: 'Ingeniería y administración de la construcción' },
      { id: 43, areaId: 4, nombre: 'Inteligencia artificial' },
      { id: 44, areaId: 4, nombre: 'Manufactura avanzada' },
      { id: 45, areaId: 4, nombre: 'Nanociencias y nanotecnologías' },
      { id: 46, areaId: 4, nombre: 'Robótica' },
      { id: 47, areaId: 4, nombre: 'Sistemas embebidos' },
      { id: 48, areaId: 4, nombre: 'Tecnología de la soldadura industrial' },
      { id: 49, areaId: 4, nombre: 'Tecnología del transporte' },
      { id: 50, areaId: 4, nombre: 'Termofluidos' },
      { id: 1, areaId: 5, nombre: 'Asuntos urbanos' },
      { id: 2, areaId: 5, nombre: 'Bibliotecología y estudios de la información' },
      { id: 3, areaId: 5, nombre: 'Cambio y desarrollo social' },
      { id: 4, areaId: 5, nombre: 'Ciencia política y gobierno' },
      { id: 5, areaId: 5, nombre: 'Demografía general' },
      { id: 6, areaId: 5, nombre: 'Derecho administrativo' },
      { id: 7, areaId: 5, nombre: 'Derecho comercial, industrial y minero' },
      { id: 8, areaId: 5, nombre: 'Derecho comparado' },
      { id: 9, areaId: 5, nombre: 'Derecho del ambiente' },
      { id: 10, areaId: 5, nombre: 'Derecho laboral' },
      { id: 11, areaId: 5, nombre: 'Derecho penal' },
      { id: 12, areaId: 5, nombre: 'Derecho procesal' },
      { id: 13, areaId: 5, nombre: 'Derechos humanos' },
      { id: 14, areaId: 5, nombre: 'Desarrollo municipal' },
      { id: 15, areaId: 5, nombre: 'Desarrollo regional' },
      { id: 16, areaId: 5, nombre: 'Desarrollo rural regional' },
      { id: 17, areaId: 5, nombre: 'Economía agrícola y de los recursos naturales' },
      { id: 18, areaId: 5, nombre: 'Economía ambiental y ecológica' },
      { id: 19, areaId: 5, nombre: 'Economía industrial' },
      { id: 20, areaId: 5, nombre: 'Economía regional' },
      { id: 21, areaId: 5, nombre: 'Economía social y solidaria' },
      { id: 22, areaId: 5, nombre: 'Economía y gestión municipal' },
      { id: 23, areaId: 5, nombre: 'Economía y política pública' },
      { id: 24, areaId: 5, nombre: 'Economía' },
      { id: 25, areaId: 5, nombre: 'Estudios de demografía y población' },
      { id: 26, areaId: 5, nombre: 'Estudios de género' },
      { id: 27, areaId: 5, nombre: 'Estudios para la paz y el desarrollo' },
      { id: 28, areaId: 5, nombre: 'Familias y prevención de la violencia' },
      { id: 29, areaId: 5, nombre: 'Género, violencia y políticas públicas' },
      { id: 30, areaId: 5, nombre: 'Geografía económica' },
      { id: 31, areaId: 5, nombre: 'Geografía física' },
      { id: 32, areaId: 5, nombre: 'Geografía histórica' },
      { id: 33, areaId: 5, nombre: 'Geografía humana' },
      { id: 34, areaId: 5, nombre: 'Geografía regional' },
      { id: 35, areaId: 5, nombre: 'Gestión de proyectos para el desarrollo solidario' },
      { id: 36, areaId: 5, nombre: 'Ordenamiento territorial comunitario' },
      { id: 37, areaId: 5, nombre: 'Planeación y políticas metropolitanas' },
      { id: 38, areaId: 5, nombre: 'Política fiscal y hacienda pública nacionales' },
      { id: 39, areaId: 5, nombre: 'Problemas internacionales' },
      { id: 40, areaId: 5, nombre: 'Problemas sociales' },
      { id: 41, areaId: 5, nombre: 'Psicología social' },
      { id: 42, areaId: 5, nombre: 'Sociología cultural' },
      { id: 43, areaId: 5, nombre: 'Sociología de los asentamientos humanos' },
      { id: 44, areaId: 5, nombre: 'Sociología del trabajo' },
      { id: 45, areaId: 5, nombre: 'Sociología experimental' },
      { id: 46, areaId: 5, nombre: 'Sociología política' },
      { id: 47, areaId: 5, nombre: 'Sociología rural' },
      { id: 48, areaId: 5, nombre: 'Turismo comunitario' },
      { id: 1, areaId: 6, nombre: 'Cognición y educación' },
      { id: 2, areaId: 6, nombre: 'Didáctica de las lenguas' },
      { id: 3, areaId: 6, nombre: 'Diversidad y educación inclusiva' },
      { id: 4, areaId: 6, nombre: 'Docencia para la educación media superior' },
      { id: 5, areaId: 6, nombre: 'Educación agrícola superior' },
      { id: 6, areaId: 6, nombre: 'Educación ambiental' },
      { id: 7, areaId: 6, nombre: 'Educación especial' },
      { id: 8, areaId: 6, nombre: 'Educación matemática' },
      { id: 9, areaId: 6, nombre: 'Enseñanza de la historia' },
      { id: 10, areaId: 6, nombre: 'Fundamentos filosóficos de la educación' },
      { id: 11, areaId: 6, nombre: 'Gestión del aprendizaje' },
      { id: 12, areaId: 6, nombre: 'Investigación en ciencias educativas' },
      { id: 13, areaId: 6, nombre: 'Pedagogía' },
      { id: 14, areaId: 6, nombre: 'Psicología de la educación y cognitiva' },
      { id: 15, areaId: 6, nombre: 'Sistemas y ambientes educativos' },
      { id: 1, areaId: 7, nombre: 'Administración fiscal' },
      { id: 2, areaId: 7, nombre: 'Administración integral del ambiente' },
      { id: 3, areaId: 7, nombre: 'Administración pública' },
      { id: 4, areaId: 7, nombre: 'Contabilidad, impuestos y auditoria' },
      { id: 5, areaId: 7, nombre: 'Gestión sustentable del agua' },
    ];

    return temas.filter(tema => tema.areaId == areaId);
  }

  public static obtenerModalidades(): Array<ModalidadPrograma> {
    return [
      { cveModalidad: 'ESC', modalidad: 'ESCOLARIZADA' },
      { cveModalidad: 'ESP MED', modalidad: 'ESPECIALIDAD MÉDICA' },
      { cveModalidad: 'PI', modalidad: 'POSGRADO CON LA INDUSTRIA' },
      { cveModalidad: 'NO ESC', modalidad: 'NO ESCOLARIZADA' },
    ];
  }

  public static obtenerGrados(): Array<GradoPrograma> {
    return [
      { cveGrado: 'DOC', grado: 'DOCTORADO' },
      { cveGrado: 'ESP', grado: 'ESPECIALIDAD' },
      { cveGrado: 'MAE', grado: 'MAESTRIA' },
      { cveGrado: 'LIC', grado: 'LICENCIATURA' },
      { cveGrado: 'TEC', grado: 'TECNICO SUPERIOR UNIVERSITARIO' },
    ];
  }

  public static obtenerOrientaciones(): Array<OrientacionPrograma> {
    return [
      { cveOrientacion: 'INV', orientacion: 'INVESTIGACIÓN' },
      { cveOrientacion: 'PRO', orientacion: 'PROFESIONAL' },
    ];
  }

  public static obtenerCaracteristicas(): Array<CaracteristicaPrograma> {
    return [
      { cveCaracteristica: 'UNISD', caracteristica: 'UNISEDE' },
      { cveCaracteristica: 'INTRINS', caracteristica: 'INTERINSTITUCIONAL' },
      { cveCaracteristica: 'MULTSD', caracteristica: 'MULTISEDE' },
      { cveCaracteristica: 'MULTDP', caracteristica: 'MULTIDEPENDENCIA' },
      { cveCaracteristica: 'DOBTIT', caracteristica: 'DOBLE TITULACIÓN' },
    ];
  }

  public static obtenerTipos(): Array<TipoPrograma> {
    return [
      { cveTipo: 'TRAD', tipo: 'TRADICIONAL' },
      { cveTipo: 'DIR', tipo: 'DIRECTO' },
    ];
  }

  public static obtenerTiposConvocatoria(): Array<TipoConvocatoria> {
    return [
      { cveTipoConvocatoria: '', nombre: 'TRADICIONAL' },
      { cveTipoConvocatoria: 'ESP MED', nombre: 'ESPECIALIDADES MÉDICAS' },
      { cveTipoConvocatoria: 'ESTADOS', nombre: 'ESPECIALES ENTIDADES FEDERATIVAS CON ALTO REZAGO SOCIAL' },
      { cveTipoConvocatoria: 'HUMANIDADES', nombre: 'ESPECIALES HUMANIDADES Y CIENCIA DE LA CONDUCTA' },
      { cveTipoConvocatoria: 'SALUD', nombre: 'ESPECIALES EN SALUD' },
    ];
  }
}
