import { IRevision } from '@/shared/model/revision.model';
import { INivel } from '../insaculacion/entity-commons/entity.factory';
import { IFile } from '@/shared/model/file.model';

export interface IRespuesta {
  nombre?: string;
  comision?: string;
  revision?: IRevision;
  dictamen?: Dictamen;
  evaluacion?: INivel;
  resumen?: string;
  valido?: boolean;
  estadoDoc?: string;
  documento?: IFile;
  evaluacionConsejo?: INivel;
  tieneConflictoInteres?: boolean;
}

export class Respuesta implements IRespuesta {
  constructor(public nombre?: string | null, public revision?: IRevision | null, public evaluacion?: INivel) {}
}

export interface Dictamen {
  comentarios?: string;
  textoConsejo?: string;
}
