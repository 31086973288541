import { Form, FormBuilder } from 'vue-formiojs';
import 'formiojs/dist/formio.full.min.css';

import IconPickerComponent from '@/components/icon-picker/icon-picker.vue';
import MessageComponent from '@/components/message/message.vue';
import ErrorComponent from '@/components/error/error.vue';
import AssingReviewer from '@/components/assign-reviewer/assign-reviewer.vue';
import AssingAuthority from '@/components/assign-authority/assign-authority.vue';
import ConfirmationComponent from '@/components/confirmation/confirmation.vue';
import DateTimePickerComponent from '@/components/date-time-picker/date-time-picker.vue';
import Convocatoria from '@/entities/convocatoria/convocatoria.vue';
import TagsComponent from '@/components/tags/tags.vue';
import { VueEditor } from 'vue2-editor';
import VCalendar from 'v-calendar';
import SaveButtonComponent from '@/components/save-button/save-button.vue';
import DualButtonComponent from '@/components/dual-button/dual-button.vue';
import DeleteModalComponent from '@/components/delete-modal/delete-modal.vue';
import ActionButtonsComponent from '@/components/action-buttons/action-buttons.vue';
import CardsListComponent from '@/components/cards-list/cards-list.vue';
import TableViewComponent from '@/components/table-view/table-view.vue';
import SelectManyComponent from '@/components/select-many/select-many.vue';
import SelectTransitionElementsComponent from '@/components/select-transition-elements/select-transition-elements.vue';
import ArgumentsConfigComponent from '@/components/arguments-config/arguments-config.vue';
import ComentarioComponent from '@/components/comentarios/comentarios.vue';
import ProcesoComponent from '@/components/proceso/proceso.vue';
import AccionesComponent from '@/components/forms/acciones/acciones.vue';
import AvisoEditableComponent from '@/components/aviso-editable/aviso-editable.vue';
import { VueBotUI } from 'vue-bot-ui';
import VueMermaid from 'vue-mermaid/src/vue-mermaid.vue';
import { VBTooltip } from 'bootstrap-vue';
import VueTour from 'vue-tour';
import TransicionesComponent from '@/components/transiciones/transiciones.vue';
import PermisosComponent from '@/components/permisos/permisos.vue';
import PersonaComponent from '@/components/persona/persona.vue';
import BuscadorPersonaComponent from '@/components/buscador-persona/buscador-persona.vue';
import PersonaResumenComponent from '@/components/persona-resumen/persona-resumen.vue';
import PersonaUpdateComponent from '@/components/persona-update/persona-update.vue';
import DocumentosComponent from '@/components/documentos/documentos.vue';
import BreadcrumbComponent from '@/components/breadcrumb/breadcrumb.vue';
import FormPickerComponent from '@/components/form-picker/form-picker.vue';
import ContactoAsistenciaComponent from '@/core/contacto-asistencia/contacto-asistencia.vue';
import GraphComponent from '@/components/graph/graph.vue';
import ConfigDocumentos from '@/components/config-documentos/config-documentos.vue';
import CardListSkeleton from '@/components/skeletons/card-list-skeleton/card-list-skeleton.vue';
import MenuSkeleton from '@/components/skeletons/menu-skeleton/menu-skeleton.vue';
import WelcomeSkeleton from '@/components/skeletons/welcome-skeleton/welcome-skeleton.vue';
import TabsSkeleton from '@/components/skeletons/tabs-skeleton/tabs-skeleton.vue';
import ComentariosSkeleton from '@/components/skeletons/comentarios-skeleton/comentarios-skeleton.vue';
import TableRowSkeleton from '@/components/skeletons/table-row-skeleton/table-row-skeleton.vue';
import PerfilNavbarSkeleton from '@/components/skeletons/perfil-navbar-skeleton/perfil-navbar-skeleton.vue';
import FiltroSolicitudesComponent from '@/components/filtro-solicitudes/filtro-solicitudes.vue';
import EstadoSolicitudComponent from '@/components/estado-solicitud/estado-solicitud.vue';
import ParticipantesComponent from '@/components/participantes/participantes.vue';
import ResultadosComponent from '@/components/resultados/resultados.vue';
import LieComponent from '@/entities/snp/lie/lie.vue';
import PronacesComponent from '@/entities/snp/pronaces/pronaces.vue';
import ClaustroComponent from '@/entities/snp/claustro/claustro.vue';
import TemasComponent from '@/entities/snp/temas/temas.vue';
import ProgramaSelectComponent from '@/components/programa-select/programa-select.vue';
import ProgramaRegistroComponent from '@/components/programa-registro/programa-registro.vue';
import ProgramaCapturaComponent from '@/components/programa-captura/programa-captura.vue';
import ProgramaIntegradoConComponent from '@/components/programa-integrado-con/programa-integrado-con.vue';
import ProgramaResumenComponent from '@/components/programa-resumen/programa-resumen.vue';
import MatriculaRegistroComponent from '@/components/matricula-registro/matricula-registro.vue';
import EstadoTagsComponent from '@/components/estado-tags/estado-tags.vue';
import VersionComponent from '@/components/version/version.vue';
import AutocompleteTextComponent from '@/components/autocomplete-text/autocomplete-text.vue';
import RegresarButtonComponent from '@/components/regresar-button/regresar-button.vue';
import TimerComponent from '@/components/timer/timer.vue';
import VariablesComponent from '@/components/variables/variables.vue';
import EmptyDataComponent from '@/components/empty-data/empty-data.vue';
import HighlightableInput from 'vue-highlightable-input';
import VueConfetti from 'vue-confetti';
import CodeDiff from 'v-code-diff';
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
import JSONView from 'vue-json-component';
import NoAccessModalComponent from '@/components/no-access-modal/no-access-modal.vue';
import ReporteComponent from '@/components/reporte/reporte.vue';
import NoUserInfoModalComponent from '@/components/no-user-info-modal/no-user-info-modal.vue';
import SolucionResumenComponent from '@/components/solucion-resumen/solucion-resumen.vue';
import RuntimeTemplateCompiler from 'vue-runtime-template-compiler';
import EvaluacionIndividualComponent from '@/entities/evaluacion/evaluacion-individual/evaluacion-individual.vue';
import DictamenComponent from '@/entities/evaluacion/dictamen/dictamen.vue';
import EvaluacionesIndividualesComponent from '@/entities/evaluacion/evaluacion-plenaria/evaluaciones-individuales.vue';
import ExcelComponent from '@/components/excel/excel.vue';
import ExpedientePersonaComponent from '@/components/expediente-persona/expediente-persona.vue';
import EvaluacionConsejoComponent from '@/entities/evaluacion/evaluacion-consejo/evaluacion-consejo.vue';
import DictamenReconsideracionComponent from '@/entities/evaluacion/dictamen-reconsideracion/dictamen-reconsideracion.vue';
import DictamenEmeritosComponent from '@/entities/evaluacion/dictamen-emeritos/dictamen-emeritos.vue';
import CollapseItemComponent from '@/components/collapse-item/collapse-item.vue';
import PagoReglasComponent from '@/components/pago/reglas/reglas.vue';
import PadronComponent from '@/components/pago/padron/padron.vue';
import ModalContainerComponent from '@/components/modal-container/modal-container.vue';
import RegistroInvestigadorComponent from '@/components/movimientos/registro-investigador/registro-investigador.vue';
import RegistroMovimientoComponent from '@/components/movimientos/registro-movimiento/registro-movimiento.vue';
import EstadoProcesoComponent from '@/components/bulk-operations/estado-proceso/estado-proceso.vue';
import CargaMasivaComponent from '@/components/bulk-operations/carga-masiva/carga-masiva.vue';
import SheetDownloadComponent from '@/components/sheet-download/sheet-download.vue';
import DatosGeneralesAcreditacionesComponent from '@/components/datos-generales-acreditaciones/datos-generales-acreditaciones.vue';
import HistoricoPagosComponent from '@/components/historico-pagos/historico-pagos.vue';
import CuotaComponent from '@/entities/snp/cuota/cuota.vue';
import CamposConocimientoComponent from '@/entities/snp/camposConocimiento/camposConocimiento.vue';
import RegistroReestructuracionComponent from '@/entities/snp/registroReestructuracion/registroReestructuracion.vue';

import ExportFromJsonComponent from '@/components/export-from-json/export-from-json.vue';
import ResultadoEvaluacionComponent from '@/entities/reconsideracion/solicitud/resultado-evaluacion.vue';
import ResultadoReconsideracionComponent from '@/entities/reconsideracion/resultado/resultado-reconsideracion.vue';
import Instituciones from '@/entities/institucion/institucion.vue';
import AsignacionOperadorComponent from '@/entities/becas/asignacion-operador.vue';
import ComentariosAjusteComponent from '@/entities/becas/evaluacion-extranjeras/comentarios-ajuste/comentarios-ajuste.vue';

import DocumentosComponentComentarios from '@/components/documentos-comentarios/documentos-comentarios.vue';
import DatosAyudanteComponent from '@/components/datos-ayudante/datos-ayudante.vue';
import AlertaComponent from '@/components/alerta/alerta.vue';

import IndicadorCargaComponent from '@/components/indicador-carga/indicador-carga.vue';
import TablaMovimientosAcademicosComponent from '@/entities/seguimiento/academico/tabla-movimientos-academicos.vue';

import ConciliacionParcialComponent from '@/entities/becas/evaluacion-extranjeras/conciliacion/conciliacion-parcial.vue';
import ValidationsCommonsComponent from '@/components/validations-commons/validations-commons.vue';
import InputTextComponent from '@/components/input-text/input-text.vue';
import InputSelectOneComponent from '@/components/input-select-one/input-select-one.vue';

import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
require('vue-tour/dist/vue-tour.css');

export function initCustomVue(vue) {
  vue.use(VCalendar);
  vue.use(VueConfetti);
  vue.use(VueTour);
  vue.use(CodeDiff);
  vue.use(JSONView);
  vue.use(RuntimeTemplateCompiler);
  vue.directive('b-tooltip', VBTooltip);
  vue.component('breadcrumb', BreadcrumbComponent);
  vue.component('vue-mermaid', VueMermaid);
  vue.component('formio', Form);
  vue.component('formbuilder', FormBuilder);
  vue.component('icon-picker', IconPickerComponent);
  vue.component('message', MessageComponent);
  vue.component('confirmation', ConfirmationComponent);
  vue.component('vue-editor', VueEditor);
  vue.component('tags', TagsComponent);
  vue.component('date-time-picker', DateTimePickerComponent);
  vue.component('errors', ErrorComponent);
  vue.component('convocatoria', Convocatoria);
  vue.component('assign-reviewer', AssingReviewer);
  vue.component('assign-authority', AssingAuthority);
  vue.component('c-delete-modal', DeleteModalComponent);
  vue.component('c-save-button', SaveButtonComponent);
  vue.component('c-dual-button', DualButtonComponent);
  vue.component('c-action-buttons', ActionButtonsComponent);
  vue.component('cards-list', CardsListComponent);
  vue.component('select-many', SelectManyComponent);
  vue.component('select-transition-elements', SelectTransitionElementsComponent);
  vue.component('arguments-config', ArgumentsConfigComponent);
  vue.component('comentarios', ComentarioComponent);
  vue.component('VueBotUI', VueBotUI);
  vue.component('proceso', ProcesoComponent);
  vue.component('acciones', AccionesComponent);
  vue.component('aviso-editable', AvisoEditableComponent);
  vue.component('transiciones', TransicionesComponent);
  vue.component('permisos', PermisosComponent);
  vue.component('persona', PersonaComponent);
  vue.component('documentos', DocumentosComponent);
  vue.component('instituciones', Instituciones);
  vue.component('form-picker', FormPickerComponent);
  vue.component('contacto-asistencia', ContactoAsistenciaComponent);
  vue.component('graph', GraphComponent);
  vue.component('config-documentos', ConfigDocumentos);
  vue.component('card-list-skeleton', CardListSkeleton);
  vue.component('menu-skeleton', MenuSkeleton);
  vue.component('welcome-skeleton', WelcomeSkeleton);
  vue.component('tabs-skeleton', TabsSkeleton);
  vue.component('comentarios-skeleton', ComentariosSkeleton);
  vue.component('table-row-skeleton', TableRowSkeleton);
  vue.component('perfil-navbar-skeleton', PerfilNavbarSkeleton);
  vue.component('filtro-solicitudes', FiltroSolicitudesComponent);
  vue.component('estado-tags', EstadoTagsComponent);
  vue.component('estado-solicitud', EstadoSolicitudComponent);
  vue.component('version', VersionComponent);
  vue.component('timer', TimerComponent);
  vue.component('variables', VariablesComponent);
  vue.component('highlightable-input', HighlightableInput);
  vue.component('at', At);
  vue.component('at-ta', AtTa);
  vue.component('autocomplete-text', AutocompleteTextComponent);
  vue.component('vue-simple-suggest', VueSimpleSuggest);
  vue.component('table-view', TableViewComponent);
  vue.component('no-access-modal', NoAccessModalComponent);
  vue.component('no-user-info-modal', NoUserInfoModalComponent);
  vue.component('solucion-resumen', SolucionResumenComponent);
  vue.component('evaluacion-individual', EvaluacionIndividualComponent);
  vue.component('dictamen', DictamenComponent);
  vue.component('export-from-json', ExportFromJsonComponent);
  vue.component('evaluaciones-individuales', EvaluacionesIndividualesComponent);
  vue.component('reporte', ReporteComponent);
  vue.component('participantes', ParticipantesComponent);
  vue.component('resultados', ResultadosComponent);
  vue.component('buscador-persona', BuscadorPersonaComponent);
  vue.component('persona-resumen', PersonaResumenComponent);
  vue.component('persona-update', PersonaUpdateComponent);
  vue.component('lie', LieComponent);
  vue.component('pronaces', PronacesComponent);
  vue.component('claustro', ClaustroComponent);
  vue.component('temas', TemasComponent);
  vue.component('programa-select', ProgramaSelectComponent);
  vue.component('programa-registro', ProgramaRegistroComponent);
  vue.component('programa-captura', ProgramaCapturaComponent);
  vue.component('programa-integrado-con', ProgramaIntegradoConComponent);
  vue.component('programa-resumen', ProgramaResumenComponent);
  vue.component('matricula-registro', MatriculaRegistroComponent);
  vue.component('excel', ExcelComponent);
  vue.component('expediente-persona', ExpedientePersonaComponent);
  vue.component('empty-data', EmptyDataComponent);
  vue.component('evaluacion-consejo', EvaluacionConsejoComponent);
  vue.component('regresar-button', RegresarButtonComponent);
  vue.component('resultado-evaluacion', ResultadoEvaluacionComponent);
  vue.component('resultado-reconsideracion', ResultadoReconsideracionComponent);
  vue.component('dictamen-reconsideracion', DictamenReconsideracionComponent);
  vue.component('dictamen-emeritos', DictamenEmeritosComponent);
  vue.component('collapse-item', CollapseItemComponent);
  vue.component('pago-reglas', PagoReglasComponent);
  vue.component('padron', PadronComponent);
  vue.component('modal-container', ModalContainerComponent);
  vue.component('registro-investigador', RegistroInvestigadorComponent);
  vue.component('registro-movimiento', RegistroMovimientoComponent);
  vue.component('estado-proceso', EstadoProcesoComponent);
  vue.component('carga-masiva', CargaMasivaComponent);
  vue.component('sheet-download', SheetDownloadComponent);
  vue.component('datos-generales-acreditacion', DatosGeneralesAcreditacionesComponent);
  vue.component('historico-pagos', HistoricoPagosComponent);
  vue.component('documentos-comentarios', DocumentosComponentComentarios);
  vue.component('asignacion-operador', AsignacionOperadorComponent);
  vue.component('comentarios-ajuste', ComentariosAjusteComponent);
  vue.component('datos-ayudante', DatosAyudanteComponent);
  vue.component('alerta', AlertaComponent);
  vue.component('indicador-carga', IndicadorCargaComponent);
  vue.component('conciliacion-parcial', ConciliacionParcialComponent);
  vue.component('validations-commons', ValidationsCommonsComponent);
  vue.component('input-text', InputTextComponent);
  vue.component('input-select-one', InputSelectOneComponent);
  vue.component('cuota', CuotaComponent);
  vue.component('camposConocimiento', CamposConocimientoComponent);
  vue.component('registroReestructuracion', RegistroReestructuracionComponent);
  vue.component('tabla-movimientos-academicos', TablaMovimientosAcademicosComponent);
}
