var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: _vm.id,
      attrs: {
        scrollable: _vm.scrollable,
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        centered: _vm.centered,
        "header-bg-variant": "primary",
        size: _vm.size,
        id: _vm.id,
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body text-center" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleCancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("entity.action.close")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }