var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.showNoCupoProgramaMessage
        ? _c("message", {
            attrs: {
              description: _vm.$t("apeironGwApp.programa.form.noCupoPrograma"),
              variant: "warning",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.ocultaCupoPrograma
        ? _c(
            "b-container",
            { staticClass: "bv-cupo-programa" },
            [
              _vm.mostrarContadorPrograma
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-alert",
                            { attrs: { show: "", variant: "warning" } },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.countPrograma.totalPrograma"
                                        )
                                      )
                                  ),
                                  _c("b", [
                                    _vm._v(" " + _vm._s(_vm.matTotalProg)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.countPrograma.ocupados"
                                        )
                                      )
                                  ),
                                  _c("b", [
                                    _vm._v(" " + _vm._s(_vm.matOcupadaProg)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.programa.countPrograma.disponible"
                                        )
                                      )
                                  ),
                                  _c("b", [
                                    _vm._v(" " + _vm._s(_vm.matDisponibleProg)),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mostrarContadorPrograma && _vm.matDisponibleProg < 1
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-alert",
                            { attrs: { show: "", variant: "danger" } },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.programa.countPrograma.alert"
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("message", {
            attrs: {
              description: _vm.$t("apeironGwApp.programa.form.selectMatricula"),
              variant: "info",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showNoProgramSelectedMessage
        ? _c("message", {
            attrs: {
              description: _vm.$t(
                "apeironGwApp.programa.form.noprogramselected"
              ),
              variant: "warning",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNoInstitucionesMessage
        ? _c("message", {
            attrs: {
              description: _vm.$t("apeironGwApp.programa.form.noinstituciones"),
              variant: "warning",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            !_vm.showNoInstitucionesMessage
              ? _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t(
                        "apeironGwApp.programa.form.selectInstitucion"
                      ),
                      "label-for": "input-sm",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: { options: _vm.instituciones },
                      on: { change: _vm.handleChangeInstitucionId },
                      model: {
                        value: _vm.institucionSelected,
                        callback: function ($$v) {
                          _vm.institucionSelected = $$v
                        },
                        expression: "institucionSelected",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _vm.sedes.length > 0
              ? _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("apeironGwApp.programa.form.selectsede"),
                      "label-for": "input-sm",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: { options: _vm.sedes },
                      on: { change: _vm.handleChangeSedeId },
                      model: {
                        value: _vm.sedeSelected,
                        callback: function ($$v) {
                          _vm.sedeSelected = $$v
                        },
                        expression: "sedeSelected",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showNoCupoInstitucionMessage
        ? _c("message", {
            attrs: {
              description: _vm.$t(
                "apeironGwApp.programa.form.noCupoInstitucion"
              ),
              variant: "warning",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "bv-cupo-institucion" },
        [
          _vm.mostrarContadorInstitucion
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "warning" } },
                        [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.programa.count.totalInstitucion"
                                    )
                                  )
                              ),
                              _c("b", [_vm._v(" " + _vm._s(_vm.matTotal))]),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.programa.count.ocupados"
                                    )
                                  )
                              ),
                              _c("b", [_vm._v(" " + _vm._s(_vm.matOcupada))]),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.programa.count.disponible"
                                    )
                                  )
                              ),
                              _c("b", [
                                _vm._v(" " + _vm._s(_vm.matDisponible)),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.matDisponible < 1
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "danger" } },
                        [
                          _c("ul", [
                            _c("li", [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.programa.count.alert"
                                      )
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showParticipantes
        ? _c("participantes", {
            key: _vm.participantesKey,
            attrs: {
              solucionId: this.formModel.solucionId,
              solicitudId: this.formModel.id,
              contenedorId: _vm.contenedorId,
              dependenciaSelected: _vm.dependenciaSelected,
              duracionOficial: _vm.duracionOficial,
              isSedeDuplicated: _vm.isSedeDuplicated,
              rol: this.ROL,
              readOnly: _vm.readOnly,
            },
            on: {
              actualizarContadorParticipacion: function ($event) {
                return _vm.actualizarContadoresCupo()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }