var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "seleccionTablas.label.filtrtarEnDisponibles"
                      ),
                      disabled: _vm.disabled,
                    },
                    on: { keyup: _vm.filtrarOpciones },
                    model: {
                      value: _vm.filtroOpciones,
                      callback: function ($$v) {
                        _vm.filtroOpciones = $$v
                      },
                      expression: "filtroOpciones",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-2 ml-3" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "9" } }, [
                        _c(
                          "p",
                          { staticClass: "h6" },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("seleccionTablas.label.disponibles")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.opcionesBase.length
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: _vm.$t(
                                      "seleccionTablas.label.pasarTodosASeleccionados"
                                    ),
                                    expression:
                                      "$t('seleccionTablas.label.pasarTodosASeleccionados')",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                attrs: {
                                  variant: "link",
                                  size: "sm",
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.pasarTodoASelecciones()
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "chevron-double-right" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    {
                      staticClass: "table-responsive",
                      class: {
                        "table-responsive-fail": !_vm.state,
                        "componente-disabled": _vm.disabled,
                      },
                    },
                    [
                      _c(
                        "b-list-group",
                        _vm._l(_vm.opcionesFiltrado, function (item) {
                          return _c(
                            "b-list-group-item",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: _vm.$t(
                                    "seleccionTablas.label.pasarASeleccionados"
                                  ),
                                  expression:
                                    "$t('seleccionTablas.label.pasarASeleccionados')",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              key: item.text,
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              class: { "componente-disabled": _vm.disabled },
                              attrs: { button: "", disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.pasarASelecciones(item)
                                },
                              },
                            },
                            [
                              _c("span", {
                                domProps: { textContent: _vm._s(item.text) },
                              }),
                              _vm._v(" "),
                              _c("b-icon", {
                                attrs: { icon: "chevron-compact-right" },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "seleccionTablas.label.filtrtarEnSeleccionados"
                      ),
                      disabled: _vm.disabled,
                    },
                    on: { keyup: _vm.filtrarSelecciones },
                    model: {
                      value: _vm.filtroSelecciones,
                      callback: function ($$v) {
                        _vm.filtroSelecciones = $$v
                      },
                      expression: "filtroSelecciones",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-2 ml-3" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "9" } }, [
                        _c(
                          "p",
                          { staticClass: "h6" },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("seleccionTablas.label.seleccionados")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.seleccionadoBase.length
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: _vm.$t(
                                      "seleccionTablas.label.pasarTodosADisponibles"
                                    ),
                                    expression:
                                      "$t('seleccionTablas.label.pasarTodosADisponibles')",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                attrs: {
                                  variant: "link",
                                  size: "sm",
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.pasarTodoAOpciones()
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "chevron-double-left" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    {
                      staticClass: "table-responsive",
                      class: {
                        "table-responsive-fail": !_vm.state,
                        "componente-disabled": _vm.disabled,
                      },
                    },
                    [
                      _c(
                        "b-list-group",
                        _vm._l(_vm.seleccionFiltrado, function (item) {
                          return _c(
                            "b-list-group-item",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: _vm.$t(
                                    "seleccionTablas.label.pasarADisponibles"
                                  ),
                                  expression:
                                    "$t('seleccionTablas.label.pasarADisponibles')",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              key: item.text,
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              class: { "componente-disabled": _vm.disabled },
                              attrs: { button: "", disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.pasarAOpciones(item)
                                },
                              },
                            },
                            [
                              _c("span", {
                                domProps: { textContent: _vm._s(item.text) },
                              }),
                              _vm._v(" "),
                              _c("b-icon", {
                                attrs: { icon: "chevron-compact-left" },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }