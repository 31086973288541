import { Component, Prop, Vue } from 'vue-property-decorator';
import { IArgument } from '@/shared/model/argument.model';
import { PropertyMap } from '@/shared/model/property-map.model';
import { PropertyMapType } from '@/shared/model/enumerations/property-map-type.model';
import { ArgumentType } from '@/shared/model/enumerations/argument-type.model';

@Component
export default class ArgumentsConfigComponent extends Vue {
  @Prop({ required: true })
  public transitionElement!: any;

  ArgumentType: any = ArgumentType;

  isSaved = false;

  argumentTypeFields = ['type', 'from', 'to', 'value', 'acciones'];
  get argumentos(): IArgument[] {
    return JSON.parse(JSON.stringify(this.transitionElement.arguments));
  }

  handleSave() {
    this.isSaved = true;
    this.$emit('update', this.argumentos);
  }

  handleChangeValue() {
    this.isSaved = false;
  }

  handleAddPropertyMap(argumento: IArgument) {
    let currentArg = null;
    for (const arg of this.argumentos) {
      if (arg.name === argumento.name) {
        if (arg?.value?.length > 0) {
          arg.value = [];
        }
        currentArg = arg;
      }
    }

    const prop = new PropertyMap();
    prop.type = PropertyMapType.FROM_OBJECT;
    prop.from = 'solicitud.solicitante.cvu';
    prop.to = 'solicitud.solicitante.cvu';
    prop.value = '123456';
    currentArg.value.push(prop);
  }
}
