import { CargoDto } from '@/shared/model/cargo.model';
import { InstitucionDto } from '@/shared/model/institucion.model';
import { Dependencia } from '@/shared/model/dependencia.model';
import { SubDependencia } from '@/shared/model/sub-dependencia.model';
import { Departamento } from '@/shared/model/departamento.model';
import { IGenericaDto } from '@/shared/model/genericaDto.model';

export interface INuevaAutoridadInst {
  institucion?: InstitucionDto;
  dependencia?: Dependencia;
  subDependencia?: SubDependencia;
  departamento?: Departamento;
  autoridad?: CargoDto;
  nivelGuardado?: IGenericaDto;
}

export class NuevaAutoridadInst implements INuevaAutoridadInst {
  constructor(
    public institucion?: InstitucionDto,
    public dependencia?: Dependencia,
    public subDependencia?: SubDependencia,
    public departamento?: Departamento,
    public nivelGuardado?: IGenericaDto
  ) {}
}
