export enum EstadoMovimientoBeca {
  CAPTURADO = 'CAPTURADO',
  EN_REVISION = 'EN_REVISION',
  AUTORIZADO = 'AUTORIZADO',
  RECHAZADO = 'RECHAZADO',
  APLICADO = 'APLICADO',
  NO_APLICADO = 'NO_APLICADO',
  REVISADO = 'REVISADO',
  SOLICITADO = 'SOLICITADO',
  PRE_AUTORIZADO = 'PRE_AUTORIZADO',
  PENDIENTE = 'PENDIENTE',
  LINEA_CAPTURA = 'LINEA_CAPTURA',
}
