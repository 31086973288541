import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { RegistroTemaSnp } from '@/entities/snp/temas/temas.model';

@Component({})
export default class TemasListadoComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({
    required: false,
    default: function () {
      return new RegistroTemaSnp();
    },
  })
  public temaAgregar: RegistroTemaSnp;

  public filtroTemas = '';
  public temasFiltrados: Array<RegistroTemaSnp> = [];
  public temas: Array<RegistroTemaSnp> = [];

  public mostrarModalEliminar = false;

  public temaSeleccionado: RegistroTemaSnp = new RegistroTemaSnp();

  public perPage = 15;
  public currentPage = 1;
  public readonly registrosMaximos = 5;

  public readonly fieldsTemas = [
    {
      key: 'area.nombre',
      label: this.$t('apeironGwApp.temasSnp.form.area'),
      class: 'text-left',
      thStyle: { width: '10rem' },
    },
    { key: 'tema.nombre', label: this.$t('apeironGwApp.temasSnp.form.tema'), class: 'text-left', thStyle: { width: '20rem' } },
    {
      key: 'principal',
      label: this.$t('apeironGwApp.temasSnp.form.principal'),
      class: 'text-center',
      thStyle: { width: '5rem' },
    },
    {
      key: 'acciones',
      label: this.$t('apeironGwApp.temasSnp.acciones.acciones'),
      class: 'text-center btn-acciones',
      thStyle: { width: '8rem' },
    },
  ];

  public mounted(): void {
    this.iniciarComponente();
  }

  @Watch('temaAgregar')
  public watchTemaAgregar(): void {
    if (this.temaAgregar && this.temaAgregar.id) {
      this.temas = this.temas.filter(tema => tema.id !== this.temaAgregar.id);

      if (this.temaAgregar.principal) {
        this.temas.forEach(tema => {
          tema.principal = false;
        });
      }
    }

    this.temas.push(this.temaAgregar);

    this.buscarEnTemas();
  }

  private iniciarComponente(): void {
    this.temaSeleccionado = new RegistroTemaSnp();

    this.mostrarModalEliminar = false;

    if (this.solicitud?.temas) {
      this.temas = this.solicitud.temas;
    }

    this.buscarEnTemas();
  }

  public mostrarModalEliminarTema(tema: RegistroTemaSnp): void {
    this.mostrarModalEliminar = true;
    this.temaSeleccionado = tema;
  }

  public eliminarTema(): void {
    if (!this.readOnly) {
      this.temas = this.temas.filter(tema => tema.id !== this.temaSeleccionado.id);

      this.buscarEnTemas();
    }
    this.mostrarModalEliminar = false;
  }

  @Emit('editarTema')
  public editarTema(tema: RegistroTemaSnp): RegistroTemaSnp {
    return { ...tema };
  }

  public guardarTemas(): void {
    if (!this.readOnly) {
      if (this.temas.length <= this.registrosMaximos) {
        const temasPrincipal = this.temas.filter(tema => tema.principal);
        if (temasPrincipal.length != 1) {
          this.alertService().showWarning(this, this.$t('apeironGwApp.temasSnp.mensajes.indicarUnPrincipal').toString());
        } else {
          this.guardarSeccion();
        }
      } else {
        this.alertService().showWarning(
          this,
          this.$t('apeironGwApp.temasSnp.mensajes.topeDeTemas', { maximos: this.registrosMaximos }).toString()
        );
      }
    }
  }

  @Emit('guardarTemas')
  public guardarSeccion(): Array<RegistroTemaSnp> {
    return this.temas;
  }

  public buscarEnTemas(): void {
    this.temasFiltrados = [];

    this.temas.forEach(tema => {
      if (this.filtroTemas) {
        if (
          tema.tema?.nombre?.toLowerCase().includes(this.filtroTemas.toLowerCase()) ||
          tema.area?.nombre?.toLowerCase().includes(this.filtroTemas.toLowerCase())
        ) {
          this.temasFiltrados.push(tema);
        }
      } else {
        this.temasFiltrados.push(tema);
      }
    });
  }

  get registros(): number {
    return this.temasFiltrados?.length ?? 0;
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }
}
