var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isNavbarVisible
        ? _c(
            "b-button",
            {
              staticClass: "float-right ml-2 floating-btn",
              attrs: { variant: "danger", size: "lg" },
              on: { click: _vm.closeFilter },
            },
            [_c("b-icon", { attrs: { icon: "x-lg" } })],
            1
          )
        : _c(
            "b-button",
            {
              staticClass: "float-right ml-2 floating-btn",
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.openFilter },
            },
            [_c("b-icon", { attrs: { icon: "funnel" } })],
            1
          ),
      _vm._v(" "),
      _c(
        "b-sidebar",
        {
          attrs: {
            width: "360px",
            "z-index": "12",
            "bg-variant": "white",
            "border-variant": "light",
            "no-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            id: "sidebar-filtro-solicitudes",
            title: "Variables disponibles",
            right: "",
            shadow: "",
            visible: _vm.isNavbarVisible,
          },
        },
        [
          _c(
            "div",
            { staticClass: "p-4" },
            _vm._l(_vm.variables, function (variable, index) {
              return _c(
                "span",
                { key: index, staticClass: "m-1" },
                [
                  _c("b-badge", { attrs: { variant: "info", pill: "" } }, [
                    _vm._v(_vm._s(variable.name) + " "),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }