var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-center floating-diagrams" },
        [
          _c("vue-mermaid", {
            key: _vm.diagramId,
            attrs: {
              nodes: _vm.diagrama,
              type: "graph LR",
              config: _vm.config,
            },
            on: { nodeClick: _vm.editEstado },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { variant: "outline-danger", size: "sm" },
          on: { click: _vm.exportToPDF },
        },
        [_vm._v(_vm._s(_vm.$t("entity.action.download")))]
      ),
      _vm._v(" "),
      _vm.proceso
        ? _c(
            "b-tabs",
            {
              attrs: { card: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "tabs-end",
                    fn: function () {
                      return [
                        _c(
                          "b-nav-item",
                          {
                            attrs: { role: "presentation", href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.newTab.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "plus-square-dotted" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                970639142
              ),
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            _vm._l(_vm.proceso.estados, function (estado, index) {
              return _c(
                "b-tab",
                {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "danger" } },
                              [_vm._v(_vm._s(index + 1))]
                            ),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.resolveTitle(estado))),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-muted" },
                              [
                                _vm._v(
                                  "\n          No hay estados registrados"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n          Agrega un nuevo estado utilizando el botón "
                                ),
                                _c("b", [_vm._v("+")]),
                                _vm._v(" que se encuentra arriba.\n        "),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-card",
                    [
                      index > 0
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "dropdownCancel",
                              refInFor: true,
                              staticClass: "m-2 float-right",
                              attrs: {
                                right: "",
                                text: "Eliminar estado",
                                variant: "outline-danger",
                              },
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                { staticClass: "dropdown-menu-custom" },
                                [
                                  _c("p", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "Estás de acuerdo en eliminar este estado?"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-dropdown-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-danger",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeTab(index)
                                            },
                                          },
                                        },
                                        [_vm._v("Aceptar")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-card-body",
                        [
                          index > 0
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "tipo-comentario-id",
                                    label: "Estado",
                                    "label-for": "tipo-comentario-id",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "mb-3",
                                    attrs: { options: _vm.estadosUnicos },
                                    on: { change: _vm.onChangeHandler },
                                    model: {
                                      value: estado.nombre,
                                      callback: function ($$v) {
                                        _vm.$set(estado, "nombre", $$v)
                                      },
                                      expression: "estado.nombre",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("transiciones", {
                            attrs: {
                              reglas: _vm.transitionRules,
                              actions: _vm.actions,
                            },
                            on: { "change-state": _vm.handleChangeTransicion },
                            model: {
                              value: estado.transiciones,
                              callback: function ($$v) {
                                _vm.$set(estado, "transiciones", $$v)
                              },
                              expression: "estado.transiciones",
                            },
                          }),
                          _vm._v(" "),
                          _c("permisos", {
                            model: {
                              value: estado.permisos,
                              callback: function ($$v) {
                                _vm.$set(estado, "permisos", $$v)
                              },
                              expression: "estado.permisos",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }