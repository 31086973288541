var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "p-3" },
        [
          _c("b-skeleton", { staticClass: "m-3", attrs: { type: "button" } }),
          _vm._v(" "),
          _c("b-skeleton", { staticClass: "m-3", attrs: { type: "button" } }),
          _vm._v(" "),
          _c("b-skeleton", { staticClass: "m-3", attrs: { type: "button" } }),
          _vm._v(" "),
          _c("b-skeleton", { staticClass: "m-3", attrs: { type: "button" } }),
          _vm._v(" "),
          _c("b-skeleton", { staticClass: "m-3", attrs: { type: "button" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }