import { Component, Prop, Vue } from 'vue-property-decorator';
import { VigenciaSni } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';
import * as formatterUtils from '@/shared/formatter/formatter-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class VigenciasSniComponent extends Vue {
  @Prop({ required: false })
  public item: Array<VigenciaSni>;

  get vigenciasSni(): Array<VigenciaSni> {
    return this.item;
  }

  get fields() {
    return [
      { key: 'activo', label: 'Vigencia activo', sortable: true },
      { key: 'solicitudId', label: 'Solicitud ID', sortable: true },
      { key: 'nivelSni.descripcion', label: 'Nivel SNI', sortable: true },
      { key: 'tipo.descripcion', label: 'Tipo de vigencia', sortable: true },
      { key: 'convocatoria.clave', label: 'Convocatoria', sortable: true },
      {
        key: 'vigencia.fechaInicio',
        label: 'Fecha inicio',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      {
        key: 'vigencia.fechaFin',
        label: 'Fecha fin',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      {
        key: 'urlConvenio',
        label: 'URL convenio',
        sortable: true,
        link: true,
        formatter: (value, key, item) => formatterUtils.vigenciaConvenioUrlResolver(value, key, item),
      },
    ];
  }
}
