import { Component, Prop } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import LieRegistro from '@/entities/snp/lie/lie-registro.vue';
import LieListado from '@/entities/snp/lie/lie-listado.vue';
import { LieSnp } from '@/entities/snp/lie/lie.model';

@Component({
  components: {
    lieRegistro: LieRegistro,
    lieListado: LieListado,
  },
})
export default class LieComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public esVisibleRegistro = false;
  public esVisibleListado = true;

  public lieEdicion: LieSnp = new LieSnp();
  public lieAgregar: LieSnp = new LieSnp();

  public editarLie(lie: LieSnp): void {
    this.lieEdicion = { ...lie };
    this.esVisibleRegistro = true;
    this.esVisibleListado = false;
  }

  public agregarLieATabla(lie: LieSnp): void {
    this.esVisibleRegistro = false;
    this.esVisibleListado = true;
    this.lieAgregar = { ...lie };
  }

  get mostrarTexto() {
    return this.solicitud?.lies?.length === 0 || !this.solicitud?.lies;
  }
}
