import Vue from 'vue';
import Component from 'vue-class-component';

/**
 * Componente que muestra una alerta mientras se realiza una operación.
 * Recibe como paramétros el mensaje, el tipo de variant de alerta y la duración que tendrá.
 *
 * Para usar este componente en el typescript padre:
 * @Ref()
 *  readonly alerta!: AlertaComponent;
 *
 * Luego:
 *  this.alerta.mostrar(mensaje, 'warning', 60);
 *
 * En template:
 * <alerta ref="alerta" />
 */
@Component
export default class AlertaComponent extends Vue {
  mensaje = null;

  public mostrar(
    texto: any,
    tipo: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'info',
    duracion = 10,
    descartable = true
  ) {
    this.mensaje = {
      texto,
      descartable,
      duracion,
      tipo,
    };
  }

  public limpiar(): void {
    this.mensaje = null;
  }

  public actualizarDuracionCuentaRegresiva(duracion: number) {
    this.mensaje.duracion = duracion;
  }
}
