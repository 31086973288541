import { EstadoBeca } from '@/shared/model/enumerations/estado-beca.model';
import { Persona } from '@/shared/model/persona/persona.model';
import { Solucion } from '@/shared/model/seguimiento/solucion.model';
import { PeriodoBeca } from '@/shared/model/seguimiento/periodo-beca.model';
import { Programa } from './programa.model';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import { DependienteEconomico } from '@/shared/model/seguimiento/dependiente-economico.model';

export interface IBeca {
  id?: string | null;
  numeroApoyo?: number | null;
  estadoActual?: EstadoBeca | null;
  solicitudId?: string | null;
  solicitudRizomaId?: string | null;
  solucionSolicitud?: Solucion | null;
  becario?: Persona | null;
  dependientesEconomicos?: DependienteEconomico[] | null;
  periodoBeca?: PeriodoBeca | null;
  programa?: Programa | null;
  bitacoraLiberacionBecasId?: string | null;
  tieneAjusteVigencia?: boolean | false;
}

export class Beca implements IBeca {
  constructor(
    public id?: string | null,
    public numeroApoyo?: number | null,
    public estadoActual?: EstadoBeca | null,
    public solicitudId?: string | null,
    public solicitudRizomaId?: string | null,
    public solucionSolicitud?: Solucion | null,
    public becario?: Persona | null,
    public dependientesEconomicos?: DependienteEconomico[] | null,
    public periodoBeca?: PeriodoBeca | null,
    public programa?: Programa | null,
    public bitacoraLiberacionBecasId?: string | null,
    public tieneAjusteVigencia?: boolean | false
  ) {}

  static ubicacionBeca(tipoSolucion: TipoSolucion) {
    switch (tipoSolucion) {
      case TipoSolucion.BECAS_NACIONALES:
        return 'NACIONAL';
      case TipoSolucion.BECAS_EXTRANJERAS:
        return 'EXTRANJERA';
      default:
        return '';
    }
  }

  static fromObject(data: IBeca): Beca {
    const beca = new Beca();
    for (const [key, value] of Object.entries(data)) {
      beca[key] = value;
    }
    return beca;
  }
}
