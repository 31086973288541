import { Component, Prop, Vue } from 'vue-property-decorator';
import { CuentaBancaria } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class CuentasBancariasComponent extends Vue {
  @Prop({ required: false })
  public item: Array<CuentaBancaria>;

  get cuentasBancarias(): Array<CuentaBancaria> {
    return this.item;
  }

  get fields() {
    return [
      { key: 'proceso', label: 'Proceso', sortable: true },
      { key: 'banco', label: 'Banco', sortable: true },
      { key: 'numeroCuenta', label: 'Cuenta clabe', sortable: true },
      { key: 'estadoSolicitud', label: 'Estado', sortable: true },
      {
        key: 'fechaModificacion',
        label: 'Fecha modificación',
        sortable: true,
        formatter: value => dateUtils.formatUtcMx(value),
      },
    ];
  }
}
