var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.temasSnp.form.area") + " *",
                            "label-for": "input-area",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-area",
                              options: _vm.areas,
                              placeholder: _vm.$t(
                                "apeironGwApp.temasSnp.form.area"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.tema.area.$invalid,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.cambiarSeleccionArea(true)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.temasSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.tema.area,
                              callback: function ($$v) {
                                _vm.$set(_vm.tema, "area", $$v)
                              },
                              expression: "tema.area",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.temasSnp.form.tema") + " *",
                            "label-for": "input-tema",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-tema",
                              options: _vm.temas,
                              placeholder: _vm.$t(
                                "apeironGwApp.temasSnp.form.tema"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.tema.tema.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: undefined,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm
                                                .$t(
                                                  "apeironGwApp.temasSnp.form.seleccionarUno"
                                                )
                                                .toString()
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.tema.tema,
                              callback: function ($$v) {
                                _vm.$set(_vm.tema, "tema", $$v)
                              },
                              expression: "tema.tema",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "input-principal",
                            name: "input-principal",
                          },
                          model: {
                            value: _vm.tema.principal,
                            callback: function ($$v) {
                              _vm.$set(_vm.tema, "principal", $$v)
                            },
                            expression: "tema.principal",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm
                                  .$t(
                                    "apeironGwApp.temasSnp.form.principalLeyenda"
                                  )
                                  .toString()
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-row", [_c("b-col", [_c("hr")])], 1),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "end" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary",
                            disabled: _vm.formularioIsInvalid || _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.agregarTema()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.temasSnp.acciones.add")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "add" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "button", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.limpiarDatos()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.temasSnp.acciones.limpiar")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "refresh" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }