import { Component, Inject, Vue, Prop } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';
import { Menu } from '@/shared/model/menu.model';
import { CustomComponente } from '@/shared/model/enumerations/custom-componente.model';

import EntitiesMenu from '@/entities/entities-menu.vue';
import { ComponenteFactory } from '@/shared/model/componente.model';
import '@/shared/config/dayjs';
import draggable from 'vuedraggable';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    draggable,
  },
})
export default class SideNavbarComponent extends Vue {
  @Prop({ required: true })
  public sidebarOpen: string;
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;

  public dragOptions = {
    animation: 0,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
  };
  public version = 'v1.0.0';
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  private menu = new Menu();
  public isSideNavbarActivated = false;
  public isPreview = false;
  public isReadonly = false;
  public hover = false;

  public currentTab = '';
  public currentStepIndex = -1;
  public currentHover = '';

  public solicitante = null;
  public estado = null;

  //TODO Refactorizar para quitar evento y utilizar sólo el store
  mounted() {
    (this.$root as any).$on('update-menu', this.handleUpdateMenu);
    (this.$root as any).$on('next-menu-item', this.handleNextMenuItem);
    (this.$root as any).$on('update-componentes', this.handleUpdateComponentes);
    (this.$root as any).$on('update-componentes-revision', this.handleUpdateComponentesRevision);
    (this.$root as any).$on('show-side-navbar', this.handleShowSideNavBar);
    (this.$root as any).$on('update-solicitante', this.handleUpdateSolicitante);
    (this.$root as any).$on('update-estado', this.handleUpdateEstado);
  }

  beforeDestroy() {
    (this.$root as any).$off('update-menu', this.handleUpdateMenu);
    (this.$root as any).$off('next-menu-item', this.handleNextMenuItem);
    (this.$root as any).$off('update-componentes', this.handleUpdateComponentes);
    (this.$root as any).$off('update-componentes-revision', this.handleUpdateComponentesRevision);
    (this.$root as any).$off('show-side-navbar', this.handleShowSideNavBar);
    (this.$root as any).$off('update-solicitante', this.handleUpdateSolicitante);
    (this.$root as any).$off('update-estado', this.handleUpdateEstado);
  }

  public initSideNavbar() {
    this.currentTab = '';
    this.currentStepIndex = -1;
    this.currentHover = '';
  }
  public handleUpdateMenu(data) {
    this.menu = data;
    this.isPreview = false;
  }

  public handleUpdateSolicitante(data) {
    this.solicitante = data;
  }
  public handleUpdateEstado(data) {
    this.estado = data;
  }

  public handleNextMenuItem(nextStep) {
    const currentIndex = this.getCurrentMenuItemIndex();
    if (currentIndex < this.menu.componentes.length - 1) {
      //se carga el siguiente componente a mostrar
      const nextComponente = this.menu.componentes[currentIndex + 1];
      this.currentTab = nextComponente.formId;
      this.loadForm(nextComponente, currentIndex + 1);
    } else {
      // en caso de que ya no existan más componentes, se muestra el componente de acciones
      this.currentTab = CustomComponente.ACCIONES;
      this.loadAccionesForm();
    }
  }

  public getCurrentMenuItemIndex() {
    return this.menu.componentes.findIndex(componente => componente.formId === this.currentTab);
  }
  public handleUpdateComponentes(data) {
    this.menu.componentes = data;
    this.isPreview = false;
  }
  public handleUpdateComponentesRevision(data) {
    this.menu.revisionComponentes = data;
    this.isPreview = false;
  }

  public handleShowSideNavBar(data) {
    this.isSideNavbarActivated = data;
    if (this.isSideNavbarActivated) {
      this.initSideNavbar();
      this.$store.dispatch('openSidebar');
    } else {
      this.$store.dispatch('closeSidebar');
    }
  }

  public loadAccionesForm() {
    this.loadForm(ComponenteFactory.getAcciones(), 100);
  }

  public loadCuestionarioForm() {
    this.loadForm(ComponenteFactory.getContestarEncuesta());
  }

  public loadSendReviewForm() {
    this.loadForm(ComponenteFactory.getEnviarEncuesta(), null, true);
  }

  public loadAssignReviewerForm() {
    this.loadForm(ComponenteFactory.getAsignarRevisor());
  }

  public loadAceptarRevisionForm() {
    this.loadForm(ComponenteFactory.getAceptarRevisar());
  }

  public loadComentariosForm() {
    this.loadForm(ComponenteFactory.getComentarios());
  }

  public loadAddComentariosForm() {
    this.loadForm(ComponenteFactory.getAgregarComentarios());
  }
  public loadOtrasRevisiones() {
    this.loadForm(ComponenteFactory.getLoadOtrasRevisiones());
  }

  public loadForm(component: any, stepIndex?: number, isRevisionFormActivated?: boolean) {
    const isReviewActivated = isRevisionFormActivated ?? false;
    this.currentStepIndex = stepIndex ?? this.currentStepIndex;
    this.currentTab = component.formId;
    (this.$root as any).$emit('load-form', component, isReviewActivated);
  }

  public isActive(tabKey) {
    return this.currentTab === tabKey;
  }

  public isHover(formId) {
    return this.currentHover === formId;
  }

  public mouseover(formId) {
    this.currentHover = formId;
  }

  public mouseleave(formId) {
    this.currentHover = '';
  }

  public afterDragging() {
    let index = 0;
    this.menu.componentes.forEach(component => {
      component.orden = index++;
    });
    (this.$root as any).$emit('rearrange-menu', this.menu);
  }

  /// revisar porque truena cuando quito un elemento del menu
  public handleDeleteComponent(formId) {
    const index = this.menu.componentes.findIndex(item => item.formId === formId);
    this.menu.componentes.splice(index, 1);
    (this.$root as any).$emit('delete-form-component', formId);
  }

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    if (this.$route.path !== '/') {
      return this.$router.push('/');
    }
    return Promise.resolve(this.$router.currentRoute);
  }

  public handleActivatePreview(activate: boolean) {
    this.isPreview = activate;
    (this.$root as any).$emit('is-preview-activated', activate);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public previousState(): void {
    this.$router.go(-1);
  }

  public resolveStep(step: number) {
    return '' + (step + 1);
  }

  public resolveStepVariant(stepIndex: number) {
    return stepIndex <= this.currentStepIndex ? 'primary' : 'secondary';
  }

  public resolveStepVariantForReview(stepIndex: number) {
    return stepIndex <= this.currentStepIndex ? 'danger' : 'secondary';
  }

  get isMenuEditable(): boolean {
    return !this.menu.isReadOnly && !this.isPreview;
  }

  get lastStepNumber(): string {
    if (!this.menu.componentes) {
      return '1';
    }
    return '' + (this.menu.componentes.length + 1);
  }
}
