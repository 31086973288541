var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12", lg: "6" } },
            [
              _c(
                "b-input-group",
                { attrs: { size: "sm" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "filter-rule-input",
                      autocomplete: "off",
                      placeholder: _vm.$t(
                        "apeironGwApp.transicion.businessRules.search"
                      ),
                    },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _vm.filter
                        ? _c("b-icon", {
                            staticClass: "i-selected ml-2",
                            attrs: {
                              icon: "x-circle",
                              variant: "primary",
                              "font-scale": "1.5",
                            },
                            on: {
                              click: function ($event) {
                                _vm.filter = null
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "text-right mt-1",
              attrs: { sm: "12", md: "12", lg: "6" },
            },
            [
              _c("b-icon", {
                attrs: {
                  icon: "check2-square",
                  variant: "secondary",
                  "font-scale": "1.2",
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text-muted",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("global.messages.selected.title", {
                      count: _vm.totalSelectedRows,
                    })
                  ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        staticClass: "text-nowrap mt-3",
        attrs: {
          responsive: "",
          borderless: "",
          small: "",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          items: _vm.items,
          fields: _vm.customFields,
          filter: _vm.filter,
          "tbody-tr-class": _vm.rowClass,
        },
        on: { filtered: _vm.onFiltered },
        scopedSlots: _vm._u([
          {
            key: "head(#)",
            fn: function () {
              return [_vm._v("​")]
            },
            proxy: true,
          },
          {
            key: "head(acciones)",
            fn: function () {
              return [_vm._v("​")]
            },
            proxy: true,
          },
          {
            key: "cell(clave)",
            fn: function (row) {
              return [
                row.item.deprecated
                  ? _c("del", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(row.item.clave)),
                    ])
                  : _c("span", [_vm._v(" " + _vm._s(row.item.clave) + " ")]),
              ]
            },
          },
          {
            key: "cell(tags)",
            fn: function (row) {
              return _vm._l(row.item.tags, function (tag) {
                return _c(
                  "span",
                  { key: tag },
                  [
                    tag
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "mr-1",
                            attrs: { pill: "", variant: "primary" },
                          },
                          [_vm._v(_vm._s(tag) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              })
            },
          },
          {
            key: "cell(#)",
            fn: function (row) {
              return [
                _vm.isRowSelected(row.item)
                  ? _c(
                      "div",
                      [
                        _c("b-icon", {
                          staticClass: "i-selected",
                          attrs: {
                            icon: "check-square",
                            variant: "primary",
                            "font-scale": "1.2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleRemoveComponente(row)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasArguments(row)
                          ? _c("b-icon", {
                              staticClass: "i-selected",
                              attrs: {
                                variant: "primary",
                                "font-scale": "1.2",
                                icon: "gear",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openConfigurarComponente(row)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("b-icon", {
                      staticClass: "i-selected",
                      attrs: {
                        variant: "primary",
                        "font-scale": "1.2",
                        icon: "square",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleAddComponente(row)
                        },
                      },
                    }),
              ]
            },
          },
          {
            key: "row-details",
            fn: function () {
              return [
                _c("b-card", [
                  _vm.currentRowSelected
                    ? _c(
                        "div",
                        [
                          _c("arguments-config", {
                            attrs: {
                              "transition-element": _vm.currentRowSelected,
                            },
                            on: { update: _vm.handleUpdateArguments },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(acciones)",
            fn: function (row) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm.isRowSelected(row.item)
                    ? _c(
                        "div",
                        [
                          _c("b-icon", {
                            staticClass: "mr-2",
                            attrs: {
                              variant: "primary",
                              "font-scale": "1.2",
                              icon: "gear",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openConfigurarComponente(row)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-pagination", {
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          align: "center",
          size: "sm",
          pills: "",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }