import { Prop, Inject, Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { Solicitud } from '@/shared/model/solicitud.model';
import SolucionMixin from '@/entities/evaluacion/mixins/solucion-mixin';
import RevisionMixin from '@/entities/evaluacion/mixins/revision-mixin';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { Solucion } from '@/shared/model/solucion.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { fullName } from '@/shared/util/format-util';
import SolicitudService from '@/entities/solicitud/solicitud.service';

@Component
export default class AsignacionOperadorComponent extends mixins(ApeironComponent) {
  @Inject('solicitudService') public solicitudService: () => SolicitudService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: false })
  public solucion: Solucion;

  @Prop({ required: false, default: false })
  public operacionMasiva: boolean;

  public login = this.$store.getters.account?.login ?? '';

  public listaOperadores: Array<any> = [];

  public operadorSelect: string = null;

  public isValidUser = false;

  mounted(): void {
    this.userRolConsejo();
    this.operadorSelect = this.solicitud?.operadorSelect ?? null;
  }

  /**
   * Valida si el usuario logeado es miembro de esa solución
   * y tiene un rol en específico, en caso afirmativo detona
   * la consulta de los miembros con Rol Operador.
   */
  @Watch('solucion')
  public userRolConsejo(): void {
    this.isValidUser = this.validaSolucionUsuarioRol(this.solucion, this.login, RolAutoridad.SUBDIRECTOR);
    if (this.isValidUser) {
      this.getOperadores();
    }
  }

  /**
   * Busca los miembros de la solución con rol Operador.
   */
  private getOperadores(): void {
    for (const autoridad of this.solucion.autoridades) {
      for (const rolesAut of autoridad.roles) {
        if (rolesAut === RolAutoridad.OPERADOR) {
          const nombreCompleto = fullName(autoridad);
          const operadorAdd = { usuarioId: autoridad.usuarioId, nombre: nombreCompleto };
          this.listaOperadores.push(operadorAdd);
        }
      }
    }
  }

  /**
   * Guarda  en la solicitud el operador seleccionador.
   */
  public guardarOperador(): void {
    const datosEnviar = { operadorSelect: this.operadorSelect };
    this.save(datosEnviar);
  }

  /**
   * Guarda en las solicitudes el operador seleccionado
   * @param solicitudes arreglo de solicitudes
   * @returns retorna una promesa para cuando termine de actualizar
   */
  public guardaOperadores(solicitudes: Array<any>): Promise<void> {
    return new Promise<void>(resolve => {
      let numeroSolActualizadas = 0;
      const operador = this.operadorSelect;
      solicitudes.forEach(sol1 => {
        this.solicitudService()
          .find(sol1.id)
          .then(sol2 => {
            sol2.operadorSelect = operador;
            this.solicitudService()
              .update(sol2)
              .then(function () {
                numeroSolActualizadas++;
                if (solicitudes.length == numeroSolActualizadas) {
                  resolve();
                }
              });
          });
      });
    });
  }

  /**
   * Verifica que el usuario tenga el rol indicado
   *
   * @param solucionSelect
   * @param username
   * @param rol
   * @returns
   */
  private validaSolucionUsuarioRol(solucionSelect: Solucion, username: string, rol: string): boolean {
    for (const autoridad of solucionSelect.autoridades) {
      if (autoridad.usuarioId === username) {
        for (const rolesAut of autoridad.roles) {
          if (rolesAut === rol) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
