import { Component, Prop, Vue } from 'vue-property-decorator';
import { IArgument } from '@/shared/model/argument.model';

@Component
export default class SelectTransitionElementsComponent extends Vue {
  @Prop({ required: true })
  public id!: string;

  @Prop({ required: true })
  public value!: any[];

  @Prop({ required: true })
  public items!: any[];

  @Prop([Boolean])
  public addTagsColumn: boolean;

  @Prop([Boolean])
  public addConditionColumn: boolean;

  public isFirst = true;

  public isLoading = false;

  filter = null;
  filterOn = [];
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  selected = [];
  originalSelected = [];

  mounted() {
    this.totalRows = this.items.length;

    //se inicializa una copia editable de la selección actual
    this.selected = JSON.parse(JSON.stringify(this.value));

    //se inicializa una copia origina que unicamente se manipula para agregar elementos y actualizar la lista original
    this.originalSelected = JSON.parse(JSON.stringify(this.value));

    //se inicializa el componente para que no muestre detalles en un inicio
    for (const item of this.items) {
      if (item._showDetails) {
        item._showDetails = false;
      }
    }
  }

  get customFields() {
    let defaultFields = [{ key: '#', stickyColumn: true }, 'clave', 'nombre'];
    if (this.addConditionColumn) {
      defaultFields = defaultFields.concat(['condicion']);
    }
    if (this.addTagsColumn) {
      defaultFields = defaultFields.concat(['tags']);
    }

    return defaultFields;
  }

  get totalSelectedRows() {
    if (this.selected?.length) {
      return this.selected.length;
    }
    return 0;
  }

  public onFiltered(filteredItems) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
  public rowClass(item, type) {
    if (!item || type !== 'row') return '';
    if (this.isRowSelected(item)) return 'table-primary-opacity-10';
  }

  public isRowSelected(item) {
    if (this.selected?.length) {
      return this.selected.filter(element => element.clave === item.clave).length > 0;
    }
    return false;
  }

  public hasArguments(row) {
    if (row?.item?.arguments?.length > 0) {
      return true;
    }
    return false;
  }

  public currentRowSelected = null;
  public openConfigurarComponente(row) {
    this.currentRowSelected = this.originalSelected.find(selected => selected.clave === row.item.clave);
    row.toggleDetails();
  }

  public handleUpdateArguments(argumentos: IArgument[]) {
    this.$set(this.currentRowSelected, 'arguments', argumentos);
    this.$emit('input', this.originalSelected);
  }

  /**
   * Este método se utiliza para mapear únicamente los valores
   * de un registro que nos interesa copiar en la lista original,
   * de tal manera que propiedades que agrega el framework
   * de b-table como lo son rowSelected entre otros
   */
  public mapToOriginalSelection(item) {
    return {
      clave: item.clave,
      nombre: item.nombre,
      condicion: item.condicion,
      tags: item.tags,
      deprecated: item.deprecated,
      arguments: this.createArgumentsFrom(item.arguments),
    };
  }

  createArgumentsFrom(args: any[]) {
    if (args?.length > 0) {
      const clonedArgs = JSON.parse(JSON.stringify(args));
      for (const argument of clonedArgs) {
        if (!argument.value) {
          this.$set(argument, 'value', null);
        }
      }
      return clonedArgs;
    }
    return [];
  }

  public handleAddComponente(row) {
    this.selected.push(row.item);
    this.originalSelected.push(this.mapToOriginalSelection(row.item));
    this.$emit('input', this.originalSelected);
  }

  public handleRemoveComponente(row) {
    if (row.detailsShowing) {
      row.toggleDetails();
    }
    const originalSelectedIndex = this.originalSelected.findIndex(element => element.clave === row.item.clave);
    const selectedIndex = this.selected.findIndex(element => element.clave === row.item.clave);
    this.originalSelected.splice(originalSelectedIndex, 1);
    this.selected.splice(selectedIndex, 1);
    this.$emit('input', this.originalSelected);
  }
}
