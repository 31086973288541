import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import exportFromJSON from 'export-from-json';

@Component
export default class ExportFromJsonComponent extends Vue {
  @Prop({ required: true })
  data: any;

  @Prop({ required: false })
  fileName: string;

  public downloadFile() {
    const data = this.data;
    const fileName = this.fileName;
    const exportType = exportFromJSON.types.csv;
    if (data) exportFromJSON({ data, fileName, exportType });
  }
}
