var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.icons, function (icon, index) {
      return _c(
        "b-button",
        {
          key: index,
          staticClass: "m-1",
          attrs: { variant: "light", size: "sm" },
          on: {
            click: function ($event) {
              return _vm.handleSelectedIcon(icon)
            },
          },
        },
        [_c("span", { class: `icon-` + icon })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }