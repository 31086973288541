const solicitudSteps: any = {
  steps: [
    {
      target: '#menu-dinamico-id',
      content: `Completa todas las secciones que aquí se indican. Una vez terminadas, podrás pasar a la última sección para completar tu solicitud. Da <strong>click</strong> en alguna de las secciones y a continuación da <strong>click</strong> en el boton de <strong>Siguiente</strong> para continuar `,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#formulario-dinamico-id',
      content: `En esta zona se cargarán los formularios que deberás de llenar con la finalidad de completar tu solicitud.`,
      params: {
        placement: 'top',
      },
    },
    {
      target: '#acciones-id',
      content: `Esta sección es muy importante, aquí podrás <strong>enviar</strong>, <strong>cancelar</strong> o hace otras acciones sobre tu solicitud actual`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#comentarios-id',
      content: `En la sección de Comentarios podrás ver todos los mensajes que Conacyt te envíe. En caso de que nuestro equipo encargado de revisar tu solicitud requiera que modifiques algún documento o información de tu solicitud, en esta sección te aparecerán las notificaciones.`,
      params: {
        placement: 'right',
      },
    },
  ],
};

export default solicitudSteps;
