var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "margin-initial" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("span", { staticClass: "h4" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "apeironGwApp.programa.programaIntegrado.seleccionePrograma"
                  )
                )
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-overlay",
                {
                  attrs: { show: _vm.isFetching, "spinner-variant": "primary" },
                },
                [
                  _c("b-table", {
                    attrs: {
                      "sticky-header": "500px",
                      "head-variant": "dark",
                      items: _vm.programas,
                      fields: _vm.programasFields,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(clave)",
                        fn: function (data) {
                          return [
                            _c("span", {
                              domProps: { textContent: _vm._s(data.value) },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(nombre)",
                        fn: function (data) {
                          return [
                            _c("span", {
                              domProps: { textContent: _vm._s(data.value) },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(integrar)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly && !row.item.integrado
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-1",
                                    attrs: {
                                      variant: "outline-success",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleIntegrarPrograma(
                                          row.item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("entity.action.check")) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-select", {
                staticClass: "w-25",
                attrs: { size: "sm", options: _vm.perPageOptions },
                on: { change: _vm.handlePageSizeChange },
                model: {
                  value: _vm.perPage,
                  callback: function ($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [
                _c("small", { staticClass: "font-weight-bold text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("global.table.perPage"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "center",
                  size: "sm",
                  pills: "",
                  disabled: _vm.isFetching,
                },
                on: { change: _vm.handlePageChange },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", [
            _c("span", [
              _c("small", { staticClass: "text-muted font-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("global.table.totalRows", {
                      totalRows: _vm.totalRows,
                    })
                  )
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "line-break" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("span", { staticClass: "h4" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "apeironGwApp.programa.programaIntegrado.programasIntegrados"
                  )
                )
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-table", {
                attrs: {
                  "sticky-header": "500px",
                  "head-variant": "dark",
                  items: _vm.programasIntegrados,
                  fields: _vm.programasIntegradosFields,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(clave)",
                    fn: function (data) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(data.value) },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(nombre)",
                    fn: function (data) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(data.value) },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(fechaInicio)",
                    fn: function (data) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(data.value) },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(fechaFin)",
                    fn: function (data) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(data.value) },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(acciones)",
                    fn: function (row) {
                      return [
                        !_vm.readOnly
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mr-1",
                                attrs: {
                                  variant: "outline-primary",
                                  title: _vm.$t("entity.action.edit"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleProgramaIntegradoEdit(
                                      row.item
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "pencil" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.readOnly
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mr-1",
                                attrs: {
                                  variant: "outline-danger",
                                  title: _vm.$t("entity.action.delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleProgramaIntegradoDelete(
                                      row.item
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "trash" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", "offset-md": "10" } },
            [
              _vm.form.programa?.id && !_vm.readOnly
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.save(_vm.form)
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.save")),
                        },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "save" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addIntegrado",
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            size: "xl",
            id: "addIntegrado",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.isSaving,
                                variant: "outline-danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCloseIntegrado()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("entity.action.cancel")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-2",
                              attrs: {
                                disabled: _vm.isSaving || !_vm.isFormValid,
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSaveIntegrado()
                                },
                              },
                            },
                            [
                              _vm.isSaving
                                ? _c("i", [
                                    _vm._v(
                                      _vm._s(_vm.$t("entity.action.saving"))
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("entity.action.save"))
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c(
                    "span",
                    {
                      attrs: { slot: "modal-title", "label-size": "lg" },
                      slot: "modal-title",
                    },
                    [
                      _c("p", { staticClass: "h4 mb-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "apeironGwApp.programa.programaIntegrado.registrarModalTitulo"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-size": "lg",
                          label: _vm.$t(
                            "apeironGwApp.programa.programaIntegrado.fechaInicio"
                          ),
                          "label-for": "input-sm",
                        },
                      },
                      [
                        _c("b-form-datepicker", {
                          staticClass: "mb-2",
                          attrs: {
                            disabled: _vm.readOnly,
                            placeholder: "",
                            required: "",
                            locale: "es",
                          },
                          model: {
                            value: _vm.fechaInicio,
                            callback: function ($$v) {
                              _vm.fechaInicio = $$v
                            },
                            expression: "fechaInicio",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.isFormValid
                          ? _c("small", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("caracteres.requerido")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-size": "lg",
                          label: _vm.$t(
                            "apeironGwApp.programa.programaIntegrado.fechaFin"
                          ),
                          "label-for": "input-sm",
                        },
                      },
                      [
                        _c("b-form-datepicker", {
                          staticClass: "mb-2",
                          attrs: {
                            disabled: _vm.readOnly,
                            placeholder: "",
                            locale: "es",
                          },
                          model: {
                            value: _vm.fechaFin,
                            callback: function ($$v) {
                              _vm.fechaFin = $$v
                            },
                            expression: "fechaFin",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    !_vm.isFormValid
                      ? _c("message", {
                          staticClass: "mt-5",
                          attrs: {
                            variant: "danger",
                            description: _vm.$t(
                              "apeironGwApp.persona.form.messages.requeridos"
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmProgramaIntegradoRemoveModal",
          attrs: {
            "header-bg-variant": "primary",
            title: _vm.$t("entity.delete.title"),
            size: "xl",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancelConfirmation()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleConfirmation()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c("p", { staticClass: "modal-body text-center h3 mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("entity.delete.question"))),
                  ]),
                ]),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }