var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "componente-aviso-tipo",
                size: "sm",
                label: "Tipo de aviso",
                "label-for": "aviso-tipo-id",
              },
            },
            [
              _c("b-form-select", {
                staticClass: "mb-3",
                attrs: { options: _vm.tiposAviso },
                model: {
                  value: _vm.aviso.tipo,
                  callback: function ($$v) {
                    _vm.$set(_vm.aviso, "tipo", $$v)
                  },
                  expression: "aviso.tipo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: "",
                variant: _vm.aviso.tipo ? _vm.aviso.tipo.toLowerCase() : "",
              },
            },
            [
              _c(
                "h4",
                { staticClass: "alert-heading" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "componente-aviso-titulo",
                        size: "sm",
                        label: "Título",
                        "label-for": "aviso-titulo-id",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "aviso-titulo-id" },
                        model: {
                          value: _vm.aviso.titulo,
                          callback: function ($$v) {
                            _vm.$set(_vm.aviso, "titulo", $$v)
                          },
                          expression: "aviso.titulo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "componente-aviso-descripcion",
                        size: "sm",
                        label: "Descripción",
                        "label-for": "aviso-descripcion-id",
                      },
                    },
                    [
                      _c("vue-editor", {
                        attrs: { id: "aviso-descripcion-id" },
                        model: {
                          value: _vm.aviso.descripcion,
                          callback: function ($$v) {
                            _vm.$set(_vm.aviso, "descripcion", $$v)
                          },
                          expression: "aviso.descripcion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "componente-aviso-pie-de-pagina",
                          size: "sm",
                          label: "Pie de paǵina",
                          "label-for": "aviso-pie-de-pagina-id",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "mensaje-bienvenida" },
                          model: {
                            value: _vm.aviso.pieDePagina,
                            callback: function ($$v) {
                              _vm.$set(_vm.aviso, "pieDePagina", $$v)
                            },
                            expression: "aviso.pieDePagina",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("message", {
            attrs: {
              variant: _vm.aviso.tipo ? _vm.aviso.tipo.toLowerCase() : "",
              title: _vm.aviso.titulo,
              description: _vm.aviso.descripcion,
              footer: _vm.aviso.pieDePagina,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }