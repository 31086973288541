export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  OPERADOR = 'ROLE_OPERADOR',
  INNOVADOR = 'ROLE_INNOVADOR',
  MODELADOR = 'ROLE_MODELADOR',
  SOPORTE = 'ROLE_SOPORTE',
  OPERADOR_PROGRAMA = 'ROLE_OPERADOR_PROGRAMA',
  INTERNAL_USER = 'ROLE_INTERNAL_USER',
  AUDITOR = 'ROLE_AUDITOR',
}
