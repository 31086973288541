import { Component, Inject, Vue } from 'vue-property-decorator';

import AccountService from '@/account/account.service';

@Component
export default class EntitiesMenu extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  hasAnyAuthority(authorities: any): boolean {
    return this.accountService().hasAnyAuthority(authorities);
  }
}
