import { Component, Prop, Vue } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import resolveEstadoSolicitudVariant from '@/shared/estados/estados.solicitud.util';
import { Estado } from '@/shared/model/proceso/estado.model';

@Component
export default class SolucionResumenComponent extends Vue {
  @Prop({ required: true })
  public solucion: any;

  public estados = [];

  public acciones = [];
  public diagrama = [];
  public diagramId = 0;

  mounted() {
    this.renderProcess();
  }

  created(): void {
    this.estados = this.resolveEstados();
    this.acciones = this.resolveAcciones();
  }

  public config = {
    theme: 'base',
    themeVariables: {
      primaryColor: '#E4F4FC', // fill color
      primaryBorderColor: '#99ccff', // border color
      primaryTextColor: '#000000', // text color
      lineColor: '#999999',
      secondaryTextColor: '#99ccff', // text color
      secondaryColor: '#FFFFFF',
      tertiaryColor: '#fff',
      transitionColor: '#800000',
    },
  };

  public resolveAcciones() {
    const acciones = [];
    const accionesKeys = Object.keys(TipoAccion);

    accionesKeys.forEach(key => {
      acciones.push({ value: key, text: this.$t('apeironGwApp.TipoAccion.' + key), disabled: false });
    });
    return acciones;
  }

  public resolveEstados() {
    const estados = [];
    const estadosKeys = Object.keys(EstadoSolicitud);

    estadosKeys.forEach(key => {
      estados.push({ value: key, text: this.$t('archeApp.EstadoSolicitud.' + key), disabled: false });
    });
    return estados;
  }

  public resolveEstadoSolicitudVariant(estado) {
    return resolveEstadoSolicitudVariant(estado);
  }

  public getEstadoName(estadoKey) {
    return this.$t('archeApp.EstadoSolicitud.' + estadoKey) + ':';
  }

  public renderProcess() {
    const diagrama = [];

    this.solucion.proceso.estados.forEach(estado => {
      const transiciones = [];
      diagrama.push({
        id: estado.nombre,
        editable: true,
        text: this.getEstadoName(estado.nombre),
      });
      estado.transiciones.forEach(transicion => {
        diagrama.push({
          id: estado.nombre,
          text: this.getEstadoName(estado.nombre),
          editable: true,
          link:
            '-- ' +
            '<p><i>' +
            this.getAccionName(transicion.accion) +
            '</i>:</p>' +
            '<small>' +
            this.resolveRoles(estado, transicion.accion) +
            '</small>' +
            ' -->',
          next: [transicion.destino],
        });
      });
    });
    this.diagrama = diagrama;
    this.diagramId += 1;
  }

  public resolveRoles(estado: Estado, accion: TipoAccion) {
    const roles = [];
    estado.permisos.forEach(permiso => {
      if (permiso.acciones.findIndex(e => e === accion) > -1) {
        roles.push(permiso.rol.toLocaleLowerCase());
      }
    });
    return roles;
  }

  public getAccionName(estadoKey) {
    return this.$t('apeironGwApp.TipoAccion.' + estadoKey);
  }
}
