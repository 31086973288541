export interface IMenuClasificacionLieSnp {
  value?: ClasificacionLieSnp;
  text?: string;
}

export class MenuClasificacionLieSnp implements IMenuClasificacionLieSnp {
  constructor(public value?: ClasificacionLieSnp, public text?: string) {}
}

export interface IMenuInstitucionLieSnp {
  value?: InstitucionLieSnp;
  text?: string;
}

export class MenuInstitucionLieSnp implements IMenuInstitucionLieSnp {
  constructor(public value?: InstitucionLieSnp, public text?: string) {}
}

export interface IClasificacionLieSnp {
  id?: string;
  nombre?: string;
}

export class ClasificacionLieSnp implements IClasificacionLieSnp {
  constructor(public id?: string, public nombre?: string) {}
}

export interface ITemasLieSnp {
  claveAreaPeciti?: number;
  clavePrioridadPeciti?: number;
  areaPeciti?: string;
  prioridadPeciti?: string;
}

export class TemasLieSnp implements ITemasLieSnp {
  constructor(
    public claveAreaPeciti?: number,
    public clavePrioridadPeciti?: number,
    public areaPeciti?: string,
    public prioridadPeciti?: string
  ) {}
}

export interface ISedeLieSnp {
  claveSede?: string;
  claveDependencia?: string;
  nombreDependencia?: string;
  sede?: string;
}

export class SedeLieSnp implements ISedeLieSnp {
  constructor(public claveSede?: string, public claveDependencia?: string, public nombreDependencia?: string, public sede?: string) {}
}

export interface IInstitucionLieSnp {
  claveInstitucion?: string;
  nombreInstitucion?: string;
  sede?: SedeLieSnp;
}

export class InstitucionLieSnp implements IInstitucionLieSnp {
  constructor(public claveInstitucion?: string, public nombreInstitucion?: string, public sede?: SedeLieSnp) {}
}

export interface ILieSnp {
  id?: string;
  nombreDeLinea?: string;
  clasificacion?: ClasificacionLieSnp;
  fechaAlta?: Date;
  temasPrioritariosPeciti?: Array<TemasLieSnp>;
  institucionCedeDependencia?: IInstitucionLieSnp;
  justificacion?: string;
  activo?: boolean;
}

export class LieSnp implements ILieSnp {
  constructor(
    public id?: string,
    public nombreDeLinea?: string,
    public clasificacion?: ClasificacionLieSnp,
    public fechaAlta?: Date,
    public temasPrioritariosPeciti?: Array<TemasLieSnp>,
    public institucionCedeDependencia?: IInstitucionLieSnp,
    public justificacion?: string,
    public activo?: boolean
  ) {}
}
