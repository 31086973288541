import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
@Component
export default class ConfirmationComponent extends Vue {
  @Prop({ default: 'confirmation' })
  id: string;

  @Prop({ default: 'Confirmación' })
  title: string;

  @Prop({ default: '¿Está seguro de ejecutar esta acción?' })
  question: string;

  @Prop({ default: false })
  check: boolean;

  @Prop({ default: false })
  addComentario: boolean;

  @Prop({ default: false })
  scrollable: boolean;

  @Prop({ default: 'xl' })
  size: string;

  @Prop({ default: false })
  centered: boolean;

  public comentario = '';

  public accept = false;

  public handleCancel(): void {
    this.accept = false;
    this.hideModal();
  }

  get isDisabled() {
    if (this.addComentario && this.comentario.length < 10) {
      return true;
    }

    if (this.check) {
      return !this.accept;
    } else {
      return false;
    }
  }

  public handleConfirmation(): void {
    this.$emit('confirmed', this.comentario);
    this.hideModal();
    this.comentario = '';
  }

  public hideModal() {
    (this.$refs[this.id] as any).hide();
  }
}
