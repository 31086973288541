import { Component, Prop, Vue } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import { IRule } from '@/shared/model/rule.model';
import { IAction } from '@/shared/model/action.model';
import { MockSolicitud } from '@/shared/util/mock-solicitud';
import { Notificacion } from '@/shared/model/notificacion.model';

import { Transicion } from '@/shared/model/proceso/transicion.model';
@Component
export default class TransicionesComponent extends Vue {
  @Prop({ required: true })
  value: Transicion[];

  @Prop({ required: true })
  reglas: IRule[];

  @Prop({ required: true })
  actions: IAction[];

  public estados = [];

  public acciones = [];

  public campos = ['accion', 'destino', 'mensaje', 'confirmar', 'reglas', 'opciones'];

  public transicion = new Transicion();

  public notificacion = new Notificacion();

  public currentIndex = 0;

  public isAddMode = false;

  public variables = [];
  public variableCorreos = [];

  created(): void {
    this.estados = this.resolveEstados();
    this.acciones = this.resolveAcciones();
    this.variables = MockSolicitud.commonVariables();
    this.variableCorreos = MockSolicitud.correos();
  }

  public resolveEstados() {
    const estados = [];
    const estadosKeys = Object.keys(EstadoSolicitud);

    estadosKeys.forEach(key => {
      estados.push({ value: key, text: this.$t('archeApp.EstadoSolicitud.' + key), disabled: false });
    });
    return estados;
  }

  /**
   * Método que agrega una nueva variable al editor del mensaje de una notificación
   *
   * @param {string} variable la variable que se desea agregar en el mensaje
   */
  public addVariable(variable: any) {
    const editor = this.$refs.mensajeNotificacion as any;
    editor.quill.insertText(editor.quill.selection.savedRange.index, ' [[${' + variable.path + '}]] ');
  }

  public resolveAcciones() {
    const acciones = [];
    const accionesKeys = Object.keys(TipoAccion);

    accionesKeys.forEach(key => {
      acciones.push({ value: key, text: this.$t('apeironGwApp.TipoAccion.' + key), disabled: false });
    });
    return acciones;
  }

  get transiciones() {
    return this.value;
  }

  set transiciones(transiciones: Transicion[]) {
    this.$emit('input', transiciones);
  }

  public handleEditar(index) {
    this.isAddMode = false;
    this.currentIndex = index;
    this.transicion = { ...this.transiciones[index] };
    //para evitar que se propague la actualizacion
    this.notificacion = { ...this.transicion.notificacion };
    if (<any>this.$refs.addTransicion) {
      (<any>this.$refs.addTransicion).show();
    }
  }

  public handleOpenAddTransicionModal(): void {
    this.isAddMode = true;
    this.transicion = new Transicion();
    this.notificacion = new Notificacion();
    if (<any>this.$refs.addTransicion) {
      (<any>this.$refs.addTransicion).show();
    }
  }

  public handleCloseAddTransicionModal(): void {
    if (<any>this.$refs.addTransicion) {
      (<any>this.$refs.addTransicion).hide();
    }
  }

  public handleAddTransicion(): void {
    this.transicion.notificacion = { ...this.notificacion };
    if (this.isAddMode) {
      this.transiciones.push(this.transicion);
    } else {
      this.transiciones.splice(this.currentIndex, 1, this.transicion);
    }
    if (<any>this.$refs.addTransicion) {
      (<any>this.$refs.addTransicion).hide();
    }
  }

  public handlePrepareToRemove(currentIndex): void {
    this.currentIndex = currentIndex;
    if (<any>this.$refs.confirmRemoveModal) {
      (<any>this.$refs.confirmRemoveModal).show();
    }
  }

  public addCorreoToPara(variable) {
    this.notificacion.para.push('{{ ' + variable.path + ' }}');
  }
  public addCorreoToCc(variable) {
    this.notificacion.cc.push('{{ ' + variable.path + ' }}');
  }
  public addCorreoToCco(variable) {
    this.notificacion.cco.push('{{ ' + variable.path + ' }}');
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public handleConfirmation(): void {
    this.transiciones.splice(this.currentIndex, 1);
    this.hideModal();
  }

  public hideModal() {
    (this.$refs.confirmRemoveModal as any).hide();
  }
}
