import { ICat } from '@/shared/model/movimiento.model';

export class MotivosCatalogos {
  static readonly MOTIVOS_SANCIONES = [
    { id: 1, clave: 'AMPRIV', desc: 'AMONESTACIÓN PRIVADA' },
    { id: 2, clave: 'AMPUB', desc: 'AMONESTACIÓN PÚBLICA' },
    { id: 3, clave: 'REVCC', desc: 'REVOCACIÓN DEL CARGO O COMISIÓN QUE LE HUBIERE SIDO CONFERIDA EN EL SNII' },
    {
      id: 4,
      clave: 'CANAPOECO',
      desc: 'CANCELACIÓN DEL APOYO ECONÓMICO DURANTE EL TIEMPO QUE DURE LA VIGENCIA DE SU RECONOCIMIENTO EN EL SNII',
    },
    { id: 5, clave: 'CANREC', desc: 'CANCELACIÓN DEL RECONOCIMIENTO EN EL SNII' },
    { id: 6, clave: 'INHAPOY', desc: 'INHABILITACIÓN HASTA POR VEINTE AÑOS PARA RECIBIR APOYOS ECONÓMICOS' },
    {
      id: 7,
      clave: 'INHREC',
      desc: 'INHABILITACIÓN HASTA POR VEINTE AÑOS PARA OBTENER ALGÚN RECONOCIMIENTO EN EL SNII',
    },
  ];

  static readonly MOTIVOS_ADEUDOS = [
    { id: 1, clave: 'BAJINST', desc: 'BAJA INSTITUCIONAL' },
    { id: 2, clave: 'BAJFALLE', desc: 'BAJA POR FALLECIMIENTO' },
    { id: 3, clave: 'BAJUBL', desc: 'BAJA POR JUBILACIÓN' },
    { id: 4, clave: 'BAJLICSIN', desc: 'BAJA POR TENER UNA LICENCIA SIN GOCE DE SUELDO' },
    { id: 5, clave: 'BAJLICCON', desc: 'BAJA POR TENER UNA LICENCIA CON GOCE PARCIAL DE SUELDO' },
    { id: 6, clave: 'SINCONVREC', desc: 'SIN CONVENIO DE RECONOCIMIENTO SNII' },
    { id: 7, clave: 'PACREIP', desc: 'POR TENER UNA ACREDITACIÓN EN UNA IP' },
    { id: 8, clave: 'CAUBI', desc: 'CAMBIO DE UBICACIÓN' },
    { id: 9, clave: 'TERVIG', desc: 'TERMINO DE VIGENCIA' },
    { id: 10, clave: 'CAMNIV', desc: 'CAMBIO DE NIVEL' },
  ];

  static readonly ESTATUS_JUCIOS_AMPARO = [
    { id: 1, clave: '1', desc: 'INICIAL' },
    { id: 2, clave: '2', desc: 'AMPLIACIÓN' },
  ];

  static readonly CATALOGO_ACTO_RECLAMADO = [
    { id: 1, clave: '1', desc: 'REGLAMENTO 2018' },
    { id: 2, clave: '2', desc: 'REGLAMENTO 2021' },
    { id: 3, clave: '3', desc: 'REGLAMENTO 2022' },
    { id: 4, clave: '4', desc: 'REGLAMENTO 2023' },
    { id: 5, clave: '5', desc: 'NUEVA LEY' },
    { id: 6, clave: '6', desc: 'CONVOCATORIA 2023' },
    { id: 7, clave: '7', desc: 'CONVOCATORIA 2024' },
    { id: 8, clave: '8', desc: 'OMISIÓN DE PAGO DERIVADO DEL CONVENIO' },
    { id: 9, clave: '9', desc: 'OTROS' },
  ];

  static readonly ACTO_RECLAMADO_OTROS: ICat = { id: '9', clave: '9', desc: 'OTROS' };

  static readonly OPCIONES_RADIO = [
    { text: 'Sí', value: 'Sí' },
    { text: 'No', value: 'No' },
  ];

  static readonly OPCIONES_RADIO_SUSPENCION = [
    { text: 'Suspensión Provisional', value: 'provisional' },
    { text: 'Suspensión Definitiva', value: 'definitiva' },
    { text: 'No se solicitó', value: 'No se solicitó' },
  ];

  static readonly OPCIONES_RADIO_TIPO_SUSPENCION = [
    { text: 'Concede', value: 'Concede' },
    { text: 'Niega', value: 'Niega' },
  ];

  static readonly OPCIONES_RADIO_TIPO_INCIDENTE = [
    { text: 'Incumplimiento a la suspensión provisional', value: 'Incumplimiento a la suspensión provisional' },
    { text: 'Incumplimiento a la suspensión definitiva', value: 'Incumplimiento a la suspensión definitiva' },
  ];

  static readonly OPCIONES_RADIO_TIPO_EJECUTORIA = [
    { text: 'Cumplimiento', value: 'Cumplimiento' },
    { text: 'Exceso o Defecto en el cumplimiento', value: 'Exceso o Defecto en el cumplimiento' },
    { text: 'Repetición del acto', value: 'Repetición del acto' },
  ];

  static readonly OPCIONES_RADIO_TIPO_OFICIO = [
    { text: 'Pago Programado', value: 'Pago Programado' },
    { text: 'Pago Urgente', value: 'Pago Urgente' },
    { text: 'Evaluación Extraordinaria', value: 'Evaluación Extraordinaria' },
    { text: 'Participación en Convocatoria', value: 'Participación en Convocatoria' },
    { text: 'Otros', value: 'Otros' },
  ];

  static tipoMotivacionAdeudosAsOptions(): Array<any> {
    return MotivosCatalogos.MOTIVOS_ADEUDOS.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }

  static tipoMotivacionSancionesAsOptions(): Array<any> {
    return MotivosCatalogos.MOTIVOS_SANCIONES.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }

  static estatusJucioApamaroAsOptions(): Array<any> {
    return MotivosCatalogos.ESTATUS_JUCIOS_AMPARO.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }
  static catalogoActoReclamadoAsOptions(): Array<any> {
    return MotivosCatalogos.CATALOGO_ACTO_RECLAMADO.map(situacion => {
      return { value: situacion, text: situacion.desc, disabled: false };
    });
  }
}
