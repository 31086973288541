export interface IVigencia {
  fechaInicio: string;
  fechaFin?: string;
}

export class Vigencia implements IVigencia {
  constructor(public fechaInicio: string, public fechaFin?: string) {}
}

export interface IAreaConocimientoEval {
  id?: number;
  descripcion?: string;
}

export class AreaConocimientoEval implements IAreaConocimientoEval {
  constructor(public id?: number, public descripcion?: string) {}
}

export interface INivelSni {
  clave?: string;
  descripcion?: string;
}

export class NivelSni implements INivelSni {
  constructor(public clave?: string, public descripcion?: string) {}
}
export interface ITipoVigencia {
  clave: string;
  descripcion: string;
  activo?: boolean;
}

export class TipoVigencia implements ITipoVigencia {
  constructor(public clave: string, public descripcion: string, public activo?: boolean) {}
}
export interface IEstatusVigencia {
  clave: string;
  descripcion: string;
}

export class EstatusVigencia implements IEstatusVigencia {
  constructor(public clave: string, public descripcion: string) {}
}
export interface IVigenciaDto {
  id?: string;
  solucionId?: string;
  solicitudId?: string;
  nivelSni?: NivelSni;
  vigencia?: Vigencia;
  tipo?: TipoVigencia;
  areaConocimientoEval?: AreaConocimientoEval;
  activo?: boolean;
  fechaAlta?: string;
  cvuUsuarioAlta?: string;
  estatusVigencia?: EstatusVigencia;
  fechaFormalizacionEvaluacion?: string;
  urlConvenio?: string;
  urlConvenioExtension?: string;
  fechaFormalizacionExtension?: string;
  fechaMigracionPS?: string;
  fechaFormalizacionUnificada?: string;

  vigenciaPendiente(): boolean;
}

export class VigenciaDto implements IVigenciaDto {
  constructor(public nivelSni?: NivelSni, public vigencia?: Vigencia, public estatusVigencia?: EstatusVigencia) {
    this.nivelSni = nivelSni;
    this.vigencia = vigencia;
  }

  static fromObject(object: IVigenciaDto): VigenciaDto {
    return Object.assign(new VigenciaDto(), object);
  }

  /**
   * Verifica si la vigencia está pendiente
   * @returns boolean
   */
  vigenciaPendiente(): boolean {
    return this.estatusVigencia?.clave === 'PENDIENTE';
  }
}
