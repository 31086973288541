import { Component, Vue, Inject } from 'vue-property-decorator';
import { StatusCodes } from 'http-status-codes';
import AlertService from '@/shared/alert/alert.service';
import JobStatusComponent from '@/components/pago/job-status/job-status.vue';
import { HistoricoPersonasApi, PadronEncabezado, EstatusJob } from '@conacyt/historicopersonasms-client';

@Component({
  components: {
    'job-status': JobStatusComponent,
  },
})
export default class PadronEncabezadoComponent extends Vue {
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('historicoPersonasApi') public historicoPersonasApi: () => HistoricoPersonasApi;

  public lastPadronEncabezado: PadronEncabezado = null;

  private scheduleTask = null;
  created() {
    this.retrivePadronEncabezado(this.isRetrivingLastPadron);
    this.scheduleTask = setInterval(() => {
      this.updatePadronEncabezadoStatus();
    }, 3 * 1000);
  }

  beforeDestroy() {
    clearInterval(this.scheduleTask);
  }

  isSearching = false;
  isExecuting = false;
  hasErrors = false;
  isRetrivingLastPadron = true;

  public retrivePadronEncabezado(isRetrivingLastPadron?: boolean, searching?: boolean) {
    this.isRetrivingLastPadron = isRetrivingLastPadron ?? true;
    this.isSearching = searching ?? true;
    this.getLastPadronPromise(this.isRetrivingLastPadron)
      .then(result => {
        if (result) {
          this.resetSearch();
          console.log(result.data);
          if (result?.data?.id) {
            this.lastPadronEncabezado = result.data;
          } else {
            this.lastPadronEncabezado = this.defaultPadronEncabezado(null);
          }
          this.doAfterRetrivePadronEncabezado(this.lastPadronEncabezado);
        }
      })
      .catch(error => {
        this.resetSearch();
        if (error.response.data.status !== StatusCodes.NOT_FOUND) {
          this.hasErrors = true;
          this.alertService().showHttpError(this, error.response);
        }
      });
  }

  public resetSearch() {
    this.isSearching = false;
    this.hasErrors = false;
  }

  public getLastPadronPromise(isRetrivingLastPadron: boolean) {
    if (isRetrivingLastPadron) {
      return this.historicoPersonasApi().getLasPadronEncabezado();
    } else {
      return this.historicoPersonasApi().getLasPadronEncabezadoAceptada();
    }
  }

  public doAfterRetrivePadronEncabezado(lastPadronEncabezado: PadronEncabezado) {}

  public getSolicitante() {
    return {
      cvu: this.userInfo.cvu,
      login: this.userInfo.login,
      nombre: this.userInfo.nombre,
      apellidoPaterno: this.userInfo.apellidoPaterno,
      apellidoMaterno: this.userInfo.apellidoMaterno,
      curp: this.userInfo.curp,
      rfc: this.userInfo.rfc,
      genero: this.userInfo.genero ? this.userInfo.genero.toUpperCase() : null,
    };
  }

  get isJobInProcess(): boolean {
    if (this.lastPadronEncabezado?.estatusJob) {
      return (
        this.lastPadronEncabezado.estatusJob === EstatusJob.PROCESSING ||
        this.lastPadronEncabezado.estatusJob === EstatusJob.SCHEDULED ||
        this.lastPadronEncabezado.estatusJob === EstatusJob.ENQUEUED
      );
    }
    return false;
  }

  get userInfo() {
    return this.$store.getters.userInfo;
  }

  public updatePadronEncabezadoStatus() {
    if (this.isJobInProcess) {
      this.retrivePadronEncabezado(this.isRetrivingLastPadron, false);
    }
  }

  public defaultPadronEncabezado(reglas): any {
    return {
      id: null,
      reglasInvestigador: null,
      reglasAyudantia: null,
      fechaRegistro: null,
      countInvestigadores: null,
      countAyudantes: null,
      jobId: null,
      estatusJob: null,
      reglas: reglas,
      ejecucionAceptada: false,
      solicitante: this.getSolicitante(),
    };
  }
}
