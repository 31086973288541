import { Component, Prop, Inject, Emit } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { Configuracion } from '@/shared/model/configuracion/configuracion.model';
import GrantService from '@/shared/security/grant.service';

import PropertyCommons from '@/components/commons/property.model';

/**
 * ApeironComponent Se utiliza para definir las propiedades y métodos
 * necesarios para implementar correctamente un componente Vuejs que
 * se integre al framework Ápeiron.
 *
 * Todos los componentes que se van
 * a utilizar en el configurador de pantallas, deberá de heredar
 * de esta clase base
 *
 */
@Component
export default class ApeironComponent extends PropertyCommons {
  @Inject('grantService') protected grantService: () => GrantService;
  @Inject('alertService') protected alertService: () => AlertService;

  created() {
    (this.$root as any).$emit('apeiron::config', this.configuracion);
  }

  /**
   * Son los permisos a los que tiene acceso el usuario actual
   */
  @Prop({ required: true })
  public permisos: any;
  /**
   * es el objeto que se conforma al formulario y que se guarda en la base de datos
   */
  @Prop({ required: true })
  public formModel: any;

  /**
   * Contiene la lista de Roles y Acciones que puede hacer un usuario
   */
  @Prop({ required: false })
  public accesos: any;

  /**
   * Es el estado en el que se encuentra la solicitud
   */
  @Prop({ required: false })
  public estadoSolicitud: EstadoSolicitud;

  /**
   * Es el estado en el que se encuentra la revisión
   */
  @Prop({ required: false })
  public estadoRevision: EstadoRevision;

  /**
   * Objeto que se le pasa al componente para su configuración
   */
  @Prop({ required: false })
  public configuracion: Configuracion;

  /**
   * Evento que se emite cuando el componente guarda su estado
   */
  @Emit('submit')
  public save(submitedData) {
    return submitedData;
  }
}
