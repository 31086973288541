import AlertService from '@/shared/alert/alert.service';
import EntityFactory, { ICuota } from '@/shared/insaculacion/entity-commons/entity.factory';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Cuota, Solicitud } from '@/shared/model/solicitud.model';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { helpers, minValue, required, requiredIf } from 'vuelidate/lib/validators';

const validations = function () {
  const { cuota } = this as CuotaComponent;
  return {
    cuota: {
      cobra: { required },
      esVoluntario: { required: requiredIf(() => cuota.cobra === true) },
      frecuenciaColegiatura: {
        required: value => {
          if (cuota.cobra) {
            if (cuota.montoColegiatura === 0 || cuota.montoColegiatura > 0) {
              return !!value;
            }
            if (!cuota.frecuenciaInscripcion && !cuota.frecuenciaOtraCuota) {
              return !!value;
            }
          }
          return true;
        },
      },

      frecuenciaInscripcion: {
        required: value => {
          if (cuota.cobra) {
            if (cuota.montoInscripcion === 0 || cuota.montoInscripcion > 0) {
              return !!value;
            }
            if (!cuota.frecuenciaColegiatura && !cuota.frecuenciaOtraCuota) {
              return !!value;
            }
          }
          return true;
        },
      },
      frecuenciaOtraCuota: {
        required: value => {
          if (cuota.cobra) {
            if (cuota.montoOtra === 0 || cuota.montoOtra > 0) {
              return !!value;
            }
            if (!cuota.frecuenciaColegiatura && !cuota.frecuenciaInscripcion) {
              return !!value;
            }
          }
          return true;
        },
      },
      montoColegiatura: {
        required: requiredIf(() => cuota.frecuenciaColegiatura !== undefined && cuota.frecuenciaColegiatura !== null),
      },
      montoInscripcion: {
        required: requiredIf(() => cuota.frecuenciaInscripcion !== undefined && cuota.frecuenciaInscripcion !== null),
      },
      montoOtra: {
        required: requiredIf(() => cuota.frecuenciaOtraCuota !== undefined && cuota.frecuenciaOtraCuota !== null),
      },
      tipoCuota: {
        required: requiredIf(() => cuota.frecuenciaOtraCuota !== undefined && cuota.frecuenciaOtraCuota !== null),
      },
    },
  };
};

@Component({ validations })
export default class CuotaComponent extends Vue {
  @Inject('alertService') public alertService: () => AlertService;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public cuota: Cuota = {};

  public frecuenciaColegiatura: ICuota = {};
  public frecuenciaInscripcion: ICuota = {};
  public frecuenciaOtraCuota: ICuota = {};
  public frecuenciasCuota: Array<ICuota> = EntityFactory.frecuenciasCuota;
  public formularioRequerido = false;

  mounted(): void {
    this.get();
  }
  /**
   * Carga inicial del componente
   *
   * @memberof CuotaComponent
   */
  public get(): void {
    if (this.solicitud?.cuota) {
      this.cuota = this.solicitud.cuota;
    } else {
      this.formularioRequerido = true;
    }
  }

  get desactivarBoton(): boolean {
    return this.$v.$invalid;
  }

  public options = [
    { text: 'Sí', value: true },
    { text: 'No', value: false },
  ];

  /**
   * Guarda la cuota con los valores que le ha dado el usuario.
   *
   * @memberof CuotaComponent
   */
  public guardarCuota(): void {
    const datosEnviar = { cuota: this.cuota };
    this.$emit('guardarCuota', datosEnviar);
  }

  /**
   * Determina si un campo debe mostrarse.
   *
   * @memberof CuotaComponent
   */
  get mostrarCampo(): boolean {
    return this.cuota.cobra === true;
  }

  /**
   * Determina si un campo tipo de cuota debe mostrarse.
   *
   * @memberof CuotaComponent
   */
  get mostrarTipoCuota() {
    if (this.cuota.frecuenciaOtraCuota) {
      return true;
    }
    return false;
  }

  /**
   * Determina si un campo debe habilitarse.
   *
   * @memberof CuotaComponent
   */
  get deshabilitarCampo(): boolean {
    return this.solicitud.estado === EstadoSolicitud.EN_CAPTURA || this.solicitud.estado === EstadoSolicitud.REVISADA;
  }
}
