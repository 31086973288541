var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: "modal" + _vm.id,
      attrs: {
        centered: "",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "header-bg-variant": "primary",
        id: "modal" + _vm.id,
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("accesos.notEnoughPermissions")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "row text-center" }, [
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("b-icon", {
                attrs: {
                  variant: "danger",
                  animation: "fade",
                  "font-scale": "5",
                  icon: "person-x-fill",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "w-100 text-center mt-3",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("strong", [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("accesos.notEnoughPermissions")
                        ),
                      },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }