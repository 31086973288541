import { Component } from 'vue-property-decorator';

import { Formulario } from './formulario.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { required } from 'vuelidate/lib/validators';

const validations: any = {
  form: {
    subSeccion: {
      nombre: { required },
    },
  },
};

@Component({
  validations,
})
export default class FormularioDemoComponent extends ApeironComponent {
  public form = { subSeccion: new Formulario() };
  created() {
    if (this.formModel?.subSeccion) {
      this.form.subSeccion = { ...this.form.subSeccion, ...JSON.parse(JSON.stringify(this.formModel.subSeccion)) };
    }
  }
}
