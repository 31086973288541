import { Component, Inject, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PropertyCommons from '../commons/property.model';
import resolveEstadoSolicitudVariant, { resolveEstadoSolicitudIcon } from '@/shared/estados/estados.solicitud.util';
import ReporteService from '@/components/reporte/reporte.service';

@Component
export default class ReporteComponent extends mixins(PropertyCommons) {
  @Inject('reporteService') public reporteService: () => ReporteService;

  @Prop({ default: null })
  public solucionId: string;

  @Prop({ default: null })
  public solucion: any;

  reporte: any = {};
  isFetching = false;

  @Watch('solucion.id')
  configureSolicitante(solucionId) {
    this.isFetching = true;
    if (solucionId) {
      this.reporteService()
        .find(solucionId)
        .then(result => {
          this.reporte = result;
          this.isFetching = false;
        })
        .catch(error => {
          this.isFetching = false;
        });
    }
  }

  get estadosReporte() {
    if (this.solucion?.proceso) {
      return this.solucion.proceso.estados.map(estado => estado.nombre);
    } else {
      return [];
    }
  }

  public resolveCountByEstado(estado) {
    if (this.reporte?.length > 0) {
      const found = this.reporte.find(reporte => reporte.id === estado);

      if (found?.id) {
        return found.count;
      }
    }
    return 0;
  }

  public variant(estado): string {
    return resolveEstadoSolicitudVariant(estado);
  }

  public icon(estado): string {
    return resolveEstadoSolicitudIcon(estado);
  }
}
