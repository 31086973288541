export enum CustomComponente {
  ENVIAR_SOLICITUD = 'ENVIAR_SOLICITUD',
  ACCIONES = 'ACCIONES',
  CONTESTAR_ENCUESTA = 'CONTESTAR_ENCUESTA',
  ENVIAR_ENCUESTA = 'ENVIAR_ENCUESTA',
  ASIGNAR_REVISOR = 'ASIGNAR_REVISOR',
  ACEPTAR_REVISAR = 'ACEPTAR_REVISAR',
  COMENTARIOS = 'COMENTARIOS',
  AGREGAR_COMENTARIOS = 'AGREGAR_COMENTARIOS',
  REGISTRAR_AYUDANTE = 'REGISTRAR_AYUDANTE',
  REGISTRAR_DOCUMENTOS = 'REGISTRAR_DOCUMENTOS',
  OTRAS_REVISIONES = 'OTRAS_REVISIONES',
}
