import FormularioDemoComponent from '@/components/formulario-demo/formulario-demo.vue';
import HistoricoAcreditacionesComponent from '@/components/historico-acreditaciones/historico-acreditaciones.vue';
import InstitucionActualComponent from '@/components/institucion-actual/institucion-actual.vue';
import InstitucionesComponent from '@/entities/institucion/institucion.vue';

export function initApeironComponentsVue(vue) {
  vue.component('formulario-demo', FormularioDemoComponent);
  vue.component('historico-acreditaciones', HistoricoAcreditacionesComponent);
  vue.component('institucion-actual', InstitucionActualComponent);
  vue.component('instituciones', InstitucionesComponent);
}
