var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    [
      !_vm.readOnly
        ? _c(
            "b-form-group",
            { staticClass: "col-12", attrs: { label: "Buscar cvu" } },
            [
              _c("buscador-persona", {
                staticClass: "mb-3",
                on: { selected: _vm.agregarPersona },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.persona
        ? [
            _c(
              "b-form-group",
              {
                staticClass: "col-6",
                attrs: { label: "Nombre", disabled: "" },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.nombre,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "nombre", $$v)
                    },
                    expression: "persona.nombre",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "col-6",
                attrs: { label: "Primer apellido", disabled: "" },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.apellidoPaterno,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "apellidoPaterno", $$v)
                    },
                    expression: "persona.apellidoPaterno",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "col-6",
                attrs: { label: "Segundo apellido", disabled: "" },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.apellidoMaterno,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "apellidoMaterno", $$v)
                    },
                    expression: "persona.apellidoMaterno",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { staticClass: "col-6", attrs: { label: "CURP", disabled: "" } },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.curp,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "curp", $$v)
                    },
                    expression: "persona.curp",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { staticClass: "col-6", attrs: { label: "RFC", disabled: "" } },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.rfc,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "rfc", $$v)
                    },
                    expression: "persona.rfc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "col-6",
                attrs: { label: "Género", disabled: "" },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.genero,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "genero", $$v)
                    },
                    expression: "persona.genero",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "col-6",
                attrs: { label: "Nacionalidad", disabled: "" },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.nacionalidad,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "nacionalidad", $$v)
                    },
                    expression: "persona.nacionalidad",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-100" }),
            _vm._v(" "),
            _vm.persona.domicilios
              ? _c(
                  "b-form-group",
                  { staticClass: "mt-3 col-6" },
                  [
                    _c("b-table", {
                      attrs: {
                        small: "",
                        borderless: "",
                        items: _vm.persona.domicilios,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "head(direccion)",
                            fn: function () {
                              return [_vm._v(" Dirección ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2962179848
                      ),
                    }),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mt-3 col-6",
                        attrs: {
                          id: "form-umas-id",
                          size: "sm",
                          label: "UMA's",
                          "label-for": "uma-id",
                        },
                      },
                      [
                        _c("b-form-radio-group", {
                          attrs: {
                            disabled: _vm.isFormDisabled(),
                            id: "uma-id",
                            options: _vm.umas,
                            name: "umas",
                          },
                          model: {
                            value: _vm.persona.uma,
                            callback: function ($$v) {
                              _vm.$set(_vm.persona, "uma", $$v)
                            },
                            expression: "persona.uma",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            false
              ? _c(
                  "b-form-group",
                  {
                    staticClass: "col-6",
                    attrs: {
                      id: "form-uma-reclasificada-id",
                      size: "sm",
                      label: "Nuevo valor de UMA's",
                      "label-for": "uma-reclasificada-id",
                    },
                  },
                  [
                    _c("b-form-radio-group", {
                      attrs: {
                        id: "uma-reclasificada-id",
                        options: _vm.umas,
                        name: "umaReclasificada",
                      },
                      model: {
                        value: _vm.persona.umaReclasificada,
                        callback: function ($$v) {
                          _vm.$set(_vm.persona, "umaReclasificada", $$v)
                        },
                        expression: "persona.umaReclasificada",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "col-12",
                attrs: {
                  disabled: _vm.readOnly,
                  label:
                    "Nombre del proyecto o los proyectos en los que participa el ayudante",
                },
              },
              [
                _c("b-form-input", {
                  model: {
                    value: _vm.persona.proyectos,
                    callback: function ($$v) {
                      _vm.$set(_vm.persona, "proyectos", $$v)
                    },
                    expression: "persona.proyectos",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { staticClass: "col-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      disabled: _vm.saving || _vm.isFormDisabled(),
                    },
                    on: { click: _vm.save },
                  },
                  [
                    _vm.saving
                      ? _c(
                          "b-spinner",
                          { attrs: { small: "", type: "grow" } },
                          [_vm._v("Guardando...")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.saving
                      ? _c("span", [_vm._v("Guardando...")])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.saving
                      ? _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.save")),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }