import { Component, Vue, Inject } from 'vue-property-decorator';

import SolucionService from '@/entities/solucion/solucion.service';
import AlertService from '@/shared/alert/alert.service';
import { mapMutations } from 'vuex';

import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';
import { EstadoSolucion } from '@/shared/model/enumerations/estado-solucion.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Filtro } from '@/shared/model/filtro.model';
import { IDefinicionEvaluacion } from '@/shared/model/definicion-evaluacion.model';
import { TipoRevision } from '@/shared/model/enumerations/tipo-revision.model';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
import type { CatTipoComision } from '@/components/filtros/filtros.component';
import { TipoConvocatoria } from '@conacyt/insaculacion-client';

/**
 * An utility service for SolucionMixin.
 */
@Component({
  computed: {
    solucion: {
      get() {
        return this.$store.getters.solucion;
      },
      set(value) {
        this.setSolucion(value);
      },
    },
    tipoComision: {
      get() {
        return this.$store.getters.tipoComision;
      },
      set(value: TipoComision) {
        this.setTipoComision(value);
      },
    },
    definicionEvaluacion: {
      get() {
        return this.$store.getters.definicionEvaluacion;
      },
      set(value: IDefinicionEvaluacion) {
        this.setDefinicionEvaluacion(value);
      },
    },
  },
  methods: {
    ...mapMutations(['setSolucion', 'setTipoComision', 'setDefinicionEvaluacion']),
  },
})
export default class SolucionMixin extends Vue {
  @Inject('solucionService') public solucionService: () => SolucionService;
  @Inject('alertService') public alertService: () => AlertService;

  public solucions: Array<Solucion> = [];

  /**
   * Solución seleccionada por el usuario.
   * Propiedad computada
   */
  public solucion: Solucion;

  public isFetching = false;

  public propOrder = 'estado';
  public reverse = false;
  public static readonly ITEMS_PER_PAGE = 5;
  public static readonly PAGE = 1;
  public previousPage = 1;
  public links = {};

  /**
   * La definición de evaluación consultada
   * Propiedad computada
   */
  public definicionEvaluacion: IDefinicionEvaluacion;
  public isValidUser = false;

  /**
   * Tipo de comisión seleccionada por el usuario.
   * Propiedad computada
   */
  public tipoComision: TipoComision;

  public enumReconsideracion: TipoComision = TipoComision.RECONSIDERACION;

  public tipoComisiones: CatTipoComision[] = this.listaTipoComisiones();

  public tipoEmerito: TipoSolucion = TipoSolucion.EMERITOS;
  public tipoIngreso: TipoSolucion = TipoSolucion.INGRESO_PERMANENCIA_PROMOCION;
  public tipoBecasExtranjeras: TipoSolucion = TipoSolucion.BECAS_EXTRANJERAS;

  public get muestraTipoComision(): boolean {
    return this.solucion.tipo === TipoSolucion.INGRESO_PERMANENCIA_PROMOCION;
  }

  /**
   * Devuelve las soluciones
   *
   * @memberof BandejaSolicitudesComponet
   */
  public retrieveAllSolucions(): void {
    const filtro = new Filtro();
    filtro.estadoSolucion = EstadoSolucion.PUBLICADA;
    filtro.tiposSolucion = [TipoSolucion.INGRESO_PERMANENCIA_PROMOCION, TipoSolucion.EMERITOS, TipoSolucion.BECAS_EXTRANJERAS];
    this.isFetching = true;
    const paginationQuery = {
      page: SolucionMixin.PAGE - 1,
      size: SolucionMixin.ITEMS_PER_PAGE,
      sort: this.sort(),
      filter: filtro,
    };
    this.solucionService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (const solucion of res.data) {
              this.solucions.push(Solucion.fromObject(solucion));
            }
          }

          this.isFetching = false;

          if (<any>this.$refs.infiniteLoading) {
            (<any>this.$refs.infiniteLoading).stateChanger.loaded();
            if (SolucionMixin.PAGE > this.links['last']) {
              (<any>this.$refs.infiniteLoading).stateChanger.complete();
            }
          }
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  /**
   *Obtiene la definición de una revisión
   *
   * @param {string} id
   * @memberof BandejaSolicitudesComponet
   */
  public getDefinicionRevision(id: string): void {
    this.solucionService()
      .findEvaluacionDefinicion(id)
      .then(definicionEvaluacion => {
        this.definicionEvaluacion = definicionEvaluacion;
      })
      .catch(err => {});
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
    }
  }
  /**
   * Filtra soluciones por usuario, tipo y estado de la solución.
   */
  public retrieveAllSolucionsByUser(username): void {
    const filtro = new Filtro();
    filtro.usuario = username;
    filtro.estadoSolucion = EstadoSolucion.PUBLICADA;
    filtro.tiposSolucion = [TipoSolucion.INGRESO_PERMANENCIA_PROMOCION, TipoSolucion.EMERITOS, TipoSolucion.BECAS_EXTRANJERAS];
    const paginationQuery = {
      page: SolucionMixin.PAGE - 1,
      size: SolucionMixin.ITEMS_PER_PAGE,
      sort: this.sort(),
      filter: filtro,
    };
    this.solucionService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (const solucion of res.data) {
              this.solucions.push(Solucion.fromObject(solucion));
            }
          }
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  /**
   * Obtiene la definición asociada a un tipo de revisión.
   * @param {TipoRevision} tipoDefinicion
   * @memberof EvaluacionConsejoComponent
   */
  public getDefinicion(tipoRevision: TipoRevision): string {
    for (const definicion of this.definicionEvaluacion.revisiones) {
      if (definicion.tipoRevision === tipoRevision) {
        return definicion.id;
      }
    }
  }

  /**
   * Busca si el usuario tiene un rol en específico para usar el módulo.
   * @param solucionSelect
   * @param username
   * @param rol
   * @returns
   */
  public validaSolucionUsuarioRol(solucionSelect, username, rol): boolean {
    for (const autoridad of solucionSelect.autoridades) {
      if (autoridad.usuarioId === username) {
        for (const rolesAut of autoridad.roles) {
          if (rolesAut === rol) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Se obtiene la lista de tipo comisión.
   * @returns
   */
  public listaTipoComisiones(): CatTipoComision[] {
    const tipoComisiones: CatTipoComision[] = [];
    const tipoComisionKeys = Object.keys(TipoComision);

    tipoComisionKeys.forEach(key => {
      tipoComisiones.push({ value: key, text: this.$t('apeironGwApp.TipoComision.' + key).toString(), disabled: false });
    });
    return tipoComisiones;
  }

  public get tipoConvocatoriaInsaculacion(): TipoConvocatoria {
    switch (this.solucion.tipo) {
      case TipoSolucion.INGRESO_PERMANENCIA_PROMOCION:
        return TipoConvocatoria.IngresoPermanencia;
      case TipoSolucion.EMERITOS:
        return TipoConvocatoria.IngresoPermanencia;
      case TipoSolucion.BECAS_EXTRANJERAS:
        return TipoConvocatoria.BecasExtranjeras;
      default:
        return null;
    }
  }

  public get claveSolucion(): string {
    if (
      (this.solucion.tipo === TipoSolucion.INGRESO_PERMANENCIA_PROMOCION && this.tipoComision === TipoComision.DICTAMINADORA) ||
      this.solucion.tipo === TipoSolucion.EMERITOS
    ) {
      return this.solucion.id;
    } else if (this.solucion.tipo === TipoSolucion.BECAS_EXTRANJERAS) {
      return this.solucion.id;
    }
    return this.solucion.id + '-R';
  }

  /**
   * Obtiene la definición asociada a un tipo de revisión y tipo comisión.
   * @param {TipoRevision} tipoRevision
   * @param {TipoComision} tipoComision
   * @memberof EvaluacionConsejoComponent
   */
  public getDefinicionByTipoComision(tipoRevision: TipoRevision, tipoComision: TipoComision): string {
    for (const definicion of this.definicionEvaluacion.revisiones) {
      if (definicion.tipoRevision === tipoRevision && definicion.tipoComision === tipoComision) {
        return definicion.id;
      }
    }
  }

  /**
   * Establece el tipo de comisión a partir de la solución seleccionada.
   */
  public setTipoComisionPorSolucion(): void {
    if (this.solucion.tipo === TipoSolucion.EMERITOS) {
      this.tipoComision = TipoComision.DICTAMINADORA;
    } else if (this.solucion.tipo === TipoSolucion.INGRESO_PERMANENCIA_PROMOCION) {
      this.tipoComision = null;
    }
  }

  /**
   * Busca soluciones según el filtro proporcionado.
   */
  public buscarSoluciones(filtro: Filtro) {
    this.solucions = [];
    const paginationQuery = {
      page: SolucionMixin.PAGE - 1,
      size: SolucionMixin.ITEMS_PER_PAGE * 10,
      sort: this.sort(),
      filter: filtro,
    };
    this.solucionService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (const solucion of res.data) {
              this.solucions.push(Solucion.fromObject(solucion));
            }
          }
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }
}
