var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(5, function (index) {
      return _c(
        "b-card",
        { key: index, staticClass: "mb-4", attrs: { "bg-variant": "light" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "center-felx card-contacto",
                  attrs: { col: "", lg: "1" },
                },
                [_c("span", { class: "icon-" + _vm.icono })]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "felx-2" },
                [
                  _c(
                    "b-col",
                    { staticClass: "left-felx", attrs: { col: "", lg: "9" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-skeleton"),
                          _vm._v(" "),
                          _c("b-skeleton"),
                          _vm._v(" "),
                          _c("b-skeleton"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "right-felx", attrs: { col: "", lg: "2" } },
                    [
                      _c(
                        "span",
                        { staticClass: "rt-1" },
                        [_c("b-skeleton", { attrs: { type: "avatar" } })],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "border-top pt-3" },
            [
              _c("b-col", { attrs: { col: "", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "btn-group float-right" },
                  [_c("b-skeleton", { attrs: { type: "button" } })],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }