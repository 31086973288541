import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoSolicitudAcreditacion } from '@/shared/model/enumerations/estado-solicitud-acreditacion.model';
import resolveEstadoSolicitudVariant from '@/shared/estados/estados.solicitud.util';

@Component
export default class EstadoTagsComponent extends Vue {
  @Prop({ required: true })
  public value!: [string];

  @Prop({ default: false })
  public isTipoAcreditacion: boolean;

  @Prop({ required: false })
  estadosDisponibles: [string];

  get options() {
    let estadosList = Object.keys(EstadoSolicitud);
    if (this.estadosDisponibles?.length >= 0) {
      estadosList = estadosList.filter(estado => this.estadosDisponibles.includes(estado));
    }
    return estadosList;
  }

  set options(newValue) {
    /* se agrega acceso de escritura para la propiedad options */
  }

  get isRemovable() {
    if (this.tags?.length > 1) {
      return true;
    }
    return false;
  }

  get tags(): [string] {
    return this.value;
  }

  set tags(newValue: [string]) {
    this.$emit('input', newValue);
  }

  get availableOptions(): any {
    if (this.isTipoAcreditacion) {
      this.options = Object.keys(EstadoSolicitudAcreditacion);
    }
    if (this.value) {
      return this.options.filter(opt => this.value.indexOf(opt) === -1);
    } else {
      return [];
    }
  }

  public variant(estado): string {
    return resolveEstadoSolicitudVariant(estado);
  }
}
