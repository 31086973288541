export interface IMenuModalidadPronaceSnp {
  value?: ModalidadPronaceSnp;
  text?: string;
}

export class MenuModalidadPronaceSnp implements IMenuModalidadPronaceSnp {
  constructor(public value?: ModalidadPronaceSnp, public text?: string) {}
}

export interface IMenuAreaPronaceSnp {
  value?: AreaPronaceSnp;
  text?: string;
}

export class MenuAreaPronaceSnp implements IMenuAreaPronaceSnp {
  constructor(public value?: AreaPronaceSnp, public text?: string) {}
}

export interface IMenuTemaPronaceSnp {
  value?: TemaPronaceSnp;
  text?: string;
}

export class MenuTemaPronaceSnp implements IMenuTemaPronaceSnp {
  constructor(public value?: TemaPronaceSnp, public text?: string) {}
}

export interface IMenuSubtemaPronaceSnp {
  value?: SubtemaPronaceSnp;
  text?: string;
}

export class MenuSubtemaPronaceSnp implements IMenuSubtemaPronaceSnp {
  constructor(public value?: SubtemaPronaceSnp, public text?: string) {}
}

export interface IModalidadPronaceSnp {
  claveModalidadPronace?: number;
  pronace?: string;
}

export class ModalidadPronaceSnp implements IModalidadPronaceSnp {
  constructor(public claveModalidadPronace?: number, public pronace?: string) {}
}

export interface IAreaPronaceSnp {
  claveArea?: number;
  area?: string;
}

export class AreaPronaceSnp implements IAreaPronaceSnp {
  constructor(public claveArea?: number, public area?: string) {}
}

export interface ITemaPronaceSnp {
  claveArea?: number;
  claveTema?: number;
  tema?: string;
}

export class TemaPronaceSnp implements ITemaPronaceSnp {
  constructor(public claveArea?: number, public claveTema?: number, public tema?: string) {}
}

export interface ISubtemaPronaceSnp {
  claveArea?: number;
  claveSubtema?: number;
  subtema?: string;
}

export class SubtemaPronaceSnp implements ISubtemaPronaceSnp {
  constructor(public claveArea?: number, public claveSubtema?: number, public subtema?: string) {}
}

export interface IPronaceSnp {
  modalidad?: ModalidadPronaceSnp;
  area?: AreaPronaceSnp;
  areaEspecifique?: string;
  tema?: TemaPronaceSnp;
  temaEspecifique?: string;
  subtema?: SubtemaPronaceSnp;
  subtemaEspecifique?: string;
}

export class PronaceSnp implements IPronaceSnp {
  constructor(
    public modalidad?: ModalidadPronaceSnp,
    public area?: AreaPronaceSnp,
    public areaEspecifique?: string,
    public tema?: TemaPronaceSnp,
    public temaEspecifique?: string,
    public subtema?: SubtemaPronaceSnp,
    public subtemaEspecifique?: string
  ) {
    if (!areaEspecifique) {
      this.areaEspecifique = null;
    }
    if (!temaEspecifique) {
      this.temaEspecifique = null;
    }
    if (!subtemaEspecifique) {
      this.subtemaEspecifique = null;
    }
  }

  static fromObject(object: IPronaceSnp): PronaceSnp {
    return Object.assign(new PronaceSnp(), object);
  }
}
