var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !_vm.authenticated
      ? _c("div", { staticClass: "container" }, [
          _c("h1", [
            _vm._v(_vm._s(_vm.$t("global.apeiron")) + " "),
            _c("small", {
              domProps: { textContent: _vm._s(_vm.$t("global.pleaselog")) },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "jumbotron count" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-12 count__box" }, [
                  _c("h2", {
                    domProps: { textContent: _vm._s(_vm.$t("global.welcome")) },
                  }),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          {
            class: {
              container: !_vm.sidebarOpen,
              "container-fluid": _vm.sidebarOpen,
            },
          },
          [
            _vm.estadoCargaPerfil === _vm.EstadoProceso.CARGANDO
              ? _c("perfil-navbar-skeleton")
              : _c(
                  "b-row",
                  { staticClass: "mb-3", attrs: { id: "header-2a" } },
                  [
                    _c(
                      "b-col",
                      { staticClass: "avatar", attrs: { cols: "7" } },
                      [
                        _vm.userInfo?.fotografiaUri
                          ? _c("b-avatar", {
                              staticClass: "avatar_img",
                              attrs: {
                                src: _vm.userInfo.fotografiaUri + "/preview",
                                size: "4rem",
                              },
                            })
                          : _c("b-avatar", {
                              staticClass: "avatar_img",
                              attrs: { size: "4rem" },
                            }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.account.firstName) +
                              " " +
                              _vm._s(_vm.account.lastName)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-col", { attrs: { cols: "5" } }, [
                      _c("div", { staticClass: "user_cvu align-middle" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("apeironGwApp.persona.login")) +
                                ": "
                            ),
                            _vm.userInfo?.login
                              ? [_vm._v(_vm._s(_vm.userInfo.login))]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("apeironGwApp.persona.noCvu")) +
                                ": "
                            ),
                            _vm.userInfo?.cvu
                              ? [_vm._v(_vm._s(_vm.userInfo.cvu))]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }