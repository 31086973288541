var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.mock
        ? [
            _c(
              "b-form-group",
              {
                attrs: {
                  label: "INE",
                  "label-cols-sm": "2",
                  "label-size": "sm",
                },
              },
              [
                _c("b-form-checkbox", {
                  staticClass: "col-2 text-right",
                  attrs: { size: "lg" },
                }),
                _vm._v(" "),
                _c("b-form-file", { attrs: { size: "sm" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: "RFC",
                  "label-cols-sm": "2",
                  "label-size": "sm",
                },
              },
              [
                _c("b-form-checkbox", {
                  staticClass: "col-2 text-right",
                  attrs: { size: "lg" },
                }),
                _vm._v(" "),
                _c("b-form-file", { attrs: { size: "sm" } }),
              ],
              1
            ),
          ]
        : [
            _vm.configuracion.documentos
              ? _c("div", { staticClass: "col-10 ml-5" }, [
                  _vm.isLoading
                    ? _c(
                        "div",
                        [
                          _c("b-skeleton-table", {
                            staticClass: "ml-3",
                            attrs: {
                              rows: 5,
                              columns: 3,
                              "hide-header": true,
                              "table-props": {
                                bordered: false,
                                striped: false,
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("b-skeleton", {
                            staticClass: "ml-3",
                            attrs: { type: "button" },
                          }),
                        ],
                        1
                      )
                    : _c("div", [
                        _vm.properties
                          ? _c(
                              "div",
                              { staticClass: "row mt-3" },
                              [
                                _c(
                                  "b-table-simple",
                                  { attrs: { responsive: "", borderless: "" } },
                                  _vm._l(
                                    _vm.properties,
                                    function (property, index) {
                                      return _c(
                                        "b-tr",
                                        { key: index },
                                        [
                                          _c("b-td", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(property.config.title) +
                                                  ":"
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "b-td",
                                            { staticClass: "text-left" },
                                            [
                                              _vm.documentoModel[property.key]
                                                .uri
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "b-link",
                                                        {
                                                          attrs: {
                                                            href: _vm
                                                              .documentoModel[
                                                              property.key
                                                            ].uri,
                                                            target: "_blank",
                                                            rel: "noreferrer",
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "file-earmark-arrow-down-fill",
                                                              variant:
                                                                "success",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm
                                                                  .documentoModel[
                                                                  property.key
                                                                ].nombre
                                                              )
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-1",
                                                          },
                                                          [
                                                            !_vm.documentoModel[
                                                              property.key
                                                            ].uri &&
                                                            _vm.isUpdating(
                                                              index
                                                            )
                                                              ? _c("b-icon", {
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    icon: "arrow-clockwise",
                                                                    animation:
                                                                      "spin-pulse",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "col",
                                                          },
                                                          [
                                                            _c("b-form-file", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isUpdating(
                                                                    index
                                                                  ) ||
                                                                  _vm.readOnly,
                                                                placeholder:
                                                                  "Seleccionar archivo",
                                                                "drop-placeholder":
                                                                  "Arrastre un archivo aquí...",
                                                                accept:
                                                                  _vm.toFileExtension(
                                                                    property
                                                                      .config
                                                                      .mediaTypes
                                                                  ),
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onReadFile(
                                                                      $event,
                                                                      _vm
                                                                        .documentoModel[
                                                                        property
                                                                          .key
                                                                      ],
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.currentFile,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.currentFile =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "currentFile",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "\n                        Extensiones válidas: "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.toFileExtension(
                                                                      property
                                                                        .config
                                                                        .mediaTypes
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.readOnly
                                            ? _c(
                                                "b-td",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm.documentoModel[
                                                    property.key
                                                  ].uri &&
                                                  !_vm.documentoModel[
                                                    property.key
                                                  ].check
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant:
                                                              "outline-danger",
                                                            type: "button",
                                                            size: "sm",
                                                            disabled:
                                                              _vm.isUpdating(
                                                                index
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDeleteDocumento(
                                                                _vm
                                                                  .documentoModel[
                                                                  property.key
                                                                ],
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.isUpdating(index)
                                                            ? _c("b-icon", {
                                                                attrs: {
                                                                  icon: "arrow-clockwise",
                                                                  animation:
                                                                    "spin-pulse",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v("Reemplazar"),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.showValidation(
                                            _vm.documentoModel[property.key]
                                              .check
                                          )
                                            ? _c(
                                                "b-td",
                                                { staticClass: "text-left" },
                                                [
                                                  !_vm.disabledValidation()
                                                    ? _c("b-form-radio-group", {
                                                        attrs: {
                                                          "button-variant":
                                                            "outline-danger",
                                                          options: _vm.options,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.documentoModel[
                                                              property.key
                                                            ].check,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .documentoModel[
                                                                property.key
                                                              ],
                                                              "check",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "documentoModel[property.key].check",
                                                        },
                                                      })
                                                    : _c(
                                                        "div",
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              "font-scale":
                                                                "1.5",
                                                              variant: _vm
                                                                .documentoModel[
                                                                property.key
                                                              ].check
                                                                ? "success"
                                                                : "danger",
                                                              icon: _vm
                                                                .documentoModel[
                                                                property.key
                                                              ].check
                                                                ? "check-circle"
                                                                : "x-circle",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                  " +
                                                              _vm._s(
                                                                _vm
                                                                  .documentoModel[
                                                                  property.key
                                                                ].check
                                                                  ? "Aceptado"
                                                                  : "Rechazado"
                                                              ) +
                                                              "\n                "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _vm.configuracion.hasMoreDocuments &&
                                _vm.canAddMoreDocuments
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-zoom",
                                        attrs: {
                                          variant: "outline-success",
                                          block: "",
                                        },
                                        on: {
                                          click:
                                            _vm.handleOpenAddNewDocumentModal,
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "plus" },
                                        }),
                                        _vm._v(" "),
                                        _c("b", [
                                          _c("i", [
                                            _vm._v("Agregar otro documento"),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-modal",
                                  {
                                    ref: "addNewDocument",
                                    attrs: {
                                      "no-close-on-esc": "",
                                      "no-close-on-backdrop": "",
                                      "hide-header-close": "",
                                      size: "xl",
                                      id: "addNewDocument",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "modal-title" },
                                        slot: "modal-title",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Agregar nuevo documento"),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "modal-body" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols": "6",
                                              "label-cols-lg": "6",
                                              label: "Nombre del archivo",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "input-new-file-name",
                                              },
                                              model: {
                                                value: _vm.newFileName,
                                                callback: function ($$v) {
                                                  _vm.newFileName = $$v
                                                },
                                                expression: "newFileName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "modal-footer" },
                                        slot: "modal-footer",
                                      },
                                      [
                                        _c("button", {
                                          staticClass: "btn btn-secondary",
                                          attrs: { type: "button" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("entity.action.cancel")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCancel()
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("button", {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "button",
                                            id: "jhi-confirm-delete-solicitud",
                                            "data-cy":
                                              "entityConfirmAddAutoridadButton",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("entity.action.save")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAddDocument()
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  id: "save-entity",
                                  disabled: _vm.isSaving,
                                  variant: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.save(true)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.save")
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }