export interface IVariable {
  section?: string | null;
  name?: string | null;
  description?: string | null;
  path?: string | null;
}

export class Variable implements IVariable {
  constructor(
    public section?: string | null,
    public name?: string | null,
    public descripcion?: string | null,
    public path?: string | null
  ) {}
}

export class VariableFactory {
  static getAcciones() {
    const variable = new Variable();
    return variable;
  }
}
