var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _c("b-jumbotron", { attrs: { lead: _vm.$t("global.contact.title") } }, [
        _c("span", {
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.messages.questions")),
          },
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "fw-bold",
          attrs: { href: "tel:+5553227708" },
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.tels.tel-1")),
          },
        }),
        _vm._v(" "),
        _c("span", {
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.messages.and")),
          },
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "fw-bold",
          attrs: { href: "tel:+8008008649" },
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.tels.tel-2")),
          },
        }),
        _vm._v(" "),
        _c("span", {
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.messages.send")),
          },
        }),
        _vm._v(" "),
        _c("a", {
          attrs: { href: "mailto:cst@conacyt.mx" },
          domProps: {
            textContent: _vm._s(_vm.$t("global.contact.emails.email-1")),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }