import { faker } from '@faker-js/faker';
import { IForm } from '@/shared/model/form.model';
import { TipoSolucion } from '@/shared/model/enumerations/tipo-solucion.model';

import curp from 'curp';

faker.locale = 'es';

import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';

export class MockSolicitud {
  static defaultSolicitud(solucion: any, forms: IForm[]) {
    return this.getCommondData({ solicitante: this.defaultPersona({}) }, solucion, forms);
  }

  static getCommondData(solicitud: any, solucion: any, forms: IForm[]) {
    solicitud.id = faker.datatype.uuid();
    solicitud.nombre = solicitud.solicitante.nombre;
    solicitud.usuario = solicitud.solicitante.login;
    solicitud.solucionId = solucion.id;
    solicitud.estado = EstadoSolicitud.EN_CAPTURA;
    solicitud = { ...solicitud, ...this.resolveComponentVariables(solucion.componentes, forms) };
    if (solucion.tipo === TipoSolucion.AYUDANTES) {
      solicitud.ayudante = this.defaultPersona({});
      solicitud.ayudante.uma = 2;
      solicitud.fecha_inicio = '2023-02-01T00:00:00.000+00:00';
      solicitud.fecha_fin = '2024-01-31T00:00:00.000+00:00';
    }
    return solicitud;
  }

  static defaultPersona(persona: any) {
    persona.cvu = faker.datatype.number();
    persona.login = faker.internet.userName();
    persona.titulo = 'Dra.';
    persona.nombre = faker.name.firstName();
    persona.apellidoPaterno = faker.name.lastName();
    persona.apellidoMaterno = faker.name.lastName();
    persona.curp = this.generateCurp(persona);
    persona.rfc = this.generateCurp(persona);
    persona.genero = faker.name.gender();
    persona.correo = faker.internet.email();
    persona.nivelAcademico = 'Doctorado';
    persona.gradoAcademico = 'DOCTORADO EN BOTANICA';
    persona.nacionalidad = 'Mexicana';
    persona.nacionalidadCodigo = 'MX';
    persona.paisResidencia = 'México';
    persona.paisResidenciaCodigo = 'MX';
    persona.nivelsni = 'Investigador Nacional  Nivel III';
    persona.rcea = 'RCEA-02-02433-2002';
    return persona;
  }

  static generateCurp(from) {
    const persona = curp.getPersona();
    persona.nombre = from.nombre;
    persona.apellidoPaterno = from.apellidoPaterno;
    persona.apellidoMaterno = from.apellidoMaterno;
    persona.genero = curp.GENERO.FEMENINO;
    persona.fechaNacimiento = '13-11-1953';
    persona.estado = curp.ESTADO.TABASCO;
    return curp.generar(persona);
  }

  static resolveComponentVariables(componentes: any[], forms: IForm[]) {
    const root = {};
    forms.filter(form => this.isFormInComponents(form, componentes)).forEach(form => this.visitForm(root, form));
    return root;
  }

  static isFormInComponents(form: IForm, componentes: any[]) {
    return form.tipo === 'FORM' && componentes.findIndex(componente => componente.formId === form.id) >= 0;
  }

  static visitForm(node, form) {
    form.components.forEach(componente => {
      if (componente.type == 'textfield') {
        node[componente.key] = faker.lorem.word(6);
      } else if (componente.type == 'columns') {
        node[componente.key] = {};
        componente.columns.forEach(columna => {
          this.visitForm(node[componente.key], columna);
        });
      }
    });
    return node;
  }

  static commonVariables() {
    return [
      { nombre: 'Nombre del solicitante', path: 'solicitud.solicitante.nombre' },
      { nombre: 'Apellido paterno del solicitante', path: 'solicitud.solicitante.apellidoPaterno' },
      { nombre: 'Apellido materno del solicitante', path: 'solicitud.solicitante.apellidoMaterno' },
      { nombre: 'Correo del solicitante', path: 'solicitud.solicitante.correo' },
      { nombre: 'CVU del solicitante', path: 'solicitud.solicitante.cvu' },
      { nombre: 'RFC del solicitante', path: 'solicitud.solicitante.rfc' },
      { nombre: 'CURP del solicitante', path: 'solicitud.solicitante.curp' },
      { nombre: 'Título del solicitante', path: 'solicitud.solicitante.titulo' },
    ];
  }

  static correos() {
    return [{ nombre: 'Solicitante', path: 'solicitud.solicitante.correo' }];
  }
}
