import { Component, Prop } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';

import TemasRegistro from '@/entities/snp/temas/temas-registro.vue';
import TemasListado from '@/entities/snp/temas/temas-listado.vue';
import { RegistroTemaSnp } from '@/entities/snp/temas/temas.model';

@Component({
  components: {
    temasRegistro: TemasRegistro,
    temasListado: TemasListado,
  },
})
export default class TemasComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public esVisibleRegistro = false;
  public esVisibleListado = true;

  public temaEdicion: RegistroTemaSnp = new RegistroTemaSnp();
  public temaAgregar: RegistroTemaSnp = new RegistroTemaSnp();

  public editarTema(tema: RegistroTemaSnp): void {
    this.temaEdicion = { ...tema };
    this.esVisibleRegistro = true;
    this.esVisibleListado = false;
  }

  public agregarTemaATabla(tema: RegistroTemaSnp): void {
    this.esVisibleRegistro = false;
    this.esVisibleListado = true;
    this.temaAgregar = { ...tema };
  }
}
