var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _vm.solicitud.estado === "EN_CAPTURA"
        ? _c(
            "b-col",
            { staticClass: "no-padding", attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3 col-12",
                  attrs: { label: _vm.$t("entity.action.searchCvu") },
                },
                [
                  _c("buscador-persona", {
                    ref: "buscador",
                    staticClass: "mb-3",
                    on: { selected: _vm.agregarPersona },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.persona.cvu"),
                disabled: "",
              },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.cvu,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "cvu", $$v)
                  },
                  expression: "ayudante.cvu",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("bandeja.nombre"), disabled: "" } },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.nombre,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "nombre", $$v)
                  },
                  expression: "ayudante.nombre",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("bandeja.primerApellido"), disabled: "" },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.apellidoPaterno,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "apellidoPaterno", $$v)
                  },
                  expression: "ayudante.apellidoPaterno",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("bandeja.segundoApellido"), disabled: "" },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.apellidoMaterno,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "apellidoMaterno", $$v)
                  },
                  expression: "ayudante.apellidoMaterno",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.persona.curp"),
                disabled: "",
              },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.curp,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "curp", $$v)
                  },
                  expression: "ayudante.curp",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.persona.rfc"),
                disabled: "",
              },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.rfc,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "rfc", $$v)
                  },
                  expression: "ayudante.rfc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.persona.genero"),
                disabled: "",
              },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.genero,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "genero", $$v)
                  },
                  expression: "ayudante.genero",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("apeironGwApp.persona.nacionalidad"),
                disabled: "",
              },
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.ayudante.nacionalidad,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "nacionalidad", $$v)
                  },
                  expression: "ayudante.nacionalidad",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }),
      _vm._v(" "),
      _vm.ayudante.domicilios
        ? _c(
            "b-form-group",
            { staticClass: "mt-3 col-6" },
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  borderless: "",
                  items: _vm.ayudante.domicilios,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(direccion)",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("apeironGwApp.persona.direccion")) +
                              " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  810497699
                ),
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3 col-6",
                  attrs: {
                    id: "form-umas-id",
                    size: "sm",
                    label: _vm.$t(
                      "apeironGwApp.persona.expediente.ayudantias.umas"
                    ),
                    "label-for": "uma-id",
                    disabled: !_vm.mostrarBoton,
                  },
                },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "uma-id",
                      options: _vm.umas,
                      name: "umas",
                      disabled: !_vm.mostrarBoton,
                    },
                    model: {
                      value: _vm.ayudante.uma,
                      callback: function ($$v) {
                        _vm.$set(_vm.ayudante, "uma", $$v)
                      },
                      expression: "ayudante.uma",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ayudante?.domicilios == null || _vm.ayudante?.rfc == null
        ? _c(
            "b-form-group",
            { staticClass: "mt-3 col-12" },
            [
              _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("apeironGwApp.persona.expediente.ayudantias.alert")
                    )
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "b-form-group",
            {
              attrs: {
                id: "form-uma-reclasificada-id",
                size: "sm",
                label: "Nuevo valor de UMA's",
                "label-for": "uma-reclasificada-id",
              },
            },
            [
              _c("b-form-radio-group", {
                attrs: {
                  id: "uma-reclasificada-id",
                  options: _vm.umas,
                  name: "umaReclasificada",
                },
                model: {
                  value: _vm.ayudante.umaRec,
                  callback: function ($$v) {
                    _vm.$set(_vm.ayudante, "umaRec", $$v)
                  },
                  expression: "ayudante.umaRec",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "col-12",
          attrs: {
            disabled: !_vm.mostrarBoton,
            label: _vm.$t(
              "apeironGwApp.persona.expediente.ayudantias.proyectoAyudante"
            ),
          },
        },
        [
          _c("b-form-input", {
            model: {
              value: _vm.ayudante.proyectos,
              callback: function ($$v) {
                _vm.$set(_vm.ayudante, "proyectos", $$v)
              },
              expression: "ayudante.proyectos",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { staticClass: "col-12" },
        [
          _vm.mostrarBoton
            ? _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.desactivarBoton },
                  on: {
                    click: function ($event) {
                      return _vm.saveAyudante2()
                    },
                  },
                },
                [
                  _vm.saving
                    ? _c("b-spinner", { attrs: { small: "", type: "grow" } }, [
                        _vm._v(_vm._s(_vm.$t("entity.action.saving"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.saving
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.saving")),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.saving
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.save")),
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }