export interface IFiltroMovimientoBeca {
  id?: string | null;
  tipoMovimientoBecaId?: string | null;
  estadoActual?: string | null;
  becaId?: string | null;
}

export class FiltroMovimientoBeca implements IFiltroMovimientoBeca {
  constructor(
    public id?: string | null,
    public tipoMovimientoBecaId?: string | null,
    public estadoActual?: string | null,
    public becaId?: string | null
  ) {}
}
