import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import { minLength, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { Catalogo } from '@/components/movimientos/registro-movimiento/movimientos.catalog';

import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';

import SolucionMixin from '@/entities/evaluacion/mixins/solucion-mixin';
import { Filtro } from '@/shared/model/filtro.model';
import SolucionService from '@/entities/solucion/solucion.service';

import * as dateUtils from '@/shared/date/date-utils';
import { Solucion } from '@/shared/model/solucion.model';
import { MotivosCatalogos } from './motivos.catalog';
import {
  ComentarioJuicio,
  HistoricoJuicio,
  IComentarioJuicio,
  IHistoricoJuicio,
  IPagoAdeudo,
  IRestForm,
  Movimiento,
  PagoAdeudo,
  RestForm,
} from '@/shared/model/movimiento.model';
import { TipoAcceso } from '@/shared/model/enumerations/tipo-acceso.model';
import { removeAccents } from '@/shared/util/format-util';

const validations = function () {
  const {
    form,
    cveVigCargoAdmvoElec,
    cveVigTermAnt,
    tamanioMinimoObservacion,
    cveVigFinalizoAdmvoElec,
    cveSancion,
    cveAdeudo,
    cveJuicio,
    noProceso,
  } = this as RegistroMovimientoComponent;
  return {
    form: {
      movimiento: {
        tipo: { required },
        inicioAplicacion: {
          required: requiredIf(
            () =>
              form?.movimiento?.tipo?.clave === cveVigCargoAdmvoElec ||
              form?.movimiento?.tipo?.clave === cveSancion ||
              form?.movimiento?.tipo?.clave === cveAdeudo
          ),
        },
        finAplicacion: {
          required: requiredIf(
            () =>
              form?.movimiento?.tipo?.clave === cveVigFinalizoAdmvoElec ||
              form?.movimiento?.tipo?.clave === cveSancion ||
              form?.movimiento?.tipo?.clave === cveAdeudo
          ),
          minValue:
            form?.movimiento?.tipo?.clave === cveSancion || form?.movimiento?.tipo?.clave === cveAdeudo
              ? minValue(form?.movimiento?.inicioAplicacion)
              : minValue(new Date()),
        },
        observacion: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveVigTermAnt),
          minLength: minLength(tamanioMinimoObservacion),
        },
        motivo: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveSancion || form?.movimiento?.tipo?.clave === cveAdeudo),
        },
        autoridad: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio),
        },
        actoReclamado: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio),
        },
        actoReclamadoTexto: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio),
        },
        numeroJucio: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio),
        },
        mesProgramado: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 3),
        },
        estatus: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio),
        },
        montoAdeudo: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveAdeudo),
        },
        estatusAdeudo: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveAdeudo),
        },
        suspension: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 0),
        },
        incidente: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 1),
        },
        ejecutoria: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 2),
        },
        oficioSolicitud: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 3),
        },
        decision: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && form?.movimiento?.decision !== 'No se solicitó'),
        },
        tipoIncidente: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && form?.movimiento?.incidente === 'Sí'),
        },
        tipoEjecutoria: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && form?.movimiento?.ejecutoria === 'Sí'),
        },
        tipoOficioSolicitud: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && form?.movimiento?.oficioSolicitud === 'Sí'),
        },
        oficioSolicitudTexto: {
          required: requiredIf(() => form?.movimiento?.tipo?.clave === cveJuicio && noProceso === 3),
        },
      },
    },
  };
};

@Component({
  validations,
})
export default class RegistroMovimientoComponent extends ApeironComponent {
  @Inject('solucionService') public solucionService: () => SolucionService;

  @Prop({ required: false })
  public solicitud: Solicitud;

  @Prop({ default: false })
  public solucion: Solucion;

  public rolOperador = false;

  public coment: IComentarioJuicio = new ComentarioJuicio();

  public pagoAdeudo: IPagoAdeudo = new PagoAdeudo();

  public resetForm: IRestForm = new RestForm(new Movimiento());

  public form: IRestForm = new RestForm(new Movimiento());

  public tipoVigenciasOpciones: Array<any> = [];
  public tipoVigenciasOpcionesSplit: Array<any> = [];

  public estatusJuciosAmparoOpciones: Array<any> = [];

  public catalogoActoReclamadoAsOptions: Array<any> = [];

  public tipoMotivosOpciones: Array<any> = [];

  public opcionesGenerales: Array<any> = [];

  public opcionesSuspencion: Array<any> = [];

  public tipoSuspensionOpciones: Array<any> = [];

  public tipoIncidenteOpciones: Array<any> = [];

  public tipoEjecutoriaOpciones: Array<any> = [];

  public tipoOficioOpciones: Array<any> = [];

  public indOpen = false;

  public readonly cveVigCargoAdmvoElec = '24';
  public readonly cveVigFinalizoAdmvoElec = '23';
  public readonly cveVigTermAnt = '83';

  public readonly cveAdeudo = 'ADEUDO';
  public readonly cveSancion = 'SANCION';
  public readonly cveJuicio = 'JUICIOS';

  public isCaptura = EstadoSolicitud.EN_CAPTURA;
  public isOperacion = EstadoSolicitud.OPERACION_EN_PROCESO;

  public login = this.$store.getters.account?.login ?? '';

  public solucionSelect = null;

  public fechaInicioVigencia = '';

  public fechaFinVigencia = '';

  public readonly tamanioMinimoObservacion = 140;

  /**
   * Variable para abrir o cerrar el modal
   *
   */
  public modalShow = false;

  /**
   * Variable para abrir o cerrar el modal de historico
   *
   */
  public modalShowJucio = false;

  /**
   * Variable para indicar que se edita el pago
   *
   */
  public isEdit = false;

  public noProceso = 0;

  get desactivarBoton(): boolean {
    return this.$v.$invalid;
  }

  mounted() {
    if (this.isPublico()) {
      this.tipoVigenciasOpciones = Catalogo.tipoVigenciasSniAsOptions();
    } else {
      this.tipoVigenciasOpciones = Catalogo.tiExcepcionesSniAsOptions();
      this.estatusJuciosAmparoOpciones = MotivosCatalogos.estatusJucioApamaroAsOptions();
      this.catalogoActoReclamadoAsOptions = MotivosCatalogos.catalogoActoReclamadoAsOptions();
      this.opcionesGenerales = MotivosCatalogos.OPCIONES_RADIO;
      this.opcionesSuspencion = MotivosCatalogos.OPCIONES_RADIO_SUSPENCION;
      this.tipoSuspensionOpciones = MotivosCatalogos.OPCIONES_RADIO_TIPO_SUSPENCION;
      this.tipoIncidenteOpciones = MotivosCatalogos.OPCIONES_RADIO_TIPO_INCIDENTE;
      this.tipoEjecutoriaOpciones = MotivosCatalogos.OPCIONES_RADIO_TIPO_EJECUTORIA;
      this.tipoOficioOpciones = MotivosCatalogos.OPCIONES_RADIO_TIPO_OFICIO;
    }

    if (this.solicitud?.movimiento) {
      this.form.movimiento = this.solicitud.movimiento;
      this.form.movimiento.inicioAplicacion = new Date(this.form.movimiento.inicioAplicacion + ' 00:00');
      this.form.movimiento.finAplicacion = new Date(this.form.movimiento.finAplicacion + ' 00:00');
      if (this.solicitud.estado !== this.isCaptura && this.solicitud.estado !== this.isOperacion) {
        this.indOpen = !this.indOpen;
      }
    }
    if (this.solicitud?.vigenciasSni) {
      this.fechaInicioVigencia = this.formatoFecha(this.solicitud.vigenciasSni[0].vigencia.fechaInicio);

      this.fechaFinVigencia = this.formatoFecha(this.solicitud.vigenciasSni[0].vigencia.fechaFin);
    }
    this.retrieveAllSolucionsByUser();
  }

  /**
   * Limpia el formulario con cada cambio
   * de selección de vigencia.
   */
  public limpiaForm(): void {
    this.resetForm = new RestForm(new Movimiento());
    this.resetForm.movimiento.tipo = this.form.movimiento.tipo;
    this.form = this.resetForm;
  }

  /**
   * Consulta si el usuario está como autoridad en la solución
   * y emite una bandera para después filtrar las opciones.
   */

  public retrieveAllSolucionsByUser(): void {
    const filtro = new Filtro();
    filtro.usuario = this.login;
    filtro.solucionId = this.solicitud.solucionId;
    const paginationQuery = {
      page: SolucionMixin.PAGE - 1,
      size: SolucionMixin.ITEMS_PER_PAGE,
      sort: ' id , desc ',
      filter: filtro,
    };
    this.solucionService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            this.rolOperador = true;
          }
          this.validaSolucionUsuarioRol();
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  /**
   * Filtra las opciones a mostrar si es autoridad en la solución
   *
   * @param rolOperador
   */
  public validaSolucionUsuarioRol(): void {
    for (const tipo of this.tipoVigenciasOpciones) {
      if (
        (this.isPublico() && this.rolOperador && (tipo.value.clave === '8' || tipo.value.clave === '52')) ||
        (this.isPublico() && !this.rolOperador && tipo.value.clave !== '83' && tipo.value.clave !== '52')
      ) {
        this.tipoVigenciasOpcionesSplit.push(tipo);
      } else if (
        this.solucion.tipoAcceso === TipoAcceso.PUBLICO &&
        this.rolOperador &&
        !this.isPublico() &&
        (tipo.value.id === 550 || tipo.value.id === 551)
      ) {
        this.tipoVigenciasOpcionesSplit.push(tipo);
      } else if (!this.rolOperador && !this.isPublico() && tipo.value.id === 553) {
        this.tipoVigenciasOpcionesSplit.push(tipo);
      } else if (this.solucion.tipoAcceso === TipoAcceso.PRIVADO && tipo.value.id === 552) {
        this.tipoVigenciasOpcionesSplit.push(tipo);
      }
    }
  }

  /**
   * Da formato a la fecha .
   * @param fecha
   * @returns
   */
  public formatoFecha(fecha): string {
    if (fecha != null) {
      const nuevaFecha = new Date(fecha);
      return dateUtils.formatUtc(nuevaFecha);
    }
    return '';
  }

  @Watch('form.movimiento.tipo')
  public getTipoMotivosOpciones(): void {
    if (this.form?.movimiento?.tipo?.clave === this.cveAdeudo) {
      this.tipoMotivosOpciones = MotivosCatalogos.tipoMotivacionAdeudosAsOptions();
    } else if (this.form?.movimiento?.tipo?.clave === this.cveSancion) {
      this.tipoMotivosOpciones = MotivosCatalogos.tipoMotivacionSancionesAsOptions();
    }
  }

  /**
   * Agrega comentario a la lista de comentarios
   *
   */
  public agregarComentario(): void {
    if (this.coment?.descripcion) {
      this.coment.fecha = new Date();
      this.form.movimiento.comentarios.push(this.coment);
      this.coment = new ComentarioJuicio();
    }
  }

  public configuracionColumnasComent = [
    {
      key: 'fecha',
      label: this.$t('apeironGwApp.movimiento.fechaComenrtario').toString(),
      class: 'text-center',
      sortable: true,
      formatter: fecha => {
        return this.formatoFecha(fecha);
      },
    },
    {
      key: 'descripcion',
      label: this.$t('apeironGwApp.movimiento.comentario').toString(),
      class: 'text-center',
      sortable: true,
    },
  ];

  /**
   * Agrega pagos a la lista de pagos de adeudos
   *
   */
  public agregarPago(): void {
    if (this.pagoAdeudo?.montoPagado) {
      if (this.isEdit) {
        this.pagoAdeudo.restaAdeudo = this.form.movimiento.montoAdeudo - this.talPago;
        this.isEdit = !this.isEdit;
      } else {
        this.form.movimiento.pagosAdeudos.push(this.pagoAdeudo);
        this.pagoAdeudo.restaAdeudo = this.form.movimiento.montoAdeudo - this.talPago;
      }
      this.form.movimiento.estatusAdeudo = this.saldado <= 0 ? 'ADEUDO SALDADO' : 'ADEUDO';
      this.modalShow = !this.modalShow;
    }
  }

  /**
   * activa y desactiva la ventana modal
   */
  public isModal(): void {
    this.pagoAdeudo = new PagoAdeudo();
    this.modalShow = !this.modalShow;
  }

  /**
   * Prepara el registro para editar pagos
   */
  public editar(pagoAdeudo): void {
    this.pagoAdeudo = pagoAdeudo;
    this.modalShow = !this.modalShow;
    this.isEdit = !this.isEdit;
  }

  /**
   * Prepara el registro para editar pagos
   */
  public eliminar(): void {
    this.form.movimiento.pagosAdeudos.splice(this.form.movimiento.pagosAdeudos.length - 1, 1);
    this.form.movimiento.estatusAdeudo = this.saldado <= 0 ? 'ADEUDO SALDADO' : 'ADEUDO';
  }

  public configuracionColumnasPagos = [
    {
      key: 'mesPago',
      label: this.$t('apeironGwApp.movimiento.mesPago').toString(),
      class: 'text-center',
      sortable: true,
      formatter: fecha => {
        return this.formatoFecha(fecha);
      },
    },
    {
      key: 'montoPagado',
      label: this.$t('apeironGwApp.movimiento.montoPagado').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'restaAdeudo',
      label: this.$t('apeironGwApp.movimiento.saldo').toString(),
      class: 'text-center',
      sortable: true,
    },
    { key: 'accion', label: this.$t('bandeja.acciones').toString(), class: 'text-center' },
  ];

  /**
   * Valida si la solución es publica o privada
   * @returns boolean
   */
  public isPublico(): boolean {
    return this.solucion?.params?.isVigencia;
  }

  /**
   * Devuelve el total de los pagos del adeudo
   * @returns number
   */
  public get talPago(): number {
    const initialValue = 0;
    const total = this.form.movimiento.pagosAdeudos.reduce((accumulator, pago) => accumulator + pago.montoPagado, initialValue);
    return total;
  }

  /**
   * Devuelve el total del saldo del adeudo
   * @returns number
   */
  public get saldoAdeudo(): number {
    const total = this.form.movimiento.montoAdeudo - this.talPago;
    return total;
  }

  /**
   * Pone la palabra ADEUDO en el esta estatusAdeudo si se registra por primera vez
   */
  @Watch('form.movimiento.tipo')
  public setEstatusAdeudo(): void {
    if (this.form.movimiento?.tipo?.clave === this.cveAdeudo && !this.form?.movimiento?.estatusAdeudo) {
      this.form.movimiento.estatusAdeudo = 'ADEUDO';
    }
  }

  public guardar(): void {
    const form = { ...this.form };
    if (form.movimiento?.inicioAplicacion) {
      form.movimiento.inicioAplicacion = dateUtils.formatDate(this.form.movimiento.inicioAplicacion).split(' ')[0];
    }
    if (form.movimiento?.finAplicacion) {
      form.movimiento.finAplicacion = dateUtils.formatDate(this.form.movimiento.finAplicacion).split(' ')[0];
    }
    this.save(form);
  }

  verificarUltimoRegistro(item): boolean {
    const indice = this.form.movimiento.pagosAdeudos.indexOf(item);
    return indice === this.form.movimiento.pagosAdeudos.length - 1;
  }

  public get saldado(): number {
    return this.form.movimiento.montoAdeudo - this.talPago;
  }

  /**
   * Convierte las letras de minúscula a mayúsculas
   * @param field
   */
  public convertToUpperCase(field: string) {
    this.form.movimiento[field] = this.form.movimiento[field].toUpperCase();
  }

  /**
   * Convierte las letras de minúscula a mayúsculas y elimina los acentos
   * @param field
   */
  public convertToUpperCaseAndRemoveAccents(field: string) {
    this.form.movimiento[field] = removeAccents(this.form.movimiento[field].toUpperCase());
  }

  /**
   * Se genera una lista de opciones donde se deshabilitan todas excepto "OTROS"
   * si "OTROS" está seleccionada en caso de que no este selecionada se habilitan todos los check.
   * @returns any[]
   */
  public filteredActoReclamadoOptions() {
    if (
      this.form?.movimiento?.actoReclamado &&
      this.form?.movimiento?.actoReclamado.some(ac => ac.clave === MotivosCatalogos.ACTO_RECLAMADO_OTROS.clave)
    ) {
      return this.catalogoActoReclamadoAsOptions.map(option => {
        return {
          ...option,
          disabled: option.value.clave !== MotivosCatalogos.ACTO_RECLAMADO_OTROS.clave,
        };
      });
    }
    return this.catalogoActoReclamadoAsOptions;
  }

  /**
   * Se llama cada vez que cambia la selección de los checkboxes.
   * Si "OTROS" está seleccionada, se asegura de que solo esa opción esté en el modelo.
   */
  public handleActoReclamadoChange() {
    if (this.form.movimiento.actoReclamado.some(ac => ac.clave === MotivosCatalogos.ACTO_RECLAMADO_OTROS.clave)) {
      this.form.movimiento.actoReclamado = null;
      this.form.movimiento.actoReclamadoTexto = null;
      this.form.movimiento.actoReclamado = [MotivosCatalogos.ACTO_RECLAMADO_OTROS];
    }
  }

  /**
   * Se llama cada vez que cambia la selección de los checkboxes.
   * Si "OTROS" está seleccionada, se asegura de que solo esa opción esté en el modelo.
   */
  public resetTipoIncidente() {
    if (this.form.movimiento.incidente === 'No') {
      this.form.movimiento.tipoIncidente = null;
    }
  }

  /**
   * Se llama cada vez que cambia la selección de los checkboxes.
   * Si "OTROS" está seleccionada, se asegura de que solo esa opción esté en el modelo.
   */
  public resetTipoSuspension() {
    if (this.form.movimiento.suspension === 'No se solicitó') {
      this.form.movimiento.decision = null;
    }
  }

  /**
   * Se llama cada vez que cambia la selección de los checkboxes.
   * Si "OTROS" está seleccionada, se asegura de que solo esa opción esté en el modelo.
   */
  public resetTipoEjecutoria() {
    if (this.form.movimiento.ejecutoria === 'No') {
      this.form.movimiento.tipoEjecutoria = null;
    }
  }

  /**
   * Se llama cada vez que cambia la selección de los checkboxes.
   * Si "OTROS" está seleccionada, se asegura de que solo esa opción esté en el modelo.
   */
  public resetTipoOficioSolicitud() {
    if (this.form.movimiento.oficioSolicitud === 'No') {
      this.form.movimiento.tipoOficioSolicitud = null;
    }
  }

  /**
   * Prepara la información que se guardara en historico de proceso de juicio
   */
  public addRegistroHistorico(proceso: string) {
    const historicoJuicioProceso: IHistoricoJuicio = new HistoricoJuicio();

    historicoJuicioProceso.proceso = proceso;
    historicoJuicioProceso.noProceso = this.noProceso;
    historicoJuicioProceso.fecha = new Date();
    this.form.movimiento.noProceso = this.noProceso;
    historicoJuicioProceso.estatus = this.form.movimiento.estatus;
    if (this.noProceso === 0) {
      historicoJuicioProceso.descProceso = this.form.movimiento.suspension;
      historicoJuicioProceso.tipoProceso = this.form?.movimiento?.decision ?? null;
      if (
        (this.form?.movimiento?.suspension !== 'No se solicitó' && this.form?.movimiento?.decision) ||
        this.form?.movimiento?.suspension === 'No se solicitó'
      ) {
        this.form.movimiento.historicoJuicioProceso.push(historicoJuicioProceso);
      }
    }
    if (this.noProceso === 1) {
      historicoJuicioProceso.descProceso = this.form.movimiento.incidente;
      historicoJuicioProceso.tipoProceso = this.form?.movimiento?.tipoIncidente ?? null;
      if (
        (this.form?.movimiento?.incidente === 'Sí' && this.form?.movimiento?.tipoIncidente) ||
        this.form?.movimiento?.incidente === 'No'
      ) {
        this.form.movimiento.historicoJuicioProceso.push(historicoJuicioProceso);
      }
    }
    if (this.noProceso === 2) {
      historicoJuicioProceso.descProceso = this.form.movimiento.ejecutoria;
      historicoJuicioProceso.tipoProceso = this.form?.movimiento?.tipoEjecutoria ?? null;
      if (
        (this.form?.movimiento?.ejecutoria === 'Sí' && this.form?.movimiento?.tipoEjecutoria) ||
        this.form?.movimiento?.ejecutoria === 'No'
      ) {
        this.form.movimiento.historicoJuicioProceso.push(historicoJuicioProceso);
      }
    }
    if (this.noProceso === 3) {
      historicoJuicioProceso.descProceso = this.form.movimiento.oficioSolicitud;
      historicoJuicioProceso.tipoProceso = this.form?.movimiento?.tipoOficioSolicitud ?? null;
      if (this.form?.movimiento?.oficioSolicitud === 'Sí' && this.form?.movimiento?.tipoOficioSolicitud) {
        this.form.movimiento.historicoJuicioProceso.push(historicoJuicioProceso);
      } else if (this.form?.movimiento?.oficioSolicitud === 'No') {
        this.form.movimiento.historicoJuicioProceso.push(historicoJuicioProceso);
      }
    }
  }

  public columnasHistoricoProcesoJuicios = [
    {
      key: 'estatus.desc',
      label: this.$t('apeironGwApp.movimiento.tipoDemanda').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'proceso',
      label: this.$t('apeironGwApp.movimiento.estatus').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'descProceso',
      label: this.$t('apeironGwApp.movimiento.descProceso').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'tipoProceso',
      label: this.$t('apeironGwApp.movimiento.tipoProceso').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'fecha',
      label: this.$t('apeironGwApp.movimiento.fechaComenrtario').toString(),
      class: 'text-center',
      sortable: true,
      formatter: fecha => {
        return this.formatoFecha(fecha);
      },
    },
  ];

  /**
   * Muestra el modal del histórico
   *
   */
  public verHistorico(): void {
    this.modalShowJucio = !this.modalShowJucio;
  }
}
