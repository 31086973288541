import HomeMenuComponent from '@/components/home-menu/home-menu.component';
import { INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';
import { TipoGenero } from '@/shared/model/enumerations/tipo-genero-enum.model';
import { Dictamen } from '@/shared/model/respuesta.model';
import { IRevision, Revision } from '@/shared/model/revision.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';

const validations: any = {
  dictamenEmerito: {
    comentarios: {
      required,
      min: minLength(140),
      max: maxLength(10000),
    },
  },
};

@Component({
  validations,
  computed: {
    ...mapGetters(['dictamen', 'evaluacion', 'revisorSeleccionado']),
  },
  methods: {
    ...mapMutations(['setDictamen', 'setEvaluacion']),
  },
})
export default class DictamenEmeritosComponent extends mixins(HomeMenuComponent) {
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public revision: Revision;

  /**
   * Indica si se requiere una evaluación individual.
   */
  @Prop({ required: false, default: true })
  public evaluacionIndividual: boolean;

  public valueEmerito: boolean | null = null;

  public dictamenEmerito: any = {};

  public aceptada = EstadoRevision.ACEPTADA;
  public revisada = EstadoRevision.REVISADA;

  public caracterInicial = 0;
  public caracteresFaltantes = 139;
  public caracteresMinimos = 140;
  public caracteresMaximos = 10000;

  public idNivelEmerito = NRecomendadoEnum.NIVEL_EMERITO;

  public Masculino = TipoGenero.MASCULINO;

  public Femenino = TipoGenero.FEMENINO;

  public revisiones: Array<IRevision> = [];

  setDictamen!: (dictamen: Dictamen) => void;

  setEvaluacion!: (evaluacion: INivel) => void;

  public readonly dictamen: Dictamen;

  public readonly evaluacion: INivel;

  public readonly revisorSeleccionado: number;

  public options = [
    { text: this.$t('emeritos.aprobado').toString(), value: true },
    { text: this.$t('emeritos.noAprobado').toString(), value: false },
  ];

  mounted(): void {
    this.get();
  }

  public get(): void {
    if (this.solicitud && this.revision) {
      if (this.revision?.respuesta?.dictamen) {
        this.dictamenEmerito = this.revision.respuesta.dictamen;
      } else if (this.dictamen && this.revision?.respuesta?.evaluacion) {
        this.dictamenEmerito = this.dictamen;
        this.revision.respuesta.evaluacion = this.evaluacion;
      } else if (!this.evaluacionIndividual) {
        this.dictamenEmerito = this.dictamen;
        this.revision.respuesta.evaluacion = this.evaluacion;
      }
      if (this.revision?.respuesta?.evaluacion?.id) {
        this.valueEmerito = this.revision.respuesta.evaluacion.id === this.idNivelEmerito;
      } else {
        this.valueEmerito = null;
      }
    }
  }

  /**
   * Este método emite un evento para guardar sobre el cambio
   * de la variable valido.
   *
   * @memberof DictamenEmeritosComponent
   */
  public toggleRadioButton(): void {
    const datosEnviar = { valido: false };
    this.$emit('guardarDictamenEmerito', datosEnviar);
  }

  public guardarDictamen(): void {
    let idEvaluacion;
    let nombreEvaluacion;
    this.setEvaluacion({});
    this.setDictamen({});
    if (this.valueEmerito) {
      idEvaluacion = NRecomendadoEnum.NIVEL_EMERITO;
      nombreEvaluacion = this.$t('dictamenEmeritos.emeritoAprobado');
    } else {
      idEvaluacion = NRecomendadoEnum.NO_APROBADO;
      nombreEvaluacion = this.$t('dictamen.noAprobado');
    }

    const dictamenEmerito = {
      evaluacion: {
        id: idEvaluacion,
        nombre: nombreEvaluacion,
      },
      dictamen: {
        comentarios: this.dictamenEmerito.comentarios,
      },
      valido: true,
    };

    this.$emit('guardarDictamenEmerito', dictamenEmerito);
  }
}
