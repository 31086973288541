var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-table-simple",
                { attrs: { small: "", "caption-top": "", responsive: "" } },
                [
                  _c(
                    "b-thead",
                    { attrs: { "head-variant": "primary" } },
                    [
                      _c(
                        "b-tr",
                        { staticClass: "text-center" },
                        [
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.headers.id"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.headers.situacion"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.headers.inicio"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.headers.fin"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.headers.comprobante"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.acreditaciones && _vm.acreditaciones.length > 0,
                          expression:
                            "acreditaciones && acreditaciones.length > 0",
                        },
                      ],
                    },
                    _vm._l(_vm.acreditaciones, function (acreditacion) {
                      return _c(
                        "b-tr",
                        {
                          key: acreditacion.idMovimiento,
                          staticClass: "text-center",
                        },
                        [
                          _c("b-td", [
                            _vm._v(_vm._s(acreditacion.idMovimiento || "")),
                          ]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(acreditacion.tipo?.descripcion || "")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(_vm._s(acreditacion.vigencia?.fechaInicio)),
                          ]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(_vm._s(acreditacion.vigencia?.fechaFin)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.urlDocumento +
                                    (acreditacion.documento
                                      ?.idContentManagment || "#"),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.historicoAcreditaciones.rows.comprobante"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.acreditaciones ||
                            _vm.acreditaciones.length === 0,
                          expression:
                            "!acreditaciones || acreditaciones.length === 0",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-tr",
                        { staticClass: "text-center h5" },
                        [
                          _c("b-td", { attrs: { colspan: "5" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.historicoAcreditaciones.sinInformacion"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }