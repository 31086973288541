var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-col",
    { staticClass: "text-center alert-info-card" },
    [
      _c("div", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c("b-col"),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                {
                  staticClass:
                    "rounded-circle shadow p-3 mt-1 bg-white d-inline-block border-0",
                  attrs: { size: "lg", variant: "light" },
                  on: { click: _vm.handleAddData },
                },
                [_c("b-icon", { attrs: { icon: _vm.icon, scale: "1.2" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "m-3 p-3 text-center", attrs: { cols: "6" } },
            [
              _c("span", { staticClass: "float-right text-center" }, [
                _c("p", { staticClass: "text-secondary" }, [
                  _vm._v(_vm._s(_vm.hintDetail)),
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.messageDetail))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("b-col"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }