export interface IPrograma {
  id?: string | null;
  clave?: string | null;
  nombre?: string | null;
  estado?: string | null;
  integrado?: boolean | null;
  cveModalidad?: string | null;
  modalidad?: string | null;
  cveGrado?: string | null;
  grado?: string | null;
  cveOrientacion?: string | null;
  orientacion?: string | null;
  cveCaracteristica?: string | null;
  caracteristica?: string | null;
  cveTipo?: string | null;
  tipo?: string | null;
  fechaCreacion?: string | null;
  fechaInicio?: string | null;
  anteDgp?: string | null;
  rvoe?: string | null;
  paginaWeb?: string | null;
  tipoEspecial?: string | null;
  properties?: object | null;
}

export class Programa implements IPrograma {
  constructor(
    public id?: string | null,
    public clave?: string | null,
    public nombre?: string | null,
    public estado?: string | null,
    public cveModalidad?: string | null,
    public modalidad?: string | null,
    public cveGrado?: string | null,
    public grado?: string | null,
    public cveOrientacion?: string | null,
    public orientacion?: string | null,
    public cveCaracteristica?: string | null,
    public caracteristica?: string | null,
    public cveTipo?: string | null,
    public tipo?: string | null,
    public fechaCreacion?: string | null,
    public fechaInicio?: string | null,
    public anteDgp?: string | null,
    public rvoe?: string | null,
    public paginaWeb?: string | null,
    public integrado?: boolean | null,
    public tipoEspecial?: string | null,
    public properties?: object | null
  ) {}

  static fromObject(data: IPrograma): Programa {
    const programa = new Programa();
    for (const [key, value] of Object.entries(data)) {
      programa[key] = value;
    }
    return programa;
  }
}
