var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.lieSnp.form.nombreDeLaLie") +
                              " *",
                            "label-for": "input-nombre-linea",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-nombre-linea",
                              placeholder: _vm.$t(
                                "apeironGwApp.lieSnp.form.nombreDeLaLie"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.lie.nombreDeLinea.$invalid,
                            },
                            model: {
                              value: _vm.lie.nombreDeLinea,
                              callback: function ($$v) {
                                _vm.$set(_vm.lie, "nombreDeLinea", $$v)
                              },
                              expression: "lie.nombreDeLinea",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.lieSnp.form.clasificacion") +
                              " *",
                            "label-for": "input-clasificacion",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-clasificacion",
                              options: _vm.clasificaciones,
                              placeholder: _vm.$t(
                                "apeironGwApp.lieSnp.form.clasificacion"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.lie.clasificacion.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.lieSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.lie.clasificacion,
                              callback: function ($$v) {
                                _vm.$set(_vm.lie, "clasificacion", $$v)
                              },
                              expression: "lie.clasificacion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.lieSnp.form.fechaAlta") +
                              " *",
                            "label-for": "input-fecha-alta",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              id: "input-fecha-alta",
                              placeholder: _vm.$t(
                                "apeironGwApp.lieSnp.form.fechaAlta"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.lie.fechaAlta.$invalid,
                            },
                            model: {
                              value: _vm.lie.fechaAlta,
                              callback: function ($$v) {
                                _vm.$set(_vm.lie, "fechaAlta", $$v)
                              },
                              expression: "lie.fechaAlta",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t(
                                "apeironGwApp.lieSnp.form.temasPrioritariosPeciti"
                              ) + " *",
                            "label-for": "input-temas-prioritarios",
                          },
                        },
                        [
                          _c("seleccion-tablas", {
                            attrs: {
                              disabled: _vm.readOnly,
                              state:
                                !_vm.$v.lie.temasPrioritariosPeciti.$invalid,
                              opciones: _vm.temasPrioritariosPeciti,
                              keyComp: _vm.comparacionTemasPriorotariosPeciti,
                              inicial: _vm.inicialTemasPriorotariosPeciti,
                            },
                            on: {
                              "change-selection": function ($event) {
                                _vm.lie.temasPrioritariosPeciti = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t(
                                "apeironGwApp.lieSnp.form.institucionCedeDependencia"
                              ) + " *",
                            "label-for": "input-institucion",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "input-institucion",
                              options: _vm.instituciones,
                              placeholder: _vm.$t(
                                "apeironGwApp.lieSnp.form.institucionCedeDependencia"
                              ),
                              disabled:
                                _vm.instituciones.length < 2 || _vm.readOnly,
                              state:
                                !_vm.$v.lie.institucionCedeDependencia.$invalid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "apeironGwApp.lieSnp.form.seleccionarUno"
                                              )
                                              .toString()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.lie.institucionCedeDependencia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.lie,
                                  "institucionCedeDependencia",
                                  $$v
                                )
                              },
                              expression: "lie.institucionCedeDependencia",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label:
                              _vm.$t("apeironGwApp.lieSnp.form.justificacion") +
                              " *",
                            "label-for": "input-justificacion",
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "input-justificacion",
                              placeholder: _vm.$t(
                                "apeironGwApp.lieSnp.form.justificacion"
                              ),
                              disabled: _vm.readOnly,
                              state: !_vm.$v.lie.justificacion.$invalid,
                            },
                            model: {
                              value: _vm.lie.justificacion,
                              callback: function ($$v) {
                                _vm.$set(_vm.lie, "justificacion", $$v)
                              },
                              expression: "lie.justificacion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-row", [_c("b-col", [_c("hr")])], 1),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "end" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary",
                            disabled: _vm.formularioIsInvalid || _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.guardarLie()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.lieSnp.acciones.add")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "add" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "button", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.limpiarDatos()
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.lieSnp.acciones.limpiar")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "refresh" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }