const formsSteps: any = {
  steps: [
    {
      target: '#form-title-id',
      content: `Ingresa el título de tu formulario <strong>Form</strong>. Con este título lo podrás localizar más fácilmente`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-description-id',
      content: `Ingresa una descripción para tu formulario. Esta descripción ayudará a entender que solución resuelve tu formulario`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-menuName-id',
      content: `Selecciona el nombre del menú en dónde se mostrará tu formulario. Todos los formularios se agrupan por categorías y cada categoría pertenece a un menú`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-tipo-id',
      content: `Selecciona el tipo de formulario que estás creando. Si el componente va a ser creado por un programador de <strong>Ápeiron</strong> entonces selecciona <strong>Personalizado</strong>, de otro modo selecciona <strong>Formulario</strong>. Para seguir este tour, por favor selecciona <strong>Formulario</strong>`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-name-id',
      content: `Dale un nombre a tu formulario, este nombre debe se único y debe tener significado asociado a su propósito`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-path-id',
      content: `Ingresa el path a tu formulario. Este campo deberá de tener la sintaxís de ruta/a/mi/componente`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#form-tags-id',
      content: `Etiqueta tu formaluario con palabras cláves que permitan localizar tu componente más rápidamente`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#tab-form-id___BV_tab_button__',
      content: `Da clic aquí para comenzar a editar tu formulario`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '#group-container-customBasic',
      content: `Selecciona y arrastra alguno de estos componentes en la zona de edición del formulario, de tal manera que puedas configurar un formulario para tus soluciones`,
      params: {
        placement: 'right',
      },
    },
    {
      target: '.formio-component',
      content: `En esta sección podrás arrastrar los componentes de la izquierda, con la finalidad de crear tu formluario`,
      params: {
        placement: 'right',
      },
    },
  ],
};

export default formsSteps;
