const defaultSteps: any = {
  steps: [
    {
      target: '#navbar-informacion-general-id', // We're using document.querySelector() under the hood
      content: `Conoce sobre <strong>CONACyT</strong> el creador de ápeiron`,
      params: {
        placement: 'bottom',
      },
    },
    {
      target: '#navbar-home-id',
      content: `Siempre que te sientas perdido, aquí podrás regresar a tu menú principal`,
      params: {
        placement: 'bottom',
      },
    },
    {
      target: '#languagesnavBarDropdown',
      content: `Con este menú, podrás cambiar el idioma del sitio`,
      params: {
        placement: 'bottom',
      },
    },
    {
      target: '#account-menu',
      content: `Aquí podrás iniciar o cerrar tú sesión`,
      params: {
        placement: 'bottom',
      },
    },
  ],
};

export default defaultSteps;
