import { Component, Prop, Vue } from 'vue-property-decorator';
import { Acreditacion } from '@conacyt/historicopersonasms-client';
import * as dateUtils from '@/shared/date/date-utils';
import * as formatterUtils from '@/shared/formatter/formatter-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class AcreditacionesComponent extends Vue {
  @Prop({ required: false })
  public item: Array<Acreditacion>;

  get acreditaciones(): Array<Acreditacion> {
    return this.item;
  }

  get fields() {
    return [
      { key: 'activoVigente', label: 'Vigencia activo', sortable: true },
      { key: 'remunerado', label: 'Remunerado', sortable: true },
      { key: 'tipo.descripcion', label: 'Situación', sortable: true },
      { key: 'tipificacion.descripcion', label: 'Tipo de programa', sortable: true },
      { key: 'institucion.tipoInstitucionDesc', label: 'Tipo de institución', sortable: true },
      { key: 'institucion.tipoInstitucionNivelUNODesc', label: 'PÚBLICA / PRIVADA', sortable: true },
      { key: 'institucion.descripcion', label: 'Institución', sortable: true },
      { key: 'institucion.dependenciaDesc', label: 'Dependencia', sortable: true },
      { key: 'institucion.subDependenciaDesc', label: 'Sub dependencia', sortable: true },
      { key: 'institucion.departamentoDesc', label: 'Departamento', sortable: true },
      { key: 'tipoParticipacion', label: 'Tipo de participación', sortable: true },
      {
        key: 'vigencia.fechaInicio',
        label: 'Fecha de inicio',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      {
        key: 'vigencia.fechaFin',
        label: 'Fecha de fin',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      { key: 'estadoSolicitud', label: 'Estado solicitud', sortable: true },
      {
        key: 'documento.idContentManagment',
        label: 'Documento',
        sortable: true,
        link: true,
        formatter: value => formatterUtils.legacyLinkFormatter(value),
      },
      {
        key: 'informe.fechaCreacion',
        label: 'Fecha de firma del informe mensual',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
    ];
  }
}
