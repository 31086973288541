var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          attrs: { variant: _vm.downloadState },
          on: {
            click: function ($event) {
              return _vm.downloadLayout()
            },
          },
        },
        [
          _vm.isDownloading
            ? _c("b-icon", {
                attrs: { animation: "cylon-vertical", icon: "download" },
              })
            : _c("b-icon", { attrs: { icon: "file-earmark-spreadsheet" } }),
          _vm._v(" "),
          _vm.isDownloading
            ? _c("span", [
                _c("em", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "apeironGwApp.movimiento.bulk.operations.downloading"
                      )
                    )
                  ),
                ]),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.$t("entity.action.export")))]),
        ],
        1
      ),
      _vm._v(" "),
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "dummyDownloadLink",
        attrs: { href: "", download: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }