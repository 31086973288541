import { render, staticRenderFns } from "./evaluaciones-individuales.vue?vue&type=template&id=74ecfb8c&scoped=true&"
import script from "./evaluaciones-individuales.componet.ts?vue&type=script&lang=ts&"
export * from "./evaluaciones-individuales.componet.ts?vue&type=script&lang=ts&"
import style0 from "./evaluaciones-individuales.vue?vue&type=style&index=0&id=74ecfb8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ecfb8c",
  null
  
)

export default component.exports