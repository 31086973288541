import { IPersona, Persona } from '@/shared/model/persona/persona.model';
import { Domicilio } from '@/shared/model/persona/domicilio.model';

const baseApiUrl = 'services/historicopersonasms/api/expedientes';
const MEDIO_CONTACTO_TIPO_CORREO = '1';
const MEDIO_CONTACTO_TIPO_CORREO_SACI = 'COR';
const COMMA = ',';
const BLANK = '';
const ESPACIO = ' ';
const NUMERO_EXTERIOR = 'ext.';
const NUMERO_INTERIOR = 'int.';
const CODIGO_POSTAL = 'C.P.';

/**
 * Función que convierte del Objeto Persona en Rizoma
 * al Objeto Persona en Ápeiron
 *
 * Todas las propiedades que no existen en Rizoma pero
 * si en el modelo de Persona de Ápeiron se inicializan
 * con cadena vacía '' utilizando la constante BLANK.
 *
 * Para completar las propiedades que son inicializadas
 * con BLANK se deberá de hacer una consulta al servicio
 * de CVU proviniente de un sistema legado
 *
 */
export function toPersona(from: any): IPersona {
  const to = new Persona();

  if (!from) {
    return to;
  }

  to.cvu = from.cvu;
  to.login = from.login;
  to.titulo = BLANK;
  to.gradoAcademico = BLANK;
  to.nivelAcademico = BLANK;
  to.paisResidencia = BLANK;
  to.paisResidenciaCodigo = BLANK;
  to.rcea = BLANK;

  if (from.principal) {
    to.curp = from.principal.curp;
    to.nombre = from.principal.nombre;
    to.apellidoPaterno = from.principal.primerApellido;
    to.apellidoMaterno = from.principal.segundoApellido;
    to.rfc = from.principal.rfc;
    to.correo = resolveCorreo(from.principal.mediosContacto);
    to.genero = from.principal.sexo?.nombre ?? null;
    to.fotografiaUri = from.principal.fotografia?.uri ?? null;
    to.fechaNacimiento = from.principal.fechaNacimiento;
  }

  if (from.principal.nacionalidad) {
    to.nacionalidad = from.principal.nacionalidad.nombre;
    to.nacionalidadCodigo = from.principal.nacionalidad.id;
  }

  if (from?.principal?.domicilio) {
    to.domicilios = resolveDomicilio(from.principal.domicilio);
    to.paisResidencia = resolvePaisResidencia(from.principal.domicilio);
    to.paisResidenciaCodigo = resolveResidenciaCodigo(from.principal.domicilio);
  }
  to.nivelsni = from.nivelSni?.nombre ?? null;
  return to;
}

/**
 * Función que copia las propiedades de titulo, gradoAcademico, nivelAcademico,
 * paisResidencia, paisResidenciaCodigo
 *
 */
export function mergePersona(fromRizoma: IPersona, fromCvu: IPersona): IPersona {
  if (!fromCvu) {
    return fromRizoma;
  }

  fromRizoma.titulo = fromCvu.titulo ?? BLANK;
  fromRizoma.gradoAcademico = fromCvu.gradoAcademico ?? BLANK;
  fromRizoma.nivelAcademico = fromCvu.nivelAcademico ?? BLANK;
  fromRizoma.nivelsni = fromRizoma.nivelsni ?? fromCvu.nivelsni;
  fromRizoma.rcea = fromCvu.rcea ?? BLANK;

  return fromRizoma;
}

/**
 * Función que convierte el objeto Domicilio de Rizoma a una cadena de texto que tiene la estructura siguiente:
 *
 * {{ nombreVialidad }} {{ exteriorNumerico }} int. {{ interiorNumerico }},
 * C.P. {{ codigoPostal }}, {{ descripcion }}, {{ estado.nombre }},
 * {{ municipio.nombre }}, {{ localidad.nombre }}
 */
function resolveDomicilio(domicilioRizoma: any): any[] {
  if (!domicilioRizoma) {
    return [];
  }

  const builder = [];
  if (domicilioRizoma.nombreVialidad) {
    builder.push(domicilioRizoma.nombreVialidad);
  }

  if (domicilioRizoma.exteriorNumerico) {
    builder.push(NUMERO_EXTERIOR);
    builder.push(domicilioRizoma.exteriorNumerico + COMMA);
  }

  if (domicilioRizoma.interiorNumerico) {
    builder.push(NUMERO_INTERIOR);
    builder.push(domicilioRizoma.interiorNumerico + COMMA);
  }

  if (domicilioRizoma.codigoPostal) {
    builder.push(CODIGO_POSTAL);
    builder.push(domicilioRizoma.codigoPostal + COMMA);
  }

  if (domicilioRizoma.descripcion) {
    builder.push(domicilioRizoma.descripcion + COMMA);
  }

  if (domicilioRizoma.localidad?.nombre) {
    builder.push(domicilioRizoma.localidad.nombre + COMMA);
  }

  if (domicilioRizoma.municipio?.nombre) {
    builder.push(domicilioRizoma.municipio.nombre + COMMA);
  }
  if (domicilioRizoma.estado?.nombre) {
    builder.push(domicilioRizoma.estado.nombre);
  }

  const domicilio = new Domicilio();
  domicilio.direccion = builder.join(ESPACIO);
  domicilio.tipo = 'PRINCIPAL';
  return [domicilio];
}

function resolvePaisResidencia(domicilioRizoma: any): string {
  if (!domicilioRizoma) {
    return BLANK;
  }

  if (domicilioRizoma.tipoUbicacion && domicilioRizoma.tipoUbicacion === 'NACIONAL') {
    return 'México';
  }

  return BLANK;
}
function resolveResidenciaCodigo(domicilioRizoma: any): string {
  if (!domicilioRizoma) {
    return BLANK;
  }

  if (domicilioRizoma.tipoUbicacion && domicilioRizoma.tipoUbicacion === 'NACIONAL') {
    return 'MX';
  }

  return BLANK;
}

/**
 * Función que recupera el contacto principal que sea correo electrónico capturado en Rizoma.
 * En caso de que no exista un contacto principal y que a su vez sea correo electŕonico,
 * entonces se regresa el primer correo electŕonico que se encuentre.
 * De otro modo, se regresa una cadena vacía
 *
 */
function resolveCorreo(mediosContacto: any[]): string {
  if (mediosContacto?.length) {
    const allMails = mediosContacto.filter(
      contacto => contacto.tipo.id === MEDIO_CONTACTO_TIPO_CORREO || contacto.tipo.id === MEDIO_CONTACTO_TIPO_CORREO_SACI
    );
    const mainMails = allMails.filter(contacto => contacto.principal);

    if (mainMails?.length) {
      return mainMails[0].valor;
    }
    if (allMails?.length) {
      return allMails[0].valor;
    }
  }
  return '';
}
