import { GenericaDto, IGenericaDto } from '@/shared/model/genericaDto.model';

export interface ICargoDto {
  cvu?: string;
  nombre?: string;
  primerApellido?: string;
  segundoApellido?: string;
  nombreCargo?: string;
  rol?: IGenericaDto;
  email?: string;
}

export class CargoDto implements ICargoDto {
  constructor(
    public cvu?: string,
    public nombre?: string,
    public primerApellido?: string,
    public segundoApellido?: string,
    public nombreCargo?: string,
    public rol?: IGenericaDto,
    public email?: string
  ) {
    this.rol = this.rol ? this.rol : new GenericaDto();
  }
}
