var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.avisos, function (aviso, index) {
        return _c("message", {
          key: index,
          attrs: {
            variant: aviso.tipo ? aviso.tipo.toLowerCase() : "",
            title: aviso.titulo,
            description: aviso.descripcion,
            footer: aviso.pieDePagina,
          },
        })
      }),
      _vm._v(" "),
      _vm.mock
        ? _c(
            "div",
            [
              _c(
                "b-card",
                [
                  _vm._l(_vm.proceso.estados, function (estado, i) {
                    return [
                      _c(
                        "b-card-header",
                        {
                          key: i,
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { block: "", variant: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggle(i)
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "view-list" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "archeApp.EstadoSolicitud." +
                                        estado.nombre
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          key: "collapse" + i,
                          attrs: {
                            id: "accordion-transiciones-" + i,
                            accordion: "transiciones",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c("b-card-body", [
                            _c(
                              "div",
                              { staticClass: "m-2 text-center" },
                              _vm._l(
                                estado.transiciones,
                                function (transicion, index) {
                                  return _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.confirmar-modal",
                                          modifiers: {
                                            "confirmar-modal": true,
                                          },
                                        },
                                      ],
                                      key: index,
                                      staticClass: "m-2",
                                      attrs: {
                                        disabled: _vm.status,
                                        id: "transicion-" + index,
                                        variant: _vm.resolveVariant(
                                          transicion.accion
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.prepareTransicion(
                                            transicion
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: _vm.resolveIcon(
                                            transicion.accion
                                          ),
                                          animation: _vm.status
                                            ? "spin-pulse"
                                            : "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.status
                                              ? "Procesando..."
                                              : _vm.$t(
                                                  "apeironGwApp.TipoAccion." +
                                                    transicion.accion
                                                )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m-2 text-center" },
                              [
                                _c("permisos", {
                                  attrs: { readonly: _vm.mock },
                                  model: {
                                    value: estado.permisos,
                                    callback: function ($$v) {
                                      _vm.$set(estado, "permisos", $$v)
                                    },
                                    expression: "estado.permisos",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-center" },
            _vm._l(_vm.transiciones, function (transicion, index) {
              return _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.confirmar-modal",
                      modifiers: { "confirmar-modal": true },
                    },
                  ],
                  key: index,
                  staticClass: "m-2",
                  attrs: {
                    disabled: _vm.status,
                    block: _vm.transiciones.length === 1,
                    id: "transicion-" + index,
                    variant: _vm.resolveVariant(transicion.accion),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.prepareTransicion(transicion)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.resolveIcon(transicion.accion),
                      animation: _vm.status ? "spin-pulse" : "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.status
                          ? "Procesando..."
                          : _vm.$t(
                              "apeironGwApp.TipoAccion." + transicion.accion
                            )
                      )
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          id: "confirmar-modal",
          question: _vm.question,
          check: _vm.check,
          addComentario: _vm.addComentario,
        },
        on: { confirmed: _vm.confirmTransition },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }