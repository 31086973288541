export function legacyLinkFormatter(value) {
  if (typeof value == 'string' && value.includes('http')) {
    return value;
  } else {
    return `https://miic.conacyt.mx/generador-api/getPdf?idDocumentoCMS=${value}`;
  }
}

export function vigenciaConvenioUrlResolver(value, key, item) {
  let url = null;
  if (item?.urlConvenio) {
    url = item.urlConvenio;
  }

  if (item?.urlConvenioExtension) {
    url = item.urlConvenioExtension;
  }
  return url;
}

export function nombreAyudante(value, key, item) {
  if (item?.persona) {
    return item.persona.nombre + ' ' + item.persona.apellidoPaterno + ' ' + item.persona.apellidoMaterno;
  }

  return 'sin nombre';
}
