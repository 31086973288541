var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fecha-ejecucion-from-group-id",
                        label: _vm.$t(
                          "apeironGwApp.EstatusJob.form.lastExecutionDate"
                        ),
                      },
                    },
                    [
                      _vm.status
                        ? _c("b-skeleton")
                        : _c(
                            "p",
                            {
                              staticClass:
                                "font-weight-bolder text-rizoma text-center",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("timeElapsed")(
                                    _vm.lastExecution.fechaRegistro
                                  )
                                )
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "estado-ejecucion-from-group-id",
                        label: _vm.$t(
                          "apeironGwApp.EstatusJob.form.statusExecution"
                        ),
                      },
                    },
                    [
                      _vm.status
                        ? _c("b-skeleton")
                        : _c("p", {
                            staticClass:
                              "font-weight-bolder text-rizoma text-center",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "apeironGwApp.EstatusJob." +
                                    _vm.lastExecution.estatusJob
                                )
                              ),
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.showLastExecution
                ? _c("b-link", { on: { click: _vm.handleShowLastExecution } }, [
                    _vm._v(
                      _vm._s(_vm.$t("apeironGwApp.pago.padron.execute.last"))
                    ),
                  ])
                : _c("b-link", { on: { click: _vm.handleShowLastExecution } }, [
                    _vm._v(
                      _vm._s(_vm.$t("apeironGwApp.pago.padron.execute.actual"))
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }