import { IComentario, Comentario } from '@/shared/model/comentario.model';
import { TipoComentario } from '@/shared/model/enumerations/tipo-comentario.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import LoginService from '@/account/login.service';
import { required } from 'vuelidate/lib/validators';

import { mixins } from 'vue-class-component';
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import JhiDataUtils from '@/shared/data/data-utils.service';

import ComentarioService from '@/shared/comentario/comentario.service';
import AlertService from '@/shared/alert/alert.service';
import { Filtro } from '@/shared/model/filtro.model';

const validations: any = {
  comentario: {
    usuarioId: {},
    text: {
      required,
    },
    tipo: {},
    fechaCreacion: {},
    leido: {},
    rol: {},
  },
};
@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class ComentarioComponent extends mixins(JhiDataUtils) {
  @Inject('comentarioService') private comentarioService: () => ComentarioService;
  @Inject('alertService') private alertService: () => AlertService;

  @Prop({ required: true })
  public solicitudId: string;

  @Prop({ default: false })
  public listMessages: boolean;

  @Prop({ default: true })
  public addComments: boolean;

  public tipoComentarioValues: string[] = Object.keys(TipoComentario);

  private removeId: string = null;
  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'fechaCreacion';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = {};

  public isCommentDialogActivated = false;

  public comentarios: IComentario[] = [];
  public comentario: IComentario = new Comentario();

  public isFetching = false;

  public currentElement = '';

  public filtro = new Filtro();

  public mounted(): void {
    this.retrieveAllComentarios();
  }

  public get username(): string {
    return this.$store.getters.account?.login ?? '';
  }

  public agregarComentarioHandler() {
    this.comentario.usuarioId = this.username;
    this.comentario.tipo = TipoComentario.ADECUAR_DATOS_SOLICITUD;
    this.comentario.rol = RolAutoridad.OPERADOR;
    this.comentario.solicitudId = this.solicitudId;
    this.comentarioService()
      .create(this.comentario)
      .then(res => {
        this.comentarios.unshift(res);
        this.comentario = new Comentario();
      });
  }

  public isActive(tabKey) {
    return this.currentElement === tabKey;
  }

  public selectElement(tabKey) {
    this.currentElement = tabKey;
  }

  public clear(): void {
    this.page = 1;
    this.links = {};
    this.infiniteId += 1;
    this.comentarios = [];
    this.retrieveAllComentarios();
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.comentarios = [];
    this.retrieveAllComentarios();
  }

  public retrieveAllComentarios(): void {
    this.isFetching = true;
    this.filtro.solicitudId = this.solicitudId;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
      filter: this.filtro,
    };
    this.comentarioService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              this.comentarios.push(res.data[i]);
            }
            if (res.headers && res.headers['link']) {
              this.links = this.parseLinks(res.headers['link']);
            }
          }
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
          if (<any>this.$refs.infiniteLoading) {
            (<any>this.$refs.infiniteLoading).stateChanger.loaded();
            if (JSON.stringify(this.links) !== JSON.stringify({}) && this.page > this.links['last']) {
              (<any>this.$refs.infiniteLoading).stateChanger.complete();
            }
          }
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public handleSyncList(): void {
    this.clear();
  }

  public prepareRemove(instance: IComentario): void {
    this.removeId = instance.id;
    if (<any>this.$refs.removeEntity) {
      (<any>this.$refs.removeEntity).show();
    }
  }

  public removeComentario(): void {
    this.comentarioService()
      .delete(this.removeId)
      .then(() => {
        const message = this.$t('apeironGwApp.comentario.deleted', { param: this.removeId });
        this.$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        });
        this.removeId = null;
        this.reset();
        this.closeDialog();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public loadMore($state): void {
    if (!this.isFetching) {
      this.page++;
      this.transition();
    }
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllComentarios();
  }

  public changeOrder(propOrder): void {
    this.propOrder = propOrder;
    this.reverse = !this.reverse;
    this.reset();
  }

  public closeDialog(): void {
    (<any>this.$refs.removeEntity).hide();
  }

  public toggleDialog() {
    this.isCommentDialogActivated = !this.isCommentDialogActivated;
  }
}
