var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", md: "6", lg: "3" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mt-3" },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "apeironGwApp.historicoPagos.label.cvuInvestigador"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: { size: "sm", type: "number", min: "0" },
                    on: {
                      keypress: function ($event) {
                        return _vm.utilidades.filtrarPorExpresionRegular(
                          $event,
                          _vm.utilidades.regExpNumero
                        )
                      },
                    },
                    model: {
                      value: _vm.filtroHistoricoPagos.cvuInvestigador,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.filtroHistoricoPagos,
                          "cvuInvestigador",
                          $$v
                        )
                      },
                      expression: "filtroHistoricoPagos.cvuInvestigador",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", md: "6", lg: "3" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mt-3" },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("apeironGwApp.historicoPagos.label.cvuAyudante")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: { size: "sm", type: "number", min: "0" },
                    on: {
                      keypress: function ($event) {
                        return _vm.utilidades.filtrarPorExpresionRegular(
                          $event,
                          _vm.utilidades.regExpNumero
                        )
                      },
                    },
                    model: {
                      value: _vm.filtroHistoricoPagos.cvuAyudante,
                      callback: function ($$v) {
                        _vm.$set(_vm.filtroHistoricoPagos, "cvuAyudante", $$v)
                      },
                      expression: "filtroHistoricoPagos.cvuAyudante",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", md: "6", lg: "3" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mt-3" },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "apeironGwApp.historicoPagos.label.expedienteInvestigador"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: { size: "sm", type: "number", min: "0" },
                    on: {
                      keypress: function ($event) {
                        return _vm.utilidades.filtrarPorExpresionRegular(
                          $event,
                          _vm.utilidades.regExpNumero
                        )
                      },
                    },
                    model: {
                      value: _vm.filtroHistoricoPagos.expedienteInvestigador,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.filtroHistoricoPagos,
                          "expedienteInvestigador",
                          $$v
                        )
                      },
                      expression: "filtroHistoricoPagos.expedienteInvestigador",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", md: "6", lg: "3" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mt-3" },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "apeironGwApp.historicoPagos.label.expedienteAyudante"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: { size: "sm", type: "number", min: "0" },
                    on: {
                      keypress: function ($event) {
                        return _vm.utilidades.filtrarPorExpresionRegular(
                          $event,
                          _vm.utilidades.regExpNumero
                        )
                      },
                    },
                    model: {
                      value: _vm.filtroHistoricoPagos.expedienteAyudante,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.filtroHistoricoPagos,
                          "expedienteAyudante",
                          $$v
                        )
                      },
                      expression: "filtroHistoricoPagos.expedienteAyudante",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _vm.btnBuscarShow
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.btnBuscarDisabled,
                        variant: "primary",
                        size: "sm",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleFiltros()
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.action.search")),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "icon-buscar font-weight-bold small mt-1",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.btnBuscarShow
                ? _c(
                    "b-button",
                    { attrs: { disabled: "", variant: "primary", size: "sm" } },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.process.search.searching")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon", {
                        attrs: {
                          icon: "arrow-clockwise",
                          animation: "spin",
                          "font-scale": "1.3",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.btnBuscarShow || _vm.btnBuscarDisabled,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.limpiarFiltros()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.clean")),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.historicoPagos?.length > 0
            ? _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btnExcelShow,
                          expression: "btnExcelShow",
                        },
                      ],
                      attrs: { variant: "outline-success" },
                      on: {
                        click: function ($event) {
                          return _vm.descargarReporteExcel()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "file-earmark-spreadsheet" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("entity.action.export"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.btnExcelShow,
                          expression: "!btnExcelShow",
                        },
                      ],
                      attrs: { disabled: "", variant: "outline-success" },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "arrow-clockwise",
                          animation: "spin",
                          "font-scale": "1.3",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "apeironGwApp.movimiento.bulk.operations.downloading"
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.historicoPagos?.length > 0
        ? _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.isSearching,
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c("b-table", {
                        staticClass: "text-nowrap mt-3",
                        attrs: {
                          responsive: "",
                          borderless: "",
                          "show-empty": "",
                          small: "",
                          "head-variant": "primary",
                          items: _vm.historicoPagos,
                          fields: _vm.fields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row-details",
                              fn: function () {
                                return [
                                  _c(
                                    "b-card",
                                    [
                                      _vm.currentRowSelected
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                _vm._l(
                                                  _vm.currentRowSelected,
                                                  function (selected) {
                                                    return _c(
                                                      "b-form-group",
                                                      {
                                                        key: selected.prop,
                                                        attrs: {
                                                          "label-cols": "1",
                                                          "content-cols": "6",
                                                          "label-align": "left",
                                                          label: selected.prop,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                          },
                                                          [
                                                            _c(
                                                              "b-col",
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        selected.valor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            selected,
                                                                            "valor",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "selected.valor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head()",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-3 mr-3 text-center" },
                                    [
                                      _c("b-col", { staticClass: "p-0" }, [
                                        _vm._v(_vm._s(data.label)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(cuentaCLABE)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-row",
                                    { staticStyle: { width: "13rem" } },
                                    [_c("b-col", [_vm._v(_vm._s(data.value))])],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(monto)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-row",
                                    { staticStyle: { width: "10rem" } },
                                    [_c("b-col", [_vm._v(_vm._s(data.value))])],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(montoPagar)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-row",
                                    { staticStyle: { width: "10rem" } },
                                    [_c("b-col", [_vm._v(_vm._s(data.value))])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3452707614
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4" } },
                        [
                          _c("b-form-select", {
                            staticClass: "w-25",
                            attrs: { size: "sm", options: _vm.perPageOptions },
                            on: { change: _vm.handlePageSizeChange },
                            model: {
                              value: _vm.perPage,
                              callback: function ($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-2" }, [
                            _c(
                              "small",
                              { staticClass: "font-weight-bold text-muted" },
                              [_vm._v(_vm._s(_vm.$t("global.table.perPage")))]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "center",
                              size: "sm",
                              pills: "",
                            },
                            on: { change: _vm.handlePageChange },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { sm: "12", md: "12", lg: "4" },
                        },
                        [
                          _c("p", [
                            _c(
                              "small",
                              { staticClass: "text-muted font-weight-bold" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("global.table.totalRows", {
                                      totalRows: _vm.totalRows,
                                    })
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }