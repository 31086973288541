import { render, staticRenderFns } from "./programa-integrado-con.vue?vue&type=template&id=569d5a2e&scoped=true&"
import script from "./programa-integrado-con.component.ts?vue&type=script&lang=ts&"
export * from "./programa-integrado-con.component.ts?vue&type=script&lang=ts&"
import style0 from "./programa-integrado-con.vue?vue&type=style&index=0&id=569d5a2e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569d5a2e",
  null
  
)

export default component.exports