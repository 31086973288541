var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "accordion", attrs: { role: "tablist" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                {
                  staticClass: "p-1",
                  attrs: { "header-tag": "header", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.accordion-1",
                          modifiers: { "accordion-1": true },
                        },
                      ],
                      attrs: { block: "", variant: "light" },
                    },
                    [
                      _c("h5", {
                        staticClass: "mb-0",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.claustroSnp.tituloRegistro")
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "accordion-1",
                    accordion: "accordion-lies",
                    role: "tabpanel",
                  },
                  model: {
                    value: _vm.mostrarRegistro,
                    callback: function ($$v) {
                      _vm.mostrarRegistro = $$v
                    },
                    expression: "mostrarRegistro",
                  },
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c("claustro-registro", {
                        attrs: {
                          id: "registro-clasutro-form",
                          formModel: _vm.formModel,
                          solucion: _vm.solucion,
                          solicitud: _vm.solicitud,
                          permisos: _vm.permisos,
                        },
                        on: {
                          guardarClaustro: function ($event) {
                            return _vm.actualizarTabla()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                {
                  staticClass: "p-1",
                  attrs: { "header-tag": "header", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.accordion-2",
                          modifiers: { "accordion-2": true },
                        },
                      ],
                      attrs: { block: "", variant: "light" },
                    },
                    [
                      _c("h5", {
                        staticClass: "mb-0",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("apeironGwApp.claustroSnp.tituloListado")
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "accordion-2",
                    accordion: "accordion-lies",
                    role: "tabpanel",
                  },
                  model: {
                    value: _vm.mostrarListado,
                    callback: function ($$v) {
                      _vm.mostrarListado = $$v
                    },
                    expression: "mostrarListado",
                  },
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("claustro-listado", {
                            attrs: {
                              id: "listado-clasutro-form",
                              formModel: _vm.formModel,
                              solucion: _vm.solucion,
                              solicitud: _vm.solicitud,
                              permisos: _vm.permisos,
                              actualizarTabla: _vm.toggleActualizarTabla,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }