import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import type { INivel } from '@/shared/insaculacion/entity-commons/entity.factory';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import { mixins } from 'vue-class-component';
import { Expediente, HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import { mapGetters, mapMutations } from 'vuex';
import RevisionMixin from '../mixins/revision-mixin';
import { Solicitud } from '@/shared/model/solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import SolucionMixin from '../mixins/solucion-mixin';
import { NivelSni } from '@/shared/model/enumerations/niveles-sni.model';
import { NRecomendadoEnum } from '@/shared/model/enumerations/nivel-recomendado-enum.model';

import VigenciasSniComponent from '@/components/expediente-persona/vigencias-sni/vigencias-sni.vue';
import HomeMenuComponent from '@/components/home-menu/home-menu.component';

@Component({
  components: {
    'vigencias-sni': VigenciasSniComponent,
  },
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters(['evaluacion', 'revisorSeleccionado']),
  },
  methods: {
    ...mapMutations(['setDictamen', 'setEvaluacion']),
  },
})
export default class EvaluacionIndividualComponet extends mixins(RevisionMixin, SolucionMixin, HomeMenuComponent) {
  @Inject('historicoPersonasApi') private historicoPersonasApi: () => HistoricoPersonasApi;

  @Prop({ required: true })
  public revision;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public expediente: Expediente = {} as Expediente;

  public readonly evaluacion: INivel;

  public readonly revisorSeleccionado: number;

  public aceptada = EstadoRevision.ACEPTADA;
  public revisada = EstadoRevision.REVISADA;

  public tieneCandidato = false;

  public readonly nivelCandidato: string = '1';

  public static readonly CANCELADO = 'CANCELADO';

  public static readonly CLAVE_TIPO_EVALUACION = '0';

  public static readonly CLAVE_TIPO_EVALUACION_REVISORA = '26';

  public nivelRecomendadoNoAplicable: Array<NRecomendadoEnum> = [
    NRecomendadoEnum.NO_APROBADO,
    NRecomendadoEnum.NIVEL_CANDIDATO,
    NRecomendadoEnum.NIVEL_I,
  ];

  /**
   * Mutación del store para guardar la evaluación
   */
  setEvaluacion!: (evaluacion: INivel) => void;

  setDictamen!: (dictamen: any) => void;

  mounted(): void {
    this.get();
    this.getExpediente();
  }

  /**
   * Carga inicial del componente
   *
   * @memberof EvaluacionIndividualComponet
   */
  public get(): void {
    if (this.solicitud && this.revision) {
      if (this.revision?.respuesta?.evaluacion) {
        this.recomendado = this.revision.respuesta.evaluacion;
      } else if (this.evaluacion?.id) {
        this.recomendado = this.evaluacion;
      } else {
        this.calcularNivelAsignado();
      }
      if (this.revision?.respuesta?.resumen && !this.evaluacionIndividual) {
        this.resumen = this.revision.respuesta.resumen;
        this.calcularPrelacion();
      } else if (!this.evaluacionIndividual) {
        this.solucion = this.solucion;
        this.calcularPrelacion();
      }
    }
  }

  /**
   * Oculta el campo Candidato si tieneCandidato es igual a true
   *
   * @memberof EvaluacionIndividualComponet
   */
  @Watch('tieneCandidato')
  public esCandidato(newValue: boolean): void {
    if (newValue && this.revision.estado === this.aceptada) {
      this.nivelRecomendado = this.nivelRecomendado.slice(1);
    }
  }

  /**
   * Guarda el nivel recomendado de SNI
   *
   * @memberof EvaluacionIndividualComponet
   */
  public guardarEvaluacion(): void {
    let datosEnviar = {};
    this.setEvaluacion({});
    this.setDictamen({});
    if (this.evaluacionIndividual) {
      datosEnviar = {
        evaluacion: this.recomendado,
        dictamen: {},
        valido: false,
      };
    } else {
      datosEnviar = {
        evaluacion: this.recomendado,
        valido: false,
        resumen: this.resumen,
      };
    }
    this.guardaEvaluacion(datosEnviar);
  }

  /**
   * Obtiene el expediente del solicitante
   */
  private getExpediente() {
    this.historicoPersonasApi()
      .getExpediente(this.solicitud.solicitante.cvu)
      .then(res => {
        this.expediente = res.data;
      })
      .catch(err => {});
  }

  /**
   * Busca nivel candidato, en caso afirmativo activa bandera
   *
   * @memberof EvaluacionIndividualComponet
   */
  @Watch('expediente')
  public validaCandidato() {
    if (this.expediente?.vigenciasSni?.length > 0) {
      for (const vigencia of this.expediente.vigenciasSni) {
        if (vigencia.nivelSni.clave === 'C' && vigencia.estatusVigencia.clave !== EvaluacionIndividualComponet.CANCELADO) {
          this.tieneCandidato = true;
        }
      }
    }
  }

  /**
   * Calcula la prelación  valida el nivel SNI.
   * y limpia los datos de la evalución y dictamen al realizar cambio
   * de NIvel
   * @memberof EvaluacionIndividualComponet
   */
  public calculaPrelacionValidayCambioNivel(): void {
    if (!this.evaluacionIndividual) {
      this.calcularPrelacion();
    }
    if (this.revision.estado === this.aceptada) {
      this.validarNivel();
    }
    this.cambioNivel();
  }

  /**
   * Valida el nivel recomendado y el historial de niveles SNI de un solicitante.
   *
   *  @memberof EvaluacionIndividualComponet
   */
  public validarNivel() {
    if (this.nivelRecomendadoNoAplicable.includes(this.recomendado.id as NRecomendadoEnum)) {
      return (this.validaNiveles = false);
    }

    return this.historicoPersonasApi()
      .getExpediente(this.solicitud.solicitante.cvu)
      .then(res => {
        if (res?.data?.vigenciasSni?.length > 0) {
          // Contador cuando se seleccione Nivel II, se buscara la clave nivelSni === '1'
          let countN2 = 0;
          // Contador cuando se seleccione Nivel III, se buscara la clave nivelSni === '2'
          let countN3 = 0;

          for (const vigencia of res.data.vigenciasSni) {
            if (this.recomendado.id === NRecomendadoEnum.NIVEL_II && vigencia.nivelSni.clave === NivelSni.NIVEL_I) {
              countN2++;
            }
            if (this.recomendado.id === NRecomendadoEnum.NIVEL_III && vigencia.nivelSni.clave === NivelSni.NIVEL_II) {
              countN3++;
            }
          }

          if (countN2 < 2 && countN3 < 2) {
            return (this.validaNiveles = true);
          }
        }
        return (this.validaNiveles = false);
      })
      .catch(err => {});
  }

  /**
   *
   * Esta función crea un objeto con datos de evaluación, dictamen
   *  y la variable 'valido' con estado 'false',
   *  @memberof EvaluacionIndividualComponet
   */
  public cambioNivel(): void {
    let datosEnviar = {};
    datosEnviar = {
      evaluacion: {},
      dictamen: {},
      valido: false,
    };
    this.setDictamen({});
    this.guardaEvaluacion(datosEnviar);
  }
}
