var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _vm.formularioRequerido
            ? _c("h5", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("apeironGwApp.programa.obligatorio")
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.filtro.coordinadorCvu
            ? _c("programa-select", {
                attrs: { filtro: _vm.filtro },
                model: {
                  value: _vm.form.programa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "programa", $$v)
                  },
                  expression: "form.programa",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.programa?.id
            ? _c("programa-resumen", { attrs: { programa: _vm.form.programa } })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.programa?.id
            ? _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save(_vm.form)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.save")),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-icon", { attrs: { icon: "save" } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }