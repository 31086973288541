import { Component, Inject, Vue } from 'vue-property-decorator';
import { FiltroAutoridad, IFiltroAutoridad } from '@/shared/model/filtroAutoridad.model';

import InstitucionService from './institucion.service';
import AlertService from '@/shared/alert/alert.service';
import { CatInstD05152020194521000, CatInstD05152020194609000, CatInstS05152020194703000, SaciApi } from '@conacyt/saci-client';

import { NuevaAutoridadInst, INuevaAutoridadInst } from '@/shared/model/NuevaAutoridadInst.model';
import { IInstitucionDto, InstitucionDto } from '@/shared/model/institucion.model';

import { ICargoDto, CargoDto } from '@/shared/model/cargo.model';
import { TipoAutoridad } from '@/shared/model/enumerations/tipo-autoridad.model';

import { TipoGuardadoInstitucion } from '@/shared/model/enumerations/tipo-guardado-institucion.model';
import { NivelNodoInstitucion } from '@/shared/model/enumerations/nivel-nodo-institucion.model';
import { IDependencia, Dependencia } from '@/shared/model/dependencia.model';
import { ISubDependencia, SubDependencia } from '@/shared/model/sub-dependencia.model';
import { IDepartamento, Departamento } from '@/shared/model/departamento.model';
import { ISubNivelInstitucion, SubNivelInstitucion } from '@/shared/model/subnivel-institucion.model';
import PersonaService from '@/shared/persona/persona.service';

@Component({})
export default class Institucion extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('institucionService') public institucionService: () => InstitucionService;
  @Inject('saciApi') public saciApi: () => SaciApi;
  @Inject('personaService') public personaService: () => PersonaService;

  public estadosMexico: any[] = [];
  public instituciones: IInstitucionDto[] = [];
  public rolOcupado = false;
  public showNuevoSubNivelModalFlag = false;
  public maxCaracteres = 14;

  public campos = Object.values(this.$t(`apeironGwApp.institucion.headersTabla`));
  public filtroInstitucion: IFiltroAutoridad = new FiltroAutoridad();
  public autoridad: ICargoDto = new CargoDto();

  public nuevaAutoridad: INuevaAutoridadInst = new NuevaAutoridadInst();
  public nuevoSubNivel: ISubNivelInstitucion = new SubNivelInstitucion();

  public rolAutoridad: any[] = [
    {
      descripcion: this.$t(`apeironGwApp.institucion.tipoAutoridad.AUTORIDAD_FACULTADA`),
      id: TipoAutoridad.AUTORIDAD_FACULTADA,
    },
    {
      descripcion: this.$t(`apeironGwApp.institucion.tipoAutoridad.AUTORIDAD_SUPLENTE`),
      id: TipoAutoridad.AUTORIDAD_SUPLENTE,
    },
    {
      descripcion: this.$t(`apeironGwApp.institucion.tipoAutoridad.ENLACE_INSTITUCIONAL`),
      id: TipoAutoridad.ENLACE_INSTITUCIONAL,
    },
  ];
  public nivelGuardado: any[] = [
    { id: TipoGuardadoInstitucion.POSICION_ACTUAL, descripcion: this.$t('apeironGwApp.institucion.modalNuevaAutoridad.posicionActual') },
    {
      id: TipoGuardadoInstitucion.POSICION_ACTUAL_Y_NIVELES,
      descripcion: this.$t('apeironGwApp.institucion.modalNuevaAutoridad.actualSubNiveles'),
    },
  ];

  public itemsPerPage = 30;
  public page = 1;
  public pageSaci = 1;
  public totalItems = 0;
  public isSaving = false;
  public mostrarPaginacion = false;
  public isGuardando = false;
  public isSaci = false;
  public institucionActual: IInstitucionDto = new InstitucionDto();
  public dependenciaActual: IDependencia = new Dependencia();
  public subDependenciaActual: ISubDependencia = new SubDependencia();
  public departamentoActual: IDepartamento = new Departamento();
  private UNIX_TIME = 'unixTime';
  public botonEliminarDependeciaDisabled = false;
  public botonEliminarSubDependeciaDisabled = false;
  public botonEliminarDepartamentoDisabled = false;

  public pageInstitucion = {
    total_pages: null,
    page: 1,
    currentPage: 1,
    items: null,
    perPage: this.itemsPerPage,
    size: this.itemsPerPage,
    sort: 'id,desc',
  };

  public pageDependencia = {
    total_pages: null,
    page: 1,
    currentPage: 1,
    items: null,
    perPage: this.itemsPerPage,
    institucion: null,
  };

  public pageSubDependencia = {
    total_pages: null,
    page: 1,
    currentPage: 1,
    items: null,
    perPage: this.itemsPerPage,
    institucion: null,
    dependencia: null,
  };

  public pageDepartamento = {
    total_pages: null,
    page: 1,
    currentPage: 1,
    items: null,
    perPage: this.itemsPerPage,
    institucion: null,
    subDependencia: null,
  };

  mounted() {
    this.getEstados();
  }

  public clean(): void {
    this.filtroInstitucion = new FiltroAutoridad();
    this.instituciones = null;
    this.page = 1;

    this.pageInstitucion = {
      total_pages: null,
      page: 1,
      currentPage: 1,
      items: null,
      perPage: this.itemsPerPage,
      size: this.itemsPerPage,
      sort: ' id , desc ',
    };
  }
  public getEstados(): void {
    this.isSaving = true;

    const page = 1;
    const registros = 32;

    this.saciApi()
      .getAreaGeoestadisticaEstatal(page, registros)
      .then(res => {
        this.estadosMexico = <any[]>res.data;
        this.isSaving = false;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
        this.isSaving = false;
      });
  }

  get isDisabled(): boolean {
    return !(
      this.filtroInstitucion.nombre ||
      this.filtroInstitucion.apellidoPaterno ||
      this.filtroInstitucion.apellidoMaterno ||
      this.filtroInstitucion.cvu ||
      this.filtroInstitucion.estado ||
      this.filtroInstitucion.institucion
    );
  }

  get disabledEstados(): boolean {
    return !this.filtroInstitucion.institucion;
  }

  public buscarAutoridadesEnApeiron(): void {
    this.isSaving = true;
    const paginationQuery = { ...this.pageInstitucion };

    this.isSaci = false;
    this.instituciones = null;
    this.institucionService()
      .buscarAutoridad(paginationQuery, this.filtroInstitucion)
      .then(res => {
        this.instituciones = res;
        this.pageInstitucion.items = res.length;
        this.pageInstitucion.perPage = this.totalItems;
        if (this.pageInstitucion.items > this.pageInstitucion.perPage) {
          this.mostrarPaginacion = true;
        } else {
          this.mostrarPaginacion = false;
        }
        this.isSaving = false;
        if (this.instituciones == null || this.instituciones.length == 0) {
          this.mensajeSinInformacion();
        }
      })
      .catch(error => {
        if (error.response.status == 404) {
          this.mensajeSinInformacion();
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.isSaving = false;
      });
  }

  public mensajeSinInformacion(): void {
    const message = this.$t('apeironGwApp.institucion.mensaje.datoNoEncontrado');
    const title = this.$t('apeironGwApp.institucion.mensaje.tituloInfo');
    this.alertService().showToaster(this, title.toString(), message.toString(), 'success');
  }

  public showNuevaAutoridad(): void {
    this.cleanNuevaAutoridad();
    (<any>this.$refs.autoridadModal).show();
  }

  public showNuevoAutoridad(inst?: any, dep?: any, subd?: any, depart?: any): void {
    this.nuevaAutoridad = new NuevaAutoridadInst();
    if (inst && inst.nombre) {
      this.nuevaAutoridad.institucion = inst;
    }

    if (dep && dep.nombre) {
      this.nuevaAutoridad.dependencia = dep;
    }

    if (subd && subd.nombre) {
      this.nuevaAutoridad.subDependencia = subd;
    }

    if (depart && depart.nombre) {
      this.nuevaAutoridad.departamento = depart;
    }
    (<any>this.$refs.autoridadModal).show();
  }

  public showNuevoSubNivelModal(inst?: any, dep?: any, subd?: any): void {
    this.nuevoSubNivel = new SubNivelInstitucion();
    this.nuevoSubNivel.idEntidad = null;

    this.nuevoSubNivel.idInstitucion = inst?.id;
    this.nuevoSubNivel.descInstitucion = inst?.nombre;
    this.nuevoSubNivel.idInstDependencia = dep?.id;
    this.nuevoSubNivel.descInstDependencia = dep?.nombre;
    this.nuevoSubNivel.idInstSubdependencia = subd?.id;
    this.nuevoSubNivel.descInstSubdependencia = subd?.nombre;

    this.showNuevoSubNivelModalFlag = true;
  }

  public buscarCVU(): void {
    this.isSaving = true;
    this.personaService()
      .findByCvu(this.autoridad.cvu)
      .then(res => {
        this.autoridad.nombre = res.nombre;
        this.autoridad.primerApellido = res.apellidoPaterno;
        this.autoridad.segundoApellido = res.apellidoMaterno;
        this.autoridad.cvu = res.cvu;
        this.autoridad.email = res.login;

        this.isSaving = false;
      })
      .catch(error => {
        if (error.response.status == 404) {
          this.mensajeNotFound();
          this.cleanNuevaAutoridad();
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.isSaving = false;
      });
  }

  public cleanNuevaAutoridad(): void {
    this.nuevaAutoridad.autoridad = new CargoDto();
    this.autoridad = new CargoDto();
    this.rolOcupado = false;
  }

  public hideModalNuevaAutoridad(): void {
    this.cleanNuevaAutoridad();
    (<any>this.$refs.autoridadModal).hide();
  }

  public mostrarModalConfirmacion(): void {
    (<any>this.$refs.confirmModal).show();
  }

  public confirmAction(): void {
    this.guardarNuevaAutoridad();
    (<any>this.$refs.confirmModal).hide();
  }

  public cancelAction(): void {
    (<any>this.$refs.confirmModal).hide();
  }

  public hideModalNuevoSubNivel(): void {
    this.showNuevoSubNivelModalFlag = false;
  }

  public mensajeNotFound(): void {
    const message = this.$t('apeironGwApp.institucion.mensaje.datoNoEncontrado');
    const title = this.$t('apeironGwApp.institucion.mensaje.tituloInfo');
    this.alertService().showToaster(this, title.toString(), message.toString(), 'warning');
  }

  public mensajeRolOcupado(texto: string): void {
    const message = this.$t('apeironGwApp.institucion.mensaje.rolOcupado', {
      param: texto,
    });
    const title = this.$t('apeironGwApp.institucion.mensaje.tituloOcupado');
    this.alertService().showToaster(this, title.toString(), message.toString(), 'warning');
  }

  public async guardarNuevaAutoridad(): Promise<void> {
    try {
      this.isGuardando = true;
      this.rolOcupado = false;

      if (this.nuevaAutoridad.departamento) {
        this.buscarYAgregarAutoridad(this.nuevaAutoridad.departamento.cargo, this.autoridad, this.nuevaAutoridad.departamento);
      } else if (this.nuevaAutoridad.subDependencia) {
        this.buscarYAgregarAutoridad(this.nuevaAutoridad.subDependencia.cargo, this.autoridad, this.nuevaAutoridad.subDependencia);
        this.nuevaAutoridad.nivelGuardado.id == TipoGuardadoInstitucion.POSICION_ACTUAL_Y_NIVELES
          ? this.agregarAutoridadEnNiveles(this.nuevaAutoridad.subDependencia.departamento, this.autoridad)
          : null;
      } else if (this.nuevaAutoridad.dependencia) {
        this.buscarYAgregarAutoridad(this.nuevaAutoridad.dependencia.cargo, this.autoridad, this.nuevaAutoridad.dependencia);
        this.nuevaAutoridad.nivelGuardado.id == TipoGuardadoInstitucion.POSICION_ACTUAL_Y_NIVELES
          ? this.agregarAutoridadEnNiveles(this.nuevaAutoridad.dependencia.subDependencia, this.autoridad)
          : null;
      } else if (this.nuevaAutoridad.institucion) {
        this.buscarYAgregarAutoridad(this.nuevaAutoridad.institucion.cargo, this.autoridad, this.nuevaAutoridad.institucion);
        this.nuevaAutoridad.nivelGuardado.id == TipoGuardadoInstitucion.POSICION_ACTUAL_Y_NIVELES
          ? this.agregarAutoridadEnNiveles(this.nuevaAutoridad.institucion.dependencia, this.autoridad)
          : null;
      }

      if (this.rolOcupado) {
        this.isGuardando = false;
        this.buscarAutoridadesEnApeiron();
        return;
      }

      this.nuevaAutoridad.autoridad = this.autoridad;
      const res = await this.institucionService().guardarNuevaAutoridad(this.nuevaAutoridad, this.autoridad.cvu);

      // El guardado se completó con éxito
      this.isSaving = false;
      this.isGuardando = false;
      this.cleanNuevaAutoridad();
      this.hideModalNuevaAutoridad();
      this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
    } catch (error) {
      if (error.response && error.response.data.errorKey === 'cvuExistente') {
        const title = this.$t('apeironGwApp.institucion.mensaje.tituloOcupado');
        const message = this.$t('apeironGwApp.institucion.mensaje.mensajeCvuExistente');
        this.alertService().showToaster(this, title.toString(), message.toString(), 'warning');
        this.buscarAutoridadesEnApeiron();
      } else {
        this.alertService().showHttpError(this, error.response);
      }
      this.isSaving = false;
      this.isGuardando = false;
    }
  }

  private agregarAutoridadEnNiveles(niveles: any[] | null, autoridad: ICargoDto): void {
    if (!niveles) return;

    niveles.forEach(nivel => {
      this.buscarYAgregarAutoridad(nivel.cargo, autoridad, nivel);
      this.agregarAutoridadEnNiveles(nivel.subDependencia, autoridad);
      this.agregarAutoridadEnNiveles(nivel.departamento, autoridad);
    });
  }

  private buscarYAgregarAutoridad(cargos: ICargoDto[] | null, autoridad: ICargoDto, nivel: any) {
    if (!cargos) {
      cargos = [];
    }

    if (cargos.some(s => s.rol.descripcion === autoridad.rol.descripcion)) {
      let nivelOcupado;
      if (nivel.departamento) {
        nivelOcupado = 'SubDepedencia: ';
      } else if (nivel.subDependencia) {
        nivelOcupado = 'Dependencia: ';
      } else if (nivel.dependencia) {
        nivelOcupado = 'Institucion: ';
      } else {
        nivelOcupado = 'departamento: ';
      }
      this.mensajeRolOcupado(`${nivelOcupado} ${nivel.nombre}`);
      this.rolOcupado = true;
    } else {
      cargos.push(autoridad);
    }
  }

  get isDisabledGuardado(): boolean {
    return !(
      this.nuevaAutoridad.nivelGuardado &&
      this.nuevaAutoridad.institucion &&
      this.autoridad.cvu &&
      this.autoridad.nombre &&
      this.autoridad.nombreCargo &&
      this.autoridad.rol
    );
  }

  get isNuevoSubNivelValido(): boolean {
    const baseValida = this.nuevoSubNivel.idInstitucion && this.nuevoSubNivel.idEntidad;
    const esDependenciaValida = baseValida && this.nuevoSubNivel.descInstDependencia;
    const esSubdependenciaValida = baseValida && this.nuevoSubNivel.idInstDependencia && this.nuevoSubNivel.descInstSubdependencia;
    const esDepartamentoValido =
      baseValida &&
      this.nuevoSubNivel.idInstDependencia &&
      this.nuevoSubNivel.idInstSubdependencia &&
      this.nuevoSubNivel.descInstDepartamento;
    return !(esDependenciaValida || esSubdependenciaValida || esDepartamentoValido);
  }

  public eliminarAutoridad(
    autoridad?: CargoDto,
    institucion?: any,
    dependencia?: any,
    subDependencia?: any,
    departamento?: any,
    nivelNodo?: string
  ): void {
    const avisoTitle = this.$t('apeironGwApp.institucion.mensaje.aviso');
    const exitoMessage = this.$t('apeironGwApp.institucion.mensaje.exito');
    this.isSaving = true;
    if (autoridad && institucion && nivelNodo && nivelNodo == NivelNodoInstitucion.NIVEL_INSTITUCION) {
      this.institucionService()
        .eliminarAutoridad(autoridad, institucion.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          institucion.cargo = institucion.cargo.filter(item => item.rol.id !== autoridad.rol.id);
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else if (autoridad && institucion && dependencia && nivelNodo && nivelNodo == NivelNodoInstitucion.NIVEL_DEPENDENCIA) {
      this.institucionService()
        .eliminarAutoridadDependencia(autoridad, institucion.id, dependencia.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          dependencia.cargo = dependencia.cargo.filter(item => item.rol.id !== autoridad.rol.id);
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else if (
      autoridad &&
      institucion &&
      dependencia &&
      subDependencia &&
      nivelNodo &&
      nivelNodo == NivelNodoInstitucion.NIVEL_SUBDEPENDENCIA
    ) {
      this.institucionService()
        .eliminarAutoridadSubDependencia(autoridad, institucion.id, dependencia.id, subDependencia.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          subDependencia.cargo = subDependencia.cargo.filter(item => item.rol.id !== autoridad.rol.id);
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else if (
      autoridad &&
      institucion &&
      dependencia &&
      subDependencia &&
      departamento &&
      nivelNodo &&
      nivelNodo == NivelNodoInstitucion.NIVEL_DEPARTAMENTO
    ) {
      this.institucionService()
        .eliminarAutoridadDepartamento(autoridad, institucion.id, dependencia.id, subDependencia.id, departamento.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          departamento.cargo = departamento.cargo.filter(item => item.rol.id !== autoridad.rol.id);
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public eliminarAutoridadArbol(
    autoridad?: CargoDto,
    institucion?: any,
    dependencia?: any,
    subDependencia?: any,
    departamento?: any,
    nivelNodo?: string
  ): void {
    const avisoTitle = this.$t('apeironGwApp.institucion.mensaje.aviso');
    const exitoMessage = this.$t('apeironGwApp.institucion.mensaje.exito');
    this.isSaving = true;
    if (autoridad && institucion && dependencia && subDependencia && nivelNodo && nivelNodo == NivelNodoInstitucion.NIVEL_SUBDEPENDENCIA) {
      this.institucionService()
        .eliminarAutoridadArbolSubDependencia(autoridad, institucion.id, dependencia.id, subDependencia.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          subDependencia.cargo = subDependencia.cargo.filter(item => item.rol.id !== autoridad.rol.id);
          subDependencia.departamento.forEach(item => {
            item.cargo = item.cargo.filter(item => item.rol.id != autoridad.rol.id);
          });
          /* this.buscarDepartamento(this.pageDepartamento.institucion, this.pageDepartamento.subDependencia); */
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else if (autoridad && institucion && dependencia && nivelNodo && nivelNodo == NivelNodoInstitucion.NIVEL_DEPENDENCIA) {
      this.institucionService()
        .eliminarAutoridadArbolDependencia(autoridad, institucion.id, dependencia.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          dependencia.cargo = dependencia.cargo.filter(item => item.rol.id !== autoridad.rol.id);
          dependencia.subDependencia.forEach(item => {
            item.cargo = item.cargo.filter(item => item.rol.id !== autoridad.rol.id);
            item.departamento.forEach(item => {
              item.cargo = item.cargo.filter(item => item.rol.id !== autoridad.rol.id);
            });
          });
          /* this.buscarSubDependencia(this.pageSubDependencia.institucion, this.pageSubDependencia.dependencia); */
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else if (autoridad && institucion && nivelNodo && nivelNodo == NivelNodoInstitucion.NIVEL_INSTITUCION) {
      this.institucionService()
        .eliminarAutoridadArbolInstitucion(autoridad, institucion.id)
        .then(res => {
          this.isSaving = false;
          this.getToaster(avisoTitle, exitoMessage, 'success');
          institucion.cargo = institucion.cargo.filter(item => item.rol.id !== autoridad.rol.id);
          institucion.dependencia.forEach(item => {
            item.cargo = item.cargo.filter(item => item.rol.id !== autoridad.rol.id);
            item.subDependencia.forEach(item => {
              item.cargo = item.cargo.filter(item => item.rol.id !== autoridad.rol.id);
              item.departamento.forEach(item => {
                item.cargo = item.cargo.filter(item => item.rol.id !== autoridad.rol.id);
              });
            });
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }
  public getToaster(title?: any, message?: any, type?: string): void {
    return this.alertService().showToaster(this, title, message, type);
  }

  public guardarNuevoSubNivel(): void {
    this.isSaving = true;
    if (this.nuevoSubNivel.descInstDepartamento) {
      this.saveDepartamento();
    } else if (this.nuevoSubNivel.descInstSubdependencia) {
      this.saveSubDependencia();
    } else if (this.nuevoSubNivel.descInstDependencia) {
      this.saveDependencia();
    }
  }

  private saveDepartamento(): void {
    const departamento: CatInstD05152020194521000 = {
      id_inst_subdependencia: this.nuevoSubNivel.idInstSubdependencia,
      desc_inst_departamento: this.nuevoSubNivel.descInstDepartamento,
      id_entidad: this.nuevoSubNivel.idEntidad,
    };

    this.isSaving = true;
    const request = {
      idInstitucion: this.nuevoSubNivel.idInstitucion,
      idDependencia: this.nuevoSubNivel.idInstDependencia,
      idSubDependencia: this.nuevoSubNivel.idInstSubdependencia,
      nombre: this.nuevoSubNivel.descInstDepartamento,
      estado: {
        descripcion: this.estadosMexico.find(estado => estado.id == this.nuevoSubNivel.idEntidad)?.nombre,
        id: this.nuevoSubNivel.idEntidad,
      },
    };

    this.institucionService()
      .guardarSubNivel(request)
      .then(res => {
        this.isSaving = false;
        const index = this.instituciones.findIndex(int => int.id === res.id);

        if (index !== -1) {
          this.instituciones[index] = res;
        }

        this.hideModalNuevoSubNivel();
        this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });

    this.saciApi()
      .createCatInstD05152020194521000(departamento)
      .then(() => {})
      .catch(error => {});
  }

  private saveSubDependencia(): void {
    const subDependencia: CatInstS05152020194703000 = {
      id_inst_dependencia: this.nuevoSubNivel.idInstDependencia,
      desc_inst_subdependencia: this.nuevoSubNivel.descInstSubdependencia,
      id_entidad: this.nuevoSubNivel.idEntidad,
    };

    this.isSaving = true;
    const request = {
      idInstitucion: this.nuevoSubNivel.idInstitucion,
      idDependencia: this.nuevoSubNivel.idInstDependencia,
      nombre: this.nuevoSubNivel.descInstSubdependencia,
      estado: {
        descripcion: this.estadosMexico.find(estado => estado.id == this.nuevoSubNivel.idEntidad)?.nombre,
        id: this.nuevoSubNivel.idEntidad,
      },
    };

    this.institucionService()
      .guardarSubNivel(request)
      .then(res => {
        this.isSaving = false;
        const index = this.instituciones.findIndex(int => int.id === res.id);

        if (index !== -1) {
          this.instituciones[index] = res;
        }

        this.hideModalNuevoSubNivel();
        this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });

    this.saciApi()
      .createCatInstS05152020194703000(subDependencia)
      .then(() => {})
      .catch(error => {});
  }

  private saveDependencia(): void {
    this.isSaving = true;
    const dependencia: CatInstD05152020194609000 = {
      id_institucion: this.nuevoSubNivel.idInstitucion,
      desc_inst_dependencia: this.nuevoSubNivel.descInstDependencia,
      id_entidad: this.nuevoSubNivel.idEntidad,
    };

    const request = {
      idInstitucion: this.nuevoSubNivel.idInstitucion,
      nombre: this.nuevoSubNivel.descInstDependencia,
      estado: {
        descripcion: this.estadosMexico.find(estado => estado.id == this.nuevoSubNivel.idEntidad)?.nombre,
        id: this.nuevoSubNivel.idEntidad,
      },
    };

    this.institucionService()
      .guardarSubNivel(request)
      .then(res => {
        this.isSaving = false;
        const index = this.instituciones.findIndex(int => int.id === res.id);

        if (index !== -1) {
          this.instituciones[index] = res;
        }

        this.hideModalNuevoSubNivel();
        this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });

    this.saciApi()
      .createCatInstD05152020194609000(dependencia)
      .then(() => {})
      .catch(error => {});
  }

  public eliminarDependencia(inst?: any, dep?: any): void {
    const request = {
      idInstitucion: inst.id,
      idDependencia: dep.id,
    };
    this.isSaving = true;

    this.validarAsociacionesNivel(request)
      .then(tieneAsociaciones => {
        if (tieneAsociaciones) {
          this.alertService().showWarning(this, 'apeironGwApp.institucion.mensaje.eliminarNivelError');
        } else {
          this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
          inst.dependencia = inst.dependencia.filter(item => item.id !== dep.id);
          this.saciApi()
            .destroycatInstD05152020194609000(request.idDependencia)
            .then(() => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
            })
            .catch(error => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
            });
        }
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });
  }

  public eliminarSubDependencia(inst?: any, dep?: any, subd?: any): void {
    const request = {
      idInstitucion: inst.id,
      idDependencia: dep.id,
      idSubDependencia: subd.id,
    };

    this.isSaving = true;

    this.validarAsociacionesNivel(request)
      .then(tieneAsociaciones => {
        if (tieneAsociaciones) {
          this.alertService().showWarning(this, 'apeironGwApp.institucion.mensaje.eliminarNivelError');
        } else {
          dep.subDependencia = dep.subDependencia.filter(item => item.id !== subd.id);
          this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
          this.saciApi()
            .destroycatInstS05152020194703000(request.idSubDependencia)
            .then(() => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
            })
            .catch(error => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
            });
        }
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });
  }

  public eliminarDepartamento(inst?: any, dep?: any, subd?: any, depart?: any): void {
    const request = {
      idInstitucion: inst.id,
      idDependencia: dep.id,
      idSubDependencia: subd.id,
      idDepartamento: depart.id,
    };
    this.isSaving = true;

    this.validarAsociacionesNivel(request)
      .then(tieneAsociaciones => {
        if (tieneAsociaciones) {
          this.alertService().showWarning(this, 'apeironGwApp.institucion.mensaje.eliminarNivelError');
        } else {
          this.alertService().showSuccess(this, 'apeironGwApp.institucion.mensaje.exito');
          subd.departamento = subd.departamento.filter(item => item.id !== depart.id);
          this.saciApi()
            .destroycatInstD05152020194521000(request.idDepartamento)
            .then(() => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
            })
            .catch(error => {
              this.isSaving = false;
              this.hideModalNuevoSubNivel();
              this.alertService().showHttpError(this, error.response);
            });
        }
      })
      .catch(error => {
        this.isSaving = false;
        this.hideModalNuevoSubNivel();
        this.alertService().showHttpError(this, error.response);
      });
  }

  public validarAsociacionesNivel(request: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.isSaving = true;
      this.institucionService()
        .validarAsociacionesInstitucion(request)
        .then(res => {
          this.isSaving = false;
          resolve(res === true);
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
          this.isSaving = false;
          reject(error);
        });
    });
  }

  public limitarLongitudCvu(): void {
    if (this.filtroInstitucion.cvu.length > this.maxCaracteres) {
      this.filtroInstitucion.cvu = this.filtroInstitucion.cvu.slice(0, this.maxCaracteres);
    }
  }

  public async cambioDePaginaInstitucion(): Promise<void> {
    // Verifica si la página cambió antes de realizar una nueva solicitud
    if (this.pageInstitucion.currentPage === this.pageInstitucion.page) {
      return;
    }

    this.pageInstitucion.currentPage = this.pageInstitucion.page;

    this.buscarAutoridadesEnApeiron();
  }
}
