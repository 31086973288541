import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { FiltroParticipacion } from '@/shared/model/filtro-participacion.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import ParticipanteService from '@/components/participantes/participante.service';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import JhiDataUtils from '@/shared/data/data-utils.service';
import * as dateUtils from '@/shared/date/date-utils';
import { StatusCodes } from 'http-status-codes';
import { mixins } from 'vue-class-component';
import { MenuTiempoDedicacionClaustro, ParticipacionSnp } from '@/entities/snp/claustro/claustro.model';
import CatalogosSnp from '@/entities/snp/catalogos/catalogos-snp.factory';

@Component({})
export default class ClaustroListadoComponent extends mixins(JhiDataUtils, ApeironComponent) {
  @Inject('participanteService') private participanteService: () => ParticipanteService;

  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({
    required: false,
    default: function () {
      return false;
    },
  })
  public actualizarTabla: boolean;

  public filtroClaustro = '';
  public claustrosFiltrados: Array<ParticipacionSnp> = [];
  public claustros: Array<ParticipacionSnp> = [];

  public mostrarModalDetalle = false;
  public mostrarModalEditar = false;
  public mostrarModalEliminar = false;

  public claustroSeleccionado: ParticipacionSnp = new ParticipacionSnp();
  public tiemposDedicacion: Array<MenuTiempoDedicacionClaustro> = [];

  public readonly fieldsClaustro = [
    {
      key: 'participante.cvu',
      label: this.$t('apeironGwApp.claustroSnp.form.cvuDocente'),
      class: 'text-left',
      thStyle: { width: '3rem' },
    },
    {
      key: 'participante',
      label: this.$t('apeironGwApp.claustroSnp.form.nombreDocente'),
      class: 'text-left',
      thStyle: { width: '15rem' },
    },
    {
      key: 'profesorSnp.tiempoDedicacion.nombre',
      label: this.$t('apeironGwApp.claustroSnp.form.tiempoDedicacion'),
      class: 'text-left',
      thStyle: { width: '15rem' },
    },
    {
      key: 'profesorSnp.claustroFechaIngreso',
      label: this.$t('apeironGwApp.claustroSnp.form.fechaIngreso'),
      class: 'text-left',
      thStyle: { width: '8rem' },
      formatter: value => dateUtils.formatUtc(value),
    },
    {
      key: 'profesorSnp.claustroFechaBaja',
      label: this.$t('apeironGwApp.claustroSnp.form.fechaBaja'),
      class: 'text-left',
      thStyle: { width: '8rem' },
      formatter: value => dateUtils.formatUtc(value),
    },
    {
      key: 'acciones',
      label: this.$t('apeironGwApp.claustroSnp.acciones.acciones'),
      class: 'text-center btn-acciones',
      thStyle: { width: '10rem' },
    },
  ];

  public perPage = 15;
  public currentPage = 1;

  public mounted(): void {
    this.iniciarComponente();
  }

  @Watch('actualizarTabla')
  private refrescarTabla(): void {
    this.iniciarComponente();
  }

  private iniciarComponente(): void {
    this.claustroSeleccionado = new ParticipacionSnp();

    this.mostrarModalDetalle = false;
    this.mostrarModalEditar = false;
    this.mostrarModalEliminar = false;

    this.tiemposDedicacion = [];
    CatalogosSnp.obtenerTiemposDedicacion().forEach(it => {
      this.tiemposDedicacion.push({ value: it, text: it.nombre });
    });

    this.obtenerProfesores();
  }

  public mostrarModalEliminarClaustro(claustro: ParticipacionSnp): void {
    this.mostrarModalEliminar = true;
    this.claustroSeleccionado = { ...claustro };
  }

  public editarClaustro() {
    if (this.claustroSeleccionado.profesorSnp?.claustroFechaBaja < this.claustroSeleccionado.profesorSnp?.claustroFechaIngreso) {
      this.alertService().showError(this, this.$t('apeironGwApp.claustroSnp.modal.detalleErrorEnFechas').toString());
    } else {
      if (!this.readOnly) {
        this.participanteService()
          .update(this.claustroSeleccionado)
          .then(() => {
            this.mostrarModalEditar = false;
          })
          .catch(error => {
            this.alertService().showHttpError(this, error.response);
          })
          .finally(() => {
            this.obtenerProfesores();
          });
      } else {
        this.mostrarModalEditar = false;
      }
    }
  }

  public eliminarClaustro(): void {
    if (!this.readOnly) {
      this.participanteService()
        .delete(this.claustroSeleccionado.id)
        .then(() => {
          this.mostrarModalEliminar = false;
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
        })
        .finally(() => {
          this.obtenerProfesores();
        });
    } else {
      this.mostrarModalEliminar = false;
    }
  }

  public mostrarModalEditarClaustro(claustro: ParticipacionSnp): void {
    this.mostrarModalEditar = true;
    this.claustroSeleccionado = JSON.parse(JSON.stringify(claustro));
  }

  public mostrarModalDetalleClaustro(claustro: ParticipacionSnp): void {
    this.mostrarModalDetalle = true;
    this.claustroSeleccionado = claustro;
  }

  public buscarEnClaustro(): void {
    this.claustrosFiltrados = [];

    this.claustros.forEach(claustro => {
      if (this.filtroClaustro) {
        if (
          claustro.participante?.nombre?.toLowerCase().includes(this.filtroClaustro.toLowerCase()) ||
          claustro.participante?.apellidoPaterno?.toLowerCase().includes(this.filtroClaustro.toLowerCase()) ||
          claustro.participante?.apellidoMaterno?.toLowerCase().includes(this.filtroClaustro.toLowerCase()) ||
          claustro.profesorSnp?.tiempoDedicacion?.nombre?.toLowerCase().includes(this.filtroClaustro.toLowerCase())
        ) {
          this.claustrosFiltrados.push(claustro);
        }
      } else {
        this.claustrosFiltrados.push(claustro);
      }
    });
  }

  public obtenerProfesores(): void {
    const filtroPart: FiltroParticipacion = {};
    filtroPart.solicitudId = this.solicitud.id;
    filtroPart.rol = RolAutoridad.PROFESOR;
    const paginationQuery = {
      page: 0,
      size: 200,
      sort: 'asc.id',
      filter: filtroPart,
    };
    this.participanteService()
      .retrieve(paginationQuery)
      .then(
        res => {
          this.claustros = [];
          res.data.forEach(item => {
            this.claustros.push(ParticipacionSnp.fromObject(item));
          });

          this.buscarEnClaustro();
        },
        err => {
          if (err.response.data.status !== StatusCodes.NOT_FOUND) {
            this.alertService().showHttpError(this, err.response);
          }
        }
      );
  }

  get registros(): number {
    return this.claustrosFiltrados?.length;
  }

  get tiempoDedicacionInfo(): string {
    return this.claustroSeleccionado?.profesorSnp?.tiempoDedicacion?.nombre;
  }

  get institucionInfo(): string {
    return this.claustroSeleccionado?.profesorSnp?.institucion?.nombreInstitucion;
  }

  get sedeInfo(): string {
    return this.claustroSeleccionado?.profesorSnp?.sede?.sede + ' | ' + this.claustroSeleccionado?.profesorSnp?.sede?.nombreDependencia;
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }
}
