export enum EstadoBeca {
  VIGENTE = 'VIGENTE',
  NO_VIGENTE = 'NO_VIGENTE',
  SUSPENDIDA = 'SUSPENDIDA',
  CANCELADA = 'CANCELADA',
  RENUNCIA = 'RENUNCIA',
  REANUDADA = 'REANUDADA',
  BAJA = 'BAJA',
}

const estadosPorTipoDeBeca: { [tipo: string]: EstadoBeca[] } = {
  NACIONAL: [EstadoBeca.VIGENTE, EstadoBeca.SUSPENDIDA, EstadoBeca.BAJA],
  EXTRANJERA: [
    EstadoBeca.VIGENTE,
    EstadoBeca.NO_VIGENTE,
    EstadoBeca.SUSPENDIDA,
    EstadoBeca.CANCELADA,
    EstadoBeca.RENUNCIA,
    EstadoBeca.REANUDADA,
    EstadoBeca.BAJA,
  ],
};

export default function obtenerEstadosPorTipo(tipo: 'NACIONAL' | 'EXTRANJERA'): EstadoBeca[] {
  return estadosPorTipoDeBeca[tipo];
}
