import { PersonaFisica } from '@conacyt/buscador-cvu';
import { Solicitud } from '@/shared/model/solicitud.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import BuscadorPersonaComponent from '../buscador-persona/buscador-persona.component';

import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';

const validations = function () {
  return {
    ayudante: {
      cvu: { required },
      uma: { required },
      proyectos: { required },
      rfc: { required },
      domicilios: { required },
    },
  };
};

class Ayudante extends PersonaFisica {
  proyectos: any;
  uma: any;
  umaRec: any;
}
@Component({
  validations,
})
export default class DatosAyudanteComponent extends Vue {
  public ayudante = new Ayudante();

  @Prop({ default: false })
  public saving: boolean;

  @Prop({ default: false })
  public solicitud: Solicitud;

  public readonly umas = [1, 2, 3];

  @Ref('buscador') readonly buscadorPersonaComponent!: BuscadorPersonaComponent;

  /**
   * Carga inicial
   */
  mounted() {
    if (this.solicitud.estado === EstadoSolicitud.EN_CAPTURA && this.solicitud?.ayudante != null) {
      this.buscadorPersonaComponent.cvuToSearch = this.solicitud.ayudante.cvu;
      this.buscadorPersonaComponent.handleFindPersonaByLogin();
    } else if (this.solicitud.estado !== EstadoSolicitud.EN_CAPTURA) {
      this.ayudante = this.solicitud.ayudante;
    }
  }

  /**
   * Guarda en la solicitud la información del ayudante.
   */
  public saveAyudante2() {
    const datosEnviar = {
      ayudante: this.ayudante,
    };
    this.$emit('guardarAyudante', datosEnviar);
  }

  /**
   * Manejador de evento para cuando se busca una persona
   * @param persona La persona a agregar
   */
  public agregarPersona(persona: Ayudante) {
    this.ayudante = persona;
    if (this.solicitud?.ayudante?.proyectos != null) {
      this.ayudante.proyectos = this.solicitud.ayudante.proyectos;
      this.ayudante.uma = this.solicitud.ayudante.uma;
      this.ayudante.umaRec = this.solicitud.ayudante.umaRec;
    }
  }

  /**
   * Controla el deshabilitado de los campos y botones.
   */
  get desactivarBoton(): boolean {
    return this.$v.$invalid;
  }

  /**
   * Controla la visualización de botón.
   */
  get mostrarBoton(): boolean {
    return this.solicitud.estado === EstadoSolicitud.EN_CAPTURA || this.solicitud.estado === EstadoSolicitud.RECLASIFICADA;
  }
}
