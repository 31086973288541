import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ayudantia } from '@conacyt/historicopersonasms-client';

import * as dateUtils from '@/shared/date/date-utils';

import HistoryItemComponent from '@/components/expediente-persona/history-item/history-item.vue';

@Component({
  components: {
    'history-item': HistoryItemComponent,
  },
})
export default class AyudantiasComponent extends Vue {
  @Prop({ required: false })
  public item: Array<Ayudantia>;

  get ayudantias(): Array<Ayudantia> {
    return this.item;
  }

  get fields() {
    return [
      // Preguntar de donde sale { key: 'vigencia', label: 'Activo', sortable: true },
      {
        key: 'vigencia.fechaInicio',
        label: 'Fecha de inicio',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      {
        key: 'vigencia.fechaFin',
        label: 'Fecha de fin',
        sortable: true,
        formatter: value => dateUtils.formatUtc(value),
      },
      { key: 'umas', label: 'UMAs', sortable: true },
      { key: 'estadoSolicitud', label: 'Estatus', sortable: true },
      { key: 'cvuInvestigador', label: 'CVU Investigador', sortable: true },
      { key: 'nombreCompletoInvestigador', label: 'Nombre del Investigador', sortable: true },
      { key: 'convenio.urlConvenio', label: 'URL convenio', sortable: true, link: true },
      // Preguntar de donde sale  { key: 'institucion.dependenciaDesc', label: 'Investigador', sortable: true },
    ];
  }
}
