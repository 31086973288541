import { Component } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoPrograma } from '@/shared/model/enumerations/estado-programa';

import { FiltroPrograma } from '@/shared/model/filtro-programa.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';

@Component
export default class ProgramaCapturaComponent extends ApeironComponent {
  public filtro = new FiltroPrograma();
  form = { programa: {} };

  public formularioRequerido = false;

  created() {
    this.filtro.coordinadorCvu = this.permisos.usuario.cvu;
    this.filtro.programaEstatus = EstadoPrograma.EN_REGISTRO;
    if (this.formModel?.programa) {
      this.form.programa = { ...this.form.programa, ...JSON.parse(JSON.stringify(this.formModel.programa)) };
    } else {
      this.formularioRequerido = true;
    }
  }
}
