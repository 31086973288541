var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _vm._l(_vm.errors, function (error, index) {
        return _c("message", {
          key: index,
          attrs: {
            variant: _vm.variant,
            title: error.nombre,
            description: error.condicion,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }