import { Component, Emit, Inject, Prop } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import {
  MenuInstitucionClaustroSnp,
  MenuSedeClaustroSnp,
  MenuTiempoDedicacionClaustro,
  ParticipacionSnp,
} from '@/entities/snp/claustro/claustro.model';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import CatalogosSnp from '@/entities/snp/catalogos/catalogos-snp.factory';
import * as dateUtils from '@/shared/date/date-utils';
import { required } from 'vuelidate/lib/validators';
import { StatusCodes } from 'http-status-codes';
import ParticipanteService from '@/components/participantes/participante.service';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { IPersona } from '@/shared/model/persona/persona.model';

const validations = function () {
  const { claustro } = this as ClaustroRegistroComponent;
  return {
    claustro: {
      participante: {
        nombre: {
          required,
        },
      },
      profesorSnp: {
        tiempoDedicacion: {
          required,
        },
        institucion: {
          required,
        },
        sede: {
          required,
        },
        claustroFechaIngreso: {
          required,
        },
        claustroFechaBaja: {
          minValue: value => {
            if (claustro.profesorSnp?.claustroFechaIngreso && value) {
              return new Date(claustro.profesorSnp?.claustroFechaIngreso) <= new Date(value);
            }
            return true;
          },
        },
      },
    },
  };
};

@Component({ validations: validations })
export default class ClaustroRegistroComponent extends mixins(JhiDataUtils, ApeironComponent) {
  @Inject('historicoPersonasApi') private historicoPersonasApi: () => HistoricoPersonasApi;
  @Inject('participanteService') private participanteService: () => ParticipanteService;

  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public claustro: ParticipacionSnp = new ParticipacionSnp();

  public tiemposDedicacion: Array<MenuTiempoDedicacionClaustro> = [];
  public instituciones: Array<MenuInstitucionClaustroSnp> = [];
  public sedes: Array<MenuSedeClaustroSnp> = [];

  public mounted(): void {
    this.iniciarComponente();
  }

  public profesorSeleccionado(participante: IPersona): void {
    Object.assign(this.claustro.participante, participante);

    this.buscarNivelSni();
  }

  public iniciarComponente(): void {
    this.tiemposDedicacion = [];
    CatalogosSnp.obtenerTiemposDedicacion().forEach(it => {
      this.tiemposDedicacion.push({ value: it, text: it.nombre });
    });

    this.claustro = new ParticipacionSnp();

    this.instituciones = [];

    if (this.solicitud?.programa?.instituciones) {
      this.solicitud.programa.instituciones.forEach(institucion => {
        this.instituciones.push({
          value: {
            claveInstitucion: institucion.claveInstitucion,
            nombreInstitucion: institucion.nombreInstitucion,
          },
          text: institucion.nombreInstitucion,
        });
      });
    }

    if (this.instituciones.length == 1) {
      this.claustro.profesorSnp.institucion = this.instituciones[0].value;
    }

    this.seleccionarInstitucion();
  }

  public seleccionarInstitucion(): void {
    this.sedes = [];

    if (this.solicitud?.programa?.instituciones) {
      this.solicitud.programa.instituciones.forEach(institucion => {
        if (institucion.claveInstitucion == this.claustro.profesorSnp?.institucion?.claveInstitucion) {
          institucion.sedes.forEach(sede => {
            this.sedes.push({
              value: {
                claveSede: sede.claveSede,
                claveDependencia: sede.claveDependencia,
                nombreDependencia: sede.nombreDependencia,
                sede: sede.sede,
              },
              text: institucion.nombreInstitucion,
            });
          });
        }
      });
    }

    if (this.sedes.length == 1) {
      this.claustro.profesorSnp.sede = this.sedes[0].value;
    }
  }

  public limpiarDatos(): void {
    this.iniciarComponente();
  }

  private buscarNivelSni(): void {
    this.historicoPersonasApi()
      .getExpediente(this.claustro.participante.cvu)
      .then(res => {
        const vigencia = res.data.vigenciasSni.filter(vig => vig.activo)[0];

        if (vigencia) {
          this.claustro.profesorSnp.areaConocimientoId = vigencia.areaConocimientoEval?.id;
          this.claustro.profesorSnp.areaConocimientoNombre = vigencia.areaConocimientoEval?.descripcion;
          this.claustro.profesorSnp.vigenciaEstatus = vigencia.estatusVigencia?.descripcion;
          this.claustro.profesorSnp.vigenciaFechaFin = new Date(vigencia.vigencia?.fechaFin);
          this.claustro.profesorSnp.vigenciaFechaInicio = new Date(vigencia.vigencia?.fechaInicio);
          this.claustro.profesorSnp.nivelSniClave = vigencia.nivelSni?.clave;
          this.claustro.profesorSnp.nivelSniNombre = vigencia.nivelSni?.descripcion;
          this.claustro.profesorSnp.vigenciaTipo = vigencia.tipo?.descripcion;
        }
      })
      .catch(error => {
        if (error.response?.data?.status === StatusCodes.NOT_FOUND) {
          this.alertService().showWarning(this, this.$t('apeironGwApp.expediente.noResultado', 'Falla').toString());
        } else {
          this.alertService().showError(this, this.$t('apeironGwApp.expediente.noCarga', 'Falla').toString());
        }
      });
  }

  public guardarClaustro(): void {
    if (!this.formularioIsInvalid) {
      if (!this.readOnly) {
        this.claustro.solicitudId = this.solicitud.id;
        this.claustro.contenedorId =
          this.claustro.profesorSnp.institucion.claveInstitucion + '-' + this.claustro.profesorSnp.sede.claveSede;
        this.claustro.dependenciaId = this.claustro.profesorSnp.sede.claveDependencia;
        this.claustro.rol = RolAutoridad.PROFESOR;

        this.participanteService()
          .create(this.claustro, this.solucion.id)
          .then(() => {
            this.registrarProfesor();
          })
          .catch(error => {
            this.alertService().showHttpError(this, error.response.message ? error.response.message : error.response);
          });
      }
    } else {
      this.alertService().showWarning(this, this.$t('apeironGwApp.claustroSnp.form.formularioInvalido').toString());
    }
  }

  @Emit('guardarClaustro')
  public registrarProfesor(): ParticipacionSnp {
    const claustro = { ...this.claustro };

    this.limpiarDatos();

    return claustro;
  }

  public formatUtc(date: Date): string {
    return dateUtils.formatUtc(date);
  }

  get nombrecompleto(): string {
    return (
      (this.claustro.participante?.nombre ?? '') +
      (this.claustro.participante?.apellidoPaterno ? ' ' + this.claustro.participante.apellidoPaterno : ' ') +
      (this.claustro.participante?.apellidoMaterno ? ' ' + this.claustro.participante.apellidoMaterno : '')
    );
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }

  get formularioIsInvalid(): boolean {
    return this.$v.$invalid;
  }
}
