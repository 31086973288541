var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("last-padron-info", {
        attrs: {
          status: _vm.isSearching,
          lastExecution: _vm.currentPadronEncabezado,
        },
        on: { "show-last-execute": _vm.handleShowLastExecute },
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "mt-3",
          attrs: { label: _vm.$t("apeironGwApp.pago.padron.tipo") },
        },
        [
          _c("b-form-select", {
            staticClass: "w-25",
            attrs: { size: "sm", options: _vm.tipoPersonaOptions },
            on: { change: _vm.handleTipoPersonaSelectedChange },
            model: {
              value: _vm.tipoPersonaSelected,
              callback: function ($$v) {
                _vm.tipoPersonaSelected = $$v
              },
              expression: "tipoPersonaSelected",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.padron?.length > 0
        ? _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isSearching,
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c("padron-download", {
                            attrs: {
                              padronEncabezadoId:
                                _vm.currentPadronEncabezado.id,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-table", {
                    staticClass: "text-nowrap mt-3",
                    attrs: {
                      responsive: "",
                      borderless: "",
                      "show-empty": "",
                      small: "",
                      "head-variant": "primary",
                      items: _vm.padron,
                      fields: _vm.fields,
                      "tbody-tr-class": _vm.rowClass,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row-details",
                          fn: function (row) {
                            return [
                              _c(
                                "b-card",
                                { staticClass: "p-5" },
                                _vm._l(
                                  _vm.encodeRules(row),
                                  function (selected) {
                                    return _c(
                                      "div",
                                      {
                                        key: selected.prop,
                                        staticClass: "mb-2",
                                      },
                                      [
                                        selected.valor
                                          ? _c("b-icon", {
                                              attrs: {
                                                icon: "check2-square",
                                                variant: "success",
                                                "font-scale": "1.5",
                                              },
                                            })
                                          : _c("b-icon", {
                                              attrs: {
                                                icon: "x-circle",
                                                variant: "danger",
                                                "font-scale": "1.5",
                                              },
                                            }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "ml-3 h5 text-dark" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toNormalCase(selected.prop)
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                        {
                          key: "head()",
                          fn: function (data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-3 mr-3 text-center" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.label) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "head(cambios)",
                          fn: function () {
                            return [_c("div", { staticClass: "text-center" })]
                          },
                          proxy: true,
                        },
                        {
                          key: "cell()",
                          fn: function (data) {
                            return [
                              typeof data.value == "boolean"
                                ? _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      data.value
                                        ? _c("b-icon", {
                                            staticClass: "i-selected",
                                            attrs: {
                                              icon: "check-square-fill",
                                              variant: "success",
                                              "font-scale": "1.2",
                                            },
                                          })
                                        : _c(
                                            "b-iconstack",
                                            { attrs: { "font-scale": "1.2" } },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  stacked: "",
                                                  icon: "square-fill",
                                                  variant: "white",
                                                  "font-scale": "1.1",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-icon", {
                                                attrs: {
                                                  stacked: "",
                                                  icon: "square",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.value) +
                                        "\n          "
                                    ),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: "cell(cambios)",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    set: (_vm.change =
                                      row.item.reglasValidacion.comparacionEstatusAnterior),
                                  },
                                },
                                [
                                  _vm.change === "INCORPORADO"
                                    ? _c("b-icon", {
                                        staticClass: "i-selected",
                                        attrs: {
                                          icon: "arrow-up-right-circle",
                                          variant: "success",
                                          "font-scale": "1.2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfigurarComponente(
                                              row
                                            )
                                          },
                                        },
                                      })
                                    : _vm.change === "DESINCORPORADO"
                                    ? _c("b-icon", {
                                        staticClass: "i-selected",
                                        attrs: {
                                          icon: "arrow-down-right-circle",
                                          variant: "danger",
                                          "font-scale": "1.2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfigurarComponente(
                                              row
                                            )
                                          },
                                        },
                                      })
                                    : _c("b-icon", {
                                        staticClass: "i-selected",
                                        attrs: {
                                          icon: "dash-lg",
                                          variant: "dark",
                                          "font-scale": "1.2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfigurarComponente(
                                              row
                                            )
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(cvu)",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName:
                                            "v-b-modal.expediente-persona-modal-id",
                                          modifiers: {
                                            "expediente-persona-modal-id": true,
                                          },
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowPersonaDetail(
                                            row.item.persona.cvu
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.item.persona.cvu))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      474650170
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12", lg: "4" } },
                    [
                      false
                        ? _c("b-form-select", {
                            staticClass: "w-25",
                            attrs: { size: "sm", options: _vm.perPageOptions },
                            on: { change: _vm.handlePageSizeChange },
                            model: {
                              value: _vm.perPage,
                              callback: function ($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c("span", { staticClass: "ml-2" }, [
                            _c(
                              "small",
                              { staticClass: "font-weight-bold text-muted" },
                              [_vm._v(_vm._s(_vm.$t("global.table.perPage")))]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12", lg: "4" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          align: "center",
                          size: "sm",
                          pills: "",
                        },
                        on: { change: _vm.handlePageChange },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right",
                      attrs: { sm: "12", md: "12", lg: "4" },
                    },
                    [
                      _c("p", [
                        _c(
                          "small",
                          { staticClass: "text-muted font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("global.table.totalRows", {
                                  totalRows: _vm.totalRows,
                                })
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right",
                      attrs: { sm: "12", md: "12", lg: "12" },
                    },
                    [
                      _vm.showConfirmButton
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.confirmation-padron-modal-id",
                                  modifiers: {
                                    "confirmation-padron-modal-id": true,
                                  },
                                },
                              ],
                              attrs: { variant: "primary" },
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("entity.action.confirm"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal-container",
        {
          attrs: {
            id: "expediente-persona-modal-id",
            scrollable: true,
            title: _vm.$t("global.menu.entities.expedientePersona"),
          },
        },
        [
          this.cvuToSearch
            ? _c("expediente-persona", {
                attrs: {
                  id: "expediente-persona-busqueda-id",
                  cvu: this.cvuToSearch,
                  "read-only": true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          id: "confirmation-padron-modal-id",
          title: _vm.$t("apeironGwApp.pago.rules.execute.modal.title"),
          question: _vm.$t("apeironGwApp.pago.rules.execute.modal.question"),
          size: "sm",
          centered: "",
        },
        on: { confirmed: _vm.handleConfirmarPadron },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }