var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.formularioRequerido
              ? _c("h5", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("apeironGwApp.programa.obligatorio")
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("apeironGwApp.programa.cuota.cobra")
                  ),
                },
              }),
              _vm._v(" "),
              _c("b-form-radio-group", {
                attrs: {
                  "button-variant": "outline-danger",
                  options: _vm.options,
                  disabled: !_vm.deshabilitarCampo,
                  state: !_vm.$v.cuota.cobra.$invalid,
                },
                model: {
                  value: _vm.cuota.cobra,
                  callback: function ($$v) {
                    _vm.$set(_vm.cuota, "cobra", $$v)
                  },
                  expression: "cuota.cobra",
                },
              }),
              _vm._v(" "),
              !_vm.$v.cuota.cobra.required
                ? _c("small", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.programa.cuota.voluntario")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-radio-group", {
                    attrs: {
                      "button-variant": "outline-danger",
                      options: _vm.options,
                      disabled: !_vm.deshabilitarCampo,
                      state: !_vm.$v.cuota.esVoluntario.$invalid,
                    },
                    model: {
                      value: _vm.cuota.esVoluntario,
                      callback: function ($$v) {
                        _vm.$set(_vm.cuota, "esVoluntario", $$v)
                      },
                      expression: "cuota.esVoluntario",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.cuota.esVoluntario.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "line-break" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.programa.cuota.frecuenciaColegiatura"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "form-control",
                      attrs: {
                        state: !_vm.$v.cuota.frecuenciaColegiatura.$invalid,
                        disabled: !_vm.deshabilitarCampo,
                      },
                      model: {
                        value: _vm.cuota.frecuenciaColegiatura,
                        callback: function ($$v) {
                          _vm.$set(_vm.cuota, "frecuenciaColegiatura", $$v)
                        },
                        expression: "cuota.frecuenciaColegiatura",
                      },
                    },
                    _vm._l(_vm.frecuenciasCuota, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.nombre) + "\n        "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.$v.cuota.frecuenciaColegiatura.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.programa.cuota.montoColegiatura")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: {
                      type: "number",
                      name: "numero",
                      id: "numero",
                      min: "0",
                      state: !_vm.$v.cuota.montoColegiatura.$invalid,
                      disabled: !_vm.deshabilitarCampo,
                    },
                    model: {
                      value: _vm.cuota.montoColegiatura,
                      callback: function ($$v) {
                        _vm.$set(_vm.cuota, "montoColegiatura", _vm._n($$v))
                      },
                      expression: "cuota.montoColegiatura",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.cuota.montoColegiatura.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "line-break" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.programa.cuota.frecuenciaInscripción"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "form-control",
                      attrs: {
                        state: !_vm.$v.cuota.frecuenciaInscripcion.$invalid,
                        disabled: !_vm.deshabilitarCampo,
                      },
                      model: {
                        value: _vm.cuota.frecuenciaInscripcion,
                        callback: function ($$v) {
                          _vm.$set(_vm.cuota, "frecuenciaInscripcion", $$v)
                        },
                        expression: "cuota.frecuenciaInscripcion",
                      },
                    },
                    _vm._l(_vm.frecuenciasCuota, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.nombre) + "\n        "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.$v.cuota.frecuenciaInscripcion.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.programa.cuota.montoInscripcion")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: {
                      type: "number",
                      name: "numero",
                      id: "numero",
                      min: "0",
                      state: !_vm.$v.cuota.montoInscripcion.$invalid,
                      disabled: !_vm.deshabilitarCampo,
                    },
                    model: {
                      value: _vm.cuota.montoInscripcion,
                      callback: function ($$v) {
                        _vm.$set(_vm.cuota, "montoInscripcion", _vm._n($$v))
                      },
                      expression: "cuota.montoInscripcion",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.cuota.montoInscripcion.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "line-break" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "apeironGwApp.programa.cuota.frecuenciaOtraCuota"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "form-control",
                      attrs: {
                        state: !_vm.$v.cuota.frecuenciaOtraCuota.$invalid,
                        disabled: !_vm.deshabilitarCampo,
                      },
                      model: {
                        value: _vm.cuota.frecuenciaOtraCuota,
                        callback: function ($$v) {
                          _vm.$set(_vm.cuota, "frecuenciaOtraCuota", $$v)
                        },
                        expression: "cuota.frecuenciaOtraCuota",
                      },
                    },
                    _vm._l(_vm.frecuenciasCuota, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.nombre) + "\n        "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.$v.cuota.frecuenciaOtraCuota.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mostrarCampo
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.programa.cuota.montoOtra")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: {
                      type: "number",
                      name: "numero",
                      id: "numero",
                      min: "0",
                      state: !_vm.$v.cuota.montoOtra.$invalid,
                      disabled: !_vm.deshabilitarCampo,
                    },
                    model: {
                      value: _vm.cuota.montoOtra,
                      callback: function ($$v) {
                        _vm.$set(_vm.cuota, "montoOtra", _vm._n($$v))
                      },
                      expression: "cuota.montoOtra",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.cuota.montoOtra.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "line-break" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.mostrarTipoCuota
            ? _c(
                "b-col",
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("apeironGwApp.programa.cuota.tipoCuota")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      required: this.cuota.frecuenciaOtraCuota,
                      state: !_vm.$v.cuota.tipoCuota.$invalid,
                      disabled: !_vm.deshabilitarCampo,
                    },
                    model: {
                      value: _vm.cuota.tipoCuota,
                      callback: function ($$v) {
                        _vm.$set(_vm.cuota, "tipoCuota", $$v)
                      },
                      expression: "cuota.tipoCuota",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.cuota.tipoCuota.required
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("entity.validation.required"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("div", { staticClass: "border-top mt-4 mb-4" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-5 float-right" },
              [
                _vm.deshabilitarCampo
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          size: "md",
                          disabled: _vm.desactivarBoton,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.guardarCuota()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.save")),
                          },
                        }),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "save" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }