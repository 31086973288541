var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-inline-block mb-2 mt-3" },
        [
          _vm.items?.length > 0
            ? _c(
                "b-button-group",
                { staticClass: "bg-white" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.confirmar-upload-modal",
                          modifiers: { "confirmar-upload-modal": true },
                        },
                      ],
                      staticClass: "shadow d-inline-block border-0 mr-1",
                      attrs: {
                        disabled: _vm.isDisabled,
                        variant: "outline-danger",
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "cloud-arrow-up-fill",
                          animation: _vm.isProcessing ? "fade" : "",
                        },
                      }),
                      _vm._v(
                        "\n        (" + _vm._s(_vm.selectedCount) + ")\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.confirmar-clear-modal",
                          modifiers: { "confirmar-clear-modal": true },
                        },
                      ],
                      staticClass: "shadow d-inline-block border-0 mr-1",
                      attrs: { variant: "outline-danger" },
                    },
                    [_c("b-icon", { attrs: { icon: "x-octagon" } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: { id: "confirmar-save-modal" },
        on: { confirmed: _vm.handleSave },
      }),
      _vm._v(" "),
      _c("confirmation", {
        attrs: { id: "confirmar-upload-modal" },
        on: { confirmed: _vm.handleUpload },
      }),
      _vm._v(" "),
      _c("confirmation", {
        attrs: { id: "confirmar-clear-modal" },
        on: { confirmed: _vm.handleClear },
      }),
      _vm._v(" "),
      _vm.showTable
        ? _c("b-table", {
            staticClass: "text-nowrap mt-3",
            attrs: {
              small: "",
              "sticky-header": "750px",
              responsive: "",
              busy: _vm.isBusy,
              items: _vm.items,
              fields: _vm.fields,
              "head-variant": "primary",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center text-danger my-2" },
                        [
                          _c("b-spinner", { staticClass: "align-middle" }),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("global.process.load.loading"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "head()",
                  fn: function (data) {
                    return [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v("\n        " + _vm._s(data.label) + "\n      "),
                      ]),
                    ]
                  },
                },
                {
                  key: "head(check)",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.isCheckingAll
                            ? _c("b-icon", {
                                attrs: {
                                  icon: "arrow-clockwise",
                                  variant: "white",
                                  animation: "spin",
                                  "font-scale": "1.3",
                                },
                              })
                            : _c("b-form-checkbox", {
                                attrs: {
                                  size: "lg",
                                  disabled: _vm.cvuDuplicates,
                                },
                                on: { change: _vm.handleCheckAll },
                                model: {
                                  value: _vm.checkAll,
                                  callback: function ($$v) {
                                    _vm.checkAll = $$v
                                  },
                                  expression: "checkAll",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "head(state)",
                  fn: function () {
                    return [_c("div", { staticClass: "text-center" })]
                  },
                  proxy: true,
                },
                {
                  key: "head(situacion)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.selectedCount > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "border-0",
                                  attrs: { size: "sm", variant: "white" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenBulkOperationModal(
                                        "situacion"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.selectedCount > 0
                                          ? "layers-fill"
                                          : "layers",
                                      variant: "white",
                                      animation: "fade",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-white" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.selectedCount) + ")"
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.label) + "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "head(inicio)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.selectedCount > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "border-0",
                                  attrs: { size: "sm", variant: "white" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenBulkOperationModal(
                                        "inicio"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.selectedCount > 0
                                          ? "layers-fill"
                                          : "layers",
                                      variant: "white",
                                      animation: "fade",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-white" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.selectedCount) + ")"
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.label) + "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "head(fin)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.selectedCount > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "border-0",
                                  attrs: { size: "sm", variant: "white" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenBulkOperationModal(
                                        "fin"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.selectedCount > 0
                                          ? "layers-fill"
                                          : "layers",
                                      variant: "white",
                                      animation: "fade",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-white" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.selectedCount) + ")"
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.label) + "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "head(tipoParticipacion)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.selectedCount > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "border-0",
                                  attrs: { size: "sm", variant: "white" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenBulkOperationModal(
                                        "tipoParticipacion"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.selectedCount > 0
                                          ? "layers-fill"
                                          : "layers",
                                      variant: "white",
                                      animation: "fade",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-white" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.selectedCount) + ")"
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.label) + "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "head(institucion)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.selectedCount > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "border-0",
                                  attrs: { size: "sm", variant: "white" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenBulkOperationModal(
                                        "institucion"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.selectedCount > 0
                                          ? "layers-fill"
                                          : "layers",
                                      variant: "white",
                                      animation: "fade",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-white" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.selectedCount) + ")"
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.label) + "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(state)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          row.item.processInfo.isProcessing
                            ? _c("b-icon", {
                                attrs: {
                                  icon: "play-circle",
                                  variant: "warning",
                                  animation: "throb",
                                  "font-scale": "1.5",
                                },
                              })
                            : row.item.processInfo.hasErrors
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "shadow d-inline-block border-0 mr-1",
                                  attrs: { size: "sm", variant: "blank" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "exclamation-circle",
                                      variant: "danger",
                                      "font-scale": "1.5",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleOpenErrorDetailModal(
                                          row.item
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : row.item.processInfo.isFinished
                            ? _c("b-icon", {
                                attrs: {
                                  icon: "check2-circle",
                                  variant: "success",
                                  "font-scale": "1.5",
                                },
                              })
                            : _c("b-icon", {
                                attrs: {
                                  icon: "record-fill",
                                  variant: "success",
                                  "font-scale": "1.5",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(check)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-checkbox", {
                            attrs: {
                              size: "lg",
                              disabled:
                                !row.item.processInfo.isSelectable ||
                                _vm.cvuDuplicates,
                            },
                            on: { change: _vm.handleSelect },
                            model: {
                              value: row.item.selected,
                              callback: function ($$v) {
                                _vm.$set(row.item, "selected", $$v)
                              },
                              expression: "row.item.selected",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(cvu)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-input", {
                            attrs: { size: "sm", type: "number" },
                            model: {
                              value: row.item.cvu,
                              callback: function ($$v) {
                                _vm.$set(row.item, "cvu", $$v)
                              },
                              expression: "row.item.cvu",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(situacion)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: { size: "sm", options: row.field.options },
                            model: {
                              value: row.item.situacion,
                              callback: function ($$v) {
                                _vm.$set(row.item, "situacion", $$v)
                              },
                              expression: "row.item.situacion",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(inicio)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.showTipoParticipacion(row)
                            ? _c(
                                "b-form-group",
                                [
                                  _c("b-form-datepicker", {
                                    class: row.field.class,
                                    attrs: {
                                      size: "sm",
                                      locale: "es",
                                      placeholder: "DD/MM/YYYY",
                                    },
                                    model: {
                                      value: row.item.inicio,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "inicio", $$v)
                                      },
                                      expression: "row.item.inicio",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "p",
                                {
                                  staticClass: "text-warning",
                                  attrs: { show: "" },
                                },
                                [
                                  _c("strong", [
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.movimiento.notApply"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(fin)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.showTipoParticipacionFechaFin(row)
                            ? _c(
                                "b-form-group",
                                [
                                  _c("b-form-datepicker", {
                                    class: row.field.class,
                                    attrs: {
                                      size: "sm",
                                      locale: "es",
                                      placeholder: "DD/MM/YYYY",
                                    },
                                    model: {
                                      value: row.item.fin,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "fin", $$v)
                                      },
                                      expression: "row.item.fin",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "p",
                                {
                                  staticClass: "text-warning",
                                  attrs: { show: "" },
                                },
                                [
                                  _c("strong", [
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.movimiento.notApply"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(tipoParticipacion)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.showTipoParticipacion(row)
                            ? _c("b-form-select", {
                                class: row.field.class,
                                attrs: {
                                  size: "sm",
                                  options: row.field.options,
                                },
                                model: {
                                  value: row.item.tipoParticipacion,
                                  callback: function ($$v) {
                                    _vm.$set(row.item, "tipoParticipacion", $$v)
                                  },
                                  expression: "row.item.tipoParticipacion",
                                },
                              })
                            : _c(
                                "p",
                                {
                                  staticClass: "text-warning",
                                  attrs: { show: "" },
                                },
                                [
                                  _c("strong", [
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "apeironGwApp.movimiento.notApply"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(institucion)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesIndex[row.index].institucion,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInstitucionChange(row, $event)
                              },
                            },
                            model: {
                              value: row.item.institucion,
                              callback: function ($$v) {
                                _vm.$set(row.item, "institucion", $$v)
                              },
                              expression: "row.item.institucion",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(dependencia)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesIndex[row.index].dependencia,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDependenciaChange(row, $event)
                              },
                            },
                            model: {
                              value: row.item.dependencia,
                              callback: function ($$v) {
                                _vm.$set(row.item, "dependencia", $$v)
                              },
                              expression: "row.item.dependencia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(subdependencia)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesIndex[row.index]
                                  .subdependencia,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleSubdependenciaChange(
                                  row,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: row.item.subdependencia,
                              callback: function ($$v) {
                                _vm.$set(row.item, "subdependencia", $$v)
                              },
                              expression: "row.item.subdependencia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(departamento)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesIndex[row.index].departamento,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDepartamentoChange(row, $event)
                              },
                            },
                            model: {
                              value: row.item.departamento,
                              callback: function ($$v) {
                                _vm.$set(row.item, "departamento", $$v)
                              },
                              expression: "row.item.departamento",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(institucionComision)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesCompletoIndex[row.index]
                                  .institucion,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInstitucionComisionChange(
                                  row,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: row.item.comision.institucion,
                              callback: function ($$v) {
                                _vm.$set(row.item.comision, "institucion", $$v)
                              },
                              expression: "row.item.comision.institucion",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(dependenciaComision)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesCompletoIndex[row.index]
                                  .dependencia,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDependenciaComisionChange(
                                  row,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: row.item.comision.dependencia,
                              callback: function ($$v) {
                                _vm.$set(row.item.comision, "dependencia", $$v)
                              },
                              expression: "row.item.comision.dependencia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(subdependenciaComision)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesCompletoIndex[row.index]
                                  .subdependencia,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleSubdependenciaComisionChange(
                                  row,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: row.item.comision.subdependencia,
                              callback: function ($$v) {
                                _vm.$set(
                                  row.item.comision,
                                  "subdependencia",
                                  $$v
                                )
                              },
                              expression: "row.item.comision.subdependencia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(departamentoComision)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-form-select", {
                            class: row.field.class,
                            attrs: {
                              size: "sm",
                              options:
                                _vm.institucionesCompletoIndex[row.index]
                                  .departamento,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDepartamentoComisionChange(
                                  row,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: row.item.comision.departamento,
                              callback: function ($$v) {
                                _vm.$set(row.item.comision, "departamento", $$v)
                              },
                              expression: "row.item.comision.departamento",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell()",
                  fn: function (data) {
                    return [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v("\n        " + _vm._s(data.value) + "\n      "),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1892549947
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "bulkOperationModal",
          attrs: { "header-bg-variant": "primary" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("apeironGwApp.movimiento.bulk.title"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.bulkOperation
            ? _c("div", { staticClass: "modal-body" }, [
                _vm.bulkOperation === "situacion"
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "apeironGwApp.movimiento.bulk.operations.situacion"
                              ),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                size: "sm",
                                options: _vm.situacionOpciones,
                              },
                              model: {
                                value: _vm.currentValue,
                                callback: function ($$v) {
                                  _vm.currentValue = $$v
                                },
                                expression: "currentValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.bulkOperation === "tipoParticipacion"
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "apeironGwApp.movimiento.bulk.operations.tipoParticipacion"
                              ),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                size: "sm",
                                options: _vm.tipoParticipacionOpciones,
                              },
                              model: {
                                value: _vm.currentValue,
                                callback: function ($$v) {
                                  _vm.currentValue = $$v
                                },
                                expression: "currentValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.bulkOperation === "inicio"
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "apeironGwApp.movimiento.bulk.operations.inicio"
                              ),
                            },
                          },
                          [
                            _c("b-form-datepicker", {
                              attrs: {
                                size: "sm",
                                locale: "es",
                                placeholder: _vm.$t(
                                  "entity.validation.dateFormat"
                                ),
                              },
                              model: {
                                value: _vm.currentValue,
                                callback: function ($$v) {
                                  _vm.currentValue = $$v
                                },
                                expression: "currentValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.bulkOperation === "fin"
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "apeironGwApp.movimiento.bulk.operations.fin"
                              ),
                            },
                          },
                          [
                            _c("b-form-datepicker", {
                              attrs: {
                                size: "sm",
                                locale: "es",
                                placeholder: _vm.$t(
                                  "entity.validation.dateFormat"
                                ),
                              },
                              model: {
                                value: _vm.currentValue,
                                callback: function ($$v) {
                                  _vm.currentValue = $$v
                                },
                                expression: "currentValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.bulkOperation === "institucion"
                  ? _c(
                      "div",
                      [
                        _vm.institucionOptions?.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "apeironGwApp.movimiento.bulk.operations.institucion"
                                  ),
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.institucionOptions,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCatalogoChange(
                                        "institucion",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.institucionValue,
                                    callback: function ($$v) {
                                      _vm.institucionValue = $$v
                                    },
                                    expression: "institucionValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.dependenciaOptions?.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "apeironGwApp.movimiento.bulk.operations.dependencia"
                                  ),
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.dependenciaOptions,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCatalogoChange(
                                        "dependencia",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.dependenciaValue,
                                    callback: function ($$v) {
                                      _vm.dependenciaValue = $$v
                                    },
                                    expression: "dependenciaValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.subdependenciaOptions?.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "apeironGwApp.movimiento.bulk.operations.subdependencia"
                                  ),
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.subdependenciaOptions,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCatalogoChange(
                                        "subdependencia",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.subdependenciaValue,
                                    callback: function ($$v) {
                                      _vm.subdependenciaValue = $$v
                                    },
                                    expression: "subdependenciaValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.departamentoOptions?.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "apeironGwApp.movimiento.bulk.operations.departamento"
                                  ),
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.departamentoOptions,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCatalogoChange(
                                        "departamento",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.departamentoValue,
                                    callback: function ($$v) {
                                      _vm.departamentoValue = $$v
                                    },
                                    expression: "departamentoValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "errorDetailModal",
          attrs: {
            scrollable: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            size: "xl",
            id: "errorDetailModal",
            "header-bg-variant": "primary",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apeironGwApp.tableView.modal.validacion.titulo"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.currentRowDetail
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      { staticClass: "ml-2 mr-2" },
                      [
                        _c("header", { staticClass: "bx-header-title" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apeironGwApp.tableView.modal.validacion.reglas.titulo"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "header-title-line" }),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "apeironGwApp.tableView.modal.validacion.reglas.nocumple"
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("errors", {
                          model: {
                            value: _vm.currentRowDetail.processInfo.errors,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentRowDetail.processInfo,
                                "errors",
                                $$v
                              )
                            },
                            expression: "currentRowDetail.processInfo.errors",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseErrorDetailModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.close")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }