import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import PersonalBecaService from '@/entities/seguimiento/personal-beca.service';
import AlertService from '@/shared/alert/alert.service';
import { FiltroMovimientoBeca } from '@/shared/model/filtro-movimiento-beca.model';
import MovimientoAcademicoService from '@/entities/seguimiento/academico/movimiento-academico.service';
import * as dateUtils from '@/shared/date/date-utils';
import { IMovimientoAcademico } from '@/shared/model/seguimiento/movimiento-academico.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { Area } from '@/shared/model/enumerations/area';
import { EstadoMovimientoBeca } from '@/shared/model/enumerations/estado-movimiento-beca.model';
import { Beca } from '@/shared/model/seguimiento/beca.model';
import BecaService from '@/entities/seguimiento/beca.service';
import { EstadoBeca } from '@/shared/model/enumerations/estado-beca.model';
import resolveEstadoMovimientoAcademicoVariant from '@/shared/estados/estados-movimientos-academico.util';

@Component
export default class TablaMovimientosAcademicosComponent extends Vue {
  @Inject('personalBecaService') public personalBecaService: () => PersonalBecaService;
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('movimientoAcademicoService') public movimientoAcademicoService: () => MovimientoAcademicoService;
  @Inject('becaService') public becaService: () => BecaService;

  @Prop({ required: true })
  public idBeca: string;

  @Prop({ required: false })
  public visible: boolean;

  public periodosList = [];
  public totalItems = 0;
  public componenteVisible = false;
  public permisoAgregarMovimiento = true;
  public totalRows = 0;
  public filtro = new FiltroMovimientoBeca();
  public beca = new Beca();
  public propOrder = 'estado';
  public reverse = false;
  private rol: RolAutoridad;
  public estadoCapturado = EstadoMovimientoBeca.CAPTURADO;
  public estadoRevisado = EstadoMovimientoBeca.REVISADO;
  public estadoPendiente = EstadoMovimientoBeca.PENDIENTE;
  public permiteConsulta = false;
  public permiteRevisar = false;
  public fechaActual = new Date();
  private accesoPermisos = {
    permiteConsulta: [
      RolAutoridad.DIRECTOR,
      RolAutoridad.SUBDIRECTOR,
      RolAutoridad.JEFE_DEPARTAMENTO,
      RolAutoridad.OPERADOR,
      RolAutoridad.COORDINADOR_PROGRAMA,
      RolAutoridad.BECARIO,
    ],
    permiteRevisar: [RolAutoridad.COORDINADOR_PROGRAMA],
  };

  public mounted(): void {
    this.componenteVisible = this.visible;
    this.personalBecaService()
      .find(false, Area.BECAS_NACIONALES)
      .then(res => {
        this.rol = res.rol;
        for (const permiso in this.accesoPermisos) {
          this[permiso] = this.accesoPermisos[permiso].includes(this.rol);
        }
        this.becaService()
          .find(this.idBeca)
          .then(beca => {
            this.beca = beca;
            this.findMovimientosAcademicos();
          })
          .catch(err => {
            this.alertService().showHttpError(this, err.response);
          });
      });
  }

  public readonly fields = [
    {
      key: 'periodoEvaluacion.anio',
      label: this.$t('apeironGwApp.periodoEvaluacion.grid.anio'),
      class: 'text-left font-weight-bold',
      thStyle: { width: '7rem' },
    },
    {
      key: 'periodoEvaluacion.numero',
      label: this.$t('apeironGwApp.periodoEvaluacion.grid.numero'),
      class: 'text-left',
      thStyle: { width: '9rem' },
    },
    {
      key: 'periodoEvaluacion.fechaInicio',
      label: this.$t('apeironGwApp.periodoEvaluacion.grid.fechaInicio'),
      class: 'text-left',
      thStyle: { width: '9rem' },
      formatter: dateUtils.defaultFormat,
    },
    {
      key: 'periodoEvaluacion.fechaFin',
      label: this.$t('apeironGwApp.periodoEvaluacion.grid.fechaFin'),
      class: 'text-left',
      thStyle: { width: '9rem' },
      formatter: dateUtils.defaultFormat,
    },
    {
      key: 'estadoCaratula',
      label: this.$t('apeironGwApp.periodoEvaluacion.label.estado'),
      class: 'text-left',
      thStyle: { width: '14rem' },
    },
    {
      key: 'acciones',
      label: this.$t('apeironGwApp.periodoEvaluacion.grid.acciones'),
    },
  ];

  public findMovimientosAcademicos(): void {
    this.periodosList = [];
    this.filtro.becaId = this.$route.params.becaId;
    const paginationQuery = {
      page: 0,
      size: 100,
      sort: this.sort(),
      filter: this.filtro,
    };

    this.movimientoAcademicoService()
      .retrieve(paginationQuery)
      .then(result => {
        if (result?.data?.length > 0) {
          const fechaActual = new Date();
          const fechaActualSinHoras = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());

          for (const movimiento of result.data) {
            movimiento.periodoEvaluacion.anio = dateUtils.formatDateYear(movimiento.periodoEvaluacion.fechaInicio);
            const fechaInicio = new Date(movimiento.periodoEvaluacion.fechaInicio);
            const fechaInicioSinHoras = new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), fechaInicio.getDate());

            const movimientoExtendido = movimiento as IMovimientoAcademico & {
              permiteConsulta: boolean;
              permiteRevisar: boolean;
            };

            movimientoExtendido.permiteRevisar =
              this.permiteRevisar &&
              fechaInicioSinHoras <= fechaActualSinHoras &&
              movimiento.estadoActual === this.estadoCapturado &&
              (EstadoBeca.VIGENTE === this.beca.estadoActual || EstadoBeca.REANUDADA === this.beca.estadoActual);

            movimientoExtendido.permiteConsulta =
              this.permiteConsulta &&
              !movimientoExtendido.permiteRevisar &&
              (movimiento.estadoActual === this.estadoRevisado || movimiento.estadoActual === this.estadoPendiente);

            let estadoCaratula: string;
            switch (movimiento.estadoActual) {
              case EstadoMovimientoBeca.CAPTURADO:
                estadoCaratula = this.$t('apeironGwApp.periodoEvaluacion.mensajes.porParteCoordinacion').toString();
                break;
              case EstadoMovimientoBeca.PENDIENTE:
                estadoCaratula = this.$t('apeironGwApp.periodoEvaluacion.mensajes.confirmacionPendiente').toString();
                break;
              default:
                estadoCaratula = movimiento.estadoActual;
                break;
            }

            this.periodosList.push({
              ...movimientoExtendido,
              estadoCaratula: estadoCaratula,
              variant: resolveEstadoMovimientoAcademicoVariant(movimiento.estadoActual),
            });
          }
        }
        this.totalRows = Number(result.headers['x-total-count']);
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public irADetalleMovimientoAcademico(item: IMovimientoAcademico, esRevision: boolean) {
    this.$router.push({
      name: 'DetalleMovimientoAcademico',
      params: { idBeca: this.idBeca, idPeriodo: item.id, esRevision: esRevision.toString() },
    });
  }

  private sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }
}
