var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-form-group",
          {
            attrs: {
              "label-size": "sm",
              "label-for": "criterio-solucion",
              label: "Seleccione una solución",
            },
          },
          [
            _c("b-form-select", {
              staticClass: "mb-3",
              attrs: {
                id: "criterio-solucion",
                size: "sm",
                options: _vm.solucionesOptions,
                "value-field": "id",
                "text-field": "titulo",
              },
              on: { change: _vm.handleSolucionSelected },
              model: {
                value: _vm.filtro.solucionId,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "solucionId", $$v)
                },
                expression: "filtro.solucionId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.filtro.solucionId
          ? [
              _vm.estadosDisponibles?.length > 0
                ? _c(
                    "div",
                    [
                      _vm.estadosDisponibles?.length > 0
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-size": "sm",
                                "label-for": "criterio-estados",
                                label: "Filtrar por estados de la solicitud",
                              },
                            },
                            [
                              _c("estado-tags", {
                                attrs: {
                                  id: "criterio-estados",
                                  isTipoAcreditacion:
                                    _vm.isTipoAcreditacion.isTipoAcreditacion,
                                  estadosDisponibles: _vm.estadosDisponibles,
                                },
                                model: {
                                  value: _vm.filtro.estadosSolicitud,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filtro,
                                      "estadosSolicitud",
                                      $$v
                                    )
                                  },
                                  expression: "filtro.estadosSolicitud",
                                },
                              }),
                              _vm._v(" "),
                              _vm.filtro?.estadosSolicitud?.length <= 0
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "formularioDemo.nombre.validations.required"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-size": "sm",
                            "label-for": "criterio-cvu-solicitante",
                            label: "Filtrar por cvu del solicitante",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "criterio-cvu-solicitante",
                              size: "sm",
                            },
                            model: {
                              value: _vm.filtro.cvuSolicitante,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtro, "cvuSolicitante", $$v)
                              },
                              expression: "filtro.cvuSolicitante",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        this.filtro.propiedades,
                        function (propiedad, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-size": "sm",
                                    label: propiedad.nombre,
                                  },
                                },
                                [
                                  propiedad?.tipoReglaFiltro ===
                                    "TEXT_IS_IN_LIST" ||
                                  propiedad?.tipoReglaFiltro ===
                                    "TEXT_IS_NOT_IN_LIST"
                                    ? _c(
                                        "b-form-select",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            id: index + "-" + propiedad.path,
                                          },
                                          model: {
                                            value:
                                              _vm.filtro.propiedades[index]
                                                .valor,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filtro.propiedades[index],
                                                "valor",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filtro.propiedades[index].valor",
                                          },
                                        },
                                        [
                                          _c("b-form-select-option", {
                                            attrs: {
                                              id:
                                                index +
                                                "-select-blank-value" +
                                                propiedad.path,
                                              value: null,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            propiedad.filtroValores,
                                            function (filtroOption) {
                                              return _c(
                                                "b-form-select-option",
                                                {
                                                  key: filtroOption,
                                                  attrs: {
                                                    id:
                                                      index +
                                                      "-select-options" +
                                                      propiedad.path,
                                                    value: filtroOption,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(filtroOption)
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _c("b-form-input", {
                                        attrs: {
                                          id: index + "-" + propiedad.path,
                                          size: "sm",
                                        },
                                        model: {
                                          value:
                                            _vm.filtro.propiedades[index].valor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filtro.propiedades[index],
                                              "valor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filtro.propiedades[index].valor",
                                        },
                                      }),
                                  _vm._v(" "),
                                  propiedad?.tipoReglaFiltro ===
                                  "TEXT_IS_IN_LIST"
                                    ? _c(
                                        "small",
                                        { staticClass: "text-info" },
                                        [
                                          _vm._v(
                                            " Selecciona un valor de la lista "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  propiedad?.tipoReglaFiltro ===
                                  "TEXT_IS_IN_COMMA_SEPARATED_VALUES"
                                    ? _c(
                                        "small",
                                        { staticClass: "text-info" },
                                        [
                                          _vm._v(
                                            "\n              Ingresa valores separados por coma\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _c("b-alert", { attrs: { show: true, variant: "warning" } }, [
                    _c("p", {
                      staticClass: "text-center text-center",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("accesos.solicitudes.notAllowed")
                        ),
                      },
                    }),
                  ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isTipoAcreditacion.isTipoAcreditacion
          ? _c(
              "b-container",
              [
                _vm.isTipoAcreditacion.isTipoAcreditacionConInst
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", {
                                staticClass: "form-label",
                                attrs: { for: "criterio-nivel-institucion" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.institucion"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "criterio-nivel-institucion",
                                  readonly: "",
                                },
                                model: {
                                  value:
                                    _vm.filtroInstitucion.institucion.nombre,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filtroInstitucion.institucion,
                                      "nombre",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "filtroInstitucion.institucion.nombre",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTipoAcreditacion.isTipoAcreditacionConInst
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", {
                                staticClass: "form-label",
                                attrs: { for: "criterio-nivel-dependencia" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.modalNuevaAutoridad.dependencia"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "criterio-nivel-dependencia" },
                                  on: { change: _vm.onChangeDependencia },
                                  model: {
                                    value: _vm.filtroInstitucion.dependencia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filtroInstitucion,
                                        "dependencia",
                                        $$v
                                      )
                                    },
                                    expression: "filtroInstitucion.dependencia",
                                  },
                                },
                                _vm._l(
                                  _vm.filtroInstitucion.institucion.dependencia,
                                  function (element) {
                                    return _c(
                                      "b-form-select-option",
                                      {
                                        key: element.id,
                                        attrs: { value: element },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(element.nombre) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-col", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", {
                                staticClass: "form-label",
                                attrs: { for: "criterio-nivel-subdependencia" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.modalNuevaAutoridad.subdependencia"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-form-select",
                                {
                                  attrs: {
                                    id: "criterio-nivel-subdependencia",
                                  },
                                  on: { change: _vm.onChangeSubDependencia },
                                  model: {
                                    value: _vm.filtroInstitucion.subDependencia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filtroInstitucion,
                                        "subDependencia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filtroInstitucion.subDependencia",
                                  },
                                },
                                _vm._l(
                                  _vm.filtroInstitucion.dependencia
                                    .subDependencia,
                                  function (element) {
                                    return _c(
                                      "b-form-select-option",
                                      {
                                        key: element.id,
                                        attrs: { value: element },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(element.nombre)
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-col", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", {
                                staticClass: "form-label",
                                attrs: { for: "criterio-nivel-departamento" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.institucion.modalNuevaAutoridad.departamento"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "criterio-nivel-departamento" },
                                  on: { change: _vm.onChangeInstitucion },
                                  model: {
                                    value: _vm.filtroInstitucion.departamento,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filtroInstitucion,
                                        "departamento",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filtroInstitucion.departamento",
                                  },
                                },
                                _vm._l(
                                  _vm.filtroInstitucion.subDependencia
                                    .departamento,
                                  function (element) {
                                    return _c(
                                      "b-form-select-option",
                                      {
                                        key: element.id,
                                        attrs: { value: element },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(element.nombre)
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.isDisabledInforme,
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showModalInformeMensual()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("archeApp.solicitud.infomeMensual")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _vm.isFetching
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "clock",
                                    animation: "spin-pulse",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-modal",
                  { ref: "informeMensualModal", attrs: { size: "xl" } },
                  [
                    _c("h4", {
                      staticClass: "modal-title",
                      attrs: {
                        slot: "modal-title",
                        id: "showModalInformeMensual",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("archeApp.solicitud.tituloModalInforme")
                        ),
                      },
                      slot: "modal-title",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-body pad" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c(
                                "h4",
                                {
                                  attrs: {
                                    id: "TituloModal",
                                    "data-cy": "TituloModal",
                                  },
                                },
                                [
                                  _c("span", {
                                    attrs: { id: "TituloModal" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "archeApp.solicitud.textoModalInforme"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              id: "jhi-confirm-delete-solicitud",
                              "data-cy": "entityConfirmButton",
                              disabled: _vm.isFetching,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.InformeMensual()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "archeApp.solicitud.ejecutarInformeMensual"
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _vm.isFetching
                              ? _c("b-icon", {
                                  attrs: {
                                    icon: "clock",
                                    animation: "spin-pulse",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.hideModalInformeMensual()
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }