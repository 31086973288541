import { Component, Prop, Vue } from 'vue-property-decorator';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import resolveEstadoSolicitudVariant from '@/shared/estados/estados.solicitud.util';

@Component
export default class EstadoSolicitudComponent extends Vue {
  @Prop({ default: null })
  public estado: EstadoSolicitud;

  get variant() {
    return resolveEstadoSolicitudVariant(this.estado);
  }
}
