import { Component, Watch, Prop, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { FiltroParticipacion } from '@/shared/model/filtro-participacion.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';

import JhiDataUtils from '@/shared/data/data-utils.service';
import { Solucion } from '@/shared/model/solucion.model';
import SolicitudService from '@/entities/solicitud/solicitud.service';
import { Solicitud } from '@/shared/model/solicitud.model';
import ParticipanteService from '@/components/participantes/participante.service';
import { IFiltro } from '@/shared/model/filtro.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';

@Component
export default class MatriculaRegistroComponent extends mixins(JhiDataUtils, ApeironComponent) {
  @Inject('solicitudService') public solicitudService: () => SolicitudService;
  @Inject('participanteService') public participanteService: () => ParticipanteService;

  form = { subSeccion: null };
  filtro = new FiltroParticipacion();

  contenedorId = null;
  showNoProgramSelectedMessage = false;
  showNoInstitucionesMessage = false;
  showNoCupoProgramaMessage = false;
  showNoCupoInstitucionMessage = false;
  showNoSedesMessage = false;
  isSedeDuplicated = false;
  participantesKey = 0;
  institucionSelected = null;
  sedeSelected = null;
  dependenciaSelected = null;
  public readonly ROL = RolAutoridad.ALUMNO;

  @Prop({ required: false })
  public solucion: Solucion;

  @Prop({ required: false })
  public solicitud: Solicitud;
  public matOcupada = 0;
  public matTotal = 0;

  public matOcupadaProg = 0;
  public matTotalProg = 0;

  get showParticipantes() {
    return this.sedeSelected !== null;
  }

  created() {
    if (this.formModel?.subSeccion) {
      this.form.subSeccion = { ...this.form.subSeccion, ...JSON.parse(JSON.stringify(this.formModel.subSeccion)) };
    }
  }

  get instituciones() {
    this.getCupoPrograma();
    if (this.formModel?.programa?.instituciones) {
      return this.formModel.programa.instituciones.map(institucion => {
        return { value: institucion, text: institucion.nombreInstitucion };
      });
    } else {
      this.showNoInstitucionesMessage = true;
      return [];
    }
  }

  get duracionOficial() {
    if (this.formModel?.programa?.planDeEstudios?.duracionOficial) {
      return this.formModel?.programa?.planDeEstudios?.duracionOficial;
    } else {
      return 0;
    }
  }

  get sedes() {
    if (this.institucionSelected?.sedes) {
      return this.institucionSelected?.sedes.map(sede => {
        return { value: sede, html: sede.sede + ' - ' + sede.nombreDependencia };
      });
    } else {
      this.showNoSedesMessage = true;
      return [];
    }
  }

  get readOnly() {
    return !this.isEditable;
  }

  get isEditable() {
    return (
      this.permisos.estadoSolicitud === EstadoSolicitud.EN_CAPTURA || this.permisos.estadoSolicitud === EstadoSolicitud.EN_ACTUALIZACION
    );
  }

  public handleChangeInstitucionId(data) {
    this.sedeSelected = null;
    this.showNoSedesMessage = false;
  }
  public handleChangeSedeId(data) {
    this.contenedorId = this.institucionSelected.claveInstitucion + '-' + this.sedeSelected.claveSede;
    this.dependenciaSelected = this.sedeSelected;
    this.isSedeDuplicated = this.IsDuplicatedCurrentSede(this.sedeSelected.claveSede);
    this.participantesKey += 1;
    this.ocultaCupoPrograma;
    this.getCupoInstitucion();
  }

  public IsDuplicatedCurrentSede(currentClaveId) {
    const sedesFiltered = this.institucionSelected.sedes.filter(sede => sede.claveSede === currentClaveId);
    return sedesFiltered.length > 1;
  }

  /**
   * Cuando se selecciona una institución, se actualiza el contador de cupo disponible
   */
  public getCupoInstitucion(): void {
    const filtroSol: IFiltro = {};
    filtroSol.solucionId = this.solucion.id;
    filtroSol.claveInstitucion = this.institucionSelected.claveInstitucion;
    filtroSol.noEstadosSolicitud = [EstadoSolicitud.EN_CAPTURA, EstadoSolicitud.CANCELADA];

    const paginationQuery = {
      page: 0,
      size: 200,
      sort: 'asc.id',
      filter: filtroSol,
    };

    const matInstitucion = this.solucion.params.instituciones.find(
      institucion => institucion.claveInstitucion === this.institucionSelected?.claveInstitucion
    );

    if (matInstitucion?.totalCupo) {
      this.matTotal = parseInt(matInstitucion.totalCupo);
      this.matOcupada = 0;
      this.solicitudService()
        .retrieve(paginationQuery)
        .then(res => {
          const listaSolicitudes = res.data;

          for (const solicitudInsitutucion of listaSolicitudes) {
            const filtroPart: FiltroParticipacion = {};
            filtroPart.solicitudId = solicitudInsitutucion.id;
            filtroPart.rol = this.ROL;
            const paginationQuery = {
              page: 0,
              size: 200,
              sort: 'asc.id',
              filter: filtroPart,
            };

            this.participanteService()
              .retrieve(paginationQuery)
              .then(res => {
                if (res?.data) {
                  for (const participante of res.data) {
                    const claves = participante.contenedorId.split('-');
                    const idContenedor = claves[0];
                    if (participante.rol === this.ROL && idContenedor === this.institucionSelected?.claveInstitucion) {
                      this.matOcupada++;
                    }
                  }
                }
              });
          }
        });
    } else {
      this.showNoCupoInstitucionMessage = true;
    }
  }

  /**
   * Cuando se entra a la pantalla de matrícula, se actualiza el contador de cupo para el programa
   */
  public getCupoPrograma(): void {
    this.matOcupadaProg = 0;
    const filtroSol: IFiltro = {};
    filtroSol.solucionId = this.solucion.id;
    filtroSol.clavePrograma = this.formModel.programa.clave;
    filtroSol.noEstadosSolicitud = [EstadoSolicitud.EN_CAPTURA, EstadoSolicitud.CANCELADA];

    const paginationQuery = {
      page: 0,
      size: 200,
      sort: 'asc.id',
      filter: filtroSol,
    };

    const matPrograma = this.solucion.params.programas.find(programa => programa.clavePrograma === this.formModel.programa.clave);
    if (matPrograma?.totalCupo) {
      this.matTotalProg = parseInt(matPrograma.totalCupo);
      this.matOcupadaProg = 0;
      this.solicitudService()
        .retrieve(paginationQuery)
        .then(res => {
          const listaSolicitudes = res.data;
          for (const solicitudPrograma of listaSolicitudes) {
            const filtroPart: FiltroParticipacion = {};
            filtroPart.solicitudId = solicitudPrograma.id;
            filtroPart.rol = this.ROL;
            const paginationQuery = {
              page: 0,
              size: 200,
              sort: 'asc.id',
              filter: filtroPart,
            };
            this.participanteService()
              .retrieve(paginationQuery)
              .then(res => {
                if (res?.data) {
                  for (const participante of res.data) {
                    if (participante.rol === RolAutoridad.ALUMNO) {
                      this.matOcupadaProg++;
                    }
                  }
                }
              });
          }
        });
    } else {
      this.showNoCupoProgramaMessage = true;
    }
  }

  /**
   * Calcula la cantidad de matricula disponible para el programa seleccionado
   */
  public get matDisponibleProg() {
    return this.matTotalProg - this.matOcupadaProg;
  }

  /**
   * Calcula la cantidad de matricula disponible para la institucion seleccionada
   */
  public get matDisponible() {
    return this.matTotal - this.matOcupada;
  }

  /**
   * Actualiza los contadores de cupo para la institución y el programa
   */
  public actualizarContadoresCupo() {
    if (this.permisos.estadoSolicitud === EstadoSolicitud.EN_ACTUALIZACION) {
      this.getCupoPrograma();
      this.getCupoInstitucion();
    }
  }

  /**
   * Si la matrícula configurada para el programa es menor a 0, no se muestra el contador
   */
  get mostrarContadorPrograma() {
    if (this.matTotalProg < 0 || this.showNoCupoProgramaMessage) {
      return false;
    }
    return true;
  }

  /**
   * Si no hay configurado cupo por institución
   */
  get mostrarContadorInstitucion() {
    if (this.showNoCupoInstitucionMessage) {
      return false;
    }
    return true;
  }

  // Método para determinar si se debe ocultar el cupo del programa
  private validarOcultaCupoPrograma() {
    if (this.institucionSelected) {
      const existeOcultaCupoPrograma = this.solucion.params.instituciones.some(
        institucion => institucion.claveInstitucion === this.institucionSelected.claveInstitucion && institucion.ocultaCupoPrograma === true
      );
      return existeOcultaCupoPrograma;
    } else {
      return true;
    }
  }

  // Método observable para obtener el estado de ocultar el cupo del programa
  get ocultaCupoPrograma() {
    return this.validarOcultaCupoPrograma();
  }
}
