var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.clave"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.clave))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.nombre"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.nombre))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.grado"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.grado))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.caracteristica"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.caracteristica))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.anteDgp"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.anteDgp))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.rvoe"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.rvoe))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.modalidad"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.modalidad))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.claveTipo"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.programa.claveTipo))])]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-size": "lg",
                label: _vm.$t("apeironGwApp.programa.paginaWeb"),
              },
            },
            [
              _c(
                "b-link",
                { attrs: { href: _vm.programa.paginaWeb, target: "_blank" } },
                [_c("span", [_vm._v(_vm._s(_vm.programa.paginaWeb))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }