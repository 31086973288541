import { EstadoMovimientoBeca } from '@/shared/model/enumerations/estado-movimiento-beca.model';

export default function resolveEstadoMovimientoAcademicoVariant(estado: EstadoMovimientoBeca) {
  switch (estado) {
    case EstadoMovimientoBeca.REVISADO:
      return 'success';
    case EstadoMovimientoBeca.PENDIENTE:
      return 'warning';
    case EstadoMovimientoBeca.CAPTURADO:
      return 'danger';
    default:
      return 'primary';
  }
}
