var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        "align-v": "end",
                        offset: "0",
                        "offset-lg": "6",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "apeironGwApp.claustroSnp.acciones.buscar"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.buscarEnClaustro()
                          },
                        },
                        model: {
                          value: _vm.filtroClaustro,
                          callback: function ($$v) {
                            _vm.filtroClaustro = $$v
                          },
                          expression: "filtroClaustro",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        attrs: {
                          striped: "",
                          hover: "",
                          bordered: "",
                          small: "",
                          "head-variant": "dark",
                          items: _vm.claustrosFiltrados,
                          fields: _vm.fieldsClaustro,
                          "show-empty": "",
                          "empty-text": _vm.$t(
                            "apeironGwApp.claustroSnp.tabla.sinRegistros"
                          ),
                          "per-page": _vm.perPage,
                          "current-page": _vm.currentPage,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(participante)",
                            fn: function (data) {
                              return [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      data.item.participante?.nombre
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span"),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      data.item.participante?.apellidoPaterno
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span"),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      data.item.participante?.apellidoMaterno
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(acciones)",
                            fn: function (data) {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: _vm.$t(
                                          "apeironGwApp.claustroSnp.acciones.ver"
                                        ),
                                        expression:
                                          "$t('apeironGwApp.claustroSnp.acciones.ver')",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    attrs: { size: "sm", variant: "outline" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mostrarModalDetalleClaustro(
                                          data.item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "exclamation-lg",
                                        variant: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: _vm.$t(
                                          "apeironGwApp.claustroSnp.acciones.editar"
                                        ),
                                        expression:
                                          "$t('apeironGwApp.claustroSnp.acciones.editar')",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    attrs: {
                                      size: "sm",
                                      variant: "outline",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mostrarModalEditarClaustro(
                                          data.item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "pencil",
                                        variant: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: _vm.$t(
                                          "apeironGwApp.claustroSnp.acciones.eliminar"
                                        ),
                                        expression:
                                          "$t('apeironGwApp.claustroSnp.acciones.eliminar')",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    attrs: {
                                      size: "sm",
                                      variant: "outline",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mostrarModalEliminarClaustro(
                                          data.item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "trash",
                                        variant: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.registros,
                          "per-page": _vm.perPage,
                          "aria-controls": "my-table",
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-modal",
                        {
                          attrs: { id: "modal-eliminar", size: "md" },
                          scopedSlots: _vm._u([
                            {
                              key: "modal-title",
                              fn: function () {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.tituloEliminar"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "modal-footer",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        variant: "primary",
                                        disabled: _vm.readOnly,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.claustroSnp.acciones.eliminar"
                                            )
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.eliminarClaustro()
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { variant: "secondary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.mostrarModalEliminar = false
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.claustroSnp.acciones.cerrar"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.mostrarModalEliminar,
                            callback: function ($$v) {
                              _vm.mostrarModalEliminar = $$v
                            },
                            expression: "mostrarModalEliminar",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "d-block text-center" },
                                [
                                  _c("h3", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.detalleEliminar"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-modal",
                        {
                          attrs: { id: "modal-editar", size: "lg" },
                          scopedSlots: _vm._u([
                            {
                              key: "modal-title",
                              fn: function () {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.tituloEditar"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "modal-footer",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        variant: "primary",
                                        disabled: _vm.readOnly,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editarClaustro()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.claustroSnp.acciones.guardar"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { variant: "secondary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.mostrarModalEditar = false
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.claustroSnp.acciones.cerrar"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.mostrarModalEditar,
                            callback: function ($$v) {
                              _vm.mostrarModalEditar = $$v
                            },
                            expression: "mostrarModalEditar",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "d-block text-center" },
                                [
                                  _c("h3", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.detalleEditar"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                                          ) + " *",
                                        "label-for": "input-tiempo-dedicacion",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "input-tiempo-dedicacion",
                                          options: _vm.tiemposDedicacion,
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                                          ),
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "first",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-form-select-option",
                                                  {
                                                    attrs: {
                                                      value: undefined,
                                                      disabled: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "apeironGwApp.claustroSnp.form.seleccionarUno"
                                                          )
                                                          .toString()
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .tiempoDedicacion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "tiempoDedicacion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.tiempoDedicacion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaIngreso"
                                          ) + " *",
                                        "label-for": "input-fecha-ingreso",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-fecha-ingreso",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaIngreso"
                                          ),
                                          "value-as-date": true,
                                          disabled: _vm.readOnly,
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .claustroFechaIngreso,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "claustroFechaIngreso",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.claustroFechaIngreso",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.fechaBaja"
                                        ),
                                        "label-for": "input-fecha-baja",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-fecha-baja",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaBaja"
                                          ),
                                          disabled: _vm.readOnly,
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .claustroFechaBaja,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "claustroFechaBaja",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.claustroFechaBaja",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-modal",
                        {
                          attrs: {
                            id: "modal-detalle",
                            scrollable: "",
                            size: "lg",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "modal-title",
                              fn: function () {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.tituloDetalle"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "modal-footer",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { variant: "secondary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.mostrarModalDetalle = false
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "apeironGwApp.claustroSnp.acciones.cerrar"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.mostrarModalDetalle,
                            callback: function ($$v) {
                              _vm.mostrarModalDetalle = $$v
                            },
                            expression: "mostrarModalDetalle",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "d-block text-center" },
                                [
                                  _c("h4", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.detalleDetalle"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.login"
                                        ),
                                        "label-for": "input-login",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-login",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.login"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.login,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "login",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.login",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.correo"
                                        ),
                                        "label-for": "input-correo",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-correo",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.correo"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.correo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "correo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.correo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.cvuDocente"
                                        ),
                                        "label-for": "input-cvu",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-cvu",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.cvuDocente"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.cvu,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "cvu",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.cvu",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.nombreDocente"
                                        ),
                                        "label-for": "input-nombre-docente",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-nombre-docente",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.nombreDocente"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.nombre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.nombre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.apellidoPaterno"
                                        ),
                                        "label-for": "input-apellido-paterno",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-apellido-paterno",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.apellidoPaterno"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.apellidoPaterno,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "apellidoPaterno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.apellidoPaterno",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.apellidoMaterno"
                                        ),
                                        "label-for": "input-apellido-materno",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-apellido-materno",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.apellidoMaterno"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.apellidoMaterno,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "apellidoMaterno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.apellidoMaterno",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.curp"
                                        ),
                                        "label-for": "input-curp",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-curp",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.curp"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.curp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "curp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.curp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.genero"
                                        ),
                                        "label-for": "input-genero",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-genero",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.genero"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.genero,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "genero",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.genero",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.nacionalidad"
                                        ),
                                        "label-for": "input-nacionalidad",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-nacionalidad",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.nacionalidad"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.nacionalidad,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "nacionalidad",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.nacionalidad",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.paisResidencia"
                                        ),
                                        "label-for": "input-pais-residencia",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-pais-residencia",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.paisResidencia"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado
                                              .participante.paisResidencia,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .participante,
                                              "paisResidencia",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.participante.paisResidencia",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "d-block text-center mt-5" },
                                [
                                  _c("h4", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.detalleInformacionSni"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.areaConocimiento"
                                        ),
                                        "label-for": "input-area-conocimiento",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-area-conocimiento",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.areaConocimiento"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .areaConocimientoNombre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "areaConocimientoNombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.areaConocimientoNombre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.nivelSni"
                                        ),
                                        "label-for": "input-nivel-sni",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-nivel-sni",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.nivelSni"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .nivelSniNombre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "nivelSniNombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.nivelSniNombre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.fechaInicioNivelSni"
                                        ),
                                        "label-for":
                                          "input-vigencia-fecha-inicio",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-vigencia-fecha-inicio",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaInicioNivelSni"
                                          ),
                                          "value-as-date": true,
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .vigenciaFechaInicio,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "vigenciaFechaInicio",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.vigenciaFechaInicio",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.fechaFinNivelSni"
                                        ),
                                        "label-for": "input-fecha-baja",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-fecha-baja",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaFinNivelSni"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .vigenciaFechaFin,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "vigenciaFechaFin",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.vigenciaFechaFin",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.vigenciaTipo"
                                        ),
                                        "label-for": "input-vigencia-tipo",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-vigencia-tipo",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.vigenciaTipo"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .vigenciaTipo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "vigenciaTipo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.vigenciaTipo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.vigenciaEstatus"
                                        ),
                                        "label-for": "input-vigencia-estatus",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-vigencia-estatus",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.vigenciaEstatus"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .vigenciaEstatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "vigenciaEstatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.vigenciaEstatus",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "d-block text-center mt-5" },
                                [
                                  _c("h4", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.claustroSnp.modal.detalleInformacionClaustro"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.institucion"
                                        ),
                                        "label-for": "input-institucion",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-institucion",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.institucion"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.institucionInfo,
                                          callback: function ($$v) {
                                            _vm.institucionInfo = $$v
                                          },
                                          expression: "institucionInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.sede"
                                        ),
                                        "label-for": "input-sede",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-sede",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.sede"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.sedeInfo,
                                          callback: function ($$v) {
                                            _vm.sedeInfo = $$v
                                          },
                                          expression: "sedeInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.fechaIngreso"
                                        ),
                                        "label-for": "input-fecha-ingreso",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-fecha-ingreso",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaIngreso"
                                          ),
                                          "value-as-date": true,
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .claustroFechaIngreso,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "claustroFechaIngreso",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.claustroFechaIngreso",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.fechaBaja"
                                        ),
                                        "label-for": "input-fecha-baja",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          id: "input-fecha-baja",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.fechaBaja"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.claustroSeleccionado.profesorSnp
                                              .claustroFechaBaja,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claustroSeleccionado
                                                .profesorSnp,
                                              "claustroFechaBaja",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "claustroSeleccionado.profesorSnp.claustroFechaBaja",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                                        ),
                                        "label-for": "input-tiempo-dedicacion",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "input-tiempo-dedicacion",
                                          placeholder: _vm.$t(
                                            "apeironGwApp.claustroSnp.form.tiempoDedicacion"
                                          ),
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.tiempoDedicacionInfo,
                                          callback: function ($$v) {
                                            _vm.tiempoDedicacionInfo = $$v
                                          },
                                          expression: "tiempoDedicacionInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }