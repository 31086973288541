var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "footer bg-light float fixed-bottom" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row align-items-center", attrs: { id: "footer-2" } },
        [
          _c("div", { staticClass: "col" }, [
            _c("p", [
              _vm._v(
                "©" +
                  _vm._s(_vm.today) +
                  " Conahcyt Derechos Reservados en trámite"
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }