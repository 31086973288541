var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-sidebar",
    {
      attrs: {
        id: "sidebar-wrapper",
        visible: _vm.isSideNavbarActivated,
        shadow: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-close-on-route-change": "",
        "no-enforce-focus": "",
        "no-header-close": "",
      },
      model: {
        value: _vm.sidebarOpen,
        callback: function ($$v) {
          _vm.sidebarOpen = $$v
        },
        expression: "sidebarOpen",
      },
    },
    [
      _vm._t("title", function () {
        return [
          _c("div", { staticClass: "text-center logo" }, [
            _c("img", {
              staticClass: "logo-img",
              attrs: {
                src: require("../../../content/images/apeiron.svg"),
                alt: "apeiron",
              },
            }),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "b-navbar-nav",
        { staticClass: "sidebar" },
        [
          _c("div", { staticClass: "sidebar_header mb-3 mt-1" }, [
            _c("div", { staticClass: "text-white" }, [_vm._v("Secciones")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stepper", attrs: { id: "menu-dinamico-id" } },
            [
              !_vm.menu.componentes || _vm.menu.componentes <= 0
                ? _c("message", {
                    attrs: { description: "No hay elementos registrados" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isMenuEditable
                ? [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          staticClass: "list-group",
                          attrs: { tag: "ul" },
                          on: { end: _vm.afterDragging },
                          model: {
                            value: _vm.menu.componentes,
                            callback: function ($$v) {
                              _vm.$set(_vm.menu, "componentes", $$v)
                            },
                            expression: "menu.componentes",
                          },
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      [
                        _c(
                          "transition-group",
                          { attrs: { type: "transition", name: "flip-list" } },
                          [
                            _vm._l(
                              _vm.menu.componentes,
                              function (item, index) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      key: item.formId,
                                      staticClass:
                                        "nav-item pointer nav-link-draggable",
                                      on: {
                                        mouseover: function ($event) {
                                          return _vm.mouseover(item.formId)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.mouseleave(item.formId)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _vm.isHover(item.formId)
                                              ? _c("b-icon", {
                                                  attrs: {
                                                    icon: "grip-vertical",
                                                  },
                                                })
                                              : _c("b-icon", {
                                                  attrs: {
                                                    icon: "grip-vertical",
                                                    variant: "secondary",
                                                  },
                                                }),
                                            _vm._v(" "),
                                            _vm.menu.tipoMenu === "STEPPER"
                                              ? _c("b-avatar", {
                                                  attrs: {
                                                    size: "md",
                                                    variant: "primary",
                                                    text: _vm.resolveStep(
                                                      index
                                                    ),
                                                  },
                                                })
                                              : _c("span", {
                                                  class: `icon-` + item.icon,
                                                }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "item-text ml-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "apeironGwApp.MenuElement." +
                                                        item.menuName
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _vm.isHover(item.formId)
                                              ? _c("b-icon", {
                                                  staticClass:
                                                    "btn-outline-danger",
                                                  attrs: {
                                                    scale: "1.5",
                                                    variant: "outline-danger",
                                                    icon: "x-circle",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDeleteComponent(
                                                        item.formId
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._l(_vm.menu.componentes, function (item, index) {
                    return _c(
                      "b-nav-item",
                      {
                        key: item.formId,
                        ref: "menuNavItem",
                        refInFor: true,
                        staticClass: "pointer",
                        attrs: {
                          exact: "",
                          "exact-active-class": "active",
                          active: _vm.isActive(item.formId),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.loadForm(item, index, false)
                          },
                        },
                      },
                      [
                        _vm.menu.tipoMenu === "STEPPER"
                          ? _c("b-avatar", {
                              attrs: {
                                variant: _vm.resolveStepVariant(index),
                                text: _vm.resolveStep(index),
                              },
                            })
                          : _c("span", { class: `icon-` + item.icon }),
                        _vm._v(" "),
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "apeironGwApp.MenuElement." + item.menuName
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.menu.tipoMenu === "STEPPER" &&
                        index < _vm.menu.componentes.length - 1
                          ? _c("div", { staticClass: "v-line" })
                          : _vm.menu.tipoMenu === "STEPPER" &&
                            _vm.menu.isAccionesActivated
                          ? _c("div", { staticClass: "v-line-long" })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.menu.isAccionesActivated
            ? [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      id: "acciones-id",
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("ACCIONES"),
                    },
                    on: { click: _vm.loadAccionesForm },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", {
                          attrs: {
                            variant: _vm.isActive("ACCIONES")
                              ? "primary"
                              : "secondary",
                            text: _vm.lastStepNumber,
                          },
                        })
                      : _c("span", { staticClass: "icon-enlace-2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Acciones"),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.isOtrasRevisionesActivated
            ? [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      id: "otra-revisiones-id",
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("OTRAS_REVISIONES"),
                    },
                    on: { click: _vm.loadOtrasRevisiones },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", { attrs: { variant: "warning" } }, [
                          _c("strong", [_vm._v("R")]),
                        ])
                      : _c("span", { staticClass: "icon-mensajes" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Revisiones realizadas"),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.isCuestionarioActivated
            ? [
                _c("div", { staticClass: "sidebar_header mb-3 mt-1" }, [
                  _c("div", { staticClass: "text-white" }, [
                    _vm._v("Mi Revisión"),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.menu.revisionComponentes ||
                _vm.menu.revisionComponentes <= 0
                  ? _c("message", {
                      attrs: { description: "No hay formularios registrados" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.menu.revisionComponentes, function (item, index) {
                  return _c(
                    "b-nav-item",
                    {
                      key: item.formId,
                      ref: "menuRevisionNavItem",
                      refInFor: true,
                      staticClass: "pointer",
                      attrs: {
                        exact: "",
                        "exact-active-class": "active",
                        active: _vm.isActive(item.formId),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.loadForm(item, index, true)
                        },
                      },
                    },
                    [
                      _vm.menu.tipoMenu === "STEPPER"
                        ? _c("b-avatar", {
                            attrs: {
                              variant: _vm.resolveStepVariantForReview(index),
                              text: _vm.resolveStep(index),
                            },
                          })
                        : _c("span", { class: `icon-` + item.icon }),
                      _vm._v(" "),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apeironGwApp.MenuElement." + item.menuName)
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.menu.tipoMenu === "STEPPER"
                        ? _c("div", { staticClass: "v-line" })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("ENVIAR_ENCUESTA"),
                    },
                    on: { click: _vm.loadSendReviewForm },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", {
                          attrs: { variant: "success", text: "E" },
                        })
                      : _c("span", { staticClass: "icon-enlace-2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Enviar Revisión"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.isAsignarRevisorActivated
            ? [
                _c("div", { staticClass: "mt-3 sidebar_header mb-3 mt-1" }, [
                  _c("div", { staticClass: "text-white" }, [
                    _vm._v("Administrar"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("ASIGNAR_REVISOR"),
                    },
                    on: { click: _vm.loadAssignReviewerForm },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", { attrs: { variant: "info" } }, [
                          _c("strong", [_vm._v("R")]),
                        ])
                      : _c("span", { staticClass: "icon-comunidades" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Asignar revisor"),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.isAceptarRevisionActivated
            ? [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("ACEPTAR_REVISAR"),
                    },
                    on: { click: _vm.loadAceptarRevisionForm },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", { attrs: { variant: "warning" } }, [
                          _c("strong", [_vm._v("A")]),
                        ])
                      : _c("span", { staticClass: "icon-calificacion" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Aceptar revisar"),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.menu.isComentariosActivated
            ? [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      id: "comentarios-id",
                      exact: "",
                      "exact-active-class": "active",
                      active: _vm.isActive("COMENTARIOS"),
                    },
                    on: { click: _vm.loadComentariosForm },
                  },
                  [
                    _vm.menu.tipoMenu === "STEPPER"
                      ? _c("b-avatar", { attrs: { variant: "success" } }, [
                          _c("strong", [_vm._v("C")]),
                        ])
                      : _c("span", { staticClass: "icon-mensajes" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v("Ver comentarios"),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              staticClass: "d-inline-block",
              attrs: { exact: "", "exact-active-class": "active" },
              on: { click: _vm.previousState },
            },
            [
              _c("b-icon", {
                staticClass: "ml-2",
                attrs: {
                  icon: "arrow-left-circle",
                  scale: "1.2",
                  variant: "primary",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-1 item-text font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("entity.action.return"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.menu.isPreviewActivated
            ? [
                _c(
                  "div",
                  { staticClass: "p-3" },
                  [
                    !_vm.isPreview
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              id: "activar-vista-previa-id",
                              block: "",
                              variant: "outline-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleActivatePreview(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          Activar vista previa\n          "
                            ),
                            _c("b-icon", { attrs: { icon: "eye-slash" } }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleActivatePreview(false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Desactivar vista previa\n            "
                                ),
                                _c("b-icon", {
                                  attrs: { icon: "eye", animation: "fade" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.solicitante
            ? _c("b-card", { staticClass: "ml-2 mr-2 mt-5" }, [
                _c("div", [
                  _c("small", [
                    _vm._v(
                      _vm._s(_vm.solicitante.nombre) +
                        " " +
                        _vm._s(_vm.solicitante.apellidoPaterno) +
                        " " +
                        _vm._s(_vm.solicitante.apellidoMaterno)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("small", [
                    _vm._v("ID Rizoma "),
                    _c("strong", [_vm._v(_vm._s(_vm.solicitante.login))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("small", [
                    _vm._v("CVU "),
                    _c("strong", [_vm._v(_vm._s(_vm.solicitante.cvu))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("estado-solicitud", { attrs: { estado: _vm.estado } })],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }