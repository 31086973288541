import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SeleccionTablas } from './seleccion-tablas.model';

@Component
export default class SelecciontablasComponent extends Vue {
  @Prop({ required: true })
  public opciones: Array<any>;

  @Prop({
    required: true,
    default: function () {
      return [];
    },
  })
  public keyComp: Array<string>;

  @Prop({
    required: false,
    default: function () {
      return [];
    },
  })
  public inicial: Array<any>;

  @Prop({
    required: false,
    default: function () {
      return false;
    },
  })
  public disabled: boolean;

  @Prop({
    required: false,
    default: function () {
      return true;
    },
  })
  public state: boolean;

  public opcionesBase: Array<SeleccionTablas> = [];
  public seleccionadoBase: Array<SeleccionTablas> = [];

  public opcionesFiltrado: Array<SeleccionTablas> = [];
  public seleccionFiltrado: Array<SeleccionTablas> = [];

  public filtroOpciones = '';
  public filtroSelecciones = '';

  public mounted(): void {
    this.iniciarComponente();
  }

  @Watch('inicial')
  public inicialWatch(): void {
    this.iniciarComponente();
  }

  private iniciarComponente(): void {
    this.opcionesBase = [];
    this.seleccionadoBase = [];

    this.opciones.forEach(currItem => {
      this.opcionesBase.push({ item: currItem, text: this.obtenerTextoVisible(currItem) });
    });
    this.inicial.forEach(currItem => {
      this.seleccionadoBase.push({ item: currItem, text: this.obtenerTextoVisible(currItem) });
      this.opcionesBase = this.opcionesBase.filter(el => el.text != currItem.text);
    });
    this.refrescar();
  }

  private refrescar(): void {
    this.filtrarSelecciones();
    this.filtrarOpciones();
    this.cambioSeleccion();
  }

  public pasarTodoASelecciones(): void {
    if (!this.disabled) {
      this.opcionesBase.forEach(item => {
        this.seleccionadoBase.push(item);
      });
      this.opcionesBase = [];
      this.refrescar();
    }
  }

  public pasarTodoAOpciones(): void {
    if (!this.disabled) {
      this.seleccionadoBase.forEach(item => {
        this.opcionesBase.push(item);
      });
      this.seleccionadoBase = [];
      this.refrescar();
    }
  }

  public pasarASelecciones(item: SeleccionTablas): void {
    if (!this.disabled) {
      this.seleccionadoBase.push(item);
      this.opcionesBase = this.opcionesBase.filter(el => el.text != item.text);
      this.refrescar();
    }
  }

  public pasarAOpciones(item: SeleccionTablas): void {
    if (!this.disabled) {
      this.opcionesBase.push(item);
      this.seleccionadoBase = this.seleccionadoBase.filter(el => el.text != item.text);
      this.refrescar();
    }
  }

  public filtrarOpciones(): void {
    this.opcionesFiltrado = [];
    this.opcionesBase.forEach(item => {
      if (this.filtroOpciones) {
        if (item.text.toLowerCase().includes(this.filtroOpciones.toLowerCase())) {
          this.opcionesFiltrado.push(item);
        }
      } else {
        this.opcionesFiltrado.push(item);
      }
    });
    this.opcionesFiltrado.sort(this.sortItems);
  }

  public filtrarSelecciones(): void {
    this.seleccionFiltrado = [];
    this.seleccionadoBase.forEach(item => {
      if (this.filtroSelecciones) {
        if (item.text.toLowerCase().includes(this.filtroSelecciones.toLowerCase())) {
          this.seleccionFiltrado.push(item);
        }
      } else {
        this.seleccionFiltrado.push(item);
      }
    });
    this.seleccionFiltrado.sort(this.sortItems);
  }

  private sortItems(a: SeleccionTablas, b: SeleccionTablas): number {
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  private obtenerTextoVisible(opcion: any): string {
    let texto = '';

    this.keyComp.forEach(currKey => {
      if (texto.length > 0) {
        texto += ' - ';
      }
      texto += opcion[currKey];
    });

    return texto;
  }

  private cambioSeleccion(): void {
    const seleccion = [];

    this.seleccionadoBase.forEach(elem => {
      seleccion.push(elem.item);
    });

    this.$emit('change-selection', seleccion);
  }
}
