var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticClass: "mb-4" },
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-3", fill: "" } },
        _vm._l(8, function (index) {
          return _c("b-tab", {
            key: index,
            staticClass: "mt-4",
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [_c("b-skeleton", { attrs: { type: "button" } })],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [_c("b-skeleton-img", { attrs: { "no-aspect": "", height: "250px" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-2 tmb-5 mt-5 float-right" },
        [_c("b-skeleton", { attrs: { type: "button" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }