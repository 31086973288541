import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';

export default function resolveEstadoSolicitudVariant(estado: EstadoSolicitud) {
  switch (estado) {
    case EstadoSolicitud.EN_CAPTURA:
      return 'primary';
    case EstadoSolicitud.ENVIADA:
      return 'success';
    case EstadoSolicitud.CANCELADA:
      return 'danger';
    case EstadoSolicitud.REVISADA:
      return 'info';
    case EstadoSolicitud.APROBADA:
      return 'success';
    case EstadoSolicitud.RECHAZADA:
      return 'danger';
    case EstadoSolicitud.BAJA:
      return 'secondary';
    case EstadoSolicitud.RECLASIFICADA:
      return 'success';
    case EstadoSolicitud.FORMALIZADA:
      return 'warning';
    case EstadoSolicitud.OPERACION_EN_PROCESO:
      return 'dark';
    case EstadoSolicitud.TERMINADO:
      return 'success';
    case EstadoSolicitud.EN_EVALUACION:
      return 'primary';
    case EstadoSolicitud.EVALUADA:
      return 'primary';
    case EstadoSolicitud.EN_ASIGNACION:
      return 'primary';
    case EstadoSolicitud.PREFORMALIZADA:
      return 'info';
    case EstadoSolicitud.ACTUALIZADA:
      return 'info';
    default:
      return 'primary';
  }
}

export function resolveEstadoRevisionVariant(estado: EstadoRevision) {
  switch (estado) {
    case EstadoRevision.ASIGNADA:
      return 'info';
    case EstadoRevision.ACEPTADA:
      return 'primary';
    case EstadoRevision.RECHAZADA:
      return 'warning';
    case EstadoRevision.REVISADA:
      return 'success';
    case EstadoRevision.CANCELADA:
      return 'danger';
    default:
      return 'info';
  }
}

export function resolveEstadoSolicitudIcon(estado: EstadoSolicitud) {
  switch (estado) {
    case EstadoSolicitud.EN_CAPTURA:
      return 'pen';
    case EstadoSolicitud.ENVIADA:
      return 'cursor';
    case EstadoSolicitud.CANCELADA:
      return 'x-circle';
    case EstadoSolicitud.REVISADA:
      return 'clipboard-check';
    case EstadoSolicitud.APROBADA:
      return 'check-circle';
    case EstadoSolicitud.RECHAZADA:
      return 'reply-all';
    case EstadoSolicitud.BAJA:
      return 'arrow-down-circle';
    case EstadoSolicitud.RECLASIFICADA:
      return 'currency-exchange';
    case EstadoSolicitud.FORMALIZADA:
      return 'award';
    case EstadoSolicitud.OPERACION_EN_PROCESO:
      return 'gear';
    case EstadoSolicitud.TERMINADO:
      return 'flag';
    case EstadoSolicitud.IMPROCEDENTE:
      return 'x-square';
    case EstadoSolicitud.EN_EVALUACION:
      return 'clipboard-check';
    case EstadoSolicitud.EVALUADA:
      return 'clipboard-check';
    case EstadoSolicitud.EN_ASIGNACION:
      return 'pen';
    case EstadoSolicitud.PREFORMALIZADA:
      return 'award';
    case EstadoSolicitud.ACTUALIZADA:
      return 'cursor';
    default:
      return 'phone';
  }
}
