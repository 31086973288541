import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CollapseItemComponent extends Vue {
  @Prop({ default: '' })
  public title: string;

  @Prop({ default: true })
  public visible: boolean;
}
