var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.items && _vm.items.length > 0
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm.title
              ? _c("h3", { staticClass: "m-h3" }, [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-card",
                {
                  key: item.id,
                  staticClass: "mb-4",
                  attrs: { "bg-variant": _vm.isEditable(item) ? "" : "light" },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "center-felx card-contacto",
                          attrs: { col: "", lg: "1" },
                        },
                        [_c("span", { class: item.icon })]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "felx-2" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "left-felx",
                              attrs: { col: "", lg: "9" },
                            },
                            [
                              _c(
                                "b-card-text",
                                [
                                  item.router
                                    ? _c("router-link", {
                                        attrs: {
                                          to: item.router.edit.to,
                                          custom: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ navigate }) {
                                                return [
                                                  _c(
                                                    "b-link",
                                                    { on: { click: navigate } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-bolder text-rizoma h4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm.hasEditableButtons(item)
                                    ? _c("router-link", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          custom: "",
                                          to: _vm.findFirstEditableDestino(
                                            item
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ navigate }) {
                                                return [
                                                  _c(
                                                    "b-link",
                                                    { on: { click: navigate } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-bolder text-rizoma h4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bolder text-black h4",
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                  _vm._v(" "),
                                  item.description
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.description),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.solicitante
                                    ? _c("div", [
                                        _c("div", { staticClass: "mt-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.tableView.solicitante"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v(_vm._s(item.solicitante)),
                                          ]),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(item.columnas, function (desc, index) {
                                    return _c("div", { key: index }, [
                                      desc.hasAccess && desc.show
                                        ? _c("div", { staticClass: "mt-2" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(desc.title) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      desc.hasAccess && desc.show
                                        ? _c("div", [
                                            _c("strong", [
                                              _vm._v(_vm._s(desc.description)),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }),
                                  _vm._v(" "),
                                  item.pieDePagina
                                    ? _c("div", {
                                        staticClass: "text-muted",
                                        staticStyle: {
                                          "white-space": "pre-line",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(item.pieDePagina),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "right-felx",
                              attrs: { col: "", lg: "2" },
                            },
                            _vm._l(item.badge, function (b) {
                              return _c(
                                "span",
                                { key: b.id, staticClass: "rt-1" },
                                [
                                  b.badge
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: b.variant
                                              ? b.variant
                                              : "light",
                                          },
                                        },
                                        [_vm._v(_vm._s(b.badge) + " ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isCardFooterVisible(item)
                    ? _c(
                        "b-row",
                        { staticClass: "boder-top" },
                        [
                          _c("b-col", { attrs: { col: "", cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "btn-group float-right" },
                              _vm._l(item.buttons, function (button, index) {
                                return _c("router-link", {
                                  key: index,
                                  staticClass: "mr-2",
                                  attrs: { to: button.destino, custom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ navigate }) {
                                          return [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "outline-primary",
                                                },
                                                on: { click: navigate },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-md-inline",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(button.nombre)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  class: "icon-" + button.icon,
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("c-delete-modal", {
              attrs: { id: _vm.id + "removeEntity" },
              on: {
                onDelete: function ($event) {
                  return _vm.remove()
                },
              },
            }),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        )
      : _c(
          "div",
          [
            _c(
              "b-col",
              { staticClass: "alert-info-card" },
              [
                _c("div", { staticClass: "mb-4" }),
                _vm._v(" "),
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c("b-col"),
                    _vm._v(" "),
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "rounded-circle shadow p-3 mt-1 bg-white d-inline-block",
                        },
                        [_c("span", { staticClass: "icon-info" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      {
                        staticClass: "m-3 p-3 text-center",
                        attrs: { cols: "6" },
                      },
                      [
                        _c("span", { staticClass: "float-right text-center" }, [
                          _c("p", {
                            staticClass: "text-secondary",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.messages.alert.add-items")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("h3", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.messages.alert.info")
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-col"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }