export interface IFile {
  contentType?: string;
  nombre?: string;
  title?: string;
  size?: number;
  uri?: string;
  check?: boolean | null;
  firma?: string;
  fecha?: string;
  comentarios?: IBitacoraDocumento[];
  comentario?: string;
}

export interface IBitacoraDocumento {
  descripcion?: string;
  fecha?: Date;
  uri?: string;
  check?: boolean;
  usuarioId?: string;
  id?: string;
  nombreArchivo?: string;
}

export class File implements IFile {
  constructor(
    public contentType?: string,
    public nombre?: string,
    public title?: string,
    public size?: number,
    public uri?: string,
    public check?: boolean | null,
    public firma?: string,
    public fecha?: string,
    public comentarios?: IBitacoraDocumento[],
    public comentario?: string
  ) {
    this.contentType = this.contentType ? this.contentType : '';
    this.nombre = this.nombre ? this.nombre : '';
    this.title = this.title ? this.title : '';
    this.size = this.size ? this.size : null;
    this.uri = this.uri ? this.uri : null;
    this.check = this.check ? this.check : null;
    this.firma = this.firma ? this.firma : null;
    this.fecha = this.fecha ? this.fecha : null;
  }
}

export class BitacoraDocumento implements IBitacoraDocumento {
  constructor(
    public descripcion?: string,
    public fecha?: Date,
    public uri?: string,
    public check?: boolean | null,
    public usuarioId?: string,
    public id?: string,
    public nombreArchivo?: string
  ) {}
}
