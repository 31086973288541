var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          staticClass: "mt-3",
          attrs: {
            "no-body": "",
            "footer-bg-variant": "white",
            "footer-border-variant": "white",
          },
        },
        [
          !_vm.visible
            ? _c("b-card-header", {
                staticClass: "p-1",
                attrs: { "header-tag": "header" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [_c("b-card-body", [_vm._t("default")], 2)],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              !_vm.visible
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "font-weight-bolder text-rizoma" },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("b-icon", {
                          staticClass: "i-selected",
                          attrs: {
                            variant: "primary",
                            "font-scale": "1.2",
                            icon: "chevron-down",
                          },
                          on: {
                            click: function ($event) {
                              _vm.visible = true
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("b-icon", {
                    staticClass: "i-selected",
                    attrs: {
                      variant: "primary",
                      "font-scale": "1.2",
                      icon: "chevron-up",
                    },
                    on: {
                      click: function ($event) {
                        _vm.visible = false
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }