import axios from 'axios';

/**
 * Servicio para refrescar el token
 */
export default class PollingService {
  private pollingAuthenticate: any;
  private errorCount = 0;

  public init() {
    if (!this.pollingAuthenticate) {
      this.pollingAuthenticate = setInterval(() => {
        this.getAuthenticate();
      }, 90000);
    }
  }

  private getAuthenticate() {
    axios
      .get('api/authenticate')
      .then(res => {
        if (!res.data) {
          this.stop();
        }
      })
      .catch(err => {
        if (this.errorCount > 10) {
          this.stop();
        } else {
          this.errorCount++;
        }
      });
  }

  public stop() {
    if (this.pollingAuthenticate) {
      clearInterval(this.pollingAuthenticate);
    }
  }
}
