export interface IFiltroAutoridad {
  nombre?: string | null;
  apellidoMaterno?: string | null;
  apellidoPaterno?: string | null;
  cvu?: string | null;
  estado?: string | null;
  institucion?: string | null;
}

export class FiltroAutoridad implements IFiltroAutoridad {
  constructor(
    public nombre?: string,
    public apellidoMaterno?: string | null,
    public apellidoPaterno?: string | null,
    public cvu?: string | null,
    public estado?: string | null,
    public institucion?: string | null
  ) {}
}
