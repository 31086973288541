import { Component, Vue, Prop } from 'vue-property-decorator';

import { Solucion } from '@/shared/model/solucion.model';
import { Variable } from '@/components/variables/variables.model';

@Component
export default class VariablesComponent extends Vue {
  @Prop({ required: false })
  public value: Solucion;

  public isNavbarVisible = true;

  get variables(): Variable[] {
    return this.resolveVariables(this.value);
  }

  public resolveVariables(solucion: any): Variable[] {
    return [
      new Variable('Datos generales', 'id', 'Id de la solución', 'solucion.id'),
      new Variable('Datos generales', 'titulo', 'Título de la solución', 'solucion.titulo'),
    ];
  }

  public openFilter() {
    this.isNavbarVisible = true;
  }

  public closeFilter() {
    this.isNavbarVisible = false;
  }
}
