var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    {
      staticClass: "mt-3 p-0",
      attrs: {
        "no-body": "",
        "footer-bg-variant": "white",
        "footer-border-variant": "white",
      },
    },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-0 ml-3 mr-3",
          attrs: { "header-text-variant": "white" },
        },
        [
          !_vm.componenteVisible
            ? _c(
                "b-row",
                {
                  staticClass: "p-4",
                  staticStyle: {
                    "border-radius": "1.5rem",
                    "background-color": "#002663",
                  },
                },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-icon", {
                        staticClass: "i-selected",
                        attrs: { "font-scale": "1.2", icon: "plus-square" },
                        on: {
                          click: function ($event) {
                            _vm.componenteVisible = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "font-weight-bold ml-3",
                        staticStyle: { "font-size": "1.5rem" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.periodoEvaluacion.label.periodosAcademicos"
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-row",
                {
                  staticClass: "p-4",
                  staticStyle: {
                    "border-top-left-radius": "1.5rem",
                    "border-top-right-radius": "1.5rem",
                    "background-color": "#002663",
                  },
                },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-icon", {
                        staticClass: "i-selected",
                        attrs: { "font-scale": "1.2", icon: "dash-square" },
                        on: {
                          click: function ($event) {
                            _vm.componenteVisible = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "font-weight-bold ml-3",
                        staticStyle: { "font-size": "1.5rem" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "apeironGwApp.periodoEvaluacion.label.periodosAcademicos"
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          model: {
            value: _vm.componenteVisible,
            callback: function ($$v) {
              _vm.componenteVisible = $$v
            },
            expression: "componenteVisible",
          },
        },
        [
          _c(
            "b-card-body",
            { staticClass: "mr-4 ml-4" },
            [
              _c("b-row", [_c("b-col")], 1),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        staticClass: "text-nowrap mt-3",
                        attrs: {
                          responsive: "",
                          "sticky-header": "70vh",
                          "show-empty": "",
                          "head-variant": "primary",
                          fields: _vm.fields,
                          items: _vm.periodosList,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(estadoCaratula)",
                            fn: function (data) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "descripcion",
                                    staticStyle: {
                                      width: "14rem",
                                      height: "2rem",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticStyle: { "font-size": "1rem" },
                                        attrs: {
                                          pill: "",
                                          variant: data.item.variant,
                                        },
                                      },
                                      [_vm._v(_vm._s(data.item.estadoCaratula))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(acciones)",
                            fn: function ({ item }) {
                              return [
                                item.permiteRevisar
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.right",
                                                value: _vm.$t(
                                                  "apeironGwApp.periodoEvaluacion.label.revisarPeriodo"
                                                ),
                                                expression:
                                                  "$t('apeironGwApp.periodoEvaluacion.label.revisarPeriodo')",
                                                modifiers: {
                                                  hover: true,
                                                  right: true,
                                                },
                                              },
                                            ],
                                            attrs: { variant: "outline" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.irADetalleMovimientoAcademico(
                                                  item,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "icon-editar",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.permiteConsulta
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.right",
                                                value: _vm.$t(
                                                  "apeironGwApp.periodoEvaluacion.label.consultarPeriodo"
                                                ),
                                                expression:
                                                  "$t('apeironGwApp.periodoEvaluacion.label.consultarPeriodo')",
                                                modifiers: {
                                                  hover: true,
                                                  right: true,
                                                },
                                              },
                                            ],
                                            attrs: { variant: "outline" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.irADetalleMovimientoAcademico(
                                                  item,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "icon-ver",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c(
                                  "b-row",
                                  { attrs: { "align-h": "center" } },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.periodoEvaluacion.mensajes.sinPeriodos"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.totalItems > 0
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { sm: "12", md: "12", lg: "4" },
                                },
                                [
                                  _c("p", [
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "text-muted font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("global.table.totalRows", {
                                              totalRows: _vm.totalItems,
                                            })
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }