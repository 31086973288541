import { Catalogo } from '@/components/movimientos/registro-movimiento/movimientos.catalog';
import { DataType } from '@/shared/model/enumerations/data-type.model';
import { TipoLayout } from '@/shared/model/enumerations/tipo-layout.model';

/**
 * Clase con las principales constantes para una acreditación
 */

function formatBol(value) {
  if (!value) {
    return false;
  }
  return value;
}

export class Fields {
  static tipoMovimientoOpciones = Catalogo.movimientosAsOptions();
  static aplicaPagoOpciones = Catalogo.aplicaPagoAsValueOptions();
  static tipoParticipacionOpciones = Catalogo.tipoParticipacionAsValueOptions();

  static resolveBaseLayout(tipoLayout: TipoLayout) {
    const situacionOpciones = Catalogo.situacionesValueOptions(tipoLayout);

    return [
      { key: 'state', label: '#' },
      { key: 'check', label: '#' },
      { key: 'cvu', label: 'Número CVU', dataType: DataType.NUMBER },
      {
        key: 'situacion',
        label: 'Situación',
        catalog: true,
        options: situacionOpciones,
        class: 'column-lg',
      },
      { key: 'inicio', label: 'Inicio', dataType: DataType.DATE, class: 'column-md' },
      { key: 'fin', label: 'Fin', dataType: DataType.DATE, class: 'column-md' },
      {
        key: 'tipoParticipacion',
        label: 'Tipo de participación',
        catalog: true,
        options: Fields.tipoParticipacionOpciones,
        class: 'column-lg',
      },
      { key: 'institucion', label: 'Institución', dataType: DataType.STRING, class: 'column-lg' },
      { key: 'dependencia', label: 'Dependencia', dataType: DataType.STRING, class: 'column-lg' },
      { key: 'subdependencia', label: 'Subdependencia', dataType: DataType.STRING, class: 'column-lg' },
      { key: 'departamento', label: 'Departamento', dataType: DataType.STRING, class: 'column-lg' },
    ];
  }
  static getFields(tipoLayout: TipoLayout) {
    const baseLayout = Fields.resolveBaseLayout(tipoLayout);
    if (tipoLayout === TipoLayout.PROGRAMAS) {
      baseLayout.push({ key: 'institucionComision', dataType: DataType.STRING, label: 'Institución de Comisión', class: 'column-lg' });
      baseLayout.push({ key: 'dependenciaComision', dataType: DataType.STRING, label: 'Dependencia de Comisión', class: 'column-lg' });
      baseLayout.push({
        key: 'subdependenciaComision',
        dataType: DataType.STRING,
        label: 'Subdependencia de Comisión',
        class: 'column-lg',
      });
      baseLayout.push({ key: 'departamentoComision', dataType: DataType.STRING, label: 'Departamento de Comisión', class: 'column-lg' });
    }
    baseLayout.push({ key: 'actions', label: '#' });
    return baseLayout;
  }
}
