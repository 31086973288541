import { IDomicilio } from './domicilio.model';
import { IInstitucion } from './institucion.model';
export interface IPersona {
  cvu?: string;
  curp?: string;
  titulo?: string;
  nombre?: string;
  apellidoPaterno?: string;
  apellidoMaterno?: string;
  rfc?: string;
  genero?: string;
  correo?: string;
  login?: string;
  gradoAcademico?: string;
  nivelAcademico?: string;
  nivelsni?: string;
  instituciones?: IInstitucion[];
  domicilios?: IDomicilio[];
  paisResidencia?: string;
  paisResidenciaCodigo?: string;
  nacionalidad?: string;
  nacionalidadCodigo?: string;
  rcea?: string;
  fotografiaUri?: string;
  fechaNacimiento?: string;
}
export class Persona implements IPersona {
  constructor(
    public cvu?: string | undefined,
    public curp?: string | undefined,
    public fechaNacimiento?: string | undefined,
    public titulo?: string | undefined,
    public nombre?: string | undefined,
    public apellidoPaterno?: string | undefined,
    public apellidoMaterno?: string | undefined,
    public rfc?: string | undefined,
    public genero?: string | undefined,
    public correo?: string | undefined,
    public login?: string | undefined,
    public gradoAcademico?: string | undefined,
    public nivelAcademico?: string | undefined,
    public nivelsni?: string | undefined,
    public instituciones?: IInstitucion[] | undefined,
    public domicilios?: IDomicilio[] | undefined,
    public paisResidencia?: string | undefined,
    public paisResidenciaCodigo?: string | undefined,
    public nacionalidad?: string | undefined,
    public nacionalidadCodigo?: string | undefined,
    public rcea?: string | undefined,
    public fotografiaUri?: string | undefined
  ) {}
}
