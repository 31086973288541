import { Component, Prop, Vue } from 'vue-property-decorator';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import { Permiso } from '@/shared/model/proceso/permiso.model';

@Component
export default class PermisosComponent extends Vue {
  @Prop({ required: true })
  value: Permiso[];

  @Prop({ required: false })
  readonly: boolean;

  public roles = [];

  public acciones = [];

  public campos = ['avatar', 'rol', 'acciones'];

  public permiso = new Permiso();

  public currentIndex = 0;

  public isAddMode = false;

  public tiposAviso = [];

  created(): void {
    if (!this.readonly) {
      this.campos.push('opciones');
    }
    this.roles = this.resolveRoles();
    this.acciones = this.resolveAcciones();
  }

  public handleEditar(index) {
    this.isAddMode = false;
    this.currentIndex = index;
    this.permiso = { ...this.permisos[index] };
    if (<any>this.$refs.addPermiso) {
      (<any>this.$refs.addPermiso).show();
    }
  }

  public resolveRoles() {
    const roles = [];
    const rolesKeys = Object.keys(RolAutoridad);

    rolesKeys.forEach(key => {
      roles.push({ value: key, text: this.$t('apeironGwApp.RolAutoridad.' + key), disabled: false });
    });
    return roles;
  }

  public resolveAcciones() {
    const acciones = [];
    const accionesKeys = Object.keys(TipoAccion);

    accionesKeys.forEach(key => {
      acciones.push({ value: key, text: this.$t('apeironGwApp.TipoAccion.' + key), disabled: false });
    });
    return acciones;
  }

  get permisos() {
    return this.value;
  }

  set permisos(permisos: Permiso[]) {
    this.$emit('input', permisos);
  }

  public handleOpenAddPermisoModal(): void {
    this.isAddMode = true;
    this.permiso = new Permiso();
    if (<any>this.$refs.addPermiso) {
      (<any>this.$refs.addPermiso).show();
    }
  }

  public handleCloseAddPermisoModal(): void {
    if (<any>this.$refs.addPermiso) {
      (<any>this.$refs.addPermiso).hide();
    }
  }

  public handleAddPermiso(): void {
    if (this.isAddMode) {
      this.permisos.push(this.permiso);
    } else {
      this.permisos.splice(this.currentIndex, 1, this.permiso);
    }
    if (<any>this.$refs.addPermiso) {
      (<any>this.$refs.addPermiso).hide();
    }
  }

  public handlePrepareToRemove(currentIndex): void {
    this.currentIndex = currentIndex;
    if (<any>this.$refs.confirmRemoveModal) {
      (<any>this.$refs.confirmRemoveModal).show();
    }
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public handleConfirmation(): void {
    this.permisos.splice(this.currentIndex, 1);
    this.hideModal();
  }

  public hideModal() {
    (this.$refs.confirmRemoveModal as any).hide();
  }
}
