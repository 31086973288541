var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c(
        "b-form-group",
        {
          staticClass: "col-6",
          attrs: {
            label: _vm.$t("formularioDemo.nombre.label"),
            "label-for": "input-nombre-id",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "input-nombre-id",
              placeholder: _vm.$t("formularioDemo.nombre.placeholder"),
              "aria-describedby": "input-live-help input-live-feedback",
              state: !_vm.$v.form.subSeccion.nombre.$invalid,
            },
            model: {
              value: _vm.$v.form.subSeccion.nombre.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.subSeccion.nombre, "$model", $$v)
              },
              expression: "$v.form.subSeccion.nombre.$model",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: "input-live-feedback" } },
            [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$t("formularioDemo.nombre.validations.required"))
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("b-form-text", { attrs: { id: "input-live-help" } }, [
            _vm._v(_vm._s(_vm.$t("formularioDemo.nombre.description"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { disabled: _vm.$v.form.$invalid, variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.save(_vm.form)
            },
          },
        },
        [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("entity.action.save")) },
          }),
          _vm._v(" "),
          _c("b-icon", { attrs: { icon: "save" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }