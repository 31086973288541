var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _c("b-row", [
        _c(
          "div",
          [
            _c(
              "b-tabs",
              { attrs: { "content-class": "mt-3" } },
              _vm._l(_vm.revisiones, function (item, index) {
                return _c(
                  "b-tab",
                  {
                    key: "dyn-tab-" + item.revisor.revisorId,
                    attrs: { title: item.revisor.revisorId, active: "" },
                  },
                  [
                    _c(
                      "b-container",
                      [
                        _c("b-row", [
                          _c("h1", [_vm._v("Evaluación " + _vm._s(index + 1))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          { staticClass: "float-right" },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.estadoPleno,
                                        expression: "estadoPleno",
                                      },
                                    ],
                                    attrs: {
                                      disabled:
                                        _vm.hasAnyAuthority("ROLE_OPERADOR") &&
                                        _vm.authenticated,
                                      variant: "primary",
                                      size: "md",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.seleccionarRevision(
                                          item,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "evaluacion-individual.selecEvaluador"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-card-text",
                      [
                        _vm.solucionTipoIngreso
                          ? _c("h3", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("evaluacion-individual.title")
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.solucionTipoIngreso
                          ? _c("evaluacion-individual", {
                              attrs: {
                                solicitud: _vm.solicitud,
                                revision: item,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h3", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("evaluacion-individual.dictamen")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        !_vm.solicitud.reconsideracion &&
                        _vm.solucionTipoIngreso
                          ? _c("dictamen", {
                              attrs: {
                                solucion: _vm.solucionDictamen,
                                solicitud: _vm.solicitud,
                                revision: item,
                              },
                            })
                          : _vm.solicitud.reconsideracion &&
                            _vm.solucionTipoIngreso
                          ? _c("dictamen-reconsideracion", {
                              attrs: {
                                solicitud: _vm.solicitud,
                                revision: item,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.solucionTipoEmerito
                          ? _c("dictamen-emeritos", {
                              attrs: {
                                solicitud: _vm.solicitud,
                                revision: item,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }