import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PersonaResumenComponent extends Vue {
  @Prop()
  persona: any;

  @Prop({ default: true })
  showDireccion: boolean;
}
