var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-icon", {
        attrs: {
          icon: _vm.icon,
          animation: _vm.animation,
          variant: _vm.variant,
          "font-scale": "1.2",
        },
      }),
      _c("span", { staticClass: "ml-3" }, [_vm._v(_vm._s(_vm.description))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }