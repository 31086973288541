import { Component, Inject, Vue } from 'vue-property-decorator';
import { StatusCodes } from 'http-status-codes';
import { HistoricoPersonasApi } from '@conacyt/historicopersonasms-client';
import AlertService from '@/shared/alert/alert.service';
import Utilidades from '@/shared/config/utilidades';

@Component
export default class HistoricoPagosComponent extends Vue {
  @Inject('historicoPersonasApi') public historicoPersonasApi: () => HistoricoPersonasApi;
  @Inject('alertService') public alertService: () => AlertService;

  public utilidades = new Utilidades();
  public perPageOptions = [10, 50, 100, 150];
  public historicoPagos: Array<any> = [];
  public currentPage = 1;
  public perPage = 150;
  public totalRows = 0;

  public isSearching = false;
  public btnBuscarShow = true;
  public btnBuscarDisabled = false;
  public currentRowSelected = null;

  public filtroHistoricoPagos: {
    cvuInvestigador: string;
    cvuAyudante: string;
    expedienteInvestigador: string;
    expedienteAyudante: string;
    claveNomina: string;
  } = {
    cvuInvestigador: null,
    cvuAyudante: null,
    expedienteInvestigador: null,
    expedienteAyudante: null,
    claveNomina: null,
  };

  private datosCompletosExcel = [];
  private paginaSiguienteExcel = true;
  public btnExcelShow = true;

  public fields = [
    { key: 'cvuInvestigador', label: this.$t('apeironGwApp.historicoPagos.cvuInvestigador'), class: 'text-center', sortable: true },
    { key: 'cvuAyudante', label: this.$t('apeironGwApp.historicoPagos.cvuAyudante'), class: 'text-center', sortable: true },
    { key: 'mes', label: this.$t('apeironGwApp.historicoPagos.mes'), class: 'text-center', sortable: true },
    { key: 'anio', label: this.$t('apeironGwApp.historicoPagos.anio'), class: 'text-center', sortable: true },
    { key: 'nivel', label: this.$t('apeironGwApp.historicoPagos.nivel'), class: 'text-center', sortable: true },
    { key: 'ubicacion', label: this.$t('apeironGwApp.historicoPagos.ubicacion'), class: 'text-center', sortable: true },
    { key: 'sucursal', label: this.$t('apeironGwApp.historicoPagos.sucursal'), class: 'text-right', sortable: true },
    { key: 'cuenta', label: this.$t('apeironGwApp.historicoPagos.cuenta'), class: 'text-center', sortable: true },
    { key: 'cuentaCLABE', label: this.$t('apeironGwApp.historicoPagos.cuentaCLABE'), class: 'text-center', sortable: true },
    { key: 'monto', label: this.$t('apeironGwApp.historicoPagos.monto'), class: 'text-right', sortable: true },
    { key: 'montoPagar', label: this.$t('apeironGwApp.historicoPagos.montoPagar'), class: 'text-right', sortable: true },
    { key: 'seguros', label: this.$t('apeironGwApp.historicoPagos.seguros'), class: 'text-center', sortable: true },
    { key: 'claveNomina', label: this.$t('apeironGwApp.historicoPagos.claveNomina'), class: 'text-center', sortable: true },
    { key: 'curp', label: this.$t('apeironGwApp.historicoPagos.curp'), class: 'text-center', sortable: true },
    { key: 'origen', label: this.$t('apeironGwApp.historicoPagos.origen'), class: 'text-center', sortable: true },
    {
      key: 'expedienteInvestigador',
      label: this.$t('apeironGwApp.historicoPagos.expedienteInvestigador'),
      class: 'text-center',
      sortable: true,
    },
    { key: 'expedienteAyudante', label: this.$t('apeironGwApp.historicoPagos.expedienteAyudante'), class: 'text-center', sortable: true },
    { key: 'estatusPago', label: this.$t('apeironGwApp.historicoPagos.estatusPago'), class: 'text-center', sortable: true },
  ];

  resetPagination() {
    this.historicoPagos = [];
    this.currentPage = 1;
    this.totalRows = 0;
  }

  public retriveHistoricoPagos() {
    this.isSearching = true;
    this.btnBuscarShow = false;
    this.historicoPersonasApi()
      .getHistoricoPagos(
        !this.filtroHistoricoPagos.cvuInvestigador ? undefined : this.filtroHistoricoPagos.cvuInvestigador,
        !this.filtroHistoricoPagos.cvuAyudante ? undefined : this.filtroHistoricoPagos.cvuAyudante,
        !this.filtroHistoricoPagos.expedienteInvestigador ? undefined : this.filtroHistoricoPagos.expedienteInvestigador,
        !this.filtroHistoricoPagos.expedienteAyudante ? undefined : this.filtroHistoricoPagos.expedienteAyudante,
        !this.filtroHistoricoPagos.claveNomina ? undefined : this.filtroHistoricoPagos.claveNomina,
        this.currentPage,
        this.perPage
      )
      .then(result => {
        if (result.data.length > 0) {
          this.historicoPagos = result.data.map(r => this.utilidades.construirHistoricoPagos(r));
          this.resolvePagination(result.headers);
        } else {
          this.alertService().showWarning(this, this.$t('global.process.search.noresults').toString());
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== StatusCodes.NOT_FOUND) {
          this.alertService().showHttpError(this, error.response);
        }
      })
      .finally(() => {
        this.isSearching = false;
        this.btnBuscarShow = true;
      });
  }

  public resolvePagination(headers) {
    this.totalRows = headers['x-total'];
    this.currentPage = headers['x-page'];
  }

  public handleFiltros() {
    this.handlePageChange(1);
  }

  public handlePageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.retriveHistoricoPagos();
  }

  public handlePageSizeChange() {
    this.resetPagination();
    this.retriveHistoricoPagos();
  }

  public limpiarFiltros() {
    this.resetPagination();
    this.filtroHistoricoPagos.cvuInvestigador = null;
    this.filtroHistoricoPagos.cvuAyudante = null;
    this.filtroHistoricoPagos.expedienteInvestigador = null;
    this.filtroHistoricoPagos.expedienteAyudante = null;
    this.filtroHistoricoPagos.claveNomina = null;
  }

  public descargarReporteExcel() {
    this.obtenerDatosExcel().then(() => {
      if (this.datosCompletosExcel.length > 0) {
        const columnasAFormatear = ['monto', 'montoPagar', 'seguros'];
        this.utilidades.exportarExcel(
          this.datosCompletosExcel,
          this.$t('apeironGwApp.historicoPagos.title').toString(),
          columnasAFormatear
        );
      }
      this.reiniciarDatos();
    });
  }

  private obtenerDatosExcel() {
    this.btnExcelShow = false;
    this.btnBuscarDisabled = true;
    return new Promise<void>((resolve, reject) => {
      const obtenerPagina = page => {
        return this.historicoPersonasApi()
          .getHistoricoPagos(
            !this.filtroHistoricoPagos.cvuInvestigador ? undefined : this.filtroHistoricoPagos.cvuInvestigador,
            !this.filtroHistoricoPagos.cvuAyudante ? undefined : this.filtroHistoricoPagos.cvuAyudante,
            !this.filtroHistoricoPagos.expedienteInvestigador ? undefined : this.filtroHistoricoPagos.expedienteInvestigador,
            !this.filtroHistoricoPagos.expedienteAyudante ? undefined : this.filtroHistoricoPagos.expedienteAyudante,
            !this.filtroHistoricoPagos.claveNomina ? undefined : this.filtroHistoricoPagos.claveNomina,
            page,
            300
          )
          .then(result => {
            const datos = result.data.map(r => this.utilidades.construirHistoricoPagosExcel(r));
            this.datosCompletosExcel = this.datosCompletosExcel.concat(datos);
            this.paginaSiguienteExcel = Number(result.headers['x-total-pages']) > page;

            if (this.paginaSiguienteExcel) {
              return obtenerPagina(page + 1);
            } else {
              resolve();
            }
          })
          .catch(error => {
            if (error.response?.data?.status !== StatusCodes.NOT_FOUND) {
              this.alertService().showHttpError(this, error.response);
            }
          })
          .finally(() => {
            this.btnExcelShow = true;
            this.btnBuscarDisabled = false;
          });
      };
      obtenerPagina(1);
    });
  }

  private reiniciarDatos() {
    this.datosCompletosExcel = [];
    this.paginaSiguienteExcel = false;
  }
}
