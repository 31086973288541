export interface IProgramaIntegrado {
  clave?: string | null;
  nombre?: string | null;
  fechaInicio?: string | null;
  fechaFin?: string | null;
}

export class ProgramaIntegrado implements IProgramaIntegrado {
  constructor(
    public clave?: string | null,
    public nombre?: string | null,
    public fechaInicio?: string | null,
    public fechaFin?: string | null
  ) {}
}
