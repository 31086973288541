import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class NoUserInfoModalComponent extends Vue {
  @Prop({ required: true })
  id: string;

  @Prop({ default: true })
  show: boolean;

  @Watch('show')
  public start(show: boolean) {
    if (show) {
      (this.$refs['modal' + this.id] as any).show();
      setTimeout(() => {
        this.$router.go(-1);
      }, 3000);
    } else {
      (this.$refs['modal' + this.id] as any).hide();
    }
  }
}
