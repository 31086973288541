import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CargaMasivaUtils extends Vue {
  translation = {
    ' ': '_',
    '-': '__',
  };
  encodeItem(item) {
    const encoded = item.nombre.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '_');
    return encoded + '_' + item.id;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  decodeItem(item) {
    if (item) {
      let decoded = item.replaceAll('__', '-');
      decoded = decoded.split('_');
      const id = decoded.pop();

      return {
        id: id,
        nombre: null,
        encoded: item,
      };
    }
    return null;
  }

  itemAsOptions(item) {
    return item.map(item => {
      return {
        value: { id: item.id, nombre: item.nombre, encoded: this.encodeItem(item) },
        text: item.nombre,
        disabled: false,
      };
    });
  }

  copyCommonProperties(item, organization) {
    item.tipoNivel = organization.tipoNivel.descripcion;
    item.idTipoNivel = organization.tipoNivel.id;
    item.pais = organization.pais;
    item.estado = organization.estado.descripcion;
    item.idEstado = organization.estado.id;
  }

  resolveInstituciones(row, catalog) {
    let institucionFinded = null;
    let dependenciaFinded = null;
    let subdependenciaFinded = null;
    let departamentoFinded = null;
    if (row?.institucion?.id) {
      institucionFinded = catalog.find(institucion => institucion.id == row.institucion.id);
      if (institucionFinded) {
        row.institucion = { id: institucionFinded.id, nombre: institucionFinded.nombre, encoded: this.encodeItem(institucionFinded) };
      }
    }

    if (institucionFinded?.dependencia?.length > 0 && row?.dependencia?.id) {
      dependenciaFinded = institucionFinded.dependencia.find(dependencia => dependencia.id == row.dependencia.id);
      if (dependenciaFinded) {
        row.dependencia = { id: dependenciaFinded.id, nombre: dependenciaFinded.nombre, encoded: this.encodeItem(dependenciaFinded) };
      }
    }

    if (dependenciaFinded?.subDependencia?.length > 0 && row?.subdependencia?.id) {
      subdependenciaFinded = dependenciaFinded.subDependencia.find(subDependencia => subDependencia.id == row.subdependencia.id);
      if (subdependenciaFinded) {
        row.subdependencia = {
          id: subdependenciaFinded.id,
          nombre: subdependenciaFinded.nombre,
          encoded: this.encodeItem(subdependenciaFinded),
        };
      }
    }

    if (subdependenciaFinded?.departamento?.length > 0 && row?.departamento?.id) {
      departamentoFinded = subdependenciaFinded.departamento.find(departamento => departamento.id == row.departamento.id);
      if (departamentoFinded) {
        row.departamento = {
          id: departamentoFinded.id,
          nombre: departamentoFinded.nombre,
          encoded: this.encodeItem(departamentoFinded),
        };
      }
    }
  }
}
