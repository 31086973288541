import { GenericaDto } from '@/shared/model/genericaDto.model';
import { CargoDto } from '@/shared/model/cargo.model';

export interface IDepartamento {
  id?: string;
  nombre?: string;
  estado?: GenericaDto;
  tipoNivel?: GenericaDto;
  cargo?: CargoDto[];
  pais?: string;
  tipo?: GenericaDto;
  nivelNodo?: string;
}

export class Departamento implements IDepartamento {
  constructor(
    public id?: string,
    public nombre?: string,
    public estado?: GenericaDto,
    public tipoNivel?: GenericaDto,
    public cargo?: CargoDto[],
    public pais?: string,
    public tipo?: GenericaDto,
    public nivelNodo?: string
  ) {
    this.tipo = this.tipo ? this.tipo : new GenericaDto();
    this.tipoNivel = this.tipoNivel ? this.tipoNivel : new GenericaDto();
    this.estado = this.estado ? this.estado : new GenericaDto();
    this.cargo = this.cargo ? this.cargo : [];
  }
}
