var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "accordion", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true },
                            },
                          ],
                          attrs: { block: "", variant: "light" },
                        },
                        [
                          _c("h5", {
                            staticClass: "mb-0",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.temasSnp.tituloRegistro")
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "accordion-temas",
                        role: "tabpanel",
                      },
                      model: {
                        value: _vm.esVisibleRegistro,
                        callback: function ($$v) {
                          _vm.esVisibleRegistro = $$v
                        },
                        expression: "esVisibleRegistro",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("temas-registro", {
                            attrs: {
                              id: "registro-temas-form",
                              formModel: _vm.formModel,
                              solucion: _vm.solucion,
                              solicitud: _vm.solicitud,
                              permisos: _vm.permisos,
                              temaEdicion: _vm.temaEdicion,
                            },
                            on: {
                              agregarTema: function ($event) {
                                return _vm.agregarTemaATabla($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true },
                            },
                          ],
                          attrs: { block: "", variant: "light" },
                        },
                        [
                          _c("h5", {
                            staticClass: "mb-0",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("apeironGwApp.temasSnp.tituloListado")
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "accordion-temas",
                        role: "tabpanel",
                      },
                      model: {
                        value: _vm.esVisibleListado,
                        callback: function ($$v) {
                          _vm.esVisibleListado = $$v
                        },
                        expression: "esVisibleListado",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-card-text",
                            [
                              _c("temas-listado", {
                                attrs: {
                                  id: "listado-temas-form",
                                  formModel: _vm.formModel,
                                  solucion: _vm.solucion,
                                  solicitud: _vm.solicitud,
                                  permisos: _vm.permisos,
                                  temaAgregar: _vm.temaAgregar,
                                },
                                on: {
                                  editarTema: function ($event) {
                                    return _vm.editarTema($event)
                                  },
                                  guardarTemas: function ($event) {
                                    return _vm.$emit("submit", {
                                      temas: $event,
                                    })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }