var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isConfigured
        ? _c(
            "div",
            [
              _c("estado-proceso", {
                attrs: { entityName: "Instituciones" },
                model: {
                  value: _vm.institucionesLoadingState,
                  callback: function ($$v) {
                    _vm.institucionesLoadingState = $$v
                  },
                  expression: "institucionesLoadingState",
                },
              }),
              _vm._v(" "),
              _c("estado-proceso", {
                attrs: { entityName: "Configuración de usuario" },
                model: {
                  value: _vm.solucionLoadingState,
                  callback: function ($$v) {
                    _vm.solucionLoadingState = $$v
                  },
                  expression: "solucionLoadingState",
                },
              }),
              _vm._v(" "),
              _c("estado-proceso", {
                attrs: { entityName: "Catálogo de instituciones" },
                model: {
                  value: _vm.institucionesCompletoLoadingState,
                  callback: function ($$v) {
                    _vm.institucionesCompletoLoadingState = $$v
                  },
                  expression: "institucionesCompletoLoadingState",
                },
              }),
              _vm._v(" "),
              _vm.isSelectReady
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "mt-3 w-25",
                      attrs: {
                        label: _vm.$t(
                          "apeironGwApp.movimiento.bulk.tipoPlantilla"
                        ),
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: { size: "sm", options: _vm.tipoLayoutOptiones },
                        on: { change: _vm.handleChangeTipoLayout },
                        model: {
                          value: _vm.tipoLayout,
                          callback: function ($$v) {
                            _vm.tipoLayout = $$v
                          },
                          expression: "tipoLayout",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _vm.tipoLayout && _vm.isComponentReady
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "8" } },
                            [
                              _c("b-form-file", {
                                attrs: {
                                  state: Boolean(_vm.dataFile),
                                  accept: ".xlsx",
                                  required: "",
                                  placeholder: _vm.$t(
                                    "entity.action.import.placeholder",
                                    { extension: ".xlsx" }
                                  ),
                                  "drop-placeholder": _vm.$t(
                                    "archeApp.solucion.import.drop.placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onReadLayoutFile()
                                  },
                                },
                                model: {
                                  value: _vm.dataFile,
                                  callback: function ($$v) {
                                    _vm.dataFile = $$v
                                  },
                                  expression: "dataFile",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { sm: "12", md: "12", lg: "4" },
                            },
                            [
                              _c("sheet-download", {
                                attrs: {
                                  tipoLayout: _vm.tipoLayout,
                                  instituciones: _vm.catalogoInstituciones,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("excel", {
                    ref: "bulkTableComponent",
                    attrs: {
                      items: _vm.items,
                      fields: _vm.fields,
                      busy: _vm.busy,
                      uploading: _vm.uploading,
                      tipoLayout: _vm.tipoLayout,
                      institucionesIndex: _vm.institucionesIndex,
                      instituciones: _vm.catalogoInstituciones,
                      institucionesCompleto: _vm.catalogoInstitucionesCompleto,
                      institucionesCompletoIndex:
                        _vm.institucionesCompletasIndex,
                    },
                    on: { upload: _vm.handleUpload, clear: _vm.handleClear },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("message", {
            attrs: {
              description: _vm.$t(
                "global.messages.warning.configuration.required"
              ),
              variant: "warning",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }