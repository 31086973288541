import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { TipoRevision } from '@/shared/model/enumerations/tipo-revision.model';
import { mixins } from 'vue-class-component';
import SolucionMixin from '../mixins/solucion-mixin';
import { IRevision } from '@/shared/model/revision.model';
import { mapMutations } from 'vuex';
import { TipoComision } from '@/shared/model/enumerations/tipo-comision.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { EstadoRevision } from '@/shared/model/enumerations/estado-revision.model';
import SolicitudMixin from '../mixins/solicitud-mixin';
import RevisionMixin from '../mixins/revision-mixin';
import { Solucion } from '@/shared/model/solucion.model';
import HomeMenuComponent from '@/components/home-menu/home-menu.component';

@Component({
  mixins: [Vue2Filters.mixin],
  methods: {
    ...mapMutations(['setEvaluacion', 'setDictamen', 'setRevisorSeleccionado']),
  },
})
export default class EvaluacionesIndividualesComponent extends mixins(SolucionMixin, SolicitudMixin, RevisionMixin, HomeMenuComponent) {
  @Prop({ required: true })
  public solicitud: Solicitud;

  @Prop({ required: true })
  public revision;

  @Prop({ required: true })
  public solucionDictamen: Solucion;

  public evaluacionId: string;
  public plenariaId: string;

  public revisiones: Array<IRevision> = [];

  public revisada = EstadoRevision.REVISADA;

  public aceptada = EstadoRevision.ACEPTADA;

  /**
   * Mutación para guardar la evaluación seleccionada
   */
  setEvaluacion!: (evaluacion: any) => void;

  /**
   * Mutación para guardar el dictamen seleccionada
   */
  setDictamen!: (dictamen: any) => void;

  /**
   * Mutación para guardar el revisor seleccionado
   */
  setRevisorSeleccionado!: (revisionSeleccionada: number) => void;

  mounted(): void {
    this.get();
  }

  /**
   * Carga inicial del componente
   *
   * @memberof EvaluacionesIndividualesComponent
   */
  public get(): void {
    if (this.solicitud && this.revision) {
      this.getDefinicionRevision(this.solicitud.solucionId);
    }
  }

  /**
   *Obtiene las definiciones de una revisión
   *
   * @memberof EvaluacionesIndividualesComponent
   */
  @Watch('definicionEvaluacion')
  public getDefiniciones() {
    if (this.solicitud?.reconsideracion) {
      this.plenariaId = this.getDefinicionByTipoComision(TipoRevision.PLENARIA, TipoComision.RECONSIDERACION);
      this.evaluacionId = this.getDefinicionByTipoComision(TipoRevision.INDIVIDUAL, TipoComision.RECONSIDERACION);
    } else {
      this.plenariaId = this.getDefinicionByTipoComision(TipoRevision.PLENARIA, TipoComision.DICTAMINADORA);
      this.evaluacionId = this.getDefinicionByTipoComision(TipoRevision.INDIVIDUAL, TipoComision.DICTAMINADORA);
    }
    this.getTodasRevisiones();
  }

  /**
   *Obtiene las revisiones de una solicitud del cuestionario evaluación individual
   *
   * @memberof EvaluacionesIndividualesComponent
   */
  public getTodasRevisiones() {
    this.revisionService()
      .getAllRevisionesBySolicitudId(this.solicitud.id, this.evaluacionId)
      .then(revisiones => {
        // Filtrar las revisiones por el estado revisada
        this.revisiones = revisiones.filter(revision => revision.estado === this.revisada);
      })
      .catch(err => {});
  }

  /**
   * Establece la evaluación y dictamen de un evaluador en el store para ser recuperado en la evaluación plenaria.
   * Dirige a la evaluación plenaria con un evento de clic
   * @param revision la revisión seleccionada
   */
  public seleccionarRevision(revision: IRevision, index: number) {
    // Mostrar la información de la revisión seleccionada
    this.setEvaluacion(revision.respuesta.evaluacion);
    this.setDictamen(revision.respuesta.dictamen);
    this.setRevisorSeleccionado(index + 1);

    // Se guardan los datos cada vez que se seleccione un evaluador diferente
    this.guardarEvaluacionPleno(revision);

    // simular clic con javascript para llevarte al siguiente paso.
    document.querySelector('#sidebar-wrapper > div > ul > li:nth-child(6) > a').dispatchEvent(new Event('click'));
  }

  /**
   * Método para guardar la evaluación de una revisión.
   * @param revision la revisión que contiene la información.
   */
  public guardarEvaluacionPleno(revision: IRevision): void {
    let revisionPleno = {};

    revisionPleno = {
      evaluacion: revision.respuesta.evaluacion,
      dictamen: revision.respuesta.dictamen,
    };
    this.guardaEvaluacion(revisionPleno);
  }

  /**
   * Verifica si la revisión está en estado 'aceptada'.
   */
  public get estadoPleno(): boolean {
    return this.revision?.estado === this.aceptada;
  }
}
