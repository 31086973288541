import { Component, Prop } from 'vue-property-decorator';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import CatalogosSnp from '../catalogos/catalogos-snp.factory';
import { MenuAreaPronaceSnp, MenuModalidadPronaceSnp, MenuSubtemaPronaceSnp, MenuTemaPronaceSnp, PronaceSnp } from './pronace.model';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';

const validations = function () {
  const { pronaceSnp } = this as PronacesComponent;
  return {
    pronaceSnp: {
      modalidad: {
        required,
      },
      area: {
        required,
      },
      areaEspecifique: {
        required: requiredIf(() => pronaceSnp.area?.claveArea == 0),
        minLength: minLength(1),
      },
      tema: {
        required,
      },
      temaEspecifique: {
        required: requiredIf(() => pronaceSnp.tema?.claveArea == 0),
        minLength: minLength(1),
      },
      subtema: {
        required,
      },
      subtemaEspecifique: {
        required: requiredIf(() => pronaceSnp.subtema?.claveArea == 0),
        minLength: minLength(1),
      },
    },
  };
};
@Component({ validations: validations })
export default class PronacesComponent extends ApeironComponent {
  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ required: true })
  public solicitud: Solicitud;

  public pronaceSnp: PronaceSnp = new PronaceSnp();

  public modalidadesPronaces: Array<MenuModalidadPronaceSnp> = [];
  public areas: Array<MenuAreaPronaceSnp> = [];
  public temas: Array<MenuTemaPronaceSnp> = [];
  public subtemas: Array<MenuSubtemaPronaceSnp> = [];

  public mounted(): void {
    this.iniciarComponente();
  }

  private iniciarComponente(): void {
    this.modalidadesPronaces = [];
    CatalogosSnp.obtenerModalidadesPronaces().forEach(it => {
      this.modalidadesPronaces.push({ value: it, text: it.pronace });
    });

    this.areas = [];
    CatalogosSnp.obtenerArea().forEach(it => {
      this.areas.push({ value: it, text: it.area });
    });

    this.pronaceSnp = new PronaceSnp();

    if (this.solicitud?.pronace) {
      this.pronaceSnp = { ...this.solicitud.pronace };
    }

    this.cambiarSeleccionArea(false);
  }

  public guardarPronace(): void {
    if (!this.formularioIsInvalid) {
      this.$emit('submit', { pronace: this.pronaceSnp });
    } else {
      this.alertService().showWarning(this, this.$t('apeironGwApp.pronaceSnp.form.formularioInvalido').toString());
    }
  }

  public limpiarDatos(): void {
    this.iniciarComponente();
  }

  public cambiarSeleccionArea(limpiarSelecion: boolean): void {
    this.temas = [];
    this.subtemas = [];

    CatalogosSnp.obtenerTemas(this.pronaceSnp.area?.claveArea).forEach(it => {
      this.temas.push({ value: it, text: it.tema });
    });

    CatalogosSnp.obtenerSubtemas(this.pronaceSnp.area?.claveArea).forEach(it => {
      this.subtemas.push({ value: it, text: it.subtema });
    });

    if (limpiarSelecion) {
      this.pronaceSnp.areaEspecifique = null;
      this.pronaceSnp.tema = undefined;
      this.pronaceSnp.temaEspecifique = null;
      this.pronaceSnp.subtema = undefined;
      this.pronaceSnp.subtemaEspecifique = null;
    }
  }

  get readOnly(): boolean {
    return this.permisos.estadoSolicitud === EstadoSolicitud.ENVIADA;
  }

  get formularioIsInvalid(): boolean {
    return this.$v.$invalid;
  }

  get mostrarTexto() {
    return !this.solicitud?.pronace;
  }
}
