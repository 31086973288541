import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Home from '../home/home.component';
import { mapGetters } from 'vuex';
import { EstadoProceso } from '@/shared/model/enumerations/estado-proceso.model';

@Component({
  computed: {
    ...mapGetters(['account', 'userInfo', 'estadoCargaPerfil']),
  },
})
export default class DataUserComponent extends Home {
  @Prop({ required: true })
  public sidebarOpen: string;

  EstadoProceso = Object.freeze(EstadoProceso);

  public mainProps = {
    blank: true,
    blankColor: '#777',
    width: 75,
    height: 75,
    class: 'm1',
  };
}
