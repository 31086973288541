import { Component, Inject } from 'vue-property-decorator';
import { InstitucionActual } from './institucion-actual.model';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import InstitucionService from '@/entities/institucion/institucion.service';
import { IInstitucionDto, InstitucionDto } from '@/shared/model/institucion.model';
import { IDependencia, Dependencia } from '@/shared/model/dependencia.model';
import { ISubDependencia, SubDependencia } from '@/shared/model/sub-dependencia.model';
import { IDepartamento, Departamento } from '@/shared/model/departamento.model';
import { TipoPais } from '@/shared/model/enumerations/tipo-pais.model';

/**
 * Enumeración para el nivel de institución.
 * @enum {number}
 */
enum NivelInstitucionEnum {
  INSTITUCION = 1,
  DEPENDENCIA = 2,
  SUB_DEPENDENCIA = 3,
  DEPARTAMENTO = 4,
}

/**
 * Componente que representa la información de la institución actual.
 * @extends ApeironComponent
 */
@Component({
  computed: {
    ...mapGetters(['userInfo']),
  },
  validations: {
    form: {
      subSeccion: {
        nombre: { required },
      },
    },
  },
})
export default class InstitucionActualComponent extends ApeironComponent {
  /**
   * API para acceder a Instituciones de apeiron.
   * @type {() => SaciApi}
   */
  @Inject('institucionService') public institucionService: () => InstitucionService;

  public instituciones: IInstitucionDto[] = [];

  /**
   * Objeto que almacena los datos del formulario.
   * @type {object}
   */
  public form: { institucionActualSubSeccion: InstitucionActual } = { institucionActualSubSeccion: new InstitucionActual() };

  /**
   * ID de la dependencia seleccionada.
   * @type {InstitucionDto}
   */
  public institucionSelected: IInstitucionDto = new InstitucionDto();

  /**
   * ID de la dependencia seleccionada.
   * @type {Dependencia}
   */
  public dependenciaSelected: IDependencia = new Dependencia();

  /**
   * ID de la subdependencia seleccionada.
   * @type {nuSubDependenciamber}
   */
  public subDependenciaSelected: ISubDependencia = new SubDependencia();

  /**
   * ID del departamento seleccionado.
   * @type {Departamento}
   */
  public departamentoSelected: IDepartamento = new Departamento();

  public isCaptura = false;

  public loadingInstituciones = false;

  /**
   * Método ejecutado al crear el componente.
   */
  created(): void {
    if (this.formModel?.institucionActualSubSeccion) {
      this.form.institucionActualSubSeccion = {
        ...this.form.institucionActualSubSeccion,
        ...JSON.parse(JSON.stringify(this.formModel.institucionActualSubSeccion)),
      };
    }
    this.getInstituciones();
    this.isCaptura = this.formModel.estado === EstadoSolicitud.EN_CAPTURA || this.formModel.estado === EstadoSolicitud.RECHAZADA;
  }

  /**
   * Manejador de cambio de institucion.
   */
  public handleInstitucionChange(): void {
    // Limpia el dropdown de dependencia, subdependencia y departamento cuando la institución se edita
    this.dependenciaSelected = new Dependencia();
    this.subDependenciaSelected = new SubDependencia();
    this.departamentoSelected = new Departamento();

    this.form.institucionActualSubSeccion = new InstitucionActual();
    this.form.institucionActualSubSeccion.idInstitucion = parseInt(this.institucionSelected.id);
    this.form.institucionActualSubSeccion.institucion = this.institucionSelected?.nombre;
    this.form.institucionActualSubSeccion.idTipoNivel = parseInt(this.institucionSelected?.tipoNivel.id);
    this.form.institucionActualSubSeccion.tipoNivel = this.institucionSelected?.tipoNivel.descripcion;
    this.form.institucionActualSubSeccion.idPais = this.institucionSelected?.pais
      ? this.institucionSelected.pais === TipoPais.MEXICO_DESC
        ? parseInt(TipoPais.MEXICO_ID)
        : null
      : null;

    this.form.institucionActualSubSeccion.idEstado = parseInt(this.institucionSelected?.estado.id);
    this.form.institucionActualSubSeccion.estado = this.institucionSelected.estado.descripcion;
    this.limpiarData(NivelInstitucionEnum.INSTITUCION);
  }

  /**
   * Manejador de cambio de dependencia.
   */
  public handleDependenciaChange(): void {
    // Limpia el dropdown de subdependencia y departamento cuando la dependencia se edita
    this.subDependenciaSelected = new SubDependencia();
    this.departamentoSelected = new Departamento();

    this.form.institucionActualSubSeccion.idDependencia = parseInt(this.dependenciaSelected.id);
    this.form.institucionActualSubSeccion.dependencia = this.dependenciaSelected.nombre;
    this.form.institucionActualSubSeccion.idEstado = parseInt(this.dependenciaSelected.estado.id);
    this.form.institucionActualSubSeccion.estado = this.dependenciaSelected.estado.descripcion;
    //limpiar datos
    this.limpiarData(NivelInstitucionEnum.DEPENDENCIA);
  }

  /**
   * Manejador de cambio de subdependencia.
   */
  public handleSubDependenciaChange(): void {
    // Limpia el dropdown de departamento cuando la subdependencia se edita
    this.departamentoSelected = new Departamento();

    this.form.institucionActualSubSeccion.idSubDependencia = parseInt(this.subDependenciaSelected.id);
    this.form.institucionActualSubSeccion.subDependencia = this.subDependenciaSelected?.nombre;
    this.form.institucionActualSubSeccion.idEstado = parseInt(this.subDependenciaSelected.estado.id);
    this.form.institucionActualSubSeccion.estado = this.subDependenciaSelected.estado.descripcion;

    //limpiar datos
    this.limpiarData(NivelInstitucionEnum.SUB_DEPENDENCIA);
  }

  /**
   * Manejador de cambio de departamento.
   */
  public handleDepartamentoChange(): void {
    // Actualiza el objeto formulario con el ID y el valor del departamento seleccionado
    this.form.institucionActualSubSeccion.idDepartamento = parseInt(this.departamentoSelected.id);
    this.form.institucionActualSubSeccion.departamento = this.departamentoSelected?.nombre;
    this.form.institucionActualSubSeccion.idEstado = parseInt(this.departamentoSelected.estado.id);
    this.form.institucionActualSubSeccion.estado = this.departamentoSelected.estado.descripcion;
  }

  /**
   * Función para ordenar el array alfabéticamente por el nombre.
   * @param {Array} array - Array de objetos a ordenar.
   * @returns {Array} Array ordenado alfabéticamente.
   */
  private sortArray(array: any[]): any[] {
    return array.sort((a: { nombre: string }, b: { nombre: string }) => {
      const nameA = (typeof a.nombre === 'string' ? a.nombre : '').toUpperCase();
      const nameB = (typeof b.nombre === 'string' ? b.nombre : '').toUpperCase();
      return nameA.localeCompare(nameB);
    });
  }

  /**
   * Borra la selección actual de dependencia, subdependencia y departamento. Reseta los array asociados
   */
  public borrarSeleccion(): void {
    this.institucionSelected = new InstitucionDto();
    this.dependenciaSelected = new Dependencia();
    this.subDependenciaSelected = new SubDependencia();
    this.departamentoSelected = new Departamento();
    this.instituciones = [];
  }

  public getInstituciones(): void {
    this.loadingInstituciones = true;
    this.institucionService()
      .findAll()
      .then(result => {
        this.loadingInstituciones = false;
        if (result?.length > 0) {
          this.instituciones = this.sortArray(result);
        } else {
          this.mensajeSinInformacion();
        }
      })
      .catch(error => {
        this.loadingInstituciones = false;
      });
  }

  public mensajeSinInformacion(): void {
    const message = this.$t('apeironGwApp.institucion.mensaje.datoNoEncontrado');
    const title = this.$t('apeironGwApp.institucion.mensaje.tituloInfo');
    this.alertService().showToaster(this, title.toString(), message.toString(), 'success');
  }

  private limpiarData(nivelInstitucion: number): void {
    switch (nivelInstitucion) {
      case NivelInstitucionEnum.INSTITUCION:
        this.form.institucionActualSubSeccion.idDependencia = null;
        this.form.institucionActualSubSeccion.dependencia = null;
        this.form.institucionActualSubSeccion.idSubDependencia = null;
        this.form.institucionActualSubSeccion.subDependencia = null;
        this.form.institucionActualSubSeccion.idDepartamento = null;
        this.form.institucionActualSubSeccion.departamento = null;
      case NivelInstitucionEnum.DEPENDENCIA:
        this.form.institucionActualSubSeccion.idSubDependencia = null;
        this.form.institucionActualSubSeccion.subDependencia = null;
        this.form.institucionActualSubSeccion.idDepartamento = null;
        this.form.institucionActualSubSeccion.departamento = null;
      case NivelInstitucionEnum.SUB_DEPENDENCIA:
        this.form.institucionActualSubSeccion.idDepartamento = null;
        this.form.institucionActualSubSeccion.departamento = null;
    }
  }
}
