import { Component, Inject, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { v4 as uuidv4 } from 'uuid';

import JhiDataUtils from '@/shared/data/data-utils.service';
import AlertService from '@/shared/alert/alert.service';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import camelCase from 'camelcase';
import { normalizeSync } from 'normalize-diacritics';
import { BitacoraDocumento, File, IBitacoraDocumento } from '@/shared/model/file.model';
import { EstadoSolicitud } from '@/shared/model/enumerations/estado-solicitud.model';
import DocumentoService from '@/components/documentos/documento.service';
import { Configuracion } from '@/shared/model/configuracion/configuracion.model';
import { DmsApi, EtapaEnum, Archivo } from '@conacyt/dms-client';

import * as dateUtils from '@/shared/date/date-utils';
import { Solucion } from '@/shared/model/solucion.model';
import { Solicitud } from '@/shared/model/solicitud.model';
import { MenuElement } from '@/shared/model/enumerations/menu-element.model';
import GrantService from '@/shared/security/grant.service';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import ScriptService from '@/shared/script/script.service';

@Component
export default class DocumentosComponentComentarios extends mixins(JhiDataUtils) {
  @Inject('documentoService') private documentoService: () => DocumentoService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('dmsApi') public dmsApi: () => DmsApi;
  @Inject('grantService') public grantService: () => GrantService;
  @Inject('scriptService') public scriptService: () => ScriptService;

  @Prop({ required: true })
  public solucion: Solucion;

  @Prop({ default: false })
  public mock: boolean;

  @Prop({ required: false })
  public solicitud: Solicitud;

  @Prop({ default: false })
  public saving: boolean;

  public newFileName = '';

  public comentarios: IBitacoraDocumento[] = new Array<IBitacoraDocumento>();

  private ETAPA: EtapaEnum = 'REGISTRO';
  public documentoModel: any = {};

  public isLoading = false;
  public currentFile: any = null;
  public currentContent: any = null;

  public isSaving = false;
  // Esta  vairable se utiliza para saber qué archivo se está  actualizando
  public currentFileIndex = -1;
  public currentLanguage = '';

  public options = [
    { text: 'Aceptar', value: true },
    { text: 'Rechazar', value: false },
  ];

  get configuracion(): Configuracion {
    return this.solucion.componentes.find(com => com.menuName === MenuElement.DOCUMENTOS).configuracion;
  }

  get isOperador(): boolean {
    return this.solucion.autoridades.some(
      user => user.usuarioId == this.userInfo.login && user.roles.some(rol => rol === RolAutoridad.OPERADOR)
    );
  }

  get accesos(): any {
    return this.grantService().accesos(this.solucion, this.solicitud);
  }

  get readOnly(): boolean {
    if (this.accesos.acciones.findIndex(accion => accion === TipoAccion.ESCRIBIR) === -1) {
      return true;
    }
    return false;
  }

  get permiteComentar(): boolean {
    return this.solicitud.estado == EstadoSolicitud.ENVIADA || this.solicitud.estado == EstadoSolicitud.ACTUALIZADA;
  }

  get properties(): any[] {
    const propertiesWithConfig = Object.keys(this.documentoModel)
      .filter(property => property !== 'id')
      .map(property => {
        const propertyDefinition = {
          key: property,
          config: { title: null, max: null, mediaTypes: [], requerido: false, orden: 1000, expresion: null },
        };
        const configurationIndex = this.configuracion.documentos.findIndex(config => config.nombrePropiedad === property);
        return configurationIndex === -1
          ? this.mapFromGeneralConfiguration(propertyDefinition, property)
          : this.mapFromConfiguration(propertyDefinition, configurationIndex);
      });

    return this.order(propertiesWithConfig);
  }

  get canAddMoreDocuments(): boolean {
    if (this.configuracion.hasMoreDocuments && !this.configuracion.maxNumberOfDocuments) {
      return true;
    }

    if (this.configuracion.hasMoreDocuments && this.configuracion.maxNumberOfDocuments) {
      return this.propertiesNotInConfiguration.length <= this.configuracion.maxNumberOfDocuments;
    }

    return false;
  }

  get propertiesNotInConfiguration(): string[] {
    const fullDocumentProperties = Object.keys(this.documentoModel);
    return fullDocumentProperties.filter(
      property => this.configuracion.documentos.findIndex(config => config.nombrePropiedad === property) === -1
    );
  }

  get userInfo(): any {
    return this.$store.getters.userInfo;
  }

  get configuracionColumnasComent(): any {
    return [
      {
        key: 'fecha',
        label: this.$t('archivosApp.documento.comentarios.fecha').toString(),
        class: 'text-center',
        sortable: false,
        formatter: fecha => {
          return dateUtils.formatDate(fecha);
        },
      },
      {
        key: 'descripcion',
        label: this.$t('archivosApp.documento.comentarios.comentario').toString(),
        class: 'text-center',
        sortable: false,
      },
      {
        key: 'archivo',
        label: this.$t('archivosApp.documento.comentarios.documento').toString(),
        class: 'text-center',
        sortable: false,
      },
    ];
  }

  created(): void {
    if (this.solicitud) {
      this.retrieveDocumento(this.solicitud.id);
    } else {
      this.documentoModel = this.createProperties(this.documentoModel);
    }
  }

  public retrieveDocumento(solicitudId): void {
    this.isLoading = true;
    this.documentoService()
      .findBySolicitudId(solicitudId)
      .then(res => {
        this.isLoading = false;
        this.documentoModel = this.createProperties(res);
      })
      .catch(error => {
        this.isLoading = false;
        this.documentoModel = this.createProperties(this.documentoModel);
      });
  }

  /**
   * Se crean las propiedades de un documento
   * a partir de la configuración del archivo.
   * @param model
   * @returns
   */
  public createProperties(model: any): any {
    if (this.configuracion) {
      /** Se agregan las propiedades qué provienen del archivo de configuración */
      this.configuracion.documentos.forEach(config => {
        if (!model[config.nombrePropiedad]) {
          /**Es necesario utilizar el método this.$set()
           * para qué las propiedades puedan ser reactivas */
          this.$set(model, config.nombrePropiedad, new File());
        }
      });
    }
    return model;
  }

  private order(propertyDefinition: any[]): any {
    let count = 0;
    this.configuracion.documentos.forEach(documento => {
      const index = propertyDefinition.findIndex(property => property.key === documento.nombrePropiedad);
      propertyDefinition[index].config.orden = count++;
    });

    return propertyDefinition.sort((leftProperty, rightProperty) => leftProperty.config.orden - rightProperty.config.orden);
  }

  private mapFromConfiguration(propertyDefinition: any, configurationIndex: number): any {
    const config = this.configuracion.documentos[configurationIndex];
    propertyDefinition.config.title = config.nombre;
    propertyDefinition.config.max = config.max;
    propertyDefinition.config.mediaTypes = config.mediaTypes;
    propertyDefinition.config.requerido = config.requerido ? true : false;
    propertyDefinition.config.expresion = config.expresion ? config.expresion : 'return true;';
    return propertyDefinition;
  }

  private mapFromGeneralConfiguration(propertyDefinition: any, property: string): any {
    propertyDefinition.config.title = this.documentoModel[property].title;
    propertyDefinition.config.max = this.configuracion.maxFileSize;
    propertyDefinition.config.mediaTypes = this.configuracion.mediaTypes;
    propertyDefinition.config.requerido = false;
    propertyDefinition.config.expresion = this.configuracion.expresion;
    return propertyDefinition;
  }

  public addPropertyToModel(model: any, propertyName: string, nombreDocumento?: string): void {
    const newFile = new File();
    newFile.title = nombreDocumento;
    newFile.comentarios = new Array<IBitacoraDocumento>();
    this.$set(model, propertyName, newFile);
  }

  /**
   * Agrega comentario a la lista de comentarios
   *
   */
  public agregarComentario(property: string): void {
    const coment = new BitacoraDocumento(
      this.documentoModel[property].comentario,
      new Date(),
      this.documentoModel[property].uri,
      this.documentoModel[property]?.check ?? null,
      this.userInfo.login,
      uuidv4(),
      this.documentoModel[property].nombre
    );
    if (!this.documentoModel[property]?.comentarios) {
      this.documentoModel[property].comentarios = new Array<IBitacoraDocumento>();
    }
    this.documentoModel[property].comentarios.push(coment);
    this.documentoModel[property].comentario = null;
    this.save(false);
  }

  public save(isFullSave?: boolean): void {
    if (!this.solicitud.id) {
      return;
    }
    this.isSaving = true;

    if (this.documentoModel.id) {
      this.documentoService()
        .update(this.documentoModel)
        .then(param => {
          this.isSaving = false;
          this.currentFileIndex = -1;
          this.documentoModel = param;
          if (isFullSave) {
            this.$emit('documentoSaved');
          }
          this.alertService().sectionUpdated(this, 'Documentos');
        })
        .catch(error => {
          this.isSaving = false;
          this.currentFileIndex = -1;
          this.alertService().showHttpError(this, error.response);
        });
    } else {
      this.documentoModel.id = this.solicitud.id;
      this.documentoService()
        .create(this.documentoModel)
        .then(param => {
          this.documentoModel = param;
          this.isSaving = false;
          this.currentFileIndex = -1;
          this.alertService().sectionUpdated(this, 'Documentos');
        })
        .catch(error => {
          this.isSaving = false;
          this.currentFileIndex = -1;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public onReadFile(file, doc: File, fileIndex): void {
    if (file) {
      this.currentFileIndex = fileIndex;
      this.toBase64(file, base64Data => {
        doc.nombre = file.name.replace('.pdf', '-' + dateUtils.formatDateHoraSegundos(new Date()) + '.pdf');
        doc.contentType = file.type;
        this.currentContent = base64Data;
        this.guardarDocumento(doc);
      });
    }
  }

  public guardarDocumento(doc: File): void {
    const archivo: Archivo = { nombre: doc.nombre, contenido: this.currentContent };
    this.dmsApi()
      .agregaDocumento(this.configuracion.carpeta, this.solicitud.id, this.userInfo.cvu, this.ETAPA, undefined, archivo)
      .then(res => {
        doc.uri = res.headers.location;
        this.currentContent = null;
        this.currentFile = null;
        this.currentFileIndex = -1;
        this.save();
      })
      .catch(error => {
        this.currentContent = null;
        this.currentFile = null;
        this.currentFileIndex = -1;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public handleDeleteDocumento(doc: File, index): void {
    this.currentFileIndex = index;
    doc.uri = null;
    this.save();
  }

  public toFileExtension(mediaTypes: string[]): string {
    if (!mediaTypes) {
      return '.pdf';
    }
    let fileExtensions = '';
    mediaTypes.forEach((type, index) => {
      if (index === 0) {
        fileExtensions = '.' + type;
      } else {
        fileExtensions = fileExtensions + ', .' + type;
      }
    });
    return fileExtensions;
  }

  public handleAddDocument(): void {
    const propertyName = camelCase(normalizeSync(this.newFileName));
    this.addPropertyToModel(this.documentoModel, propertyName, this.newFileName);
    this.newFileName = '';
    this.hideModal();
  }

  public handleOpenAddNewDocumentModal(): void {
    (this.$refs.addNewDocument as any).show();
  }

  public handleCloseAddDocumentModal(): void {
    this.hideModal();
  }

  public handleCancel(): void {
    this.hideModal();
  }

  public hideModal(): void {
    (this.$refs.addNewDocument as any).hide();
  }

  public isUpdating(fileIndex): boolean {
    return this.currentFileIndex === fileIndex;
  }

  public showValidation(): boolean {
    return this.solicitud.estado !== EstadoSolicitud.EN_CAPTURA;
  }

  public disabledValidation(): boolean {
    if (this.accesos.acciones) {
      return (
        this.accesos.acciones.filter(accion => {
          return [TipoAccion.VALIDAR, TipoAccion.RECHAZAR, TipoAccion.APROBAR, TipoAccion.REVISAR].includes(accion);
        }).length <= 0
      );
    }
    return true;
  }

  /**
   * Abre el modal de la lista de comentarios
   *
   */
  public handleOpenComentariosModal(property: string): void {
    this.comentarios = this.documentoModel[property].comentarios;
    (this.$refs.verComentarios as any).show();
  }

  /**
   * Cierra el modal de la lista de comentarios
   *
   */
  public hideModalComentarios(): void {
    (this.$refs.verComentarios as any).hide();
  }

  public execute(expression) {
    const context = { solucion: this.solucion, solicitud: this.solicitud };
    return this.scriptService().runFunction(expression, context);
  }
}
