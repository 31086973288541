var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.definicionEvaluacion?.revisiones
        ? _c(
            "b-tabs",
            {
              model: {
                value: _vm.evaluacionTabIndex,
                callback: function ($$v) {
                  _vm.evaluacionTabIndex = $$v
                },
                expression: "evaluacionTabIndex",
              },
            },
            _vm._l(
              _vm.definicionEvaluacion.revisiones,
              function (revision, index) {
                return _c(
                  "b-tab",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.loadRevision(revision)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "danger" } },
                                [_vm._v(_vm._s(index + 1))]
                              ),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "apeironGwApp.TipoRevision." +
                                        revision.tipoRevision
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    !_vm.readOnly
                      ? _c(
                          "b-card",
                          { attrs: { "bg-variant": "light" } },
                          [
                            _c("header", { staticClass: "bx-header-title" }, [
                              _c("h3", [
                                _vm._v(
                                  "Revisión " +
                                    _vm._s(
                                      _vm.$t(
                                        "apeironGwApp.TipoRevision." +
                                          revision.tipoRevision
                                      )
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "header-title-line" }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-alert",
                              {
                                staticClass: "mt-3",
                                attrs: { variant: "info", show: "" },
                              },
                              [
                                _vm._v(
                                  "En esta sección puede asignar revisores para la\n          "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    "revisión " +
                                      _vm._s(
                                        _vm.$t(
                                          "apeironGwApp.TipoRevision." +
                                            revision.tipoRevision
                                        )
                                      )
                                  ),
                                ]),
                                _vm._v(
                                  ". Todos los revisores que son asignados a esta solicitud, aparecerán en la tabla de abajo. No podrá remover un revisor que ha\n          aceptado evaluar la solicitud\n        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols": "2",
                                  "label-cols-lg": "2",
                                  label: "Identificador:",
                                },
                              },
                              [_c("strong", [_vm._v(_vm._s(revision.id))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols": "2",
                                  "label-cols-lg": "2",
                                  label: "Tipo de revisión:",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "h5" },
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { variant: "primary", pill: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.TipoRevision." +
                                                  revision.tipoRevision
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols": "2",
                                  "label-cols-lg": "2",
                                  label: "Descripción:",
                                },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    textContent: _vm._s(revision.descripcion),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-card",
                      { staticClass: "mt-3" },
                      [
                        !_vm.readOnly
                          ? _c(
                              "h2",
                              {
                                attrs: {
                                  id: "page-heading",
                                  "data-cy": "RevisoresHeading",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-content-end" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { variant: "primary" },
                                        on: {
                                          click: _vm.handleOpenAddRevisorModal,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "plus" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.revision.add"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.revisoresFiltered
                                      ? _c("export-from-json", {
                                          attrs: {
                                            data: _vm.revisoresFiltered,
                                            "file-name":
                                              "revisores-" +
                                              _vm.$t(
                                                "apeironGwApp.TipoRevision." +
                                                  revision.tipoRevision
                                              ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("b-table", {
                          attrs: {
                            items: _vm.revisoresFiltered,
                            fields: _vm.campos,
                            "show-empty": "",
                            "head-variant": "primary",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "empty",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "h4 text-center" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("entity.data.blank"))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "head(revisorId)",
                                fn: function () {
                                  return [_vm._v(" Login ")]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(estado)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          variant: _vm.resolveVariant(
                                            row.item.estado
                                          ),
                                          pill: "",
                                        },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "apeironGwApp.EstadoRevision." +
                                                  row.item.estado
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(acciones)",
                                fn: function (row) {
                                  return [
                                    _vm.isRemovable(row.item)
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              variant: "danger",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePrepareToRemove(
                                                  row.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("entity.action.delete")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.readOnly
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleShowReviewDetails(
                                                  row.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("entity.action.detail")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }
            ),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addRevisor",
          attrs: { id: "addRevisorModal", size: "lg", centered: "" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("Asignar revisor")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-12",
                  attrs: { label: "Buscar revisor por cvu" },
                },
                [
                  _c("buscador-cvu", {
                    attrs: { id: "cvu" },
                    model: {
                      value: _vm.persona,
                      callback: function ($$v) {
                        _vm.persona = $$v
                      },
                      expression: "persona",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "text-center",
                  attrs: {
                    id: "componente-add-autoridad",
                    size: "sm",
                    label: "",
                    "label-for": "add-autoridad",
                  },
                },
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: "warning",
                        show: _vm.persona.cvu === null,
                      },
                    },
                    [
                      _vm._v(
                        "No se encontraron resultados para el criterio de busqueda "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.persona.cvu
                ? _c("b-card", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("CVU")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.persona.cvu)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("Login")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.persona.login)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("Nombre")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm.persona.nombre +
                              " " +
                              _vm.persona.apellidoPaterno +
                              " " +
                              _vm.persona.apellidoMaterno
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "warning", show: _vm.isAlreadyInvited },
                },
                [_vm._v("Esta persona ya se encuentra invitada ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c("button", {
              staticClass: "btn btn-outline-danger",
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
              on: {
                click: function ($event) {
                  return _vm.handleCloseAddRevisorModal()
                },
              },
            }),
            _vm._v(" "),
            _vm.persona.cvu && !_vm.isAlreadyInvited
              ? _c("button", {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "button",
                    id: "jhi-confirm-delete-solicitud",
                    "data-cy": "entityConfirmAddRevisorButton",
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("entity.action.save")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAddRevisor()
                    },
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmRemoveModal",
          attrs: { "header-bg-variant": "primary", size: "lg" },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", [_vm._v("Confirmar eliminación")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("p", { staticClass: "text-center h5" }, [
                _vm._v("¿Estás seguro de eliminar al siguiente revisor?"),
              ]),
              _vm._v(" "),
              _vm.revisorToRemove?.cvu
                ? _c("b-card", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("CVU")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.revisorToRemove.cvu)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("Login")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.revisorToRemove.revisorId)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", [_vm._v("Nombre")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.revisorToRemove.nombre)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirmation()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("entity.action.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }