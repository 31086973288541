import * as Entities from '@conacyt/insaculacion-client';

export interface INivelAspira {
  id?: string;
  nombre?: string;
}

export interface ICatalogosDictamen {
  id?: string;
  nombre?: string;
}

export interface ICatalogoGenerico {
  id?: string;
  clave?: string;
  nombre?: string;
}

export interface INivel {
  id?: string;
  nombre?: string;
}

export interface IAreaConocimiento {
  clave: string;
  nombre: string;
}

export interface ICuota {
  id?: string;
  nombre?: string;
}
export default class EntityFactory {
  public static setCrearEstimacion(): Entities.CrearEstimacionComisionesRequest {
    return {
      claveConvocatoria: null,
      solicitudesPorArea: [],
      evaluadoresPorComision: 0,
      solicitudesPorEvaluador: 0,
    };
  }

  public static getSolicitudPorArea(): Entities.SolicitudesArea {
    return { area: null, cantidad: 0 };
  }

  public static getConfiguracion(): Entities.Configuracion {
    return {
      area: null,
      solicitudesPorArea: 0,
      solicitudesAEvaluar: 0,
      evaluadoresPorComision: 0,
      solicitudesPorEvaluador: 0,
      evaluadoresPorArea: 0,
      comisionesTotales: 0,
    };
  }

  public static getResponseConfiguracion() {
    return {
      area: null,
      solicitudesPorArea: 0,
      solicitudesAEvaluar: 0,
      evaluadoresPorComision: 0,
      solicitudesPorEvaluador: 0,
      evaluadoresPorArea: 0,
      comisionesTotales: 0,
    };
  }

  public static getComisionesConv(): Entities.InsaculacionIngresoPermanencia {
    return {
      claveConvocatoria: null,
      areas: [],
    };
  }

  public static setAgregarEvaluador() {
    return {
      claveConvocatoria: null,
      claveArea: null,
      idComision: null,
      cvu: null,
      rol: null,
    };
  }

  public static getInvestigador(): Entities.Investigador {
    return {
      id: null,
      cvu: null,
      nombre: null,
      fechaInicioVigencia: null,
      fechaFinVigencia: null,
      nombreInstitucion: null,
      idCampo: null,
      idDisciplina: null,
      idSubdisciplina: null,
    };
  }

  public static area(): Entities.Area {
    return {
      clave: null,
      nombre: null,
      comisiones: [],
    };
  }

  public static comision(): Entities.Comision {
    return {
      clave: null,
      nombre: null,
      tipo: null,
      evaluadores: [],
    };
  }

  public static evaluador(): Entities.Evaluador {
    return {
      id: null,
      cvu: null,
      nombre: null,
      fechaInicioVigencia: null,
      fechaFinVigencia: null,
      nombreInstitucion: null,
      correo: null,
      estado: null,
      rol: null,
      posicionIntegrante: null,
      idCampo: null,
      idDisciplina: null,
      idSubdisciplina: null,
      nombreComision: null,
      solicitudes: [],
    };
  }

  static areaConocimientos: Array<IAreaConocimiento> = [
    { clave: '1', nombre: 'Ciencias físico matemáticas y ciencias de la tierra' },
    { clave: '2', nombre: 'Biología y química' },
    { clave: '3', nombre: 'Medicina y ciencias de la salud' },
    { clave: '4', nombre: 'Ciencias de la Conducta y la Educación' },
    { clave: '5', nombre: 'Humanidades' },
    { clave: '6', nombre: 'Ciencias Sociales' },
    { clave: '7', nombre: 'Ciencias de Agricultura, Agropecuarias, Forestales y de Ecosistemas' },
    { clave: '8', nombre: 'Ingenierías y Desarrollo Tecnológico' },
    { clave: '9', nombre: 'Interdisciplinaria' },
  ];

  static nivelAspira: Array<INivelAspira> = [
    { id: '1', nombre: 'Candidato' },
    { id: '2', nombre: 'Investigador Nacional Nivel I' },
    { id: '3', nombre: 'Investigador Nacional Nivel II' },
    { id: '4', nombre: 'Investigador Nacional Nivel III' },
  ];

  static nivelCapacidad: Array<ICatalogosDictamen> = [
    { id: '1', nombre: '' },
    { id: '2', nombre: 'de manera sostenida' },
    { id: '3', nombre: 'de manera sostenida, relevante y pertinente' },
  ];

  static nivelCumple: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'cumple' },
    { id: '2', nombre: 'no cumple' },
  ];

  static nivelAcredita: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'acredita' },
    { id: '2', nombre: 'no acredita' },
  ];

  static nivelPresenta: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'presenta' },
    { id: '2', nombre: 'no presenta' },
  ];

  static nivelParticipacionPromocion: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'participacion' },
    { id: '2', nombre: 'promocion' },
  ];

  static nivelParticipando: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'participando en' },
    { id: '2', nombre: 'promoviendo' },
    { id: '3', nombre: 'generando' },
    { id: '4', nombre: 'gestionando' },
  ];

  static nivelInnovacionNivel: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'licenciatura y posgrado' },
    { id: '2', nombre: 'licenciatura' },
    { id: '3', nombre: 'posgrado' },
    { id: '4', nombre: 'media superior' },
  ];

  static nivelInvolucramiento: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'impulso y desarrollo' },
    { id: '2', nombre: 'impulso' },
    { id: '3', nombre: 'desarrollo' },
  ];

  static nivelLiderazgo: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'nacional' },
    { id: '2', nombre: 'internacional' },
    { id: '3', nombre: 'nacional e internacional' },
  ];

  static nivelCuenta: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'cuenta' },
    { id: '2', nombre: 'no cuenta' },
  ];

  static nivelNivel: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'intermedio' },
    { id: '2', nombre: 'alto ' },
  ];

  static nivelMadurez: Array<ICatalogosDictamen> = [
    { id: '1', nombre: '4' },
    { id: '2', nombre: '5' },
    { id: '3', nombre: '6' },
    { id: '4', nombre: '7' },
    { id: '5', nombre: '8' },
    { id: '6', nombre: '9' },
  ];

  static nivelMejoraVida: Array<ICatalogosDictamen> = [
    { id: '0-100', nombre: '0-100' },
    { id: '101-1000', nombre: '101-1000' },
    { id: '1001-10000', nombre: '1001-10000' },
    { id: '10001-100000', nombre: '10001-100000' },
    { id: 'más de 100001', nombre: 'más de 100001' },
  ];

  static nivelDemuestra: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'demuestra' },
    { id: '2', nombre: 'no demuestra' },
  ];

  static nivelAutor: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'autor(a)' },
    { id: '2', nombre: 'coautor(a)' },
  ];

  public static getNivelRecomendado(): any {
    return [
      { recomendado: { id: '1', nombre: 'Candidato' } },
      { recomendado: { id: '2', nombre: 'Investigador Nacional Nivel I' } },
      { recomendado: { id: '3', nombre: 'Investigador Nacional Nivel II' } },
      { recomendado: { id: '4', nombre: 'Investigador Nacional Nivel III' } },
      { recomendado: { id: '5', nombre: 'No Aprobado' } },
    ];
  }

  static nivelTecnologias: Array<ICatalogosDictamen> = [
    { id: '1', nombre: 'desarrollo de tecnologías estratégicas de vanguardia' },
    { id: '2', nombre: 'innovación abierta para la transformación social' },
  ];

  static nivelesRecomendado: Array<INivel> = [
    { id: '1', nombre: 'Candidato' },
    { id: '2', nombre: 'Investigador Nacional Nivel I' },
    { id: '3', nombre: 'Investigador Nacional Nivel II' },
    { id: '4', nombre: 'Investigador Nacional Nivel III' },
    { id: '5', nombre: 'No Aprobado' },
  ];

  static tiposReportes: Array<ICatalogoGenerico> = [
    { id: '1', clave: 'SOLICITUDES', nombre: 'Solicitudes / Validación documental' },
    { id: '2', clave: 'AYUDANTES', nombre: 'Ayudantes' },
    { id: '3', clave: 'CUENTAS_BANCARIAS', nombre: 'Cuentas bancarias' },
    { id: '4', clave: 'ACREDITACIONES', nombre: 'Acreditaciones' },
    { id: '5', clave: 'BECAS_EXTRANJERAS', nombre: 'Becas extranjeras' },
    { id: '6', clave: 'EVALUACION', nombre: 'Evaluación' },
    { id: '7', clave: 'DOCUMENTOS_SOLICITUDES', nombre: 'Oficios / Reconocimientos / Convenios' },
  ];

  static frecuenciasCuota: Array<ICuota> = [
    { id: '1', nombre: 'Mensual' },
    { id: '2', nombre: 'Bimestral' },
    { id: '3', nombre: 'Trimestral' },
    { id: '4', nombre: 'Cuatrimestral' },
    { id: '5', nombre: 'Semestral' },
    { id: '6', nombre: 'Anual' },
    { id: '7', nombre: 'Bienal' },
    { id: '8', nombre: 'Trienal' },
    { id: '9', nombre: 'Cuatrienal' },
  ];
}
