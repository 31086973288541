import { mixins } from 'vue-class-component';
import { Component, Inject, Prop, Ref } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
import ApeironComponent from '@/components/commons/apeiron-component.model';
import { Solucion } from '@/shared/model/solucion.model';
import { RolAutoridad } from '@/shared/model/enumerations/rol-autoridad.model';
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { BModal } from 'bootstrap-vue';
import { TipoAccion } from '@/shared/model/enumerations/tipo-accion.model';
import SolicitudService from '@/entities/solicitud/solicitud.service';
import { CharCodes } from '@/shared/model/enumerations/caracteres.model';
import { ICatalogoDto } from '@/shared/model/catalogoDto.model';
import { IDependienteEconomico } from '@/shared/model/persona/dependiente-economico';
import { CURP } from '@/shared/formatter/regex-utils';
import { LOCALE } from '@/shared/date/date-utils';

const VALIDATIONS = function () {
  return {
    dependientesNuevo: {
      esExtranjero: {
        required,
      },
      curp: {
        required: requiredIf(() => !this.curpReadOnly),
        minLength: minLength(1),
        maxLength: maxLength(18),
        CURP,
      },
      nombre: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      primerApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      segundoApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      fechaNacimiento: {
        required,
      },
      sexo: {
        required,
      },
      parentesco: {
        required,
      },
    },
  };
};
@Component({
  validations: VALIDATIONS,
  computed: {
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
    },
  },
})
export default class ConciliacionParcialComponent extends mixins(ApeironComponent) {
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('solicitudService') public solicitudService: () => SolicitudService;

  @Prop({ required: true })
  public solicitud: any;

  @Prop({ required: true })
  public solucion: Solucion;

  @Ref('removeDependiente')
  public readonly removeDependiente: BModal;

  @Ref('changeDependiente')
  public readonly changeDependiente: BModal;

  public readonly fields = [
    { label: 'Rubros' },
    { label: 'Primer año', key: 'anio_1' },
    { label: 'Segundo año', key: 'anio_2' },
    { label: 'Tercer año', key: 'anio_3' },
    { label: 'Cuarto año', key: 'anio_4' },
    { label: 'Quinto año', key: 'anio_5' },
  ];
  public nacionalidadOptions: Array<ICatalogoDto> = [];
  public parentescoOption: Array<ICatalogoDto> = [];
  public sexoOptions: Array<ICatalogoDto> = [];
  public curpReadOnly = false;
  public sexoDisabled = true;
  public nacionalidadDisabled = false;
  public confirmo = false;
  public disabledConfirmo = false;
  public removeIndex = null;
  public dependientesNuevo: IDependienteEconomico = {
    curp: null,
    nombre: null,
    primerApellido: null,
    segundoApellido: null,
    fechaNacimiento: null,
    sexo: null,
    parentesco: null,
    esExtranjero: false,
  };

  public valueValidateInput;
  public valueFormatInput;

  public parts;
  public integerPart;
  public decimalPart;

  public readonly userInfo;

  public locale = LOCALE;

  public mounted() {
    this.initCatalogos();
    this.onDisabledConfirma();
  }

  get isOperador(): boolean {
    return this.solucion.autoridades.some(
      user => user.usuarioId == this.userInfo.login && user.roles.some(rol => rol === RolAutoridad.OPERADOR)
    );
  }

  get acceso(): { acciones: any[] } {
    return this.grantService().accesos(this.solucion, this.solicitud);
  }

  get readOnly(): boolean {
    if (this.acceso.acciones.findIndex(accion => accion === TipoAccion.ESCRIBIR) === -1) {
      return true;
    }
    return false;
  }

  public limpiaComponente() {
    this.dependientesNuevo = {
      curp: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      fechaNacimiento: null,
      sexo: null,
      parentesco: null,
      esExtranjero: false,
    };
    setTimeout(() => {
      this.$v.dependientesNuevo.$reset();
    }, 1);
  }

  public addDependiente() {
    if (!this.$v.dependientesNuevo.$invalid) {
      console.log(this.dependientesNuevo.fechaNacimiento);
      const form = JSON.parse(JSON.stringify(this.dependientesNuevo));
      if (this.removeIndex === null) {
        if (this.solicitud.datos_conciliados.dependientesEconomicos.length <= 2) {
          this.solicitud.datos_conciliados.dependientesEconomicos.push(form);
          this.updtaeDependientes();
        } else {
          this.alertService().showWarning(
            this,
            this.$t('apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.maximo-dependientes').toString()
          );
        }
      } else {
        this.$set(this.solicitud.datos_conciliados.dependientesEconomicos, this.removeIndex, form);
        this.removeIndex = null;
        this.updtaeDependientes();
      }
      this.limpiaComponente();
      this.dependientesNuevo.esExtranjero = false;
    } else {
      this.$v.$touch();
    }
  }

  public editDependiente(item: IDependienteEconomico, i: number) {
    this.nacionalidadDisabled = true;
    this.removeIndex = i;
    this.dependientesNuevo = this.shallowCopy(item);
    this.changeNacionalidad(item.esExtranjero);
  }

  public cancelModal() {
    this.removeDependiente.hide();
    this.removeIndex = null;
  }

  public removeModal(i: number) {
    this.removeDependiente.show();
    this.removeIndex = i;
  }

  public dependienteModificaModal() {
    this.changeDependiente.show();
  }

  public deleteDependiente() {
    this.solicitud.datos_conciliados.dependientesEconomicos.splice(this.removeIndex, 1);
    this.updtaeDependientes();
  }

  public cancelaDependiente() {
    this.removeIndex = null;
    this.nacionalidadDisabled = false;
    this.limpiaComponente();
  }

  public changeNacionalidad(e: boolean) {
    this.curpReadOnly = e;
    this.dependientesNuevo.esExtranjero = e;
    if (e) this.dependientesNuevo.curp = null;
  }

  public capitalsOnly() {
    this.dependientesNuevo.curp = this.dependientesNuevo.curp.toUpperCase();
  }

  /**
   * Setea y deshabilita el campo sexo dependiendo el id del catalogo parentesco
   * Habilita campo sexo cuando la opcion es conyuge, concubino y los demás.
   * @param e valor del catalogo parentesco
   */
  public sexoParentescoChange(e: any) {
    if (e.id == '1' || e.id == '5' || e.id == '8') {
      this.dependientesNuevo.sexo = { id: 'F', nombre: 'Femenino' };
      this.sexoDisabled = true;
    } else if (e.id == '2' || e.id == '6' || e.id == '7') {
      this.dependientesNuevo.sexo = { id: 'M', nombre: 'Masculino' };
      this.sexoDisabled = true;
    } else {
      this.dependientesNuevo.sexo = null;
      this.sexoDisabled = false;
    }
  }

  public numbersOnly(evt: any) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    // Bloquea el ingreso del número 0 como único dígito y cualquier carácter que no sea un número ni el punto decimal
    if (charCode === CharCodes.CERO && evt.target.value.length === 0) {
      evt.preventDefault();
    } else if ((charCode < CharCodes.CERO || charCode > CharCodes.NUEVE) && charCode !== CharCodes.DELETE) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy(source: any) {
    return JSON.parse(JSON.stringify(source));
  }

  public initCatalogos() {
    this.nacionalidadOptions = this.solucion.params.nacionalidadOptions;
    this.parentescoOption = this.solucion.params.parentescoOption;
    this.sexoOptions = this.solucion.params.sexoOptions;
  }

  public confirmaDatos() {
    this.solicitud.terminos_aceptados = this.confirmo;
    this.save(this.solicitud);
  }

  public updateAjustesSolicitados() {
    this.solicitud.ajustes_solicitados = true;
    this.solicitud.terminos_aceptados = false;
    this.confirmo = !this.solicitud.ajustes_solicitados;
    this.solicitudService()
      .update(this.solicitud)
      .then(() => {
        this.alertService().showSuccess(
          this,
          this.$t('apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.comentario', 'Exitoso').toString()
        );
        this.onDisabledConfirma();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public updtaeDependientes() {
    this.solicitud.dependientes_modificados = true;
    this.solicitud.terminos_aceptados = false;
    this.confirmo = !this.solicitud.dependientes_modificados;
    this.solicitudService()
      .update(this.solicitud)
      .then(() => {
        this.alertService().showSuccess(
          this,
          this.$t('apeironGwApp.conciliacionExtranjeras.dependientes-economicos.alerts.actualizar', 'Exitoso').toString()
        );
        this.onDisabledConfirma();
        this.dependienteModificaModal();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public validateInput(item: any, key: string) {
    this.valueValidateInput = item[key];
    // Reemplazar cualquier carácter que no sea un dígito o un punto
    this.valueValidateInput = this.valueValidateInput.replace(/[^0-9.]/g, '');
    // Asegúrese de que solo haya un punto presente
    if (this.valueValidateInput.split('.').length > 2) {
      this.valueValidateInput = this.valueValidateInput.replace(/\.+$/, '');
    }
    item[key] = this.valueValidateInput;
  }

  public formatInput(item: any, key: string) {
    this.valueFormatInput = item[key];

    // Verificar si el valor es un número y no está vacío
    if (typeof this.valueFormatInput === 'number' || (typeof this.valueFormatInput === 'string' && !isNaN(Number(this.valueFormatInput)))) {
      this.valueFormatInput = String(this.valueFormatInput); // Convertir a cadena si es número

      // Verificar si la cadena contiene un punto decimal
      if (this.valueFormatInput.includes('.')) {
        this.parts = this.valueFormatInput.split('.');
        this.integerPart = this.parts[0];
        this.decimalPart = this.parts[1] ? this.parts[1].substring(0, 2) : '';
        item[key] = this.decimalPart ? `${this.integerPart}.${this.decimalPart}` : this.integerPart;
      } else {
        // Si no contiene un punto decimal, no hacer nada
        this.integerPart = this.valueFormatInput;
        this.decimalPart = '';
        item[key] = this.integerPart;
      }
    }
  }

  public onDisabledConfirma() {
    if (this.solicitud.terminos_aceptados) {
      this.confirmo = this.solicitud.terminos_aceptados;
    }
    if (this.solicitud.dependientes_modificados || this.solicitud.ajustes_solicitados) {
      this.disabledConfirmo = true;
    } else {
      this.disabledConfirmo = false;
    }
  }

  public onFechaSeleccionada(value) {
    const fechaSeleccionada = new Date(value);
    fechaSeleccionada.setHours(0, 0, 0, 0);
    this.$v.dependientesNuevo.fechaNacimiento.$model = fechaSeleccionada;
  }
}
