var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        { staticClass: "w-25" },
        [
          _c("b-form-input", {
            attrs: {
              autocomplete: "off",
              placeholder: "Ingresa un cvu",
              type: "number",
            },
            model: {
              value: _vm.cvuToSearch,
              callback: function ($$v) {
                _vm.cvuToSearch = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "cvuToSearch",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-3",
                  attrs: {
                    disabled: _vm.isSearchDisabled,
                    "aria-placeholder": "",
                    size: "sm",
                    variant: "primary",
                  },
                  on: { click: _vm.handleFindPersonaByLogin },
                },
                [
                  !_vm.isSearching
                    ? _c(
                        "div",
                        [
                          _c("b-icon", { attrs: { icon: "search" } }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("global.process.search.action"))
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c("span", [
                        _c("em", [
                          _vm._v(
                            _vm._s(_vm.$t("global.process.search.searching"))
                          ),
                        ]),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          staticClass: "mt-3",
          attrs: { variant: "warning", show: _vm.userNotFound },
        },
        [
          _vm._v(_vm._s(_vm.$t("global.process.search.empty")) + " "),
          _c("strong", [_vm._v(_vm._s(_vm.cvuToSearch))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }