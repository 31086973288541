var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-overlay",
    {
      attrs: {
        variant: "transparent",
        "spinner-variant": "primary",
        "spinner-type": "none",
        show: "",
        opacity: 1,
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-3", attrs: { id: "header-2a" } },
        [
          _c(
            "b-col",
            { staticClass: "avatar", attrs: { cols: "7" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: true, opacity: 0 } },
                [
                  _c("b-avatar", {
                    staticClass: "avatar_img",
                    attrs: { variant: "primary", icon: "none", size: "4rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.account.firstName) +
                    " " +
                    _vm._s(_vm.account.lastName)
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "user_cvu align-middle" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.login")) + ": "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("apeironGwApp.persona.noCvu")) + ": "),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }