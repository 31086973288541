var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-form-tags", {
        staticClass: "no-border",
        attrs: {
          "tag-variant": "primary",
          "tag-pills": "",
          placeholder: "",
          "remove-on-delete": "",
          "add-on-change": "",
          "no-outer-focus": "",
          size: "sm",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({
              tags,
              inputAttrs,
              inputHandlers,
              disabled,
              removeTag,
            }) {
              return [
                tags.length > 0
                  ? _c(
                      "ul",
                      { staticClass: "list-inline d-inline-block mb-2" },
                      _vm._l(tags, function (tag) {
                        return _c(
                          "li",
                          { key: tag, staticClass: "list-inline-item" },
                          [
                            _c(
                              "b-form-tag",
                              {
                                attrs: {
                                  pill: "",
                                  title: tag,
                                  disabled: disabled,
                                  variant: _vm.variant(tag),
                                },
                                on: {
                                  remove: function ($event) {
                                    return removeTag(tag)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("archeApp.EstadoSolicitud." + tag)
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.availableOptions?.length > 0
                  ? _c(
                      "b-form-select",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: { disabled: disabled, size: "sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "option",
                                        { attrs: { disabled: "", value: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "archeApp.solicitud.buscador.modal.stateSelect"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          "b-form-select",
                          inputAttrs,
                          false
                        ),
                        inputHandlers
                      ),
                      [
                        _vm._v(" "),
                        _vm._l(_vm.availableOptions, function (estado) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: estado,
                              attrs: { value: estado, size: "sm" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("archeApp.EstadoSolicitud." + estado)
                                  )
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tags,
          callback: function ($$v) {
            _vm.tags = $$v
          },
          expression: "tags",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }