var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _vm.isValidUser
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("apeironGwApp.RolAutoridad.OPERADOR"),
                          },
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "w-100",
                            attrs: {
                              id: "select-operador-id",
                              options: _vm.listaOperadores,
                              "value-field": "usuarioId",
                              "text-field": "nombre",
                            },
                            model: {
                              value: _vm.operadorSelect,
                              callback: function ($$v) {
                                _vm.operadorSelect = $$v
                              },
                              expression: "operadorSelect",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      !_vm.operacionMasiva
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.operadorSelect,
                                variant: "primary",
                                size: "md",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.guardarOperador()
                                },
                              },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.save")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "save" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-row",
            [
              _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.$t("apeironGwApp.alertRol.noAutorizado")) + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }